(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievanceController', GrievanceController);

    GrievanceController.$inject = ['Grievance'];

    function GrievanceController(Grievance) {

        var vm = this;

        vm.grievances = [];

        loadAll();

        function loadAll() {
            Grievance.query(function(result) {
                vm.grievances = result;
                vm.searchQuery = null;
            });
        }
    }
})();
