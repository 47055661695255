(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcTicketSidebarController', HepcTicketSidebarController);

    HepcTicketSidebarController.$inject = [];

    function HepcTicketSidebarController() {
        var vm = this;

    }
})();
