(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('serviceFormFilledController', serviceFormFilledController);

    serviceFormFilledController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'projectService', 'serviceFormFieldDataCollection','ServiceFormAttachments','downloadserviceformattachment','DepartmentService', 'Project'];
    function serviceFormFilledController ($timeout, $scope, $stateParams, $uibModalInstance, projectService, serviceFormFieldDataCollection,ServiceFormAttachments,downloadserviceformattachment,DepartmentService, Project) {
        var vm = this;
        vm.clear = clear;
        vm.projectService= projectService;
        vm.downloadFile=downloadFile;
        vm.pdfconverter= pdfconverter;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function pdfconverter () {
            var element = document.getElementById('pdf_data');
            html2pdf(element,{
                margin: 4
            });
        }


        function clear ()
        {
            $uibModalInstance.dismiss('cancel');
        }
        function downloadFile(serverfilename,downloadfilename)
        {
            downloadserviceformattachment.download(serverfilename,downloadfilename)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

        vm.departmentservice= DepartmentService.get({id:vm.projectService.serviceid});
        loadServiceFormFields();
        function loadServiceFormFields() {
            vm.serviceFormFieldData= serviceFormFieldDataCollection.query({serviceid:vm.projectService.serviceid, projectid:vm.projectService.projectid});
            vm.serviceformattachments=ServiceFormAttachments.query({id:"1",serviceid:vm.projectService.serviceid, projectid:vm.projectService.projectid});
            vm.project = Project.get({id:vm.projectService.projectid});
        }

    }
})();
