(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ViewDecriminalizationFormController', ViewDecriminalizationFormController);

    ViewDecriminalizationFormController.$inject = ['$scope', 'entity', 'Decriminalization', 'Department', 'ViewDecriminalization'];

    function ViewDecriminalizationFormController($scope, entity, Decriminalization, Department, ViewDecriminalization) {

        var vm = this;

        vm.decriminalizations = [];

        vm.decriminalization = entity;
        vm.download=download;

        vm.downloadPDF = downloadPDF;

        loadAll();



        function loadAll() {
            getDecriminationzation();
            Decriminalization.query(function(result) {
                vm.decriminalizations = result;
                vm.decriminalizationResult = result; // Display all data on load
                vm.searchQuery = null;
            });
        }



        $scope.searchForm = function () {
            vm.decriminalizationResult = vm.decriminalizations.filter(function(item) {
                return (!vm.decriminalization.departmentId || item.departmentId === vm.decriminalization.departmentId) &&
                    (!vm.decriminalization.actName || item.actName === vm.decriminalization.actName) &&
                    (!vm.decriminalization.id || item.id.includes(vm.decriminalization.id));
            });
        }






        function getDepartments() {
            Department.query().$promise.then(function(data) {
                vm.departments = data;

            });
        }

        function getDecriminationzation() {
            Decriminalization.query().$promise.then(function(data) {
                vm.decriminalization = data;

            });
        }

        function download() {
            // Pass parameters dynamically in the URL
            ViewDecriminalization.query({
                actName: vm.decriminalization.actName,
                departmentId: vm.decriminalization.departmentId,
                id: vm.decriminalization.id
            }, function(result) {
                vm.decriminalizationResult = result;

                // Convert JSON to an array of arrays
                var rowsCleared = convertJsonsToArrays(result);

                if (rowsCleared.length === 0) {
                    alert('No rows to export');  // Add error handling if no data is present
                    return;
                }

                // Define the headers
                var headers = [
                    "Sr. No.",
                    "Compliance Request No.",
                    "Department",
                    "Act/Rule",
                    "Section/Rule Number that prescribe Criminal Punishment",
                    "Criminal Punishment Tenure Min Tenure (Months)",
                    "Criminal Punishment Tenure Max Tenure (Months)",
                    "Criminal Punishment Amount Min Amount (INR)",
                    "Criminal Punishment Amount Max Amount (INR)"
                ];

                var lineArrayCleared = [];

                // Add headers to the output
                lineArrayCleared.push(headers.join("\t"));

                // Add data rows to the output
                for (var i = 0; i < rowsCleared.length; i++) {
                    var lineCleared = rowsCleared[i].join("\t");
                    lineArrayCleared.push(lineCleared);
                }

                // Join all lines with a newline character
                lineArrayCleared = lineArrayCleared.join("\n");

                // Create a blob and initiate the download
                var a = document.createElement('a');
                var blob = new Blob([lineArrayCleared], { type: 'data:text/plain;charset=utf-8;' });
                var url = URL.createObjectURL(blob);
                a.href = url;
                a.download = 'DecriminalizationData.xls';  // specify the file name
                a.click();
            });
        }



        function downloadPDF() {
            ViewDecriminalization.query({
                actName: vm.decriminalization.actName,
                departmentId: vm.decriminalization.departmentId,
                id: vm.decriminalization.id
            }, function(result) {
                vm.decriminalizationResult = result;

                // Convert JSON to an array of arrays (without adding Sr. No.)
                var rowsCleared = convertJsonsToArrayss(result);

                // Define the headers
                var headers = [
                    "Sr. No.",
                    "Compliance Request No.",
                    "Department",
                    "Act/Rule",
                    "Section/Rule Number that prescribe Criminal Punishment",
                    "Criminal Punishment Tenure Min Tenure (Months)",
                    "Criminal Punishment Tenure Max Tenure (Months)",
                    "Criminal Punishment Amount Min Amount (INR)",
                    "Criminal Punishment Amount Max Amount (INR)"
                ];


                // Create HTML content using concatenation
                var htmlContent =
                    '<!DOCTYPE html>' +
                    '<html>' +
                    '<head>' +
                    '<title>Decriminalization Data</title>' +
                    '<style>' +
                    'body { font-family: Arial, sans-serif; margin: 20px; }' +
                    'table { width: 100%; border-collapse: collapse; }' +
                    'th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }' +
                    'th { background-color: #f2f2f2; }' +
                    'h1 { text-align: center; margin-bottom: 20px; }' +
                    '</style>' +
                    '</head>' +
                    '<body>' +
                    '<h1>Decriminalization Form Data</h1>' +
                    '<table>' +
                    '<thead>' +
                    '<tr>' +
                    headers.map(function(header) {
                        return '<th>' + header + '</th>';
                    }).join('') +
                    '</tr>' +
                    '</thead>' +
                    '<tbody>' +
                    rowsCleared.map(function(row, index) {
                        return '<tr>' +
                            '<td>' + (index + 1) + '</td>' + // Sr. No. is added here
                            row.map(function(cell) {
                                return '<td>' + (cell !== undefined ? cell : '') + '</td>';
                            }).join('') +
                            '</tr>';
                    }).join('') +
                    '</tbody>' +
                    '</table>' +
                    '</body>' +
                    '</html>';

                // Open a new window and print
                var printWindow = window.open('', '', 'height=600,width=800');
                printWindow.document.open();
                printWindow.document.write(htmlContent);
                printWindow.document.close();
                printWindow.focus(); // Focus on the new window

                // Trigger print dialog
                printWindow.onload = function() {
                    printWindow.print();
                };
            });
        }


        function convertJsonsToArrays(json) {
            var rows = [];

            // Iterate through the JSON data and convert each entry into an array
            json.forEach(function(item, index) {
                // If 'Enter months' or 'Enter Amount' is selected, display min/max values, otherwise use default '--'
                var tenureMin = item.criminalPunishmentTenureInMonths && item.criminalPunishmentTenureInMonths === 'Enter months' ? item.minimumTenure : '--';
                var tenureMax = item.criminalPunishmentTenureInMonths && item.criminalPunishmentTenureInMonths === 'Enter months' ? item.maximumTenure : '--';
                var amountMin = item.criminalPunishmentAmountInInr && item.criminalPunishmentAmountInInr === 'Enter Amount' ? item.minimumAmount : '--';
                var amountMax = item.criminalPunishmentAmountInInr && item.criminalPunishmentAmountInInr === 'Enter Amount' ? item.maximumAmount : '--';


                // Push row data into the rows array
                rows.push([
                    index + 1,  // Sr. No.
                    item.id,    // Compliance Request No.
                    item.departmentName,  // Department
                    item.actName,  // Act/Rule
                    item.sectionRuleNumberCriminalProvision,  // Section/Rule Number that prescribe Criminal Punishment
                    item.criminalPunishmentTenureInMonths === 'Enter months' ? item.minimumTenure : '--',
                    item.criminalPunishmentTenureInMonths === 'Enter months' ? item.maximumTenure : '--',
                    item.criminalPunishmentAmountInInr === 'Enter Amount' ? item.minimumAmount : '--',
                    item.criminalPunishmentAmountInInr === 'Enter Amount' ? item.maximumAmount : '--'
                ]);
            });

            return rows;
        }


        function convertJsonsToArrayss(json) {
            var rows = [];

            json.forEach(function(item) {
                // If Min/Max Tenure or Amount is missing, fill it with '--'
                rows.push([
                    item.id || '--',                                 // Compliance Request No.
                    item.departmentName || '--',                     // Department
                    item.actName || '--',                            // Act/Rule
                    item.sectionRuleNumberCriminalProvision || '--', // Section/Rule Number
                    item.minimumTenure || '--',        // Min Tenure
                    item.maximumTenure || '--',        // Max Tenure
                    item.minimumAmount || '--',        // Min Amount
                    item.maximumAmount || '--'         // Max Amount
                ]);
            });

            return rows;
        }

    }
})();
