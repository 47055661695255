(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CafsfornodalofficerController', CafsfornodalofficerController);

    CafsfornodalofficerController.$inject = ['Cafsfornodalofficer'];

    function CafsfornodalofficerController(Cafsfornodalofficer) {

        var vm = this;

        vm.cafsfornodalofficers = [];

        loadAll();

        function loadAll() {
            Cafsfornodalofficer.query(function(result) {
                vm.cafsfornodalofficers = result;
                vm.searchQuery = null;
            });
        }
    }
})();
