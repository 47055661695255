(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('FeedbackDashboardController', FeedbackDashboardController);

    FeedbackDashboardController.$inject = ['$scope', '$filter', 'Rating', 'DepartmentService', 'District','Department','RatingsByRating', 'RatingStats'];

    function FeedbackDashboardController($scope, $filter, Rating, DepartmentService, District,Department, RatingsByRating, RatingStats) {
        var vm = this;
        vm.allRatingsCounter = 0;
        vm.allExcellentCounter = 0;
        vm.allVeryGoodCounter = 0;
        vm.allGoodCounter = 0;
        vm.allAverageCounter = 0;
        vm.allPoorCounter = 0;
        vm.allPoorRatings = [];
        vm.allAverageRatings = [];
        vm.allResolvedCounter = 0;
        vm.allUnresolvedCounter = 0;
        vm.allRatingStats = {};
        vm.allRatings = [];
        vm.districts = [];
        vm.departments = [];
        vm.selectedDepartmentId = null;
        vm.departmentratings = [];

        vm.selectedFilter = 'service';
        console.log(vm.selectedDepartmentId);
        Rating.query(function(result){
            vm.feedbackratings = result;
            vm.allRatings = result;
            angular.forEach(vm.feedbackratings, function(value, key){
                if(vm.departments.indexOf(value.departmentName) === -1){
                    vm.departments.push(value.departmentName);
                }
            });
            getResolvedAndUnresolved();
        });

//        DepartmentService.query({id:'',departmentid:selectedDepartmentId},function(result){
//            vm.departmentservices = result;
//        });

        // Department.query(function(result) {
        //     vm.departments = result;
        // });

        District.query(function(result){
            vm.districts = result;
        });

        vm.departmentSelected = function(selectedDepartmentId){

            vm.departmentservices = [];
            if(vm.selectedDepartmentId ===null){
                vm.showService = true;
            }

            vm.feedbackratings = $filter('filter')(vm.allRatings,{ departmentName : selectedDepartmentId });
            angular.forEach(vm.feedbackratings, function(value, key) {
                if (vm.departmentservices.indexOf(value.serviceName) === -1) {
                    vm.departmentservices.push(value.serviceName);
                }
            });
            vm.departmentratings = vm.feedbackratings;
        }
        vm.serviceSelected = function(selectedServiceId){
            vm.feedbackratings = $filter('filter')(vm.departmentratings,{ serviceName : selectedServiceId });

        }

        vm.selectFilter = function(selectedFilter){
            vm.selectedFilter = selectedFilter;
            if(selectedFilter === 'service'){
                vm.serviceSelected(vm.selectedServiceId);
            } else if (selectedFilter === 'district') {
                vm.districtSelected(vm.selectedDistrict);
            }else if (selectedFilter === 'department') {
                vm.departmentSelected(vm.selectedDepartmentId);
            }
        }
        vm.districtSelected = function(selectedDistrict){
            vm.feedbackratings = $filter('filter')(vm.allRatings,{ district : selectedDistrict });
        }

        RatingStats.get(function(result){
            vm.allRatingStats = result;
        });

        function getResolvedAndUnresolved(){
            vm.unresolvedPoorCounter=$filter('filter')(vm.allRatings,{ isResolved : false, rating: 'Poor'}).length;
            vm.unresolvedAverageCounter=$filter('filter')(vm.allRatings,{ isResolved : false, rating: 'Average'}).length;
            vm.resolvedPoorCounter=$filter('filter')(vm.allRatings,{ isResolved : true, rating: 'Poor'}).length;
            vm.resolvedAverageCounter=$filter('filter')(vm.allRatings,{ isResolved : true, rating: 'Average'}).length;
        }

        vm.clear = function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
