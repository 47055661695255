(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('servicePaymentController', servicePaymentController);

    servicePaymentController.$inject = ['$window', '$timeout','$scope','$stateParams','$uibModalInstance','projectService','projectServicePayment','projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLogUpdated','FileManagement','Projectservicedetail','ProjectServicePaymentDetails','Payment'];
    function servicePaymentController ($window, $timeout, $scope, $stateParams, $uibModalInstance,projectService, projectServicePayment, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, FileManagement, Projectservicedetail, ProjectServicePaymentDetails,Payment) {
        var vm = this;
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectServicePayment = projectServicePayment;
        vm.projectService = projectService;
        vm.projectServicePayment.paymentMade = vm.projectService.serviceFee;
        vm.paymentparameters = Payment.get({projectid:vm.projectService.projectid,amount:vm.projectService.serviceFee,serviceid:vm.projectService.serviceid});

        vm.doPayment=doPayment;
        var projectServiceLogid=null;
        var projectAttachmentResultObject=null;
        vm.clear = clear;
        vm.makePayment = makePayment;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function doPayment()
        {
            var paymenturlwithparams='https://test.payu.in/_payment/firstname='+vm.paymentparameters.firstname+'&surl='+vm.paymentparameters.surl+'&phone='+vm.paymentparameters.phone+'&key='+vm.paymentparameters.key+'&hash='+vm.paymentparameters.hash;
            console.log(paymenturlwithparams);
            $window.location = paymenturlwithparams;
        }

        function makePayment(){
            vm.projectServicePayment.projectid=vm.projectService.projectid;
            vm.projectServicePayment.serviceid=vm.projectService.serviceid;
            ProjectServicePaymentDetails.save(vm.projectServicePayment,onServicePaymentSaveSuccess,onServicePaymentSaveError);
        }
        function onServicePaymentSaveSuccess(result)
        {
            alert('servicepaymentsaved');
            $scope.$emit('investhryApp:projectServicePaymentDetailsUpdate', result);
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.actionTaken="FeePaid";
            ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);
        }
        function onServicePaymentSaveError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments=vm.projectServiceLog.comments
            vm.projectService.isPaymentMade=true;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
        }
        function onUpdateProjectServiceSuccess(result)
        {
            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }
        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }
        function saveProjectAttachment()
        {
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Service Payment Attachment";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }
        function onSaveProjectAttachmentSuccess(result)
        {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }
        function onSaveProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function onUpdateProjectAttachmentSuccess(result)
        {
            $uibModalInstance.close(result);
        }
        function onUpdateProjectAttachmentError()
        {
            vm.isSaving = false;
        }
    }
})();
