(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ViewDisputeLogController', ViewDisputeLogController);

    ViewDisputeLogController.$inject = ['$scope', '$state', '$uibModalInstance', '$stateParams', 'Principal', 'LoginService', 'Disputelog','dispute','downloadService','viewRecruitmentFile'];

    function ViewDisputeLogController ($scope, $state, $uibModalInstance, $stateParams, Principal, LoginService, Disputelog, dispute, downloadService, viewRecruitmentFile) {
        var vm = this;
        vm.dispute = dispute;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.downloadFile = downloadFile;
        vm.id= $stateParams.id;
        vm.pdfconverter= pdfconverter;

        vm.clear = clear;

        vm.disputeLog = [];

        Disputelog.query({disputeMainId:$stateParams.id}, function(result) {
            vm.disputeLog = result;
            vm.searchQuery = null;
        });

        function clear ()
        {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        function downloadFile(attachment)
        {
            viewRecruitmentFile.view(attachment)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
                });
        }

        function pdfconverter () {
            var element = document.getElementById('pdf_data');
            html2pdf(element,{
                margin:  0.5,
                html2canvas: { dpi:192, letterRendering: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            });
        }
    }
})();
