(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('newservicedashboard', {
            parent: 'app',
            url: '/newservicedashboard',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_DESK_OFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/newservicedashboard/newservicedashboard.html',
                    controller: 'NewservicedashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('state');
                    return $translate.refresh();
                }]
            }
        })
        .state('clearancecharts', {
                    parent: 'newservicedashboard',
                    url: '/clearancecharts/{departmentid}',
                    data: {
                        authorities: []
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/newservicedashboard/clearancecharts.html',
                            controller: 'ClearancechartsController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('dashboard');
                                    return $translate.refresh();
                                }]
                            }
                        }).result.then(function() {
                            $state.go('newservicedashboard', null, { reload: 'servicedashboard' });
                        }, function() {
                            $state.go('newservicedashboard');
                        });
                    }]
                });

    }
})();
