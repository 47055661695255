(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('viewProjectAdminController', viewProjectAdminController);

    viewProjectAdminController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'ViewProjectAdmin'];

    function viewProjectAdminController ($scope, Principal, LoginService, $state, ViewProjectAdmin) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.viewcafs = viewcafs;
        vm.isloadingrequest=false;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        vm.type="new";
        vm.status="All";
        loadall();
        function loadall()
        {
           $scope.message = "All New Cafs";
           vm.isloadingrequest=true;
           ViewProjectAdmin.query({type:vm.type,Status:vm.status},function(result)
           {
              vm.projectlist = result;
              vm.isloadingrequest=false;
           });
        }

        function viewcafs(status)
        {
            vm.projectlist=[];
            vm.status=status;
            if(vm.type==="new")
            {
                if(vm.status==="All")
                {
                    $scope.message = "All New Cafs";
                }
                if(vm.status==="Pending")
                {
                    $scope.message = "Pending New Cafs";
                }
                if(vm.status==="Completed")
                {
                    $scope.message = "Completed New Cafs";
                }
                if(vm.status==="DeemedClear")
                {
                    $scope.message = "DeemedClear New Cafs";
                }
            }
            if(vm.type==="Renewable")
            {
                if(vm.status==="All")
                {
                    $scope.message = "All Renewable Cafs";
                }
                if(vm.status==="Pending")
                {
                    $scope.message = "Pending Renewable Cafs";
                }
                if(vm.status==="Completed")
                {
                    $scope.message = "Completed Renewable Cafs";
                }
                if(vm.status==="DeemedClear")
                {
                    $scope.message = "DeemedClear Renewable Cafs";
                }
            }
            if(vm.type==="ShopCaf")
            {
                if(vm.status==="All")
                {
                    $scope.message = "All Shop Cafs";
                }
                if(vm.status==="Pending")
                {
                    $scope.message = "Pending Shop Cafs";
                }
                if(vm.status==="Completed")
                {
                    $scope.message = "Completed Shop Cafs";
                }
                if(vm.status==="DeemedClear")
                {
                    $scope.message = "DeemedClear Shop Cafs";
                }
            }
            if(vm.type==="LandRequirementCaf")
            {
                if(vm.status==="All")
                {
                    $scope.message = "All Land Requirement Cafs";
                }
                if(vm.status==="Pending")
                {
                    $scope.message = "Pending Land Requirement Cafs";
                }
                if(vm.status==="Completed")
                {
                    $scope.message = "Completed Land Requirement Cafs";
                }
                if(vm.status==="DeemedClear")
                {
                    $scope.message = "DeemedClear Land Requirement Cafs";
                }
            }
            vm.isloadingrequest=true;
            ViewProjectAdmin.query({type:vm.type,Status:vm.status},function(result)
            {
                vm.projectlist = result;
                vm.isloadingrequest=false;
            });
        }

        function register () {
            $state.go('register');
        }
    }
})();
