(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NMAserviceFormController', NMAserviceFormController);

    NMAserviceFormController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'projectService', 'projectServiceFormFieldData', 'projectAttachemnt', 'projectServiceLog', 'ProjectAttachemnt', 'ProjectServiceLogUpdated', 'FileManagement', 'Projectservicedetail', 'Projectserviceformfielddata', 'serviceFormFieldDataCollection', 'ServiceFormAttachments', 'DepartmentService', 'Serviceformdocs', 'downloadserviceformattachment', 'NMARequest', '$filter', 'Serviceformdraftsaved'];

    function NMAserviceFormController($timeout, $scope, $stateParams, $uibModalInstance, projectService, projectServiceFormFieldData, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, FileManagement, Projectservicedetail, Projectserviceformfielddata, serviceFormFieldDataCollection, ServiceFormAttachments, DepartmentService, Serviceformdocs, downloadserviceformattachment, NMARequest, $filter, Serviceformdraftsaved) {
        var vm = this;
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectServiceFormFieldData = projectServiceFormFieldData;
        vm.projectService = projectService;
        vm.uploadNMAFiles = uploadNMAFiles;
        var projectAttachmentResultObject = null;
        var projectServiceLogid = null;
        var formfielddataObjectArrayLength = 0;
        var formfieldCount = 0;
        var vmlength = 0;
        vm.serviceformdraftsaved = {};
        vm.isSaved = false;
        vm.finalsubmit = false;
        vm.NMAfiles = [];
        var eventname;
        var saveLogFlag = false;
        vm.clear = clear;
        vm.saveFormFieldData = saveFormFieldData;
        vm.downloadFile = downloadFile;
        $scope.file = {};
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.departmentservice = DepartmentService.get({id: vm.projectService.serviceid});
        Serviceformdocs.query({id: "1", serviceid: vm.projectService.serviceid}, function (result) {
            vm.serviceformdocs = result;
            vm.searchQuery = null;
        });
        loadServiceFormFields();

        function loadServiceFormFields() {
            Serviceformdraftsaved.get({id: vm.projectService.id}, function () {
                vm.isDraftSaved = true;
            });

            vm.serviceFormFieldData = serviceFormFieldDataCollection.query({
                serviceid: vm.projectService.serviceid,
                projectid: vm.projectService.projectid
            });
        }

        var combineFilledForm = "";

        function saveFormFieldData() {
            if (!vm.finalsubmit) {
                alert('Your Form is temporarily Submitted.\n\nInorder to make corrections re-open the form and save again.For Final submission click on the Final Submit button.\n\nNote: After final submission no more updations will be accepted');
            }
            vm.isSaving = true;
            vmlength = vm.serviceFormFieldData.length;
            formfielddataObjectArrayLength = vmlength;
            for (var i = 0; i < vmlength; i++) {
                var data = vm.serviceFormFieldData[i];
                combineFilledForm = combineFilledForm + vm.serviceFormFieldData[i].formfieldvalue + " | ";
                data.projectid = vm.projectService.projectid;
                if (data.id !== null) {
                    Projectserviceformfielddata.update(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
                } else {
                    Projectserviceformfielddata.save(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
                }
                formfieldCount = i;
            }

            for (var j = 0; j < vm.fileattach.length; j++) {
                if (vm.fileattach[j].isSaved === false) {
                    vm.fileattach[j].isSaved = true;
                }
                ServiceFormAttachments.update(vm.fileattach[j], onUpdatefieldServiceFormAttachmentsSuccess);
            }

            if (vm.finalsubmit) {
                createNMAObject();
            }
        }

        function onUpdatefieldServiceFormAttachmentsSuccess() {
        }

        vm.fileattach = ServiceFormAttachments.query({
            id: "1",
            projectid: vm.projectService.projectid,
            serviceid: vm.projectService.serviceid
        }, function (result) {
            loadFileAttachments(result);
        });
        vm.addfileattachment = addfileattachment;

        function addfileattachment(documentType) {
            eventname = documentType;
            var fileNameArray = vm.serviceformfile.name.split(".");
            var fileextension = "";
            if ((fileNameArray.length > 1)) {
                fileextension = fileNameArray[(fileNameArray.length - 1)];
            }
            var inputFileName = vm.serviceformattachment.description;
            if (fileextension !== "") {
                inputFileName = inputFileName + "." + fileextension;
            }
            vm.serviceformattachment.isSaved = false;
            vm.serviceformattachment.downloadfilename = inputFileName;
            vm.serviceformattachment.clientfilename = vm.serviceformfile.name;
            vm.serviceformattachment.projectID = vm.projectService.projectid;
            vm.serviceformattachment.serviceID = vm.projectService.serviceid;
            ServiceFormAttachments.save(vm.serviceformattachment, onSaveServiceFormAttachmentsSuccess);
        }

        function onSaveServiceFormAttachmentsSuccess(result) {
            var filename = result.id;
            var file = vm.serviceformfile;
            FileManagement.saveFile(file, filename);
            var fileNameArray = vm.serviceformfile.name.split(".");
            var fileextension = "";
            if ((fileNameArray.length > 1)) {
                fileextension = fileNameArray[(fileNameArray.length - 1)];
            }
            var inputFileName = result.id;
            if (fileextension !== "") {
                inputFileName = inputFileName + "." + fileextension;
            }
            result.serverfilename = inputFileName;
            eventname(result);


            ServiceFormAttachments.update(result, onUpdateServiceFormAttachmentsSuccess);
        }

        function onUpdateServiceFormAttachmentsSuccess(result) {
            vm.fileattach.push(result);
            vm.fileattachment = {};
            vm.serviceformattachment.description = "";
            $('#browsefile').val('');
        }

        vm.removeRawRow = function (id) {
            ServiceFormAttachments.delete({id: id});
            var index = -1;
            var comArray = eval(vm.fileattach);
            for (var i = 0; i < comArray.length; i++) {
                if (comArray[i].id === id) {
                    index = i;
                    break;
                }
            }
            vm.fileattach.splice(index, 1);
        };

        function onSaveServiceFormFieldDataSuccess(result) {
            if (!vm.finalsubmit && !saveLogFlag && formfieldCount === (formfielddataObjectArrayLength - 1)) {
                saveProjectServiceLog();
                saveLogFlag = true;
            }
        }

        function onSaveServiceFormFieldDataError() {
        }

        function saveProjectServiceLog(result) {
            $scope.$emit('investhryApp:projectserviceformfielddataUpdate', result);
            vm.projectServiceLog.projectid = vm.projectService.projectid;
            vm.projectServiceLog.serviceid = vm.projectService.serviceid;
            vm.projectServiceLog.comments = vm.projectServiceLog.comments + " | " + combineFilledForm;

            if (!vm.finalsubmit) {
                vm.projectServiceLog.actionTaken = "ServiceFormDraftSaved";
            } else {
                vm.projectServiceLog.actionTaken = "ServiceFormEdited";
            }
            ProjectServiceLogUpdated.save(vm.projectServiceLog, onServiceLogSaveSuccess, onServiceLogSaveError);
        }

        function onServiceLogSaveSuccess(result) {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            if (!vm.finalsubmit) {
                vm.serviceformdraftsaved.id = vm.projectService.id;
                vm.serviceformdraftsaved.draftsaved = true;
                Serviceformdraftsaved.update(vm.serviceformdraftsaved);
                $uibModalInstance.close();
                return;
            }
            projectServiceLogid = result.id;
            vm.projectService.latestComments = "Service form edited";
            vm.projectService.formFilledStatus = true;
            Projectservicedetail.update(vm.projectService, onUpdateProjectServiceSuccess, onUpdateProjectServiceError);
        }

        function onUpdateProjectServiceSuccess(result) {
            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            if (angular.isDefined(vm.projectAttachemnt.file)) {
                saveProjectAttachment();
            }
            else {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

        function onUpdateProjectServiceError() {
            vm.isSaving = false;
        }

        function onServiceLogSaveError() {
            vm.isSaving = false;
        }

        function saveProjectAttachment() {
            var file = vm.projectAttachemnt.file;
            vm.projectAttachemnt.id = projectServiceLogid;
            vm.projectAttachemnt.fileName = file.name;
            vm.projectAttachemnt.description = "Service Form Field Data Attachment";
            vm.projectAttachemnt.fileExtension = file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt, onSaveProjectAttachmentSuccess, onSaveProjectAttachmentError);
        }

        function onSaveProjectAttachmentSuccess(result) {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file = vm.projectAttachemnt.file;
            FileManagement.saveFile(file, filename);
            projectAttachmentResultObject = result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension = "";
            if ((fileNameArray.length > 1)) {
                fileextension = fileNameArray[(fileNameArray.length - 1)];
            }
            var inputFileName = result.id;
            if (fileextension !== "") {
                inputFileName = inputFileName + "." + fileextension;
            }
            projectAttachmentResultObject.serverFileName = inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject, onUpdateProjectAttachmentSuccess, onUpdateProjectAttachmentError);
        }

        function onSaveProjectAttachmentError() {
            vm.isSaving = false;
        }

        function onUpdateProjectAttachmentSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onUpdateProjectAttachmentError() {
            vm.isSaving = false;
        }

        function downloadFile(serverfilename, downloadfilename) {
            downloadserviceformattachment.download(serverfilename, downloadfilename)
                .then(function (success) {
                    console.log('success : ' + success);
                }, function (error) {
                    console.log('error : ' + error);
                });
        }

        $scope.firmFiles = [];
        vm.firmFiles = [];
        $scope.propertyDocument = [];
        vm.propertyDocument = [];
        $scope.typicalfloorplans = [];
        vm.typicalfloorplans = [];
        $scope.ownershipdocuments = [];
        vm.ownershipdocuments = [];
        $scope.sectionDocuments = [];
        vm.sectionDocuments = [];
        $scope.elevationDocuments = [];
        vm.elevationDocuments = [];
        $scope.repairsDocuments = [];
        vm.repairsDocuments = [];
        $scope.googleEarthImage = [];
        vm.googleEarthImage = [];
        $scope.modernconstructionImage = [];
        vm.modernconstructionImage = [];
        $scope.signature = [];
        vm.sign = [];
        vm.otherDocuments = [];
        $scope.otherDocuments = [];
        vm.serviceformattachment = {};

        vm.createNMAObject = createNMAObject;

        function createNMAObject() {
            var sampleObject = {};
            vm.NMAfiles = [$scope.propertyDocument, $scope.elevationDocuments, $scope.typicalfloorplans, $scope.sectionDocuments, $scope.otherDocuments, $scope.repairsDocuments, $scope.googleEarthImage, $scope.modernconstructionImage, $scope.ownershipdocuments, $scope.firmFiles, $scope.signature];
            vm.testData = $filter('orderBy')(vm.serviceFormFieldData, 'formfieldOrder');
            $scope.data = NMARequest.createNMAObject(vm.testData, vm.NMAfiles, $stateParams.cafpin, vm.projectService.id,onNmaObjectSaveSuccess,onNmaObjectSaveError);

        }

        function onNmaObjectSaveSuccess(result) {
            if (!saveLogFlag && formfieldCount === (formfielddataObjectArrayLength - 1)) {
                saveProjectServiceLog();
                saveLogFlag = true;
            }
        }

        function onNmaObjectSaveError(result) {
            alert(result.data.errorMessage);
            vm.isSaving = false;
        }

        function uploadNMAFiles(documentType) {
            var baseAddress = 'https://investharyana.in/api/downloadserviceformattachment?serverfilename=';
            switch (documentType) {

                case 'Property':
                    vm.serviceformfile = vm.propertydocument;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.propertyDocument.push(result);
                        $scope.propertyDocument.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.propertydocument = null;
                    vm.propertydocumentdescription = '';
                    $('#propertydocs').val('');

                    break;

                case 'Elevation':
                    vm.serviceformfile = vm.elevationdocument;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.elevationDocuments.push(result);
                        $scope.elevationDocuments.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.elevationdocument = null;
                    vm.elevationdocumentdescription = '';
                    $('#elevationdocs').val('');
                    break;
                case 'FloorPlan':
                    vm.serviceformattachment.description = documentType;
                    vm.serviceformfile = vm.typicalfloorplan;
                    addfileattachment(function (result) {
                        vm.typicalfloorplans.push(result);
                        $scope.typicalfloorplans.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.typicalfloorplan = null;
                    vm.typicalfloorplandescription = '';
                    $('#floorplan').val('');
                    break;


                case 'Repairs':
                    vm.serviceformfile = vm.repairsdocument;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.repairsDocuments.push(result);
                        $scope.repairsDocuments.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.repairsdocument = null;
                    vm.repairsdescrption = '';
                    $('#repairdocs').val('');
                    break;
                case 'Section':
                    vm.serviceformfile = vm.sectiondocument;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.sectionDocuments.push(result);
                        $scope.sectionDocuments.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.sectiondocument = null;
                    vm.sectiondocumentdescription = '';
                    $('#sectiondocs').val('');
                    break;
                case 'ModernConstructionsImage':
                    vm.serviceformfile = vm.constructionimg;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.modernconstructionImage.push(result);
                        $scope.modernconstructionImage.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.constructionimg = null;
                    vm.constructionimgdesc = '';
                    $('#constructiondocs').val('');
                    break;
                case 'GoogleEarthImage':
                    vm.serviceformfile = vm.googlearth;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.googleEarthImage.push(result);
                        $scope.googleEarthImage.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.googlearth = null;
                    vm.googlearthdesc = '';
                    $('#earthimage').val('');
                    break;
                case 'Ownership':
                    vm.serviceformfile = vm.ownership;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.ownershipdocuments.push(result);
                        $scope.ownershipdocuments.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.ownership = null;
                    vm.ownershipdesc = '';
                    $('#ownership').val('');
                    break;
                case 'Firm':
                    vm.serviceformfile = vm.firmfile;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.firmFiles.push(result);
                        $scope.firmFiles.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.firmfile = null;
                    vm.firmfiledesc = '';
                    $('#firmfiles').val('');
                    break;
                case 'Other':
                    vm.serviceformfile = vm.otherdocument;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.otherDocuments.push(result);
                        $scope.otherDocuments.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.otherdocument = null;
                    vm.others = '';
                    $('#others').val('');
                    break;
                case 'Signature':
                    vm.serviceformfile = vm.signature;
                    vm.serviceformattachment.description = documentType;
                    addfileattachment(function (result) {
                        vm.sign.push(result);
                        $scope.signature.push({file: baseAddress + result.serverfilename + '&downloadfilename=' + result.clientfilename});
                    });
                    vm.otherdocument = null;
                    $('#signature').val('');
                    break;
            }
        }

        vm.removeRow = function (patharray, data, id, $index) {
            ServiceFormAttachments.delete({id: id});
            data.splice($index, 1);
            patharray.splice($index, 1);
        };
        vm.finalsubmitted = function () {
            vm.finalsubmit = true;
            saveFormFieldData();
        };

        function loadFileAttachments(result) {
            var baseAddress = 'https://investharyana.in/api/downloadserviceformattachment?serverfilename=';
            for (var i = 0; i < result.length; i++) {
                switch (result[i].description) {
                    case 'Property':
                        vm.propertyDocument.push(result[i]);
                        $scope.propertyDocument.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'Elevation':
                        vm.elevationDocuments.push(result[i]);
                        $scope.elevationDocuments.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'FloorPlan':
                        vm.typicalfloorplans.push(result[i]);
                        $scope.typicalfloorplans.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'Repairs':
                        vm.repairsDocuments.push(result[i]);
                        $scope.repairsDocuments.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'Section':
                        vm.sectionDocuments.push(result[i]);
                        $scope.sectionDocuments.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'ModernConstructionsImage':
                        vm.modernconstructionImage.push(result[i]);
                        $scope.modernconstructionImage.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'GoogleEarthImage':
                        vm.googleEarthImage.push(result[i]);
                        $scope.googleEarthImage.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'Ownership':
                        vm.ownershipdocuments.push(result[i]);
                        $scope.ownershipdocuments.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'Firm':
                        vm.firmFiles.push(result[i]);
                        $scope.firmFiles.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'Other':
                        vm.otherDocuments.push(result[i]);
                        $scope.otherDocuments.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                    case 'Signature':
                        vm.sign.push(result[i]);
                        $scope.signature.push({file: baseAddress + result[i].serverfilename + '&downloadfilename=' + result[i].clientfilename});
                        break;
                }
            }
        }



    }
})();
