(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ClearedServicesController', ClearedServicesController);

    ClearedServicesController.$inject = ['ClearedServices'];

    function ClearedServicesController(ClearedServices) {

        var vm = this;

        vm.clearedServices = [];

        loadAll();

        function loadAll() {
            ClearedServices.query(function(result) {
                vm.clearedServices = result;
                vm.searchQuery = null;
            });
        }
    }
})();
