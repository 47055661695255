(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('aai', {
            parent: 'entity',
            url: '/aai',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.aai.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/aai/aais.html',
                    controller: 'AaiController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('aai');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('aai-detail', {
            parent: 'aai',
            url: '/aai/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.aai.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/aai/aai-detail.html',
                    controller: 'AaiDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('aai');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Aai', function($stateParams, Aai) {
                    return Aai.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'aai',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('aai-detail.edit', {
            parent: 'aai-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/aai/aai-dialog.html',
                    controller: 'AaiDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Aai', function(Aai) {
                            return Aai.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state("nma-state",{
            parent: 'constructionpermit',
            url: '/nma/{CPid}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
            },
            views: {
                'nestedForm@constructionpermit': {
                    templateUrl: 'app/entities/nma/nma-dialog.html',
                    controller: 'NmaDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        department: null,
                        architectName: null,
                        architectEmail: null,
                        architectMobile: null,
                        nocRequestScreen: null,
                        nameOfApplicant: null,
                        presentAddress: null,
                        permanentAddress: null,
                        isOwner: null,
                        nameOfOwner: null,
                        presentAddressOwner: null,
                        permanentAddressOwner: null,
                        typeOwner1: null,
                        typeOwner2: null,
                        typeOwner3: null,
                        plotNo: null,
                        state: null,
                        district: null,
                        taulk: null,
                        locality: null,
                        monumentName: null,
                        stateMonument: null,
                        districtMonument: null,
                        taulkMonument: null,
                        localityMonument: null,
                        distance1: null,
                        distance2: null,
                        workDetails1: null,
                        workDetails2: null,
                        workDetails3: null,
                        workDetails4: null,
                        workDetails5: null,
                        workDetails6: null,
                        workDetails7: null,
                        workDetails8: null,
                        workDetails9: null,
                        workDetails10: null,
                        workDetails11: null,
                        workDetails12: null,
                        otherWorkDetails1: null,
                        otherWorkDetails2: null,
                        otherWorkDetails3: null,
                        otherWorkDetails4: null,
                        otherWorkDetails5: null,
                        otherWorkDetails6: null,
                        otherWorkDetails7: null,
                        otherWorkDetails8: null,
                        id: null
                    };
                }
            }
        })
        .state('aai.new', {
            parent: 'aai',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/aai/aai-dialog.html',
                    controller: 'AaiDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                height_clearance: null,
                                nameOfAplicant: null,
                                addressOfApplicant: null,
                                mobile_no: null,
                                email_id: null,
                                nameOfOwner: null,
                                addressOfCommunication: null,
                                typeOfStructure: null,
                                usesOfStructure: null,
                                siteDescription: null,
                                sizeOfPlot: null,
                                state: null,
                                areaName: null,
                                latitude1: null,
                                latitude2: null,
                                latitude3: null,
                                longitude1: null,
                                longitude2: null,
                                longitude3: null,
                                siteElevation: null,
                                buildingHeight: null,
                                maxSiteElevation: null,
                                question1: null,
                                question2: null,
                                question3: null,
                                file1: null,
                                city: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('aai', null, { reload: 'aai' });
                }, function() {
                    $state.go('aai');
                });
            }]
        })
        .state('aai.edit', {
            parent: 'aai',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/aai/aai-dialog.html',
                    controller: 'AaiDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Aai', function(Aai) {
                            return Aai.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('aai', null, { reload: 'aai' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('aai.delete', {
            parent: 'aai',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/aai/aai-delete-dialog.html',
                    controller: 'AaiDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Aai', function(Aai) {
                            return Aai.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('aai', null, { reload: 'aai' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
