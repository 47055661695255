(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceViewCafController', ServiceViewCafController);

    ServiceViewCafController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'ServiceViewCaf', 'TowerserviceNO','ServiceViewCafByElastic'];
    function ServiceViewCafController ($timeout, $scope, $stateParams, $uibModalInstance, ServiceViewCaf, TowerserviceNO,ServiceViewCafByElastic) {
        var vm = this;
        vm.clear = clear;
        vm.servicename=$stateParams.servicename;
        vm.status=$stateParams.status;
        var initDate=new Date('05-01-2017');
        var totalTime=0;
        var totalCleared=0;
        vm.showDepartments=showDepartments;
        vm.departmentName=[];
        vm.show = false;
        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";

        $scope.file = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
            /*appliedDate=new Date(result[i].appliedDate.toDateString());
            clearedDate=new Date(result[i].clearedDate.toDateString());
            dayDiff= (clearedDate - appliedDate)  / 1000 / 60 / 60 / 24;
*/
        loadAllCafs();
        function loadAllCafs() {
            var appliedDate;
            var clearedDate;
            var dayDiff;
            if(!$stateParams.departmentName){
                vm.deptName = null;
            } else {
                vm.deptName = $stateParams.departmentName;
            }

            if(!$stateParams.district){
                vm.districtName = null;
            } else {
                vm.districtName = $stateParams.district;
            }

            if(!$stateParams.projectLevel){
                vm.levelfiled = null;
            } else {
                vm.levelfiled = $stateParams.projectLevel;
            }
            var data = {departmentName: vm.deptName, district: vm.districtName, projectLevel: vm.levelfiled,serviceId:$stateParams.serviceid,status:$stateParams.status,
                page: $scope.currentPage - 1,
                size: $scope.pageSize};
            Object.keys(data).forEach(function (k) {
                if(!data[k] && data[k] !== undefined)
                {
                    delete data[k];
                }
            });
          vm.dashboardcafs=ServiceViewCafByElastic.get(data,function(result){
              vm.show=true;
          });
        }

        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
        }

        $scope.pageChangedHandler = function(num) {
            loadAllCafs();
        };

        function showDepartments(data) {
            TowerserviceNO.query({id:'1',projectdetailid:data.projectdetailid},function (result) {
                if(result.length===0){
                    vm.departmentName=[];
                    vm.departmentName[0] = "NA";
                }
                else {
                    vm.departmentName = result;
                }
            });

        }

    }
})();
