(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DeemedClearanceApprovalDetailController', DeemedClearanceApprovalDetailController);

    DeemedClearanceApprovalDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DeemedClearanceApproval'];

    function DeemedClearanceApprovalDetailController($scope, $rootScope, $stateParams, previousState, entity, DeemedClearanceApproval) {
        var vm = this;

        vm.deemedClearanceApproval = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:deemedClearanceApprovalUpdate', function(event, result) {
            vm.deemedClearanceApproval = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
