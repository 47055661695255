(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DistrictDeskOfficerDialogController', DistrictDeskOfficerDialogController);

    DistrictDeskOfficerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DistrictDeskOfficer','District'];

    function DistrictDeskOfficerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DistrictDeskOfficer, District) {
        var vm = this;

        vm.districtDeskOfficer = entity;
        vm.clear = clear;
        vm.save = save;
        vm.districts = [];

        loadAllDistricts();
        function loadAllDistricts() {
            District.query(function (result) {
                vm.districts=result;
                for( var i = 0; i < vm.districts.length-1; i++){
                    if ( vm.districts[i].districtname === 'Chandigarh HQ') {
                        vm.districts.splice(i, 1);
                    }
                }
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.districtDeskOfficer.id !== null) {
                DistrictDeskOfficer.update(vm.districtDeskOfficer, onSaveSuccess, onSaveError);
            } else {
                DistrictDeskOfficer.save(vm.districtDeskOfficer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:districtDeskOfficerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (error) {
            vm.isSaving = false;
            if(error.status == 400)
                alert("Login does not exist. Please recheck or create the same.");
        }


    }
})();
