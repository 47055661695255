(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AcknowledgementsController', AcknowledgementsController);

    AcknowledgementsController.$inject = ['Acknowledgements'];

    function AcknowledgementsController(Acknowledgements) {

        var vm = this;
        var step=1000;
        vm.limit=1000;

        vm.acknowledgements = [];

        loadAll();

        function loadAll() {
            Acknowledgements.query(function(result) {
                vm.acknowledgements = result;
                vm.searchQuery = null;
            });
        }

        vm.incrementSize=function () {
            vm.limit+=step;
        };

        vm.decrementSize=function () {
            vm.limit-=step;
        };
    }
})();
