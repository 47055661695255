(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewDasboardAllCompletedStatsDeleteController',NewDasboardAllCompletedStatsDeleteController);

    NewDasboardAllCompletedStatsDeleteController.$inject = ['$uibModalInstance', 'entity', 'NewDasboardAllCompletedStats'];

    function NewDasboardAllCompletedStatsDeleteController($uibModalInstance, entity, NewDasboardAllCompletedStats) {
        var vm = this;

        vm.newDasboardAllCompletedStats = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            NewDasboardAllCompletedStats.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
