(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('servicedashboard', {
            parent: 'app',
            url: '/servicedashboard/{auth}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DESK_OFFICER']
            },
            params: {
                auth: ""
            },
            views: {
                'content@': {
                    templateUrl: 'app/deptservicedashboard/deptservicedashboard.html',
                    controller: 'DeptServiceDashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }]
            }
        }).state('servicedashboardcafs', {
            parent: 'servicedashboard',
            url: '/servicedashboard/{servicename}/{departmentName}/{district}/{projectLevel}/{serviceid}/{status}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/deptservicedashboard/serviceviewcafs.html',
                    controller: 'ServiceViewCafController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('dashboard');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('servicedashboard', null, { reload: 'servicedashboard' });
                }, function() {
                    $state.go('servicedashboard');
                });
            }]
        })
        .state('towerservicedashboard', {
                parent: 'app',
                url: '/tower_service_dashboard',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER','ROLE_DESK_OFFICER'],
                    pageTitle: 'investhryApp.tower_service_dashboard.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/deptservicedashboard/towerservicedashboard.html',
                        controller: 'TowerServiceDashboardController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('tower_service_dashboard');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
        .state('tower-servicedashboardcafs', {
            parent: 'towerservicedashboard',
            url: '/servicedashboard/{departmentname}/{servicename}/{status}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER','ROLE_DESK_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/deptservicedashboard/serviceviewcafsTower.html',
                    controller: 'TowerServiceViewCafController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('dashboard');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('towerservicedashboard', null, { reload: 'towerservicedashboard' });
                }, function() {
                    $state.go('towerservicedashboard');
                });
            }]
        })
        .state('powerdashboard', {
            parent: 'app',
            url: '/powerdashboard',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/deptservicedashboard/powerDashboard.html',
                    controller: 'PowerDashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });

    }
})();
