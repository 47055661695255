(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('grievancedashboard', {
            parent: 'app',
            url: '/grievancedashboard',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/grievancedashboard/grievancedashboard.html',
                    controller: 'GrievanceDashboardController',
                    controllerAs: 'vm'
                }
                },
                resolve: {
                   translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                       $translatePartialLoader.addPart('home');
                       return $translate.refresh();
                 }]
                }
        })
            .state('searchGrievance', {
                parent: 'app',
                url: '/searchGrievance',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/grievancedashboard/searchgrievance.html',
                        controller: 'SearchGrievanceController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('dashboard');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('viewlog', {
                parent: 'searchGrievance',
                url: '/viewlog/{grievanceId}',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/grievancedetail/viewlog.html',
                        controller: 'ViewLogController',
                        controllerAs: 'vm',
                        size:'lg',

                        resolve: {
                            grievance: ['$stateParams', 'Grievance', function($stateParams, Grievance) {
                                return Grievance.query({grievanceId : $stateParams.grievanceId}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('searchGrievance', null, { reload: 'searchGrievance' });
                    }, function() {
                        $state.go('searchGrievance');
                    });
                }]
            })



            .state('grievanceviewstatsdetail', {
            parent: 'grievancedashboard',
            url: '/grieavanceviewstatsdetail/{departmentId}/{district}/{districtId}/{status}',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/grievancedashboard/grievanceviewstatsdetail.html',
                    controller: 'GrievanceViewStatsDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('grievancedashboard', null, { reload: 'grievancedashboard' });
                }, function() {
                    $state.go('grievancedashboard');
                });
            }]
        });
    }
})();
