(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ViewUserServiceStatusController', ViewUserServiceStatusController);

    ViewUserServiceStatusController.$inject = ['$scope', '$filter', 'Principal', 'LoginService', '$state', 'ViewServiceStatus','ServiceStatus','ServiceLevel'];

    function ViewUserServiceStatusController ($scope, $filter, Principal, LoginService, $state, ViewServiceStatus , ServiceStatus, ServiceLevel) {
        var vm = this;
        vm.loadAll=loadAll();

        vm.service = false;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        var isCafSearch = true;
        vm.serviceLevels = [];
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        vm.more = function (index, serviceId) {
            $scope.activeParentIndex = index;
            if (serviceId != null){
                vm.serviceLevelsByServiceId = $filter('filter')(vm.serviceLevels,{serviceId: serviceId});
                vm.serviceLevelsSelected = [];
                vm.serviceLevelsByServiceId.forEach(function (servicelevel) {
                    vm.serviceLevelsSelected[servicelevel.level] = servicelevel.levelDescription;
                });
            }
        };

        $scope.isShowing = function(index){
            return  $scope.activeParentIndex === index;
        };


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });

        }
        function register () {
            $state.go('register');
        }


        function loadAll() {
            ServiceStatus.query({userName:'user'},function(result) {
                vm.serviceStatuses = result;
                vm.searchQuery = null;
            });

            ServiceLevel.query(function (serviceLevels) {
                vm.serviceLevels = serviceLevels;
            });
        }
    }
})();
