(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('FeedbackOfficerController', FeedbackOfficerController);

    FeedbackOfficerController.$inject = ['FeedbackOfficer'];

    function FeedbackOfficerController(FeedbackOfficer) {

        var vm = this;

        vm.feedbackOfficers = [];

        loadAll();

        function loadAll() {
            FeedbackOfficer.query(function(result) {
                vm.feedbackOfficers = result;
                vm.searchQuery = null;
            });
        }
    }
})();
