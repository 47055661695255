(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('project', {
            parent: 'entity',
            url: '/project',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT'],
                pageTitle: 'investhryApp.project.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/project/projects.html',
                    controller: 'ProjectController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('project');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('project-detail', {
            parent: 'project',
            url: '/project/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN'],
                pageTitle: 'investhryApp.project.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/project/project-detail.html',
                    controller: 'ProjectDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('project');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Project', function($stateParams, Project) {
                    return Project.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'project',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('project-detail.edit', {
            parent: 'project-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-dialog.html',
                    controller: 'ProjectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Project', function(Project) {
                            return Project.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('project.new', {
            parent: 'project',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-dialog.html',
                    controller: 'ProjectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                mouapplicable: null,
                                mousignyear: null,
                                mouidnumber: null,
                                firstname: null,
                                middlename: null,
                                lastname: null,
                                address1: null,
                                address2: null,
                                address3: null,
                                emailprimary: null,
                                emailsecondary: null,
                                userlogin: null,
                                cityname: null,
                                countryname: null,
                                statename: null,
                                createdate: null,
                                updatedate: null,
                                cafpin: null,
                                pincode: null,
                                phonenumber: null,
                                mobilenumber: null,
                                promoter_md_director: null,
                                designation: null,
                                businessentity: null,
                                director_promoter_md_ceo_number: null,
                                pan_number: null,
                                aadhar_number: null,
                                nri: null,
                                tin_vat_number: null,
                                cst_number: null,
                                businessentitytype: null,
                                gstnumber: null,
                                siteaddress: null,
                                multyvillageinvolved: null,
                                villageinvolved: null,
                                falls_in_aravalli: null,
                                islandprocured: null,
                                allotedbyhsiidc: null,
                                estate: null,
                                cluster: null,
                                phase: null,
                                sector: null,
                                plotno: null,
                                hadbastno: null,
                                liesunder_mc: null,
                                distance_from_mc: null,
                                islocated_in_urban: null,
                                totalproposedprojectarea: null,
                                proposedbuilt_up_area: null,
                                certifiedownership: null,
                                leaseapplicable: null,
                                landagreementapplicable: null,
                                intersectiondistance: null,
                                railwaydistance: null,
                                confirmitylanduse: null,
                                existing_building_applicable: null,
                                site_situated_in_controlled_area: null,
                                buildingexisted: null,
                                district: null,
                                block: null,
                                city_town_village: null,
                                connectingroad: null,
                                landzoneuse_type: null,
                                tehsil_subtehsil: null,
                                clurequired: null,
                                land_cost: null,
                                building_cost: null,
                                machinery_cost: null,
                                misc_assests: null,
                                total_project_cost: null,
                                isfdi: null,
                                fdivalue: null,
                                project_construction_start_date: null,
                                commercial_activity_start_date: null,
                                fdi_country: null,
                                foreign_funding_source: null,
                                totalpurposedemployment: null,
                                projectrawmaterialid: null,
                                productid: null,
                                processid: null,
                                manufacturing_flow_document: null,
                                temporaryrequired: null,
                                tem_load_existing: null,
                                tem_account_number: null,
                                temp_existing_load_demand_kw: null,
                                temp_existing_load_demand_kva: null,
                                temp_new_load_demand_kw: null,
                                temp_new_load_demand_kva: null,
                                temp_load_demand_date: null,
                                regular_load_required: null,
                                regular_existing_connection: null,
                                regular_account_number: null,
                                regular_existing_load_ifany_kw: null,
                                regular_existing_load_ifany_kva: null,
                                regular_new_load_demand_kw: null,
                                regular_new_load_demand_kva: null,
                                regular_load_demand_date: null,
                                customertype: null,
                                source_of_water_supply: null,
                                mode_of_disposal_for_discharge: null,
                                recycling_process: null,
                                recycling_cooling: null,
                                recycling_domestic: null,
                                recycling_other: null,
                                sourcewatersupplyother: null,
                                modedisposalother: null,
                                water_process: null,
                                water_cooling: null,
                                water_domestic: null,
                                water_other: null,
                                waste_water_process: null,
                                waste_water_cooling: null,
                                waste_water_domesting: null,
                                waste_water_other: null,
                                acceptance: null,
                                applicationdate: null,
                                place: null,
                                projectpurpose: null,
                                niccode: null,
                                existing_regulatory_approval: null,
                                edc_sif_clu_fee_paid_applicable: null,
                                approval_application_form: null,
                                category_of_project: null,
                                collaboration_with_foreign_country: null,
                                projectype: null,
                                sectorname: null,
                                size_of_industry: null,
                                sectorother: null,
                                assignedRM: null,
                                isdeactivated: null,
                                deactivatedby: null,
                                deactivateddate: null,
                                projectlevel: null,
                                isdraft: null,
                                caftype: null,
                                landrequired: null,
                                plotsize: null,
                                estatename: null,
                                purchaserate: null,
                                estatelocation: null,
                                agencyname: null,
                                village: null,
                                tehsil: null,
                                landusetype: null,
                                landrequirementindistrict: null,
                                product: null,
                                uamnumber: null,
                                nri_investment: null,
                                foreign_investment: null,
                                isulb: null,
                                mc: null,
                                blockCategory: null,
                                investmentType: null,
                                otherInvestmetType: null,
                                otherFDI: null,
                                prodctionStartDate: null,
                                haryanadomicile: null,
                                nonharyanadomicile: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('project', null, { reload: 'project' });
                }, function() {
                    $state.go('project');
                });
            }]
        })
        .state('project.edit', {
            parent: 'project',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-dialog.html',
                    controller: 'ProjectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Project', function(Project) {
                            return Project.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('project', null, { reload: 'project' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('project.delete', {
            parent: 'project',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project/project-delete-dialog.html',
                    controller: 'ProjectDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Project', function(Project) {
                            return Project.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('project', null, { reload: 'project' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
