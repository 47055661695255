(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcOpenDashboardController', HepcOpenDashboardController);

    HepcOpenDashboardController.$inject = ['$scope', 'Tickets', '$window', 'TicketsPagination', '$filter', 'TicketsLogs', 'downloadticketattachment', 'TicketsMean'];

    function HepcOpenDashboardController($scope, Tickets, $window, TicketsPagination, $filter, TicketsLogs, downloadticketattachment, TicketsMean) {
        var vm = this;
        vm.getLogs=getLogs;
        vm.loadAll=loadAll;
        vm.filterAll=filterAll;
        vm.tickets = [];
        vm.tickets1 = [];
        vm.filterBy="All";
        vm.page = 0;
        vm.downloadAttachment=downloadAttachment;
        vm.loadAllTickets = loadAllTickets;
        vm.download_all_table_data = download_all_table_data;
        loadAllTickets(vm.page);
        loadAll();
        $scope.CurrentDate = new Date();


        function loadAll() {
            TicketsMean.get(function(result) {
                vm.tickets1 = result;
                if(vm.tickets1.median === "0"){
                    vm.tickets1.median = 1;
                }
            });
        }



        function convertJsonsToArrays(args){
            var dataArray = [];
            var header = [ "Ticket Number",
                "Subject",
                "Category",
                "Department",
                "Assigned Member",
                "Priority",
                "Creation By",
                "Creation On",
                "Closing Date",
                "Ticket Status"
            ];
            dataArray.push(header);
            for( var i = 0; i < args.length; i++ ){
                var row = args[i];
                var rowAr=[];
                rowAr.push(row.ticketNumber);
                rowAr.push(row.subject);
                rowAr.push(row.incidentCategory);
                rowAr.push(row.assignedDepartmentName);
                rowAr.push(row.assignedMemberUserName);
                rowAr.push(row.priority);
                rowAr.push(row.createdByName);
                rowAr.push(row.creationDate);
                rowAr.push(row.resolveDate);
                rowAr.push(row.status);
                dataArray.push(rowAr);
            }
            return dataArray;
        }

        function download_all_table_data(args) {
                var rowsCleared= convertJsonsToArrays(args);
                var lineArray = [];
                var lineArrayCleared = [];


                for(var i = 0; i < rowsCleared.length; i++){
                    var lineCleared = rowsCleared[i].join("\t");
                    lineArrayCleared.push(lineCleared);
                }
                lineArrayCleared = lineArrayCleared.join("\n");


            var title = [];
            var rows = [];
            title = title.join(",");
            rows = rows.join("\n");
            var csv = title + rows;
            var blob = new Blob([lineArrayCleared],{type: 'data:text/csv;charset=utf-8,'+ encodeURIComponent(csv)});
            var url = URL.createObjectURL(blob);
            $window.location.href = url;

        }

        function loadAllTickets(page) {
            if(page >= 0) {
                vm.page = page;
                TicketsPagination.get({page: page, size: 200}, function(result) {
                    vm.tickets = result.content;
                    vm.ticketList=vm.tickets;
                    vm.openTickets = $filter('filter')(vm.tickets, { status: 'Open'});
                    vm.closedTickets = $filter('filter')(vm.tickets, { status: 'Closed'});
                    vm.timeoutLevel = $filter('filter')(vm.tickets, { level: 'LEVEL_3'});
                    vm.timeoutTickets = $filter('filter')(vm.timeoutLevel, { incidentCategory: '!Grievances'});
                    vm.technical = $filter('filter')(vm.tickets, { incidentCategory: 'Technical'});
                    vm.processRelated = $filter('filter')(vm.tickets, { incidentCategory: 'Process Related'});
                    vm.grievances = $filter('filter')(vm.tickets, { incidentCategory: 'Grievances'});
                    vm.suggestion = $filter('filter')(vm.tickets, { incidentCategory: 'Suggestion/Feedback'});
                    vm.generalEnquiry = $filter('filter')(vm.tickets, { incidentCategory: 'General Enquiry'});
                    vm.incentives = $filter('filter')(vm.tickets, { incidentCategory: 'Incentives'});
                    vm.hobPas = $filter('filter')(vm.tickets, { incidentCategory: 'HOBPAS'});
                });
            }
        }

        function filterAll(filterTickets) {
            if (filterTickets==="All"){
                vm.ticketList = vm.tickets;
                vm.filterBy="All";
            } else if(filterTickets==="Open"){
                vm.ticketList = vm.openTickets;
            } else if(filterTickets==="Closed"){
                vm.ticketList = vm.closedTickets;
            } else if(filterTickets==="Timeout"){
                vm.ticketList = vm.timeoutTickets;
            } else if(filterTickets==="Technical"){
                vm.ticketList = vm.technical;
            } else if(filterTickets==="Process Related"){
                vm.ticketList = vm.processRelated;
            } else if(filterTickets==="Grievances"){
                vm.ticketList = vm.grievances;
            } else if(filterTickets==="Suggestion/Feedback"){
                vm.ticketList = vm.suggestion;
            } else if(filterTickets==="General Enquiry"){
                vm.ticketList = vm.generalEnquiry;
            } else if(filterTickets==="Incentives"){
                vm.ticketList = vm.incentives
            }
            else if(filterTickets==="HOBPAS"){
                vm.ticketList = vm.hobPas;
            }
        }

        function getLogs(ticketDetails) {
            TicketsLogs.query({ticketNumber: ticketDetails.ticketNumber},function (result) {
                vm.ticketsLogs=result;
            })
        }

        function downloadAttachment(attachment) {
            downloadticketattachment.download(attachment)
                .then(function(success) {}, function(error) {
                    alert('error : ' + error);
                });
        }
    }
})();
