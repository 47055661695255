(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('RejectionReason', RejectionReason);

    RejectionReason.$inject = ['$resource'];

    function RejectionReason ($resource) {
        var resourceUrl =  '/api/projectServiceLog/:status';


        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
            },
            'update': { method:'PUT' }
        });
    }
})();
