(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CafsfornodalofficerDetailController', CafsfornodalofficerDetailController);

    CafsfornodalofficerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Cafsfornodalofficer'];

    function CafsfornodalofficerDetailController($scope, $rootScope, $stateParams, previousState, entity, Cafsfornodalofficer) {
        var vm = this;

        vm.cafsfornodalofficer = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:cafsfornodalofficerUpdate', function(event, result) {
            vm.cafsfornodalofficer = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
