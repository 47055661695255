(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewDasboardStatsController', NewDasboardStatsController);

    NewDasboardStatsController.$inject = ['NewDasboardStats'];

    function NewDasboardStatsController(NewDasboardStats) {

        var vm = this;

        vm.newDasboardStats = [];

        loadAll();

        function loadAll() {
            NewDasboardStats.query(function(result) {
                vm.newDasboardStats = result;
                vm.searchQuery = null;
            });
        }
    }
})();
