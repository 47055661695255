(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('assignProjectToRMController', assignProjectToRMController);

    assignProjectToRMController.$inject = ['$scope','$filter','Principal', 'LoginService', '$state','Projectlist', 'unassignedProject', 'assignedProjects','Rmlist'];

    function assignProjectToRMController ($scope,$filter, Principal, LoginService, $state,Projectlist, unassignedProject, assignedProjects,Rmlist) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.rmlists = {};
        vm.assigned = false;
        vm.pagination = false;
        vm.projectlist = null;
        vm.getAssignedProject =getAssignedProject;
        vm.getUnassignedProject = getUnassignedProject;
        vm.getAssignedProjectByRm = getAssignedProjectByRm;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        $scope.currentPage = 1;
        $scope.itemsPerPage = 1000;

        vm.changePage = function (index) {
            $scope.currentPage = index+1;
        };

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                getUnassignedProject();
            });
        }

        loadAll();

        function loadAll() {
            Rmlist.query(function(result) {
                vm.rmlists = result;
                vm.searchQuery = null;
            });
        }

        function getAssignedProject() {
            vm.assigned = true;
            vm.pagination = true;
            vm.projectlist = null;
            vm.title ="";
            vm.selectedRm = null ;
            assignedProjects.query(function(result){
                vm.projectlist=result;
                vm.totalAssignedProject = result.length;
                $scope.maxSizeArray = function() {
                    $scope.maxPageRound = Math.round(result.length/$scope.itemsPerPage);
                    $scope.maxPageAbs = Math.abs(result.length/$scope.itemsPerPage);
                    if($scope.maxPageRound > $scope.maxPageAbs){
                        return new Array($scope.maxPageRound);
                    }
                    else {
                        return new Array($scope.maxPageRound + 1);
                    }
                };
                vm.title = "Total Asssigned Project";
            });
        }
        function getAssignedProjectByRm(value) {
            vm.pagination = false;
             vm.projectlistByRM = [];
                vm.projectlistByRM=$filter('filter')(vm.projectlist,{assignedToRM : value});

        }

        function getUnassignedProject() {
            vm.assigned = false;
            vm.pagination = false;
            vm.projectlistByRM = [];
            vm.title = "";
            unassignedProject.query(function(result){
                vm.projectlistByRM=result;
                vm.totalUnassignedProject = vm.projectlistByRM.length;
                vm.title = "Total Unasssigned Project";
            });
        }

        function register () {
            $state.go('register');
        }
    }
})();
