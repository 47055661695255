(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ExciseServiceFormFilledController', ExciseServiceFormFilledController);

    ExciseServiceFormFilledController.$inject = ['ExciseServiceFormFilled'];

    function ExciseServiceFormFilledController(ExciseServiceFormFilled) {

        var vm = this;

        vm.exciseServiceFormFilleds = [];

        loadAll();

        function loadAll() {
            ExciseServiceFormFilled.query(function(result) {
                vm.exciseServiceFormFilleds = result;
                vm.searchQuery = null;
            });
        }
    }
})();
