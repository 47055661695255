(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DisputeController', DisputeController);

    DisputeController.$inject = ['Dispute'];

    function DisputeController(Dispute) {

        var vm = this;

        vm.disputes = [];

        loadAll();

        function loadAll() {
            Dispute.query(function(result) {
                vm.disputes = result;
                vm.searchQuery = null;
            });
        }
    }
})();
