(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerServiceDashboardController', TowerServiceDashboardController);

    TowerServiceDashboardController.$inject = ['Tower_service_dashboard','TowerServiceForDashboard'];

    function TowerServiceDashboardController(Tower_service_dashboard, TowerServiceForDashboard) {

        var vm = this;

        vm.tower_service_dashboards = [];

        vm.merged = 'mergedDepartments';

        loadAll();

        function loadAll() {
            TowerServiceForDashboard.query({merged :'mergedDepartments'},function(result) {
                vm.tower_service_dashboards = result;
                vm.searchQuery = null;
            });
        }
    }
})();
