(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ResolveDisputeController', ResolveDisputeController);

    ResolveDisputeController.$inject = ['$scope', '$state','$uibModalInstance', 'Principal', 'Dispute','dispute','Disputelog','FileManagement'];

    function ResolveDisputeController ($scope, $state, $uibModalInstance, Principal,  Dispute,dispute ,Disputelog ,FileManagement) {
        var vm = this;
       vm.dispute = dispute;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.register = register;

        $scope.file={};

        vm.clear = clear;
        vm.saveResolveData = saveResolveData;
        vm.disputelog={};

        function saveResolveData()
        {
            console.log(vm.dispute);
            vm.dispute.isResolved=true;
            Dispute.update(vm.dispute, onSaveSuccess);
        }
        function onSaveSuccess(result)
        {
            vm.disputelog.disputeID = vm.dispute.disputeID;
            vm.disputelog.disputeMainId = vm.dispute.id;
            vm.disputelog.action = "Resolved";
            if(angular.isDefined(vm.projectAttachemnt)){
                var fileNameArray = vm.projectAttachemnt.file.name.split(".");
                if ((fileNameArray.length == 2)) {
                    if(fileNameArray[0] != ""){
                        Disputelog.save(vm.disputelog, onServiceLogSaveSuccess);

                    }
                    else {
                        alert("Invalid File Name");
                    }
                }
                else{
                    alert("Please select correct file format")
                }
            }
            else {
                Disputelog.save(vm.disputelog, onServiceLogSaveSuccess);

            }
        }
        function onServiceLogSaveSuccess(result){

            if(angular.isDefined(vm.projectAttachemnt)) {

                // $scope.$emit('investhryApp:projectAttachemntUpdate', result);
                var filename = result.id;
                var file = vm.projectAttachemnt.file;
                FileManagement.saveFile(file, filename);

                var fileNameArray = vm.projectAttachemnt.file.name.split(".");
                var fileextension = "";
                if ((fileNameArray.length > 1)) {
                    fileextension = fileNameArray[(fileNameArray.length - 1)];
                }

                var inputFileName = result.id;
                if (fileextension !== "") {
                    inputFileName = inputFileName + "." + fileextension;
                }
                vm.disputelog.id = result.id;
                vm.disputelog.fileName = inputFileName;
                console.log(inputFileName);
                console.log(vm.disputelog);

                if (vm.disputelog.id !== null) {
                    Disputelog.update(vm.disputelog, onSaveSuccessLog);
                } else {
                    Disputelog.save(vm.disputelog, onSaveSuccessLog);
                }
            }
            $uibModalInstance.dismiss('cancel');

        }
        function clear ()
        {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccessLog ()
        {
            $state.go('disputes');
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
