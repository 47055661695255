(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sentTickets', {
            parent: 'hepcticket',
            url: '/sentTickets',
            data: {
                authorities: ["ROLE_L1_SUPPORT", "ROLE_L2_IT_MANAGER", "ROLE_L2_DEPARTMENT", "ROLE_L2_DEPARTMENT_HEAD", "ROLE_L2_INCENTIVES", "ROLE_TICKET_NODAL_OFFICER", "ROLE_TICKET_OPERATION_MANAGER", "ROLE_NODAL_OFFICER", "ROLE_ADMIN", "ROLE_SUB_ADMIN"]
            },
            views: {
                'content_section@hepcticket': {
                    templateUrl: 'app/hepcTickets/sendTickets/sentTickets.html',
                    controller: 'SentTicketsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
