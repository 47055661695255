(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('UserFeedbackController', UserFeedbackController);

    UserFeedbackController.$inject = ['UserFeedback'];

    function UserFeedbackController(UserFeedback) {

        var vm = this;

        vm.userFeedbacks = [];

        loadAll();

        function loadAll() {
            UserFeedback.query(function(result) {
                vm.userFeedbacks = result;
                vm.searchQuery = null;
            });
        }
    }
})();
