(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SkillsDataController', SkillsDataController);

    SkillsDataController.$inject = ['SkillsData'];

    function SkillsDataController(SkillsData) {

        var vm = this;

        vm.skillsData = [];

        loadAll();

        function loadAll() {
            SkillsData.query(function(result) {
                vm.skillsData = result;
                vm.searchQuery = null;
            });
        }
    }
})();
