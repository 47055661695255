(function() {
    'use strict';

    angular
        .module('investhryApp')
        .factory('CheckContent', CheckContent);

    CheckContent.$inject = [];

    function CheckContent () {
        var service = {
            check: check
        };

        return service;

        function check (elem) {
            elem.bind("propertychange change click keyup input paste", function(){
                if(elem.val().includes('<script') || elem.val().includes('alert(') || elem.val().includes('.js') || elem.val().includes('.exe')
                    || elem.val().includes('.jar')
                    || elem.val().includes('bash') || elem.val().includes('./') || elem.val().includes('.tar.gz') || elem.val().includes('.deb')
                    || elem.val().includes('.rpm') || elem.val().includes('.ko') || elem.val().includes('.php') || elem.val().includes('.py')
                    || elem.val().includes('.tar'))
                {
                    elem.val('');
                    alert('Please insert relevant information');
                }
            });
        }
    }
})();
