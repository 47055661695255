(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('allclearancesController', allclearancesController);

    allclearancesController.$inject = ['$scope', '$stateParams','Principal', 'LoginService', '$state', 'Clearance'];

    function allclearancesController ($scope, $stateParams, Principal, LoginService, $state, Clearance) {
        var vm = this;
        vm.answerspreestablish=[];
        vm.answerspostestablish=[];
        vm.answerspreoperational=[];
        loadAll()
        function loadAll(){
            ga('set', 'page', '/Know-Your-Clearance-All-Clearances');ga('send','pageview');
        }

            var Str_txt2={
                "clearance": "Building Plan Approval",
                "stage": 2,
                "department": "Town and Country Planning",
                "url": "allpage24"
            };
            vm.answerspreoperational.push(Str_txt2);

            var Str_txt2={
                "clearance": "Building Plan Approval",
                "stage": 2,
                "department": "HSIIDC",
                "url": "allhsiidc2"
            };
            vm.answerspreoperational.push(Str_txt2);

            var Str_txt5={
                "clearance": "Release of permanent sewarage connection",
                "stage": 3,
                "department": "HSIIDC",
                "url": "allhsiidc4"
            };
            vm.answerspreoperational.push(Str_txt5);

//        if(vm.questionnaire.typeofestablishment=='Post-Establishment')
//        {
            Str_txt={
                "clearance": "Factory registration/ license under Factories Act, 1948-Safety Wing",
                "stage": '',
                "department": "Labour department",
                "url": "allfactlicense"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt={
                "clearance": "Occupation/completion certificate",
                "stage": 3,
                "department": "Town and Country Planning",
                 "url":"allpage23"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt = {
                "clearance": "Change of land use Within Municipal Limit",
                "stage": 1,
                "department": "Urban & local Bodies",
                "url": ""
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Occupation/completion certificate",
                "stage": 3,
                "department": "Urban & local Bodies",
                 "url":"alltable36"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Certification of Electrical installation by Chief Electrical Inspector",
                "stage": 3,
                "department": "Power Department(11kv to 33kv:- certification by Charted Engineer / Chief elcetrical Engineer, " +
                "Above 33 kv:- Certification Only by Chief Electical Engineer of Department)",
                "url": ""
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Establishment inter state migrant workmen (RE&CS) Act 1979",
                "stage": 3,
                "department": "labour department",
                "url":"alltable48"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Registration of the Principal Employer (Who is engaging the workers)under the Contracts Labour (Regulation & Abolition)Act,1970",
                "stage": 3,
                "department": "labour department",
                "url":"alltable43"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Registration under boiler act",
                "stage": 3,
                "department": "Industries and commerce department",
                "url": "allinds1"
             };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Drug license for allopathic medicine",
                "stage": 3,
                "department": "Food and Drug Administration",
                "url":"allpage60"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Drug license for ayurvedic medicine",
                "stage": 3,
                "department": "Ayush department",
                "url":"allpage58"
            };
            vm.answerspostestablish.push(Str_txt);


            Str_txt=    {
                "clearance": "Lubricant license",
                "stage": 3,
                "department": "Industries and commerce department",
                "url": "allinds2"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "License to manufacture Insecticides/Pesticides",
                "stage": 1,
                "department": "Agriculture department",
                "url": "alltable54"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Licence for manufacturing fertilizer",
                "stage": 1,
                "department": "Agriculture department",
                "url":"alltable53"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Consent to operate under Water Act, 1974 and the Air Act, 1981",
                "stage": 3,
                "department": "HSPCB",
                "url":"allpage3"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Registration/ Authorization under e-waste (Management & Handing) Rules, 2011",
                "stage": 3,
                "department": "Haryana state pollution controll board",
                "url": "allpage4"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Registration under hazardous waste rule 2016",
                "stage": 3,
                "department": "Haryana state pollution controll board",
                "url":"allpage5"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Authorization under bio medical waste ( management & handling )rules, 2016",
                "stage": 3,
                "department": "Haryana state pollution controll board",
                "url": "allpage7"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt=    {
                "clearance": "Release of permanent/regular electric connection,Indian electricity Act, 2003",
                "stage": 1,
                "department": "UHBVN/DHBVN",
                "url":"allpage14"
            };
            vm.answerspostestablish.push(Str_txt);

            Str_txt={
                "clearance": "Issue of completion/occupation certificate",
                "stage": 3,
                "department": "HSIIDC",
                "url": "allhsiidc1"
            };
            vm.answerspostestablish.push(Str_txt);

//        }

//        if(vm.questionnaire.typeofestablishment=='Pre-Establishment')
//        {
            Str_txt={
                "clearance": "NOC for fire service",
                "stage": '',
                "department": "Urban Local Bodies",
                "url": "alltable33"
            };
            // vm.answerspreestablish.push(Str_txt);
            // Str_txt={
            //     "clearance": "	Forest clearance for approach public entry/exit and diversion of forest land-under Forest Conservation Act,1980",
            //     "stage": '',
            //     "department": "Forest Department",
            //     "url": "allpage26"
            // };
            vm.answerspreestablish.push(Str_txt);
            Str_txt={
                "clearance": "Approval of factory plan under the Factories Act, 1948",
                "stage": '',
                "department": "Labour department",
                "url": "alltable45"
            };
            vm.answerspreestablish.push(Str_txt);

                var Str_txt3=    {
                    "clearance": "Consent for establishment under Water Act, 1974 and the Air Act, 1981",
                    "stage": 1,
                    "department": "HSPCB",
                    "url":"allpage1"
                };
                vm.answerspreestablish.push(Str_txt3);


                var Str_txt1 = {
                    "clearance": "Change of land use under Controlled Area Act 1963",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url":"allpage16"
                };
                vm.answerspreestablish.push(Str_txt1);


                var Str_txt3=    {
                    "clearance": "License for brick klin",
                    "stage": 3,
                    "department": "Town and Country Planning",
                    "url": "allpage17"
                };
                vm.answerspreestablish.push(Str_txt3);

                var Str_txt1 = {
                    "clearance": "NOC for establishment of unit under Urban Area Act",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url": "allpage21"
                };
                vm.answerspreestablish.push(Str_txt1);

                var Str_txt1= {
                    "clearance": "NOC for establishment of unit under Urban Area Act",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url":"allpage22"
                };
                vm.answerspreestablish.push(Str_txt1);

                var Str_txt3=    {
                    "clearance": "Environmental clearance (EC) under Aravali Notification 1992 & 1999",
                    "stage": 1,
                    "department": "Enviornment Department",
                    "url":"allpage56"
                };
                vm.answerspreestablish.push(Str_txt3);

                Str_txt={
                    "clearance": "Shop registration under Shops and Establishment Act-Labour Wing",
                    "stage": '',
                    "department": "Labour",
                    "url": "alltable47"
                };
                vm.answerspreestablish.push(Str_txt);

                var Str_txt={
                    "clearance": "Registration of Firms under Indian Partnership Act. 1932",
                    "stage": 1,
                    "department": "Industries & Commerce Department",
                    "url": "allinds4"
                };
                vm.answerspreestablish.push(Str_txt);


                var Str_txt1={
                    "clearance": "Allotment of Plot",
                    "stage": 1,
                    "department": "HSIIDC",
                    "url": "allhsiidc5"
                };
                vm.answerspreestablish.push(Str_txt1);
                var Str_txt3={
                    "clearance": "Release of permanent water connection",
                    "stage": 2,
                    "department": "HSIIDC",
                    "url": "allhsiidc3"
                };
                vm.answerspreestablish.push(Str_txt3);

                var Str_txt7={
                    "clearance": "Issuance of Trade License Under section 330 for factory, workshop or trade remises. In which it is intended to employ steam, electricity, water or other mechanical power.",
                    "stage": 3,
                    "department": "Urban & local Bodies",
                    "url":"alltable34"
                };
                vm.answerspreestablish.push(Str_txt7);

                var Str_txt2= {
                        "clearance": "Approval of building plan",
                        "stage": 2,
                        "department": "Urban & local Bodies",
                        "url": "alltable37"
                    };
                vm.answerspreestablish.push(Str_txt2);
                var Str_txt4= {
                        "clearance": "Issuance of Water connection",
                        "stage": 2,
                        "department": "Urban & local Bodies",
                        "url": ""
                    };
                vm.answerspreestablish.push(Str_txt4);

                var Str_txt6=   {
                        "clearance": "Issuance of Trade License Under section 330 for factory, workshop or trade remises. In which it is intended to employ steam, electricity, water or other mechanical power.",
                        "stage": 3,
                        "department": "Urban & local Bodies",
                        "url": "alltable34"
                    };
                vm.answerspreestablish.push(Str_txt6);

                var Str_txt8=    {
                    "clearance": "Issuance of sewarage connection",
                    "stage": 3,
                    "department": "Urban & local Bodies",
                    "url": "alltable41"
                };
                vm.answerspreestablish.push(Str_txt8);

                var Str_txt1 = {
                    "clearance": "Change of land use Within Municipal Limit",
                    "stage": 1,
                    "department": "Urban & local Bodies",
                    "url": ""
                };
                vm.answerspreestablish.push(Str_txt1);
                var Str_txt2=    {
                    "clearance": "Approval of building plan",
                    "stage": 2,
                    "department": "Urban & local Bodies",
                    "url":"alltable37"
                };
                vm.answerspreestablish.push(Str_txt2);

                var Str_txt4= {
                    "clearance": "Issuance of Water connection",
                    "stage": 2,
                    "department": "Urban & local Bodies",
                    "url": ""
                };
                vm.answerspreestablish.push(Str_txt4);

                var Str_txt2=    {
                    "clearance": "Tree felling irrespective of location of land",
                    "stage": 1,
                    "department": "Forest Department",
                    "url": "allforest2"
                };
                vm.answerspreestablish.push(Str_txt2);

                var Str_txt3=    {
                    "clearance": "Release of permanent water connection",
                    "stage": 2,
                    "department": "HSIIDC",
                    "url": "allhsiidc3"
                };
                vm.answerspreestablish.push(Str_txt3);

                var Str_txt3=    {
                    "clearance": "Shop registration under Shops and Establishment Act",
                    "stage": 3,
                    "department": "labour department",
                    "url": "alltable47"
                };
                vm.answerspreestablish.push(Str_txt3);

                var Str_txt3=    {
                    "clearance": "Registration of Establishment engaged in Building & other Construction activity under Building and Other Construction Workers (RE&CS) Act, 1996",
                    "stage": 2,
                    "department": "Labour department",
                    "url": "alltable42"
                };
                vm.answerspreestablish.push(Str_txt3);

                var Str_txt3=    {
                    "clearance": "Environment clearance plan from State/Central Environment Impact Assessment Authority- EIA notification 2006.",
                    "stage": 1,
                    "department": "Enviornment Department",
                    "url":"allpage57"
                };
                vm.answerspreestablish.push(Str_txt3);

                var Str_txt3=    {
                    "clearance": "Release of Temporary Connection",
                    "stage": 1,
                    "department": "UHBVN/DHBVN",
                    "url":"alluhbvn2"
                };
                vm.answerspreestablish.push(Str_txt3);

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

    }
})();
