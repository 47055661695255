(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('DatewiseDeptDashboard', DatewiseDeptDashboard);

    DatewiseDeptDashboard.$inject = ['$resource'];

    function DatewiseDeptDashboard ($resource) {
        var resourceUrl =  'api/datewisedeptdashboard/:fromdate/:todate/:amount/:area/:caftype/:sectortype/:employees';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET'

                // uncomment for cassandra////////////
                // ,
                // transformResponse: function (data) {
                //     if (data) {
                //         data = angular.fromJson(data);
                //     }
                //     return data;
                // }
                ////////////////////////////////////////
            },
            'update': { method:'PUT' }
        });
    }
})();
