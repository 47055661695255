(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RmlistDeleteController',RmlistDeleteController);

    RmlistDeleteController.$inject = ['$uibModalInstance', 'entity', 'Rmlist','CheckRm','ReplaceRm'];

    function RmlistDeleteController($uibModalInstance, entity, Rmlist, CheckRm, ReplaceRm) {
        var vm = this;

        vm.rmlist = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.loadAll =loadAll;
        vm.replaceRm =replaceRm;

        loadAll();
        function loadAll(){
            CheckRm.get({ id : vm.rmlist.id}, function(res){
                vm.replacement=!(res.value);
            });
            Rmlist.query(function(result) {
                            vm.rmlists = result;
                            var list = vm.rmlists;
                                        vm.rmloginList = list.filter(function(obj){
                                            return obj.district=='Haryana';
                                        });
                            vm.searchQuery = null;

                       });

        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function replaceRm(id,newRm) {
            vm.rmForbidden =false;
            vm.showRMStatus =false;
            var newRm1 = newRm.split('.').join('#');
            ReplaceRm.get({rmOld : vm.rmlist.rmlogin, rmNew: newRm1 },function(result){
            vm.showConfirmation=true;
           }, function(err){
                if(err.status === 404){
                vm.showRMStatus=true;
               }
               if(err.status===403){
                vm.rmForbidden =true;
               }
           });
        }

        function confirmDelete (id) {
            Rmlist.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
