(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServicetokenDetailController', ServicetokenDetailController);

    ServicetokenDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Servicetoken'];

    function ServicetokenDetailController($scope, $rootScope, $stateParams, previousState, entity, Servicetoken) {
        var vm = this;

        vm.servicetoken = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:servicetokenUpdate', function(event, result) {
            vm.servicetoken = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
