(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'ParseLinks', 'AlertService', 'JhiLanguageService','User','CheckContent'];

    function UserManagementController(Principal, ParseLinks, AlertService, JhiLanguageService,User,CheckContent) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN','ROLE_SUB_ADMIN', 'ROLE_RM', 'ROLE_DESK_OFFICER', 'ROLE_NODAL_OFFICER', 'ROLE_ACCOUNT_OFFICER', 'ROLE_FIELD_OFFICER','ROLE_ASSIGNRM_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_API_VIEWER','ROLE_GRIEVANCE_OFFICER','ROLE_GRIEVANCE_ADMIN', 'ROLE_TOWER_DC', 'ROLE_TOWER_USER', 'ROLE_TOWER_NODAL_OFFICER', 'ROLE_TOWER_DEPARTMENT', 'ROLE_CPUSER', 'ROLE_TOWER_DIO','ROLE_TOWER_OFFICER','ROLE_DEPT_CHANGE','ROLE_USER_EXT','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER', 'ROLE_L1_SUPPORT', 'ROLE_L2_IT_MANAGER', 'ROLE_L2_INCENTIVES', 'ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_TICKET_OPERATION_MANAGER', 'ROLE_TICKET_NODAL_OFFICER', 'ROLE_FEEDBACK_OFFICER', 'ROLE_TICKET_ADMIN' ,'ROLE_SKILLS_REQUIREMENT_OFFICER','ROLE_CAF_STATUS_OFFICER','ROLE_INCENTIVE_DISBURSEMENT_HEAD','ROLE_STATE_NODAL_OFFICER'];

        vm.currentAccount = null;
        vm.languages = null;
        vm.getUser = loadAll;
        vm.setActive = setActive;
        vm.users = undefined;

        $('input').each(function(){ CheckContent.check($(this))});
        $('textarea').each(function() { CheckContent.check($(this))});


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function setActive (user, isActivated) {
            user.activated = isActivated;
            User.update(user.body, function () {
            });
        }

        function loadAll () {
            vm.users=undefined;
            User.query({userlogin:vm.userinfo},onSuccess, onError);
        }

        function onSuccess(data, headers) {
            if (data.length<=0){
                AlertService.error("User Not Found");
                return;
            }
            vm.users=data;
        }

        function onError(error) {

        }
    }
})();
