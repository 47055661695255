(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('incentiveform', {
            parent: 'app',
            url: '/incentiveform',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/incentiveforms/incentiveform.html',
                    controller: 'IncentiveFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    .state('incentiveforms', {
            parent: 'app',
            url: '/incentiveforms',
            data: {
                authorities: ['ROLE_USER']
            },
        params: {
            param: ""
        },
            views: {
                'content@': {
                    templateUrl: 'app/incentiveforms/incentiveformNewScheme.html',
                    controller: 'IncentiveFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('incentiveformsmega', {
                    parent: 'app',
                    url: '/incentiveformsmega',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/incentiveforms/incentiveformsmega.html',
                            controller: 'IncentiveFormController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: [ function() {
                            return false;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).state('incentivedashboard', {
                    parent: 'app',
                    url: '/incentivedashboard',
                    data: {
                        authorities: []
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/incentiveforms/incentivedashboard.html',
                            controller: 'IncentiveFormController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                });
    }
})();
