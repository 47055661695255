(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('ProjectType', ProjectType);

    ProjectType.$inject = [];

    function ProjectType () {
        var data;
        return {
            putProjectType:function(projectType){
                data=projectType;
            },
            getProjectType:function(){
                return data;
            }
        }
    }
})();
