(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('reappliedservices', {
            parent: 'entity',
            url: '/reappliedservices',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.reappliedservices.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/reappliedservices/reappliedservices.html',
                    controller: 'ReappliedservicesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reappliedservices');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('reappliedservices-detail', {
            parent: 'reappliedservices',
            url: '/reappliedservices/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.reappliedservices.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/reappliedservices/reappliedservices-detail.html',
                    controller: 'ReappliedservicesDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reappliedservices');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Reappliedservices', function($stateParams, Reappliedservices) {
                    return Reappliedservices.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'reappliedservices',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('reappliedservices-detail.edit', {
            parent: 'reappliedservices-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/reappliedservices/reappliedservices-dialog.html',
                    controller: 'ReappliedservicesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Reappliedservices', function(Reappliedservices) {
                            return Reappliedservices.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('reappliedservices.new', {
            parent: 'reappliedservices',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/reappliedservices/reappliedservices-dialog.html',
                    controller: 'ReappliedservicesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                isRequired: null,
                                requireMarkedOnDate: null,
                                requireMarkedBy: null,
                                isAssigned: null,
                                assigOnDate: null,
                                assignBy: null,
                                formFilledStatus: null,
                                isPaymentMade: null,
                                isPaymentVerified: null,
                                formFilledOnDate: null,
                                formFilledBy: null,
                                paymentMadeOnDate: null,
                                status: null,
                                latestComments: null,
                                departmentName: null,
                                serviceName: null,
                                serviceStage: null,
                                isDimmed: null,
                                serviceDuration: null,
                                serviceFee: null,
                                lastactiondate: null,
                                statusdate: null,
                                isIntegrated: null,
                                integrationDate: null,
                                isDateExceeded: null,
                                url: null,
                                isShowchoosedept: null,
                                reApply: null,
                                constructionPermit: null,
                                reApplyCounter: null,
                                clearedDate: null,
                                rejectionDate: null,
                                deemedClearedDate: null,
                                reAppliedDate: null,
                                projectservicedetailId: null,
                                cafpin: null,
                                projectservicelogid: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('reappliedservices', null, { reload: 'reappliedservices' });
                }, function() {
                    $state.go('reappliedservices');
                });
            }]
        })
        .state('reappliedservices.edit', {
            parent: 'reappliedservices',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/reappliedservices/reappliedservices-dialog.html',
                    controller: 'ReappliedservicesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Reappliedservices', function(Reappliedservices) {
                            return Reappliedservices.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('reappliedservices', null, { reload: 'reappliedservices' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('reappliedservices.delete', {
            parent: 'reappliedservices',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/reappliedservices/reappliedservices-delete-dialog.html',
                    controller: 'ReappliedservicesDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Reappliedservices', function(Reappliedservices) {
                            return Reappliedservices.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('reappliedservices', null, { reload: 'reappliedservices' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
