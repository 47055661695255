(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('EstateDialogController', EstateDialogController);

    EstateDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Estate', 'District'];

    function EstateDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Estate, District) {
        var vm = this;

        vm.estate = entity;
        vm.clear = clear;
        vm.save = save;
        vm.district = [];


        loadAllDistricts();
        function loadAllDistricts() {
            District.query(function (result) {
                vm.districts=result;
                for( var i = 0; i < vm.districts.length-1; i++){
                    if ( vm.districts[i].districtname === 'Chandigarh HQ') {
                        vm.districts.splice(i, 1);
                    }
                }
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.estate.id !== null) {
                Estate.update(vm.estate, onSaveSuccess, onSaveError);
            } else {
                Estate.save(vm.estate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:estateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
