(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Tower_service_dashboardDeleteController',Tower_service_dashboardDeleteController);

    Tower_service_dashboardDeleteController.$inject = ['$uibModalInstance', 'entity', 'Tower_service_dashboard'];

    function Tower_service_dashboardDeleteController($uibModalInstance, entity, Tower_service_dashboard) {
        var vm = this;

        vm.tower_service_dashboard = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Tower_service_dashboard.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
