(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RequiredListController', RequiredListController);

    RequiredListController.$inject = ['$scope', '$stateParams','$state','$filter', 'Principal', 'Projectservicedetail', 'projectServiceLog', 'ProjectServiceLogUpdated', 'ProjectservicedetailbyProject', 'Department', 'TowerserviceNO','Municipals', 'TowerServiceNoRequire'];

    function RequiredListController ($scope, $stateParams, $state,$filter, Principal, Projectservicedetail, projectServiceLog, ProjectServiceLogUpdated, ProjectservicedetailbyProject, Department, TowerserviceNO,Municipals, TowerServiceNoRequire ) {
        var vm = this;
        vm.projectid = $stateParams.projectid;

        vm.projectServiceLog = projectServiceLog;
        vm.selectedprojectservice = {};
        vm.tower_departments = tower_departments;
        vm.saveTowerServiceNO = saveTowerServiceNO;
        vm.markRequired = markRequired;
        vm.municipals = [];
        $scope.requiredMunicipal = [];
        // vm.tower_departmentlist =[];

        $scope.required = {
            department: {},
            municipals: {}
        };

        $scope.clear = function () {
            $scope.required.belowMunicipal = null;
            $scope.required.department = [];
            $scope.requiredMunicipal = [];
            vm.aboveMunicipal = null;
        };
        $scope.cancel = function () {
            $scope.requiredMunicipal = [];
            $scope.required.department = [];
            vm.aboveMunicipal = null;
            $scope.required.belowMunicipal = null;

        };
        vm.towerserviceNO = {};

        getAccount();
        loadProjectServices();

        vm.selectedDepartment = function () {
            vm.department = vm.department || 'all';
            vm.constructionpermit = vm.constructionpermit || 'all';

            vm.projectservicelist.projectservicedetailDTOS = [];
            if (vm.department === 'all' && vm.constructionpermit === 'all') {
                vm.projectservicelist.projectservicedetailDTOS = vm.tempDataHandler.projectservicedetailDTOS;
                return;
            }
            else if (vm.department === 'all' && vm.constructionpermit !== 'all') {
                for (var i = 0; i < vm.tempDataHandler.projectservicedetailDTOS.length; i++) {
                    if (vm.tempDataHandler.projectservicedetailDTOS[i].constructionPermit === vm.constructionpermit) {
                        vm.projectservicelist.projectservicedetailDTOS.push(vm.tempDataHandler.projectservicedetailDTOS[i])
                    }
                }
            }
            else if (vm.department !== 'all' && vm.constructionpermit === 'all') {
                for (var i = 0; i < vm.tempDataHandler.projectservicedetailDTOS.length; i++) {
                    if (vm.tempDataHandler.projectservicedetailDTOS[i].departmentName === vm.department) {
                        vm.projectservicelist.projectservicedetailDTOS.push(vm.tempDataHandler.projectservicedetailDTOS[i])
                    }
                }
            } else {

                for (var i = 0; i < vm.tempDataHandler.projectservicedetailDTOS.length; i++) {
                    if (vm.tempDataHandler.projectservicedetailDTOS[i].departmentName === vm.department && vm.tempDataHandler.projectservicedetailDTOS[i].constructionPermit === vm.constructionpermit) {
                        vm.projectservicelist.projectservicedetailDTOS.push(vm.tempDataHandler.projectservicedetailDTOS[i])
                    }
                }
            }
        };


        vm.updateCheck = function (check) {
            $scope.required.department = [];
            $scope.required.department.push(check);
            if (check === 'MUNICIPAL COUNCIL' || check === 'MUNICIPAL COMMITTEE' || check === 'MUNICIPAL CORPORATION') {
                alert('Kindly exercise due diligence before selecting the entity under which your tower land falls, since the fee regarding the same shall be directly transferred to the selected entity only');
                Municipals.query({district: $stateParams.district, name: $scope.required.department}, function (result) {
                    vm.municipals = result;
                    vm.searchQuery = null;
                });
                $('#tower_department1').modal('hide');
                $('#YourModalId').modal('show');
            }
        };

        vm.BelowGround = function (check) {
            if (check === 'MUNICIPAL COUNCIL' || check === 'MUNICIPAL COMMITTEE' || check === 'MUNICIPAL CORPORATION') {
                Municipals.query({district: $stateParams.district, name: check}, function (result) {
                    vm.municipals = result;
                    vm.searchQuery = null;
                // )};
                    if($scope.required.department.indexOf(check) != -1) {
                        $('#tower_department').modal('hide');
                        $('#belowGround').modal('show');
                    } else {
                        for (var im = 0; im < vm.municipals.length; im++) {
                            var indexvalue = $scope.requiredMunicipal.indexOf(vm.municipals[im].name) ;
                            if(indexvalue != -1) {
                                $scope.requiredMunicipal.splice(indexvalue, 1);
                            }
                        }
                    }
                });
            }
        };

        // vm.updateMunicipalForBelow = function (munici) {
        //     $scope.required.belowMunicipal = null;
        //     $scope.required.belowMunicipal = munici;
        // };

        // vm.updateMunicipal = function (munici) {
        //     // $scope.requiredMunicipal=[];
        //     $scope.requiredMunicipal = null;
        //     $scope.requiredMunicipal = munici;
        //
        // };

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.id = vm.account.id;
            });
        }

        function loadProjectServices() {
            ProjectservicedetailbyProject.get({id: vm.projectid, status: 'required'}, function (result) {
                vm.projectservicelist = result;
            });
        }

        vm.markRequiredForBelow = function (service, department, municipal) {
            for (var i = 0; i < municipal.length; i++) {
                if ($scope.requiredMunicipal.indexOf(municipal[i]) < 0) {
                    $scope.requiredMunicipal.push(municipal[i]);
                }
            }
            $scope.required.belowMunicipal =null;

            $('#belowGround').modal('hide');
            $('#tower_department').modal('show');
        };

        vm.markRequiredForMunicipal = function (service, department, municipal) {
            if ($scope.requiredMunicipal.indexOf(municipal) < 0) {
                $scope.requiredMunicipal.push(municipal);
            }
            // $('#tower_department1').modal('hide');
            markRequired(service, department);
            $('#YourModalId').modal('hide');

        };

        function markRequired(projectservice, departmentName) {
            vm.isSaving = true;
            vm.selectedprojectservice = projectservice;
            projectservice.isRequired = true;
            if (departmentName != null) {
                projectservice.departmentName = "" + departmentName;
            }

            vm.towerServiceNORequire = {};
            vm.towerServiceNORequire.projectId = projectservice.projectid;
            vm.towerServiceNORequire.serviceId = projectservice.serviceid;
            vm.towerServiceNORequire.officerList = $scope.required.department || [];
            if ($scope.requiredMunicipal.length != 0) {
                for (var i = 0; i < $scope.requiredMunicipal.length; i++) {
                    if (vm.towerServiceNORequire.subname) {
                        vm.towerServiceNORequire.subname = vm.towerserviceNO.subname + "," + $scope.requiredMunicipal[i];
                    } else {
                        vm.towerServiceNORequire.subname = $scope.requiredMunicipal[i];
                    }
                }

            }
            if (Object.keys($scope.required.department).length === 0) {
                Projectservicedetail.save(projectservice, onMarkSuccess, onMarkError);
            } else {
                TowerServiceNoRequire.save(vm.towerServiceNORequire, function () {
                    Projectservicedetail.save(projectservice, onMarkSuccess, onMarkError);
                });
            }
        }

        function onMarkSuccess(result) {
            if (vm.required_type === 'tower') {
                saveTowerServiceNO(result.id);
                $scope.clear();
            }
            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            onSaveSuccess();
        }

        function onSaveSuccess(result) {
            $scope.$emit('investhryApp:projectServiceReportInfoUpdate', result);
            vm.projectServiceLog.projectid = vm.selectedprojectservice.projectid;
            vm.projectServiceLog.serviceid = vm.selectedprojectservice.serviceid;
            vm.projectServiceLog.comments = "MarkedRequired";
            vm.projectServiceLog.actionTaken = "MarkedRequired";
            ProjectServiceLogUpdated.save(vm.projectServiceLog, onServiceLogSaveSuccess, onServiceLogSaveError);
        }

        function onMarkError() {
        }

        function onServiceLogSaveSuccess(result) {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            vm.isSaving = false;
        }

        function onServiceLogSaveError() {
        }


        function tower_departments(service, type,name) {
            $scope.required.department = [];
            vm.tower_service = service;
            vm.required_type = type;
            Department.query(function (result) {
                vm.tower_departmentlist = result;
            });
            if(name==='above'){
                $('#tower_department1').modal('show');
            }
            if(name==='below'){
                $('#tower_department').modal('show');
            }
        }


        function saveTowerServiceNO(id) {
            vm.towerserviceNO.projectdetailId = id;
            vm.towerserviceNO.district = $stateParams.district;
            vm.towerserviceNO.officerList = $scope.required.department;
            if ($scope.requiredMunicipal.length != 0) {
                for (var i = 0; i < $scope.requiredMunicipal.length; i++) {
                    if (vm.towerserviceNO.subname) {
                        vm.towerserviceNO.subname = vm.towerserviceNO.subname + "," + $scope.requiredMunicipal[i];
                    } else {
                        vm.towerserviceNO.subname = $scope.requiredMunicipal[i];
                    }
                }

            }
            TowerserviceNO.save(vm.towerserviceNO, saveSuccess);

            vm.towerserviceNO = {};
        }

        function saveSuccess(result) {
            vm.towerserviceNO = {};
            $scope.requiredMunicipal = [];
            $scope.required.department = [];
            vm.aboveMunicipal = null;
        }

        vm.closeModal = function () {
            vm.aboveMunicipal = null;
            $scope.requiredMunicipal = [];
            $scope.required.belowMunicipal =null;
            $('#YourModalId').modal('hide');
            $('#tower_department').modal('hide');
        };

        vm.closeBelow = function () {
            $scope.requiredMunicipal = [];
            $scope.required.belowMunicipal =null;
            vm.aboveMunicipal = null;
            $('#belowGround').modal('hide');
            $('#tower_department1').modal('hide');

        }
    }

})();
