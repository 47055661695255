(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewDuplicatesController', NewDuplicatesController);

    NewDuplicatesController.$inject = ['User'];

    function NewDuplicatesController (User) {
        var vm = this;

        vm.user = {};

        load();
        function load () {
            User.query({newDuplicates: ''}, function(result) {
                vm.user = result;
            });
        }
    }
})();
