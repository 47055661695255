(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CafSummaryController', CafSummaryController);
    CafSummaryController.$inject = ['$window','$scope', 'Principal','$filter', 'LoginService', '$state', '$stateParams','Projectlist','downloadService', 'Projectlog', 'Projectdetailupdated', 'Projectlogupdated', 'Project', 'ConstructionPermitApprovalSteps', 'ConstructionPermitApproval', 'AddCAF', 'projectLog', 'ProjectLogNewCaf', 'projectLogNewCaf'];

    function CafSummaryController ($window,$scope, Principal,$filter, LoginService, $state, $stateParams,Projectlist,downloadService, Projectlog, Projectdetailupdated, Projectlogupdated, Project, ConstructionPermitApprovalSteps, ConstructionPermitApproval, AddCAF, projectLog, ProjectLogNewCaf, projectLogNewCaf) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.projectdetail = null;
        vm.existingproject = false;
        vm.login = LoginService.open;
        vm.register = register;
        vm.edit_form = edit_form;
        vm.editInvestment = editInvestment;
        vm.constructionPermitData= {};
        vm.project = [];
        vm.projectlog = projectLog;
        vm.projectLogNewCaf = projectLogNewCaf;
        vm.REDetails = null;
//        vm.total_project_cost = 0;
//        vm.project.totalpurposedemployment=0;
        vm.deactivateCAF = deactivateCAF;
        vm.activateCAF = activateCAF;
        vm.REInfo = [
            {
                REName:"Sahil Chaudhary",
                RMname :"Mamta Joshi",
                REphoneNumber : '9988227626',
                EmailId:'sahiltoor227@gmail.com'
            },
            {
                REName:"Sahil Chaudhary",
                RMname :"Kapil kumar mittAL",
                REphoneNumber : '9988227626',
                EmailId:'sahiltoor227@gmail.com'
            },
            {
                REName:"Sahil Chaudhary",
                RMname :"Kshitij",
                REphoneNumber : '9988227626',
                EmailId:'sahiltoor227@gmail.com'
            },
            {
                REName:"Aditi Sharma",
                RMname :"Ashwani Gupta",
                REphoneNumber : '8284942555',
                EmailId:'aditish135@gmail.com'
            },
            {
                REName:"Aditi Sharma",
                RMname :"S.N Singh",
                REphoneNumber : '8284942555',
                EmailId:'aditish135@gmail.com'
            },
            {
                REName:"Aditi Sharma",
                RMname :"J.D Kapoor",
                REphoneNumber : '8284942555',
                EmailId:'aditish135@gmail.com'
            },
            {
                REName:"Aditi Sharma",
                RMname :"Rajneesh Mittal",
                REphoneNumber : '8284942555',
                EmailId:'aditish135@gmail.com'
            },
            {
                REName:"Aditi Sharma",
                RMname :"Sanjay Rohilla",
                REphoneNumber : '8284942555',
                EmailId:'aditish135@gmail.com'
            },
            {
                REName:"Aditi Sharma",
                RMname :"Digvijay Singh",
                REphoneNumber : '8284942555',
                EmailId:'aditish135@gmail.com'
            },
            {
                REName:"Shilpa Pandey ",
                RMname :"Sunil Chopra",
                REphoneNumber : '9041198392',
                EmailId:'shiplahepc@gmail.com'
            },
            {
                REName:"Harjot Singh Reen",
                RMname :"Ajay Bansal",
                REphoneNumber : '9876914995',
                EmailId:'harjot.hsiidc@gmail.com'
            },
            {
                REName:"Harjot Singh Reen",
                RMname :"Aseem Tandon",
                REphoneNumber : '9876914995',
                EmailId:'harjot.hsiidc@gmail.com'
            },
            {
                REName:"Navjot Vashisht",
                RMname :"Satya Nairan",
                REphoneNumber : '7347003604',
                EmailId:'navjotvashisht.hsiidc@gmail.com'
            },
            {
                REName:"Navjot Vashisht",
                RMname :"Sanjay Kumar",
                REphoneNumber : '7347003604',
                EmailId:'navjotvashisht.hsiidc@gmail.com'
            },

            {
                REName:"Hitesh sharma",
                RMname :"Sanjay Mittal",
                REphoneNumber : '7837922459',
                EmailId:'hiteshsharma.hsiidc@gmail.com'
            },
            {
                REName:"Hitesh sharma",
                RMname :"Yogesh Kaushik",
                REphoneNumber : '7837922459',
                EmailId:'hiteshsharma.hsiidc@gmail.com'
            },
            {
                REName:"Komal sharma",
                RMname :"Gulshan Kumar",
                REphoneNumber : '9717802530',
                EmailId:'komalhsiidc@gmail.com'
            },
            {
                REName:"Vandna",
                RMname :"Manoj Pal",
                REphoneNumber : '7347003660',
                EmailId:'vandnabist.hsiidc@gmail.com'
            },
            {
                REName:"Salivia Rathore",
                RMname :"Bharat Bhushan",
                REphoneNumber : '8427795846',
                EmailId:'salivia.hsiidc@gmail.com'
            },
            {
                REName:"Salivia Rathore",
                RMname :"Priyanka Rani",
                REphoneNumber : '8427795846',
                EmailId:'salivia.hsiidc@gmail.com'
            },
            {
                REName:"Amit Gautam",
                RMname :"Dheeraj Joshi",
                REphoneNumber : '9355573939',
                EmailId:''
            },
            {
                REName:"Amit Gautam",
                RMname :"Surinder Singh",
                REphoneNumber : '9355573939',
                EmailId:''
            },
            {
                REName:"Prinkle",
                RMname :"O.D Vashisht",
                REphoneNumber : '9996608866',
                EmailId:''
            },
            {
                REName:"Prinkle",
                RMname :"Vijay Godara",
                REphoneNumber : '9996608866',
                EmailId:''
            },

            {
                REName:"Pallavi Batra",
                RMname :"Aninderbir Kaur",
                REphoneNumber : '8950477447',
                EmailId:''
            },
            {
                REName:"Pallavi Batra",
                RMname :"Ravish Jani",
                REphoneNumber : '8950477447',
                EmailId:''
            },
            {
                REName:"Manpreet",
                RMname :"R C Dhara",
                REphoneNumber : '9646336699',
                EmailId:'manpreetsingh.hsiidc@gmail.com'
            },
            {
                REName:"Manpreet",
                RMname :"Mahavir Singh",
                REphoneNumber : '9646336699',
                EmailId:'manpreetsingh.hsiidc@gmail.com'
            },
            {
                REName:"Manpreet",
                RMname :"Rajiv Sharma",
                REphoneNumber : '9646336699',
                EmailId:'manpreetsingh.hsiidc@gmail.com'
            },
            {
                REName:"Aditi Sharma",
                RMname :"Jaideep Kapoor",
                REphoneNumber : '8284942555',
                EmailId:'aditish135@gmail.com'
            },
            {
                REName:"Aditi Sharma",
                RMname :"S N Singh",
                REphoneNumber : '8284942555',
                EmailId:'aditish135@gmail.com'
            },
            {
                REName:"Shilpa Pandey",
                RMname :"Deepak ",
                REphoneNumber : '7888997391',
                EmailId:'Shilpahepc@gmail.com'
            },
            {
                REName:"Shilpa Pandey",
                RMname :"DIC Faridabad",
                REphoneNumber : '7888997391',
                EmailId:'Shilpahepc@gmail.com'
            },
            {
                REName:"Shilpa Pandey",
                RMname :"DIC Palwal",
                REphoneNumber : '7888997391',
                EmailId:'Shilpahepc@gmail.com'
            },
            {
                REName:"Ashish Bansal",
                RMname :"Harinder Pal Singh",
                REphoneNumber : '9416073624',
                EmailId:'ashishbansal020@gmail.com'
            },
            {
                REName:"Ashish Bansal",
                RMname :"Anoop Kumar",
                REphoneNumber : '9416073624',
                EmailId:'ashishbansal020@gmail.com'
            },
            {
                REName:"Shilpa Pandey",
                RMname :"Kapil Mittal",
                REphoneNumber : '7888997391',
                EmailId:'Shilpahepc@gmail.com'
            }
        ];

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });
        getAccount();

        getProjectDetail();

        vm.proposedEmployment = function(domicile){
                    if(domicile === 'haryana'){
                        vm.project.nonharyanadomicile = vm.project.totalpurposedemployment - vm.project.haryanadomicile;
                        if(vm.project.nonharyanadomicile < 0){
                            vm.project.haryanadomicile = 0;
                            vm.project.nonharyanadomicile = 0;
                            alert("Your Value Is Not Valid");
                        }
                    }else{
                        vm.project.haryanadomicile = vm.project.totalpurposedemployment - vm.project.nonharyanadomicile;
                        if(vm.project.haryanadomicile < 0){
                            vm.project.nonharyanadomicile = 0;
                            vm.project.haryanadomicile = 0;
                            alert("Your Value Is Not Valid");
                        }
                    }
                };

        function getProjectDetail(){
            vm.projectdetail = Project.get({id : $stateParams.projectid});

            Projectlist.get({id : $stateParams.projectid},  function (result) {
                vm.investorsummarydetails = result;

                 if(vm.investorsummarydetails.caftype=== 'New'){
                    if(vm.investorsummarydetails.rm !==''){
                        var value = vm.investorsummarydetails.rm.split(',');
                        vm.REDetails = $filter('filter')(vm.REInfo,{RMname : value[0]},true);
                    }
                }

                if(vm.investorsummarydetails.caftype === 'FILMCAF'){
                    vm.investorsummarydetails.caftype = 'Film'
                }
                if(vm.investorsummarydetails.caftype === 'LandExchange'){
                    vm.investorsummarydetails.caftype = 'Land'
                }
                if(vm.investorsummarydetails.caftype === 'Renewable'){
                    vm.investorsummarydetails.caftype = 'Renew'
                }
            });
        }

        function deactivateCAF() {
            vm.projectdetail.isdeactivated =true;
            vm.projectdetail.deactivateddate=new Date();
            Projectdetailupdated.update(vm.projectdetail, onDeactivateSaveSuccess, onDeactivateSaveError);
        }

        function activateCAF() {
            vm.projectdetail.isdeactivated =false;
            vm.projectdetail.deactivateddate=new Date();
            Projectdetailupdated.update(vm.projectdetail, onActivateSaveSuccess, onActivateSaveError);
        }

        function onDeactivateSaveSuccess (result) {
            if(vm.projectdetail.caftype!=='New'){
                $scope.$emit('investhryApp:projectUpdate', result);
                vm.projectlog.projectid = $stateParams.projectid;
                vm.projectlog.actiontaken = "De-Activated the CAF.";
                vm.projectlog.actiontype = "CAF Deactivated";
                Projectlogupdated.save(vm.projectlog, onProjectLogSaveSuccess, onProjectLogSaveError);
            }
            if(vm.projectdetail.caftype==='New'){
                vm.projectLogNewCaf.projectId = $stateParams.projectid;
                vm.projectLogNewCaf.actionTaken = "De-Activated the CAF.";
                vm.projectLogNewCaf.actionType = "CAF Deactivated";
                ProjectLogNewCaf.save(vm.projectLogNewCaf, onProjectLogSaveSuccess, onProjectLogSaveError);
            }
            $window.location.reload();
        }

        function onDeactivateSaveError () {
            vm.isSaving = false;
        }
        function onActivateSaveSuccess (result) {
            if(vm.projectdetail.caftype!=='New'){
                $scope.$emit('investhryApp:projectUpdate', result);
                vm.projectlog.projectid = $stateParams.projectid;
                vm.projectlog.actiontaken = "Re-activated the CAF.";
                vm.projectlog.actiontype = "CAF Re-Activated";
                Projectlogupdated.save(vm.projectlog, onProjectLogSaveSuccess, onProjectLogSaveError);
            }
            if(vm.projectdetail.caftype==='New'){
                vm.projectLogNewCaf.projectId = $stateParams.projectid;
                vm.projectLogNewCaf.actionTaken = "Re-activated the CAF.";
                vm.projectLogNewCaf.actionType = "CAF Re-Activated";
                ProjectLogNewCaf.save(vm.projectLogNewCaf, onProjectLogSaveSuccess, onProjectLogSaveError);
            }
            $window.location.reload();
        }

        function onActivateSaveError () {
            vm.isSaving = false;
        }

        function onProjectLogSaveSuccess(result)
        {

        }
        function onProjectLogSaveError()
        {

        }
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        function downloadFile(filename) {
            downloadService.download(filename)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

        function edit_form(){
         if(vm.projectdetail.caftype=='New'){
          $state.go('editnewCAF',{id:vm.investorsummarydetails.projectid});
         }
         else if(vm.projectdetail.caftype=='Shop'){
          $state.go('editshopCAF',{id:vm.investorsummarydetails.projectid});
         }
         else if(vm.projectdetail.caftype=='Renewable'){
          $state.go('editrenewableCAF',{id:vm.investorsummarydetails.projectid});
         }
         else if(vm.projectdetail.caftype=='LandRequirement'){
          $state.go('editlandreqCAF',{id:vm.investorsummarydetails.projectid});
         }
         else if(vm.projectdetail.caftype=='Tower'){
          $state.go('editTowerCAF',{id:vm.investorsummarydetails.projectid});
         }
         else if(vm.projectdetail.caftype=='FILMCAF'){
          $state.go('editFilmCAF',{id:vm.investorsummarydetails.projectid});
         }
         else if(vm.projectdetail.caftype=='Excise'){
             $state.go('editExciseCAF',{id:vm.investorsummarydetails.projectid});
         }
         else{
          alert("unexpected error occured");
         }
        }

        vm.createConstructionPermit=function () {
            ConstructionPermitApproval.query({projectId: $stateParams.projectid},function (result) {
                // console.log(result.projectId !== $stateParams.projectid);
                vm.result = result;
                if(result.projectId !== $stateParams.projectid){
                    // console.log("if");
                    vm.constructionPermitData.projectId=$stateParams.projectid;
                    ConstructionPermitApprovalSteps.save(vm.constructionPermitData,onCPsaveSuccess);
                }
                else {
                    // console.log("else");
                    $state.go('constructionpermit',{id: $stateParams.projectid});
                }
            });
        };

        function onCPsaveSuccess(result) {
            $state.go('constructionpermit',{id: $stateParams.projectid});
        }

        function editInvestment() {
            Project.get({id: vm.investorsummarydetails.projectid},function (result) {
                vm.project = result;
                console.log(vm.project.investmentType);
                if(vm.project.investmentType == 'Existing Project (not a new investment in state)' && vm.project.caftype=='New'){
                    vm.existingproject=true;
                    console.log("existing project");
                }

                vm.project_cost_in_crore = calculateCostInCrore(vm.project);
                vm.project_cost_in_lacs = calculateCostInLacs(vm.project);
                vm.project_cost_in_thousands = calculateCostInThousands(vm.project);
            });
        }

        vm.changeInCost = function(){
            var projectCostInCrore=vm.project_cost_in_crore || '00';
            var projectCostInLacs=vm.project_cost_in_lacs|| '00' ;
            var projectCostInThousands=vm.project_cost_in_thousands||'00';
            if(vm.project_cost_in_lacs<10){
                projectCostInLacs='0'+vm.project_cost_in_lacs;
            }
            if(vm.project_cost_in_thousands<10){
                projectCostInThousands='0'+vm.project_cost_in_thousands;
            }
            return vm.total_project_cost = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;
        };

        function calculateCostInCrore(project){
            return parseInt(project.total_project_cost.toString().split('.')[0]);
        }

        function calculateCostInLacs(project){
            if(project.total_project_cost.toString().indexOf('.') !== -1){
                return parseInt(project.total_project_cost.toString().split('.')[1].substring(0,2));
            }
            return 0;
        }

        function calculateCostInThousands(project){
            if(project.total_project_cost.toString().indexOf('.') !== -1){
                return parseInt(project.total_project_cost.toString().split('.')[1].substring(2,4));
            }
            return 0;
        }

        vm.changeInvestment = function (project) {
        if(vm.project.totalpurposedemployment > 0 && (vm.project.haryanadomicile + vm.project.nonharyanadomicile != vm.project.totalpurposedemployment)){
                    alert("Total of employment with Haryana Domicile and Non-Haryana Domicile must be equal to Total Proposed Employment!!");
                    vm.project.haryanadomicile = 0;
                    vm.project.nonharyanadomicile = 0;
                    return;
               }
               if(vm.project.totalpurposedemployment === 0){
                    vm.project.haryanadomicile = 0;
                    vm.project.nonharyanadomicile = 0;
               }
           project.totalpurposedemployment = vm.project.totalpurposedemployment;
           project.haryanadomicile = vm.project.haryanadomicile;
           project.nonharyanadomicile = vm.project.nonharyanadomicile;
           if(vm.total_project_cost==null){
            vm.total_project_cost = vm.project.total_project_cost;
           }
           project.total_project_cost = vm.total_project_cost;
           AddCAF.update(project,onUpdateProjectSuccess,onUpdateProjectError);
        };

        function onUpdateProjectSuccess(result) {
            $('#editCAFmodal').modal('hide');
        }

        function onUpdateProjectError() {
            alert("Unexpected Error Occurred! ");
        }
    }

})();
