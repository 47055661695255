(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('nswsURL', {
            parent: 'app',
            url: '/nswsURL',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/nswsRedirectPage/searchCAF.html',
                    controller: 'NswsRedirectUrlController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        // .state('backtocentral', {
        //     parent: 'app',
        //     url: '/backtocentral',
        //     data: {
        //         authorities: []
        //     },
        //     views: {
        //         'content@': {
        //             templateUrl: 'app/centerRedirectPage/centralForm.html',
        //             controller: 'RedirectToCentralPortalController',
        //             controllerAs: 'vm'
        //         }
        //     },
        //     resolve: {
        //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
        //             $translatePartialLoader.addPart('home');
        //             return $translate.refresh();
        //         }]
        //     }
        // });

        }
        })();
