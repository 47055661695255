(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('IndexMigrateDataController', IndexMigrateDataController);

    IndexMigrateDataController.$inject = ['$scope', 'Principal', 'LoginService', '$state','MigrateGrievanceLog', 'MigrateGrievance', 'MigrateGrievanceDashboardOutput' , 'MigrateProjectAssignedData'];

    function IndexMigrateDataController($scope, Principal, LoginService, $state, MigrateGrievanceLog, MigrateGrievance, MigrateGrievanceDashboardOutput, MigrateProjectAssignedData) {

        var vm = this;
        vm.migrate_grievance_log = migrate_grievance_log;
        vm.migrate_grievance = migrate_grievance;
        vm.migrate_grievance_dashboard_output = migrate_grievance_dashboard_output;
        vm.migrate_project_assigned_data = migrate_project_assigned_data ;

        function migrate_grievance_log(){
            MigrateGrievanceLog.save();
        }
        function migrate_grievance(){
            MigrateGrievance.save();
        }
        function migrate_grievance_dashboard_output(){
            MigrateGrievanceDashboardOutput.save();
        }

        function migrate_project_assigned_data (){
            MigrateProjectAssignedData.save();
        }
    }
 })();
