(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TotalservicedashboarddetailsController', TotalservicedashboarddetailsController);

    TotalservicedashboarddetailsController.$inject = ['Totalservicedashboarddetails'];

    function TotalservicedashboarddetailsController(Totalservicedashboarddetails) {

        var vm = this;

        vm.totalservicedashboarddetails = [];

        loadAll();

        function loadAll() {
            Totalservicedashboarddetails.query(function(result) {
                vm.totalservicedashboarddetails = result;
                vm.searchQuery = null;
            });
        }
    }
})();
