(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ProjectServiceLogController', ProjectServiceLogController);

    ProjectServiceLogController.$inject = ['ProjectServiceLog', 'GetServiceLogByProjectId'];

    function ProjectServiceLogController(ProjectServiceLog, GetServiceLogByProjectId) {

        var vm = this;

        vm.projectServiceLogs = undefined;
        vm.loadByProjectid=loadByProjectid;

//        loadAll();

        function loadAll() {
            ProjectServiceLog.query(function(result) {
                vm.projectServiceLogs = result;
                vm.searchQuery = null;
            });
        }

        function loadByProjectid() {
            vm.projectServiceLogs=null;
            GetServiceLogByProjectId.query({projectid:vm.projectServiceLog.projectid}, function(result) {
                vm.projectServiceLogs = result;
                vm.searchQuery = null;
            });
        }

    }
})();
