(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewservicedashboardController', NewservicedashboardController);

   NewservicedashboardController.$inject = ['$filter','Newservicedashboard','Clearancecharts'];

    function NewservicedashboardController ($filter,Newservicedashboard,Clearancecharts) {
        var vm = this;

        vm.datePickerOpenStatus = {};
        vm.sortingdates={};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;
        vm.selectedDate=selectedDate;
        var d=null;
        function openCalendar (date) {
        vm.datePickerOpenStatus[date] = true;
        }

        vm.dateOptions = {
            minDate: new Date("05-01-2017"),
            maxDate: Date.now()
        };

        function selectedDate(dateSelected){
            var newDate = new Date(dateSelected);
            newDate.setDate(newDate.getDate()+30);

            if(newDate > Date.now()){
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: Date.now()
                };
            }
            else {
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: newDate
                };
            }
        }




        vm.getAverageClearance=function (deptdata,status) {
            Clearancecharts.putClearanceData(null,deptdata,status);
        };
       vm.viewcharts=function(departmentname,servicestats,status){
       Clearancecharts.putClearanceData(departmentname,servicestats,status);
       };

        vm.getServicesBetweenDates=function(){
            vm.newdashboards = null;
            var fromdate=$filter('date')(vm.fromdate, "dd-MM-yyyy");
            var todate=$filter('date')(vm.todate, "dd-MM-yyyy");
            Newservicedashboard.query({fromdate:fromdate,todate:todate},onSuccess,OnError);
        }

        function onSuccess(result) {
            vm.newdashboards=result;
            for (var i=0;i<vm.newdashboards.length;i++){
                if (vm.newdashboards[i].deptname==='DAKSHIN HARYANA BIJLI VITRAN NIGAM'){
                    vm.newdashboards[i].deptorder=1;

                }
                if (vm.newdashboards[i].deptname==='UTTAR HARYANA BIJLI VITRAN NIGAM'){
                    vm.newdashboards[i].deptorder=2;

                }
                if (vm.newdashboards[i].deptname==='HARYANA URBAN DEVELOPMENT AUTHORITY'){
                    vm.newdashboards[i].deptorder=3;

                }
                if (vm.newdashboards[i].deptname==='LABOUR DEPARTMENT - FACTORY WING'){
                    vm.newdashboards[i].deptorder=10;

                }
                if (vm.newdashboards[i].deptname==='LABOUR DEPARTMENT - LABOUR WING'){
                    vm.newdashboards[i].deptorder=11;

                }
                if (vm.newdashboards[i].deptname==='TOWN & COUNTRY PLANNING'){
                    vm.newdashboards[i].deptorder=6;

                }
                if (vm.newdashboards[i].deptname==='HARYANA STATE POLLUTION CONTROL BOARD'){
                    vm.newdashboards[i].deptorder=8;

                }
                if (vm.newdashboards[i].deptname==='URBAN LOCAL BODIES'){
                    vm.newdashboards[i].deptorder=9;

                }
                if (vm.newdashboards[i].deptname==='DEPARTMENT OF INDUSTRIES & COMMERCE'){
                    vm.newdashboards[i].deptorder=4;
                }

                if (vm.newdashboards[i].deptname==='HARYANA STATE INDUSTRIAL & INFRASTRUCTURE DEVELOPMENT CORPORATION LTD'){
                    vm.newdashboards[i].deptorder=5;
                }
            }

        }

        function OnError() {
            alert("Please enter a period  between 31 days ");
        }

    }
})();
