(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AboveGroundController', AboveGroundController);

    AboveGroundController.$inject = ['$scope', 'Principal', 'AboveGroundService', 'Above_ground','Tower_log', 'downloadserviceformattachment'];

    function AboveGroundController($scope, Principal , AboveGroundService, Above_ground,Tower_log,downloadserviceformattachment) {
        var vm = this;
        vm.view_log=view_log;
        vm.comment= comment;
        vm.save= save;
        vm.download=download;
        vm.dowload_dialog=dowload_dialog;

        loadAll();
        // Principal.identity().then(function(account){
        //     vm.username=account.login;
        //
        // });

        function loadAll() {
            AboveGroundService.query(function(result) {
                vm.above_grounds = result;
                vm.searchQuery = null;
            });

        }
        function view_log(id) {
            Tower_log.query({towerId: id},function (result){
                vm.towerLog=result;
            });
        }
        function comment(result) {
          vm.applicant_id= result.id;
          vm.name_applicant= result.name_applicant;
        }
        function save() {
            vm.tower_log.tower_id= vm.applicant_id;
            vm.tower_log.action= "comment";
            Tower_log.save(vm.tower_log, onSaveSuccess);
        }
        function onSaveSuccess() {
            UIkit.modal('#modal_above3').hide();
            vm.tower_log.comment =null;
        }

        function dowload_dialog(file1,file2,file3) {
            vm.clearancefile1=file1;
            vm.clearancefile2=file2;
            vm.clearancefile3=file3;
        }

        function download(file_id,clientfilename) {
            if(file_id===1){
                downloadserviceformattachment.download(vm.clearancefile1,clientfilename);
            }
            else if(file_id===2){
                downloadserviceformattachment.download(vm.clearancefile2,clientfilename);
            }
            else if(file_id===3){
                downloadserviceformattachment.download(vm.clearancefile3,clientfilename);
            }

        }
    }
})();
