(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcTicketsController', HepcTicketsController);

    HepcTicketsController.$inject = ['Tickets', 'Principal'];

    function HepcTicketsController(Tickets, Principal) {

        var vm = this;
        loadAll();

        function loadAll() {
            vm.authenticated = Principal.isAuthenticated();
        }

    }
})();
