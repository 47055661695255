(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('viewprojectdetail', {
            parent: 'app',
            url: '/viewprojectdetail/{status}',
            data: {
                authorities: ['ROLE_RM','ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_ACCOUNT_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/viewprojectdetail/viewprojectdetail.html',
                    controller: 'viewprojectdetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('viewprojectdetail');
                    return $translate.refresh();
                }]
            }
        })
        .state('nodalofficerviewprojectdetail', {
            parent: 'app',
            url: '/nodalofficerviewprojectdetail/{status}',
            data: {
                authorities: ['ROLE_NODAL_OFFICER','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/viewprojectdetail/nodalofficerviewprojectdetail.html',
                    controller: 'nodalofficerviewprojectdetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('viewprojectdetail');
                    return $translate.refresh();
                }]
            }
        })
        .state('showDLCC', {
                    parent: 'viewprojectdetail',
                    url: 'nodalofficerviewprojectdetail/showDLCC/{status}',
                    data: {
                        authorities: ['ROLE_NODAL_OFFICER','ROLE_FILM_OFFICER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/viewprojectdetail/showDLCC.html',
                            controller: 'showdlccController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('viewprojectdetail');
                            return $translate.refresh();
                        }]
                    }
                });
    }
})();

