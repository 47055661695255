(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('FeedBackViewController', FeedBackViewController);

    FeedBackViewController.$inject = ['$state','$window' , '$filter','TicketsFeedback'];

    function FeedBackViewController ($state, $window, $filter, TicketsFeedback) {
        var vm = this;
        vm.searchBy='All';
        vm.feedbacks=[];
        vm.selectedFeedback=selectedFeedback;
        vm.changeUserBy=changeUserBy;

        vm.download = download;

        loadAll();

        function loadAll() {
            TicketsFeedback.query(function (result) {
                vm.feedbacks=result;
            })
        }

        function selectedFeedback(result) {
            vm.feed=result;
        }

        function changeUserBy(searchBy) {
            if(searchBy!='All'){
                TicketsFeedback.query({rating: searchBy},function (result) {
                    vm.feedbacks=result;
                })
            }else{
                loadAll();
            }
        }

        function convertJsonsToArrays(args){
            var dataArray = [];
            var header = ["Ticket Number    ",
                "Category   ",
                "ClosedBy  ",
                "Closing Date         ",
                "Investor Name  ",
                "Rating  "
            ];
            dataArray.push(header);
            for( var i = 0; i < args.length; i++ ){
                var row = args[i];
                var rowAr=[];
                var closingDate=$filter('date')(row.closingDate, 'dd-MMM-yyyy h:mm a');
                rowAr.push(row.ticketNumber);
                rowAr.push(row.category);
                rowAr.push(row.closedBy);
                rowAr.push(closingDate);
                rowAr.push(row.investorName);
                rowAr.push(row.rating);
                dataArray.push(rowAr);
            }
            return dataArray;
        }


        function download(args,status) {

            var rows = convertJsonsToArrays(args);
            var lineArray = [];

            for(var i = 0; i < rows.length; i++){
                var line = rows[i].join("\t");
                lineArray.push(line);
            }
            lineArray = lineArray.join("\n");
            var a = document.createElement('a');
            var blob = new Blob([lineArray],{type: 'data:text/plain;charset=utf-8;'});
            var url = URL.createObjectURL(blob);
            $window.location.href = url;


        }
    }
})();
