(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketMemberController', TicketMemberController);

    TicketMemberController.$inject = ['TicketMember'];

    function TicketMemberController(TicketMember) {

        var vm = this;

        vm.ticketMembers = [];

        loadAll();

        function loadAll() {
            TicketMember.query(function(result) {
                vm.ticketMembers = result;
                vm.searchQuery = null;
            });
        }
    }
})();
