(function() {
    'use strict';

    angular
    .module('investhryApp')
    .factory('GetImageForApplicant', ['$q', '$timeout', '$window',
        function($q, $timeout, $window, $http) {
            return {
                getImageApplicant: function(name) {
                    return 'api/getimage?name=' + name;
                }
            };
        }
    ]);
})();
