(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ClearedServicesDeleteController',ClearedServicesDeleteController);

    ClearedServicesDeleteController.$inject = ['$uibModalInstance', 'entity', 'ClearedServices'];

    function ClearedServicesDeleteController($uibModalInstance, entity, ClearedServices) {
        var vm = this;

        vm.clearedServices = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ClearedServices.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
