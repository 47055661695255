(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('deactivateuser', {
                    parent: 'app',
                    url: '/deactivateuser',
                    data: {
                        authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/deactivateduplicateusers/deactivateuser.html',
                            controller: 'DeactivateUsersWithoutCafController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                 }]
             }
        });
    }
})();
