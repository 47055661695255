(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HudaformOcController', HudaformOcController);

    HudaformOcController.$inject = ['HudaformOc'];

    function HudaformOcController(HudaformOc) {

        var vm = this;

        vm.hudaformOcs = [];

        loadAll();

        function loadAll() {
            HudaformOc.query(function(result) {
                vm.hudaformOcs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
