(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('StateDialogController', StateDialogController);

    StateDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'State', 'Country'];

    function StateDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, State, Country) {
        var vm = this;

        vm.state = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

         Country.query(function(result) {
                   vm.countries = result;
                   vm.searchQuery = null;
               });



        load();
        function load()
        {
        vm.id=$stateParams.countryID;
        Country.get({id: vm.id},function(result)
        {
        $scope.selected= {id: result.id, countryname: result.countryname}
        });
        }


        function save () {
            vm.isSaving = true;
            vm.state.countryID = $scope.selected.id;
            vm.state.countryname = $scope.selected.countryname;
            if (vm.state.id !== null) {
                State.update(vm.state, onSaveSuccess, onSaveError);
            } else {
                State.save(vm.state, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:stateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
