(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MultipleUserWithCafController', MultipleUserWithCafController);

    MultipleUserWithCafController.$inject = ['MultipleUserWithCaf'];

    function MultipleUserWithCafController(MultipleUserWithCaf) {

        var vm = this;

        vm.multipleUserWithCafs = [];

        loadAll();

        function loadAll() {
            MultipleUserWithCaf.query(function(result) {
                vm.multipleUserWithCafs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
