(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('grievance-log', {
            parent: 'entity',
            url: '/grievance-log',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.grievanceLog.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/grievance-log/grievance-logs.html',
                    controller: 'GrievanceLogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('grievanceLog');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('grievance-log-detail', {
            parent: 'grievance-log',
            url: '/grievance-log/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.grievanceLog.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/grievance-log/grievance-log-detail.html',
                    controller: 'GrievanceLogDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('grievanceLog');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'GrievanceLog', function($stateParams, GrievanceLog) {
                    return GrievanceLog.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'grievance-log',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('grievance-log-detail.edit', {
            parent: 'grievance-log-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grievance-log/grievance-log-dialog.html',
                    controller: 'GrievanceLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['GrievanceLog', function(GrievanceLog) {
                            return GrievanceLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('grievance-log.new', {
            parent: 'grievance-log',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grievance-log/grievance-log-dialog.html',
                    controller: 'GrievanceLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                grievanceId: null,
                                action: null,
                                actionDate: null,
                                actionComment: null,
                                actionBy: null,
                                attachment: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('grievance-log', null, { reload: 'grievance-log' });
                }, function() {
                    $state.go('grievance-log');
                });
            }]
        })
        .state('grievance-log.edit', {
            parent: 'grievance-log',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grievance-log/grievance-log-dialog.html',
                    controller: 'GrievanceLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['GrievanceLog', function(GrievanceLog) {
                            return GrievanceLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('grievance-log', null, { reload: 'grievance-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('grievance-log.delete', {
            parent: 'grievance-log',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grievance-log/grievance-log-delete-dialog.html',
                    controller: 'GrievanceLogDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GrievanceLog', function(GrievanceLog) {
                            return GrievanceLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('grievance-log', null, { reload: 'grievance-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
