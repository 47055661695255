(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('excise-service-form-filled', {
            parent: 'entity',
            url: '/excise-service-form-filled',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.exciseServiceFormFilled.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/excise-service-form-filled/excise-service-form-filleds.html',
                    controller: 'ExciseServiceFormFilledController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('exciseServiceFormFilled');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('excise-service-form-filled-detail', {
            parent: 'excise-service-form-filled',
            url: '/excise-service-form-filled/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.exciseServiceFormFilled.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/excise-service-form-filled/excise-service-form-filled-detail.html',
                    controller: 'ExciseServiceFormFilledDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('exciseServiceFormFilled');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ExciseServiceFormFilled', function($stateParams, ExciseServiceFormFilled) {
                    return ExciseServiceFormFilled.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'excise-service-form-filled',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('excise-service-form-filled-detail.edit', {
            parent: 'excise-service-form-filled-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/excise-service-form-filled/excise-service-form-filled-dialog.html',
                    controller: 'ExciseServiceFormFilledDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ExciseServiceFormFilled', function(ExciseServiceFormFilled) {
                            return ExciseServiceFormFilled.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('excise-service-form-filled.new', {
            parent: 'excise-service-form-filled',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/excise-service-form-filled/excise-service-form-filled-dialog.html',
                    controller: 'ExciseServiceFormFilledDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                projectId: null,
                                serviceId: null,
                                status: null,
                                lastActionDate: null,
                                formFilledDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('excise-service-form-filled', null, { reload: 'excise-service-form-filled' });
                }, function() {
                    $state.go('excise-service-form-filled');
                });
            }]
        })
        .state('excise-service-form-filled.edit', {
            parent: 'excise-service-form-filled',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/excise-service-form-filled/excise-service-form-filled-dialog.html',
                    controller: 'ExciseServiceFormFilledDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ExciseServiceFormFilled', function(ExciseServiceFormFilled) {
                            return ExciseServiceFormFilled.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('excise-service-form-filled', null, { reload: 'excise-service-form-filled' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('excise-service-form-filled.delete', {
            parent: 'excise-service-form-filled',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/excise-service-form-filled/excise-service-form-filled-delete-dialog.html',
                    controller: 'ExciseServiceFormFilledDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ExciseServiceFormFilled', function(ExciseServiceFormFilled) {
                            return ExciseServiceFormFilled.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('excise-service-form-filled', null, { reload: 'excise-service-form-filled' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
