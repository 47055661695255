(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('DownloadExternalServiceFiles', ['$q', '$timeout', '$window',
            function($q, $timeout, $window, $http) {
                return {
                    download: function(projectid, serviceid, reApplyCounter, url) {
                        var defer = $q.defer();
                        $timeout(function() {
                            //pollution
                            if((serviceid==='6bd18359-09f4-4c71-85d9-e4f313ccfb8d')||(serviceid==='b27b79b9-a3ec-4eb1-9f70-3e275dd13cd6')||
                                (serviceid==='bcf33589-b4ab-468d-aafc-921d6754c236') || (serviceid==='3e5652fc-4aeb-4ddf-8af0-72e6f94ccb1e')
                                || (serviceid==='60da1053-0e39-4151-bbbf-d6ee41d0185f') || (serviceid==='d8607e7b-7c35-42ec-812c-c20253e22b10')
                                || (serviceid==='596c9059-7e28-49ca-a790-c79714d24f0c') || (serviceid==='8ed2d405-1651-4253-bbe9-84e869902ed2')
                                || (serviceid==='d700ca84-18e9-4126-ba11-ee57c3ba5693') || (serviceid==='aad383df-03d6-4104-9f2a-69b992919566'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid;
                            }
                            //tcp
                            else if((serviceid==='c8981bea-c376-4e65-9d43-9650989a7bb7') || (serviceid === 'dd2ca94a-1091-4290-b518-507a28d253ec') || (serviceid === 'a267e5a1-6da8-4c7b-9689-49a0ca61b692') || (serviceid === '06099ce7-a9dd-49e4-9b53-640f6dd31a20') || (serviceid === '159a0331-87c3-407d-9c6c-559455d4b6ab')
                                || (serviceid === '8b44a3ef-e336-4fb4-bdf6-24e6e157a202') || (serviceid === 'bf9caa30-5dcf-455e-85b3-28ab4fe1488e') || (serviceid === 'fa4682ac-045f-4748-849f-ad8e03317be5')
                                || (serviceid === 'c8050fed-7707-4ae2-82b4-09f6ae9bdff6') || (serviceid === '39f412f2-9248-4589-bff6-77e565131891'))
                            {
                                $window.location = url + projectid+'_'+serviceid+'_'+reApplyCounter+'.pdf';
                            }
                            //forest department
                            else if((serviceid==='c63bd059-6b32-4c17-974e-20917cdaa5ab')||(serviceid==='1f8db0c1-da38-454d-8224-3f89e622b7fb'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid;
                            }
                            //UHBVN
                            else if((serviceid==='363ecdcf-90ee-4700-9fe4-9a73081517b3') || (serviceid==='04b2a851-70f2-4223-982e-3982f6f63ea4') || (serviceid==='57fa837c-0897-4c7a-8225-74323823c890') || (serviceid==='32d7ff3c-4347-4dc9-b558-65853108610f') || (serviceid==='b4bfdc90-c1cc-44f6-9a8d-35317930b58f')
                                || (serviceid==='e2d1e569-3ee9-4cc7-ba6f-9bd7d446e9ba') || (serviceid==='8ff2374e-2d60-4d12-9aae-e9d89a4916d3') || (serviceid==='f43dc9df-1ef5-4209-be68-c989d009b83d')
                                || (serviceid==='7bd6a58a-e2a5-435f-9f48-d4a6afc35076') || (serviceid==='7ddcfe57-048f-4839-b36b-55dcc537d596')
                                || (serviceid==='a251b23d-e1c0-4ad5-ab82-23cd250855df') || (serviceid==='e9f0ee6d-5c4c-4f07-857d-7d23acec632d'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //DHBVN
                            else if((serviceid==='0577cfbb-77e3-4022-8b13-ec100e7094e0') || (serviceid==='71866fad-2959-447a-93bd-bd94dab54dd0') || (serviceid==='82924c69-73fc-4071-a2a9-45e8e4fa20c5') || (serviceid==='ac042b18-6c28-44a9-aea3-5a841b5a01be') || (serviceid==='553240db-9c19-45c8-b63a-beb3e6a1df41')
                                || (serviceid==='fb721b52-b0ec-460d-820e-05b741f6c34b') || (serviceid==='2d8fd723-bff1-4332-bbae-0d6ed600dd77') || (serviceid==='bf577a2d-2a9e-4039-9cec-1b5bd17bc079')
                                || (serviceid==='c0347070-4fb8-4d5b-ae26-b05ee70fe6c4') || (serviceid==='88aadb31-3f6f-4f89-b39b-20478a0428ea'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //ULB
                            else if((serviceid==='9d063f53-5e50-4b05-b543-bfa0fcb6da58') || (serviceid==='7b1bb81c-51b3-43cd-95bf-1980e84ef6fd') || (serviceid==='b1dcd895-4c99-4e86-a8b1-97793e189c63') || (serviceid==='5cdd8209-5daf-40d2-a9ea-47048b141fc8')
                                || (serviceid==='1c90c949-f2d1-4997-84b0-e6b2d40fe5e6') || (serviceid==='2c520622-d89a-42a1-ae85-efa54ee3630f') || (serviceid==='6c3b5015-2251-404a-bf94-166ed093a23b') || (serviceid==='429a67a7-e607-41e3-a5e1-2e071e01dd3d') || (serviceid==='7e6e6f13-d78b-4f36-9b82-5623e0602975'))
                            {
                                $window.location = url + '?Projectid='+projectid+'&ServiceId='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //Labour
                            else if((serviceid==='ceb9286c-d5a9-4309-8505-c70279b88b4c') || (serviceid==='fbb359c8-e545-4a92-a57d-85ac14541e73') || (serviceid==='2b9b27f0-28b3-4d1d-baf1-037a0ce53c67') || (serviceid==='ead00143-65ac-415b-8ebd-7f3d44fdfe34')
                                || (serviceid==='4d85842e-79ed-4c02-98b4-d561f19b2b85') || (serviceid==='eeb5f718-76a7-49ff-af69-7ce67106ca61') || (serviceid==='bcbe8c5b-32e7-4181-b931-eea2cac22708') || (serviceid==='30a78b54-1016-4779-9197-0883eaf43d22') || (serviceid==='a2172fbe-dcd5-4aef-9154-4d3b5ca16d7f'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //DEPARTMENT OF INDUSTRIES & COMMERCE - Boiler Registration
                            else if((serviceid==='cd7a35d5-d697-468c-94c8-f773cd8bb262'))
                            {
                                $window.location = url + projectid+'_'+serviceid+'_'+reApplyCounter+'.pdf';
                            }
                            //DEPARTMENT OF INDUSTRIES & COMMERCE - Renewal of Boiler Registration
                            else if((serviceid==='f82dce85-acb3-49f9-b3a5-85522c483f6b'))
                            {
                                $window.location = url + projectid+'_'+serviceid+'_'+reApplyCounter+'.pdf';
                            }
                            //DEPARTMENT OF INDUSTRIES & COMMERCE - Lubricating license for manufacturing
                            else if((serviceid==='0ad4260f-99df-4cc9-98e9-65d58e1e2069'))
                            {
                                $window.location = url + projectid+'_'+serviceid+'_'+reApplyCounter+'.pdf';
                            }
                            //DEPARTMENT OF INDUSTRIES & COMMERCE - Lubricating license for trading
                            else if((serviceid==='4ad9c76b-c05f-4b35-ba91-0a764dcaa1a5') || (serviceid==='bf199052-f819-4bf6-88f2-004f49ed238b'))
                            {
                                $window.location = url + projectid+'_'+serviceid+'_'+reApplyCounter+'.pdf';
                            }
                            //HSIIDC
                            else if((serviceid==='077f3112-69e6-41ea-a2e5-75b4925d6900') || (serviceid==='a69786f5-c674-4de9-8807-8689c7ef86b5') || (serviceid==='b3b554c2-6869-4330-bd6b-bfccf100f498') || (serviceid==='22b0f2ab-c7b7-436c-8cd5-fa4b5295159b') || (serviceid==='ed1de49f-62e4-48a8-b332-389ecbaecb01'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter+'.pdf';
                            }
                            //HSVP
                            else if((serviceid==='43575ec0-7dd3-40bd-b3c4-47638d17bf3c') || (serviceid==='b04665ae-00db-4c2e-b872-9c113b3b0e70') || (serviceid==='c5baea92-079d-44d3-9980-8b7d7d568f98') || (serviceid==='9b8f4307-4f88-4821-aec7-2dd2668041ab') || (serviceid==='a8ed9b41-68cc-4cdf-b393-6ae0302fca4b  '))
                            {
                                $window.location = url + projectid+'_'+serviceid+'_'+reApplyCounter+'.pdf';
                            }
                            //PHED
                            else if((serviceid==='c9570baf-a399-46e8-912b-b25f0d58066a') || (serviceid==='ccd073c3-e354-4722-90a7-71abaac3a036'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //MGD
                            else if((serviceid==='0f37cdd6-9816-4df2-89bf-40da4bd44a1c') || (serviceid==='dc98dff2-4efc-4638-9797-0c9c0f31a6c2')
                                || (serviceid==='fc45e0bb-0b60-44d4-ba14-0b85396cc653') || (serviceid==='62a65a72-c140-474e-9e05-8ed506d10db6')
                                || (serviceid==='79f8d127-577e-4140-ac89-7fb7a951904f') || (serviceid==='099ace9f-fb7f-4a75-bce2-343bb4e89261')
                                || (serviceid==='882c2e8c-f1e7-4a64-a4b1-fef2e053d7cb'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //DEPARTMENT OF AYUSH
                            else if((serviceid==='3468419f-d667-4abd-bf79-ae444b67ffa7') || (serviceid==='ca617a3d-048e-4684-8b67-6df84e8ae2a4') || (serviceid==='6e39ccec-1a2d-4f39-bf6d-e3b63f77dbb5') || (serviceid==='b04423c7-492a-4e00-90d0-e35573c6db2c')
                                || (serviceid==='f139928f-2c86-4412-8e9e-e528651eaeb2'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //Food & Drug
                            else if((serviceid==='f3264440-59b9-4069-a819-189a68e722da') || (serviceid==='6701d812-3e17-4ba3-97de-41dffa5c56b6') || (serviceid==='79714bde-edce-44e2-a911-04c2c6b90127') || (serviceid==='9d7d4fe7-7f0c-4650-93ce-18718d0cbf76') || (serviceid==='094a07bc-11d4-428c-95f3-e49d6a062d00'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //Food & Supply
                            else if((serviceid==='ee48492a-a335-4c26-98e9-b654240c1774') || (serviceid==='1c67a3b9-1396-491a-b82e-9afa16f34093') || (serviceid==='071a7ee8-0606-4583-b30b-7c761b9941a2') || (serviceid==='ed9e795c-ef58-4a57-8f66-8c814feccd74') || (serviceid==='c870e3a1-d080-4a38-8cff-5425c9e4454b') || (serviceid==='09efed7f-49a7-43cb-9c1f-1c86a5535ae0')
                                || (serviceid==='9a37e49e-8d6f-4c11-8e4b-0fe1dbd7ae1a'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //Information, Public Relations and Language	
                            else if((serviceid==='935dc4c7-e7c8-4398-a9ce-4bd5ab491476'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                            //Agriculuture Dept
                            else if((serviceid==='1af4cdaf-0c51-4e57-bb0a-6a2a77ccbfe7') || (serviceid==='54e8e70a-4ee6-4863-af7f-769f92f43796'))
                            {
                                $window.location = url + '?projectid='+projectid+'&serviceid='+serviceid+'&reApplyCounter='+reApplyCounter;
                            }
                        }, 1000)
                            .then(function() {
                                defer.resolve('success');
                            }, function() {
                                defer.reject('error');
                            });
                        return defer.promise;
                    }
                };
            }
        ]);
})();
