(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DeptServiceDashboardController', DeptServiceDashboardController);

    DeptServiceDashboardController.$inject = ['$scope','$filter', 'Principal', '$stateParams', 'LoginService', '$state', 'DeptServiceDashboard', 'DeptServiceStats','ServiceViewCaf','Service_dashboard_output','$location','DeptServiceStatsElastic','GetFilterWiseDashboardData','District'];

    function DeptServiceDashboardController ($scope,$filter, Principal,$stateParams , LoginService, $state, DeptServiceDashboard, DeptServiceStats, ServiceViewCaf, Service_dashboard_output,$location,DeptServiceStatsElastic, GetFilterWiseDashboardData,District) {
        var vm = this;
        vm.account = null;
        vm.districts = [];
        vm.departments =[];
        vm.filter = {};
        vm.deptName = null;
        vm.districtName = null;
        vm.levelfiled = null;

        loadAll();
        function loadAll() {
             vm.auth = $stateParams.auth;
        Principal.identity().then(function(account) {
            vm.account = account;
            ga('set', 'page', $location.path());
            ga('send','pageview');


        });


        District.query(function(result) {
            vm.districts = result;
            vm.searchQuery = null;
        });

            DeptServiceStatsElastic.query(function(result) {
                vm.hideClear = true;
                vm.deptservicedashboard = result;
          vm.totalappliedservices=0;
          vm.totalrequiredservices=0;
          vm.totalpendingservices=0;
          vm.totalcompletedservices=0;
          vm.totalrejectedservices=0;
          vm.totaldeemedclearedservices = 0;
          vm.totaldeactivatedservices = 0;
           for(var i=0;i<vm.deptservicedashboard.length;i++)
           {
             vm.totalappliedservices=vm.totalappliedservices + parseInt(vm.deptservicedashboard[i].applied);
             vm.totalrequiredservices=vm.totalrequiredservices+ parseInt(vm.deptservicedashboard[i].required);
             vm.totalpendingservices=vm.totalpendingservices+ parseInt(vm.deptservicedashboard[i].pending_total);
             vm.totalcompletedservices=vm.totalcompletedservices+ parseInt(vm.deptservicedashboard[i].cleared);
             vm.totalrejectedservices=vm.totalrejectedservices+ parseInt(vm.deptservicedashboard[i].rejected);
             vm.totaldeemedclearedservices=vm.totaldeemedclearedservices+ parseInt(vm.deptservicedashboard[i].deemed_cleared);
             vm.totaldeactivatedservices=vm.totaldeactivatedservices+ parseInt(vm.deptservicedashboard[i].deactivated);
           }
           vm.searchQuery = null;
                vm.departments = [];

                angular.forEach(vm.deptservicedashboard, function(value, key){
                    if(vm.departments.indexOf(value.department_name) === -1){
                        vm.departments.push(value.department_name);
                    }
                });
            });
        }
        vm.FilterWiseDat = function () {
            GetFilterWiseDashboardData.query(vm.filter,function (result) {
                vm.hideClear = false;
                vm.deptservicedashboard =result;
            })
        };
        vm.clearFilter = function () {
            vm.filter ={};
            loadAll();
        }

    }
})();
