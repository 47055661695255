(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('clearancesController', clearancesController);

    clearancesController.$inject = ['$scope', '$stateParams','Principal', 'LoginService', '$state', 'Clearance'];

    function clearancesController ($scope, $stateParams, Principal, LoginService, $state, Clearance) {
        var vm = this;
        vm.questionnaire = $stateParams.questionnaire;
        vm.answers=[];
        loadAll();
       function loadAll(){ga('set', 'page', '/Know-Your-Clearance-Show-my-clearances');ga('send','pageview');}
        if(vm.questionnaire.typeofestablishment=='Pre-Operational')
        {
            Str_txt={
                "clearance": "GST/CST/VAT registration",
                "stage": '',
                "department": "Excie & taxatation",
                "url": "page25"
            };
            vm.answers.push(Str_txt);
            if(vm.questionnaire.category=='Red' || vm.questionnaire.category=='Orange' || vm.questionnaire.category=='Green')
            {
                var Str_txt3=    {
                    "clearance": "Consent to operate under Water Act, 1974 and the Air Act, 1981",
                    "stage": 3,
                    "department": "HSPCB",
                    "url":"page3"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.hazardouswaste=='E-waste') {
                var Str_txt3=    {
                    "clearance": "Registration/ Authorization under e-waste (Management & Handing) Rules, 2011",
                    "stage": 3,
                    "department": "Haryana state pollution controll board",
                    "url": "page4"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.hazardouswaste=='hazardous waste') {
                var Str_txt3=    {
                    "clearance": "Registration under hazardous waste rule 2016",
                    "stage": 3,
                    "department": "Haryana state pollution controll board",
                    "url":"page5"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.hazardouswaste=='bio-medical waste') {
                var Str_txt3=    {
                    "clearance": "Authorization under bio medical waste ( management & handling )rules, 2016",
                    "stage": 3,
                    "department": "Haryana state pollution controll board",
                    "url": "page7"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.electrictype == 'Permanent Connection') {
                var Str_txt3=    {
                    "clearance": "Release of permanent/regular electric connection,Indian electricity Act, 2003",
                    "stage": 1,
                    "department": "UHBVN/DHBVN",
                    "url":"page14"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.natureofland=='Within Controlled Area & Urban Area') {
                var Str_txt2={
                        "clearance": "Building Plan Approval",
                        "stage": 2,
                        "department": "Town and Country Planning",
                        "url": "page24"
                    };
                vm.answers.push(Str_txt2);
            }
            if(vm.questionnaire.natureofland=='Industrial')
            {
                vm.answers.push(Str_txt1);
                var Str_txt2={
                    "clearance": "Building Plan Approval",
                    "stage": 2,
                    "department": "HSIIDC",
                    "url": "hsiidc2"
                };
                vm.answers.push(Str_txt2);
                var Str_txt4={
                    "clearance": "Issue of completion/occupation certificate",
                    "stage": 3,
                    "department": "HSIIDC",
                    "url": "hsiidc1"
                };
                vm.answers.push(Str_txt4);
                var Str_txt5={
                    "clearance": "Release of permanent sewarage connection",
                    "stage": 3,
                    "department": "HSIIDC",
                    "url": "hsiidc4"
                };
                vm.answers.push(Str_txt5);
            }
        }

        if(vm.questionnaire.typeofestablishment=='Post-Establishment')
        {
            Str_txt={
                "clearance": "Factory registration/ license under Factories Act, 1948-Safety Wing",
                "stage": '',
                "department": "Labour department",
                "url": "factlicense"
            };
            vm.answers.push(Str_txt);
        }

        if(vm.questionnaire.typeofestablishment=='Pre-Establishment')
        {
            Str_txt={
                "clearance": "NOC for fire service",
                "stage": '',
                "department": "Fire Department",
                "url": "table33"
            };
            vm.answers.push(Str_txt);
            Str_txt={
                "clearance": "	Forest clearance for approach public entry/exit and diversion of forest land-under Forest Conservation Act,1980",
                "stage": '',
                "department": "Forest Department",
                "url": "page26"
            };
            vm.answers.push(Str_txt);
            Str_txt={
                "clearance": "Approval of factory plan under the Factories Act, 1948",
                "stage": '',
                "department": "Labour department",
                "url": "table45"
            };
            vm.answers.push(Str_txt);
            if(vm.questionnaire.category=='Red' || vm.questionnaire.category=='Orange' || vm.questionnaire.category=='Green')
            {
                var Str_txt3=    {
                    "clearance": "Consent for establishment under Water Act, 1974 and the Air Act, 1981",
                    "stage": 1,
                    "department": "HSPCB",
                    "url":"page1"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.natureofland=='Under Controlled area & outside extended Mc limit') {
                var Str_txt1 = {
                    "clearance": "Change of land use under Controlled Area Act 1963",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url":"page16"
                };
                vm.answers.push(Str_txt1);
            }
            if(vm.questionnaire.requireexcavation==true) {
                var Str_txt3=    {
                    "clearance": "License for brick klin",
                    "stage": 3,
                    "department": "Town and Country Planning",
                    "url": "page17"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.natureofland=='Outside MC limit & beyond controlled area') {
                var Str_txt1 = {
                    "clearance": "NOC for establishment of unit under Urban Area Act",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url": "page21"
                };
                vm.answers.push(Str_txt1);
            }
            if(vm.questionnaire.natureofland=='Within Controlled Area & Urban Area') {
                var Str_txt1= {
                    "clearance": "NOC for establishment of unit under Urban Area Act",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url":"page22"
                };
                vm.answers.push(Str_txt1);
                var Str_txt3={
                    "clearance": "Occupation/completion certificate",
                    "stage": 3,
                    "department": "Town and Country Planning",
                    "url":"page23"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.underaravali==true) {
                var Str_txt3=    {
                    "clearance": "Environmental clearance (EC) under Aravali Notification 1992 & 1999",
                    "stage": 1,
                    "department": "Enviornment Department",
                    "url":"page56"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.typeofoperation=='Service Business')
            {
                Str_txt={
                    "clearance": "Shop registration under Shops and Establishment Act-Labour Wing",
                    "stage": '',
                    "department": "Labour",
                    "url": "table47"
                };
                vm.answers.push(Str_txt);
            }
            if(vm.questionnaire.typeofoperation=='Trade Business')
            {
                Str_txt={
                    "clearance": "Shop registration under Shops and Establishment Act-Labour Wing",
                    "stage": '',
                    "department": "Labour",
                    "url":"table47"
                };
                vm.answers.push(Str_txt);
            }

            if(vm.questionnaire.typeofownership=='Partnership')
            {

                var Str_txt={
                    "clearance": "Registration of Firms under Indian Partnership Act. 1932",
                    "stage": 1,
                    "department": "Industries & Commerce Department",
                    "url": "inds4"
                };
                vm.answers.push(Str_txt);
            }

            if(vm.questionnaire.natureofland=='Industrial')
            {
                var Str_txt1={
                    "clearance": "Allotment of Plot",
                    "stage": 1,
                    "department": "HSIIDC"
                };
                vm.answers.push(Str_txt1);
                var Str_txt3={
                    "clearance": "Release of permanent water connection",
                    "stage": 2,
                    "department": "HSIIDC",
                    "url": "hsiidc3"
                };
                vm.answers.push(Str_txt3);

                var Str_txt7={
                    "clearance": "Issuance of Trade License Under section 330 for factory, workshop or trade remises. In which it is intended to employ steam, electricity, water or other mechanical power.",
                    "stage": 3,
                    "department": "Urban & local Bodies",
                    "url":"table34"
                };
                vm.answers.push(Str_txt7);

            }
            if(vm.questionnaire.natureofland=='Within Controlled Area & Urban Area') {
                var Str_txt1= {
                    "clearance": "NOC for establishment of unit under Urban Area Act",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url":"page22"
                    };
                vm.answers.push(Str_txt1);
                var Str_txt3={
                        "clearance": "Occupation/completion certificate",
                        "stage": 3,
                        "department": "Town and Country Planning",
                        "url":"page23"
                    };
                vm.answers.push(Str_txt3);

            }
            if(vm.questionnaire.natureofland=='With in MC Limit') {
                var Str_txt1 = {
                    "clearance": "Change of land use Within Municipal Limit",
                    "stage": 1,
                    "department": "Urban & local Bodies"
                };
                vm.answers.push(Str_txt1);
                var Str_txt2= {
                        "clearance": "Approval of building plan",
                        "stage": 2,
                        "department": "Urban & local Bodies",
                        "url": "table37"
                    };
                vm.answers.push(Str_txt2);
                var Str_txt4= {
                        "clearance": "Issuance of Water connection",
                        "stage": 2,
                        "department": "Urban & local Bodies"
                    };
                vm.answers.push(Str_txt4);

                var Str_txt6=   {
                        "clearance": "Issuance of Trade License Under section 330 for factory, workshop or trade remises. In which it is intended to employ steam, electricity, water or other mechanical power.",
                        "stage": 3,
                        "department": "Urban & local Bodies",
                        "url": "table34"
                    };
                vm.answers.push(Str_txt6);
                var Str_txt7=    {
                        "clearance": "Occupation/completion certificate",
                        "stage": 3,
                        "department": "Urban & local Bodies",
                        "url":"table36"
                    };
                vm.answers.push(Str_txt7);
                var Str_txt8=    {
                        "clearance": "Issuance of sewarage connection",
                        "stage": 3,
                        "department": "Urban & local Bodies",
                        "url": "table41"
                };
                vm.answers.push(Str_txt8);
            }
            if(vm.questionnaire.natureofland=='Under Controlled area & within extended Mc limit') {
                var Str_txt1 = {
                    "clearance": "Change of land use Within Municipal Limit",
                    "stage": 1,
                    "department": "Urban & local Bodies"
                };
                vm.answers.push(Str_txt1);
                var Str_txt2=    {
                        "clearance": "Approval of building plan",
                        "stage": 2,
                        "department": "Urban & local Bodies",
                        "url":"table37"
                    };
                vm.answers.push(Str_txt2);

                var Str_txt4=    {
                        "clearance": "Issuance of Water connection",
                        "stage": 2,
                        "department": "Urban & local Bodies"
                    };
                vm.answers.push(Str_txt4);
                var Str_txt6=    {
                        "clearance": "Issuance of Trade License Under section 330 for factory, workshop or trade remises. In which it is intended to employ steam, electricity, water or other mechanical power.",
                        "stage": 3,
                        "department": "Urban & local Bodies",
                        "url": "table34"
                    };
                vm.answers.push(Str_txt6);
                var Str_txt7=    {
                        "clearance": "Occupation/completion certificate",
                        "stage": 3,
                        "department": "Urban & local Bodies",
                        "url":"table36"
                    };
                vm.answers.push(Str_txt7);
                var Str_txt8=    {
                        "clearance": "Issuance of sewarage connection",
                        "stage": 3,
                        "department": "Urban & local Bodies",
                        "url":"table41"
                    };
                vm.answers.push(Str_txt8);
            }
            if(vm.questionnaire.natureofland=='Under Controlled area & outside extended Mc limit') {
                var Str_txt1 = {
                    "clearance": "Change of land use under Controlled Area Act 1963",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url":"page16"
                };
                vm.answers.push(Str_txt1);
                var Str_txt3=    {
                        "clearance": "Occupation/completion certificate",
                        "stage": 3,
                        "department": "Town and Country Planning",
                        "url": "page23"
                    };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.natureofland=='Outside MC limit & beyond controlled area') {
                var Str_txt1 = {
                    "clearance": "NOC for establishment of unit under Urban Area Act",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url": "page21"
                };
                vm.answers.push(Str_txt1);

                var Str_txt3=    {
                        "clearance": "Occupation/completion certificate",
                        "stage": 3,
                        "department": "Town and Country Planning",
                        "url": "page23"
                    };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.natureofland=='Outside controlled area & urban area') {
                var Str_txt1 = {
                    "clearance": "NOC for unit falling outside controlled/urban area",
                    "stage": 1,
                    "department": "Town and Country Planning",
                    "url":"page21"
                };
                vm.answers.push(Str_txt1);

                var Str_txt3=    {
                    "clearance": "Occupation/completion certificate",
                    "stage": 3,
                    "department": "Town and Country Planning",
                    "url": "page23"
                };
                vm.answers.push(Str_txt3);
            }

            if(vm.questionnaire.natureofland=='Forest land') {
                var Str_txt2=    {
                    "clearance": "Tree felling irrespective of location of land",
                    "stage": 1,
                    "department": "Forest Department",
                    "url": "forest2"
                };
                vm.answers.push(Str_txt2);
            }
            if(vm.questionnaire.sourceofwater=='Ground Water') {
                var Str_txt1 = {
                    "clearance": "Permission to Dig New Wells",
                    "stage": 1,
                    "department": "Irrigation Department"
                };
                vm.answers.push(Str_txt1);
            }
            if(vm.questionnaire.sourceofwater=='Municiple Corporation') {
                var Str_txt2 = {
                    "clearance": "Issuance of Water connection",
                    "stage": 2,
                    "department": "Urban & local Bodies"
                };
                vm.answers.push(Str_txt2);
            }
            if(vm.questionnaire.sourceofwater=='River & Public Tank') {
                var Str_txt3=    {
                    "clearance": "Permission ro draw water from River / Public Tank",
                    "stage": 1,
                    "department": "Irrigation Department"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.sourceofwater=='Urban water supply') {
                var Str_txt3=    {
                    "clearance": "Issuance of Water connection",
                    "stage": 2,
                    "department": "Urban & local Bodies"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.sourceofwater=='HSIIDC') {
                var Str_txt3=    {
                    "clearance": "Release of permanent water connection",
                    "stage": 2,
                    "department": "HSIIDC",
                    "url": "hsiidc3"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.loadrequired >=11) {
                var Str_txt3=    {
                    "clearance": "Certification of Electrical installation by Chief Electrical Inspector",
                    "stage": 3,
                    "department": "Power Department(11kv to 33kv:- certification by Charted Engineer / Chief elcetrical Engineer, " +
                    "Above 33 kv:- Certification Only by Chief Electical Engineer of Department)"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.migrantworker=='Yes') {
                var Str_txt3=    {
                    "clearance": "Establishment inter state migrant workmen (RE&CS) Act 1979",
                    "stage": 3,
                    "department": "labour department",
                    "url":"table48"
                };
                vm.answers.push(Str_txt3);
            }

            if(vm.questionnaire.contractworker=='Yes') {
                var Str_txt3=    {
                    "clearance": "Registration of the Principal Employer (Who is engaging the workers)under the Contracts Labour (Regulation & Abolition)Act,1970",
                    "stage": 3,
                    "department": "labour department",
                    "url":"table43"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.operatingboilers==true) {
                var Str_txt3=    {
                    "clearance": "Registration under boiler act",
                    "stage": 3,
                    "department": "Industries and commerce department",
                    "url": "inds1"
                };
                vm.answers.push(Str_txt3);
            }

            if(vm.questionnaire.purchaseland == true) {
                var Str_txt3=    {
                    "clearance": "Land/Plot allotment",
                    "stage": 1,
                    "department": "HSIIDC",
                    "url": "hsiidc5"
                };
                vm.answers.push(Str_txt3);
            }
            else{
                var Str_txt3=    {
                    "clearance": "Registration of Land/Property",
                    "stage": 1,
                    "department": "Department of Revenue and Disaster Management",
                    "url": "dradm"
                };
                vm.answers.push(Str_txt3);
            }





            if(vm.questionnaire.hazardouswaste=='municipal solid waste') {
                var Str_txt3=    {
                    "clearance": "Authorization under Municiple Solid Waste ( management & handling )rules, 2016",
                    "stage": 3,
                    "department": "Haryana state pollution controll board"
                };
                vm.answers.push(Str_txt3);
            }

            if(vm.questionnaire.motortransport==true) {
                var Str_txt3=    {
                    "clearance": "Registration under the Motor Transport Workers Act, 1961",
                    "stage": 3,
                    "department": "labour department",
                    "url":"table49"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.involveallopathic=='Allopathic  medicine') {
                var Str_txt3=    {
                    "clearance": "Drug license for allopathic medicine",
                    "stage": 3,
                    "department": "Department of health services",
                    "url":"page60"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.involveallopathic=='Ayurvedic medicine') {
                var Str_txt3=    {
                    "clearance": "Drug license for ayurvedic medicine",
                    "stage": 3,
                    "department": "Ayush department",
                    "url":"page58"
                };
                vm.answers.push(Str_txt3);
            }

            if(vm.questionnaire.lubricatinglicense==true) {
                var Str_txt3=    {
                    "clearance": "Lubricant license",
                    "stage": 3,
                    "department": "Industries and commerce department",
                    "url": "inds2"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.commercial==true) {
                var Str_txt3=    {
                    "clearance": "Shop registration under Shops and Establishment Act",
                    "stage": 3,
                    "department": "labour department",
                    "url": "table47"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.involvepesticides=='Pesticide or Insecticide') {
                var Str_txt3=    {
                    "clearance": "License to manufacture Insecticides/Pesticides",
                    "stage": 1,
                    "department": "Agriculture department",
                    "url": "table54"
                };
                vm.answers.push(Str_txt3);
            }
            if(vm.questionnaire.involvepesticides=='Fertilizer') {
                var Str_txt3=    {
                    "clearance": "Licence for manufacturing fertilizer",
                    "stage": 1,
                    "department": "Agriculture department",
                    "url":"table53"
                };
                vm.answers.push(Str_txt3);
            }

            if(vm.questionnaire.underbocwact==true) {
                var Str_txt3=    {
                    "clearance": "Registration of Establishment engaged in Building & other Construction activity under Building and Other Construction Workers (RE&CS) Act, 1996",
                    "stage": 2,
                    "department": "Labour department",
                    "url": "table42"
                };
                vm.answers.push(Str_txt3);
            }


            if(vm.questionnaire.involveenergy==true) {
                var Str_txt3=    {
                    "clearance": "Registration & approval for setting up of Biomass based power projects in the State",
                    "stage": 1,
                    "department": "Non-Conventional Energy/ Renewal Energy Deptt"
                };
                vm.answers.push(Str_txt3);
            }



            if(vm.questionnaire.undereia==true) {
                var Str_txt3=    {
                    "clearance": "Environment clearance plan from State/Central Environment Impact Assessment Authority- EIA notification 2006.",
                    "stage": 1,
                    "department": "Enviornment Department",
                    "url":"page57"
                };
                vm.answers.push(Str_txt3);
            }

            if(vm.questionnaire.electrictype=='Temporary Connection') {
                var Str_txt3=    {
                    "clearance": "Agreement for temporary connection and load sanctioning, Indian electricity Act, 2003",
                    "stage": 1,
                    "department": "UHBVN/DHBVN",
                    "url":"uhbvn2"
                };
                vm.answers.push(Str_txt3);
            }
        }
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

    }
})();
