(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketsController', TicketsController);

    TicketsController.$inject = ['Tickets'];

    function TicketsController(Tickets) {

        var vm = this;
        vm.tickets = [];
        vm.search = false;
        vm.searchTicket = searchTicket;

        function searchTicket(columnValues) {
            vm.search = true;
            Tickets.query({columnKey: "ticketNumber", columnValue: columnValues }, function (result) {
                vm.tickets=result;
            });
        }

    }
})();
