(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewDasboardAllCompletedStatsController', NewDasboardAllCompletedStatsController);

    NewDasboardAllCompletedStatsController.$inject = ['NewDasboardAllCompletedStats'];

    function NewDasboardAllCompletedStatsController(NewDasboardAllCompletedStats) {

        var vm = this;

        vm.newDasboardAllCompletedStats = [];

        loadAll();

        function loadAll() {
            NewDasboardAllCompletedStats.query(function(result) {
                vm.newDasboardAllCompletedStats = result;
                vm.searchQuery = null;
            });
        }
    }
})();
