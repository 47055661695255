(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ApplicationLogsDetailController', ApplicationLogsDetailController);

    ApplicationLogsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ApplicationLogs'];

    function ApplicationLogsDetailController($scope, $rootScope, $stateParams, previousState, entity, ApplicationLogs) {
        var vm = this;

        vm.applicationLogs = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:applicationLogsUpdate', function(event, result) {
            vm.applicationLogs = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
