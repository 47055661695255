(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('projects-home', {
            parent: 'app',
            url: '/projects-home',
            data: {
                authorities: ['ROLE_HOME_CTM','ROLE_ADMIN', 'ROLE_HOME_SUPERINTENDENT','ROLE_HOME_DC','ROLE_HOME_ASSISTANT']
            },
            views: {
                'content@': {
                    templateUrl: 'app/showprojects-home/projects_home.html',
                    controller: 'HomeProjectsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                projectAttachemnt: function () {
                    return {
                        fileName: null,
                        description: null,
                        fileExtension: null,
                        serverFileName: null,
                        id: null
                    };
                },
                projectServiceLog: function () {
                    return {
                        projectid: null,
                        serviceid: null,
                        comments: null,
                        commentDate: null,
                        commentByUserLogin: null,
                        actionTaken: null,
                        id: null
                    };
                },
                entity: function () {
                    return {
                        projectId: null,
                        serviceId: null,
                        cafPin: null,
                        district: null,
                        status: null,
                        serviceName: null,
                        businessEntity: null,
                        departmentName: null,
                        ageing: null,
                        lastComments: null,
                        lastActionDate: null,
                        id: null
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('state');
                    return $translate.refresh();
                }]
            }
        })
            .state('action-home', {
                parent: 'projects-home',
                url: '/{action}/{projectserviceid}',
                data: {
                    authorities: ['ROLE_HOME_CTM','ROLE_ADMIN', 'ROLE_HOME_ASSISTANT', 'ROLE_HOME_SUPERINTENDENT','ROLE_HOME_DC']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/showprojects-home/projects-dialog_home.html',
                        controller: 'HomeProjectDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            projectAttachemnt: function () {
                                return {
                                    fileName: null,
                                    description: null,
                                    fileExtension: null,
                                    serverFileName: null,
                                    id: null
                                };
                            },
                            projectServiceLog: function () {
                                return {
                                    projectid: null,
                                    serviceid: null,
                                    comments: null,
                                    commentDate: null,
                                    commentByUserLogin: null,
                                    actionTaken: null,
                                    id: null
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                $translatePartialLoader.addPart('home');
                                return $translate.refresh();
                            }],
                            projectService: ['Projectservicedetail', function(Projectservicedetail) {
                                return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                            }],
                            homeProjectService: ['HomeProjectServiceDetails', function(HomeProjectServiceDetails) {
                                return HomeProjectServiceDetails.get({id : $stateParams.projectserviceid}).$promise;
                            }],

//                            projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
//                                return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
//                            }]
                        }
                    }).result.then(function() {
                        $state.go('projects-home', null, { reload: 'projects-home' });
                    }, function() {
                        $state.go('projects-home');
                    });
                }]
            });

    }
})();
