(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('assignOtherServiceDialogController', assignOtherServiceDialogController);

    assignOtherServiceDialogController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','projectService','projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLogUpdated','AssignServiceFee','ProjectServiceReportInfoForClearance'];
    function assignOtherServiceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, projectService, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, AssignServiceFee , ProjectServiceReportInfoForClearance) {
        var vm = this;
        vm.actionflag=false;
        vm.assignServiceFee= {};
        vm.projectServiceLog={};
        vm.projectService = projectService;

        vm.addfeeDetail = AssignServiceFee.query({id:"1", projectid:vm.projectService.projectid, serviceid:vm.projectService.serviceid});
        var projectServiceLogid=null;
        var isDelete=null;
        var deleteID=[];
        vm.clear = clear;
        vm.assignOtherService = assignOtherService;
        vm.addOtherService = addOtherService;
        vm.deleteRow = deleteRow;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function assignOtherService(){
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.actionTaken="Assigned Other Fee";
            vm.projectServiceLog.comments="Assigned Other Fee";
            ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess);
        }
       function onServiceLogSaveSuccess(result)
       {
            projectServiceLogid=result.id;
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            vm.projectService.isAssigned=true;
            saveOtherService();
       }
        function saveOtherService(result)
        {
           var vmlength=vm.addfeeDetail.length;
           for(var i=0; i < vmlength; i++)
           {
              vm.addfeeDetail[i].projectid=vm.projectServiceLog.projectid;
              vm.addfeeDetail[i].serviceid=vm.projectServiceLog.serviceid;
              if(vm.addfeeDetail[i].isSaved===false)
              {
                vm.addfeeDetail[i].isSaved=true;
                AssignServiceFee.save(vm.addfeeDetail[i]);
              }
            }
           if(isDelete==1)
           {
             for(var j=0; j < deleteID.length; j++)
             {
              AssignServiceFee.delete({id: deleteID[j]});
             }
           }
           vm.addfeeDetail=[];
         $uibModalInstance.close(result);
        }
        function deleteRow(id)
         {
           var index = -1;
           var comArray =eval(vm.addfeeDetail);
           for( var i = 0; i < comArray.length; i++ )
           {
              if( comArray[i].id=== id)
               {
                   index = i;
                   vm.addfeeDetail.splice(index, 1 );
                   deleteID.push(id);
                   isDelete=1;
               }
           }
           vm.actionflag=true;
         }
        function addOtherService()
        {
         vm.assignServiceFee.isPaymentMade=false;
         vm.assignServiceFee.isSaved=false;
         vm.addfeeDetail.push(vm.assignServiceFee);
         vm.assignServiceFee={};
         document.getElementById("resetForm").reset();
         vm.actionflag=true;
        }

    }
})();
