(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TotalDeptDashboardDetailController', TotalDeptDashboardDetailController);

    TotalDeptDashboardDetailController.$inject = ['$scope', '$stateParams', 'Principal', '$uibModalInstance', 'LoginService', '$state', 'TotalDeptDashboardDetail'];

    function TotalDeptDashboardDetailController ($scope, $stateParams, Principal, $uibModalInstance, LoginService, $state, TotalDeptDashboardDetail) {
        var vm = this;
        vm.status = $stateParams.status;
        vm.clear = clear;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        function clear () {
        $uibModalInstance.dismiss('cancel');
        }

        loadAllCafs();
        function loadAllCafs() {
            vm.totaldeptdashboarddetail=TotalDeptDashboardDetail.query({status:$stateParams.status},function (result) {
                vm.show= true;
            });
        }
    }
})();
