(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Visitor_countController', Visitor_countController);

    Visitor_countController.$inject = ['Visitor_count'];

    function Visitor_countController(Visitor_count) {

        var vm = this;

        vm.visitor_counts = [];

        loadAll();

        function loadAll() {
            Visitor_count.query(function(result) {
                vm.visitor_counts = result;
                vm.searchQuery = null;
            });
        }
    }
})();
