(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AboveGroundDetailController', AboveGroundDetailController);

    AboveGroundDetailController.$inject = ['$scope', '$state', '$stateParams', 'entity', 'Above_ground', 'District', 'Zone','FileManagement'];

    function AboveGroundDetailController($scope, $state, $stateParams, entity, Above_ground, District, Zone,FileManagement) {
        var vm = this;
        vm.save= save;

        vm.above_ground = entity;
        console.log(entity);
        vm.edit_form= edit_form;
        vm.download=download;
        vm.upload=upload;
        var savefilename=null;

        $scope.file={};
        $scope.isDisabled =true;

        loadAll();

        function loadAll() {
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
            });
            Zone.query(function(result) {
                vm.zones = result;
                vm.searchQuery = null;
            });
        }

        function save () {
            vm.isSaving = true;
            vm.show1= true;
            if (vm.above_ground.id !== null) {
                vm.above_ground.status = 'Pending';
                Above_ground.update(vm.above_ground, onSaveSuccess, onSaveError);
            } else {
                vm.above_ground.isfullyfilled = false;
                Above_ground.save(vm.above_ground, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $state.go("aboveGround");
            //$scope.$emit('investhryApp:above_groundUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function edit_form() {
            vm.show= true;
            $scope.isDisabled =false;
        }

        function download(serverfilename,clientfilename) {
            downloadserviceformattachment.download(serverfilename,clientfilename);
        }

        function  upload(i,object) {
            var fileNameArray = object.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var filename = vm.above_ground.id+'_'+ i;
            savefilename=filename+ "." + fileextension;
            var file = object;
            FileManagement.saveFile(file,filename,onFileUploadedSuccess,onFileUploadedError);
            vm.tempvar=i;
        }


        function onFileUploadedSuccess() {
            UIkit.notification("File Uploaded Successfully . . .",{timeout: 2000});
         if(vm.tempvar==1){
                vm.show_upload1=true;
                vm.above_ground.document1= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==2){
                vm.show_upload2=true;
                vm.above_ground.document2= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==3){
                vm.show_upload3=true;
                vm.above_ground.document3= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==4){
                vm.show_upload4=true;
                vm.above_ground.document4= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==5){
                vm.show_upload5=true;
                vm.above_ground.document5= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==6){
                vm.show_upload6=true;
                vm.above_ground.document6= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==7){
                vm.show_upload7=true;
                vm.above_ground.document7= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==8){
                vm.show_upload8=true;
                vm.above_ground.document8= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==9){
                vm.show_upload9=true;
                vm.above_ground.document9= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==10){
                vm.show_upload10=true;
                vm.above_ground.document10= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==11){
                vm.show_upload11=true;
                vm.above_ground.document11= savefilename;
                vm.above_ground.isfullyfilled = true;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==12){
                vm.show_upload12=true;
                vm.above_ground.other_document1= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==13){
                vm.show_upload13=true;
                vm.above_ground.other_document2= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==14){
                vm.show_upload14=true;
                vm.above_ground.other_document3= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==15){
                vm.show_upload15=true;
                vm.above_ground.other_document4= savefilename;
                Above_ground.update(vm.above_ground);
            }
            else if(vm.tempvar==16) {
                vm.show_upload16=true;
                vm.above_ground.other_document5= savefilename;
                Above_ground.update(vm.above_ground);
            }

        }

        function onFileUploadedError() {
            UIkit.notification("Unexpected Error Occurred! Try Again . .",{timeout: 2000,status: 'danger'});
        }
    }
})();
