(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ConstructionPermitLogDeleteController',ConstructionPermitLogDeleteController);

    ConstructionPermitLogDeleteController.$inject = ['$uibModalInstance', 'entity', 'ConstructionPermitLog'];

    function ConstructionPermitLogDeleteController($uibModalInstance, entity, ConstructionPermitLog) {
        var vm = this;

        vm.constructionPermitLog = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ConstructionPermitLog.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
