(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ProjectCompleteDetailController', ProjectCompleteDetailController);

    ProjectCompleteDetailController.$inject = ['$scope', 'Principal', 'LoginService', '$state','entity','cafAttachments','Projectcompletedetail','Projectcompletedetaildata', 'GetImageForView','downloadService', 'District'];

    function ProjectCompleteDetailController ($scope, Principal, LoginService, $state, entity, cafAttachments, Projectcompletedetail,Projectcompletedetaildata, GetImageForView, downloadService, District) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.CompleteProjectDetail=entity;
        vm.cafAttachments=cafAttachments;
        vm.investorphoto=null;
        vm.investorsign=null;
        vm.downloadFile=downloadFile;

    
        for(var cafAttachmentscounter=0;cafAttachmentscounter<vm.cafAttachments.length;cafAttachmentscounter++)
        {
            if(vm.cafAttachments[cafAttachmentscounter].docName=="Applicant Photo")
            {
                var filename=vm.cafAttachments[cafAttachmentscounter].fileName;
                vm.investorphoto=GetImageForView.getImageSource(filename);
            }
            if(vm.cafAttachments[cafAttachmentscounter].docName=="Signature")
            {
                var filename=vm.cafAttachments[cafAttachmentscounter].fileName;
                vm.investorsign=GetImageForView.getImageSource(filename);
            }
        }

        function downloadFile(filename)
        {
            downloadService.download(filename)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

        vm.project = vm.CompleteProjectDetail.projectDTO;
//        vm.investor=vm.CompleteProjectDetail.investorDTO;
//        vm.companydetail=vm.CompleteProjectDetail.companydetailDTO;
//        vm.projectdetail=vm.CompleteProjectDetail.projectdetailDTO;
//        vm.projectsitedetail=vm.CompleteProjectDetail.projectsitedetailDTO;
//        vm.project_finance_investment=vm.CompleteProjectDetail.project_finance_investmentDTO;
//        vm.manufacturing_detail=vm.CompleteProjectDetail.manufacturingdetailDTO;
//        vm.term_declaration_accept=vm.CompleteProjectDetail.term_declaration_acceptDTO;
//        vm.electricrequirement=vm.CompleteProjectDetail.electricrequirementDTO;
//        vm.projectcombinecodes=vm.CompleteProjectDetail.projectdetailcombinecodesDTO;
//        vm.environmentimpactdetail=vm.CompleteProjectDetail.environment_impactdetailDTO;
        vm.project_phase=vm.CompleteProjectDetail.project_phaseDTOList;
        vm.projectrawmaterial=vm.CompleteProjectDetail.projectrawmaterialDTOList;
        vm.projectproduct=vm.CompleteProjectDetail.projectproductDTOList;
        vm.projectprocessflowsteps=vm.CompleteProjectDetail.projectprocessflowstepsDTOList;
        vm.emissiondetail=vm.CompleteProjectDetail.emissiondetailDTOList;
        vm.wastewaterdetail=vm.CompleteProjectDetail.wastewaterdetailDTOList;

//        if(vm.projectsitedetail.district !== null)
//        {
//             District.query({id:vm.projectsitedetail.district,districtName:vm.projectsitedetail.district},function(result) {
//                 vm.districts = result;
//                 vm.electricrequirement.powercompany = vm.districts[0].powercompany;
//             });
//        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
