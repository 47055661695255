(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('hepc-recruitment', {
            parent: 'entity',
            url: '/hepc-recruitment',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.hepcRecruitment.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/hepc-recruitment/hepc-recruitments.html',
                    controller: 'HepcRecruitmentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('hepcRecruitment');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('hepc-recruitment-detail', {
            parent: 'hepc-recruitment',
            url: '/hepc-recruitment/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.hepcRecruitment.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/hepc-recruitment/hepc-recruitment-detail.html',
                    controller: 'HepcRecruitmentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('hepcRecruitment');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'HepcRecruitment', function($stateParams, HepcRecruitment) {
                    return HepcRecruitment.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'hepc-recruitment',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('hepc-recruitment-detail.edit', {
            parent: 'hepc-recruitment-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/hepc-recruitment/hepc-recruitment-dialog.html',
                    controller: 'HepcRecruitmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['HepcRecruitment', function(HepcRecruitment) {
                            return HepcRecruitment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('hepc-recruitment.new', {
            parent: 'hepc-recruitment',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/hepc-recruitment/hepc-recruitment-dialog.html',
                    controller: 'HepcRecruitmentDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        fatherHusbandName: null,
                        gender: null,
                        dateOfBirth: null,
                        aadharNumber: null,
                        correspondenceAddress: null,
                        correspondenceDistrict: null,
                        correspondenceState: null,
                        permanentAddress: null,
                        permanentDistrict: null,
                        permanentState: null,
                        contactNumber: null,
                        alternateContactNumber: null,
                        email: null,
                        preferredLocation: null,
                        id: null
                    };
                }
            }
        })
        .state('hepc-recruitment.edit', {
            parent: 'hepc-recruitment',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/hepc-recruitment/hepc-recruitment-dialog.html',
                    controller: 'HepcRecruitmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['HepcRecruitment', function(HepcRecruitment) {
                            return HepcRecruitment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('hepc-recruitment', null, { reload: 'hepc-recruitment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('hepc-recruitment.delete', {
            parent: 'hepc-recruitment',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/hepc-recruitment/hepc-recruitment-delete-dialog.html',
                    controller: 'HepcRecruitmentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['HepcRecruitment', function(HepcRecruitment) {
                            return HepcRecruitment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('hepc-recruitment', null, { reload: 'hepc-recruitment' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
