(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TotalDeptServicesDetailController', TotalDeptServicesDetailController);

    TotalDeptServicesDetailController.$inject = ['$scope', '$window', '$stateParams','$filter', 'Principal', '$uibModalInstance', 'LoginService', '$state', 'TotalDasboardDetail'];

    function TotalDeptServicesDetailController ($scope, $window, $stateParams,$filter, Principal, $uibModalInstance, LoginService, $state, TotalDasboardDetail) {
        var vm = this;

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";
        vm.download = download;

        vm.status = $stateParams.status;
        vm.clear = clear;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        var args = [];

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        loadAllCafs();
        function loadAllCafs() {
            vm.totaldeptservicesdetail=TotalDasboardDetail.query({status:$stateParams.status},function (result) {
                vm.show=true;
            });
        }

        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
        }
        $scope.pageChangeHandler = function(num) {
        };

        function convertJsonsToArrays(args){
            var dataArray = [];
            var header = [ "CAFPin",
                "Business Entity",
                "District",
                "Department",
                "Investor Name",
                "Investor Contact",
                "Investor Email",
                "Service Name",
                "Project Level",
                "Proposed Employment",
                "Project Investment" ,
                "Applied Date",
                "Address",
                "Land Zone Use Type"
            ];
            dataArray.push(header);
            for( var i = 0; i < args.length; i++ ){
                var row = args[i];
                var rowAr=[];
                var appliedDate=$filter('date')(row.formfilleddate, 'dd-MM-yyyy');
                rowAr.push(row.cafpin);
                rowAr.push(row.businessentity);
                rowAr.push(row.district);
                rowAr.push(row.departmentname);
                rowAr.push(row.investorName);
                rowAr.push(row.investorContact);
                rowAr.push(row.investorEmail);
                rowAr.push(row.servicename);
                rowAr.push(row.projectlevel);
                rowAr.push(row.proposedemployement);
                rowAr.push(row.projectinvestment);
                rowAr.push(appliedDate);
                rowAr.push(row.address);
                rowAr.push(row.landZoneUseType);
                dataArray.push(rowAr);
            }
            return dataArray;
        }

        function convertJsonsToArrays1(args){
            var dataArray = [];
            var header = [ "CAFPin",
                "Business Entity",
                "District",
                "Department",
                "Investor Name",
                "Investor Contact",
                "Investor Email",
                "Service Name",
                "Project Level",
                "Proposed Employment",
                "Project Investment" ,
                "Applied Date",
                "Completion Date",
                "Address",
                "Land Zone Use Type"
            ];
            dataArray.push(header);
            for( var i = 0; i < args.length; i++ ){
                var row = args[i];
                var rowAr=[];
                var appliedDate=$filter('date')(row.formfilleddate, 'dd-MM-yyyy');
                var clearedDate=$filter('date')(row.serviceClearedDate, 'dd-MM-yyyy');
                rowAr.push(row.cafpin);
                rowAr.push(row.businessentity);
                rowAr.push(row.district);
                rowAr.push(row.departmentname);
                rowAr.push(row.investorName);
                rowAr.push(row.investorContact);
                rowAr.push(row.investorEmail);
                rowAr.push(row.servicename);
                rowAr.push(row.projectlevel);
                rowAr.push(row.proposedemployement);
                rowAr.push(row.projectinvestment);
                rowAr.push(appliedDate);
                rowAr.push(clearedDate);
                rowAr.push(row.address);
                rowAr.push(row.landZoneUseType);
                dataArray.push(rowAr);
            }
            return dataArray;
        }

        function download(args,status) {

            if(status === "Required" || status === "Pending" || status === "Applied"){
                var rowsCleared= convertJsonsToArrays(args);
            }
            else{
                var rows = convertJsonsToArrays1(args);

            }
            var lineArray = [];
            var lineArrayCleared = [];
            if(status === "Required" || status === "Pending" || status === "Applied"){
                for(var i = 0; i < rowsCleared.length; i++){
                    var lineCleared = rowsCleared[i].join("\t");
                    lineArrayCleared.push(lineCleared);
                }
                lineArrayCleared = lineArrayCleared.join("\n");
                var a = document.createElement('a');
                var blob = new Blob([lineArrayCleared],{type: 'data:text/plain;charset=utf-8;'});
                var url = URL.createObjectURL(blob);
                $window.location.href = url;
            }
            else{
                for(var i = 0; i < rows.length; i++){
                    var line = rows[i].join("\t");
                    lineArray.push(line);
                }
                lineArray = lineArray.join("\n");
                var a = document.createElement('a');
                var blob = new Blob([lineArray],{type: 'data:text/plain;charset=utf-8;'});
                var url = URL.createObjectURL(blob);
                $window.location.href = url;
            }

        }
    }
})();
