(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('disputelog', {
            parent: 'entity',
            url: '/disputelog',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.disputelog.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/disputelog/disputelogs.html',
                    controller: 'DisputelogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('disputelog');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('disputelog-detail', {
            parent: 'disputelog',
            url: '/disputelog/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.disputelog.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/disputelog/disputelog-detail.html',
                    controller: 'DisputelogDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('disputelog');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Disputelog', function($stateParams, Disputelog) {
                    return Disputelog.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'disputelog',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('disputelog-detail.edit', {
            parent: 'disputelog-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/disputelog/disputelog-dialog.html',
                    controller: 'DisputelogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Disputelog', function(Disputelog) {
                            return Disputelog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('disputelog.new', {
            parent: 'disputelog',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/disputelog/disputelog-dialog.html',
                    controller: 'DisputelogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                action: null,
                                actionBy: null,
                                actionComment: null,
                                actionDate: null,
                                disputeID: null,
                                disputeMainId: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('disputelog', null, { reload: 'disputelog' });
                }, function() {
                    $state.go('disputelog');
                });
            }]
        })
        .state('disputelog.edit', {
            parent: 'disputelog',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/disputelog/disputelog-dialog.html',
                    controller: 'DisputelogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Disputelog', function(Disputelog) {
                            return Disputelog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('disputelog', null, { reload: 'disputelog' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('disputelog.delete', {
            parent: 'disputelog',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/disputelog/disputelog-delete-dialog.html',
                    controller: 'DisputelogDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Disputelog', function(Disputelog) {
                            return Disputelog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('disputelog', null, { reload: 'disputelog' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
