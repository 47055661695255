(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('disbursePaymentController', disbursePaymentController);

    disbursePaymentController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','entity','projectServiceLog','ProjectServiceLogUpdated','ProjectServicePaymentDetails'];
    function disbursePaymentController ($timeout, $scope, $stateParams, $uibModalInstance,entity,projectServiceLog,ProjectServiceLogUpdated,ProjectServicePaymentDetails) {
        var vm = this;
        vm.clear = clear;
        vm.projectServiceLog=projectServiceLog;
        vm.projectServicePaymentDetail=entity;
        vm.disbursePayment=disbursePayment;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function disbursePayment()
        {
            vm.projectServicePaymentDetail.paymentdisbursed=true;
            ProjectServicePaymentDetails.update(vm.projectServicePaymentDetail,onSaveSuccess,onSaveError);
        }
        function onSaveError()
        {}
        function onSaveSuccess(result)
        {
            vm.projectServiceLog.projectid=result.projectid;
            vm.projectServiceLog.serviceid=result.serviceid;
            vm.projectServiceLog.actionTaken="PaymentDisbursed";
            vm.projectServiceLog.comments=(result.feedescription==null?"Service Fee":result.feedescription);
            ProjectServiceLogUpdated.save(vm.projectServiceLog,onSaveServiceLogSuccess,onSaveServiceLogError);
        }
        function onSaveServiceLogSuccess(result)
        {
            $uibModalInstance.close(true);
        }
        function onSaveServiceLogError()
        {}
    }
})();
