(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('BackupProjectServiceLogDeleteController',BackupProjectServiceLogDeleteController);

    BackupProjectServiceLogDeleteController.$inject = ['$uibModalInstance', 'entity', 'BackupProjectServiceLog'];

    function BackupProjectServiceLogDeleteController($uibModalInstance, entity, BackupProjectServiceLog) {
        var vm = this;

        vm.backupProjectServiceLog = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            BackupProjectServiceLog.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
