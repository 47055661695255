(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketMemberDetailController', TicketMemberDetailController);

    TicketMemberDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TicketMember'];

    function TicketMemberDetailController($scope, $rootScope, $stateParams, previousState, entity, TicketMember) {
        var vm = this;

        vm.ticketMember = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:ticketMemberUpdate', function(event, result) {
            vm.ticketMember = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
