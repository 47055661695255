(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MoveCafLoginController', MoveCafLoginController);

    MoveCafLoginController.$inject = ['$scope','$filter', 'Principal','MoveCafLoginService','User','SearchCAF', 'SearchGrievance','MoveGrievanceLoginService','$stateParams'];

    function MoveCafLoginController ($scope,$filter, Principal, MoveCafLoginService, User,SearchCAF, SearchGrievance,MoveGrievanceLoginService,$stateParams) {
        var vm = this;
        vm.user = {};
        vm.tabSelected = $stateParams.type;
        vm.showCafpinBlock = false;
        vm.showGrievanceBlock = false;
        vm.grievanceID = '';
        loadAll();
        function loadAll() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
        }
        vm.findUser = function (user) {
            vm.user = {};
            User.get({login: user},function (result) {
                vm.user = result;
            }, function (error) {
                alert('User Not Found');
                console.log(error);
            })
        };
        vm.confirm = function () {
             vm.showCafpinBlock = true;
        }
        vm.grievanceConfirm = function () {
             vm.showGrievanceBlock = true;
        }
        vm.moveCaf = function (cafPin) {
            MoveCafLoginService.get({login: vm.user.login, cafpin: cafPin}, function (result) {
                if(result.success){
                    $('#confirmCaf').modal('hide');
                    alert('Caf Successfully Moved');
                    vm.user = {};
                    vm.showCafpinBlock = false;
                    vm.cafPin = '';
                    vm.userLogin = '';
                }
            })
        }
        vm.moveGrievance = function () {
            MoveGrievanceLoginService.get({login: vm.user.login, grievance:vm.grievanceID}, function (result) {
                if(result.success){
                    $('#confirmgrievance').modal('hide');
                    alert('Grievance Successfully Moved');
                    vm.user = {};
                    vm.showGrievanceBlock = false;
                    vm.grievanceID = '';
                    vm.userLogin = '';
                }
            })
        }
        vm.confirmProject = function () {
            SearchCAF.get({CAFPIN:vm.cafPin},function(result) {
                vm.cafresult = result;
                if(vm.cafresult.userLogin !== vm.user.login){
                    $('#confirmCaf').modal('show');
                }else {
                    alert(vm.cafresult.userLogin + " already has a caf with cafpin " + vm.cafPin)
                }
            });
        };
        vm.confirmGrievance = function () {
            SearchGrievance.get({grievance:vm.grievanceID},function(result){
                vm.grievance = result;
                if(vm.grievance.grievanceBy !== vm.user.login){
                    $('#confirmgrievance').modal('show');
                }else {
                    alert(vm.grievance.grievanceBy + " already has a grievance with id " + vm.grievanceID)
                }
            });
        }
    }
})();
