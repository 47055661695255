(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievanceAssignDeleteController',GrievanceAssignDeleteController);

    GrievanceAssignDeleteController.$inject = ['$uibModalInstance', 'entity', 'GrievanceAssign'];

    function GrievanceAssignDeleteController($uibModalInstance, entity, GrievanceAssign) {
        var vm = this;

        vm.grievanceAssign = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            GrievanceAssign.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
