(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ScheduledListController', ScheduledListController);

    ScheduledListController.$inject = ['$scope','$state','ScheduledList','ScheduledVisits','InspectorDetails'];

    function ScheduledListController($scope, $state, ScheduledList,ScheduledVisits,InspectorDetails) {
        var vm = this;
        vm.scheduledList = null;
        vm.inspectorDetails =[];
        vm.datePickerOpenStatus = {};
        vm.statusFilter = "Pending";
        vm.statusSelected = statusSelected;
        vm.pending = pending;
        pending();
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.repeatValue = repeatValue;
        vm.value = true;
        vm.prdtl = {};
        vm.datePickerOpenStatus.scheduledDate = false;

        function repeatValue(values) {
            vm.scheduledVisits = values;
               var ds = vm.scheduledVisits[0].district;
            InspectorDetails.query({district: vm.scheduledVisits[0].district},function(result) {
                vm.inspectorDetails = result;
                vm.searchQuery = null;
            });

        }

        $scope.options = {
            minDate: new Date(),
        }

        function openCalendar(date, prdtl1) {
            vm.datePickerOpenStatus[date] = true;
        }

        function save() {
            vm.isSaving = true;
            for (var i = 0; i < vm.scheduledVisits.length; i++) {
                vm.scheduledVisits[i].scheduledDate = vm.prdtl.scheduledDate;
                vm.scheduledVisits[i].assignedToOfficer=vm.prdtl.fireOfficer;
                vm.scheduledVisits[i].assignedOfficerMail=vm.prdtl.ocOfficer;
                ScheduledVisits.save(vm.scheduledVisits[i]);
            }
            location.reload();
        }

        function statusSelected(status1) {
            if(status1 ==="Pending"){
                ScheduledList.query({status: "Pending"}, function (result) {
                    vm.scheduledList = result;
                    vm.searchQuery = null;
                    vm.value=true;
                });
            }
            else if(status1 ==="Scheduled"){
                ScheduledVisits.query(function (result) {
                vm.scheduledVisits = result;
                vm.searchQuery = null;
                vm.scheduledList = vm.scheduledVisits;
                vm.value=false;
            });
            }
        }

        function pending() {
            ScheduledList.query({status: "Pending"}, function (result) {
                vm.scheduledList = result;
                vm.searchQuery = null;
                vm.value=true;
            });
        }

    }
    
})();



