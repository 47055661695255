(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DepartmentHodDetailController', DepartmentHodDetailController);

    DepartmentHodDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DepartmentHod'];

    function DepartmentHodDetailController($scope, $rootScope, $stateParams, previousState, entity, DepartmentHod) {
        var vm = this;

        vm.departmentHod = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:departmentHodUpdate', function(event, result) {
            vm.departmentHod = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
