(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ReasonCategoryController', ReasonCategoryController);

    ReasonCategoryController.$inject = ['ReasonCategory'];

    function ReasonCategoryController(ReasonCategory) {

        var vm = this;

        vm.reasonCategories = [];

        loadAll();

        function loadAll() {
            ReasonCategory.query(function(result) {
                vm.reasonCategories = result;
                vm.searchQuery = null;
            });
        }
    }
})();
