(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerdiolistDialogController', TowerdiolistDialogController);

    TowerdiolistDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Towerdiolist', 'District'];

    function TowerdiolistDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Towerdiolist, District) {
        var vm = this;

        vm.towerdiolist = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        loadAllDistricts();
        function loadAllDistricts() {
            District.query(function (result) {
                vm.districts=result;
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.towerdiolist.id !== null) {
                Towerdiolist.update(vm.towerdiolist, onSaveSuccess, onSaveError);
            } else {
                Towerdiolist.save(vm.towerdiolist, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:towerdiolistUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
