(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerCAFServicesController', TowerCAFServicesController);

    TowerCAFServicesController.$inject = ['$timeout', '$scope', '$stateParams', 'TowerServiceAllCAF', 'DownloadTowerCafs', 'DownloadCafCsv', 'SearchTowerCafs'];
    function TowerCAFServicesController ($timeout, $scope, $stateParams, TowerServiceAllCAF, DownloadTowerCafs, DownloadCafCsv, SearchTowerCafs) {
        var vm = this;
        vm.towerData = [];
        vm.status='Pending';
        // vm.searchText = null;
        // vm.status=$stateParams.status;
        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";
        vm.downloadAllData = downloadAllData;
        vm.getTowerCafs = getTowerCafs;
        vm.cafPin = null;

        $scope.file = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        loadAllCafs();
        function loadAllCafs() {
            vm.dashboardcafs=TowerServiceAllCAF.get({
                status: vm.status,
                page: $scope.currentPage - 1,
                size: $scope.pageSize}, function(result) {
                vm.towerData = result;
                console.log(vm.towerData);
                vm.show = true;
            });
        }

        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
        }
        $scope.pageChanged = function(num) {
            loadAllCafs();
        };

        vm.statusSelected=function () {
            loadAllCafs();
            vm.cafPin = null;
        };

        function downloadAllData() {
            DownloadTowerCafs.query({
                status: vm.status
            },function (result) {
                DownloadCafCsv.download(result[0].status).then(function (success) {
                    console.log(success);
                }, function (error) {
                    alert('error : ' + error);
                });
            });
        }

        function getTowerCafs(cafPin) {
            SearchTowerCafs.query({
                status: vm.status,
                cafPin: cafPin}, function (result) {
                vm.towerData.content = result;
                vm.show = true;
            });
        }

    }
})();
