(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('complianceProjectDetailListController', complianceProjectDetailListController);

    complianceProjectDetailListController.$inject = ['$scope', '$stateParams','$state','projectServiceReportInfo', 'Projectcompletedetail', 'Auth', 'Principal', 'DepartmentService', 'Projectservicedetail','ProjectServiceReportInfoForClearance','projectservicelist','projectServiceLog','ProjectServiceLogUpdated','ProjectservicedetailbyProject','Department', 'TowerserviceNO', 'Project', '$sce','Municipals','GetServiceLog','downloadService','DownloadExternalServiceFiles'];

    function complianceProjectDetailListController ($scope, $stateParams, $state, projectServiceReportInfo, Projectcompletedetail, Auth, Principal, DepartmentService, Projectservicedetail,ProjectServiceReportInfoForClearance,projectservicelist,projectServiceLog,ProjectServiceLogUpdated,ProjectservicedetailbyProject,Department, TowerserviceNO, Project, $sce,Municipals,GetServiceLog,downloadService,DownloadExternalServiceFiles) {
        var vm = this;
        vm.projectid = $stateParams.projectid;
        vm.district = $stateParams.district;
        vm.GetServiceLog=GetServiceLog;
        vm.title = "Service Clearances in Process";
        vm.statusOnPage = 'All';
        vm.selectedprojectservice = {};
        vm.towerserviceNO = {};
        vm.isDeskof = false;

        vm.constructionpermits=[{id:'PRE',name:'Pre Establishment'},{id:'POST',name:'Post Establishment'}];
        vm.loadProjectServices = loadProjectServices;

        vm.projectservicelist = projectservicelist;

        vm.formStatus = true;

        vm.goToServiceDetail = goToServiceDetail;



        vm.municipals = [];
        $scope.requiredMunicipal = [];
        vm.caftype = '';


        function statusChange (status){
            if(status ==="All") {
                status="";
            }
            vm.formStatus =true;
           vm.statusFilter =status;
           if(status===""){
            vm.formStatus= "";
           }
            if(status === "Required"){
                vm.statusFilter = "Pending";
                vm.formStatus = false;
            }

        }

        vm.loadServiceLogs = function(projectid, serviceid) {
            return new Promise(function(resolve, reject) {
                // Call GetServiceLog API
                vm.GetServiceLog.query({ projectid: projectid, serviceid: serviceid }, function(result) {
                    console.log("Service logs: ", result);

                    // Initialize variables
                    var latestCommentDate = new Date(0); // Start with the earliest possible date
                    var latestFileName = null;
                    var latestReApplyCounter = null;
                    var latestActionTaken = null;

                    // Iterate through the result to find the latest fileName
                    result.forEach(function(log) {
                        if (log.fileName && new Date(log.commentDate) > latestCommentDate) {
                            latestCommentDate = new Date(log.commentDate);
                            latestFileName = log.fileName;
                            latestReApplyCounter = log.reApplyCounter;
                            latestActionTaken = log.actionTaken;
                        }
                    });

                    console.log("Latest file name: ", latestFileName);
                    console.log("Latest reApplyCounter: ", latestReApplyCounter);
                    console.log("Latest action taken: ", latestActionTaken);

                    // Store the result in a variable to be used in the view
                    vm.latestLogDetails = {
                        fileName: latestFileName,
                        reApplyCounter: latestReApplyCounter,
                        actionTaken: latestActionTaken
                    };

                    // Resolve the promise with the data
                    resolve(vm.latestLogDetails);
                }, function(error) {
                    console.log("Error loading service logs: ", error);
                    // Reject the promise in case of error
                    reject(error);
                });
            });
        };


        vm.handleButtonClick = function(projectservice) {
            // Call loadServiceLogs method
            vm.loadServiceLogs(projectservice.projectid, projectservice.serviceid).then(function(latestLogDetails) {
                // After loading service logs, check projectservice.isIntegrated and call other methods
                if (projectservice.isIntegrated) {
                    // Call method if isIntegrated is true, passing the latestLogDetails
                    downloadExt(projectservice.projectid, projectservice.serviceid,latestLogDetails.reApplyCounter);
                } else {
                    // Call method if isIntegrated is false, passing the latestLogDetails
                    downloadHEPC(latestLogDetails.fileName);
                }
            }).catch(function(error) {
                console.log("Error handling button click: ", error);
            });
        };


        function downloadHEPC(fileName) {
            downloadService.download(fileName)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
                });
        }


        loadProjectServices("");

        function loadProjectServices(status)
        {

             vm.department='all';
             vm.constructionpermit='all';

            // Fetching the project service list
            vm.projectservicelist = ProjectservicedetailbyProject.get({ id: vm.projectid, status: "inProcess" }, function (result) {
                // Accessing the projectservicedetailDTOS array
                var serviceDetails = result.projectservicedetailDTOS;

                // Filtering the services where status is "Cleared"
                vm.projectservicelist = serviceDetails.filter(function(service) {

                    return service.status === "Cleared";
                });

                // Optional: If you still need a copy in tempDataHandler
                vm.tempDataHandler = angular.copy(vm.projectservicelist);

                console.log("Filtered Cleared Services: ", vm.projectservicelist);
            });
            if(status =="required")
                vm.title = "Service Required";
            else
                vm.title = "Service Clearances in Process";
        }


        function goToServiceDetail(projectid,serviceid,status,reApplyCounter) {
            downloadExt(projectid,serviceid,reApplyCounter);


        }

        function downloadHEPC(fileName) {
            downloadService.download(fileName)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
                });
        }

        function downloadExt(projectid,serviceid,reApplyCounter) {

            DepartmentService.get({id:serviceid}, function (result) {
                if(result.downloadUrl == null){
                    alert("Download URL not found! Kindly contact concerned department.");
                    return;
                }

                DownloadExternalServiceFiles.download(projectid, serviceid, reApplyCounter, result.downloadUrl);
            });
        }


    }
})();
