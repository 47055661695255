(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CafWithNoServiceController', CafWithNoServiceController);

    CafWithNoServiceController.$inject = ['CafWithNoService'];

    function CafWithNoServiceController(CafWithNoService) {

        var vm = this;

        vm.cafWithNoServices = [];

        loadAll();

        function loadAll() {
            CafWithNoService.query(function(result) {
                vm.cafWithNoServices = result;
                vm.searchQuery = null;
            });
        }
    }
})();
