(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('nmatoken', {
            parent: 'entity',
            url: '/nmatoken',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.nmatoken.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/nmatoken/nmatokens.html',
                    controller: 'NmatokenController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('nmatoken');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('nmatoken-detail', {
            parent: 'nmatoken',
            url: '/nmatoken/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.nmatoken.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/nmatoken/nmatoken-detail.html',
                    controller: 'NmatokenDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('nmatoken');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Nmatoken', function($stateParams, Nmatoken) {
                    return Nmatoken.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'nmatoken',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('nmatoken-detail.edit', {
            parent: 'nmatoken-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/nmatoken/nmatoken-dialog.html',
                    controller: 'NmatokenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Nmatoken', function(Nmatoken) {
                            return Nmatoken.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('nmatoken.new', {
            parent: 'nmatoken',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/nmatoken/nmatoken-dialog.html',
                    controller: 'NmatokenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nmatoken: null,
                                cafpin: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('nmatoken', null, { reload: 'nmatoken' });
                }, function() {
                    $state.go('nmatoken');
                });
            }]
        })
        .state('nmatoken.edit', {
            parent: 'nmatoken',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/nmatoken/nmatoken-dialog.html',
                    controller: 'NmatokenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Nmatoken', function(Nmatoken) {
                            return Nmatoken.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('nmatoken', null, { reload: 'nmatoken' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('nmatoken.delete', {
            parent: 'nmatoken',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/nmatoken/nmatoken-delete-dialog.html',
                    controller: 'NmatokenDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Nmatoken', function(Nmatoken) {
                            return Nmatoken.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('nmatoken', null, { reload: 'nmatoken' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
