(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CenterTowerUserDataDeleteController',CenterTowerUserDataDeleteController);

    CenterTowerUserDataDeleteController.$inject = ['$uibModalInstance', 'entity', 'CenterTowerUserData'];

    function CenterTowerUserDataDeleteController($uibModalInstance, entity, CenterTowerUserData) {
        var vm = this;

        vm.centerTowerUserData = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CenterTowerUserData.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
