(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ProjectlogDeleteController',ProjectlogDeleteController);

    ProjectlogDeleteController.$inject = ['$uibModalInstance', 'entity', 'Projectlog'];

    function ProjectlogDeleteController($uibModalInstance, entity, Projectlog) {
        var vm = this;

        vm.projectlog = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Projectlog.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
