(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('constructionPermitDeptController', constructionPermitDeptController);

    constructionPermitDeptController.$inject = ['$rootScope', '$timeout', '$scope', '$stateParams','$state', 'ConstructionPermitApprovalSteps', 'ConstructionPermitLog', 'ConstructionPermitApproval', 'FactotyPlan','$sce', 'HudaformOc', 'HudaForm'];

    function constructionPermitDeptController ($rootScope, $timeout, $scope, $stateParams, $state, ConstructionPermitApprovalSteps, ConstructionPermitLog, ConstructionPermitApproval, FactotyPlan,$sce, HudaformOc, HudaForm) {
        var vm = this;
        vm.constructionPermit={};
        vm.constructionPermitLog={};
        vm.projectid = $stateParams.id;
        vm.resourceurl=$sce.trustAsResourceUrl('https://online.ulbharyana.gov.in/eforms/HepcLinkPage1.aspx?projectid='+vm.projectid+'&serviceid=1c90c949-f2d1-4997-84b0-e6b2d40fe5e6');
        vm.resourceurl_ffs=$sce.trustAsResourceUrl('https://online.ulbharyana.gov.in/eforms/HepcLinkPage1.aspx?projectid='+vm.projectid+'&serviceid=2c520622-d89a-42a1-ae85-efa54ee3630f');
        vm.resourceurl_twc=$sce.trustAsResourceUrl('https://online.ulbharyana.gov.in/eforms/HepcLinkPage1.aspx?projectid='+vm.projectid+'&serviceid=c5baea92-079d-44d3-9980-8b7d7d568f98');
        vm.resourceurl_ulbOC=$sce.trustAsResourceUrl('https://online.ulbharyana.gov.in/eforms/HepcLinkPage1.aspx?projectid='+vm.projectid+'&serviceid=9d063f53-5e50-4b05-b543-bfa0fcb6da58');


        // console.log(vm.projectid);

        loadAll();

        function loadAll() {
            ConstructionPermitApproval.query({projectId: $stateParams.id},function (result) {
                vm.constructionPermit= result;
            })
        }

        // vm.viewLog = function (serviceId) {
        //     ConstructionPermitLog.query({projectId: vm.projectid,serviceId: serviceId},function (result) {
        //
        //     })
        // };

        vm.saveDepartment = function (type) {
            vm.constructionPermit.bPflag=true;
            vm.constructionPermit.projectId=$stateParams.id;
            // if(type==='huda'){
            //     vm.constructionPermit.serviceId='43575ec0-7dd3-40bd-b3c4-47638d17bf3c';
            // }
            if(type==='hsidc'){
                vm.constructionPermit.serviceId='a69786f5-c674-4de9-8807-8689c7ef86b5';
            }
            else if(type==='tcp'){
                vm.constructionPermit.serviceId='159a0331-87c3-407d-9c6c-559455d4b6ab';
            }
            else if(type==='ulb'){
                vm.constructionPermit.serviceId='1c90c949-f2d1-4997-84b0-e6b2d40fe5e6';
            }
            // else if(type==='labour'){
            //     vm.constructionPermit.serviceId='ead00143-65ac-415b-8ebd-7f3d44fdfe34';
            // }
            vm.constructionPermit.serviceIdEC= vm.projectid;
            vm.constructionPermitLog.serviceid= vm.constructionPermit.serviceId;
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFirstSaveSuccess);
        };

        vm.saveDepartmenthuda= function () {
            vm.constructionPermit.bPflag=true;
            vm.constructionPermit.projectId=$stateParams.id;
            vm.constructionPermit.serviceIdEC= vm.projectid;
            vm.constructionPermit.serviceId='43575ec0-7dd3-40bd-b3c4-47638d17bf3c';
            vm.constructionPermitLog.serviceid= vm.constructionPermit.serviceId;
            HudaForm.save(vm.hudaForm, onhudaSaveSuccess);
        };

        function onhudaSaveSuccess() {
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFirstSaveSuccess);
        }

        vm.saveLabour = function () {
            vm.constructionPermit.bPflag=true;
            vm.constructionPermit.projectId=$stateParams.id;
            vm.constructionPermit.serviceIdEC= vm.projectid;
            vm.constructionPermit.serviceId='ead00143-65ac-415b-8ebd-7f3d44fdfe34';
            vm.constructionPermitLog.serviceid= vm.constructionPermit.serviceId;
            FactotyPlan.save(vm.factotyPlan, onFactoryPlanSaveSuccess);
        };

        function onFactoryPlanSaveSuccess() {
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFirstSaveSuccess);
        }

        vm.saveffs= function () {
            // console.log(vm.constructionPermit);
            vm.constructionPermit.fFSflag=true;
            vm.constructionPermit.nMAflag=true;
            vm.constructionPermit.aAIflag=true;
            vm.constructionPermitLog.serviceid= '2c520622-d89a-42a1-ae85-efa54ee3630f';
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFirstSaveSuccess);
        };

        vm.saveTWC= function () {
            vm.constructionPermit.tWCflag=true;
            vm.constructionPermitLog.serviceid= 'c5baea92-079d-44d3-9980-8b7d7d568f98';
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFirstSaveSuccess);
        };

        vm.saveTEC= function () {
            vm.constructionPermit.tECflag=true;
            vm.constructionPermitLog.serviceid= $stateParams.deptID;
            vm.constructionPermit.tECid= $stateParams.deptID;
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFirstSaveSuccess);
            alert("You have successfully applied for Phase - 1 services");
        };

        vm.fireNOC= function () {
            console.log("called");
            vm.constructionPermit.oCflag=true;
            // vm.constructionPermitLog.serviceid= '2c520622-d89a-42a1-ae85-efa54ee3630f';
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFirstSaveSuccess);
        };

        vm.saveOC= function (id) {
            vm.constructionPermitLog.serviceid= id;
            if(id==='9b8f4307-4f88-4821-aec7-2dd2668041ab'){
                HudaformOc.save(vm.hudaformOc,onOCsaveSuccess);
            }
            else {
                onOCsaveSuccess();
            }
        };

        function onOCsaveSuccess () {
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFirstSaveSuccess);
        };

        function onFirstSaveSuccess() {
            vm.constructionPermitLog.action="Form Submitted";
            vm.constructionPermitLog.projectid= vm.projectid;
            ConstructionPermitLog.save(vm.constructionPermitLog,onSecondSaveSuccess)
        }

        function onSecondSaveSuccess() {
            vm.constructionPermitLog.action="Service Under Process";
            ConstructionPermitLog.save(vm.constructionPermitLog,onSaveSuccess)
        }

        function onSaveSuccess(result) {
            $rootScope.$broadcast("investhry: constructionPermitEvent");
        }

    }
})();
