(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ActivateUserController', ActivateUserController);

    ActivateUserController.$inject = ['User','UserById','ActivateUser'];

    function ActivateUserController(User,UserById,ActivateUser) {

        var vm = this;
        vm.getUser = getUser;
        function getUser(id){
            UserById.get({id:id},function(user){
                vm.user = user;
            });
        }

        vm.activate = activate;
        function activate(user){
            vm.user.activated = true;
            ActivateUser.update(vm.user,onSuccess,onError);
        }
        function onSuccess(result){
            alert("User Successfully Activated with login: " + result.login);
        }
        function onError(){
            alert("User Not Activated.");
        }
    }
 })();
