(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Meeting_attendanceController', Meeting_attendanceController);

    Meeting_attendanceController.$inject = ['Meeting_attendance', '$filter', '$scope'];

    function Meeting_attendanceController(Meeting_attendance, $filter, $scope) {

        var vm = this;
        vm.AttendanceByDate= AttendanceByDate;
        vm.AttendanceDate = 'empty';

        vm.meeting_attendances = [];

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        $scope.toggle_attendance='present';

        $scope.toggle_attendance_function= function (value) {
            $scope.toggle_attendance= value;
        };

        loadAll();

        function loadAll() {
            Meeting_attendance.query(function(result) {
                vm.meeting_attendances = result;
                vm.searchQuery = null;
            });
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function AttendanceByDate() {
            var meeting_date= $filter('date')(vm.AttendanceDate,'dd-MM-yyyy');
            Meeting_attendance.query({date: meeting_date},function (result) {
               vm.meeting_attendees=result;
            });
        }
    }
})();
