(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('SupplyDisposalViewController', SupplyDisposalViewController);

    SupplyDisposalViewController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'ServiceViewCafYearly', 'TowerserviceNO','SupplyDisposalViewByElastic'];
    function SupplyDisposalViewController ($timeout, $scope, $stateParams, $uibModalInstance, ServiceViewCafYearly, TowerserviceNO, SupplyDisposalViewByElastic) {
        var vm = this;
        vm.clear = clear;
        vm.status=$stateParams.status;
        vm.spiner= true;

        $scope.file = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
            /*appliedDate=new Date(result[i].appliedDate.toDateString());
            clearedDate=new Date(result[i].clearedDate.toDateString());
            dayDiff= (clearedDate - appliedDate)  / 1000 / 60 / 60 / 24;
*/
        loadAllCafs();
        function loadAllCafs() {
            var data = {
                status: $stateParams.status,
            };
            Object.keys(data).forEach(function (k) {
                if (!data[k] && data[k] !== undefined) {
                    delete data[k];
                }
            });

            vm.dashboardcafs = SupplyDisposalViewByElastic.query(data, function (result) {
                alert(data.status);
                console.log(vm.dashboardcafs);
                vm.show = true;
                vm.spiner = false;
            });
        }

    }
})();
