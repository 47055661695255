(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('district-desk-officer', {
            parent: 'entity',
            url: '/district-desk-officer',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN'],
                pageTitle: 'investhryApp.districtDeskOfficer.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/district-desk-officer/district-desk-officers.html',
                    controller: 'DistrictDeskOfficerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('districtDeskOfficer');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('district-desk-officer-detail', {
            parent: 'district-desk-officer',
            url: '/district-desk-officer/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN'],
                pageTitle: 'investhryApp.districtDeskOfficer.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/district-desk-officer/district-desk-officer-detail.html',
                    controller: 'DistrictDeskOfficerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('districtDeskOfficer');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'DistrictDeskOfficer', function($stateParams, DistrictDeskOfficer) {
                    return DistrictDeskOfficer.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'district-desk-officer',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('district-desk-officer-detail.edit', {
            parent: 'district-desk-officer-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/district-desk-officer/district-desk-officer-dialog.html',
                    controller: 'DistrictDeskOfficerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DistrictDeskOfficer', function(DistrictDeskOfficer) {
                            return DistrictDeskOfficer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('district-desk-officer.new', {
            parent: 'district-desk-officer',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/district-desk-officer/district-desk-officer-dialog.html',
                    controller: 'DistrictDeskOfficerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                deskOfficerLogin: null,
                                districtid: null,
                                districtName: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('district-desk-officer', null, { reload: 'district-desk-officer' });
                }, function() {
                    $state.go('district-desk-officer');
                });
            }]
        })
        .state('district-desk-officer.edit', {
            parent: 'district-desk-officer',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/district-desk-officer/district-desk-officer-dialog.html',
                    controller: 'DistrictDeskOfficerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DistrictDeskOfficer', function(DistrictDeskOfficer) {
                            return DistrictDeskOfficer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('district-desk-officer', null, { reload: 'district-desk-officer' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('district-desk-officer.delete', {
            parent: 'district-desk-officer',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/district-desk-officer/district-desk-officer-delete-dialog.html',
                    controller: 'DistrictDeskOfficerDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DistrictDeskOfficer', function(DistrictDeskOfficer) {
                            return DistrictDeskOfficer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('district-desk-officer', null, { reload: 'district-desk-officer' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
