(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('LandexchangeDialogController2', LandexchangeDialogController2);

    LandexchangeDialogController2.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Landexchange', 'projectServiceLog', 'projectAttachemnt', 'projectServiceFormFieldData', 'projectService', 'ProjectServiceLogUpdated', 'Projectservicedetail', 'FileManagement', 'ServiceFormAttachments', 'District', 'Block', 'Tehsil_subtehsil'];
    function LandexchangeDialogController2 ($timeout, $scope, $stateParams, $uibModalInstance, entity, Landexchange, projectServiceLog, projectAttachemnt, projectServiceFormFieldData, projectService, ProjectServiceLogUpdated, Projectservicedetail, FileManagement, ServiceFormAttachments, District, Block, Tehsil_subtehsil) {
        var vm = this;

        vm.landexchange = entity;
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectServiceFormFieldData=projectServiceFormFieldData;
        vm.projectService = projectService;

        vm.clear = clear;
        vm.save = save;
        var projectServiceLogid=null;
        vm.serviceformattachment={};
        $scope.file = {};
        loadAll();

        function loadAll() {
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
            });
            Block.query(function(result) {
                vm.blocks = result;
                vm.searchQuery = null;
            });
            Tehsil_subtehsil.query(function(result) {
                vm.tehsil_subtehsils = result;
                vm.searchQuery = null;
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.landexchange.id= projectService.id;
            Landexchange.update(vm.landexchange, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:landexchangeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.comments="Form Edited";
            vm.projectServiceLog.actionTaken="Service Form Filled";
            ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);
        }

        function onServiceLogSaveSuccess(result) {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments="Service form edited";
            vm.projectService.formFilledStatus=true;
            Projectservicedetail.update(vm.projectService);
        }

        vm.upload= function(attachment) {
            var fileNameArray = attachment.name.split(".");
            var description="";
            if((fileNameArray.length>1))
            {
                description=fileNameArray[(fileNameArray.length-2)];
            }
            var filename = attachment.name;
            vm.serviceformfile = attachment;

            vm.serviceformattachment.isSaved=false;
            vm.serviceformattachment.description=description;
            vm.serviceformattachment.downloadfilename=filename;
            vm.serviceformattachment.clientfilename=filename;
            vm.serviceformattachment.projectID=vm.projectService.projectid;
            vm.serviceformattachment.serviceID=vm.projectService.serviceid;
            ServiceFormAttachments.save(vm.serviceformattachment,onSaveServiceFormAttachmentsSuccess);
        };

        function onSaveServiceFormAttachmentsSuccess(result) {
            console.log(result.id);
            var filename = result.id;
            var file = vm.serviceformfile;
            FileManagement.saveFile(file,filename);
            var fileNameArray = vm.serviceformfile.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            result.isSaved=true;
            result.serverfilename=inputFileName;
            ServiceFormAttachments.update(result,onServiceFormAttachmentUpdateSuccess);
        }

        function onServiceFormAttachmentUpdateSuccess() {
            alert("File Uploaded Successfully");
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }




    }
})();
