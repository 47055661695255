(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerdclistDetailController', TowerdclistDetailController);

    TowerdclistDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Towerdclist'];

    function TowerdclistDetailController($scope, $rootScope, $stateParams, previousState, entity, Towerdclist) {
        var vm = this;

        vm.towerdclist = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:towerdclistUpdate', function(event, result) {
            vm.towerdclist = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
