(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RmlistDialogController', RmlistDialogController);

    RmlistDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Rmlist','District'];

    function RmlistDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Rmlist,District) {
        var vm = this;

        vm.rmlist = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        loadAllDistricts();
        function loadAllDistricts() {
            District.query(function (result) {
               vm.districts=result;
               var obj={districtname:'Haryana',
                       powercompany:null,
                       stateID:null,
                       id:null,
                       statename:'Haryana'};
               vm.districts.push(obj);
               for( var i = 0; i < vm.districts.length-1; i++){
                                 if ( vm.districts[i].districtname === 'Chandigarh HQ') {
                                   vm.districts.splice(i, 1);
                                 }
                              }
            });
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.rmlist.id !== null) {
                Rmlist.update(vm.rmlist, onSaveSuccess, onSaveError);
            } else {
                Rmlist.save(vm.rmlist, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:rmlistUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
