(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievancelistadminController', GrievancelistadminController);

    GrievancelistadminController.$inject = ['$scope', '$state', 'Principal', 'LoginService', 'Grievancelist', 'GrievanceLog', 'Grievance'];

    function GrievancelistadminController ($scope, $state, Principal, LoginService, Grievancelist, GrievanceLog, Grievance) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.status= 'unassigned';
        vm.grievanceLog={};

        loadAll();

        function loadAll() {
            Grievancelist.query({status:vm.status},function(result) {
                vm.grievance = result;
                vm.searchQuery = null;
            });
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        vm.statusSelected=function () {
            loadAll();
        };

        vm.commentContent= function (data) {
            vm.commentData = data;
        };

        vm.comment = function () {
            vm.grievanceLog.grievanceId= vm.commentData.grievanceId;
            vm.grievanceLog.action= "Comment";
            vm.grievanceLog.actionComment= vm.commentValue;
            console.log(vm.grievanceLog);
            GrievanceLog.save(vm.grievanceLog,onSaveSuccess);
        };

        function onSaveSuccess() {
            vm.grievanceLog={};
            vm.commentValue=null;
        }

        vm.showPersonalDetail= function (id) {
            Grievance.get({id: id},function(result) {
                swal({
                    title: '<u>Applicant Details</u><br/><br/>',
                    animation: true,
                    html:
                        '<b>Name: </b> ' + result.grievanceBy + '<br/><br/>' +
                        '<b>Phone: </b> ' + result.phone + '<br/><br/>' +
                        '<b>Email: </b> ' + result.email + '<br/><br/>' +
                        '<b>Address: </b> ' + result.address + '<br/><br/>'
                })
            });
        }
    }
})();
