(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

    function stateConfig($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/checklistAvailability');

        $stateProvider
            .state('checklistAvailability', {
                parent: 'app',
                url: '/checklistAvailability',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/checklistAvailability/checklistAvailability.html',
                        controller: 'checklistAvailabilityController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('searchResults', {
                parent: 'app',
                url: '/search-results?responseData',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/checklistAvailability/searchResults.html',
                        controller: 'searchResultsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentUrl: ['$stateParams', function($stateParams) {
                        return $stateParams.documentUrl;
                    }]
                },
                params: {
                    responseData: null
                }
            });
        $urlRouterProvider.otherwise('/');
    }
})();
