(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketsCustomerDetailController', TicketsCustomerDetailController);

    TicketsCustomerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TicketsCustomer'];

    function TicketsCustomerDetailController($scope, $rootScope, $stateParams, previousState, entity, TicketsCustomer) {
        var vm = this;

        vm.ticketsCustomer = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:ticketsCustomerUpdate', function(event, result) {
            vm.ticketsCustomer = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
