(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceFormFieldController', ServiceFormFieldController);

    ServiceFormFieldController.$inject = ['$scope','ServiceFormField','DepartmentService'];

    function ServiceFormFieldController($scope,ServiceFormField,DepartmentService) {

        var vm = this;
        vm.selectedservicedepartment="";
        vm.serviceFormFields = [];
        vm.updateDepartmentName = updateDepartmentName;

        function loadAll() {
            ServiceFormField.query(function(result) {
                vm.serviceFormFields = result;
                vm.searchQuery = null;
            });
        }

        loadAllService();
        function loadAllService() {
            DepartmentService.query(function(result) {
                vm.departmentServices = result;
                vm.searchQuery = null;
            });
        }

        function updateDepartmentName()
        {
            vm.selectedservicedepartment=$scope.selectedservice.departmentname;
            var serviceid=$scope.selectedservice.id;
            ServiceFormField.query({id:"1",serviceid:serviceid},function(result) {
                vm.serviceFormFields = result;
                vm.searchQuery = null;
            });
        }
    }
})();
