(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('disputeDashboard', {
            parent: 'app',
            url: '/disputeDashboard',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DISPUTE_OFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/disputeDashboard/disputeDashboard.html',
                    controller: 'DisputeDashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
            .state('disputeDetails', {
                parent: 'disputeDashboard',
                url: '/disputeDetails/{departmentname}/{status}',
                data: {
                    authorities: []
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/disputeDashboard/disputeViewDetails.html',
                        controller: 'DisputeViewDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                $translatePartialLoader.addPart('home');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('disputeDetails', null, { reload: 'disputeDetails' });
                    }, function() {
                        $state.go('disputeDetails');
                    });
                }]
            });

    }
})();
