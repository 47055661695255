(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AppliedServicesDeleteController',AppliedServicesDeleteController);

    AppliedServicesDeleteController.$inject = ['$uibModalInstance', 'entity', 'AppliedServices'];

    function AppliedServicesDeleteController($uibModalInstance, entity, AppliedServices) {
        var vm = this;

        vm.appliedServices = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AppliedServices.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
