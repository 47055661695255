(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerdclistController', TowerdclistController);

    TowerdclistController.$inject = ['Towerdclist'];

    function TowerdclistController(Towerdclist) {

        var vm = this;

        vm.towerdclists = [];

        loadAll();

        function loadAll() {
            Towerdclist.query(function(result) {
                vm.towerdclists = result;
                vm.searchQuery = null;
            });
        }
    }
})();
