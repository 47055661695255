(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SkillsRequiredController', SkillsRequiredController);
    SkillsRequiredController.$inject = ['$stateParams', '$scope','$filter','$uibModalInstance', 'Principal', 'LoginService', '$state', 'projectInfo', 'SkillsData', 'ProjectSkills', 'Projectlogupdated', 'ProjectLogNewCaf', 'Project'];

    function SkillsRequiredController ($stateParams, $scope,$filter ,$uibModalInstance , Principal, LoginService, $state, projectInfo, SkillsData , ProjectSkills, Projectlogupdated, ProjectLogNewCaf, Project) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.sectorResult=sectorResult;
        vm.mySectorData = [];
        vm.requiredPerson = [];
        vm.project = projectInfo;

        vm.projectLogNewCaf ={};
        vm.projectlog ={};

        vm.clear = clear;


        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register () {
            $state.go('register');
        }
        vm.skillsData = [];

        loadAll();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        function loadAll() {
            SkillsData.query(function(result) {
                vm.skillsData = result;
                vm.searchQuery = null;
            });
        }

        function sectorResult(sectorName) {
            vm.requiredPerson = $filter('filter')(vm.skillsData, {sector: sectorName});
            vm.mySectorData=[];
        }


        vm.saveSkillsRequried = function (sector) {
            vm.projectSkills = {};
            vm.projectSkills.cafPin = vm.project.cafpin;
            vm.projectSkills.projectId = vm.project.id;

            for(var i=0;i<vm.mySectorData.length;i++){
                vm.isSaving = true;
                vm.projectSkills.cafPin = vm.project.cafpin;
                vm.projectSkills.projectId = vm.project.id;
                vm.projectSkills.sector = vm.mySectorData[i].sector;
                vm.projectSkills.district = vm.project.district;
                vm.projectSkills.jobRole = vm.mySectorData[i].jobRole;
                vm.projectSkills.requiredLabour = vm.mySectorData[i].requiredLabour;

                ProjectSkills.save(vm.projectSkills,onSaveError);
                vm.projectSkills = {};

            }
            onSaveSuccess();

        };
        function onSaveSuccess (result) {
            vm.project.id = $stateParams.projectid;
            vm.project.isskillsFilled = true;

            Project.update(vm.project);

            if(vm.project.caftype!=='New'){
                vm.projectlog.projectid = $stateParams.projectid;
                vm.projectlog.actiontaken = "Skills Required";
                vm.projectlog.actiontype = "Skills Added";
                Projectlogupdated.save(vm.projectlog, onProjectLogSaveSuccess, onProjectLogSaveError);
            }
            if(vm.project.caftype==='New'){
                vm.projectLogNewCaf.projectId = $stateParams.projectid;
                vm.projectLogNewCaf.actionTaken = "added Required Skills for Project";
                vm.projectLogNewCaf.actionType = "Skills Added";

                ProjectLogNewCaf.save(vm.projectLogNewCaf, onProjectLogSaveSuccess, onProjectLogSaveError);
            }
            $uibModalInstance.close(result);
            $state.go('projectdetailjist');


            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function onProjectLogSaveSuccess(result)
        {

        }
        function onProjectLogSaveError()
        {

        }


    }

})();
