(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerDcProjectsController', TowerDcProjectsController);

    TowerDcProjectsController.$inject = ['$scope', '$stateParams', 'TowerDcProjects', 'Department', '$filter', 'TowerserviceNO', 'Municipals'];

    function TowerDcProjectsController($scope, $stateParams, TowerDcProjects, Department, $filter, TowerserviceNO, Municipals) {
        var vm = this;
        vm.projects = [];
        vm.status='Pending';
        vm.currentDepartment="All";
        vm.municipals = [];
        vm.isTowerServiceForClarification = isTowerServiceForClarification;
        $scope.required={
            department: {},
            municipal:{}
        };
        $scope.requiredMunicipal = [];
        vm.towerserviceNO = {};

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";
        vm.tower_departments = tower_departments;

        loadAll();

        function loadAll() {
            TowerDcProjects.get({
                status: vm.status,
                departmentName: vm.currentDepartment,
                page: $scope.currentPage - 1,
                size: $scope.pageSize
            },function (result) {
                vm.projects = result;
            });
            Department.query(function(result){
                vm.departments = $filter('filter')(result,{ istower : true });
            });
        }

        function tower_departments(service,type) {
            $scope.required.department=[];
            vm.tower_service = service;
            vm.required_type = type;
            Department.query(function(result) {
                vm.tower_departmentlist = result;
            });
        }

        vm.updateCheck = function (check) {
            $scope.required.department=[];
            $scope.required.department.push(check);
            if(check==='MUNICIPAL COUNCIL' || check==='MUNICIPAL COMMITTEE'|| check==='MUNICIPAL CORPORATION'){
                Municipals.query({district:vm.tower_service.district,name:$scope.required.department},function(result) {
                    vm.municipals = result;
                    vm.searchQuery = null;
                });
                $('#tower_department2').modal('hide');
                $('#YourModalId').modal('show');
            }
        };

        vm.markRequiredForMunicipal = function (service,department,municipal) {
            if ($scope.requiredMunicipal.indexOf(municipal) < 0) {
                $scope.requiredMunicipal.push(municipal);
            }
            vm.updateTowerServiceNO(service);

        };

        vm.updateTowerServiceNO= function (id) {
            vm.towerserviceNO.projectdetailId = id;
            vm.towerserviceNO.district = vm.tower_service.district;
            vm.towerserviceNO.officerList = $scope.required.department;
            if($scope.requiredMunicipal.length !== 0) {
                for (var i = 0; i < $scope.requiredMunicipal.length; i++ ) {
                    if(vm.towerserviceNO.subname) {
                        vm.towerserviceNO.subname = vm.towerserviceNO.subname + "," + $scope.requiredMunicipal[i];
                    } else {
                        vm.towerserviceNO.subname = $scope.requiredMunicipal[i];
                    }
                }
            }
            // Projectservicedetail.get({id: vm.towerserviceNO.projectdetailId},function (data) {
            //     vm.projectServiceLog.projectid = $stateParams.projectid;
            //     vm.projectServiceLog.serviceid = data.serviceid;
            //     vm.projectServiceLog.comments = vm.towerserviceNO.subname;
            //     vm.projectServiceLog.actionTaken = vm.towerserviceNO.subname;
            //     Projectlogupdated.save(vm.projectServiceLog, function (data) {
            //         console.log(data);
            //     }, function (error) {
            //         console.log(error);
            //     });
            // });
            TowerserviceNO.save({id: '1'}, vm.towerserviceNO);
            vm.requiredMunicipal= [];
            location.reload();
            vm.towerserviceNO = {};
        };


        vm.statusSelected=function () {
          loadAll();
        };

        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
        };

        $scope.pageChangedHandler = function(num) {
            loadAll();
        };

        vm.closeModal = function () {
            $scope.required.department=[];
            $('#YourModalId').modal('hide');
            $('#tower_department2').modal('hide');
            vm.aboveMunicipal = null;
            $scope.requiredMunicipal = [];
        };

        vm.closeBelow = function () {
            $scope.required.department=[];
            $scope.requiredMunicipal = [];
            $scope.required.belowMunicipal =null;
            $('#belowGround').modal('hide');
            $('#tower_department3').modal('hide');
            vm.aboveMunicipal = null;
        };

        function isTowerServiceForClarification(serviceId) {

            return !!((serviceId === '5f8e0757-e88b-4ef1-bb15-00a9e984b9b1' || serviceId === '58d75fd4-f360-4931-a484-ff9be0adc12c' || serviceId === '741f0f60-cf69-4659-8b19-19d96ffe0103' || serviceId === 'fc1b1635-d3dc-4d30-a5fd-5abf6381594d' || serviceId === '6f81801c-59c6-4773-9f37-a5d81172a1bf'));

        }

    }
})();
