(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('TicketsForFeedBack', TicketsForFeedBack);

    TicketsForFeedBack.$inject = ['$resource', 'DateUtils'];

    function TicketsForFeedBack ($resource, DateUtils) {
        var resourceUrl =  'api/ticketsforfeedback/:ticketNumber';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                        data.resolveDate = DateUtils.convertDateTimeFromServer(data.resolveDate);
                        data.assignDate = DateUtils.convertDateTimeFromServer(data.assignDate);
                        data.expectedResoluation = DateUtils.convertDateTimeFromServer(data.expectedResoluation);
                    }
                    return data;
                }
            }
        });
    }
})();
