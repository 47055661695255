(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$state', 'entity',  'User', 'extendedUserDetails', 'ExtendedUserInformationByUserID', 'ExtendedUserInformation', '$uibModalInstance', 'JhiLanguageService'];

    function UserManagementDialogController ($state, entity,  User, extendedUserDetails, ExtendedUserInformationByUserID, ExtendedUserInformation, $uibModalInstance, JhiLanguageService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_TOWER_MONITORING_OFFICER','ROLE_SUB_ADMIN', 'ROLE_RM', 'ROLE_DESK_OFFICER', 'ROLE_NODAL_OFFICER',
            'ROLE_ACCOUNT_OFFICER', 'ROLE_FIELD_OFFICER','ROLE_ASSIGNRM_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER',
            'ROLE_API_VIEWER','ROLE_GRIEVANCE_OFFICER','ROLE_GRIEVANCE_ADMIN', 'ROLE_TOWER_DC', 'ROLE_TOWER_DIO',
            'ROLE_TOWER_OFFICER','ROLE_DEPT_CHANGE', 'ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER', 'ROLE_L1_SUPPORT' ,
            'ROLE_L2_IT_MANAGER', 'ROLE_L2_DEPARTMENT', 'ROLE_L2_DEPARTMENT_HEAD', 'ROLE_L2_INCENTIVES','ROLE_TICKET_OPERATION_MANAGER',
            'ROLE_TICKET_NODAL_OFFICER', 'ROLE_FEEDBACK_OFFICER', 'ROLE_TICKET_ADMIN', 'ROLE_USER_EXT','ROLE_TICKET_HOBPAS',
            'ROLE_DISPUTE_OFFICER','ROLE_SKILLS_REQUIREMENT_OFFICER','ROLE_CAF_STATUS_OFFICER','ROLE_INCENTIVE_DISBURSEMENT_HEAD',
            'ROLE_HOME_CTM', 'ROLE_HOME_SUPERINTENDENT', 'ROLE_HOME_DC', 'ROLE_HOME_ASSISTANT','ROLE_STATE_NODAL_OFFICER', 'ROLE_NSWS_USER', 'ROLE_ACS'];
        vm.clear = clear;
        vm.languages = null;
        vm.ticketMember={};
        vm.save = save;
        vm.user = entity;
        vm.extendedUserInformation = extendedUserDetails;
        JhiLanguageService.getAll().then(function (languages) {
        vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function onSaveSuccess (result) {
            saveExtendedUserInformation(result);
            vm.isSaving = false;
        }
        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function saveExtendedUserInformation (result) {
            vm.isSaving = true;
            vm.extendedUserInformation.id = result.id;
            vm.extendedUserInformation.userid = result.id;
            vm.extendedUserInformation.userlogin = result.login;
            ExtendedUserInformation.update(vm.extendedUserInformation, onSaveExtendedUserInformationSuccess, onSaveExtendedUserInformationError);
        }

        function onSaveExtendedUserInformationSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveExtendedUserInformationError () {
            vm.isSaving = false;
        }
    }
})();
