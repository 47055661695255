(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ticketsearch', {
            parent: 'hepcticket',
            url: '/ticketsearch',
            data: {
                authorities: ["ROLE_L1_SUPPORT","ROLE_L2_IT_MANAGER","ROLE_L2_DEPARTMENT","ROLE_L2_DEPARTMENT_HEAD","ROLE_L2_INCENTIVES","ROLE_TICKET_NODAL_OFFICER","ROLE_TICKET_OPERATION_MANAGER","ROLE_NODAL_OFFICER","ROLE_ADMIN","ROLE_SUB_ADMIN","ROLE_TICKET_ADMIN"]
            },
            views: {
                'content_section@hepcticket': {
                    templateUrl: 'app/hepcTickets/searchTicket/searchTicket.html',
                    controller: 'SearchTicketController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
