(function() {
    'use strict';

    angular
        .module('investhryApp')
        .factory('viewRecruitmentFile', ['$q', '$timeout', '$window',
            function($q, $timeout, $window, $http) {
                return {
                    view: function(serverfilename) {
                        var defer = $q.defer();
                        $timeout(function() {
                            $window.location = 'api/viewReqruitment?name=' + serverfilename;
                        }, 1000)
                            .then(function() {
                                defer.resolve('success');
                            }, function() {
                                defer.reject('error');
                            });
                        return defer.promise;
                    }
                };
            }
        ]);
})();
