(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ProjectSkillsDeleteController',ProjectSkillsDeleteController);

    ProjectSkillsDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProjectSkills'];

    function ProjectSkillsDeleteController($uibModalInstance, entity, ProjectSkills) {
        var vm = this;

        vm.projectSkills = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ProjectSkills.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
