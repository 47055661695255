(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider




            .state('complianceprojectdetaillist', {
                parent: 'app',
                url: '/complianceprojectdetaillist/{projectid}',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/complianceprojectdetaillist/complianceprojectdetaillist.html',
                        controller: 'complianceProjectDetailListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    projectServiceReportInfo: function () {
                        return {
                            projectid: null,
                            projecttype: null,
                            departmentname: null,
                            servicename: null,
                            assignDate: null,
                            requireDate: null,
                            status: null,
                            stage: null,
                            investorName: null,
                            cafPin: null,
                            finalAction: null,
                            finalActionDate: null,
                            projectInvestment: null,
                            projectEmployment: null,
                            id: null
                        };
                    },
                    projectServiceLog: function () {
                        return {
                            projectid: null,
                            serviceid: null,
                            comments: null,
                            commentDate: null,
                            commentByUserLogin: null,
                            actionTaken: null,
                            id: null
                        };
                    },
                    projectservicelist: ['$stateParams', 'ProjectservicedetailbyProject', function($stateParams, ProjectservicedetailbyProject) {
                        return ProjectservicedetailbyProject.get({id : $stateParams.projectid, status:"inProcess"});
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

    }
})();
