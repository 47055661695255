(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DistrictNodalOfficerDialogController', DistrictNodalOfficerDialogController);

    DistrictNodalOfficerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DistrictNodalOfficer','District', 'Department', '$filter'];

    function DistrictNodalOfficerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DistrictNodalOfficer, District, Department, $filter) {
        var vm = this;

        vm.districtNodalOfficer = entity;
        vm.clear = clear;
        vm.save = save;
        var selecteddistrict=null;
        var selecteddepartment=null;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
//            vm.districtNodalOfficer.districtid= vm.districtNodalOfficer.selecteddistrict.id;
//            vm.districtNodalOfficer.districtName= vm.districtNodalOfficer.selecteddistrict.districtname;
//            vm.districtNodalOfficer.departmentid= vm.districtNodalOfficer.selecteddepartment.id;
//            vm.districtNodalOfficer.departmentname= vm.districtNodalOfficer.selecteddepartment.departmentname;
            selecteddistrict = $filter('filter')(vm.districts,{id:vm.districtNodalOfficer.districtid});
            selecteddepartment = $filter('filter')(vm.departments,{id:vm.districtNodalOfficer.departmentid});

            vm.districtNodalOfficer.districtName = selecteddistrict[0].districtname;
            vm.districtNodalOfficer.departmentname = selecteddepartment[0].departmentname;

            vm.isSaving = true;
            if (vm.districtNodalOfficer.id !== null) {
                DistrictNodalOfficer.update(vm.districtNodalOfficer, onSaveSuccess, onSaveError);
            } else {
                DistrictNodalOfficer.save(vm.districtNodalOfficer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:districtNodalOfficerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (error) {
            vm.isSaving = false;
            if(error.status == 400)
               alert("Login does not exist. Please recheck or create the same.");
        }

        vm.districts = [];
        loadAllDistricts();
        function loadAllDistricts() {
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
                var obj={districtname:'Haryana',
                        powercompany:null,
                        stateID:null,
                        id:null,
                        statename:'Haryana'};
                vm.districts.push(obj);
                for( var i = 0; i < vm.districts.length-1; i++){
                     if ( vm.districts[i].districtname === 'Chandigarh HQ') {
                       vm.districts.splice(i, 1);
                     }
                  }
            });
        }

        vm.departments = [];
        loadAllDepartments();
        function loadAllDepartments() {
            Department.query(function(result) {
                vm.departmentstemp = result;
                angular.forEach(vm.departmentstemp, function(value, key){
                    if(value.departmentname!=='Development and Panchayats'){
                        vm.departments.push(value);
                    }
                });
                vm.searchQuery = null;
            });
        }
    }
})();
