(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AssignServiceFeeController', AssignServiceFeeController);

    AssignServiceFeeController.$inject = ['AssignServiceFee'];

    function AssignServiceFeeController(AssignServiceFee) {

        var vm = this;

        vm.assignServiceFees = [];

        loadAll();

        function loadAll() {
            AssignServiceFee.query(function(result) {
                vm.assignServiceFees = result;
                vm.searchQuery = null;
            });
        }
    }
})();
