(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('ViewGrievanceFormFilledController', ViewGrievanceFormFilledController);

    ViewGrievanceFormFilledController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'grievance','downloadserviceformattachment', 'viewRecruitmentFile'];
    function ViewGrievanceFormFilledController ($timeout, $scope, $stateParams, $uibModalInstance, grievance, downloadserviceformattachment, viewRecruitmentFile) {
        var vm = this;
        vm.clear = clear;
        vm.grievancedata = grievance;
        vm.downloadFile=downloadFile;
        vm.pdfconverter= pdfconverter;


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function pdfconverter () {
            var element = document.getElementById('pdf_data');
            html2pdf(element,{
                margin: 4
            });
        }


        function clear ()
        {
            $uibModalInstance.dismiss('cancel');
        }
        function downloadFile(id)
        {
            viewRecruitmentFile.view(id)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

        // loadServiceFormFields();
        // function loadServiceFormFields() {
        //     vm.serviceFormFieldData= serviceFormFieldDataCollection.query({serviceid:vm.projectService.serviceid, projectid:vm.projectService.projectid});
        //     vm.serviceformattachments=ServiceFormAttachments.query({id:"1",serviceid:vm.projectService.serviceid, projectid:vm.projectService.projectid});
        //     vm.project = Project.get({id:vm.projectService.projectid});
        // }

    }
})();
