(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketsDetailController', TicketsDetailController);

    TicketsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tickets'];

    function TicketsDetailController($scope, $rootScope, $stateParams, previousState, entity, Tickets) {
        var vm = this;

        vm.tickets = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:ticketsUpdate', function(event, result) {
            vm.tickets = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
