(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NmaDetailController', NmaDetailController);

    NmaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Nma'];

    function NmaDetailController($scope, $rootScope, $stateParams, previousState, entity, Nma) {
        var vm = this;

        vm.nma = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:nmaUpdate', function(event, result) {
            vm.nma = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
