(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DeactivateUsersWithoutCafController', DeactivateUsersWithoutCafController);

    DeactivateUsersWithoutCafController.$inject = ['User','UserById','DeactivateUser'];

    function DeactivateUsersWithoutCafController(User,UserById,DeactivateUser) {

        var vm = this;
        vm.getUser = getUser;
        function getUser(id){
            UserById.get({id:id},function(user){
                vm.user = user;
            });
        }

        vm.deactivate = deactivate;
        function deactivate(user){
            vm.user.activated = false;
            DeactivateUser.update(vm.user,onSuccess,onError);
        }
        function onSuccess(result){
            alert("User Successfully Deactivated with login: " + result.login);
        }
        function onError(){
            alert("User Not Deactivated");
        }
    }
 })();
