(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NswsRedirectUrlController', NswsRedirectUrlController);

    NswsRedirectUrlController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$stateParams', '$sessionStorage', '$location', '$q'];

    function NswsRedirectUrlController ($scope, Principal, LoginService, $state, $stateParams, $sessionStorage, $location, $q) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;

        vm.token = $location.search().token;

        vm.url =  $location.search().redirectparam;
        login();

        function login() {
            var defer = loginWithToken(vm.token, false);
            defer.then(function () {
                getAccount();
            });
        }

        function getAccount() {
            Principal.identity(true).then(function(account) {
                $state.go("listproject");
            }, function (error) {

            });
        }

         function storeAuthenticationToken(jwt) {
            $sessionStorage.authenticationToken = jwt;

        }

        function loginWithToken(jwt, rememberMe) {
            var deferred = $q.defer();

            if (angular.isDefined(jwt)) {
                storeAuthenticationToken(jwt, rememberMe);
                deferred.resolve(jwt);
            } else {
                deferred.reject();
            }

            return deferred.promise;
        }
    }
})();
