(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerDcProjectDialogController', TowerDcProjectDialogController);

    TowerDcProjectDialogController.$inject = ['$scope','$stateParams','GetServiceLog','serviceFormFieldDataCollection','ServiceFormAttachments','$uibModalInstance','downloadserviceformattachment','ProjectAction','projectService', 'ProjectServiceLogUpdated', 'Projectservicedetail', 'ProjectServiceReportInfoForClearance', 'ProjectAttachemnt', 'FileManagement','CheckContentFile','ProjectServicePaymentDetailsByProjectAndService', 'TowerRejectionReason', 'RejectedProjectServiceLog', 'ReasonCategory','TowerRejectionReasonByCategory'];

    function TowerDcProjectDialogController ($scope,$stateParams,GetServiceLog,serviceFormFieldDataCollection,ServiceFormAttachments,$uibModalInstance,downloadserviceformattachment,ProjectAction,projectService, ProjectServiceLogUpdated, Projectservicedetail, ProjectServiceReportInfoForClearance, ProjectAttachemnt, FileManagement,CheckContentFile ,ProjectServicePaymentDetailsByProjectAndService, TowerRejectionReason, RejectedProjectServiceLog, ReasonCategory, TowerRejectionReasonByCategory) {
        var vm = this;
        vm.projects=[];
        vm.downloadFile=downloadFile;
        vm.clearService=clearService;
        vm.addComment=addComment;
        vm.deactivateService=deactivateService;
        vm.rejectService=rejectService;
        vm.reactivate=reactivate;
        vm.undoreject=undoreject;
        vm.deemedClearService = deemedClearService;
        vm.addClarification=addClarification;

        vm.projectService = projectService;

        vm.status=$stateParams.action;
        vm.projectAttachemnt={};
        var projectServiceLogid=null;
        var projectAttachmentResultObject=null;
        vm.rejectedProjectServiceLog = {};
        vm.getReasons = getReasons;
        vm.getRejectionReason = getRejectionReason;
        vm.selectedCategory = {};
        vm.rejectionReason = "";

        $scope.file = {};

        vm.rejectionReasons = [];
        vm.reasonCategories = [];

        loadAll();

        function loadAll() {
            ReasonCategory.query(function(result) {
                vm.reasonCategories = result;
                vm.searchQuery = null;
            });
        }

        function getRejectionReason(category) {
            TowerRejectionReasonByCategory.query({category: category.category}, function(result) {
                vm.rejectionReasons = result;
                vm.searchQuery = null;
            });
        }

        function getReasons() {
            var reasons = [];
            angular.forEach(vm.selectedReason, function (value, key) {
                reasons.push(value.reason);
            }, reasons);
            var rejection = reasons.join();

            var code = [];
            angular.forEach(vm.selectedReason, function (value, key) {
                code.push(value.code);
            }, code);
            vm.rejectionReason = rejection;
            vm.rejectedProjectServiceLog.reasonCode = code;
        }


        loadProject();

        function loadProject() {
            vm.project=projectService;
           switch (vm.status){
               case 'service-logs':
                   viewlogs(vm.project);
                   break;
               case 'view-form':
                   loadFilledForm(vm.project);
                   break;
           }
        }

        vm.pdfconverter= function  () {
            console.log("called");
            var element = document.getElementById('pdf_data');
            html2pdf(element,{
                margin: 4
            });
        };

        function viewlogs(project) {
            vm.project=project;
            GetServiceLog.query({projectid:project.projectid,serviceid:project.serviceid},function (result) {
                vm.ServiceLog=result;
            });
        }

        function loadFilledForm(project) {
            vm.project=project;
            vm.serviceFormFieldData= serviceFormFieldDataCollection.query({serviceid:project.serviceid, projectid:project.projectid});
            vm.serviceformattachments=ServiceFormAttachments.query({id:"1",serviceid:project.serviceid, projectid:project.projectid});
        }

        loadAllPayments();

        function loadAllPayments() {
            vm.assignFeeDetail=ProjectServicePaymentDetailsByProjectAndService.query({projectid: vm.projectService.projectid,serviceid: vm.projectService.serviceid});
        }


        function clearService() {
            ProjectAction.clear(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function addComment() {
            ProjectAction.comment(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

         function addClarification(role) {
                    ProjectAction.clarification(vm.project,vm.projectAttachemnt,vm.comments, role, onsuccess,onerror);
                }

        function deactivateService() {
            ProjectAction.deactivate(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function rejectService() {
            ProjectAction.reject(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror, vm.selectedCategory.category, vm.rejectionReason);
        }

        function reactivate() {
            ProjectAction.reactivate(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function undoreject() {
            ProjectAction.undoreject(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function deemedClearService(){
                if(CheckContentFile.checkFile(vm.projectAttachemnt.file.name)){
                    vm.projectServiceLog.projectid = vm.projectService.projectid;
                    vm.projectServiceLog.serviceid=vm.projectService.serviceid;
                    vm.projectServiceLog.actionTaken="DeemedCleared";
                    ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);
                }
                else{
                    alert("Invalid File");

            }
        }

        function onServiceLogSaveSuccess(result)
        {
            alert("2nd");
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments=vm.projectServiceLog.comments;
            vm.projectService.status="DeemedCleared";
            vm.projectService.deemedClearedDate = result.commentDate;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
        }

        function onUpdateProjectServiceSuccess(result)
        {
            alert("3rd");
            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            vm.projectservicereportinfo = ProjectServiceReportInfoForClearance.get({projectid:vm.projectService.projectid,department:vm.projectService.departmentName,service:vm.projectService.serviceName});
            vm.projectservicereportinfo.status='DeemedCleared';
            vm.projectservicereportinfo.projectid = result.projectid;
            vm.projectservicereportinfo.departmentname = result.departmentName;
            vm.projectservicereportinfo.servicename = result.serviceName;
            ProjectServiceReportInfoForClearance.update(vm.projectservicereportinfo,onUpdateProjectServiceReportInfoSuccess,onUpdateProjectServiceReportInfoError);
        }

        function onUpdateProjectServiceReportInfoSuccess(result)
        {
            alert("4nd");
            $scope.$emit('investhryApp:projectServiceReportInfoUpdate', result);
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

        function saveProjectAttachment()
        {
            alert("5nd");
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Deemed Clearance Attachment";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }

        function onSaveProjectAttachmentSuccess(result)
        {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }

            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }

        function onUpdateProjectAttachmentSuccess(result)
        {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }

        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }

        function onUpdateProjectServiceReportInfoError()
        {
            vm.isSaving = false;
        }

        function onUpdateProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function onSaveProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function downloadFile(serverfilename,downloadfilename)
        {
            downloadserviceformattachment.download(serverfilename,downloadfilename)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
                });
        }

        vm.clear=function () {
            $uibModalInstance.close();
        };

        function onsuccess(result) {
            console.log(result);
            vm.rejectedProjectServiceLog.projectServiceLogId = result.id;
            RejectedProjectServiceLog.save(vm.rejectedProjectServiceLog);
            $uibModalInstance.close();
        }

        function onerror() {

        }
    }
})();
