(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('Clearancecharts', Clearancecharts);

    Clearancecharts.$inject = [];

    function Clearancecharts () {
        var deptname;
        var serviceStats;
        var Status;
        return {
            putClearanceData:function(departmentname,servicestats,status){
               deptname=departmentname;
               serviceStats=servicestats;
               Status=status;
            },
            getdepartmentname:function(){
                return deptname;
            },
            getservicestats:function(){
                return serviceStats;
            },
            getStatus:function(){
                return Status;
            }

        }
    }
})();
