(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('PsTowerServiceViewController', PsTowerServiceViewController);

    PsTowerServiceViewController.$inject = ['$timeout','$filter', '$scope', '$stateParams', '$uibModalInstance', 'ServiceViewCafYearly', 'TowerserviceNO','ServiceViewCafYearlyByElastic','DownloadServiceViewCafYearlyByElastic','DownloadCafCsv', 'PsTowerServiceViewByElastic', 'PsTowerDashboardDatewise', 'DownloadRejectionReason'];
    function PsTowerServiceViewController ($timeout,$filter, $scope, $stateParams, $uibModalInstance, ServiceViewCafYearly, TowerserviceNO, ServiceViewCafYearlyByElastic,DownloadServiceViewCafYearlyByElastic,DownloadCafCsv, PsTowerServiceViewByElastic, PsTowerDashboardDatewise, DownloadRejectionReason) {
        var vm = this;

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";
        vm.clear = clear;
        vm.servicename=$stateParams.servicename;
        vm.status=$stateParams.status;
        vm.serviceid=$stateParams.serviceid;
        var initDate=new Date('05-01-2017');
        var totalTime=0;
        var totalCleared=0;
        vm.showDepartments=showDepartments;
        vm.departmentName=[];
        vm.toDate = null;
        vm.fromDate = null;
        vm.downloadAllData = downloadAllData;
        vm.openCalendar = openCalendar;
        vm.hideClear = false;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;
        // vm.clearFilterButton = false;
        vm.selectedDate=selectedDate;



        vm.spiner= true;

        $scope.file = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
            /*appliedDate=new Date(result[i].appliedDate.toDateString());
            clearedDate=new Date(result[i].clearedDate.toDateString());
            dayDiff= (clearedDate - appliedDate)  / 1000 / 60 / 60 / 24;
*/
        loadAllCafs();
        function loadAllCafs() {
            var appliedDate;
            var clearedDate;
            var dayDiff;
            if (!$stateParams.departmentName) {
                vm.deptName = null;
            } else {
                vm.deptName = $stateParams.departmentName;
            }

            if (!$stateParams.district) {
                vm.districtName = null;
            } else {
                vm.districtName = $stateParams.district;
            }

            if (!$stateParams.projectLevel) {
                vm.levelfiled = null;
            } else {
                vm.levelfiled = $stateParams.projectLevel;
            }
            var data = {
                departmentName: vm.deptName,
                district: vm.districtName,
                projectLevel: vm.levelfiled,
                serviceId: $stateParams.serviceid,
                status: $stateParams.status,
                page: $scope.currentPage - 1,
                size: $scope.pageSize
            };
            Object.keys(data).forEach(function (k) {
                if (!data[k] && data[k] !== undefined) {
                    delete data[k];
                }
            });

            //   vm.dashboardcafs=ServiceViewCafYearly.query({serviceid:$stateParams.serviceid,status:$stateParams.status},function(result){
            //     vm.show=true;
            //     vm.spiner=false;
            //     console.log(result);
            // });
            vm.dashboardcafs = PsTowerServiceViewByElastic.get(data,
                function (result) {
                    vm.show = true;
                    vm.spiner = false;
                });
        }

        vm.loadAllCafsByDate=function () {
            var f=$filter('date')(vm.fromdate, "yyyy-MM-dd");
            var t=$filter('date')(vm.todate, "yyyy-MM-dd");
            var fromdate=$filter('date')(vm.fromdate, "yyyy-MM-dd");
            var todate=$filter('date')(vm.todate, "yyyy-MM-dd");

            if (!$stateParams.departmentName) {
                vm.deptName = null;
            } else {
                vm.deptName = $stateParams.departmentName;
            }

            if (!$stateParams.district) {
                vm.districtName = null;
            } else {
                vm.districtName = $stateParams.district;
            }

            if (!$stateParams.projectLevel) {
                vm.levelfiled = null;
            } else {
                vm.levelfiled = $stateParams.projectLevel;
            }
            var data = {
                departmentName: vm.deptName,
                district: vm.districtName,
                projectLevel: vm.levelfiled,
                serviceId: $stateParams.serviceid,
                status: $stateParams.status,
                fromDate: fromdate,
                toDate: todate,
                page: $scope.currentPage - 1,
                size: $scope.pageSize
            };
            console.log(data);

            Object.keys(data).forEach(function (k) {
                if (!data[k] && data[k] !== undefined) {
                    delete data[k];
                }
            });

            vm.dashboardcafs = PsTowerDashboardDatewise.get(data,
                function (result) {
                vm.show = true;
                vm.hideClear = true;
                vm.spiner = false;
            });
        };

        function downloadAllData() {
            DownloadServiceViewCafYearlyByElastic.query({
                    departmentName: vm.deptName,
                    district: vm.districtName,
                    projectLevel: vm.levelfiled,
                    serviceId: $stateParams.serviceid,
                    status: $stateParams.status
                },function (result) {
                DownloadCafCsv.download(result[0].status).then(function (success) {
                    console.log(success);
                }, function (error) {
                    alert('error : ' + error);
                });
            });
        }

        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
        };

        $scope.pageChangedHandler = function(num) {
            loadAllCafs();
        };

        function showDepartments(data) {
            TowerserviceNO.query({id:'1',projectdetailid:data.projectdetailid},function (result) {
                if(result.length===0){
                    vm.departmentName=[];
                    vm.departmentName[0] = "NA";
                }
                else {
                    vm.departmentName = result;
                }
            });

        }



        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.dateOptions = {
            minDate: new Date("05-01-2017"),
            maxDate: Date.now()
        };

        function selectedDate(dateSelected){
            var newDate = new Date(dateSelected);
            newDate.setDate(newDate.getDate()+1200);
            if(newDate > Date.now()){
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: Date.now()
                };
            }
            else {
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: newDate
                };
            }
        }
        vm.clearFilter = function () {
            vm.filter ={};
            loadAll();
        }
    }
})();
