(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('FileManagement', ['$http', function($http) {
            function saveFile(file, filename, onsuccess, onerror) {
                var resourceUrl = 'api/FileManagement';
                var fd = new FormData();
                fd.append('file', file);
                fd.append('filename', filename);
                $http.post(resourceUrl, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    }
                }).then(function(response) {
                    console.log('File uploaded successfully', response);
                    if (onsuccess !== undefined) {
                        onsuccess(response);
                    }
                }).catch(function(error) {
                    console.error('Error uploading file', error);
                    if (onerror !== undefined) {
                        onerror(error);
                    }
                });
            }

            function saveFileForBrapCheckList(file, filename, onsuccess, onerror) {
                var resourceUrl = 'api/FileManagementBrapChecklist';
                var fd = new FormData();
                fd.append('file', file);
                fd.append('filename', filename);
                $http.post(resourceUrl, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    }
                }).then(function(response) {
                    console.log('File uploaded successfully', response);
                    if (onsuccess !== undefined) {
                        onsuccess(response);
                    }
                }).catch(function(error) {
                    console.error('Error uploading file', error);
                    if (onerror !== undefined) {
                        onerror(error);
                    }
                });
            }

            return {
                saveFile: saveFile,
                saveFileForBrapCheckList: saveFileForBrapCheckList
            };
        }]);
})();
