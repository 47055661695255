(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('projectdetailjistController', projectdetailjistController);
    projectdetailjistController.$inject = ['$stateParams', '$scope', 'Principal', 'LoginService', '$state', 'projectInfo'];

    function projectdetailjistController($stateParams, $scope, Principal, LoginService, $state, projectInfo) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.project = projectInfo;


        if (vm.project.caftype === 'FILMCAF') {
            vm.project.caftype = 'Film'
        }
        if (vm.project.caftype === 'LandExchange') {
            vm.project.caftype = 'Land'
        }
        if (vm.project.caftype === 'Renewable') {
            vm.project.caftype = 'Renew'
        }

        $scope.currentstate = $state.current.name;
        $scope.projectId = $stateParams.projectid;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }


        function register() {
            $state.go('register');
        }

        vm.view_form = function () {
            if (projectInfo.caftype === 'New') {
                $state.go('ProjectCompleteDetailNew', {id: $scope.projectId}, {reload: true});
            } else if (projectInfo.caftype === 'Shop') {
                $state.go('ProjectCompleteDetailShop', {id: $scope.projectId}, {reload: true});
            } else if (projectInfo.caftype === 'Excise') {
                $state.go('ProjectCompleteDetailExcise', {id: $scope.projectId}, {reload: true});
            } else if (projectInfo.caftype === 'Renew') {
                $state.go('ProjectCompleteDetailRenewable', {id: $scope.projectId}, {reload: true});
            } else if (projectInfo.caftype === 'LandRequirement' || projectInfo.caftype === 'Land') {
                $state.go('ProjectCompleteDetailLandRequirement', {id: $scope.projectId}, {reload: true});
            } else if (projectInfo.caftype === 'Tower' || projectInfo.caftype === 'ROW') {
                $state.go('ProjectCompleteDetailTower', {id: $scope.projectId}, {reload: true});
            } else if (projectInfo.caftype === 'Film') {
                $state.go('ProjectCompleteDetailFilm', {id: $scope.projectId}, {reload: true});
            } else {
                alert("unexpected error occured");
            }
        }
    }

})();
