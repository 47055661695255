(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DisputelogDeleteController',DisputelogDeleteController);

    DisputelogDeleteController.$inject = ['$uibModalInstance', 'entity', 'Disputelog'];

    function DisputelogDeleteController($uibModalInstance, entity, Disputelog) {
        var vm = this;

        vm.disputelog = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Disputelog.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
