(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('LandexchangeController', LandexchangeController);

    LandexchangeController.$inject = ['Landexchange'];

    function LandexchangeController(Landexchange) {

        var vm = this;

        vm.landexchanges = [];

        loadAll();

        function loadAll() {
            Landexchange.query(function(result) {
                vm.landexchanges = result;
                vm.searchQuery = null;
            });
        }
    }
})();
