(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerServiceNoRequireController', TowerServiceNoRequireController);

    TowerServiceNoRequireController.$inject = ['TowerServiceNoRequire'];

    function TowerServiceNoRequireController(TowerServiceNoRequire) {

        var vm = this;

        vm.towerServiceNoRequires = [];

        loadAll();

        function loadAll() {
            TowerServiceNoRequire.query(function(result) {
                vm.towerServiceNoRequires = result;
                vm.searchQuery = null;
            });
        }
    }
})();
