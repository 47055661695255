(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('PolicyDetailsDetailController', PolicyDetailsDetailController);

    PolicyDetailsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PolicyDetails'];

    function PolicyDetailsDetailController($scope, $rootScope, $stateParams, previousState, entity, PolicyDetails) {
        var vm = this;

        vm.policyDetails = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:policyDetailsUpdate', function(event, result) {
            vm.policyDetails = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
