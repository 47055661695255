(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('InvestorTicketFeedBackController', InvestorTicketFeedBackController);

    InvestorTicketFeedBackController.$inject = ['$state', '$stateParams', 'TicketsForFeedBack', 'TicketsFeedback'];

    function InvestorTicketFeedBackController ($state, $stateParams, TicketsForFeedBack, TicketsFeedback) {
        var vm = this;
        vm.rating="Excellent";
        vm.ticketDetail={};
        vm.ticketFeedback={};
        vm.isSaving = false;
        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.saveFeedback=saveFeedback;
        loadAll();

        function loadAll() {
            TicketsForFeedBack.get({ticketNumber: $stateParams.key}, function (result) {
                vm.ticketDetail = result;
            })
        }

        function saveFeedback() {
            vm.isSaving = true;
            vm.ticketFeedback.id = vm.ticketDetail.id;
            vm.ticketFeedback.ticketNumber = vm.ticketDetail.ticketNumber;
            vm.ticketFeedback.category = vm.ticketDetail.incidentCategory;
            vm.ticketFeedback.investorDetail = vm.ticketDetail.investorDetail;
            vm.ticketFeedback.rating = vm.rating;
            vm.ticketFeedback.ratingRemark = vm.ratingRemark;
            if(vm.ticketDetail.assignedDepartmentId!=null){
                vm.ticketFeedback.departmentName = vm.ticketDetail.assignedDepartmentName;
            }
            if(vm.ticketDetail.assignedMemberUserName == null) {
                vm.ticketFeedback.closedBy = vm.ticketDetail.assignedMemberName;
            } else {
                vm.ticketFeedback.closedBy = vm.ticketDetail.assignedMemberUserName;
            }
            vm.ticketFeedback.closingDate = vm.ticketDetail.resolveDate;
            TicketsFeedback.update(vm.ticketFeedback, onSaveSuccess, onError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            alert("Thank you for your feedback!");
            $state.go('home');
        }

        function onError(result) {
            vm.isSaving = false;
            if(result) {
                alert(result.data.ratingRemark);
            } else {
                alert("Please Try after some time!");
            }
            $state.go('home');
        }

    }
})();
