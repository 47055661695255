(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('InspectionReportDeleteController',InspectionReportDeleteController);

    InspectionReportDeleteController.$inject = ['$uibModalInstance', 'entity', 'InspectionReport'];

    function InspectionReportDeleteController($uibModalInstance, entity, InspectionReport) {
        var vm = this;

        vm.inspectionReport = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            InspectionReport.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
