(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
         .state('totaldeptservicesdetailyearly', {
          parent: 'servicedashboardyearly',
          url: '/servicedashboardcafsYearlyByElasticByStatus/{departmentName}/{district}/{projectLevel}/{serviceId}/{status}',
          data: {
              authorities: []
          },
          onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
              $uibModal.open({
                  templateUrl: 'app/totaldeptservicesdetailyearly/totaldeptservicesdetailyearly.html',
                  controller: 'TotalDeptServicesDetailYearlyController',
                  controllerAs: 'vm',
                  backdrop: 'static',
                  size: 'lg',
                  windowClass: 'app-modal-window',
                  resolve: {
                      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                          $translatePartialLoader.addPart('dashboard');
                          return $translate.refresh();
                      }]
                  }
              }).result.then(function() {
                  $state.go('servicedashboardyearly', null, { reload: 'servicedashboardyearly' });
              }, function() {
                  $state.go('servicedashboardyearly');
              });
          }]
      });
    }
})();
