(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievancedetailController', GrievancedetailController);

    GrievancedetailController.$inject = ['$scope', '$state','$uibModalInstance', 'Principal', 'LoginService', 'Grievance','grievancedetail', 'District', 'Department','FileManagement'];

    function GrievancedetailController ($scope, $state, $uibModalInstance, Principal, LoginService, Grievance, grievancedetail, District, Department,FileManagement) {
        var vm = this;
        vm.grievancedata = grievancedetail;
        vm.grievancedata.isUserAttachmentPresent = false;

        vm.account = null;
        vm.isAuthenticated = null;
        $scope.file = {};
        vm.login = LoginService.open;
        vm.register = register;
        vm.datePickerOpenStatus = {};

        vm.fileattachment = [];
        vm.openCalendar = openCalendar;

        vm.clear = clear;
        vm.saveGrievanceData = saveGrievanceData;
        console.log(vm.grievancedata);

        District.query(function(result) {
           vm.districts = result;
           vm.searchQuery = null;
       });

        Department.query(function(result) {
            vm.departments = result;
            vm.searchQuery = null;
        });

        function saveGrievanceData()
        {
            vm.grievancedata.department = vm.grievancedata.selecteddepartment.departmentname;
            vm.grievancedata.departmentId = vm.grievancedata.selecteddepartment.id;
            vm.grievancedata.district = vm.grievancedata.selecteddistrict.districtname;
            vm.grievancedata.districtId = vm.grievancedata.selecteddistrict.id;
            Grievance.save(vm.grievancedata,onSaveSuccess,onSaveError);
        }
        function onSaveSuccess(result)
        {
            vm.fileattachment = [];
            $uibModalInstance.close(result);
        }
        function onSaveError()
        {
            vm.fileattachment = [];

            $uibModalInstance.close();
        }
        function clear ()
        {
            vm.fileattachment = [];
            $uibModalInstance.dismiss('cancel');
        }
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();
        vm.addfileattachment = function(){
            if(!vm.grievancedata.grievanceRefferedToDept){
                vm.dispute.dateOfCommunication = null;
            }

            if(vm.attachment1 != null){
                vm.fileattachment.push({file:vm.attachment1,name:"Attachment1"})
            }
            if(vm.attachment2 != null){
                vm.fileattachment.push({file:vm.attachment2,name:"Attachment2"})
            }
            if(vm.fileattachment.length != 0){
                // var fileNameArray = vm.attachment.name.split(".");
                // var fileextension="";
                // if((fileNameArray.length>1)){
                //     fileextension=fileNameArray[(fileNameArray.length-1)];
                // }
                // var inputFileName=vm.attachment.name.split(".")[0];
                // if(fileextension!==""){
                //     inputFileName=inputFileName+"."+fileextension;
                // }
                vm.grievancedata.isUserAttachmentPresent = true;
            }
            vm.grievancedata.department = vm.grievancedata.selecteddepartment.departmentname;
            vm.grievancedata.departmentId = vm.grievancedata.selecteddepartment.id;
            vm.grievancedata.district = vm.grievancedata.selecteddistrict.districtname;
            vm.grievancedata.districtId = vm.grievancedata.selecteddistrict.id;
//            vm.grievancedata.isSaving = true;
            Grievance.save(vm.grievancedata,onSaveAttachmentSuccess,onSaveError);
        }

        function onSaveAttachmentSuccess (result){
            if(vm.fileattachment.length !==0) {
                // var filename = result.id;
                // var file =vm.attachment;
                for (var i = 0; i < vm.fileattachment.length; i++) {
                    var filename = result.id + "_" +i;
                    var file = vm.fileattachment[i].file;
                    FileManagement.saveFile(file, filename);
                    var fileNameArray = vm.fileattachment[i].file.name.split(".");
                    var fileextension = "";
                    if ((fileNameArray.length > 1)) {
                        fileextension = fileNameArray[(fileNameArray.length - 1)];
                    }
                    var inputFileName = result.id;
                    if (fileextension !== "") {
                        inputFileName = inputFileName + "." + fileextension;
                    }
                    if (vm.fileattachment[i].name === "Attachment1") {
                        result.attachment1 = filename + "." + fileextension
                    }
                    if (vm.fileattachment[i].name === "Attachment2") {
                        result.attachment2 = filename + "." + fileextension
                    }

                    // result.description = result.id+"."+fileextension;
                }
                Grievance.update(result, onSaveSuccess, onSaveError);
            }
            $uibModalInstance.close(result);
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
        vm.datePickerOpenStatus.dateOfFiling =false;
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
