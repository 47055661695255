(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('InspectorDetailsDetailController', InspectorDetailsDetailController);

    InspectorDetailsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InspectorDetails'];

    function InspectorDetailsDetailController($scope, $rootScope, $stateParams, previousState, entity, InspectorDetails) {
        var vm = this;

        vm.inspectorDetails = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:inspectorDetailsUpdate', function(event, result) {
            vm.inspectorDetails = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
