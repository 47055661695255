(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('disputes', {
            parent: 'app',
            url: '/disputes',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN' ,'ROLE_DISPUTE_OFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/disputeForm/disputes.html',
                    controller: 'DisputeAllController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('myDisputes', {
                parent: 'app',
                url: '/myDisputes',
                data: {
                    authorities: ['ROLE_USER','ROLE_NODAL_OFFICER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/disputeForm/userdisputes.html',
                        controller: 'UserDisputeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

        .state('disputeForm', {
            parent: 'myDisputes',
            url: '/disputeForm',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/disputeForm/disputeForm.html',
                    controller: 'DisputeFormController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nameOfEnterprise: null,
                                activity: null,
                                nameOfPartner: null,
                                mobileNumber: null,
                                emailAddress: null,
                                fixedInvestement: null,
                                categoryOfDispute: null,
                                detailOfDispute: null,
                                statusPendingLevel: null,
                                isResolved: null,
                                disputeID: null,
                                gotReliefLevel: null,
                                levelOfRelief: null,
                                departmentName: null,
                                departmentAppeals: null,
                                address: null,
                                officeAddress: null,
                                resolveComment: null,
                                userlogin: null,
                                dateOfFilingPetition: null,
                                replyOfDepartment: null,
                                detailsOfDecision: null,
                                orderOfReliefAttachemnt: null,
                                appealsDocument: null,
                                distrcit: null,
                                dateOfFiling: null,
                                durationOfPendency: null,
                                otherCategoryDispute: null,
                                creationDate: null,
                                lastActionDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('myDisputes', null, { reload: 'myDisputes' });
                }, function() {
                    $state.go('myDisputes');
                });
            }]
        })
            .state('resolveDisputes', {
                parent: 'disputes',
                url: '/resolveDisputes/{id}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DISPUTE_OFFICER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/disputeForm/disputeResolve.html',
                        controller: 'ResolveDisputeController',
                        controllerAs: 'vm',
                        resolve: {
                            dispute: ['Dispute', function(Dispute) {
                                return Dispute.get({id : $stateParams.id}).$promise;
                            }]
                        }

                    }).result.then(function() {
                        $state.go('disputes', null, { reload: 'disputes' });
                    }, function() {
                        $state.go('disputes');
                    });
                }]
            })

    .state('assignDispute', {
            parent: 'disputes',
            url: '/assignDispute/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DISPUTE_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/disputeForm/assignDispute.html',
                    controller: 'AssignDisputeController',
                    controllerAs: 'vm',
                    resolve: {
                        dispute: ['Dispute', function(Dispute) {
                            return Dispute.get({id : $stateParams.id}).$promise;
                        }]
                    }

                }).result.then(function() {
                    $state.go('disputes', null, { reload: 'disputes' });
                }, function() {
                    $state.go('disputes');
                });
            }]
        })
            .state('viewDisputeLog-NO', {
                parent: 'disputes',
                url: '/viewDisputeLog/{id}',
                data: {
                    authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER','ROLE_DISPUTE_OFFICER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/disputeForm/viewLog.html',
                        controller: 'ViewDisputeLogController',
                        controllerAs: 'vm',
                        size:'lg',

                        resolve: {
                            dispute: ['Dispute', function(Dispute) {
                                return Dispute.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('disputes', null, { reload: 'disputes' });
                    }, function() {
                        $state.go('disputes');
                    });
                }]
            })

            .state('myDisputeLog', {
                parent: 'myDisputes',
                url: '/myDisputeLog/{id}',
                data: {
                    authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER','ROLE_DISPUTE_OFFICER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/disputeForm/viewLog.html',
                        controller: 'ViewDisputeLogController',
                        controllerAs: 'vm',
                        size:'lg',

                        resolve: {
                            dispute: ['Dispute', function(Dispute) {
                                return Dispute.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('myDisputes', null, { reload: 'myDisputes' });
                    }, function() {
                        $state.go('myDisputes');
                    });
                }]
            })
            .state('commentDispute', {
            parent: 'myDisputes',
            url: '/comment/{id}',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/disputeForm/disputeComment.html',
                    controller: 'DisputeCommentController',
                    controllerAs: 'vm',
                    size:'lg',

                    resolve: {
                        dispute: ['Dispute', function(Dispute) {
                            return Dispute.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('myDisputes', null, { reload: 'myDisputes' });
                }, function() {
                    $state.go('myDisputes');
                });
            }]
        })
            .state('commentDispute-DeskOfficer', {
                parent: 'disputes',
                url: '/comment/{id}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DISPUTE_OFFICER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/disputeForm/disputeComment.html',
                        controller: 'DisputeCommentController',
                        controllerAs: 'vm',
                        size:'lg',

                        resolve: {
                            dispute: ['Dispute', function(Dispute) {
                                return Dispute.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('disputes', null, { reload: 'disputes' });
                    }, function() {
                        $state.go('disputes');
                    });
                }]
            })

            .state('viewDisputeFilledForm', {
            parent: 'disputes',
            url: '/viewFilledForm/{id}',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER','ROLE_DISPUTE_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/disputeForm/ViewDisputeFormFilled.html',
                    controller: 'ViewDisputeFormFilledController',
                    controllerAs: 'vm',
                    size:'lg',
                    backdrop:'static',
                    keyboard:'false',

                    resolve: {
                        dispute: ['Dispute', function(Dispute) {
                            return Dispute.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('disputes', null, { reload: 'disputes' });
                }, function() {
                    $state.go('disputes');
                });
            }]
        })
            .state('viewUserFilledForm', {
                parent: 'myDisputes',
                url: '/viewFilledForm/{id}',
                data: {
                    authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/disputeForm/ViewDisputeFormFilled.html',
                        controller: 'ViewDisputeFormFilledController',
                        controllerAs: 'vm',
                        size:'lg',
                        backdrop:'static',
                        keyboard:'false',

                        resolve: {
                            dispute: ['Dispute', function(Dispute) {
                                return Dispute.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('myDisputes', null, { reload: 'myDisputes' });
                    }, function() {
                        $state.go('myDisputes');
                    });
                }]
            });
    }
})();
