(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('IncentiveFormController', IncentiveFormController);

    IncentiveFormController.$inject = ['$stateParams','$scope', '$state','IncentiveForm'];

    function IncentiveFormController ($stateParams,$scope, $state, IncentiveForm) {
        var vm = this;
        vm.param = $stateParams.param;


        IncentiveForm.query(function(result){
            vm.userdetails=result;
        });

        vm.goToIncentiveForm = function () {
            $state.go("incentiveforms");
        }

    }
})();
