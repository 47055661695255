(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('hepcDashboard', {
            parent: 'hepcticket',
            url: '/HEPC-dashboard',
            data: {
                authorities: ["ROLE_ADMIN","ROLE_SUB_ADMIN","ROLE_TICKET_ADMIN"]
            },
            views: {
                'content_section@hepcticket': {
                    templateUrl: 'app/hepcTickets/hepcDashboard/hepcDashBoard.html',
                    controller: 'HepcDashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
            .state('hepcdashboard', {
                parent: 'hepcticket',
                url: '/HEPCdashboard',
                data: {
                    authorities: []
                },
                views: {
                    'content_section@hepcticket': {
                        templateUrl: 'app/hepcTickets/hepcDashboard/hepcOpenDashBoard.html',
                        controller: 'HepcOpenDashboardController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
