(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('nmaDetailPageController', nmaDetailPageController);

    nmaDetailPageController.$inject = ['$stateParams', 'Nma'];

    function nmaDetailPageController ($stateParams, Nma) {
        var vm = this;

        vm.nma={};

        loadAll();

        function loadAll() {
            Nma.get({id: $stateParams.nmaId},function (result) {
                vm.nma=result;
            })
        }
    }
})();
