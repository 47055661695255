(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DecriminalizationFormController', DecriminalizationFormController);

    DecriminalizationFormController.$inject = ['$scope', 'entity', 'Decriminalization', 'Department'];

    function DecriminalizationFormController($scope, entity, Decriminalization, Department) {

        var vm = this;

        vm.decriminalizations = [];

        vm.save = save;


        vm.decriminalization = entity;

        vm.showServiceType = false;
        vm.showServiceName = false;
        vm.showSector = false;
        vm.showOtherSector = false;
        vm.showOtherServiceType = false;
        vm.disableMinTenure = true;
        vm.disableMaxTenure = true;
        vm.disableMinAmount = true;
        vm.disableMaxAmount = true;

        loadAll();

        function loadAll() {
            getDepartments();
            Decriminalization.query(function(result) {
                vm.decriminalizations = result;
                vm.searchQuery = null;
            });
        }

        function save () {

            if (vm.decriminalization.complianceRequest==null){
                alert("Please Choose Compliance Request Interface");
                return;
            }

            if (vm.decriminalization.departmentId==null){
                alert("Please Select Department");
                return;
            }
            if (vm.decriminalization.complianceRequest === "Citizen") {


                if (vm.decriminalization.serviceType==null){
                    alert("Please Select Service Type");
                    return;
                }

                if (vm.decriminalization.serviceSchemeName==null){
                    alert("Please Fill Service Scheme Name");
                    return;
                }

                if (vm.decriminalization.serviceType==="Other" && vm.decriminalization.otherServiceType==null){
                    alert("Please Enter Other Service Type");
                    return;
                }

            } else {
                if (vm.decriminalization.actName==null){
                    alert("Please Enter ACT Name");
                    return;
                }

                if (vm.decriminalization.sector==null){
                    alert("Please Select Sector");
                    return;
                }

                if (vm.decriminalization.sector==="Other" && vm.decriminalization.otherSector==null){
                    alert("Please Enter Other Sector");
                    return;
                }
            }

            if (vm.decriminalization.ruleRegulation==null){
                alert("Please Select Rule Regulation");
                return;
            }

            if (vm.decriminalization.ruleRegulationName==null){
                alert("Please Enter Rule Regulation Name");
                return;
            }



            if (vm.decriminalization.sectionRuleNumber==null){
                alert("Please Select Section/Rule number");
                return;
            }




            if (vm.decriminalization.sectionRuleNumberCriminalProvision==null){
                alert("Please Enter Section/Rule number of Criminal Provision");
                return;
            }



            if (vm.decriminalization.criminalPunishmentDescription==null){
                alert("Please Enter Criminal Punishment Description");
                return;
            }




            if (vm.decriminalization.offence==null){
                alert("Please Select Offence");
                return;
            }

            if (vm.decriminalization.criminalPunishmentTenureInMonths==null){
                alert("Please Select Criminal Punishment Tenure in months");
                return;
            }

            if (vm.decriminalization.criminalPunishmentTenureInMonths==="Enter months" && vm.decriminalization.minimumTenure==null){
                alert("Please Enter Minimum Tenure");
                return;
            }

            if (vm.decriminalization.criminalPunishmentTenureInMonths==="Enter months" && vm.decriminalization.maximumTenure==null){
                alert("Please Enter Maximum Tenure");
                return;
            }


            if (vm.decriminalization.criminalPunishmentAmountInInr==null){
                alert("Please Enter Criminal Punishment Amount in INR");
                return;
            }

            if (vm.decriminalization.criminalPunishmentAmountInInr==="Enter Amount" && vm.decriminalization.minimumAmount==null){
                alert("Please Enter Minimum Amount");
                return;
            }


            if (vm.decriminalization.criminalPunishmentAmountInInr==="Enter Amount" && vm.decriminalization.maximumAmount==null){
                alert("Please Enter Maximum Amount");
                return;
            }


            vm.isSaving = true;
            if (vm.decriminalization.id !== null) {
                Decriminalization.update(vm.decriminalization, onSaveSuccess, onSaveError);
            } else {
                Decriminalization.save(vm.decriminalization, onSaveSuccess, onSaveError);
            }
        }

        $scope.requestInterface = function (value) {
            //If DIV is visible it will be hidden and vice versa.
           if (value==="Citizen"){

               vm.showServiceType = true;
               vm.showServiceName = true;
               vm.showSector = false;

           } else {
               vm.showServiceType = false;
               vm.showServiceName = false;
               vm.showSector = true;
           }
        }

        $scope.serviceTypeCheck = function () {
            if (vm.decriminalization.serviceType==="Other"){

                vm.showOtherServiceType = true;

            } else {
                vm.showOtherServiceType = false;
            }


        }

        $scope.otherSectorCheck = function () {
            if (vm.decriminalization.sector==="Other"){

                vm.showOtherSector = true;

            } else {
                vm.showOtherSector = false;
            }


        }

        $scope.crimePunMonths = function() {

            // Trim whitespace and make the comparison case-insensitive
            if (vm.decriminalization.criminalPunishmentTenureInMonths === "Enter months") {
                vm.disableMinTenure = false;
                vm.disableMaxTenure = false;
            } else {
                vm.disableMinTenure = true;
                vm.disableMaxTenure = true;
            }
        }


        $scope.crimePunAmount = function () {
            if (vm.decriminalization.criminalPunishmentAmountInInr==="Enter Amount"){
                vm.disableMinAmount = false;
                vm.disableMaxAmount = false;

            } else {
                vm.disableMinAmount = true;
                vm.disableMaxAmount = true;
            }


        }


        function onSaveSuccess (result) {

            alert("Form Submitted Successfully");

            vm.isSaving = false;
            window.location.href = '/';
        }

        function onSaveError () {
            alert("Error While Submitting Form! Please try again");
            vm.isSaving = false;
        }


        function getDepartments() {
            Department.query().$promise.then(function(data) {
                vm.departments = data;

            });
        }

    }
})();
