(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ViewMemberController', ViewMemberController);

    ViewMemberController.$inject = ['$state', 'Principal', 'TicketMember', 'Department', 'TicketMemberDepartmentAssign'];

    function ViewMemberController($state, Principal, TicketMember, Department, TicketMemberDepartmentAssign) {

        var vm = this;

        vm.ticketMembers = [];
        vm.selectedMember=selectedMember;
        vm.addDeparmentHead=addDeparmentHead;
        vm.selectedTicketMember={};
        loadAll();

        function loadAll() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
            TicketMember.query(function(result) {
                vm.ticketMembers = result;
                vm.searchQuery = null;
            });
            Department.query(function (result) {
                vm.deparment=result;
            });
        }

        function selectedMember(ticketMember) {
            vm.selectedTicketMember = ticketMember;
            if(vm.selectedTicketMember.departmentList != null) {
                if (Object.keys(vm.selectedTicketMember.departmentList).length == 0) {
                    vm.selectedTicketMember.departmentList = null;
                }
            }
        }

        function addDeparmentHead() {
            vm.map = {};
            angular.forEach(vm.departmentDetail, function (item) {
                vm.map[item.id] = item.departmentname;
            }) ;
            vm.selectedTicketMember.departmentList = vm.map;
            TicketMemberDepartmentAssign.update(vm.selectedTicketMember, onUpdateSuccess, onUpdateError);
        }

        function onUpdateSuccess(result) {
            $("#AssigndepartmentModel").modal("hide");
            alert("Successfully Updated");
            location.reload();
        }

        function onUpdateError(result) {
            $("#AssigndepartmentModel").modal("hide");
            if(result.data.departmentName!=null && result.statusText=="Bad Request"){
                alert("HOD of "+result.data.departmentName+ " is already assigned!");
            }else{
                alert("Error while assigning department! Please try again after sometime.");
            }
            location.reload();
        }
    }
})();
