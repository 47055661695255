(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: [ function() {
                    return true;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })

        .state('about', {
                    parent: 'home',
                    url: 'AboutUs',
                    data: {
                        authorities: []
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/home/about.html',
                            controller: 'HomeController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: [ function() {
                            return false;
                        }],
                      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                          $translatePartialLoader.addPart('home');
                          return $translate.refresh();
                    }]
                   }
               })

               .state('aboutHEPC', {
                                   parent: 'home',
                                   url: 'AboutHEPC',
                                   data: {
                                       authorities: []
                                   },
                                   views: {
                                       'content@': {
                                           templateUrl: 'app/home/about.html',
                                           controller: 'HomeController',
                                           controllerAs: 'vm'
                                       }
                                   },
                                   resolve: {
                                       entity: [ function() {
                                           return false;
                                       }],
                                     translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                         $translatePartialLoader.addPart('home');
                                         return $translate.refresh();
                                   }]
                                  }
                              })


           .state('CM_Message', {
                                   parent: 'home',
                                   url: 'CM_Message',
                                   data: {
                                       authorities: []
                                   },
                                   views: {
                                       'content@': {
                                           templateUrl: 'app/home/cmMessage.html',
                                           controller: 'HomeController',
                                           controllerAs: 'vm'
                                       }
                                   },
                                   resolve: {
                                       entity: [ function() {
                                           return false;
                                       }],
                                     translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                         $translatePartialLoader.addPart('home');
                                         return $translate.refresh();
                                   }]
                                  }
                              })

         .state('mission', {
                                           parent: 'home',
                                           url: 'Mission',
                                           data: {
                                               authorities: []
                                           },
                                           views: {
                                               'content@': {
                                                   templateUrl: 'app/home/Mission.html',
                                                   controller: 'HomeController',
                                                   controllerAs: 'vm'
                                               }
                                           },
                                           resolve: {
                                               entity: [ function() {
                                                   return false;
                                               }],
                                             translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                                 $translatePartialLoader.addPart('home');
                                                 return $translate.refresh();
                                           }]
                                          }
                                      })

        .state('participating_department', {
                                       parent: 'home',
                                       url: 'Participating_Department',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/participating_department.html',
                                               controller: 'HomeController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })

                 .state('haryana', {
                                       parent: 'home',
                                       url: 'Haryana',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/haryana.html',
                                               controller: 'HomeController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })
                       .state('states', {
                                       parent: 'home',
                                       url: 'States',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/states.html',
                                               controller: 'HomeController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })

                .state('Enabling_infrastructure', {
                                       parent: 'home',
                                       url: 'Enabling_Infrastructure',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/Enabling_infrastructure.html',
                                               controller: 'HomeController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })

                   .state('urban_infrastructure', {
                                       parent: 'home',
                                       url: 'Urban_Infrastructure',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/urban_infrastructure.html',
                                               controller: 'HomeController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })

                    .state('industrial_powerhouse', {
                                       parent: 'home',
                                       url: 'Industrial_Powerhouse',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/industrial_powerhouse.html',
                                               controller: 'HomeController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })
            .state('electronicsAndInfo', {
                parent: 'home',
                url: 'electronicsAndInfo',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/electronicsAndInfo.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('footwearAndAccessory', {
                parent: 'home',
                url: 'footwearAndAccessory',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/footwearAndAccessory.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

            .state('pharmaceuticalAndChemical', {
                parent: 'home',
                url: 'pharmaceuticalAndChemical',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/pharmaceuticalAndChemical.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('agriculture', {
                parent: 'home',
                url: 'agriculture',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/agriculture.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('conducive_ecosystem', {
                parent: 'home',
                url: 'Conducive_Ecosystem',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/conducive_ecosystem.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('booming_economy', {
                parent: 'home',
                url: 'Booming_Economy',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/booming_economy.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('supportive_government', {
                parent: 'home',
                url: 'Supportive_Government',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/supportive_government.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('org_structure', {
                parent: 'home',
                url: 'org_structure',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/org_structure.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('gurugram', {
                parent: 'home',
                url: 'gurugram',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/gurugram.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('iMultiModalLogisticsHub', {
                parent: 'home',
                url: 'iMultiModalLogisticsHub',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/iMultiModalLogisticsHub.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('integratedAviationHub', {
                parent: 'home',
                url: 'integratedAviationHub',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/integratedAviationHub.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('industrialModalTownship', {
                parent: 'home',
                url: 'industrialModalTownship',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/industrialModalTownship.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('qualityOfLife', {
                parent: 'home',
                url: 'qualityOfLife',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/qualityOfLife.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('defence', {
                parent: 'home',
                url: 'defence',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/defence.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('textile', {
                parent: 'home',
                url: 'textile',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/textile.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            // .state('meetOurExperts', {
            //     parent: 'home',
            //     url: 'meetOurExperts',
            //     data: {
            //         authorities: []
            //     },
            //     views: {
            //         'content@': {
            //             templateUrl: 'app/home/meetOurExperts.html'
            //         }
            //     },
            //     resolve: {
            //         entity: [ function() {
            //             return false;
            //         }],
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
            //             $translatePartialLoader.addPart('home');
            //             return $translate.refresh();
            //         }]
            //     }
            // })
            .state('renewable', {
                parent: 'home',
                url: 'renewable',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/renewable.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('kmpGlobalCorridor', {
                parent: 'home',
                url: 'kmpGlobalCorridor',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/kmpGlobalCorridor.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: [ function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })

                 .state('investor_service', {
                                       parent: 'home',
                                       url: 'Investor_Service',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/investor_services.html',
                                               controller: 'HomeController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })


                    .state('information', {
                                       parent: 'home',
                                       url: 'Information',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/information.html',
                                               controller: 'HomeController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })

                        .state('news_gallery', {
                                       parent: 'home',
                                       url: 'News_Gallery',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/news_gallery.html',
                                               controller: 'NewsController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })

                    .state('contact_us', {
                                       parent: 'home',
                                       url: 'contact_us',
                                       data: {
                                           authorities: []
                                       },
                                       views: {
                                           'content@': {
                                               templateUrl: 'app/home/contact.html',
                                               controller: 'HomeController',
                                               controllerAs: 'vm'
                                           }
                                       },
                                       resolve: {
                                           entity: [ function() {
                                               return false;
                                           }],
                                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                             $translatePartialLoader.addPart('home');
                                             return $translate.refresh();
                                       }]
                                      }
                                  })
                      .state('caffillingguide', {
                                     parent: 'home',
                                     url: 'caffillingguide',
                                     data: {
                                         authorities: []
                                     },
                                     views: {
                                         'content@': {
                                             templateUrl: 'app/home/caffillingguide.html',
                                             controller: 'HomeController',
                                             controllerAs: 'vm'
                                         }
                                     },
                                     resolve: {
                                         entity: [ function() {
                                             return false;
                                         }],
                                       translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                           $translatePartialLoader.addPart('home');
                                           return $translate.refresh();
                                     }]
                                    }
                                })
                                .state('serviceapplicationguide', {
                                     parent: 'home',
                                     url: 'serviceapplicationguide',
                                     data: {
                                         authorities: []
                                     },
                                     views: {
                                         'content@': {
                                             templateUrl: 'app/home/serviceapplicationguide.html',
                                             controller: 'HomeController',
                                             controllerAs: 'vm'
                                         }
                                     },
                                     resolve: {
                                         entity: [ function() {
                                             return false;
                                         }],
                                       translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                           $translatePartialLoader.addPart('home');
                                           return $translate.refresh();
                                     }]
                                    }
                                })
                                 .state('investorfacilitationcell', {
                                     parent: 'home',
                                     url: 'investorfacilitationcell',
                                     data: {
                                         authorities: []
                                     },
                                     views: {
                                         'content@': {
                                             templateUrl: 'app/home/investorfacilitationcell.html',
                                             controller: 'HomeController',
                                             controllerAs: 'vm'
                                         }
                                     },
                                     resolve: {
                                         entity: [ function() {
                                             return false;
                                         }],
                                       translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                           $translatePartialLoader.addPart('home');
                                           return $translate.refresh();
                                     }]
                                    }
                                })

                                .state('focusarea', {
                                     parent: 'home',
                                     url: 'focusarea',
                                     data: {
                                         authorities: []
                                     },
                                     views: {
                                         'content@': {
                                             templateUrl: 'app/home/focusarea.html',
                                             controller: 'HomeController',
                                             controllerAs: 'vm'
                                         }
                                     },
                                     resolve: {
                                         entity: [ function() {
                                             return false;
                                         }],
                                       translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                           $translatePartialLoader.addPart('home');
                                           return $translate.refresh();
                                     }]
                                    }
                                })
                                .state('feedbacksbyofficer', {
                                     parent: 'app',
                                     url: '/feedbacksbyofficer',
                                     data: {
                                         authorities: ['ROLE_FEEDBACK_OFFICER','ROLE_NODAL_OFFICER']
                                     },
                                     views: {
                                         'content@': {
                                             templateUrl: 'app/feedbacksbyofficer/feedbacksbyofficer.html',
                                             controller: 'FeedbacksByOfficerController',
                                             controllerAs: 'vm'
                                         }
                                     },
                                     resolve: {
                                         entity: [ function() {
                                             return false;
                                         }],
                                       translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                           $translatePartialLoader.addPart('home');
                                           return $translate.refresh();
                                     }]
                                    }
                                })
                            .state('advertisement', {
                                parent: 'home',
                                url: 'advertisement',
                                data: {
                                    authorities: []
                                },
                                views: {
                                    'content@': {
                                        templateUrl: 'app/home/advertisment.html',
                                        controller: 'HomeController',
                                        controllerAs: 'vm'
                                    }
                                },
                                resolve: {
                                    entity: [ function() {
                                        return false;
                                    }],
                                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                        $translatePartialLoader.addPart('home');
                                        return $translate.refresh();
                                    }]
                                }
                            });

    }
})();
