(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Tower_dcController', Tower_dcController);

    Tower_dcController.$inject = ['Tower_dc'];

    function Tower_dcController(Tower_dc) {

        var vm = this;

        vm.tower_dcs = [];

        loadAll();

        function loadAll() {
            Tower_dc.query(function(result) {
                vm.tower_dcs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
