(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ConstructionPermitApprovalStepsController', ConstructionPermitApprovalStepsController);

    ConstructionPermitApprovalStepsController.$inject = ['ConstructionPermitApprovalSteps'];

    function ConstructionPermitApprovalStepsController(ConstructionPermitApprovalSteps) {

        var vm = this;

        vm.constructionPermitApprovalSteps = [];

        loadAll();

        function loadAll() {
            ConstructionPermitApprovalSteps.query(function(result) {
                vm.constructionPermitApprovalSteps = result;
                vm.searchQuery = null;
            });
        }
    }
})();
