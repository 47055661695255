(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('supplyDisposal', {
            parent: 'app',
            url: '/supplyDisposal',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/supplyDisposal/supplyDisposal.html',
                    controller: 'SupplyDisposalController',
                    controllerAs: 'vm'
                }
            },
            // resolve: {
            //     translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
            //         $translatePartialLoader.addPart('dashboard');
            //         return $translate.refresh();
            //     }]
            // }
        }).state('supplyAndDisposaldashboard', {
            parent: 'supplyDisposal',
            url: '/supplyDisposalView/{status}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/supplyDisposal/supplyDisposalView.html',
                    controller: 'SupplyDisposalViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('dashboard');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('supplyDisposal', null, { reload: 'supplyDisposal' });
                }, function() {
                    $state.go('supplyDisposal');
                });
            }]
        });

        }
        })();
