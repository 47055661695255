(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('ratingViewAdmin', {
            parent: 'hepcticket',
            url: '/feedbackView',
            data: {
                authorities: ["ROLE_ADMIN","ROLE_SUB_ADMIN","ROLE_TICKET_ADMIN"]
            },
            views: {
                'content_section@hepcticket': {
                    templateUrl: 'app/hepcTickets/ticketsFeedBackView/viewTicketFeedBack.html',
                    controller: 'FeedBackViewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('ticketFeedbackdetail', {
                parent: 'ratingViewAdmin',
                url: '/{id}',
                data: {
                    authorities: ["ROLE_L1_SUPPORT","ROLE_L2_IT_MANAGER","ROLE_L2_DEPARTMENT","ROLE_L2_DEPARTMENT_HEAD","ROLE_L2_INCENTIVES","ROLE_TICKET_NODAL_OFFICER","ROLE_TICKET_OPERATION_MANAGER","ROLE_NODAL_OFFICER","ROLE_ADMIN","ROLE_SUB_ADMIN","ROLE_TICKET_ADMIN"]
                },
                views: {
                    'content_section@hepcticket': {
                        templateUrl: 'app/hepcTickets/ticketsFeedBackView/viewTicketFeedBackDetail.html',
                        controller: 'TicketFeedbackDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Tickets', function($stateParams, Tickets) {
                        return Tickets.query({columnKey : 'ticketNumber', columnValue:$stateParams.id}).$promise;
                    }]
                }
            });
    }
})();
