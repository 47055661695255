(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('grievanceReAssign', grievanceReAssign);

    grievanceReAssign.$inject = ['$resource'];

    function grievanceReAssign ($resource) {
        var resourceUrl =  '/api/grievancesList/:assignby/:grievanceId/:assignto/:assigndate';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: false},
            'query': { method: 'GET', isArray: true}
        });
    }
})();
