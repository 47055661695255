(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DistrictFieldOfficerDialogController', DistrictFieldOfficerDialogController);

    DistrictFieldOfficerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DistrictFieldOfficer', 'District', 'Department'];

    function DistrictFieldOfficerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DistrictFieldOfficer, District, Department) {
        var vm = this;

        vm.districtFieldOfficer = entity;
        vm.clear = clear;
        vm.save = save;
        var selecteddistrict=null;
        var selecteddepartment=null;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.districtFieldOfficer.districtid= vm.districtFieldOfficer.selecteddistrict.id;
            vm.districtFieldOfficer.districtName= vm.districtFieldOfficer.selecteddistrict.districtname;
            vm.districtFieldOfficer.departmentid= vm.districtFieldOfficer.selecteddepartment.id;
            vm.districtFieldOfficer.departmentName= vm.districtFieldOfficer.selecteddepartment.departmentname;
            vm.isSaving = true;
            if (vm.districtFieldOfficer.id !== null) {
                DistrictFieldOfficer.update(vm.districtFieldOfficer, onSaveSuccess, onSaveError);
            } else {
                DistrictFieldOfficer.save(vm.districtFieldOfficer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:districtFieldOfficerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.districts = [];
        loadAllDistricts();
        function loadAllDistricts() {
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
            });
        }

        vm.departments = [];
        loadAllDepartments();
        function loadAllDepartments() {
            Department.query(function(result) {
                vm.departments = result;
                vm.searchQuery = null;
            });
        }
    }
})();
