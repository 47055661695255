(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MultipleactiveaccountwithoutcafDetailController', MultipleactiveaccountwithoutcafDetailController);

    MultipleactiveaccountwithoutcafDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Multipleactiveaccountwithoutcaf'];

    function MultipleactiveaccountwithoutcafDetailController($scope, $rootScope, $stateParams, previousState, entity, Multipleactiveaccountwithoutcaf) {
        var vm = this;

        vm.multipleactiveaccountwithoutcaf = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:multipleactiveaccountwithoutcafUpdate', function(event, result) {
            vm.multipleactiveaccountwithoutcaf = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
