(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('belowGround', {
            parent: 'app',
            url: '/towerInstallation/belowGround',
            data: {
                authorities: ['ROLE_TOWER_USER'],
                pageTitle: 'global.menu.belowGround'
            },
            views: {
                'content@': {
                    templateUrl: 'app/belowGround/belowGround.html',
                    controller: 'BelowGroundController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('belowGround');
                    return $translate.refresh();
                }]
            }
        })
            .state('belowGround-dialog', {
            parent: 'belowGround',
            url: '/new',
            data: {
                authorities: ['ROLE_TOWER_USER']
            },
            views: {
                    'content@': {
                        templateUrl: 'app/belowGround/belowGround-dialog.html',
                        controller: 'BelowGroundDialogController',
                        controllerAs: 'vm'
                    }
                },
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        reg_add: null,
                        har_office_add: null,
                        name_auth: null,
                        designation_auth: null,
                        phone_auth: null,
                        email: null,
                        lenth: null,
                        route: null,
                        nature: null,
                        methodology: null,
                        address1: null,
                        district: null,
                        zone: null,
                        details1: null,
                        details2: null,
                        other_info1: null,
                        other_info2: null,
                        other_info3: null,
                        other_info4: null,
                        other_info5: null,
                        other_info6: null,
                        other_info7: null,
                        address2: null,
                        document1: null,
                        document2: null,
                        document3: null,
                        document4: null,
                        document5: null,
                        document6: null,
                        document7: null,
                        document8: null,
                        isfullyfilled: false,
                        status: null,
                        isforwardedtodio: false,
                        isforwardedtodept: false,
                        isforwardedtodc: false,

                        clearancefile1: null,
                        clearancefile2: null,
                        clearancefile3: null,

                        created_by: null,
                        created_on: null,
                        isforwarded: null,

                        id: null
                    };
                }
                }
        })
            .state('belowGround-detail', {
                parent: 'belowGround',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_TOWER_USER']
                    //pageTitle: 'investhryApp.belowGround.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/belowGround/belowGround-detail.html',
                        controller: 'BelowGroundDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('below_ground');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Below_ground', function($stateParams, Below_ground) {
                        return Below_ground.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'below-ground',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });;
    }
})();
