(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ResolveboxController', ResolveboxController);

    ResolveboxController.$inject = ['$scope', '$state','$uibModalInstance', 'Principal', 'LoginService', 'Grievance', 'grievance'];

    function ResolveboxController ($scope, $state, $uibModalInstance, Principal, LoginService, Grievance, grievance) {
        var vm = this;

        vm.grievance = grievance;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        vm.clear = clear;
        vm.saveResolveData = saveResolveData;


        function saveResolveData()
        {
            vm.grievance.status="Resolved";
            vm.grievance.isResolved=true;
            Grievance.update(vm.grievance,onSaveSuccess);

        }
        function onSaveSuccess(result)
        {
            $uibModalInstance.close(result);
        }
        function clear ()
        {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
