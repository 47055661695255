(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('Locations', Locations);

    Locations.$inject = ['$resource'];

    function Locations ($resource) {
        var locations=[
            {
                "cities": [
                    "Baksa",
                    "Barpeta",
                    "Bongaigaon",
                    "Cachar",
                    "Chirang",
                    "Darrang",
                    "Dhemaji",
                    "Dhubri",
                    "Dibrugarh",
                    "Dima Hasao",
                    "Goalpara",
                    "Golaghat",
                    "Hailakandi",
                    "Jorhat",
                    "Kamrup",
                    "Kamrup Metropolitan",
                    "Karbi Anglong",
                    "Karimganj",
                    "Kokrajhar",
                    "Lakhimpur",
                    "Morigaon",
                    "Nagaon",
                    "Nalbari",
                    "Sibsagar",
                    "Sonitpur",
                    "Tinsukia",
                    "Udalguri"
                ],
                "state": "Assam"
            },
            {
                "cities": [
                    "North Goa",
                    "South Goa"
                ],
                "state": "Goa"
            },
            {
                "cities": [
                    "Alirajpur",
                    "Anuppur",
                    "Ashoknagar",
                    "Balaghat",
                    "Barwani",
                    "Betul",
                    "Bhind",
                    "Bhopal",
                    "Burhanpur",
                    "Chhatarpur",
                    "Chhindwara",
                    "Damoh",
                    "Datia",
                    "Dewas",
                    "Dhar",
                    "Dindori",
                    "East Nimar",
                    "Guna",
                    "Gwalior",
                    "Harda",
                    "Hoshangabad",
                    "Indore",
                    "Jabalpur",
                    "Jhabua",
                    "Katni",
                    "Khargone",
                    "Mandla",
                    "Mandsaur",
                    "Morena",
                    "Narsimhapur",
                    "Neemuch",
                    "Panna",
                    "Raisen",
                    "Rajgarh",
                    "Ratlam",
                    "Rewa",
                    "Sagar",
                    "Satna",
                    "Sehore",
                    "Seoni",
                    "Shahdol",
                    "Shajapur",
                    "Sheopur",
                    "Shivpuri",
                    "Sidhi",
                    "Singrauli",
                    "Tikamgarh",
                    "Ujjain",
                    "Umaria",
                    "Vidisha"
                ],
                "state": "Madhya Pradesh"
            },
            {
                "cities": [
                    "Bishnupur",
                    "Chandel",
                    "Churachandpur",
                    "Imphal East",
                    "Imphal West",
                    "Senapati",
                    "Tamenglong",
                    "Thoubal",
                    "Ukhrul"
                ],
                "state": "Manipur"
            },
            {
                "cities": [
                    "East Garo Hills",
                    "East Khasi Hills",
                    "Jaintia Hills",
                    "Ri-Bhoi",
                    "South Garo Hills",
                    "West Garo Hills",
                    "West Khasi Hills"
                ],
                "state": "Meghalaya"
            },
            {
                "cities": [
                    "Aizawl",
                    "Champhai",
                    "Kolasib",
                    "Lawngtlai",
                    "Lunglei",
                    "Mamit",
                    "Saiha",
                    "Serchhip"
                ],
                "state": "Mizoram"
            },
            {
                "cities": [
                    "Central Delhi",
                    "East Delhi",
                    "New Delhi",
                    "North Delhi",
                    "North East Delhi",
                    "North West Delhi",
                    "South Delhi",
                    "South West Delhi",
                    "West Delhi"
                ],
                "state": "Delhi"
            },
            {
                "cities": [
                    "East",
                    "North",
                    "South",
                    "West"
                ],
                "state": "Sikkim"
            },
            {
                "cities": [
                    "Anantapur",
                    "Chittoor",
                    "Cuddapah",
                    "East Godavari",
                    "Guntur",
                    "Krishna",
                    "Kurnool",
                    "Nellore",
                    "Prakasam",
                    "Srikakulam",
                    "Vishakhapatnam",
                    "Vizianagaram",
                    "West Godavari"
                ],
                "state": "Andhra Pradesh"
            },
            {
                "cities": [
                    "Anjaw",
                    "Changlang",
                    "Dibang Valley",
                    "East Kameng",
                    "East Siang",
                    "Kurung Kumey",
                    "Lohit",
                    "Lower Dibang Valley",
                    "Lower Subansiri",
                    "Papum Pare",
                    "Tawang",
                    "Tirap",
                    "Upper Siang",
                    "Upper Subansiri",
                    "West Kameng",
                    "West Siang"
                ],
                "state": "Arunachal Pradesh"
            },
            {
                "cities": [
                    "Araria",
                    "Arwal",
                    "Aurangabad",
                    "Banka",
                    "Begusarai",
                    "Bhagalpur",
                    "Bhojpur",
                    "Buxar",
                    "Darbhanga",
                    "Gaya",
                    "Gopalganj",
                    "Jamui",
                    "Jehanabad",
                    "Kaimur",
                    "Katihar",
                    "Khagaria",
                    "Kishanganj",
                    "Lakhisarai",
                    "Madhepura",
                    "Madhubani",
                    "Munger",
                    "Muzaffarpur",
                    "Nalanda",
                    "Nawada",
                    "Pashchim Champaran",
                    "Patna",
                    "Purba Champaran",
                    "Purnia",
                    "Rohtas",
                    "Saharsa",
                    "Samastipur",
                    "Saran",
                    "Sheikhpura",
                    "Sheohar",
                    "Sitamarhi",
                    "Siwan",
                    "Supaul",
                    "Vaishali"
                ],
                "state": "Bihar"
            },
            {
                "cities": [
                    "Bastar",
                    "Bijapur",
                    "Bilaspur",
                    "Dakshin Bastar Dantewada",
                    "Dhamtari",
                    "Durg",
                    "Janjgir-Champa",
                    "Jashpur",
                    "Kabeerdham",
                    "Korba",
                    "Koriya",
                    "Mahasamund",
                    "Narayanpur",
                    "Raigarh",
                    "Raipur",
                    "Raj Nandgaon",
                    "Surguja",
                    "Uttar Bastar Kanker"
                ],
                "state": "Chhattisgarh"
            },
            {
                "cities": [
                    "Ahmadabad",
                    "Amreli",
                    "Anand",
                    "Banas Kantha",
                    "Bharuch",
                    "Bhavnagar",
                    "Dohad",
                    "Gandhinagar",
                    "Jamnagar",
                    "Junagadh",
                    "Kachchh",
                    "Kheda",
                    "Mahesana",
                    "Narmada",
                    "Navsari",
                    "Panch Mahals",
                    "Patan",
                    "Porbandar",
                    "Rajkot",
                    "Sabar Kantha",
                    "Surat",
                    "Surendranagar",
                    "Tapi",
                    "The Dangs",
                    "Vadodara",
                    "Valsad"
                ],
                "state": "Gujarat"
            },
            {
                "cities": [
                    "Ambala",
                    "Bhiwani",
                    "Faridabad",
                    "Fatehabad",
                    "Gurgaon",
                    "Hisar",
                    "Jhajjar",
                    "Jind",
                    "Kaithal",
                    "Karnal",
                    "Kurukshetra",
                    "Mahendragarh",
                    "Mewat",
                    "Palwal",
                    "Panchkula",
                    "Panipat",
                    "Rewari",
                    "Rohtak",
                    "Sirsa",
                    "Sonipat",
                    "Yamunanagar"
                ],
                "state": "Haryana"
            },
            {
                "cities": [
                    "Bilaspur",
                    "Chamba",
                    "Hamirpur",
                    "Kangra",
                    "Kinnaur",
                    "Kulu",
                    "Lahul and Spiti",
                    "Mandi",
                    "Shimla",
                    "Sirmaur",
                    "Solan",
                    "Una"
                ],
                "state": "Himachal Pradesh"
            },
            {
                "cities": [
                    "Anantnag",
                    "Badgam",
                    "Bandipore",
                    "Baramula",
                    "Doda",
                    "Ganderbal",
                    "Jammu",
                    "Kargil",
                    "Kathua",
                    "Kishtwar",
                    "Kulgam",
                    "Kupwara",
                    "Ladakh",
                    "Pulwama",
                    "Punch",
                    "Rajauri",
                    "Ramban",
                    "Reasi",
                    "Samba",
                    "Shupiyan",
                    "Srinagar",
                    "Udhampur"
                ],
                "state": "Jammu and Kashmir"
            },
            {
                "cities": [
                    "Bokaro",
                    "Chatra",
                    "Deogarh",
                    "Dhanbad",
                    "Dumka",
                    "Garhwa",
                    "Giridih",
                    "Godda",
                    "Gumla",
                    "Hazaribag",
                    "Jamtara",
                    "Khunti",
                    "Kodarma",
                    "Latehar",
                    "Lohardaga",
                    "Pakur",
                    "Palamu",
                    "Pashchim Singhbhum",
                    "Purba Singhbhum",
                    "Ramgarh",
                    "Ranchi",
                    "Sahibganj",
                    "Saraikela",
                    "Simdega"
                ],
                "state": "Jharkhand"
            },
            {
                "cities": [
                    "Bagalkot",
                    "Bangalore Rural",
                    "Bangalore Urban",
                    "Belgaum",
                    "Bellary",
                    "Bidar",
                    "Bijapur",
                    "Chamrajnagar",
                    "Chikkaballapur",
                    "Chikmagalur",
                    "Chitradurga",
                    "Dakshina Kannada",
                    "Davanagere",
                    "Dharwad",
                    "Gadag",
                    "Gulbarga",
                    "Hassan",
                    "Haveri",
                    "Kodagu",
                    "Kolar",
                    "Koppal",
                    "Mandya",
                    "Mysore",
                    "Raichur",
                    "Ramanagara",
                    "Shimoga",
                    "Tumkur",
                    "Udupi",
                    "Uttar Kannada",
                    "Yadgir"
                ],
                "state": "Karnataka"
            },
            {
                "cities": [
                    "Alappuzha",
                    "Ernakulam",
                    "Idukki",
                    "Kannur",
                    "Kasaragod",
                    "Kollam",
                    "Kottayam",
                    "Kozhikode",
                    "Malappuram",
                    "Palakkad",
                    "Pattanamtitta",
                    "Thiruvananthapuram",
                    "Thrissur",
                    "Wayanad"
                ],
                "state": "Kerala"
            },
            {
                "cities": [
                    "Ahmadnagar",
                    "Akola",
                    "Amravati Division",
                    "Aurangabad Division",
                    "Bhandara",
                    "Bid",
                    "Buldana",
                    "Chandrapur",
                    "Dhule",
                    "Gadchiroli",
                    "Gondiya",
                    "Hingoli",
                    "Jalgaon",
                    "Jalna",
                    "Kolhapur",
                    "Latur",
                    "Mumbai",
                    "Mumbai Suburban",
                    "Nagpur Division",
                    "Nanded",
                    "Nandurbar",
                    "Nashik Division",
                    "Osmanabad",
                    "Parbhani",
                    "Pune Division",
                    "Raigarh",
                    "Ratnagiri",
                    "Sangli",
                    "Satara Division",
                    "Sindhudurg",
                    "Solapur",
                    "Thane",
                    "Wardha",
                    "Washim",
                    "Yavatmal"
                ],
                "state": "Maharashtra"
            },
            {
                "cities": [
                    "Dimapur",
                    "Kiphire",
                    "Kohima",
                    "Longleng",
                    "Mokokchung",
                    "Mon",
                    "Peren",
                    "Phek",
                    "Tuensang",
                    "Wokha",
                    "Zunheboto"
                ],
                "state": "Nagaland"
            },
            {
                "cities": [
                    "Angul",
                    "Balangir",
                    "Baleshwar",
                    "Baragarh",
                    "Baudh",
                    "Bhadrak",
                    "Cuttack",
                    "Debagarh",
                    "Dhenkanal",
                    "Gajapati",
                    "Ganjam",
                    "Jagatsinghpur",
                    "Jajpur",
                    "Jharsuguda",
                    "Kalahandi",
                    "Kandhamal",
                    "Kendrapara",
                    "Kendujhar",
                    "Khordha",
                    "Koraput",
                    "Malkangiri",
                    "Mayurbhanj",
                    "Nabarangpur",
                    "Nayagarh",
                    "Nuapada",
                    "Puri",
                    "Rayagada",
                    "Sambalpur",
                    "Subarnapur",
                    "Sundargarh"
                ],
                "state": "Odisha"
            },
            {
                "cities": [
                    "Ajitgarh",
                    "Amritsar",
                    "Barnala",
                    "Bathinda",
                    "Faridkot",
                    "Fatehgarh Sahib",
                    "Fazilka",
                    "Ferozepur",
                    "Gurdaspur",
                    "Hoshiarpur",
                    "Jalandhar",
                    "Kapurthala",
                    "Ludhiana",
                    "Mansa",
                    "Moga",
                    "Muktsar",
                    "Pathankot",
                    "Patiala",
                    "Rupnagar",
                    "Sangrur",
                    "Shahid Bhagat Singh Nagar",
                    "Tarn Taran"
                ],
                "state": "Punjab"
            },
            {
                "cities": [
                    "Ajmer",
                    "Alwar",
                    "Banswara",
                    "Baran",
                    "Barmer",
                    "Bharatpur",
                    "Bhilwara",
                    "Bikaner",
                    "Bundi",
                    "Chittaurgarh",
                    "Churu",
                    "Dausa",
                    "Dhaulpur",
                    "Dungarpur",
                    "Ganganagar",
                    "Hanumangarh",
                    "Jaipur",
                    "Jaisalmer",
                    "Jalore",
                    "Jhalawar",
                    "Jhunjhunun",
                    "Jodhpur",
                    "Karauli",
                    "Kota",
                    "Nagaur",
                    "Pali",
                    "Pratapgarh",
                    "Rajsamand",
                    "Sawai Madhopur",
                    "Sikar",
                    "Sirohi",
                    "Tonk",
                    "Udaipur"
                ],
                "state": "Rajasthan"
            },
            {
                "cities": [
                    "Ariyalur",
                    "Chennai",
                    "Coimbatore",
                    "Cuddalore",
                    "Dharmapuri",
                    "Dindigul",
                    "Erode",
                    "Kancheepuram",
                    "Kanniyakumari",
                    "Karur",
                    "Krishnagiri",
                    "Madurai",
                    "Nagapattinam",
                    "Namakkal",
                    "Nilgiris",
                    "Perambalur",
                    "Pudukkottai",
                    "Ramanathapuram",
                    "Salem",
                    "Sivaganga",
                    "Thanjavur",
                    "Theni",
                    "Thiruvallur",
                    "Thiruvarur",
                    "Thoothukkudi",
                    "Tiruchchirappalli",
                    "Tirunelveli Kattabo",
                    "Tiruppur",
                    "Tiruvannamalai",
                    "Vellore",
                    "Villupuram",
                    "Virudhunagar"
                ],
                "state": "Tamil Nadu"
            },
            {
                "cities": [
                    "Almora",
                    "Bageshwar",
                    "Chamoli",
                    "Champawat",
                    "Dehradun",
                    "Garhwal",
                    "Haridwar",
                    "Naini Tal",
                    "Pithoragarh",
                    "Rudraprayag",
                    "Tehri-Garhwal",
                    "Udham Singh Nagar",
                    "Uttarkashi"
                ],
                "state": "Uttarakhand"
            },
            {
                "cities": [
                    "Adilabad",
                    "Hyderabad",
                    "Karimnagar",
                    "Khammam",
                    "Mahbubnagar",
                    "Medak",
                    "Nalgonda",
                    "Nizamabad",
                    "Rangareddi",
                    "Warangal"
                ],
                "state": "Telangana"
            },
            {
                "cities": [
                    "Dhalai",
                    "North Tripura",
                    "South Tripura",
                    "West Tripura"
                ],
                "state": "Tripura"
            },
            {
                "cities": [
                    "Nicobar",
                    "North  & Middle Andaman",
                    "South Andaman"
                ],
                "state": "Union Territory of Andaman and Nicobar Islands"
            },
            {
                "cities": [
                    "Chandigarh"
                ],
                "state": "Union Territory of Chandigarh"
            },
            {
                "cities": [
                    "Dadra & Nagar Haveli"
                ],
                "state": "Union Territory of Dadra and Nagar Haveli"
            },
            {
                "cities": [
                    "Daman",
                    "Diu"
                ],
                "state": "Union Territory of Daman and Diu"
            },
            {
                "cities": [
                    "Lakshadweep"
                ],
                "state": "Union Territory of Lakshadweep"
            },
            {
                "cities": [
                    "Karaikal",
                    "Mahe",
                    "Puducherry",
                    "Yanam"
                ],
                "state": "Union Territory of Puducherry"
            },
            {
                "cities": [
                    "Agra",
                    "Aligarh",
                    "Allahabad",
                    "Ambedkar Nagar",
                    "Auraiya",
                    "Azamgarh",
                    "Baghpat",
                    "Bahraich",
                    "Ballia",
                    "Balrampur",
                    "Banda",
                    "Bara Banki",
                    "Bareilly",
                    "Basti",
                    "Bijnor",
                    "Budaun",
                    "Bulandshahr",
                    "Chandauli",
                    "Chitrakoot",
                    "Deoria",
                    "Etah",
                    "Etawah",
                    "Faizabad",
                    "Farrukhabad",
                    "Fatehpur",
                    "Firozabad",
                    "Gautam Buddha Nagar",
                    "Ghaziabad",
                    "Ghazipur",
                    "Gonda",
                    "Gorakhpur",
                    "Hamirpur",
                    "Hardoi",
                    "Hathras",
                    "Jalaun",
                    "Jaunpur",
                    "Jhansi",
                    "Jyotiba Phule Nagar",
                    "Kannauj",
                    "Kanpur",
                    "Kanpur Dehat",
                    "Kasganj",
                    "Kaushambi",
                    "Kheri",
                    "Kushinagar",
                    "Lalitpur",
                    "Lucknow",
                    "Maharajganj",
                    "Mahoba",
                    "Mainpuri",
                    "Mathura",
                    "Mau",
                    "Meerut",
                    "Mirzapur",
                    "Moradabad",
                    "Muzaffarnagar",
                    "Pilibhit",
                    "Pratapgarh",
                    "Rae Bareli",
                    "Rampur",
                    "Saharanpur",
                    "Sant Kabir Nagar",
                    "Sant Ravi Das Nagar",
                    "Shahjahanpur",
                    "Shrawasti",
                    "Siddharthangar",
                    "Sitapur",
                    "Sonbhadra",
                    "Sultanpur",
                    "Unnao",
                    "Varanasi"
                ],
                "state": "Uttar Pradesh"
            },
            {
                "cities": [
                    "Bankura",
                    "Barddhaman",
                    "Birbhum",
                    "Dakshin Dinajpur",
                    "Darjiling",
                    "Haora",
                    "Hugli",
                    "Jalpaiguri",
                    "Koch Bihar",
                    "Kolkata",
                    "Maldah",
                    "Murshidabad",
                    "Nadia",
                    "North 24 Parganas",
                    "Paschim Medinipur",
                    "Purba Medinipur",
                    "Puruliya",
                    "South 24 Parganas",
                    "Uttar Dinajpur"
                ],
                "state": "West Bengal"
            }
        ];

        return {
            getAllStates:function () {
                return locations;
            }
        }
    }
})();
