(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DisputeAttachmentDetailController', DisputeAttachmentDetailController);

    DisputeAttachmentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DisputeAttachment'];

    function DisputeAttachmentDetailController($scope, $rootScope, $stateParams, previousState, entity, DisputeAttachment) {
        var vm = this;

        vm.disputeAttachment = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:disputeAttachmentUpdate', function(event, result) {
            vm.disputeAttachment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
