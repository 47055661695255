(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerofficerlistController', TowerofficerlistController);

    TowerofficerlistController.$inject = ['Towerofficerlist'];

    function TowerofficerlistController(Towerofficerlist) {

        var vm = this;

        vm.towerofficerlists = [];

        loadAll();

        function loadAll() {
            Towerofficerlist.query(function(result) {
                vm.towerofficerlists = result;
                vm.searchQuery = null;
            });
        }
    }
})();
