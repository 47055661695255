(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HudaFormController', HudaFormController);

    HudaFormController.$inject = ['HudaForm'];

    function HudaFormController(HudaForm) {

        var vm = this;

        vm.hudaForms = [];

        loadAll();

        function loadAll() {
            HudaForm.query(function(result) {
                vm.hudaForms = result;
                vm.searchQuery = null;
            });
        }
    }
})();
