(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RasCredentialsDeleteController',RasCredentialsDeleteController);

    RasCredentialsDeleteController.$inject = ['$uibModalInstance', 'entity', 'RasCredentials'];

    function RasCredentialsDeleteController($uibModalInstance, entity, RasCredentials) {
        var vm = this;

        vm.rasCredentials = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RasCredentials.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
