(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DistrictDeskOfficerDetailController', DistrictDeskOfficerDetailController);

    DistrictDeskOfficerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DistrictDeskOfficer'];

    function DistrictDeskOfficerDetailController($scope, $rootScope, $stateParams, previousState, entity, DistrictDeskOfficer) {
        var vm = this;

        vm.districtDeskOfficer = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:districtDeskOfficerUpdate', function(event, result) {
            vm.districtDeskOfficer = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
