(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('PolicyDetailsDialogController', PolicyDetailsDialogController);

    PolicyDetailsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PolicyDetails', 'FileManagement'];

    function PolicyDetailsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PolicyDetails, FileManagement) {
        var vm = this;

        vm.policyDetails = entity;
        vm.clear = clear;
        vm.save = save;
        $scope.file = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.policyDetails.id !== null) {
                PolicyDetails.update(vm.policyDetails, onSaveSuccess, onSaveError);
            } else {
                PolicyDetails.save(vm.policyDetails, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:policyDetailsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            UploadAttachment(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function UploadAttachment(result)
        {
            var serverFileName = result.id;
            var file= vm.fileAttachemnt;

            var fileNameArray = file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension = fileNameArray[(fileNameArray.length-1)];
            }

            if(fileextension!=="" && fileextension === 'pdf')
            {
                FileManagement.saveFile(file,serverFileName,onFileSaveSuccess,OnFileSaveEror);
                serverFileName = serverFileName+"."+fileextension;
                vm.policyDetails1 = result;
                vm.policyDetails1.file = serverFileName;
            }
            else {
                alert('only pdf are allowed');
                PolicyDetails.delete({id: result.id});
            }
        }

        function onFileSaveSuccess(result) {
            PolicyDetails.update(vm.policyDetails1, onSaveSuccess1);
        }

        function OnFileSaveEror() {
            PolicyDetails.delete({id: vm.policyDetails1.id});
        }

        function onSaveSuccess1() {
            location.reload();
        }
    }
})();
