(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcRecruitmentQualificationsController', HepcRecruitmentQualificationsController);

    HepcRecruitmentQualificationsController.$inject = ['HepcRecruitmentQualifications'];

    function HepcRecruitmentQualificationsController(HepcRecruitmentQualifications) {

        var vm = this;

        vm.hepcRecruitmentQualifications = [];

        loadAll();

        function loadAll() {
            HepcRecruitmentQualifications.query(function(result) {
                vm.hepcRecruitmentQualifications = result;
                vm.searchQuery = null;
            });
        }
    }
})();
