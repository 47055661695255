(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AppliedServicesDetailController', AppliedServicesDetailController);

    AppliedServicesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AppliedServices'];

    function AppliedServicesDetailController($scope, $rootScope, $stateParams, previousState, entity, AppliedServices) {
        var vm = this;

        vm.appliedServices = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:appliedServicesUpdate', function(event, result) {
            vm.appliedServices = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
