(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Above_groundController', Above_groundController);

    Above_groundController.$inject = ['Above_ground'];

    function Above_groundController(Above_ground) {

        var vm = this;

        vm.above_grounds = [];

        loadAll();

        function loadAll() {
            Above_ground.query(function(result) {
                vm.above_grounds = result;
                vm.searchQuery = null;
            });
        }
    }
})();
