(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('DeptServiceStatsElastic', DeptServiceStatsElastic);

    DeptServiceStatsElastic.$inject = ['$resource'];

        function DeptServiceStatsElastic ($resource) {
        var resourceUrl = '/api/service-dashboard-stats-elastic';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
