(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievanceassignController', GrievanceassignController);

    GrievanceassignController.$inject = ['$scope', '$state','$uibModalInstance', 'Principal', 'LoginService', 'Grievance', 'grievance', 'UserListToAssignGrievance'];

    function GrievanceassignController ($scope, $state, $uibModalInstance, Principal, LoginService, Grievance, grievance, UserListToAssignGrievance) {
        var vm = this;

        vm.grievance = grievance;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        vm.clear = clear;
        vm.updateAssignGrievance = updateAssignGrievance;

        UserListToAssignGrievance.query({role:"ROLE_GRIEVANCE_OFFICER"}, function(result) {
            vm.users = result;
            vm.searchQuery = null;
        });

        function updateAssignGrievance()
        {
            vm.grievance.assignTo=vm.grievance.selectedassignTo.login;
            vm.grievance.assignStatus=true;
            Grievance.update(vm.grievance,onUpdateGrievanceSuccess,onUpdateGrievanceError);
        }
        function onUpdateGrievanceSuccess(result)
        {
              $uibModalInstance.close(result);
        }
        function onUpdateGrievanceError()
        {
        alert("Grievance not updated");
        }
        function clear ()
        {
            $uibModalInstance.dismiss('cancel');
        }


        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
