(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketsDialogController', TicketsDialogController);

    TicketsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Tickets', 'AdminTickets'];

    function TicketsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Tickets, AdminTickets) {
        var vm = this;
        vm.saveWithLogs = false;
        vm.tickets = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.tickets.id !== null) {
                if(vm.saveWithLogs === true) {
                    vm.tickets.adminSaveWithLogs = "Add-Logs";
                } else {
                    vm.tickets.adminSaveWithLogs = "No-Logs";
                }

                AdminTickets.update(vm.tickets, onSaveSuccess, onSaveError);
            } else {
                Tickets.save(vm.tickets, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:ticketsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.resolveDate = false;
        vm.datePickerOpenStatus.assignDate = false;
        vm.datePickerOpenStatus.expectedResoluation = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
