(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerServiceViewCafController', TowerServiceViewCafController);

    TowerServiceViewCafController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'ServiceViewCaf', 'TowerserviceNO'];
    function TowerServiceViewCafController ($timeout, $scope, $stateParams, $uibModalInstance, ServiceViewCaf, TowerserviceNO) {
        var vm = this;
        vm.clear = clear;
        vm.departmentname=$stateParams.departmentname;
        vm.servicename=$stateParams.servicename;
        vm.status=$stateParams.status;
        vm.showDepartments=showDepartments;
        vm.departmentName=[];

        $scope.file = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        loadAllCafs();
        function loadAllCafs() {

          vm.dashboardcafs=ServiceViewCaf.query({status:$stateParams.status,department:$stateParams.departmentname,servicename:$stateParams.servicename},function(result) {
              vm.show = true;
          })
        }

        function showDepartments(data) {
            TowerserviceNO.query({id:'1',projectdetailid:data.projectdetailid},function (result) {
                if(result.length===0){
                    vm.departmentName=[];
                    vm.departmentName[0] = "NA";
                }
                else {
                    vm.departmentName = result;
                }
            });

        }

    }
})();
