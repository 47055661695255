(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TotalservicedashboarddetailsDetailController', TotalservicedashboarddetailsDetailController);

    TotalservicedashboarddetailsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Totalservicedashboarddetails'];

    function TotalservicedashboarddetailsDetailController($scope, $rootScope, $stateParams, previousState, entity, Totalservicedashboarddetails) {
        var vm = this;

        vm.totalservicedashboarddetails = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:totalservicedashboarddetailsUpdate', function(event, result) {
            vm.totalservicedashboarddetails = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
