(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('searchResultsController', searchResultsController);

    searchResultsController.$inject = ['$scope', '$stateParams', 'FileService'];

    function searchResultsController($scope, $stateParams, FileService) {
        var vm = this;

        var filename = $stateParams.responseData;

        if (filename) {
            FileService.getFileUrl(filename).then(function(fileUrl) {
                vm.fileUrl = fileUrl;
            }).catch(function(error) {
                console.error('Error fetching file:', error);
            });
        }
    }
})();
