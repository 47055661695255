(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('ticketview', {
                parent: 'hepcticket',
                url: '/ticketview',
                data: {
                    authorities: ["ROLE_L1_SUPPORT","ROLE_L2_IT_MANAGER","ROLE_L2_DEPARTMENT","ROLE_L2_DEPARTMENT_HEAD","ROLE_L2_INCENTIVES","ROLE_TICKET_NODAL_OFFICER","ROLE_TICKET_OPERATION_MANAGER","ROLE_NODAL_OFFICER","ROLE_ADMIN","ROLE_SUB_ADMIN","ROLE_TICKET_HOBPAS"]
                },
                views: {
                    'content_section@hepcticket': {
                        templateUrl: 'app/hepcTickets/viewTicket/viewMyOpenTicket.html',
                        controller: 'TicketViewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('allticketview', {
                parent: 'hepcticket',
                url: '/allTickets',
                data: {
                    authorities: ["ROLE_L1_SUPPORT","ROLE_TICKET_OPERATION_MANAGER","ROLE_ADMIN","ROLE_SUB_ADMIN", "ROLE_TICKET_HOBPAS"]
                },
                views: {
                    'content_section@hepcticket': {
                        templateUrl: 'app/hepcTickets/viewTicket/viewTicketCreatedBy.html',
                        controller: 'TicketViewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('timeoutTickets', {
                parent: 'hepcticket',
                url: '/escalatedTickets',
                data: {
                    authorities: ["ROLE_L2_DEPARTMENT_HEAD"]
                },
                views: {
                    'content_section@hepcticket': {
                        templateUrl: 'app/hepcTickets/viewTicket/escalatedTickets.html',
                        controller: 'EscalatedTicketController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('depTicketsView', {
                parent: 'hepcticket',
                url: '/deptarmentTickets',
                data: {
                    authorities: ["ROLE_L2_DEPARTMENT_HEAD"]
                },
                views: {
                    'content_section@hepcticket': {
                        templateUrl: 'app/hepcTickets/viewTicket/departmentTickets.html',
                        controller: 'DepartmentTicketsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('closedticketview', {
                parent: 'hepcticket',
                url: '/closedticketview',
                data: {
                    authorities: ["ROLE_L1_SUPPORT","ROLE_L2_IT_MANAGER","ROLE_L2_DEPARTMENT","ROLE_L2_DEPARTMENT_HEAD","ROLE_L2_INCENTIVES","ROLE_TICKET_NODAL_OFFICER","ROLE_TICKET_OPERATION_MANAGER","ROLE_NODAL_OFFICER","ROLE_ADMIN","ROLE_SUB_ADMIN","ROLE_TICKET_HOBPAS"]
                },
                views: {
                    'content_section@hepcticket': {
                        templateUrl: 'app/hepcTickets/viewTicket/viewClosedTicket.html',
                        controller: 'ClosedTicketViewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('hepcticketadmin', {
                parent: 'hepcticket',
                url: '/hepcticketadmin',
                data: {
                    authorities: ["ROLE_ADMIN","ROLE_SUB_ADMIN","ROLE_TICKET_OPERATION_MANAGER","ROLE_TICKET_ADMIN"]
                },
                views: {
                    'content_section@hepcticket': {
                        templateUrl: 'app/hepcTickets/viewTicket/adminTicketView.html',
                        controller: 'AdminTicketViewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('ticketdetail', {
                parent: 'hepcticket',
                url: 'view-ticket-details/{id}',
                data: {
                    authorities: ["ROLE_L1_SUPPORT","ROLE_L2_IT_MANAGER","ROLE_L2_DEPARTMENT","ROLE_L2_DEPARTMENT_HEAD","ROLE_L2_INCENTIVES","ROLE_TICKET_NODAL_OFFICER","ROLE_TICKET_OPERATION_MANAGER","ROLE_NODAL_OFFICER","ROLE_ADMIN","ROLE_SUB_ADMIN","ROLE_TICKET_ADMIN","ROLE_TICKET_HOBPAS"]
                },
                views: {
                    'content_section@hepcticket': {
                        templateUrl: 'app/hepcTickets/viewTicket/viewTicketDetail.html',
                        controller: 'TicketDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Tickets', function($stateParams, Tickets) {
                        return Tickets.get({id : $stateParams.id}).$promise;
                    }]
                }
            });
    }

})();
