(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('projectdetailjist', {
            parent: 'app',
            url: '/projectdetailjist/{projectid}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER','ROLE_HEPC_MONITORING_OFFICER','ROLE_DEPT_CHANGE','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT',"ROLE_L2_DEPARTMENT_HEAD",'ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN','ROLE_CAF_STATUS_OFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/projectdetailjist/projectdetailjist.html',
                    controller: 'projectdetailjistController',
                    controllerAs: 'vm'
                },
                'sideView@projectdetailjist': {
                    templateUrl: 'app/projectdetailjist/cafSummary.html',
                    controller: 'CafSummaryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                projectLog: function () {
                    return {
                        projectid: null,
                        actiontaken: null,
                        actiontakenby: null,
                        actiontakendate: null,
                        actiontype: null,
                        id: null
                    };
                },
                projectLogNewCaf: function () {
                    return {
                        projectId: null,
                        actionTaken: null,
                        actionTakenBy: null,
                        actionTakenDate: null,
                        actionType: null,
                        fieldUpdated: null,
                        oldValue: null,
                        newValue: null,
                        id: null
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('projectdetailjist');
                    return $translate.refresh();
                }],
                projectInfo: ['$stateParams', 'Project', function ($stateParams, Project) {
                    return Project.get({id : $stateParams.projectid}).$promise;
                }]
            }
        })
        .state('projectdetailjist-fillform', {
            parent: 'projectdetailjist',
            url: '/fillform/:serviceID?projectid',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/projectdetailjist/fillform.html',
                    controller: 'fillformController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {
                    $state.go('projectdetailjist', null, { reload: 'projectdetailjist' });
                }, function() {
                    $state.go('projectdetailjist');
                });
            }]
        })
        .state('projectdetailjist-payfee', {
            parent: 'projectdetailjist',
            url: '/payfee/:serviceID?projectid',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/projectdetailjist/payfee.html',
                    controller: 'payfeeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {
                    $state.go('projectdetailjist', null, { reload: 'projectdetailjist' });
                }, function() {
                    $state.go('projectdetailjist');
                });
            }]
        });
        $stateProvider.state('viewServiceLog', {
            parent: 'projectdetailjist',
            url: '/service-log/{cafType}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_DEPT_CHANGE','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT',"ROLE_L2_DEPARTMENT_HEAD",'ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_CAF_STATUS_OFFICER']
            },
            views: {
                'sideView@projectdetailjist': {
                    templateUrl: 'app/projectdetailjist/serviceLog.html',
                    controller: 'ServiceLogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]

            }
        });

        $stateProvider.state('skills', {
            parent: 'projectdetailjist',
            url: '/skills-Required/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/projectdetailjist/skillsRequired.html',
                    controller: 'SkillsRequiredController',
                    controllerAs: 'vm',
                    backdrop: 'static',

                    resolve: {
                        projectInfo: ['$stateParams', 'Project', function ($stateParams, Project) {
                            return Project.get({id : $stateParams.projectid}).$promise;
                        }]
                    }

                }).result.then(function() {
                    $state.go('projectdetailjist', null, { reload: 'projectdetailjist' });
                }, function() {
                    $state.go('projectdetailjist');
                });
            }]
        }),

        $stateProvider.state('noInfo', {
            parent: 'projectdetailjist',
            url: '/NOInfo',
            data: {
                authorities: []
            },
            views: {
                'sideView@projectdetailjist': {
                    templateUrl: 'app/projectdetailjist/NoInfo.html',
                    controller: 'NoInfoController',
                    controllerAs: 'vm'
                }
            },

            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('projectdetailjist');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }

        })
            .state('totalSkills', {
                parent: 'app',
                url: '/total-Skills',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_SKILLS_REQUIREMENT_OFFICER'],
                    pageTitle: 'investhryApp.projectSkills.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/projectdetailjist/totalskills.html',
                        controller: 'TotalSkillsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('projectSkills');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
