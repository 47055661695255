(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HomeClearedDocumentController', HomeClearedDocumentController);

    HomeClearedDocumentController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'SearchHomeCAF', 'downloadService'];

    function HomeClearedDocumentController ($scope, Principal, LoginService, $state, SearchHomeCAF, downloadService) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.getProject = getProject;
        vm.project=[];

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });


        // getAccount();
        //
        // function getAccount() {
        //     Principal.identity().then(function(account) {
        //         vm.account = account;
        //         vm.isAuthenticated = Principal.isAuthenticated;
        //     });
        // }
        function register () {
            $state.go('register');
        }
        function getProject()
        {
            vm.project=null;
            vm.showtable=false;
            SearchHomeCAF.query({CAFPIN:vm.CAFPIN},function(result){
                vm.project = result;
                if(vm.project.length !==0){
                    vm.noProject = false;
                    vm.showtable=true;
                }
                else{
                    vm.noProject = true;
                }
            });
        }
        $('.select_location').on('change', function(){
            window.location = $(this).val();
        });


        vm.downFile = function(project) {
            downloadService.download(project.fileName)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
                });
        }
    }
})();
