(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcTicketTaskController', HepcTicketTaskController);

    HepcTicketTaskController.$inject = ['HepcTicketTask'];

    function HepcTicketTaskController(HepcTicketTask) {

        var vm = this;

        vm.hepcTicketTasks = [];

        loadAll();

        function loadAll() {
            HepcTicketTask.query(function(result) {
                vm.hepcTicketTasks = result;
                vm.searchQuery = null;
            });
        }
    }
})();
