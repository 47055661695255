(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ReAppliedDashboardController', ReAppliedDashboardController);

    ReAppliedDashboardController.$inject = ['ReApplyReDashboardStats'];

    function ReAppliedDashboardController (ReApplyReDashboardStats) {
        var vm = this;
        vm.reApplyDashboardData = [];
        loadAll();
        function loadAll() {
            ReApplyReDashboardStats.query(function(result) {
                vm.reApplyDashboardData = result;
            });
        }
    }
})();

