(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('aaiDetailPageController', aaiDetailPageController);

    aaiDetailPageController.$inject = ['$stateParams', 'Aai_service'];

    function aaiDetailPageController ($stateParams, Aai_service ) {
        var vm = this;

        vm.aai={};

        loadAll();

        function loadAll() {
            Aai_service.get({id: $stateParams.aaiId},function (result) {
                vm.aai=result;
            })
        }
    }
})();
