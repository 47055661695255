(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NmaController', NmaController);

    NmaController.$inject = ['Nma'];

    function NmaController(Nma) {

        var vm = this;

        vm.nmas = [];

        loadAll();

        function loadAll() {
            Nma.query(function(result) {
                vm.nmas = result;
                vm.searchQuery = null;
            });
        }
    }
})();
