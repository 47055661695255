(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Tower_logController', Tower_logController);

    Tower_logController.$inject = ['Tower_log'];

    function Tower_logController(Tower_log) {

        var vm = this;

        vm.tower_logs = [];

        loadAll();

        function loadAll() {
            Tower_log.query(function(result) {
                vm.tower_logs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
