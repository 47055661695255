(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('DraftHavingServices', DraftHavingServices);

    DraftHavingServices.$inject = ['$resource'];

    function DraftHavingServices ($resource) {
        var resourceUrl =  'api/drafthavingservices';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
            },
            'update': { method:'PUT' }
        });
    }
})();
