(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('searchCAF', {
            parent: 'app',
            url: '/searchCAF',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_DESK_OFFICER','ROLE_DEPT_CHANGE','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_NODAL_OFFICER','ROLE_TICKET_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/searchCAF/searchCAF.html',
                    controller: 'SearchCAFController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }]
            }
        });

        }
        })();
