(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HudaFormDetailController', HudaFormDetailController);

    HudaFormDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'HudaForm'];

    function HudaFormDetailController($scope, $rootScope, $stateParams, previousState, entity, HudaForm) {
        var vm = this;

        vm.hudaForm = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:hudaFormUpdate', function(event, result) {
            vm.hudaForm = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
