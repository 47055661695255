(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DatewiseDeptDashboardController', DatewiseDeptDashboardController)
        .filter('decimalFormat', function () {
            return function (text) {
                return parseFloat(text).toFixed(4).replace(/0+$/,'');
            };
        });

    DatewiseDeptDashboardController.$inject = ['$scope', '$filter','$window', 'Principal', 'LoginService', '$state', 'DatewiseDeptDashboard', 'Sector', 'Encryption', 'GetDeactivatedCAFs', '$location'];

    function DatewiseDeptDashboardController ($scope, $filter, $window , Principal, LoginService, $state, DatewiseDeptDashboard, Sector, Encryption, GetDeactivatedCAFs, $location) {
        var vm = this;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.getDatewiseDeptStat = getDatewiseDeptStat;
        vm.sectors = [];
        vm.showDeactivationDate = false;
        vm.datewisestatcollection = [];
        vm.downloadDeactivatedCAFs = download;
        vm.deactivatedCAFsAvailable = false;

        vm.sectorObj =  {
            id: " ",
            sectortype: "All",
            $$hashKey: " "
        };
        $scope.optionsForDatePicker = {
            maxDate: new Date(), // set this to whatever date you want to set
        };

        vm.selectedDate = function(dateSelected){
            var newDate = new Date(dateSelected);
            newDate.setDate(newDate.getDate()+30);
            if(newDate > Date.now()){
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: Date.now()
                };
            }
            else {
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: newDate
                };
            }
        };

        vm.amount = "all";
        vm.area = "all";
        vm.caftype = "new";
        vm.sector = "All";
        vm.employees = "all";
        vm.isDeactivatedRequested = false;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        vm.datePickerOpenStatus.startdate = false;
        vm.datePickerOpenStatus.enddate = false;
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        Sector.query(function(result) {
            result.unshift(vm.sectorObj);
            vm.sectors = result;
            vm.searchQuery = null;
        });

        function getDatewiseDeptStat() {
            vm.datewisestatcollection = null;
            vm.showDeactivationDate = false;
            vm.isDeactivatedRequested = false;
            if(vm.sectorObj.sectortype.includes("/")){
                vm.sector = vm.sectorObj.sectortype.split("/").join("=");
            }
            else{
                vm.sector = vm.sectorObj.sectortype;
            }
            var startdate = $filter('date')(vm.cafdetail.startdate , 'dd-MM-yyyy');
            var enddate =  $filter('date')(vm.cafdetail.enddate , 'dd-MM-yyyy');
            DatewiseDeptDashboard.query({fromdate:startdate, todate:enddate, amount:vm.amount, area:vm.area, caftype:vm.caftype, sectortype:vm.sector, employees:vm.employees},function(result) {
                vm.datewisestatcollection = result;
                vm.searchQuery = null;
                vm.show= true;
            });
            // DatewiseDeptDashboard.get({fromdate:startdate, todate:enddate, amount:vm.amount, area:vm.area, caftype:vm.caftype, sectortype:vm.sector, employees:vm.employees},function(result) {
            //     vm.datewisestatcollection = result.hits.hits;
            //     vm.searchQuery = null;
            //     vm.show= true;
            // });
        }

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                ga('set', 'page', $location.path());
                ga('send','pageview');
            });
        }
        function register () {
            $state.go('register');
        }

    ////////////uncomment if you want to use cassandra instead of elasticsearch//////////////////
    // vm.getDeactivatedCAFs = function(){
    //     vm.datewisestatcollection = null;
    //     GetDeactivatedCAFs.query(function(result){
    //         vm.showDeactivationDate = true;
    //         vm.isDeactivatedRequested = true;
    //         vm.datewisestatcollection = result;
    //     });
    // }
    ////////////////////////////////////////////////////////////////////////////////////////////

        vm.getDeactivatedCAFs = function(){
            vm.datewisestatcollection = null;
            vm.deactivatedCAFsAvailable = true;
            GetDeactivatedCAFs.get(function(result){
                vm.showDeactivationDate = true;
                vm.isDeactivatedRequested = true;
            // vm.datewisestatcollection = result.hits.hits;
                vm.deactivatedCAFsAvailable = true;
                download(result.hits.hits);
            });


        };

        function convertJsonsToArrays(args){
            var dataArray = [];
            var header = [ "Sr.",
                "CAFPin",
                "Investor Name",
                "Investor Email",
                "Investor Contact",
                "Business Entity",
                "Site Address",
                "District",
                "Project Level",
                "Proposed Employment",
                "Haryana Domicile",
                "Non-Haryana Domicile",
                "Proposed Investment(in crore)" ,
                "Proposed Area (in acres)",
                "Sector",
                "Project Purpose",
                "Project ID",
                "Application Date",
                "Deactivation Date",
                "Category Of Block",
                "Investment Type",
                "Product",
                "Start Date of Production",
                "Foreign Direct Investment",
            ];
            dataArray.push(header);
            for( var i = 0; i < args.length; i++ ){
                var row = args[i];
                var rowAr=[];
                var appliedDate=$filter('date')(row.serviceAppliedDate, 'dd-MM-yyyy');
                var clearedDate=$filter('date')(row.serviceClearedDate, 'dd-MM-yyyy');
                rowAr.push(i);
                rowAr.push(row._source.cafpin);
                rowAr.push(row._source.firstname);
                rowAr.push(row._source.emailprimary);
                rowAr.push(row._source.mobilenumber);
                rowAr.push(row._source.businessentity == "Renewable" ? "Renew" : row._source.businessentity);
                rowAr.push(row._source.siteaddress);
                rowAr.push(row._source.district);
                rowAr.push(row._source.projectlevel=="d" ? "DLCC" : "EEC");
                rowAr.push(row._source.totalpurposedemployment);
                rowAr.push(row._source.haryanadomicile);
                rowAr.push(row._source.nonharyanadomicile);
                rowAr.push(row._source.total_project_cost);
                rowAr.push(row._source.totalproposedprojectarea);
                rowAr.push(row._source.sectorname === 'Other Industries' ? row._source.otherSector + " - "+ row._source.sectorname : row._source.sectorname);
                rowAr.push(row._source.projectpurpose);
                rowAr.push(row._source.id);
                rowAr.push(row._source.applicationdate);
                rowAr.push(row._source.deactivateddate);
                rowAr.push(row._source.blockCategory);
                rowAr.push(row._source.investmentType);
                rowAr.push(row._source.product);
                rowAr.push(row._source.prodctionStartDate);
                rowAr.push(row._source.foreign_investment);
                dataArray.push(rowAr);
            }
            return dataArray;
        }

        function download(args) {
            var rows = convertJsonsToArrays(args);
            var lineArray = [];
            var lineArrayCleared = [];
            for(var i = 0; i < rows.length; i++){
                var line = rows[i].join("\t");
                lineArray.push(line);
            }
            lineArray = lineArray.join("\n");
            var a = document.createElement('a');
            var blob = new Blob([lineArray],{type: 'data:text/plain;charset=utf-8;'});
            var url = URL.createObjectURL(blob);
            $window.location.href = url;
            vm.deactivatedCAFsAvailable = false;
        }


    }
})();
