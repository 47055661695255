(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NoInfoController', NoInfoController);

    NoInfoController.$inject = ['$scope', '$stateParams','$state','$filter'];

    function NoInfoController ($scope, $stateParams, $state, $filter) {
        var vm = this;
        vm.projectid = $stateParams.projectid;
        vm.district = $stateParams.district;
        vm.NOInfo = [
              {
                deptName:"LABOUR DEPARTMENT - LABOUR WING",
                name :"Ms. Suman Kundu, Addl. Labour Commissioner",
                phoneNumber : '-',
                landlineNumber: '2587101-105'
            },
            {
                deptName:"LABOUR DEPARTMENT - FACTORY WING",
                name :"Sh. Sunil Kumar,Dy. Director",
                phoneNumber : '9896677104',
                landlineNumber: '2587101-105'
            },
            {
                deptName:"LABOUR DEPARTMENT - FACTORY WING",
                name :"Sh. Dharmender Singh, Dy. Director (Industrial safety and Health) ",
                phoneNumber : '2587101-105',
                landlineNumber: '2587101-105'
            },
            {
                deptName:"Town & Country Planning",
                name :"Sh. Hitesh Sharma, DTP",
                phoneNumber : '9417463002',
                landlineNumber: '2587101-105'
            },
            {
                deptName:"HSPCB (Haryana State Pollution Control Board)",
                name :"Sh. Satinder Pal, SEE, HSPCB",
                phoneNumber : '9812284704'
            },
            {
                deptName:"HSIIDC",
                name :"Sh. Mahavir Singh, G.M.",
                phoneNumber : '9814112596',
                landlineNumber: '2587101-105'
            },
            {
                deptName:"HSIIDC",
                name :"Ms. Mamta Joshi, DGM",
                phoneNumber : '8558829220',
                landlineNumber: '2587101-105'
            },
            {
                deptName:"Excise & Taxation Department",
                name :"Sh. N.K. Lamba, Dy. ETC",
                phoneNumber : '9991640966',
                landlineNumber: '2587101-105'
            },
            {
                deptName:"Forest Department",
                name :"Ajay Pal, DFO",
                phoneNumber : '9416745128',
                landlineNumber: '2587101-105'
            },
            {
                deptName:"UHBVN/ DHBVN",
                name :"Smt. Manju Bala, SE, UHBVN",
                phoneNumber : '1800-180-2132'
            },
            {
                deptName:"Revenue Department",
                name :"Sh. Rajesh Khyalia, DRO",
                phoneNumber : '9996920567',
                landlineNumber: '2587101-105'
            },

            {
                deptName:"Urban Locan Bodies",
                name :"Sh. Chirag Singh, Assistant Town Planner",
                phoneNumber : '9468411510'
            },
            {
                deptName:"Environment Department",
                name :"Dr. Radhey Shyam, Scientist-1",
                phoneNumber : '9417153589'
            },
            {
                deptName:"Food & Drug Administration (FDA)",
                name :"Sh. N.K. Ahooja, State Drug Controller",
                phoneNumber : '9878748899'
            },
            {
                deptName:"Mines & Geology",
                name :"Sh. R. K. Sharma, Mining Engineer",
                phoneNumber : '9416906565'
            },
            {
                deptName:"Public Health",
                name :"Sh. Amardeep Dahiya, Executive Engineer",
                phoneNumber : '8607519207'
            },
            {
                deptName:"Agriculture Department",
                name :"Sh. Parveen Kumar, Deputy Director (Plant Protection)",
                phoneNumber : '9896245943'
            },
            {
                deptName:"HAREDA",
                name :"Sh. R.S. Poonia, Project Officer ",
                phoneNumber : '9417350243'
            },
            {
                deptName:"Food Civil Supplies & Consumer Affairs ",
                name :"Dr. Ghanshyam Singh, Deputy Director",
                phoneNumber : '9416076423,9416915848'
            },
            {
                deptName:"Chief Electrical Inspector",
                name :"Sh. Hoshiar Singh, AE",
                phoneNumber : '9468178404'
            },
            {
                deptName:"PW(B&R) Department",
                name :"Sh. Romit Chug, XEN",
                phoneNumber : '9215928090, 7696921142'
            },
            {
                deptName:"DEPARTMENT OF AYUSH",
                name :"Dr. Satish Kathkar, District Ayurvedic Officer",
                phoneNumber : '9416070800'
            },
            {
                deptName:"Irrigation Department",
                name :"Sh. Sanjay Rahar, SE Project",
                phoneNumber : '9416322922'
            },
            {
                deptName:"HARYANA URBAN DEVELOPMENT AUTHORITY (HUDA)",
                name :"Sh. Satyavir Sabharwal, STP",
                phoneNumber : '9992211555'
            },
            {
                deptName:"Industries Department",
                name :"Sh. Sanjay Sharma, Deputy CIB",
                phoneNumber : '9891221818'
            },
            {
                deptName:"ULB-Fire Service-cum",
                name :"Sh. Gulshan Kalra, Assistant Divisional Fire Officer",
                phoneNumber : '7015091697'
            },
            {
                deptName:"DIPR",
                name :"Aman Kumar",
                phoneNumber : '9250765615',
                landlineNumber: '0172-2722018'
            }
        ];

        vm.findNO = function (value) {
            vm.NOContact = $filter('filter')(vm.NOInfo,{deptName : value});
        }
    }
})();
