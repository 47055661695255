(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerserviceNODialogController', TowerserviceNODialogController);

    TowerserviceNODialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TowerserviceNO'];

    function TowerserviceNODialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TowerserviceNO) {
        var vm = this;

        vm.towerserviceNO = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            console.log(vm.towerserviceNO);
            vm.isSaving = true;
            if (vm.towerserviceNO.id !== null) {
                TowerserviceNO.update(vm.towerserviceNO, onSaveSuccess, onSaveError);
            } else {
                TowerserviceNO.save(vm.towerserviceNO, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:towerserviceNOUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
