(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ProjectLogNewCafController', ProjectLogNewCafController);

    ProjectLogNewCafController.$inject = ['ProjectLogNewCaf'];

    function ProjectLogNewCafController(ProjectLogNewCaf) {

        var vm = this;

        vm.projectLogNewCafs = [];

        loadAll();

        function loadAll() {
            ProjectLogNewCaf.query(function(result) {
                vm.projectLogNewCafs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
