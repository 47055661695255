(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('landrequirementcaf', {
            parent: 'entity',
            url: '/landrequirementcaf',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.landrequirementcaf.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/landrequirementcaf/landrequirementcafs.html',
                    controller: 'LandrequirementcafController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('landrequirementcaf');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('landrequirementcaf-detail', {
            parent: 'landrequirementcaf',
            url: '/landrequirementcaf/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.landrequirementcaf.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/landrequirementcaf/landrequirementcaf-detail.html',
                    controller: 'LandrequirementcafDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('landrequirementcaf');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Landrequirementcaf', function($stateParams, Landrequirementcaf) {
                    return Landrequirementcaf.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'landrequirementcaf',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('landrequirementcaf-detail.edit', {
            parent: 'landrequirementcaf-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/landrequirementcaf/landrequirementcaf-dialog.html',
                    controller: 'LandrequirementcafDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Landrequirementcaf', function(Landrequirementcaf) {
                            return Landrequirementcaf.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('landrequirementcaf.new', {
            parent: 'landrequirementcaf',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/landrequirementcaf/landrequirementcaf-dialog.html',
                    controller: 'LandrequirementcafDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                landrequired: null,
                                plotsize: null,
                                estatename: null,
                                purchaserate: null,
                                estatelocation: null,
                                agencyname: null,
                                village: null,
                                tehsil: null,
                                district: null,
                                landusetype: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('landrequirementcaf', null, { reload: 'landrequirementcaf' });
                }, function() {
                    $state.go('landrequirementcaf');
                });
            }]
        })
        .state('landrequirementcaf.edit', {
            parent: 'landrequirementcaf',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/landrequirementcaf/landrequirementcaf-dialog.html',
                    controller: 'LandrequirementcafDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Landrequirementcaf', function(Landrequirementcaf) {
                            return Landrequirementcaf.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('landrequirementcaf', null, { reload: 'landrequirementcaf' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('landrequirementcaf.delete', {
            parent: 'landrequirementcaf',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/landrequirementcaf/landrequirementcaf-delete-dialog.html',
                    controller: 'LandrequirementcafDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Landrequirementcaf', function(Landrequirementcaf) {
                            return Landrequirementcaf.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('landrequirementcaf', null, { reload: 'landrequirementcaf' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
