(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ImposedLeviesController', ImposedLeviesController);

    ImposedLeviesController.$inject = [];

    function ImposedLeviesController () {
        var vm = this;
    }
})();
