(function () {
    'use strict';

    angular
        .module('investhryApp')
        .factory('UserById', UserById);

    UserById.$inject = ['$resource'];

    function UserById ($resource) {
            var resourceUrl =  '/api/users/userbyid/:id';


            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': { method:'PUT' }
            });
        }
    })();
