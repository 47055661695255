(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal', '$state'];

    function PasswordController (Auth, Principal, $state) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.passwordResult = false;
        vm.showError = false;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword (currentPassword) {
            var encPassword = encryptPassword(currentPassword);
            Auth.checkPassword({
                username: vm.account.login,
                password: encPassword
            },function(result) {
                vm.passwordResult = result;
                if(vm.passwordResult) {
                    if (vm.password !== vm.confirmPassword) {
                        vm.error = null;
                        vm.success = null;
                        vm.doNotMatch = 'ERROR';
                    } else {
                        vm.doNotMatch = null;
                        var newPassword = encryptPassword(vm.password);
                        Auth.changePassword(newPassword).then(function () {
                            vm.error = null;
                            vm.success = 'OK';
                        }).catch(function () {
                            vm.success = null;
                            vm.error = 'ERROR';
                        });
                    }
                } else {
                    vm.showError = true;
                }
            });
        }

        function encryptPassword(plainString)
        {
            var iv = "a407e444cd6179be5ced97caf0ff11cb";
            var salt = "a826254e90f626b011eb21aca9a79b65";
            var keySize = 128;
            var iterationCount = 10000;
            var passPhrase = "NAimIodPNkqOyHQXmEpoFA==Sdty=jHY";
            var aesUtil = new AesUtil(keySize, iterationCount);
            var encryptedString = aesUtil.encrypt(salt, iv, passPhrase, plainString);
            return encryptedString;
        }
    }
})();
