(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ShowprojectsTowerofficerController', ShowprojectsTowerofficerController);

    ShowprojectsTowerofficerController.$inject = ['$scope', '$filter', 'ShowprojectsTowerofficerService'];

    function ShowprojectsTowerofficerController($scope, $filter , ShowprojectsTowerofficerService) {
        var vm = this;
        vm.projects = [];
        vm.status='Pending';
        vm.isTowerServiceForClarification = isTowerServiceForClarification;

        loadAll();

        function loadAll() {
            ShowprojectsTowerofficerService.query({status:vm.status},function (result) {
                vm.projects = $filter('filter')(result,{formfilledstatus : true});
            });
        }

        function isTowerServiceForClarification(serviceId) {

                return !!((serviceId === '5f8e0757-e88b-4ef1-bb15-00a9e984b9b1' || serviceId === '58d75fd4-f360-4931-a484-ff9be0adc12c' || serviceId === '741f0f60-cf69-4659-8b19-19d96ffe0103' || serviceId === 'fc1b1635-d3dc-4d30-a5fd-5abf6381594d' || serviceId === '6f81801c-59c6-4773-9f37-a5d81172a1bf'));

        }


        vm.statusSelected=function () {
            loadAll();
        };
    }
})();
