(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RmlistController', RmlistController);

    RmlistController.$inject = ['Rmlist'];

    function RmlistController(Rmlist) {

        var vm = this;

        vm.rmlists = [];

        loadAll();

        function loadAll() {
            Rmlist.query(function(result) {
                vm.rmlists = result;
                vm.searchQuery = null;
            });
        }
    }
})();
