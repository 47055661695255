(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('service-dashboard-stats', {
            parent: 'entity',
            url: '/service-dashboard-stats',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.serviceDashboardStats.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service-dashboard-stats/service-dashboard-stats.html',
                    controller: 'ServiceDashboardStatsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('serviceDashboardStats');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('service-dashboard-stats-detail', {
            parent: 'service-dashboard-stats',
            url: '/service-dashboard-stats/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.serviceDashboardStats.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service-dashboard-stats/service-dashboard-stats-detail.html',
                    controller: 'ServiceDashboardStatsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('serviceDashboardStats');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ServiceDashboardStats', function($stateParams, ServiceDashboardStats) {
                    return ServiceDashboardStats.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'service-dashboard-stats',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('service-dashboard-stats-detail.edit', {
            parent: 'service-dashboard-stats-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-dashboard-stats/service-dashboard-stats-dialog.html',
                    controller: 'ServiceDashboardStatsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ServiceDashboardStats', function(ServiceDashboardStats) {
                            return ServiceDashboardStats.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service-dashboard-stats.new', {
            parent: 'service-dashboard-stats',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-dashboard-stats/service-dashboard-stats-dialog.html',
                    controller: 'ServiceDashboardStatsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                department_name: null,
                                service_name: null,
                                required: null,
                                applied: null,
                                pending_total: null,
                                pending_30days: null,
                                pending_45days: null,
                                cleared: null,
                                deactivated: null,
                                deemed_cleared: null,
                                rejected: null,
                                service_id: null,
                                withDraw: null,
                                departmentorder: null,
                                pending_60days: null,
                                pending_75days: null,
                                pending_90days: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('service-dashboard-stats', null, { reload: 'service-dashboard-stats' });
                }, function() {
                    $state.go('service-dashboard-stats');
                });
            }]
        })
        .state('service-dashboard-stats.edit', {
            parent: 'service-dashboard-stats',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-dashboard-stats/service-dashboard-stats-dialog.html',
                    controller: 'ServiceDashboardStatsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ServiceDashboardStats', function(ServiceDashboardStats) {
                            return ServiceDashboardStats.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('service-dashboard-stats', null, { reload: 'service-dashboard-stats' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service-dashboard-stats.delete', {
            parent: 'service-dashboard-stats',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-dashboard-stats/service-dashboard-stats-delete-dialog.html',
                    controller: 'ServiceDashboardStatsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ServiceDashboardStats', function(ServiceDashboardStats) {
                            return ServiceDashboardStats.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('service-dashboard-stats', null, { reload: 'service-dashboard-stats' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
