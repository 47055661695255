(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceStatusController', ServiceStatusController);

    ServiceStatusController.$inject = ['ServiceStatus'];

    function ServiceStatusController(ServiceStatus) {

        var vm = this;

        vm.serviceStatuses = [];

        loadAll();

        function loadAll() {
            ServiceStatus.query(function(result) {
                vm.serviceStatuses = result;
                vm.searchQuery = null;
            });
        }
    }
})();
