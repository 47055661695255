(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CAFTypeController', CAFTypeController);

    CAFTypeController.$inject = ['$scope','$state','$uibModalInstance','Projectlist','$filter'];

    function CAFTypeController($scope,$state,$uibModalInstance, Projectlist, $filter) {

        var vm = this;
        vm.clear = clear;

        function clear () {
           $uibModalInstance.dismiss('cancel');
        }

        vm.onprojectTypeSelected=function(){
            if(vm.projectType=='LANDREQUIREMENT'){
            $uibModalInstance.close();
            $state.go('addCAFlandreq',{},{reload:true});
            }
            if(vm.projectType=='SHOP'){
            $uibModalInstance.close();
            $state.go('addCAFshop',{},{reload:true});
            }
            if(vm.projectType=='RENEWABLE'){
            $uibModalInstance.close();
            $state.go('addCAFrenewable',{},{reload:true});
            }
            if(vm.projectType=='NEW'){
                Projectlist.get({id: "1", status: "All"}, function (result) {
                    vm.newCAFsList = $filter('filter')(result.content,{ caftype : 'New' }, true);
                    if (vm.newCAFsList.length > 0){
                        Swal.fire({
                            title: 'Attention!',
                            type: 'info',
                            html:
                                "You've already applied for a 'New' type of CAF from this account. " +
                                "If you continue then you'll be redirected to expand/diversify existing New CAF",
                            showCloseButton: true,
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonText:
                                'Expand/Diversify',
                            cancelButtonText:
                                'View CAFs',
                        }).then(function (result) {
                            if (result.value) {
                                $uibModalInstance.close();
                                $state.go('addCAFnewRestricted',{
                                    "id": vm.newCAFsList[0].projectid
                                },{reload:true});
                            }else {
                                $uibModalInstance.close();
                                $state.go('listproject',{},{reload:true});
                            }
                        });
                    }else {
                        $uibModalInstance.close();
                        $state.go('addCAFnew',{},{reload:true});
                    }
                });

            }
            if(vm.projectType=='TOWERCAF'){
            $uibModalInstance.close();
            $state.go('addCAFtower',{},{reload:true});
            }
            if(vm.projectType=='LANDEXCHANGE'){
                $uibModalInstance.close();
                $state.go('addCAFlandExchange',{},{reload:true});
            }
            if(vm.projectType=='FILMCAF'){
                $uibModalInstance.close();
                $state.go('addCAFfilm',{},{reload:true});
            }
            if(vm.projectType==='EXCISE'){
                $uibModalInstance.close();
                $state.go('addCAFexcise',{},{reload:true});
            }
            // if(vm.projectType==='ROW'){
            //     $uibModalInstance.close();
            //     $state.go('addCAFrow',{},{reload:true});
            // }
        }

    }
})();
