(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('PSTowerDownloadService', PSTowerDownloadService);

    PSTowerDownloadService.$inject = ['$resource'];

    function PSTowerDownloadService ($resource) {
        var resourceUrl =  'api/psTowerDownloadDashboard/status/fromDate/toDate';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPdf': {
                method: 'GET',
                url: 'api/psTowerDownloadDashboardPdf/status/fromDate/toDate',
                isArray: false, // Set isArray to false to expect an object response
                transformResponse: function(data, headers) {
                    // Parse the response data if necessary
                    var responseData = angular.fromJson(data);
                    // Return the data as an object or modify it as needed
                    return responseData;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
