(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CafOtpDetailController', CafOtpDetailController);

    CafOtpDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CafOtp'];

    function CafOtpDetailController($scope, $rootScope, $stateParams, previousState, entity, CafOtp) {
        var vm = this;

        vm.cafOtp = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:cafOtpUpdate', function(event, result) {
            vm.cafOtp = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
