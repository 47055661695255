(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Meeting_attendeeController', Meeting_attendeeController);

    Meeting_attendeeController.$inject = ['Meeting_attendee'];

    function Meeting_attendeeController(Meeting_attendee) {

        var vm = this;

        vm.meeting_attendees = [];

        loadAll();

        function loadAll() {
            Meeting_attendee.query(function(result) {
                vm.meeting_attendees = result;
                vm.searchQuery = null;
            });
        }
    }
})();
