(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketViewController', TicketViewController);

    TicketViewController.$inject = ['Principal', '$state','Tickets', 'TicketsLogs', 'downloadticketattachment', 'AdminTickets'];

    function TicketViewController(Principal, $state, Tickets, TicketsLogs, downloadticketattachment, AdminTickets) {
        var vm = this;
        vm.tickets = [];
        vm.allTickets = [];
        vm.getLogs=getLogs;
        vm.redirect=redirect;
        vm.downloadAttachment=downloadAttachment;
        vm.show = false;
        loadAll();

        function loadAll() {
            Principal.identity().then(function(account) {
                vm.account = account;
                Tickets.query({assignedMember: vm.account.login, status: "Open"},function(result){
                   vm.tickets = result;
                    vm.show = true;
                   vm.searchQuery = null;
                });
                Tickets.query({createdBy: vm.account.login},function(result){
                   vm.allTickets = result;
                   vm.searchQuery = null;
                });
            });
        }

        function getLogs(ticketDetails) {
            TicketsLogs.query({ticketNumber: ticketDetails.ticketNumber},function (result) {
                vm.ticketsLogs=result;
            })
        }

        function downloadAttachment(attachment) {
            downloadticketattachment.download(attachment)
                .then(function(success) {
                }, function(error) {
                   alert('error : ' + error);
                });
        }

        function redirect(tkt) {
            if(!tkt.isOpen){
                tkt.isOpen = true;
                tkt.adminSaveWithLogs="Direct Save";
                AdminTickets.update(tkt, onUpdateSuccess, onUpdateError)
            }
            else {
                $state.go('ticketdetail',{id:tkt.id});
            }
        }

        function onUpdateSuccess(result) {
            $state.go('ticketdetail',{id:result.id});
        }

        function onUpdateError() {
            alert("Error While Validating Ticket");
        }
    }
})();
