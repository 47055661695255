(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('AveragefeeYearlyByElatsic', AveragefeeYearlyByElatsic);

    AveragefeeYearlyByElatsic.$inject = ['$resource'];

    function AveragefeeYearlyByElatsic($resource) {
        var resourceUrl = 'api/averagefee-dashboard-statsYearly-elastic/departmentName/district/projectLevel/serviceId/status';
        var averageFeeUrl = 'api/servicedashboardcafsYearlyListByElasticForAverageFee/departmentName/district/projectLevel/serviceId/status';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    return angular.fromJson(data);
                }
            },
            'update': { method: 'PUT' },
            'getAverageFee': {
                method: 'GET',
                isArray: true,
                url: averageFeeUrl,
                transformResponse: function(data) {
                    // Parse the response and ensure it returns an array
                    var parsedData = angular.fromJson(data);
                    if (!Array.isArray(parsedData)) {
                        return [parsedData]; // Wrap the object in an array if it isn't already
                    }
                    return parsedData;
                }
            }
        });
    }
})();
