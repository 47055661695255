(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AppliedServicesController', AppliedServicesController);

    AppliedServicesController.$inject = ['AppliedServices'];

    function AppliedServicesController(AppliedServices) {

        var vm = this;

        vm.appliedServices = [];

        loadAll();

        function loadAll() {
            AppliedServices.query(function(result) {
                vm.appliedServices = result;
                vm.searchQuery = null;
            });
        }
    }
})();
