(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('UserListController', UserListController);

    UserListController.$inject = ['User'];

    function UserListController (User) {
        var vm = this;

        vm.user = {};

        load();
        function load () {
            User.query({createDate: ''}, function(result) {
                vm.user = result;
            });
        }
    }
})();
