(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AaiDialogController', AaiDialogController);

    AaiDialogController.$inject = ['$rootScope', '$state', '$timeout', '$scope', '$stateParams', 'entity', 'Aai_service', 'ConstructionPermitApprovalSteps', 'ConstructionPermitApproval', 'ConstructionPermitLog'];

    function AaiDialogController ($rootScope, $state, $timeout, $scope, $stateParams, entity, Aai_service, ConstructionPermitApprovalSteps, ConstructionPermitApproval, ConstructionPermitLog) {
        var vm = this;

        vm.aai = entity;
        vm.clear = clear;
        vm.save = save;
        vm.constructionPermit={};
        vm.constructionPermitLog={};
        vm.id = $stateParams.CPid;

        // console.log($stateParams);

        loadAll();

        function loadAll() {
            ConstructionPermitApproval.query({projectId: $stateParams.id},function (result) {
                vm.constructionPermit= result;
            })
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            // $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.aai.id !== null) {
                Aai_service.update(vm.aai, onSaveSuccess, onSaveError);
            } else {
                Aai_service.save(vm.aai, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.constructionPermit.aAIflag=true;
            vm.constructionPermit.aAIid=result.id;
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFirstSaveSuccess);
            // $uibModalInstance.close(result);
            // $state.go('nma-state');
            vm.isSaving = false;
        }

        function onFirstSaveSuccess() {
            vm.constructionPermitLog.action="Form Submitted";
            vm.constructionPermitLog.projectid= $stateParams.id;
            vm.constructionPermitLog.serviceid= '16b4d378-5723-4adc-85e9-de55da296008';
            ConstructionPermitLog.save(vm.constructionPermitLog,onSecondSaveSuccess)
        }

        function onSecondSaveSuccess() {
            vm.constructionPermitLog.action="Service Under Process";
            ConstructionPermitLog.save(vm.constructionPermitLog,onCPsavesuccess)
        }

        function onCPsavesuccess() {
            $rootScope.$broadcast("investhry: constructionPermitEvent");
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
