(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceLevelDetailController', ServiceLevelDetailController);

    ServiceLevelDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ServiceLevel'];

    function ServiceLevelDetailController($scope, $rootScope, $stateParams, previousState, entity, ServiceLevel) {
        var vm = this;

        vm.serviceLevel = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:serviceLevelUpdate', function(event, result) {
            vm.serviceLevel = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
