(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('SearchProject', SearchProject);

    SearchProject.$inject = ['$resource','DateUtils'];

    function SearchProject ($resource,DateUtils) {
        var resourceUrl =  '/api/searchProject/:CAFPIN';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdate = DateUtils.convertDateTimeFromServer(data.createdate);
                        data.updatedate = DateUtils.convertDateTimeFromServer(data.updatedate);
                        data.project_construction_start_date = DateUtils.convertDateTimeFromServer(data.project_construction_start_date);
                        data.commercial_activity_start_date = DateUtils.convertDateTimeFromServer(data.commercial_activity_start_date);
                        data.temp_load_demand_date = DateUtils.convertDateTimeFromServer(data.temp_load_demand_date);
                        data.regular_load_demand_date = DateUtils.convertDateTimeFromServer(data.regular_load_demand_date);
                        data.applicationdate = DateUtils.convertDateTimeFromServer(data.applicationdate);
                        data.deactivateddate = DateUtils.convertDateTimeFromServer(data.deactivateddate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
