(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SearchTicketController', SearchTicketController);

    SearchTicketController.$inject = ['Tickets', 'TicketsLogs', 'downloadticketattachment'];

    function SearchTicketController(Tickets, TicketsLogs, downloadticketattachment) {
        var vm = this;
        vm.search=false;
        vm.searchTicket=searchTicket;
        vm.getLogs=getLogs;
        vm.downloadAttachment=downloadAttachment;

        function searchTicket(columnKeys,columnValues) {
            vm.search=true;
            Tickets.query({columnKey: columnKeys, columnValue: columnValues }, function (result) {
               vm.tickets=result;
            });
        }

        function getLogs(tickets) {
            TicketsLogs.query({ticketNumber: tickets.ticketNumber},function (result) {
                vm.ticketsLogs=result;
            })
        }

        function downloadAttachment(attachment) {
            downloadticketattachment.download(attachment)
                .then(function(success) {}, function(error) {
                    alert('error : ' + error);
                });
        }
    }
})();
