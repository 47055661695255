(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MunicipalsController', MunicipalsController);

    MunicipalsController.$inject = ['Municipals'];

    function MunicipalsController(Municipals) {

        var vm = this;

        vm.municipals = [];

        loadAll();

        function loadAll() {
            Municipals.query(function(result) {
                vm.municipals = result;
                vm.searchQuery = null;
            });
        }
    }
})();
