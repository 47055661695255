(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('serviceFormController', serviceFormController);

   serviceFormController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','projectService','projectServiceFormFieldData','projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLogUpdated','FileManagement','Projectservicedetail','Projectserviceformfielddata','serviceFormFieldDataCollection','ServiceFormAttachments','DepartmentService','Serviceformdocs','downloadserviceformattachment','Project','CheckContent','NewHomeProjectServiceDetails'];
   function serviceFormController ($timeout, $scope, $stateParams, $uibModalInstance,projectService,projectServiceFormFieldData, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, FileManagement, Projectservicedetail, Projectserviceformfielddata,serviceFormFieldDataCollection,ServiceFormAttachments,DepartmentService,Serviceformdocs,downloadserviceformattachment, Project,CheckContent,NewHomeProjectServiceDetails) {
        var vm = this;
        vm.download_appendix = download_appendix;

        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectServiceFormFieldData=projectServiceFormFieldData;
        vm.projectService = projectService;
        var projectAttachmentResultObject=null;
        var projectServiceLogid=null;
        var formfielddataObjectArrayLength = 0;
        var formfieldCount = 0;
        var saveLogFlag = false;
        vm.clear = clear;
        vm.saveAsDraft=saveAsDraft;
        vm.saveFormFieldData = saveFormFieldData;
        vm.downloadFile=downloadFile;
        vm.isSavedDraft=false;
        vm.doubleextension = true;
        $scope.file = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        show();


        function show(){
         switch (vm.projectService.serviceid) {
            case '58d75fd4-f360-4931-a484-ff9be0adc12c':
                vm.show=true;
                break;
            case '5f8e0757-e88b-4ef1-bb15-00a9e984b9b1':
                vm.show=true;
                break;
            case '741f0f60-cf69-4659-8b19-19d96ffe0103':
            vm.show=true;
                break;
             case 'fc1b1635-d3dc-4d30-a5fd-5abf6381594d':
                 vm.show=true;
                 break;
             case '6f81801c-59c6-4773-9f37-a5d81172a1bf':
                 vm.show=true;
                 break;
            default:
            vm.show=false;
         }
        }

        function download_appendix() {
          downloadserviceformattachment.download('Appendix7.docx','Appendix7.docx');
        }

        function clear()
        {
             $uibModalInstance.dismiss('cancel');
        }

        vm.departmentservice= DepartmentService.get({id:vm.projectService.serviceid});
        Serviceformdocs.query({id:"1",serviceid:vm.projectService.serviceid},function(result) {
            vm.serviceformdocs = result;
            vm.searchQuery = null;
        });

        Project.get({ id : vm.projectService.projectid }, function(result){
            vm.project = result;
        });


        loadServiceFormFields();
        function loadServiceFormFields() {
            vm.serviceFormFieldData = serviceFormFieldDataCollection.query({serviceid:vm.projectService.serviceid, projectid:vm.projectService.projectid});
        }

        var combineFilledForm="";
        function saveFormFieldData()
        {
            vm.isSaving = true;
            var vmlength = vm.serviceFormFieldData.length;
            formfielddataObjectArrayLength = vmlength;
            for(var i= 0; i<vmlength;i++)
            {
                var data = vm.serviceFormFieldData[i];
                combineFilledForm=combineFilledForm + vm.serviceFormFieldData[i].formfieldvalue+" | ";
                data.projectid = vm.projectService.projectid;
                if (data.id !== null)
                {
                    Projectserviceformfielddata.update(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
                } else
                {
                    Projectserviceformfielddata.save(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
                }
                formfieldCount = i;
            }
             for( var j = 0; j < vm.fileattach.length; j++ ) {
                 if(vm.fileattach[j].isSaved===false)
                  {
                    vm.fileattach[j].isSaved=true;
                  }
                  ServiceFormAttachments.update(vm.fileattach[j],onUpdatefieldServiceFormAttachmentsSuccess);
             }
        }
        function onUpdatefieldServiceFormAttachmentsSuccess()
        {}
        vm.fileattach = ServiceFormAttachments.query({id:"1", projectid:vm.projectService.projectid, serviceid:vm.projectService.serviceid});
        vm.addfileattachment=addfileattachment;

        function addfileattachment()
        {
            if (angular.isDefined(vm.serviceformfile.name)) {
                var fileNameArray = vm.serviceformfile.name.split(".");
                var fileextension = "";

                if ((fileNameArray.length < 3)) {
                    if (fileNameArray[0] != "") {
                        if ((fileNameArray.length > 1)) {
                            fileextension = fileNameArray[(fileNameArray.length - 1)];
                        }
                        var inputFileName = vm.serviceformattachment.description;
                        if (fileextension !== "") {
                            inputFileName = inputFileName + "." + fileextension;
                        }

                        vm.serviceformattachment.isSaved = false;
                        vm.serviceformattachment.downloadfilename = inputFileName;
                        vm.serviceformattachment.clientfilename = vm.serviceformfile.name;
                        vm.serviceformattachment.projectID = vm.projectService.projectid;
                        vm.serviceformattachment.serviceID = vm.projectService.serviceid;
                        ServiceFormAttachments.save(vm.serviceformattachment, onSaveServiceFormAttachmentsSuccess);

                    }
                    else {
                        alert("Invalid File Name");
                    }

                }
                else {
                    alert("Please select correct file format");
                    vm.doubleextension = false;

                }
            }
            else {
                alert("Please Select a file first");
            }
        }


        function onSaveServiceFormAttachmentsSuccess (result)
        {
            var filename = result.id;
            var file =vm.serviceformfile;
            FileManagement.saveFile(file,filename);
            var fileNameArray = vm.serviceformfile.name.split(".");
                var fileextension="";
                if((fileNameArray.length>1))
                {
                    fileextension=fileNameArray[(fileNameArray.length-1)];
                }
                var inputFileName=result.id;
                if(fileextension!=="")
                {
                    inputFileName=inputFileName+"."+fileextension;
                }
                result.serverfilename=inputFileName;
                ServiceFormAttachments.update(result,onUpdateServiceFormAttachmentsSuccess);

        }
        function onUpdateServiceFormAttachmentsSuccess(result)
        {

            vm.fileattach.push(result);
            vm.fileattachment={};
            vm.serviceformattachment.description="";
            $('#browsefile').val('');
            vm.serviceformfile = {};

        }
        vm.removeRawRow = function(id)
        {
             ServiceFormAttachments.delete({id: id});
             var index = -1;
             var comArray =eval(vm.fileattach);
             for( var i = 0; i < comArray.length; i++ )
             {
                 if( comArray[i].id=== id)
                 {
                     index = i;
                     break;
                 }
             }
             vm.fileattach.splice( index, 1 );
        }
        function onSaveServiceFormFieldDataSuccess(result)
        {
            if(!saveLogFlag)
            {
                if(formfieldCount === (formfielddataObjectArrayLength-1))
                {
                    saveProjectServiceLog();
                    saveLogFlag = true;
                }
            }
        }
        function onSaveServiceFormFieldDataError()
        {}
        function saveProjectServiceLog(result){
            if (vm.isSavedDraft===true){
                $uibModalInstance.close(result);
                vm.isSaving = false;
                return;
            }
            $scope.$emit('investhryApp:projectserviceformfielddataUpdate', result);
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.comments=vm.projectServiceLog.comments + " | " +combineFilledForm;
            vm.projectServiceLog.actionTaken="ServiceFormEdited";
            ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);
        }
        function onServiceLogSaveSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments="Service form edited";
            vm.projectService.formFilledStatus=true;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
        }
        function onUpdateProjectServiceSuccess(result)
        {
            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            NewHomeProjectServiceDetails.query({projectServiceDetailId: vm.projectService.id, projectId: vm.projectService.projectid, serviceId: vm.projectService.serviceid}, function () {
                console.log('success');
            }, function (error) {
                console.log(error);
            });
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }
        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }
        function saveProjectAttachment()
        {
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Service Form Field Data Attachment";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }
        function onSaveProjectAttachmentSuccess(result)
        {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }
        function onSaveProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function onUpdateProjectAttachmentSuccess(result)
        {
            $uibModalInstance.close(result);
        }
        function onUpdateProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function downloadFile(serverfilename,downloadfilename)
        {
            downloadserviceformattachment.download(serverfilename,downloadfilename)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

        function saveAsDraft() {
            vm.isSavedDraft=true;
            saveFormFieldData();
        }
    }
})();
