(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('servicedashboardhod', {
            parent: 'app',
            url: '/servicedashboardHod',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/deptHodServiceDashboard/deptHodServiceDashboard.html',
                    controller: 'DeptHodServiceDashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }]
            }
        }).state('dashboardHod', {
            parent: 'app',
            url: '/dashboardHod',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/deptHodServiceDashboard/hodDashboard.html',
                    controller: 'HodDashboardController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('servicedashboardcafsHod', {
            parent: 'servicedashboardhod',
            url: '/servicedashboardHod/{servicename}/{serviceid}/{status}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/deptservicedashboard/serviceviewcafs.html',
                    controller: 'ServiceViewCafController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('dashboard');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('servicedashboardhod', null, { reload: 'servicedashboardhod' });
                }, function() {
                    $state.go('servicedashboardhod');
                });
            }]
        })
        }
    })();
