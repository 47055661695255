(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MultipleactiveaccountwithoutcafController', MultipleactiveaccountwithoutcafController);

    MultipleactiveaccountwithoutcafController.$inject = ['Multipleactiveaccountwithoutcaf'];

    function MultipleactiveaccountwithoutcafController(Multipleactiveaccountwithoutcaf) {

        var vm = this;
        vm.deactivateAll = deactivateAll;

        vm.multipleactiveaccountwithoutcafs = [];

        loadAll();

        function loadAll() {
            Multipleactiveaccountwithoutcaf.query(function(result) {
                vm.multipleactiveaccountwithoutcafs = result;
                vm.searchQuery = null;
            });
        }

        function deactivateAll(){
            Multipleactiveaccountwithoutcaf.get({deactivateAll:''},function (result){
            if(result=true){
                alert("Deactivated Successfully.");
                }
            });
        }
    }
})();
