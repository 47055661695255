(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketDetailController', TicketDetailController);

    TicketDetailController.$inject = ['$state', '$scope', '$window', 'Tickets', 'entity', 'HepcTicketTask', 'Principal', 'TicketsLogs', 'UploadTicketAttachment', 'downloadticketattachment', 'TicketsCustomer','DepartmentService'];

    function TicketDetailController($state, $scope, $window, Tickets, entity, HepcTicketTask, Principal, TicketsLogs, UploadTicketAttachment, downloadticketattachment, TicketsCustomer ,DepartmentService) {
        var vm = this;
        vm.tickets = entity;
        vm.ticketsLogs = {};
        vm.account= {};
        vm.hepcTicketTask = {};
        vm.changeStatus = changeStatus;
        vm.changeLevel = changeLevel;
        vm.uploadFile = uploadFile;
        vm.downloadAttachment = downloadAttachment;
        vm.levelChange= false;
        vm.statusChange= false;
        vm.timeStatus=false;
        $scope.file = {};
        vm.service = {};

        loadAll();

        function loadAll() {
            Principal.identity().then(function(account) {
                vm.account = account;
            });
            
            if(vm.tickets.serviceid !==null)
            {
                DepartmentService.get({id:vm.tickets.serviceid},function(result) {
                    vm.service = result;
                    console.log(vm.departmentServices);
                    vm.searchQuery = null;
                });

            }

            if(entity.cafpin.length===10){
                TicketsCustomer.get({columnKey: "cafpin", columnValue: entity.cafpin},function (result) {
                    vm.complainant=result;
                });
            }
            else if(entity.cafpin.length>10){
                if(entity.investorDetail.includes("@")){
                    TicketsCustomer.get({columnKey: "email", columnValue: entity.investorDetail},function (result) {
                        vm.complainant=result;
                    });
                }
                else{
                    TicketsCustomer.get({columnKey: "mobileNumber", columnValue: entity.investorDetail},function (result) {
                        vm.complainant=result;
                    });
                }

            }


            HepcTicketTask.query(function (result) {
                vm.hepcTicketTask = result[0];
                if(vm.hepcTicketTask.isWorkingDay){
                    if(vm.hepcTicketTask.isWorkingHours){
                        vm.workingStatus=true;
                        if(entity.timeLeft>0) {
                            vm.timeStatus=true;
                            var clock = $('.countDown').FlipClock(entity.timeLeft, {
                                clockFace: 'HourCounter',
                                countdown: true,
                                autoStart: true
                            });
                        }
                    }
                    else{
                        vm.workingStatus=false;
                        if(entity.timeLeft>0) {
                            vm.timeStatus=true;
                            var clock = $('.countDown').FlipClock(entity.timeLeft, {
                                clockFace: 'HourCounter',
                                countdown: false,
                                autoStart: false
                            });
                        }
                    }
                }
                else {
                    vm.workingStatus = false;
                    if(entity.timeLeft>0) {
                        vm.timeStatus=true;
                        var clock = $('.countDown').FlipClock(entity.timeLeft, {
                            clockFace: 'HourCounter',
                            countdown: false,
                            autoStart: false
                        });
                    }
                }
            });
        }

        function changeStatus () {
            vm.statusChange= true;
            vm.tickets.status = "Closed";
            vm.tickets.latestRemark = vm.latestRemark;
            Tickets.update(vm.tickets, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            alert("Ticket Closed successfully");
            vm.statusChange= false;
            $state.go('ticketview');
            location.reload();
        }

        function onSaveError () {
            alert("Error while closing ticket");
            $("#setStatus").modal("hide");
            vm.statusChange= false;
            location.reload();
        }

        function changeLevel () {
            vm.levelChange= true;
            vm.tickets.latestRemark = vm.latestRemark;
            Tickets.update(vm.tickets, onAssignSuccess, onAssignError);
        }

        function onAssignSuccess (result) {
            alert("Assigned to next level successfully");
            vm.levelChange= false;
            $state.go('ticketview');
            location.reload();
        }

        function onAssignError () {
            alert("Error while assigning");
            vm.levelChange= false;
            $("#changeLevel").modal("hide");
            location.reload();
        }

        function uploadFile () {
            vm.ticketsLogs.ticketId = vm.tickets.id;
            vm.ticketsLogs.ticketNumber = vm.tickets.ticketNumber;
            vm.ticketsLogs.creationDate = vm.tickets.creationDate;
            vm.ticketsLogs.actionBy = vm.account.login;
            vm.ticketsLogs.actionByName = vm.account.firstName+" "+vm.account.lastName;
            vm.ticketsLogs.departmentName = vm.tickets.departmentName;
            vm.ticketsLogs.departmentId = vm.tickets.departmentId;
            vm.ticketsLogs.projectid = vm.tickets.projectid;
            vm.ticketsLogs.serviceid = vm.tickets.serviceid;
            vm.ticketsLogs.remark = vm.ticketComment;
            vm.ticketsLogs.cafpin = vm.tickets.cafpin;
            vm.ticketsLogs.status = vm.tickets.status;
            vm.ticketsLogs.assignedMember = vm.tickets.assignedMemberName;
            vm.ticketsLogs.assignedMemberName = vm.tickets.assignedMemberUserName;

            if(angular.isDefined(vm.file)){
                var file =  vm.file;
                UploadTicketAttachment.saveFile(file,onUploadSuccess,onUploadError);
            }else {
                vm.ticketsLogs.actionType = "Comment";
                TicketsLogs.save(vm.ticketsLogs, onLogSuccess, onLogError);
            }
        }

        function onUploadSuccess (result) {
            vm.ticketsLogs.actionType = "Comment And Attachment";
            vm.ticketsLogs.attachment = result.filename;

            TicketsLogs.save(vm.ticketsLogs, onLogSuccess, onLogError);
        }

        function onUploadError () {
            alert("Error while adding comment and file");
            $("#commentUpload").modal("hide");
            location.reload();
        }

        function onLogSuccess (result) {
            alert("Comment added successfully");
            $("#commentUpload").modal("hide");
            location.reload();
        }

        function onLogError () {
            alert("Error while adding comment");
            $("#commentUpload").modal("hide");
            location.reload();
        }

        function downloadAttachment(attachment) {
            downloadticketattachment.download(attachment)
                .then(function(success) {
                }, function(error) {
                    alert('error : ' + error);
                });
        }

    }
})();
