(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RejectedProjectServiceLogDeleteController',RejectedProjectServiceLogDeleteController);

    RejectedProjectServiceLogDeleteController.$inject = ['$uibModalInstance', 'entity', 'RejectedProjectServiceLog'];

    function RejectedProjectServiceLogDeleteController($uibModalInstance, entity, RejectedProjectServiceLog) {
        var vm = this;

        vm.rejectedProjectServiceLog = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RejectedProjectServiceLog.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
