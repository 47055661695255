(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Tower_logDeleteController',Tower_logDeleteController);

    Tower_logDeleteController.$inject = ['$uibModalInstance', 'entity', 'Tower_log'];

    function Tower_logDeleteController($uibModalInstance, entity, Tower_log) {
        var vm = this;

        vm.tower_log = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Tower_log.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
