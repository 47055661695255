(function () {
        'use strict';

        angular
            .module('investhryApp')
            .controller('ClearancechartsController', ClearancechartsController);

        ClearancechartsController.$inject = ['Clearancecharts', '$uibModalInstance'];

        function ClearancechartsController(Clearancecharts, $uibModalInstance) {
            var vm = this;
            vm.services = [];
            vm.isService = false;
            vm.isTotal = false;
            vm.averageTime = [];
            vm.clear = clear;
            vm.options = {legend: {display: true, position: 'bottom'}};
            loadCharts();

            function loadCharts() {
                if (Clearancecharts.getStatus() === 'SERVICE') {
                    loadServices();
                } else {
                    loadDept();
                }
            }

            function clear() {
                $uibModalInstance.close();
            }

            function loadServices() {
                vm.departmentname = Clearancecharts.getdepartmentname();
                vm.servicestats = Clearancecharts.getservicestats();
                vm.isService = true;
                for (var i = 0; i < vm.servicestats.length; i++) {
                    vm.services.push(vm.servicestats[i].servicename);
                    if (vm.servicestats[i].averageClearanceTime === 'NA') {
                        vm.averageTime.push(0);
                    } else {
                        vm.averageTime.push(parseInt(vm.servicestats[i].averageClearanceTime));
                    }
                }
            }

            function loadDept() {
                vm.deptData = Clearancecharts.getservicestats();
                vm.isTotal = true;
                for (var i=0;i<vm.deptData.length;i++){
                    vm.services.push(vm.deptData[i].deptname);
                    var sum=0;
                    var totalCleared=0;
                    for (var j=0;j<vm.deptData[i].newServiceStats.length;j++){
                        if (vm.deptData[i].newServiceStats[j].averageClearanceTime!=='NA'){
                            sum=sum+parseInt(vm.deptData[i].newServiceStats[j].averageClearanceTime);
                            totalCleared++;
                        }
                    }
                    vm.averageTime.push(Math.round(sum/totalCleared));
                }
            }
        }
    })();
