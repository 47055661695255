(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['$scope', 'Principal'];

    function FooterController ($scope, Principal) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;
        jQuery('.side-container').each(function() {
            var $container = jQuery(this);
            var $openButton = $container.find('.side-container-open');
            var $closeButton = $container.find('.side-container-close');

            $openButton.on('click', function() {
                $container.addClass('active');
            });

            $closeButton.on('click', function() {
                $container.removeClass('active');
            });

            window.onkeydown = function(event) {
                if(event.keyCode == 27)	{
                    $container.removeClass('active');
                }
            }
        })
    }
})();
