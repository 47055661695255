(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Below_groundController', Below_groundController);

    Below_groundController.$inject = ['Below_ground'];

    function Below_groundController(Below_ground) {

        var vm = this;

        vm.below_grounds = [];

        loadAll();

        function loadAll() {
            Below_ground.query(function(result) {
                vm.below_grounds = result;
                vm.searchQuery = null;
            });
        }
    }
})();
