(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('serviceClearanceActionController', serviceClearanceActionController);

    serviceClearanceActionController.$inject = ['$scope', '$stateParams','$state','projectServiceReportInfo', 'Projectcompletedetail', 'Auth', 'Principal', 'DepartmentService', 'Projectservicedetail','ProjectServiceReportInfoForClearance','projectservicelist','projectServiceLog','ProjectServiceLogUpdated','ProjectservicedetailbyProject','Department', 'TowerserviceNO', 'Project', '$sce','Municipals'];

    function serviceClearanceActionController ($scope, $stateParams, $state, projectServiceReportInfo, Projectcompletedetail, Auth, Principal, DepartmentService, Projectservicedetail,ProjectServiceReportInfoForClearance,projectservicelist,projectServiceLog,ProjectServiceLogUpdated,ProjectservicedetailbyProject,Department, TowerserviceNO, Project, $sce,Municipals) {
        var vm = this;
        vm.projectid = $stateParams.projectid;
        vm.district = $stateParams.district;

        vm.title = "Service Clearances in Process";
        vm.statusOnPage = 'All';
        vm.selectedprojectservice = {};
        vm.towerserviceNO = {};
        vm.isDeskof = false;

        vm.constructionpermits=[{id:'PRE',name:'Pre Establishment'},{id:'POST',name:'Post Establishment'}];
        vm.loadProjectServices = loadProjectServices;
        vm.showFillServiceForm = showFillServiceForm;
        vm.showTrackServiceForm = showTrackServiceForm;
        vm.tower_departments = tower_departments;
        vm.isTowerService = isTowerService;
        vm.isTowerServiceForWithdraw = isTowerServiceForWithdraw;
        vm.projectServiceReportInfo = projectServiceReportInfo;
        vm.projectservicelist = projectservicelist;
        vm.projectServiceLog = projectServiceLog;
        vm.formStatus = true;
        vm.statusFilter = vm.statusOnPage;
        vm.statusChange = statusChange;
        vm.municipals = [];
        $scope.requiredMunicipal = [];
        vm.caftype = '';


        function statusChange (status){
            if(status ==="All") {
                status="";
            }
            vm.formStatus =true;
           vm.statusFilter =status;
           if(status===""){
            vm.formStatus= "";
           }
            if(status === "Required"){
                vm.statusFilter = "Pending";
                vm.formStatus = false;
            }

        }

        loadAllDepartments();

        $scope.required={
            department: {},
            municipal:{}
        };

        $scope.clear= function () {
            $scope.required.department=[];
            vm.aboveMunicipal = null;
            $scope.requiredMunicipal = [];
            $scope.required.belowMunicipal = null

        };

        $scope.cancel= function () {
            $scope.required.department=[];
            vm.aboveMunicipal = null;
            $scope.requiredMunicipal = [];
            $scope.required.belowMunicipal = null

        };

        Project.get({id: vm.projectid},function (result) {
            vm.caftype = result.caftype;
        });

        vm.updateCheck = function (check) {
            $scope.required.department=[];
            $scope.required.department.push(check);
            if(check==='MUNICIPAL COUNCIL' || check==='MUNICIPAL COMMITTEE'|| check==='MUNICIPAL CORPORATION'){
                Municipals.query({district:$stateParams.district,name:$scope.required.department},function(result) {
                    vm.municipals = result;
                    vm.searchQuery = null;
                });
                $('#tower_department2').modal('hide');
                $('#YourModalId').modal('show');
            }
        };

        vm.BelowGround = function(check){
            if(check==='MUNICIPAL COUNCIL' || check==='MUNICIPAL COMMITTEE'|| check==='MUNICIPAL CORPORATION'){
                Municipals.query({district:$stateParams.district,name:check},function(result) {
                    vm.municipals = result;
                    vm.searchQuery = null;
                    if($scope.required.department.indexOf(check) != -1) {
                        $('#tower_department3').modal('hide');
                        $('#belowGround').modal('show');
                    } else {
                        for (var im = 0; im < vm.municipals.length; im++) {
                            var indexvalue = $scope.requiredMunicipal.indexOf(vm.municipals[im].name) ;
                            if(indexvalue != -1) {
                                $scope.requiredMunicipal.splice(indexvalue, 1);
                            }
                        }
                    }
                });

            }
        };

        vm.markRequiredForBelow = function(service,department,municipal){
            for(var i = 0; i<municipal.length; i++ ) {
                if ($scope.requiredMunicipal.indexOf(municipal[i]) < 0) {
                    $scope.requiredMunicipal.push(municipal[i]);
                }
            }
            $scope.required.belowMunicipal =null;

            $('#belowGround').modal('hide');
            $('#tower_department3').modal('show');
        };

        // vm.updateMunicipal = function (munici) {
        //     $scope.required.municipal=[];
        //     $scope.required.municipal.push(munici);
        //
        // };

        vm.markRequiredForMunicipal = function (service,department,municipal) {
            if ($scope.requiredMunicipal.indexOf(municipal) < 0) {
                $scope.requiredMunicipal.push(municipal);
            }
            vm.updateTowerServiceNO(service);

        };

        Principal.identity().then(function(account) {
            vm.account = account;
            if(angular.equals(vm.account.login,"deskof")){
                vm.isDeskof = true;
            }
        });

        vm.selectedDepartment =function(){
         vm.department=vm.department || 'all';
         vm.constructionpermit=vm.constructionpermit||'all';

            vm.projectservicelist.projectservicedetailDTOS=[];
         if (vm.department==='all' && vm.constructionpermit==='all') {
            vm.projectservicelist.projectservicedetailDTOS=vm.tempDataHandler.projectservicedetailDTOS;
            return;
         }
         else if (vm.department==='all' && vm.constructionpermit!=='all'){
             for (var i = 0; i < vm.tempDataHandler.projectservicedetailDTOS.length; i++) {
                 if (vm.tempDataHandler.projectservicedetailDTOS[i].constructionPermit===vm.constructionpermit) {
                     vm.projectservicelist.projectservicedetailDTOS.push(vm.tempDataHandler.projectservicedetailDTOS[i])
                 }
             }
         }
         else if (vm.department!=='all' && vm.constructionpermit==='all'){
             for (var i = 0; i < vm.tempDataHandler.projectservicedetailDTOS.length; i++) {
                 if (vm.tempDataHandler.projectservicedetailDTOS[i].departmentName===vm.department) {
                     vm.projectservicelist.projectservicedetailDTOS.push(vm.tempDataHandler.projectservicedetailDTOS[i])
                 }
             }
         }else {
             for (var i = 0; i < vm.tempDataHandler.projectservicedetailDTOS.length; i++) {
                 if (vm.tempDataHandler.projectservicedetailDTOS[i].departmentName===vm.department && vm.tempDataHandler.projectservicedetailDTOS[i].constructionPermit===vm.constructionpermit) {
                     vm.projectservicelist.projectservicedetailDTOS.push(vm.tempDataHandler.projectservicedetailDTOS[i])
                 }
             }
         }
        };

        vm.selectedPermit =function(){
            vm.department=vm.department || 'all';
            vm.constructionpermit=vm.constructionpermit||'all';

            vm.projectservicelist.projectservicedetailDTOS=[];
            if (vm.department==='all' && vm.constructionpermit==='all') {
                vm.projectservicelist.projectservicedetailDTOS=vm.tempDataHandler.projectservicedetailDTOS;
                return;
            }
            else if (vm.department==='all' && vm.constructionpermit!=='all'){
                for (var i = 0; i < vm.tempDataHandler.projectservicedetailDTOS.length; i++) {
                    if (vm.tempDataHandler.projectservicedetailDTOS[i].constructionPermit===vm.constructionpermit) {
                        vm.projectservicelist.projectservicedetailDTOS.push(vm.tempDataHandler.projectservicedetailDTOS[i])
                    }
                }
            }
            else if (vm.department!=='all' && vm.constructionpermit==='all'){
                for (var i = 0; i < vm.tempDataHandler.projectservicedetailDTOS.length; i++) {
                    if (vm.tempDataHandler.projectservicedetailDTOS[i].departmentName===vm.department) {
                        vm.projectservicelist.projectservicedetailDTOS.push(vm.tempDataHandler.projectservicedetailDTOS[i])
                    }
                }
            }else {
                for (var i = 0; i < vm.tempDataHandler.projectservicedetailDTOS.length; i++) {
                    if (vm.tempDataHandler.projectservicedetailDTOS[i].departmentName===vm.department && vm.tempDataHandler.projectservicedetailDTOS[i].constructionPermit===vm.constructionpermit) {
                        vm.projectservicelist.projectservicedetailDTOS.push(vm.tempDataHandler.projectservicedetailDTOS[i])
                    }
                }
            }
        };

        vm.fillform=fillform;
        function fillform(projectservice,requestType)
        {
            if(projectservice.serviceid === '16b4d378-5723-4adc-85e9-de55da296008'){
                $state.go('aai-fillform', {projectserviceid:projectservice.id});
            }
            else if (projectservice.serviceid === 'bdb451cb-f12b-4352-8360-e6e3a715386f'){
                Project.get({id: vm.projectid},function (result) {
                    $state.go('nma-serviceForm-fillform', {projectserviceid:projectservice.id,cafpin: result.cafpin});
                })
            }
            else if(projectservice.serviceid === 'ee348d5a-48c9-484c-81db-76fa10938e82'){
                $state.go('landexchange-form', {projectserviceid:projectservice.id});
            }
            else if(projectservice.serviceid === 'f2c426f3-33a3-4a20-8065-6e6a553c1af1'){
                $state.go('ULBlandexchange-form', {projectserviceid:projectservice.id});
            }
            else if((projectservice.isIntegrated === true))
            {
                getAccount(projectservice,requestType);
            }
            else
            {
                $state.go('serviceForm-fillform', {projectserviceid: projectservice.id});
            }
        }

        function getAccount(projectservice,requestType) {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.id=vm.account.id;
                $state.go('i-serviceform',{projectid:vm.projectservicelist.projectid,serviceid:projectservice.serviceid,projectserviceid:projectservice.id,id:vm.id,markeddate:projectservice.requireMarkedOnDate,requestType: requestType});
            });
        }

        function showFillServiceForm(projectservice)
        {
            if((projectservice.isRequired === true))
            {
                if (vm.caftype === 'Shop' && projectservice.departmentName==='EXCISE & TAXATION - EXCISE') {
                    return false
                }
                
                if((projectservice.status==='Cleared')||(projectservice.status==='Declined')||(projectservice.status==='Rejected')||projectservice.status === 'DeemedCleared' )
                {
                    return false;
                }
                else if(projectservice.isIntegrated === true)
                {

                    if(projectservice.formFilledStatus !== true){
                        if((projectservice.renewable || projectservice.shop || projectservice.istowerservice || projectservice.islandexchange || projectservice.rowService || projectservice.newService || projectservice.landRequirement || projectservice.isprojectfilm || projectservice.exciseService)){
                           return true;
                        }else{
                            return false;
                        }
                    }
//                    return projectservice.formFilledStatus !== true;
                    return false;
                }
                else
                {
                    if ((projectservice.renewable || projectservice.shop || projectservice.istowerservice || projectservice.islandexchange || projectservice.rowService || projectservice.newService || projectservice.landRequirement || projectservice.isprojectfilm || projectservice.exciseService)) {
                        return true;
                    }

                    return false;
                }

            }
            return false;
        }

        function showTrackServiceForm(projectservice)
        {
            if((projectservice.isRequired === true))
            {
                if (vm.caftype === 'Shop' && projectservice.departmentName==='EXCISE & TAXATION - EXCISE') {
                    return false
                }
                return (projectservice.isIntegrated === true && projectservice.formFilledStatus === true);
            }
            return false;
        }

        getServiceCount();
        function getServiceCount()
        {
           vm.departmentServices =  DepartmentService.query();
            vm.count =vm.projectservicelist=ProjectservicedetailbyProject.get({id : vm.projectid, status:"inProcess"},function(result){

            vm.tempDataHandler=angular.copy(vm.projectservicelist);

           });
           vm.totalServicesInProcess = vm.count.length;
           vm.totalserviceRequired = (vm.departmentServices.length)-(vm.totalServicesInProcess);
        }
        function loadProjectServices(status)
        {
             vm.department='all';
             vm.constructionpermit='all';

            vm.projectservicelist=ProjectservicedetailbyProject.get({id : vm.projectid, status:status},function(result){

                vm.tempDataHandler=angular.copy(vm.projectservicelist);
            });
            if(status =="required")
                vm.title = "Service Required";
            else
                vm.title = "Service Clearances in Process";
        }

        function loadAllDepartments(){
          Department.query(function(result){
            vm.departments=result;
          });
            if(vm.statusOnPage === 'All') {
                vm.statusFilter ="";
                vm.formStatus= "";
            }
        }

        function tower_departments(service,type) {
            $scope.required.department=[];
            vm.tower_service = service;
            vm.required_type = type;
            Department.query(function(result) {
                vm.tower_departmentlist = result;
            });
        }

        vm.updateTowerServiceNO= function (id) {
           vm.towerserviceNO.projectdetailId = id;
           vm.towerserviceNO.district = vm.district;
           vm.towerserviceNO.officerList = $scope.required.department;
           if($scope.requiredMunicipal.length !== 0) {
                for (var i = 0; i < $scope.requiredMunicipal.length; i++ ) {
                    if(vm.towerserviceNO.subname) {
                        vm.towerserviceNO.subname = vm.towerserviceNO.subname + "," + $scope.requiredMunicipal[i];
                    } else {
                        vm.towerserviceNO.subname = $scope.requiredMunicipal[i];
                    }
                }
            }
            // Projectservicedetail.get({id: vm.towerserviceNO.projectdetailId},function (data) {
            //     vm.projectServiceLog.projectid = $stateParams.projectid;
            //     vm.projectServiceLog.serviceid = data.serviceid;
            //     vm.projectServiceLog.comments = vm.towerserviceNO.subname;
            //     vm.projectServiceLog.actionTaken = vm.towerserviceNO.subname;
            //     Projectlogupdated.save(vm.projectServiceLog, function (data) {
            //         console.log(data);
            //     }, function (error) {
            //         console.log(error);
            //     });
            // });
           TowerserviceNO.save({id: '1'}, vm.towerserviceNO);
            vm.requiredMunicipal= [];
            location.reload();
            vm.towerserviceNO = {};

        };

        function isTowerService(projectservice) {
            var isNodalOfficer=Principal.hasAnyAuthority(['ROLE_NODAL_OFFICER']);
            return !!(isNodalOfficer && (projectservice.serviceid === '5f8e0757-e88b-4ef1-bb15-00a9e984b9b1' || projectservice.serviceid === '58d75fd4-f360-4931-a484-ff9be0adc12c' || projectservice.serviceid === '741f0f60-cf69-4659-8b19-19d96ffe0103' || projectservice.serviceid === 'fc1b1635-d3dc-4d30-a5fd-5abf6381594d' || projectservice.serviceid === '6f81801c-59c6-4773-9f37-a5d81172a1bf'));

        }

        function isTowerServiceForWithdraw(projectservice) {

            return !!((projectservice.serviceid === '5f8e0757-e88b-4ef1-bb15-00a9e984b9b1' || projectservice.serviceid === '58d75fd4-f360-4931-a484-ff9be0adc12c' || projectservice.serviceid === '741f0f60-cf69-4659-8b19-19d96ffe0103' || projectservice.serviceid === 'fc1b1635-d3dc-4d30-a5fd-5abf6381594d' || projectservice.serviceid === '6f81801c-59c6-4773-9f37-a5d81172a1bf'));

        }

        vm.showReApply = function (projectservice) {
            //for Land Allotment Service
            if(projectservice.serviceId ==='077f3112-69e6-41ea-a2e5-75b4925d6900'){
                return false;
            }
            if((projectservice.status ==='Cleared' || projectservice.status ==='Declined') && projectservice.isIntegrated && projectservice.serviceActive===true && (projectservice.renewable || projectservice.shop || projectservice.istowerservice || projectservice.islandexchange || projectservice.rowService || projectservice.newService)){
                return true;
            }else {
                return false;
            }
        };
        vm.closeModal = function () {
            $scope.required.department=[];
            $('#YourModalId').modal('hide');
            $('#tower_department2').modal('hide');
            vm.aboveMunicipal = null;
            $scope.requiredMunicipal = [];
        };

        vm.closeBelow = function () {
            $scope.required.department=[];
            $scope.requiredMunicipal = [];
            $scope.required.belowMunicipal =null;
            $('#belowGround').modal('hide');
            $('#tower_department3').modal('hide');
            vm.aboveMunicipal = null;
        }
    }
})();
