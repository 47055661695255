(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('PowerDashboardController', PowerDashboardController);

    PowerDashboardController.$inject = ['$timeout', '$scope', '$stateParams','$filter' , 'PowerRequiredCaf'];
    function PowerDashboardController ($timeout, $scope, $stateParams, $filter, PowerRequiredCaf) {
        var vm = this;

        var initDate=new Date('05-01-2017');
        vm.show = false;
        vm.requiredcaf = [];

        loadAllCafs();
        function loadAllCafs() {
            PowerRequiredCaf.query({date:initDate,power:'1'},function (result) {
                vm.requiredcaf =$filter('filter')(result,{isdeactivated:false});
                vm.show = true;
            })
        }



    }
})();
