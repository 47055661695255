(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RejectionBucketDetailController', RejectionBucketDetailController);

    RejectionBucketDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RejectionBucket'];

    function RejectionBucketDetailController($scope, $rootScope, $stateParams, previousState, entity, RejectionBucket) {
        var vm = this;

        vm.rejectionBucket = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:rejectionBucketUpdate', function(event, result) {
            vm.rejectionBucket = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
