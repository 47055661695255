(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('LandexchangeDetailController2', LandexchangeDetailController2);

    LandexchangeDetailController2.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'Landexchange', 'serviceFormAttachments', 'downloadserviceformattachment'];

    function LandexchangeDetailController2($scope, $rootScope, $stateParams, $uibModalInstance, entity, Landexchange, serviceFormAttachments, downloadserviceformattachment) {
        var vm = this;

        vm.landexchange = entity;
        vm.serviceformattachments=serviceFormAttachments;
        console.log(serviceFormAttachments);
        vm.clear=clear;
        vm.pdfconverter=pdfconverter;
        vm.downloadFile=downloadFile;

        var unsubscribe = $rootScope.$on('investhryApp:landexchangeUpdate', function(event, result) {
            vm.landexchange = result;
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function pdfconverter () {
            var element = document.getElementById('pdf_data');
            html2pdf(element,{
                margin: 4
            });
        }

        function downloadFile(serverfilename,downloadfilename)
        {
            downloadserviceformattachment.download(serverfilename,downloadfilename)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    alert('Error : ' + error);
                });
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
