(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('scheduledList', {
            parent: 'app',
            url: '/scheduledVisits',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/scheduledService/scheduledList.html',
                    controller: 'ScheduledListController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('listproject');
                    return $translate.refresh();
                }]
            }
        })
    .state('service-action', {
            parent: 'scheduledList',
            url: '/{action}/{projectserviceid}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN'  ]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/scheduledService/projectDetail-dialog.html',
                    controller: 'ScheduledPendingDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectAttachemnt: function () {
                            return {
                                fileName: null,
                                description: null,
                                fileExtension: null,
                                serverFileName: null,
                                id: null
                            };
                        },
                        projectServiceLog: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                comments: null,
                                commentDate: null,
                                commentByUserLogin: null,
                                actionTaken: null,
                                id: null
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }],
                        projectService: ['Projectservicedetail', function(Projectservicedetail) {
                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                        }]
                           // projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
                           //     return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
                           // }]
                    }
                }).result.then(function() {
                    $state.go('scheduledList', null);
                }, function() {
                    $state.go('scheduledList');
                });

            }]
        })
    }
})();
