(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievancelistController', GrievancelistController);

    GrievancelistController.$inject = ['$scope', '$state', 'Principal', 'LoginService', 'Grievance', 'GrievanceLog'];

    function GrievancelistController ($scope, $state, Principal, LoginService, Grievance, GrievanceLog) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.grievanceLog={};

        Grievance.query({id:"1",user:"1"},function(result) {
                        vm.grievances = result;
                        vm.searchQuery = null;
                    });

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        vm.commentContent= function (data) {
            vm.commentData = data;
        };

        vm.comment = function () {
            vm.grievanceLog.grievanceId= vm.commentData.grievanceId;
            vm.grievanceLog.action= "Comment";
            vm.grievanceLog.actionComment= vm.commentValue;
            GrievanceLog.save(vm.grievanceLog,onSaveSuccess);
        };

        function onSaveSuccess() {
            vm.grievanceLog={};
            vm.commentValue=null;
        }
    }
})();
