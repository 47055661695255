(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceStatusDialogController', ServiceStatusDialogController);

    ServiceStatusDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ServiceStatus'];

    function ServiceStatusDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ServiceStatus) {
        var vm = this;

        vm.serviceStatus = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.serviceStatus.id !== null) {
                ServiceStatus.update(vm.serviceStatus, onSaveSuccess, onSaveError);
            } else {
                ServiceStatus.save(vm.serviceStatus, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:serviceStatusUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.level1Date = false;
        vm.datePickerOpenStatus.level2Date = false;
        vm.datePickerOpenStatus.level3Date = false;
        vm.datePickerOpenStatus.level4Date = false;
        vm.datePickerOpenStatus.level5Date = false;
        vm.datePickerOpenStatus.level6Date = false;
        vm.datePickerOpenStatus.level7Date = false;
        vm.datePickerOpenStatus.level8Date = false;
        vm.datePickerOpenStatus.level9Date = false;
        vm.datePickerOpenStatus.level10Date = false;
        vm.datePickerOpenStatus.level0Date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
