(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewDashboardController', NewDashboardController);

    NewDashboardController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'NewDashboardStats', 'NewDasboardAllCompletedStats'];

    function NewDashboardController ($scope, Principal, LoginService, $state, NewDashboardStats, NewDasboardAllCompletedStats) {
        var vm = this;
//        vm.yesterday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);
        vm.newDashboardStats = [];
        vm.newDashboardAllCompleteStats = [];
        NewDashboardStats.query(function(result){
            vm.newDashboardStats = result;
        });
        NewDasboardAllCompletedStats.query(function(result){
            vm.newDashboardAllCompleteStats = result;
        });
    }
})();
