(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievanceDashboardOutputController', GrievanceDashboardOutputController);

    GrievanceDashboardOutputController.$inject = ['GrievanceDashboardOutput'];

    function GrievanceDashboardOutputController(GrievanceDashboardOutput) {

        var vm = this;

        vm.grievanceDashboardOutputs = [];

        loadAll();

        function loadAll() {
            GrievanceDashboardOutput.query(function(result) {
                vm.grievanceDashboardOutputs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
