(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('EodbController', EodbController);

    EodbController.$inject = ['$scope', 'EodbService'];

    function EodbController($scope, EodbService) {
        var vm = this;

    }
})();
