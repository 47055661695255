(function () {
    'use strict';
    angular
        .module('investhryApp')
        .factory('Incentives', Incentives);

    Incentives.$inject = ['$filter'];

    function Incentives($filter) {
        var incentives = [
            {
                projecttype: 'MEGA',
                block: 'B',
                incentives:
                    [
                        {
                            name: 'Investment Subsidy on SGST',
                            value1: '30%',
                            value2: '15%',
                            constraints1: '30% of net SGST paid for first 5 years',
                            constraints2: '15% of net SGST paid for next 3 years',
                            ismultiple: true,
                            remarks: 'Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment'
                        },
                        {
                            name: 'Employment Generation Subsidy (Rs/year)',
                            value1: 'Rs.36000 Per Year and Rs. 30000 Per Year OR 20%',
                            value2: null,
                            constraints1: 'Rs. 36,000 per year for SC/Women and Rs. 30,000/- per year for General Category for 5 years for capacity building of persons belonging to Haryana or 20% of the SGST deposited whichever is less,  for 5 years ',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Minimum 50% of total employment of persons belonging to Haryana b) Evidence for employment would be related statutory returns such as returns filed under the Employees Provident Fund Act'
                        },
                        {
                            name: 'Electricity Duty Exemption',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% Exemption from payment of Electricity duty for 5 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Only for new Mega Projects b) Applicable from date of release of electricity connection'
                        },
                        {
                            name: 'Stamp Duty Refund',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% refund of stamp duty within 5 years of filing of IEM',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Applicable on land area meant for industrial use'
                        },
                        {
                            name: 'EDC charges exemption',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption towards EDC charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'MEGA',
                block: 'C',
                incentives:
                    [
                        {
                            name: 'Investment Subsidy on SGST',
                            value1: '50% and 25%',
                            value2: null,
                            constraints1: '50% of net SGST paid for first 5 years and 25% of net SGST paid for next 3 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment'

                        },
                        {
                            name: 'Employment Generation Subsidy (Rs/year)',
                            value1: 'Rs.36000 Per Year and Rs. 30000 Per Year',
                            value2: '20%',
                            constraints1: 'Rs. 36,000 per year for SC/Women and Rs. 30,000/- per year for General Category for 5 years for capacity building of persons belonging to Haryana',
                            constraints2: '20% of the SGST deposited whichever is less,  for 5 years ',
                            ismultiple: true,
                            remarks: 'a) Minimum 50% of total employment of persons belonging to Haryana b) Evidence for employment would be related statutory returns such as returns filed under the Employees Provident Fund Act'
                        },
                        {
                            name: 'Electricity Duty Exemption',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% Exemption from payment of Electricity duty for 5 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Only for new Mega Projects b) Applicable from date of release of electricity connection'
                        },
                        {
                            name: 'Stamp Duty Refund',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% refund of stamp duty within 5 years of filing of IEM',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Applicable on land area meant for industrial use'
                        },
                        {
                            name: 'EDC charges exemption',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption towards EDC charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'MEGA',
                block: 'D',
                incentives:
                    [
                        {
                            name: 'Investment Subsidy on SGST',
                            value1: '75% and 35%',
                            value2: null,
                            constraints1: '75% of net SGST paid for first 5 years and 35% of net SGST paid for next 3 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment'
                        },
                        {
                            name: 'Employment Generation Subsidy (Rs/year)',
                            value1: 'Rs.36000 Per Year and Rs. 30000 Per Year',
                            value2: '20%',
                            constraints1: 'Rs. 36,000 per year for SC/Women and Rs. 30,000/- per year for General Category for 5 years for capacity building of persons belonging to Haryana',
                            constraints2: '20% of the SGST deposited whichever is less,  for 5 years ',
                            ismultiple: true,
                            remarks: 'a) Minimum 50% of total employment of persons belonging to Haryana b) Evidence for employment would be related statutory returns such as returns filed under the Employees Provident Fund Act'
                        },
                        {
                            name: 'Electricity Duty Exemption',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% Exemption from payment of Electricity duty for 7 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Only for new Mega Projects b) Applicable from date of release of electricity connection'
                        },
                        {
                            name: 'Stamp Duty Refund',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% refund of stamp duty within 5 years of filing of IEM',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Applicable on land area meant for industrial use'
                        },
                        {
                            name: 'EDC charges exemption',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption towards EDC charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'LARGE',
                block: 'B',
                incentives:
                    [
                        {
                            name: 'EDC Charges',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption towards EDC charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'LARGE',
                block: 'C',
                incentives:
                    [
                        {
                            name: 'VAT/SGST Subsidy',
                            value1: '50% and 25%',
                            value2: '50%',
                            constraints1: '50% of net SGST paid for first 5 years and 25% of net SGST paid for next 3 years',
                            constraints2: '50% of net SGST paid for  5 years on 50% additional investment in plant and machineryfor expansion/ diversification',
                            ismultiple: true,
                            remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI'
                        },
                        {
                            name: 'Employment Generation Subsidy (Rs/year)',
                            value1: 'Rs.36000 Per Year/Rs.30000 Per Year',
                            value2: '20%',
                            constraints1: 'Rs. 36,000 per year for SC/Women and Rs. 30,000/- per year for General Category for 5 years for capacity building of persons belonging to Haryana',
                            constraints2: '20% of the SGST deposited whichever is less,  for 5 years ',
                            ismultiple: true,
                            remarks: 'a) Minimum 50% of total employment of persons belonging to Haryana'
                        },
                        {
                            name: 'Electricity Duty Exemption',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% Exemption from payment of Electricity duty for 5 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                        },
                        {
                            name: 'Stamp Duty Refund',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% refund of stamp duty within 5 years of filing of IEM',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Applicable on land area meant for industrial use'
                        },
                        {
                            name: 'EDC Charges',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption towards EDC charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'LARGE',
                block: 'D',
                incentives:
                    [
                        {
                            name: 'VAT/SGST Subsidy',
                            value1: '75% and 35%',
                            value2: '75%',
                            constraints1: '75% of net SGST paid for first 7 years and 35% of net SGST paid for next 3 years',
                            constraints2: '75% of net SGST paid for 7 years on 50% additional investment in plant and machineryfor expansion/ diversification',
                            ismultiple: true,
                            remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI'
                        },
                        {
                            name: 'Employment Generation Subsidy (Rs/year)',
                            value1: 'Rs.36000 Per Year/Rs.30000 Per Year',
                            value2: '20%',
                            constraints1: 'Rs. 36,000 per year for SC/Women and Rs. 30,000/- per year for General Category for 5 years for capacity building of persons belonging to Haryana',
                            constraints2: '20% of the SGST deposited whichever is less,  for 5 years ',
                            ismultiple: true,
                            remarks: 'a) Minimum 50% of total employment of persons belonging to Haryana'
                        },
                        {
                            name: 'Electricity Duty Exemption',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% Exemption from payment of Electricity duty for 7 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                        },
                        {
                            name: 'Stamp Duty Refund',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% refund of stamp duty within 5 years of filing of IEM',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Applicable on land area meant for industrial use'
                        },
                        {
                            name: 'EDC Charges',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption towards EDC charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'MSME',
                block: 'A',
                incentives:
                    [
                        {
                            name: 'Testing Equipment Assistance',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% as subsidy for the purchase of testing equipments ',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 5 Lakhs'
                        },
                        {
                            name: 'Environment Compliance Assistance',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% financial support on capital cost for setting up of Effluent Treatment Plant and for installation of Air Pollution Control Devices ',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Upto a maximum of INR 25 lakh b) Units adopting zero effluent will be considered for higher subsidy on case to case basis'
                        },
                        {
                            name: 'Technology Acquisition Assistance',
                            value1: 'Misc',
                            value2: null,
                            constraints1: 'Upto a maximum of INR 25 Lakhs',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        {
                            name: 'Patent Cost',
                            value1: '50%',
                            value2: null,
                            constraints1: 'Reimbursement of the actual expenses (including filing fees, consultancy fees, search fees and maintenance fees) for domestic and international patent registration.',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 25 lakh'
                        },
                        {
                            name: 'Energy Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement for conducting energy audit',
                            constraints2: '50% subsidy on cost of capital equipments required for undertaking measures to conserve energy',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 2 lakh b)Upto a maximum of INR 20 lakh'
                        },
                        {
                            name: 'Water Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement of cost of Water audit',
                            constraints2: '50% subsidy on the cost of capital equipments required for Water Conservation/ Harvesting System. ',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 1 lakh b)Upto a maximum of INR 20 lakh'
                        },
                        {
                            name: 'Safety Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement of expenses incurred on safety audit ',
                            constraints2: '50% subsidy on cost of capital equipments required for undertaking safety measures',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 1 lakh b)Upto a maximum of INR 5 lakh'
                        },
                        {
                            name: 'Quality Certification',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% reimbursement for obtaining ISO certifications',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 1 lakh'
                        },
                        {
                            name: 'MSME State Awards',
                            value1: 'Rs.3 lakh',
                            value2: null,
                            constraints1: 'Rs. 3 lakh each separately for growth in production and profit, for innovation & development of new products, quality products and lean manufacturing techniques in the field of Agro and Food processing, Automobiles and auto parts, Textiles, Engineering, Pharmaceuticals, IT & Electronics etc.',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'The unit once selected for award shall not be considered again during next 5 years.'
                        },
                        {
                            name: 'Credit Rating',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% reimbursement of expenses incurred towards the credit rating carried out by SIDBI /Govt. accredited credit rating agency',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 2 lakh'
                        },
                        {
                            name: 'Market Development Assistance',
                            value1: 'Misc',
                            value2: 'Misc',
                            constraints1: 'For exhibitions within the country: 100% reimbursment of participation charges for space of maximum size up to 9 sq.m and Second class AC travelling charges and Boarding charges at the rate of Rs.1000 per day',
                            constraints2: 'For International Exhibitions: 75% space charges, shipment of exhibits, cost of product literature, display material or maximum up to Rs. 5 lakh and Air fare by economy class up to Rs. 1 lakh',
                            ismultiple: true,
                            remarks: null
                        },
                        {
                            name: 'Credit Linked Capital Subsidy for Technology Up-gradation',
                            value1: '15%',
                            value2: '5%',
                            constraints1: '15% capital subsidy for facilitating technology up-gradation in specified sectors/products of the Industry provided by Government of India',
                            constraints2: '5% Interest subsidy @ 5% for a period of 3 years provided by Govt. of Haryana for units eligible in the scheme',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 15 lakh b)Upto a maximum of INR 5 lakh'
                        },
                        {
                            name: 'Design Clinic Scheme',
                            value1: 'Misc',
                            value2: 'Misc',
                            constraints1: 'Gol provides Rs. 3 lakh with contribution of Rs. 1 lakh by industries for conducting Design Awareness Program (DAP) in the various clusters by National Institute of Design, Ahmadabad. The State would refund the contribution of industries of Rs. 1 lakh per program',
                            constraints2: 'Gol provides funding support@ 60% of the designing cost upto a maximum of Rs. 15 lakh under this scheme. The State Government would provide 20% of the designing cost out of the 40% contribution to be made by the industry',
                            ismultiple: true,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'MSME',
                block: 'B',
                incentives:
                    [
                        {
                            name: 'VAT/SGST Subsidy',
                            value1: '50% and 25%',
                            value2: '50%',
                            constraints1: '50% of net SGST paid for first 5 years and 25% of net SGST paid for next 3 years',
                            constraints2: '25% of net SGST paid for 5 years AND 25% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification',
                            ismultiple: true,
                            remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI'
                        },
                        {
                            name: 'Electricity Duty Expemption',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% from payment of Electricity duty for 7 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                        },
                        {
                            name: 'Stamp Duty Refund',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% refund of stamp duty within 5 years of filing of IEM',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer'
                        },
                        {
                            name: 'EDC Charges',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption of Conversion charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        {
                            name: 'CLU Refund',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption of Conversion charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        {
                            name: 'Interest Subsidy',
                            value1: '5%',
                            value2: '5%',
                            constraints1: '5% on term loan OR maximum upto Rs. 10.00 lakh per year for 3 years',
                            constraints2: '5% on term loan for expansion/diversification or maximum upto Rs. 10.00 lakh per year for 3 years',
                            ismultiple: true,
                            remarks: 'a)Cap of net SGST paid during the relevant year b)Expansion/diversification should not be covered under CLCSS of Gol c) Cap of net SGST paid during the relevant year'
                        },
                        {
                            name: 'Testing Equipment Assistance',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% as subsidy for the purchase of testing equipments ',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 5 Lakhs'
                        },
                        {
                            name: 'Environment Compliance Assistance',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% financial support on capital cost for setting up of Effluent Treatment Plant and for installation of Air Pollution Control Devices ',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Upto a maximum of INR 25 lakh b) Units adopting zero effluent will be considered for higher subsidy on case to case basis'
                        },
                        {
                            name: 'Technology Acquisition Assistance',
                            value1: 'Misc',
                            value2: null,
                            constraints1: 'Upto a maximum of INR 25 Lakhs',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        {
                            name: 'Patent Cost',
                            value1: '50%',
                            value2: null,
                            constraints1: 'Reimbursement of the actual expenses (including filing fees, consultancy fees, search fees and maintenance fees) for domestic and international patent registration.',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 25 lakh'
                        },
                        {
                            name: 'Energy Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement for conducting energy audit',
                            constraints2: '50% subsidy on cost of capital equipments required for undertaking measures to conserve energy',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 2 lakh b)Upto a maximum of INR 20 lakh'
                        },
                        {
                            name: 'Water Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement of cost of Water audit',
                            constraints2: '50% subsidy on the cost of capital equipments required for Water Conservation/ Harvesting System. ',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 1 lakh b)Upto a maximum of INR 20 lakh'
                        },
                        {
                            name: 'Safety Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement of expenses incurred on safety audit ',
                            constraints2: '50% subsidy on cost of capital equipments required for undertaking safety measures',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 1 lakh b)Upto a maximum of INR 5 lakh'
                        },
                        {
                            name: 'Quality Certification',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% reimbursement for obtaining ISO certifications',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 1 lakh'
                        },
                        {
                            name: 'MSME State Awards',
                            value1: 'Rs.3 lakh',
                            value2: null,
                            constraints1: 'Rs. 3 lakh each separately for growth in production and profit, for innovation & development of new products, quality products and lean manufacturing techniques in the field of Agro and Food processing, Automobiles and auto parts, Textiles, Engineering, Pharmaceuticals, IT & Electronics etc.',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'The unit once selected for award shall not be considered again during next 5 years.'
                        },
                        {
                            name: 'Credit Rating',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% reimbursement of expenses incurred towards the credit rating carried out by SIDBI /Govt. accredited credit rating agency',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 2 lakh'
                        },
                        {
                            name: 'Market Development Assistance',
                            value1: 'Misc',
                            value2: 'Misc',
                            constraints1: 'For exhibitions within the country: 100% reimbursment of participation charges for space of maximum size up to 9 sq.m and Second class AC travelling charges and Boarding charges at the rate of Rs.1000 per day',
                            constraints2: 'For International Exhibitions: 75% space charges, shipment of exhibits, cost of product literature, display material or maximum up to Rs. 5 lakh and Air fare by economy class up to Rs. 1 lakh',
                            ismultiple: true,
                            remarks: null
                        },
                        {
                            name: 'Credit Linked Capital Subsidy for Technology Up-gradation',
                            value1: '15%',
                            value2: '5%',
                            constraints1: '15% capital subsidy for facilitating technology up-gradation in specified sectors/products of the Industry provided by Government of India',
                            constraints2: '5% Interest subsidy @ 5% for a period of 3 years provided by Govt. of Haryana for units eligible in the scheme',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 15 lakh b)Upto a maximum of INR 5 lakh'
                        },
                        {
                            name: 'Design Clinic Scheme',
                            value1: 'Misc',
                            value2: 'Misc',
                            constraints1: 'Gol provides Rs. 3 lakh with contribution of Rs. 1 lakh by industries for conducting Design Awareness Program (DAP) in the various clusters by National Institute of Design, Ahmadabad. The State would refund the contribution of industries of Rs. 1 lakh per program',
                            constraints2: 'Gol provides funding support@ 60% of the designing cost upto a maximum of Rs. 15 lakh under this scheme. The State Government would provide 20% of the designing cost out of the 40% contribution to be made by the industry',
                            ismultiple: true,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'MSME',
                block: 'C',
                incentives:
                    [
                        {
                            name: 'VAT/SGST Subsidy',
                            value1: '75% and 35%',
                            value2: '75%',
                            constraints1: '75% of net SGST paid for first 7 years and 35% of net SGST paid for next 3 years',
                            constraints2: '75% of net SGST paid for 7 years AND 35% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification',
                            ismultiple: true,
                            remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI'
                        },
                        {
                            name: 'Electricity Duty Expemption',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% exemption from payment of Electricity duty for 7 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                        },
                        {
                            name: 'Stamp Duty Refund',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% refund of stamp duty within 5 years of filing of IEM',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer'
                        },
                        {
                            name: 'EDC Charges',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption of Conversion charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        {
                            name: 'CLU Refund',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption of Conversion charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        // {
                        //     name: 'Power Tariff Subsidy (Only for Micro & Small)',
                        //     value1: 'Rs.2 per unit',
                        //     value2: null,
                        //     constraints1: 'Rs. 2 per unit for 3 years',
                        //     constraints2: null,
                        //     ismultiple: false,
                        //     remarks: 'Applicable from date of release of electricity connection'
                        // },
                        {
                            name: 'Interest Subsidy',
                            value1: '5%',
                            value2: '5%',
                            constraints1: '5% on term loan OR maximum upto Rs. 10.00 lakh per year for 3 years',
                            constraints2: '5% on term loan for expansion/diversification or maximum upto Rs. 10.00 lakh per year for 3 years',
                            ismultiple: true,
                            remarks: 'a)Cap of net SGST paid during the relevant year b)Expansion/diversification should not be covered under CLCSS of Gol c) Cap of net SGST paid during the relevant year'
                        },
                        {
                            name: 'Testing Equipment Assistance',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% as subsidy for the purchase of testing equipments ',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 5 Lakhs'
                        },
                        {
                            name: 'Environment Compliance Assistance',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% financial support on capital cost for setting up of Effluent Treatment Plant and for installation of Air Pollution Control Devices ',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Upto a maximum of INR 25 lakh b) Units adopting zero effluent will be considered for higher subsidy on case to case basis'
                        },
                        {
                            name: 'Technology Acquisition Assistance',
                            value1: 'Misc',
                            value2: null,
                            constraints1: 'Upto a maximum of INR 25 Lakhs',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        {
                            name: 'Patent Cost',
                            value1: '50%',
                            value2: null,
                            constraints1: 'Reimbursement of the actual expenses (including filing fees, consultancy fees, search fees and maintenance fees) for domestic and international patent registration.',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 25 lakh'
                        },
                        {
                            name: 'Energy Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement for conducting energy audit',
                            constraints2: '50% subsidy on cost of capital equipments required for undertaking measures to conserve energy',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 2 lakh b)Upto a maximum of INR 20 lakh'
                        },
                        {
                            name: 'Water Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement of cost of Water audit',
                            constraints2: '50% subsidy on the cost of capital equipments required for Water Conservation/ Harvesting System. ',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 1 lakh b)Upto a maximum of INR 20 lakh'
                        },
                        {
                            name: 'Safety Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement of expenses incurred on safety audit ',
                            constraints2: '50% subsidy on cost of capital equipments required for undertaking safety measures',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 1 lakh b)Upto a maximum of INR 5 lakh'
                        },
                        {
                            name: 'Quality Certification',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% reimbursement for obtaining ISO certifications',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 1 lakh'
                        },
                        {
                            name: 'MSME State Awards',
                            value1: 'Rs.3 lakh',
                            value2: null,
                            constraints1: 'Rs. 3 lakh each separately for growth in production and profit, for innovation & development of new products, quality products and lean manufacturing techniques in the field of Agro and Food processing, Automobiles and auto parts, Textiles, Engineering, Pharmaceuticals, IT & Electronics etc.',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'The unit once selected for award shall not be considered again during next 5 years.'
                        },
                        {
                            name: 'Credit Rating',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% reimbursement of expenses incurred towards the credit rating carried out by SIDBI /Govt. accredited credit rating agency',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 2 lakh'
                        },
                        {
                            name: 'Market Development Assistance',
                            value1: 'Misc',
                            value2: 'Misc',
                            constraints1: 'For exhibitions within the country: 100% reimbursment of participation charges for space of maximum size up to 9 sq.m and Second class AC travelling charges and Boarding charges at the rate of Rs.1000 per day',
                            constraints2: 'For International Exhibitions: 75% space charges, shipment of exhibits, cost of product literature, display material or maximum up to Rs. 5 lakh and Air fare by economy class up to Rs. 1 lakh',
                            ismultiple: true,
                            remarks: null
                        },
                        {
                            name: 'Credit Linked Capital Subsidy for Technology Up-gradation',
                            value1: '15%',
                            value2: '5%',
                            constraints1: '15% capital subsidy for facilitating technology up-gradation in specified sectors/products of the Industry provided by Government of India',
                            constraints2: '5% Interest subsidy @ 5% for a period of 3 years provided by Govt. of Haryana for units eligible in the scheme',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 15 lakh b)Upto a maximum of INR 5 lakh'
                        },
                        {
                            name: 'Design Clinic Scheme',
                            value1: 'Misc',
                            value2: 'Misc',
                            constraints1: 'Gol provides Rs. 3 lakh with contribution of Rs. 1 lakh by industries for conducting Design Awareness Program (DAP) in the various clusters by National Institute of Design, Ahmadabad. The State would refund the contribution of industries of Rs. 1 lakh per program',
                            constraints2: 'Gol provides funding support@ 60% of the designing cost upto a maximum of Rs. 15 lakh under this scheme. The State Government would provide 20% of the designing cost out of the 40% contribution to be made by the industry',
                            ismultiple: true,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'MSME',
                block: 'D',
                incentives:
                    [
                        {
                            name: 'VAT/SGST Subsidy',
                            value1: '50% and 25%',
                            value2: '50%',
                            constraints1: '50% of net SGST paid for first 5 years and 25% of net SGST paid for next 3 years',
                            constraints2: '50% of net SGST paid for 5 years AND 25% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification',
                            ismultiple: true,
                            remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                        },
                        {
                            name: 'Electricity Duty Expemption',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% exemption from payment of Electricity duty for 7 years',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                        },
                        {
                            name: 'Stamp Duty Refund',
                            value1: '100%',
                            value2: null,
                            constraints1: '100% refund of stamp duty within 5 years of filing of IEM',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer'
                        },
                        {
                            name: 'EDC Charges',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption of Conversion charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        {
                            name: 'CLU Refund',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% exemption of Conversion charges',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        // {
                        //     name: 'Power Tariff Subsidy (Only for Micro & Small)',
                        //     value1: 'Rs.2 per unit',
                        //     value2: null,
                        //     constraints1: 'Rs. 2 per unit for 3 years',
                        //     constraints2: null,
                        //     ismultiple: false,
                        //     remarks: 'Applicable from date of release of electricity connection'
                        // },
                        {
                            name: 'Interest Subsidy',
                            value1: '5%',
                            value2: '5%',
                            constraints1: '5% on term loan OR maximum upto Rs. 10.00 lakh per year for 3 years',
                            constraints2: '5% on term loan for expansion/diversification or maximum upto Rs. 10.00 lakh per year for 3 years',
                            ismultiple: true,
                            remarks: 'a)Cap of net SGST paid during the relevant year b)Expansion/diversification should not be covered under CLCSS of Gol c) Cap of net SGST paid during the relevant year'
                        },
                        {
                            name: 'Testing Equipment Assistance',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% as subsidy for the purchase of testing equipments ',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 5 Lakhs'
                        },
                        {
                            name: 'Environment Compliance Assistance',
                            value1: '50%',
                            value2: null,
                            constraints1: '50% financial support on capital cost for setting up of Effluent Treatment Plant and for installation of Air Pollution Control Devices ',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'a) Upto a maximum of INR 25 lakh b) Units adopting zero effluent will be considered for higher subsidy on case to case basis'
                        },
                        {
                            name: 'Technology Acquisition Assistance',
                            value1: 'Misc',
                            value2: null,
                            constraints1: 'Upto a maximum of INR 25 Lakhs',
                            constraints2: null,
                            ismultiple: false,
                            remarks: null
                        },
                        {
                            name: 'Patent Cost',
                            value1: '50%',
                            value2: null,
                            constraints1: 'Reimbursement of the actual expenses (including filing fees, consultancy fees, search fees and maintenance fees) for domestic and international patent registration.',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 25 lakh'
                        },
                        {
                            name: 'Energy Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement for conducting energy audit',
                            constraints2: '50% subsidy on cost of capital equipments required for undertaking measures to conserve energy',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 2 lakh b)Upto a maximum of INR 20 lakh'
                        },
                        {
                            name: 'Water Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement of cost of Water audit',
                            constraints2: '50% subsidy on the cost of capital equipments required for Water Conservation/ Harvesting System. ',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 1 lakh b)Upto a maximum of INR 20 lakh'
                        },
                        {
                            name: 'Safety Audit',
                            value1: '75%',
                            value2: '50%',
                            constraints1: '75% reimbursement of expenses incurred on safety audit ',
                            constraints2: '50% subsidy on cost of capital equipments required for undertaking safety measures',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 1 lakh b)Upto a maximum of INR 5 lakh'
                        },
                        {
                            name: 'Quality Certification',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% reimbursement for obtaining ISO certifications',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 1 lakh'
                        },
                        {
                            name: 'MSME State Awards',
                            value1: 'Rs.3 lakh',
                            value2: null,
                            constraints1: 'Rs. 3 lakh each separately for growth in production and profit, for innovation & development of new products, quality products and lean manufacturing techniques in the field of Agro and Food processing, Automobiles and auto parts, Textiles, Engineering, Pharmaceuticals, IT & Electronics etc.',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'The unit once selected for award shall not be considered again during next 5 years.'
                        },
                        {
                            name: 'Credit Rating',
                            value1: '75%',
                            value2: null,
                            constraints1: '75% reimbursement of expenses incurred towards the credit rating carried out by SIDBI /Govt. accredited credit rating agency',
                            constraints2: null,
                            ismultiple: false,
                            remarks: 'Upto a maximum of INR 2 lakh'
                        },
                        {
                            name: 'Market Development Assistance',
                            value1: 'Misc',
                            value2: 'Misc',
                            constraints1: 'For exhibitions within the country: 100% reimbursment of participation charges for space of maximum size up to 9 sq.m and Second class AC travelling charges and Boarding charges at the rate of Rs.1000 per day',
                            constraints2: 'For International Exhibitions: 75% space charges, shipment of exhibits, cost of product literature, display material or maximum up to Rs. 5 lakh and Air fare by economy class up to Rs. 1 lakh',
                            ismultiple: true,
                            remarks: null
                        },
                        {
                            name: 'Credit Linked Capital Subsidy for Technology Up-gradation',
                            value1: '15%',
                            value2: '5%',
                            constraints1: '15% capital subsidy for facilitating technology up-gradation in specified sectors/products of the Industry provided by Government of India',
                            constraints2: '5% Interest subsidy @ 5% for a period of 3 years provided by Govt. of Haryana for units eligible in the scheme',
                            ismultiple: true,
                            remarks: 'a)Upto a maximum of INR 15 lakh b)Upto a maximum of INR 5 lakh'
                        },
                        {
                            name: 'Design Clinic Scheme',
                            value1: 'Misc',
                            value2: 'Misc',
                            constraints1: 'Gol provides Rs. 3 lakh with contribution of Rs. 1 lakh by industries for conducting Design Awareness Program (DAP) in the various clusters by National Institute of Design, Ahmadabad. The State would refund the contribution of industries of Rs. 1 lakh per program',
                            constraints2: 'Gol provides funding support@ 60% of the designing cost upto a maximum of Rs. 15 lakh under this scheme. The State Government would provide 20% of the designing cost out of the 40% contribution to be made by the industry',
                            ismultiple: true,
                            remarks: null
                        }
                    ]
            },
            {
                projecttype: 'Large Service Enterprises',
                block: 'B',
                incentives: [
                    {
                       name: 'Investment Subsidy on SGST',
                       value1: '50%',
                       value2: null,
                       constraints1: '50% of net VAT/SGST paid for a period of 5 years ',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'a) Only for new enterprise b) Applicable from date of commencement of service'
                    },
                    {
                       name: 'Entertainment Tax/ SGST (except cinema halls)',
                       value1: '50%',
                       value2: null,
                       constraints1: '50% tax exemption for 5 years',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'a) Only for new enterprise b) Cap of 100% of fixed capital investment'
                    },
                    {
                       name: 'Stamp Duty Exemption',
                       value1: '30%',
                       value2: null,
                       constraints1: '30% stamp duty within 5 years of filing of IEM',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'Only for new enterprise'
                    },
                    {
                       name: 'Electricity Duty Expemption',
                       value1: '50%',
                       value2: null,
                       constraints1: '50% payment of Electricity duty for 5 years',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'Only for new enterprise'
                    }
                ]
            },
            {
                projecttype: 'Large Service Enterprises',
                block: 'C',
                incentives: [
                    {
                       name: 'Investment Subsidy on SGST',
                       value1: '50%',
                       value2: null,
                       constraints1: '50% of net VAT/SGST paid for a period of 5 years ',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'a) Only for new enterprise b) Applicable from date of commencement of service'
                    },
                    {
                       name: 'Entertainment Tax/ SGST (except cinema halls)',
                       value1: '50%',
                       value2: null,
                       constraints1: '50% tax exemption for 5 years',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'a) Only for new enterprise b) Cap of 100% of fixed capital investment'
                    },
                    {
                       name: 'Stamp Duty Exemption',
                       value1: '50%',
                       value2: null,
                       constraints1: 'stamp duty within 5 years of filing of IEM',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'Only for new enterprise'
                    },
                    {
                       name: 'Electricity Duty Expemption',
                       value1: '75%',
                       value2: null,
                       constraints1: 'payment of Electricity duty for 5 years',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'Only for new enterprise'
                    }
                ]
            },
            {
                projecttype: 'Large Service Enterprises',
                block: 'D',
                incentives: [
                    {
                       name: 'Investment Subsidy on SGST',
                       value1: '50%',
                       value2: null,
                       constraints1: '50% of net VAT/SGST paid for a period of 5 years ',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'a) Only for new enterprise b) Applicable from date of commencement of service'
                    },
                    {
                       name: 'Entertainment Tax/ SGST (except cinema halls)',
                       value1: '50%',
                       value2: null,
                       constraints1: '50% tax exemption for 5 years',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'a) Only for new enterprise b) Cap of 100% of fixed capital investment'
                    },
                    {
                       name: 'Stamp Duty Exemption',
                       value1: '50%',
                       value2: null,
                       constraints1: 'stamp duty within 5 years of filing of IEM',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'Only for new enterprise'
                    },
                    {
                       name: 'Electricity Duty Expemption',
                       value1: '75%',
                       value2: null,
                       constraints1: 'payment of Electricity duty for 5 years',
                       constraints2: null,
                       ismultiple: false,
                       remarks: 'Only for new enterprise'
                    }
                ]
            }
        ];

        var incentivesBySector = [
            {
                sectortype: 'Agro',
                block: 'A',
                incentives: [
                    {
                        name: 'Market Fee Exemption',
                        value1: 'Misc',
                        value2: 'Misc',
                        constraints1: 'No market fee would be charged on the vegetables and fruits grown in the State and consumed as raw material by the food processing industry located within the State of Haryana.',
                        constraints2: 'No market fee would be charged on agriculture and horticulture produce used as raw material by food processing industries within the State except paddy, sugar, oil-seeds, til, toria, taramira.',
                        ismultiple: true,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Agro',
                block: 'B',
                incentives: [
                    {
                        name: 'VAT/SGST Subsidy',
                        value1: '75% and 35%',
                        value2: '75% and 50%',
                        constraints1: '75% of net SGST paid for first 7 years 35% of net SGST paid for next 3 years',
                        constraints2: '75% of net SGST paid for  7 years AND 35% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification.',
                        ismultiple: true,
                        remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                    },
                    {
                        name: 'Interest Subsidy',
                        value1: '6%',
                        value2: null,
                        constraints1: '6% on term loan for for 5 years',
                        ismultiple: false,
                        remarks: 'a) Upto a maximum of INR 10 Lakhs b) Shall not exceed the amount of net VAT/ SGST paid in the State by the enterprise during the relevant year.',
                    },
                    {
                        name: 'Stamp Duty Refund',
                        value1: '80%',
                        value2: null,
                        constraints1: '80% refund of stamp duty within 5 years of filing of IEM',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer.',
                    },
                    {
                        name: 'EDC Charges',
                        value1: '50%',
                        value2: null,
                        constraints1: '50% exemption of External Development Charges ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    // {
                    //     name: 'Power Tariff Subsidy(Only for Medium & Large Enterprise)',
                    //     value1: 'Misc',
                    //     value2: null,
                    //     constraints1: 'Exemption from open excess charges of 93 paisa per unit',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: null
                    // },
                    {
                        name: 'Electricity Duty Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% Exemption from payment of Electricity duty for 10 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                    },
                    {
                        name: 'Primary Processing Center',
                        value1: '50%',
                        value2: null,
                        constraints1: 'Capital subsidy @ 50% of FCI on plant & machinery and technical works,  for eligible entrepreneurs engaged in washing, grading, waxing, drying, packaging, cold chain and processing of fruits & vegetables using local produce as raw material',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Upto a maximum of Rs. 1.75 crore'
                    },
                    {
                        name: 'Excise Duty Exemption',
                        value1: 'Misc',
                        value2: null,
                        constraints1: 'Wines/ Liquors/ Brandy etc. made from 100% fruits and Barley produced in the State will be exempted from the Excise Duty',
                        ismultiple: false,
                        remarks: null

                    },
                    {
                        name: 'Market Fee Exemption',
                        value1: 'Misc',
                        value2: 'Misc',
                        constraints1: 'No market fee would be charged on the vegetables and fruits grown in the State and consumed as raw material by the food processing industry located within the State of Haryana.',
                        constraints2: 'No market fee would be charged on agriculture and horticulture produce used as raw material by food processing industries within the State except paddy, sugar, oil-seeds, til, toria, taramira.',
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Agro',
                block: 'C',
                incentives: [
                    {
                        name: 'VAT/SGST Subsidy',
                        value1: '75% and 35%',
                        value2: '75% and 50%',
                        constraints1: '75% of net SGST paid for first 7 years 35% of net SGST paid for next 3 years',
                        constraints2: '75% of net SGST paid for  7 years AND 35% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification.',
                        ismultiple: true,
                        remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                    },
                    {
                        name: 'Interest Subsidy',
                        value1: '6%',
                        value2: null,
                        constraints1: '6% on term loan for for 5 years',
                        ismultiple: false,
                        remarks: 'a) Upto a maximum of INR 10 Lakhs b) Shall not exceed the amount of net VAT/ SGST paid in the State by the enterprise during the relevant year.',
                    },
                    {
                        name: 'Stamp Duty Refund',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% refund of stamp duty within 5 years of filing of IEM',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer.',
                    },
                    {
                        name: 'EDC Charges',
                        value1: '50%',
                        value2: null,
                        constraints1: '50% exemption of External Development Charges ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Micro & Small)',
                    //     value1: 'Rs.2 per unit',
                    //     value2: null,
                    //     constraints1: 'Rs. 2 per unit for 3 years',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: 'Applicable from date of release of electricity connection'
                    // },
                    // {
                    //     name: 'Power Tariff Subsidy(Only for Medium & Large Enterprise)',
                    //     value1: 'Misc',
                    //     value2: null,
                    //     constraints1: 'Exemption from open excess charges of 93 paisa per unit',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: null
                    // },
                    {
                        name: 'Electricity Duty Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% Exemption from payment of Electricity duty for 10 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                    },
                    {
                        name: 'Primary Processing Center',
                        value1: '50%',
                        value2: null,
                        constraints1: 'Capital subsidy @ 50% of FCI on plant & machinery and technical works,  for eligible entrepreneurs engaged in washing, grading, waxing, drying, packaging, cold chain and processing of fruits & vegetables using local produce as raw material',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Upto a maximum of Rs. 1.75 crore'
                    },
                    {
                        name: 'Excise Duty Exemption',
                        value1: 'Misc',
                        value2: null,
                        constraints1: 'Wines/ Liquors/ Brandy etc. made from 100% fruits and Barley produced in the State will be exempted from the Excise Duty',
                        ismultiple: false,
                        remarks: null

                    },
                    {
                        name: 'Market Fee Exemption',
                        value1: 'Misc',
                        value2: 'Misc',
                        constraints1: 'No market fee would be charged on the vegetables and fruits grown in the State and consumed as raw material by the food processing industry located within the State of Haryana.',
                        constraints2: 'No market fee would be charged on agriculture and horticulture produce used as raw material by food processing industries within the State except paddy, sugar, oil-seeds, til, toria, taramira.',
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Agro',
                block: 'D',
                incentives: [
                    {
                        name: 'VAT/SGST Subsidy',
                        value1: '75% and 35%',
                        value2: '75% and 50%',
                        constraints1: '75% of net SGST paid for first 7 years 35% of net SGST paid for next 3 years',
                        constraints2: '75% of net SGST paid for  7 years AND 35% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification.',
                        ismultiple: true,
                        remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                    },
                    {
                        name: 'Interest Subsidy',
                        value1: '6%',
                        value2: null,
                        constraints1: '6% on term loan for for 5 years',
                        ismultiple: false,
                        remarks: 'a) Upto a maximum of INR 10 Lakhs b) Shall not exceed the amount of net VAT/ SGST paid in the State by the enterprise during the relevant year.',
                    },
                    {
                        name: 'Stamp Duty Refund',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% refund of stamp duty within 5 years of filing of IEM',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer.',
                    },
                    {
                        name: 'EDC Charges',
                        value1: '50%',
                        value2: null,
                        constraints1: '50% exemption of External Development Charges ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Micro & Small)',
                    //     value1: 'Rs.2 per unit',
                    //     value2: null,
                    //     constraints1: 'Rs. 2 per unit for 3 years',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: 'Applicable from date of release of electricity connection'
                    // },
                    // {
                    //     name: 'Power Tariff Subsidy(Only for Medium & Large Enterprise)',
                    //     value1: 'Misc',
                    //     value2: null,
                    //     constraints1: 'Exemption from open excess charges of 93 paisa per unit',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: null
                    // },
                    {
                        name: 'Electricity Duty Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% Exemption from payment of Electricity duty for 10 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                    },
                    {
                        name: 'Primary Processing Center',
                        value1: '50%',
                        value2: null,
                        constraints1: 'Capital subsidy @ 50% of FCI on plant & machinery and technical works,  for eligible entrepreneurs engaged in washing, grading, waxing, drying, packaging, cold chain and processing of fruits & vegetables using local produce as raw material',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Upto a maximum of Rs. 1.75 crore'
                    },
                    {
                        name: 'Excise Duty Exemption',
                        value1: 'Misc',
                        value2: null,
                        constraints1: 'Wines/ Liquors/ Brandy etc. made from 100% fruits and Barley produced in the State will be exempted from the Excise Duty',
                        ismultiple: false,
                        remarks: null

                    },
                    {
                        name: 'Market Fee Exemption',
                        value1: 'Misc',
                        value2: 'Misc',
                        constraints1: 'No market fee would be charged on the vegetables and fruits grown in the State and consumed as raw material by the food processing industry located within the State of Haryana.',
                        constraints2: 'No market fee would be charged on agriculture and horticulture produce used as raw material by food processing industries within the State except paddy, sugar, oil-seeds, til, toria, taramira.',
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Textile',
                block: 'A',
                incentives: [
                    {
                        name: 'VAT Exemption on cotton yarn',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% VAT exemption of VAT@ 5.25% on cotton yarn manufactured in the State',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Textile',
                block: 'B',
                incentives: [
                    {
                        name: 'VAT/SGST Subsidy',
                        value1: '50% and 25%',
                        value2: '50% and 50%',
                        constraints1: '50% of net SGST paid for first 5 years and 25% of net SGST paid for next 3 years',
                        constraints2: '50% of net SGST paid for 5 years AND 25% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification',
                        ismultiple: true,
                        remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                    },
                    {
                        name: 'Employment Generation Subsidy',
                        value1: 'Rs.10000',
                        value2: null,
                        constraints1: 'One time support of INR 10,000/- for capacity building of persons belonging to Haryana engaged in apparel rural functional clusters',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'Interest Subsidy (only for MSME)',
                        value1: '6%',
                        value2: '5%',
                        constraints1: '6% on term loan for new/ expansion/ diversification for 5 years for garments/ knitting/ embroidery/ technical textiles',
                        constraints2: '5% for ginning, cotton spinning and power loom MSMEs maximum of Rs. 10 lakh for 5 years',
                        ismultiple: true,
                        remarks: 'a) Upto a maximum of INR 10 Lakhs b) Shall not exceed the amount of net VAT/ SGST paid in the State by the enterprise during the relevant year.'
                    },
                    {
                        name: 'Stamp Duty Refund',
                        value1: '80%',
                        value2: null,
                        constraints1: '80% refund of stamp duty within 5 years of filing of IEM',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer'
                    },
                    {
                        name: 'EDC Charges',
                        value1: '50%',
                        value2: null,
                        constraints1: '50% exemption of External Development Charges ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Medium & Large Enterprise)',
                    //     value1: 'Misc',
                    //     value2: null,
                    //     constraints1: 'Exemption from open excess charges of 93 paisa per unit',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: null
                    // },
                    {
                        name: 'Electricity Duty Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% Exemption from payment of Electricity duty for 10 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                    },
                    {
                        name: 'Mandi Fees Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% exemption in case of ginning enterprises',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'Transfer Property Tax Exemption',
                        value1: 'Misc',
                        value2: null,
                        constraints1: 'Exemption from Transfer Property Tax in case of Dyes & Chemicals purchased from outside the state being used in process house. ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'VAT Exemption on cotton yarn',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% VAT exemption of VAT@ 5.25% on cotton yarn manufactured in the State',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Textile',
                block: 'C',
                incentives: [
                    {
                        name: 'VAT/SGST Subsidy',
                        value1: '75% and 35%',
                        value2: '75% and 50%',
                        constraints1: '75% of net SGST paid for first 7 years and 35% of net SGST paid for next 3 years',
                        constraints2: '75% of net SGST paid for 7 years AND 35% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification',
                        ismultiple: true,
                        remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                    },
                    {
                        name: 'Employment Generation Subsidy',
                        value1: 'Rs.10000',
                        value2: null,
                        constraints1: 'One time support of INR 10,000/- for capacity building of persons belonging to Haryana engaged in apparel rural functional clusters',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'Interest Subsidy (only for MSME)',
                        value1: '6%',
                        value2: '5%',
                        constraints1: '6% on term loan for new/ expansion/ diversification for 5 years for garments/ knitting/ embroidery/ technical textiles',
                        constraints2: '5% for ginning, cotton spinning and power loom MSMEs maximum of Rs. 10 lakh for 5 years',
                        ismultiple: true,
                        remarks: 'a) Upto a maximum of INR 10 Lakhs b) Shall not exceed the amount of net VAT/ SGST paid in the State by the enterprise during the relevant year.'
                    },
                    {
                        name: 'Stamp Duty Refund',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% refund of stamp duty within 5 years of filing of IEM',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer'
                    },
                    {
                        name: 'EDC Charges',
                        value1: '50%',
                        value2: null,
                        constraints1: '50% exemption of External Development Charges ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Micro & Small)',
                    //     value1: 'Rs.2 per unit',
                    //     value2: null,
                    //     constraints1: 'Rs. 2 per unit for 3 years',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: 'Applicable from date of release of electricity connection'
                    // },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Medium & Large Enterprise)',
                    //     value1: 'Misc',
                    //     value2: null,
                    //     constraints1: 'Exemption from open excess charges of 93 paisa per unit',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: null
                    // },
                    {
                        name: 'Electricity Duty Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% Exemption from payment of Electricity duty for 10 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                    },
                    {
                        name: 'Mandi Fees Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% exemption in case of ginning enterprises',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'Transfer Property Tax Exemption',
                        value1: 'Misc',
                        value2: null,
                        constraints1: 'Exemption from Transfer Property Tax in case of Dyes & Chemicals purchased from outside the state being used in process house. ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'VAT Exemption on cotton yarn',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% VAT exemption of VAT@ 5.25% on cotton yarn manufactured in the State',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Textile',
                block: 'D',
                incentives: [
                    {
                        name: 'VAT/SGST Subsidy',
                        value1: '75% and 35%',
                        value2: '75% and 50%',
                        constraints1: '75% of net SGST paid for first 7 years and 35% of net SGST paid for next 3 years',
                        constraints2: '75% of net SGST paid for 7 years AND 35% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification',
                        ismultiple: true,
                        remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                    },
                    {
                        name: 'Employment Generation Subsidy',
                        value1: 'Rs.10000',
                        value2: null,
                        constraints1: 'One time support of INR 10,000/- for capacity building of persons belonging to Haryana engaged in apparel rural functional clusters',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'Interest Subsidy (only for MSME)',
                        value1: '6%',
                        value2: '5%',
                        constraints1: '6% on term loan for new/ expansion/ diversification for 5 years for garments/ knitting/ embroidery/ technical textiles',
                        constraints2: '5% for ginning, cotton spinning and power loom MSMEs maximum of Rs. 10 lakh for 5 years',
                        ismultiple: true,
                        remarks: 'a) Upto a maximum of INR 10 Lakhs b) Shall not exceed the amount of net VAT/ SGST paid in the State by the enterprise during the relevant year.'
                    },
                    {
                        name: 'Stamp Duty Refund',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% refund of stamp duty within 5 years of filing of IEM',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer'
                    },
                    {
                        name: 'EDC Charges',
                        value1: '50%',
                        value2: null,
                        constraints1: '50% exemption of External Development Charges ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Micro & Small)',
                    //     value1: 'Rs.2 per unit',
                    //     value2: null,
                    //     constraints1: 'Rs. 2 per unit for 3 years',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: 'Applicable from date of release of electricity connection'
                    // },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Medium & Large Enterprise)',
                    //     value1: 'Misc',
                    //     value2: null,
                    //     constraints1: 'Exemption from open excess charges of 93 paisa per unit',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: null
                    // },
                    {
                        name: 'Electricity Duty Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% Exemption from payment of Electricity duty for 10 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                    },
                    {
                        name: 'Mandi Fees Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% exemption in case of ginning enterprises',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'Transfer Property Tax Exemption',
                        value1: 'Misc',
                        value2: null,
                        constraints1: 'Exemption from Transfer Property Tax in case of Dyes & Chemicals purchased from outside the state being used in process house. ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'VAT Exemption on cotton yarn',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% VAT exemption of VAT@ 5.25% on cotton yarn manufactured in the State',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Footwear',
                block: 'A',
                incentives: [
                    {
                        name: 'VAT Exemption',
                        value1: '5%',
                        value2: null,
                        constraints1: '5% VAT in place of existing 12.5% on the footwear above Rs. 500/-.',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Footwear',
                block: 'B',
                incentives: [
                    {
                        name: 'VAT/SGST Subsidy',
                        value1: '50% and 25%',
                        value2: '50% and 50%',
                        constraints1: '50% of net SGST paid for first 5 years and 25% of net SGST paid for next 3 years',
                        constraints2: '50% of net SGST paid for 5 years AND 25% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification',
                        ismultiple: true,
                        remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                    },
                    {
                        name: 'Employment Generation Subsidy',
                        value1: 'Rs.10000',
                        value2: null,
                        constraints1: 'One time support of INR 10,000/- for capacity building of persons belonging to Haryana engaged in apparel rural functional clusters',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'Interest Subsidy (only for MSME)',
                        value1: '6%',
                        value2: null,
                        constraints1: '6% for 5 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Upto a maximum of INR 10 Lakhs b) Shall not exceed the amount of net VAT/ SGST paid in the State by the enterprise during the relevant year.'
                    },
                    {
                        name: 'Stamp Duty Refund',
                        value1: '80%',
                        value2: null,
                        constraints1: '80% refund of stamp duty within 5 years of filing of IEM',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer'
                    },
                    {
                        name: 'EDC Charges',
                        value1: '50%',
                        value2: null,
                        constraints1: '50% exemption of External Development Charges ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Medium & Large Enterprise)',
                    //     value1: 'Misc',
                    //     value2: null,
                    //     constraints1: 'Exemption from open excess charges of 93 paisa per unit',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: null
                    // },
                    {
                        name: 'VAT Exemption',
                        value1: '5%',
                        value2: null,
                        constraints1: '5% VAT in place of existing 12.5% on the footwear above Rs. 500/-.',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Footwear',
                block: 'C',
                incentives: [
                    {
                        name: 'VAT/SGST Subsidy',
                        value1: '75% and 35%',
                        value2: '75% and 50%',
                        constraints1: '75% of net SGST paid for first 7 years and 35% of net SGST paid for next 3 years',
                        constraints2: '75% of net SGST paid for 7 years AND 35% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification',
                        ismultiple: true,
                        remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                    },
                    {
                        name: 'Employment Generation Subsidy',
                        value1: 'Rs.10000',
                        value2: null,
                        constraints1: 'One time support of INR 10,000/- for capacity building of persons belonging to Haryana engaged in apparel rural functional clusters',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'Interest Subsidy (only for MSME)',
                        value1: '6%',
                        value2: null,
                        constraints1: '6% for 5 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Upto a maximum of INR 10 Lakhs b) Shall not exceed the amount of net VAT/ SGST paid in the State by the enterprise during the relevant year.'
                    },
                    {
                        name: 'Stamp Duty Refund',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% refund of stamp duty within 5 years of filing of IEM',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer'
                    },
                    {
                        name: 'EDC Charges',
                        value1: '50%',
                        value2: null,
                        constraints1: '50% exemption of External Development Charges ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Micro & Small)',
                    //     value1: 'Rs.2 per unit',
                    //     value2: null,
                    //     constraints1: 'Rs. 2 per unit for 3 years',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: 'Applicable from date of release of electricity connection'
                    // },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Medium & Large Enterprise)',
                    //     value1: 'Misc',
                    //     value2: null,
                    //     constraints1: 'Exemption from open excess charges of 93 paisa per unit',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: null
                    // },
                    {
                        name: 'Electricity Duty Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% Exemption from payment of Electricity duty for 10 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                    },
                    {
                        name: 'VAT Exemption',
                        value1: '5%',
                        value2: null,
                        constraints1: '5% VAT in place of existing 12.5% on the footwear above Rs. 500/-.',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    }
                ]
            },
            {
                sectortype: 'Footwear',
                block: 'D',
                incentives: [
                    {
                        name: 'VAT/SGST Subsidy',
                        value1: '75% and 35%',
                        value2: '75% and 50%',
                        constraints1: '75% of net SGST paid for first 7 years and 35% of net SGST paid for next 3 years',
                        constraints2: '75% of net SGST paid for 7 years AND 35% of net SGST paid for next 3 years on atleast 50% additional investment in plant and machineryfor expansion/ diversification',
                        ismultiple: true,
                        remarks: 'a)Applicable from date of commencement of commercial production with cap of 100% of fixed capital investment b)Cap of 100% of fixed capital investment on expanded FCI.'
                    },
                    {
                        name: 'Employment Generation Subsidy',
                        value1: 'Rs.10000',
                        value2: null,
                        constraints1: 'One time support of INR 10,000/- for capacity building of persons belonging to Haryana engaged in apparel rural functional clusters',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    {
                        name: 'Interest Subsidy (only for MSME)',
                        value1: '6%',
                        value2: null,
                        constraints1: '6% for 5 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Upto a maximum of INR 10 Lakhs b) Shall not exceed the amount of net VAT/ SGST paid in the State by the enterprise during the relevant year.'
                    },
                    {
                        name: 'Stamp Duty Refund',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% refund of stamp duty within 5 years of filing of IEM',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'Applicable on purchase/ leasing of land for establishment of enterprise including Industrial Estates developed by HSIIDC/ private developer'
                    },
                    {
                        name: 'EDC Charges',
                        value1: '50%',
                        value2: null,
                        constraints1: '50% exemption of External Development Charges ',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Micro & Small)',
                    //     value1: 'Rs.2 per unit',
                    //     value2: null,
                    //     constraints1: 'Rs. 2 per unit for 3 years',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: 'Applicable from date of release of electricity connection'
                    // },
                    // {
                    //     name: 'Power Tariff Subsidy (Only for Medium & Large Enterprise)',
                    //     value1: 'Misc',
                    //     value2: null,
                    //     constraints1: 'Exemption from open excess charges of 93 paisa per unit',
                    //     constraints2: null,
                    //     ismultiple: false,
                    //     remarks: null
                    // },
                    {
                        name: 'Electricity Duty Exemption',
                        value1: '100%',
                        value2: null,
                        constraints1: '100% Exemption from payment of Electricity duty for 10 years',
                        constraints2: null,
                        ismultiple: false,
                        remarks: 'a) Only for new Enterprises b) Applicable from date of release of electricity connection'
                    },
                    {
                        name: 'VAT Exemption',
                        value1: '5%',
                        value2: null,
                        constraints1: '5% VAT in place of existing 12.5% on the footwear above Rs. 500/-.',
                        constraints2: null,
                        ismultiple: false,
                        remarks: null
                    }
                ]
            }

        ]
        var projectSize;
        return {
            getIncentives: function (projectsize, block, sector, type) {
                return $filter('filter')(incentives, {projecttype: projectsize, block: block});
            },
            getITSectorIncentives: function (block) {

            },
            getSectorSpecificIncentives: function (sector, block) {
                return $filter('filter')(incentivesBySector, {sectortype: sector, block: block});
            }
        }
    }
})();
