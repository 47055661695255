(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('FactotyPlanController', FactotyPlanController);

    FactotyPlanController.$inject = ['FactotyPlan'];

    function FactotyPlanController(FactotyPlan) {

        var vm = this;

        vm.factotyPlans = [];

        loadAll();

        function loadAll() {
            FactotyPlan.query(function(result) {
                vm.factotyPlans = result;
                vm.searchQuery = null;
            });
        }
    }
})();
