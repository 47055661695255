(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SharedPendingCAFController', SharedPendingCAFController);

    SharedPendingCAFController.$inject = ['SharedPendingCAF'];

    function SharedPendingCAFController(SharedPendingCAF) {

        var vm = this;

        vm.sharedPendingCAFS = [];

        loadAll();

        function loadAll() {
            SharedPendingCAF.query(function(result) {
                vm.sharedPendingCAFS = result;
                vm.searchQuery = null;
            });
        }
    }
})();
