(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SupplyAndDisposalController', SupplyAndDisposalController);

    SupplyAndDisposalController.$inject = ['SupplyAndDisposal'];

    function SupplyAndDisposalController(SupplyAndDisposal) {

        var vm = this;

        vm.supplyAndDisposals = [];

        loadAll();

        function loadAll() {
            SupplyAndDisposal.query(function(result) {
                vm.supplyAndDisposals = result;
                vm.searchQuery = null;
            });
        }
    }
})();
