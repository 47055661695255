(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('ServiceStatus', ServiceStatus);

    ServiceStatus.$inject = ['$resource', 'DateUtils'];

    function ServiceStatus ($resource, DateUtils) {
        var resourceUrl =  'api/service-statuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.level1Date = DateUtils.convertLocalDateFromServer(data.level1Date);
                        data.level2Date = DateUtils.convertLocalDateFromServer(data.level2Date);
                        data.level3Date = DateUtils.convertLocalDateFromServer(data.level3Date);
                        data.level4Date = DateUtils.convertLocalDateFromServer(data.level4Date);
                        data.level5Date = DateUtils.convertLocalDateFromServer(data.level5Date);
                        data.level6Date = DateUtils.convertLocalDateFromServer(data.level6Date);
                        data.level7Date = DateUtils.convertLocalDateFromServer(data.level7Date);
                        data.level8Date = DateUtils.convertLocalDateFromServer(data.level8Date);
                        data.level9Date = DateUtils.convertLocalDateFromServer(data.level9Date);
                        data.level10Date = DateUtils.convertLocalDateFromServer(data.level10Date);
                        data.level0Date = DateUtils.convertLocalDateFromServer(data.level0Date);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.level1Date = DateUtils.convertLocalDateToServer(copy.level1Date);
                    copy.level2Date = DateUtils.convertLocalDateToServer(copy.level2Date);
                    copy.level3Date = DateUtils.convertLocalDateToServer(copy.level3Date);
                    copy.level4Date = DateUtils.convertLocalDateToServer(copy.level4Date);
                    copy.level5Date = DateUtils.convertLocalDateToServer(copy.level5Date);
                    copy.level6Date = DateUtils.convertLocalDateToServer(copy.level6Date);
                    copy.level7Date = DateUtils.convertLocalDateToServer(copy.level7Date);
                    copy.level8Date = DateUtils.convertLocalDateToServer(copy.level8Date);
                    copy.level9Date = DateUtils.convertLocalDateToServer(copy.level9Date);
                    copy.level10Date = DateUtils.convertLocalDateToServer(copy.level10Date);
                    copy.level0Date = DateUtils.convertLocalDateToServer(copy.level0Date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.level1Date = DateUtils.convertLocalDateToServer(copy.level1Date);
                    copy.level2Date = DateUtils.convertLocalDateToServer(copy.level2Date);
                    copy.level3Date = DateUtils.convertLocalDateToServer(copy.level3Date);
                    copy.level4Date = DateUtils.convertLocalDateToServer(copy.level4Date);
                    copy.level5Date = DateUtils.convertLocalDateToServer(copy.level5Date);
                    copy.level6Date = DateUtils.convertLocalDateToServer(copy.level6Date);
                    copy.level7Date = DateUtils.convertLocalDateToServer(copy.level7Date);
                    copy.level8Date = DateUtils.convertLocalDateToServer(copy.level8Date);
                    copy.level9Date = DateUtils.convertLocalDateToServer(copy.level9Date);
                    copy.level10Date = DateUtils.convertLocalDateToServer(copy.level10Date);
                    copy.level0Date = DateUtils.convertLocalDateToServer(copy.level0Date);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
