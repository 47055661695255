(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DeptHodServiceDashboardController', DeptHodServiceDashboardController);

    DeptHodServiceDashboardController.$inject = ['$scope','$filter', 'Principal','DeptHodServiceDashboard','$location'];

    function DeptHodServiceDashboardController ($scope,$filter, Principal, DeptHodServiceDashboard,$location) {
        var vm = this;
        vm.account = null;

        loadAll();
        function loadAll() {
        Principal.identity().then(function(account) {
            vm.account = account;
            ga('set', 'page', $location.path());
            ga('send','pageview');
        });
        DeptHodServiceDashboard.query(function(result) {
          vm.deptservicedashboard = result;
          vm.totalappliedservices=0;
          vm.totalrequiredservices=0;
          vm.totalpendingservices=0;
          vm.totalcompletedservices=0;
          vm.totalrejectedservices=0;
           for(var i=0;i<vm.deptservicedashboard.length;i++)
           {
             vm.totalappliedservices=vm.totalappliedservices + parseInt(vm.deptservicedashboard[i].applied);
             vm.totalrequiredservices=vm.totalrequiredservices+ parseInt(vm.deptservicedashboard[i].required);
             vm.totalpendingservices=vm.totalpendingservices+ parseInt(vm.deptservicedashboard[i].pending_total);
             vm.totalcompletedservices=vm.totalcompletedservices+ parseInt(vm.deptservicedashboard[i].cleared);
             vm.totalrejectedservices=vm.totalrejectedservices+ parseInt(vm.deptservicedashboard[i].rejected);
           }
           vm.searchQuery = null;
         });
        }
    }
})();
