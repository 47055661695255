(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('IncentiveWizardProps', IncentiveWizardProps);

    IncentiveWizardProps.$inject = ['$resource'];

    function IncentiveWizardProps ($resource) {
        var resourceUrl =  'api/incentive-wizard-rules/props/load';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
