(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Visitor_countDialogController', Visitor_countDialogController);

    Visitor_countDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Visitor_count'];

    function Visitor_countDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Visitor_count) {
        var vm = this;

        vm.visitor_count = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.visitor_count.id !== null) {
                Visitor_count.update(vm.visitor_count, onSaveSuccess, onSaveError);
            } else {
                Visitor_count.save(vm.visitor_count, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:visitor_countUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
