(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('BrapChecklistDialogController', BrapChecklistDialogController);

    BrapChecklistDialogController.$inject = ['$uibModalInstance', 'entity', 'BrapChecklist', 'FileManagement'];

    function BrapChecklistDialogController($uibModalInstance, entity, BrapChecklist, FileManagement) {
        var vm = this;

        vm.brapChecklist = entity;
        vm.isSaving = false;
        vm.save = save;
        vm.addfileattachment = addfileattachment;
        vm.submitForm = submitForm;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function submitForm() {
            vm.isSaving = true;
            save().then(function(result) {
                return addfileattachment(result);
            }).then(function(updatedResult) {
                $uibModalInstance.close(updatedResult);
            }).catch(function(error) {
                console.error('Error in form submission', error);
                vm.isSaving = false;
            });
        }

        function save() {
            return new Promise(function(resolve, reject) {
                if (vm.brapChecklist.id !== null) {
                    BrapChecklist.update(vm.brapChecklist, function(result) {
                        resolve(result);
                    }, function(error) {
                        reject(error);
                    });
                } else {
                    BrapChecklist.save(vm.brapChecklist, function(result) {
                        resolve(result);
                    }, function(error) {
                        reject(error);
                    });
                }
            });
        }

        function addfileattachment(result) {
            return new Promise(function(resolve, reject) {
                var fileInput = document.getElementById('field_document');
                var file = fileInput.files[0];
                if (file) {
                    var filename = result.id ;
                    FileManagement.saveFileForBrapCheckList(file, filename, function() {
                        result.documentUrl = filename;
                        BrapChecklist.update(result, function(updatedResult) {
                            resolve(updatedResult);
                        }, function(error) {
                            reject(error);
                        });
                    }, function(error) {
                        reject(error);
                    });
                } else {
                    resolve(result);
                }
            });
        }
    }
})();
