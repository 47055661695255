(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('commentDialogController', commentDialogController);

    commentDialogController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','projectService','projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLogUpdated','FileManagement','Projectservicedetail','CheckContent'];
    function commentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, projectService, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, FileManagement, Projectservicedetail,CheckContent) {
        var vm = this;
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectService = projectService;
        var projectAttachmentResultObject=null;
        var projectServiceLogid=null;
        $scope.file = {};
        vm.clear = clear;
        vm.saveComments = saveComments;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function saveComments() {
            vm.projectServiceLog.projectid = vm.projectService.projectid;
            vm.projectServiceLog.serviceid = vm.projectService.serviceid;
            vm.projectServiceLog.actionTaken = "OpenComment";
            if(angular.isDefined(vm.projectAttachemnt.file)){
                var fileNameArray = vm.projectAttachemnt.file.name.split(".");
                if ((fileNameArray.length == 2)) {
                    if(fileNameArray[0] != ""){
                        ProjectServiceLogUpdated.save(vm.projectServiceLog, onServiceLogSaveSuccess, onServiceLogSaveError);

                    }
                    else {
                        alert("Invalid File Name");
                    }
                }
                else{
                    alert("Please select correct file format")
                }
            }
            else {
                ProjectServiceLogUpdated.save(vm.projectServiceLog, onServiceLogSaveSuccess, onServiceLogSaveError);

            }

        }
        function onServiceLogSaveSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments=vm.projectServiceLog.comments;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
        }
        function onUpdateProjectServiceSuccess(result)
        {
            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }
        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }
        function saveProjectAttachment()
        {
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Open Comments";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }
        function onSaveProjectAttachmentSuccess(result)
        {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }

            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }
        function onSaveProjectAttachmentError()
        {
//            alert('project attachment not saved');
            vm.isSaving = false;
        }

        function onUpdateProjectAttachmentSuccess(result)
        {
//            alert('file attachment updated');
            $uibModalInstance.close(result);
        }
        function onUpdateProjectAttachmentError()
        {
//            alert('file attachment not updated');
            vm.isSaving = false;
        }
        $('input').each(function(){ CheckContent.check($(this))});
        $('textarea').each(function() { CheckContent.check($(this))});

    }
})();
