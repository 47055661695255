(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('HepcRecruitmentExperienceByRefId', HepcRecruitmentExperienceByRefId);

    HepcRecruitmentExperienceByRefId.$inject = ['$resource'];

    function HepcRecruitmentExperienceByRefId ($resource) {
        var resourceUrl =  'api/hepc-recruitment-experience/experience';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
