(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('below-ground', {
            parent: 'entity',
            url: '/below-ground',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.below_ground.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/below-ground/below-grounds.html',
                    controller: 'Below_groundController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('below_ground');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('below-ground-detail', {
            parent: 'below-ground',
            url: '/below-ground/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.below_ground.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/below-ground/below-ground-detail.html',
                    controller: 'Below_groundDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('below_ground');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Below_ground', function($stateParams, Below_ground) {
                    return Below_ground.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'below-ground',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('below-ground-detail.edit', {
            parent: 'below-ground-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/below-ground/below-ground-dialog.html',
                    controller: 'Below_groundDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Below_ground', function(Below_ground) {
                            return Below_ground.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('below-ground.new', {
            parent: 'below-ground',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/below-ground/below-ground-dialog.html',
                    controller: 'Below_groundDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                reg_add: null,
                                har_office_add: null,
                                name_auth: null,
                                designation_auth: null,
                                phone_auth: null,
                                email: null,
                                lenth: null,
                                route: null,
                                nature: null,
                                methodology: null,
                                address1: null,
                                district: null,
                                zone: null,
                                details1: null,
                                details2: null,
                                other_info1: null,
                                other_info2: null,
                                other_info3: null,
                                other_info4: null,
                                other_info5: null,
                                other_info6: null,
                                other_info7: null,
                                address2: null,
                                document1: null,
                                document2: null,
                                document3: null,
                                document4: null,
                                document5: null,
                                document6: null,
                                document7: null,
                                document8: null,
                                isfullyfilled: null,
                                status: null,
                                isforwardedtodio: null,
                                isforwardedtodept: null,
                                isforwardedtodc: null,
                                clearancefile1: null,
                                clearancefile2: null,
                                clearancefile3: null,
                                created_by: null,
                                created_on: null,
                                isforwarded: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('below-ground', null, { reload: 'below-ground' });
                }, function() {
                    $state.go('below-ground');
                });
            }]
        })
        .state('below-ground.edit', {
            parent: 'below-ground',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/below-ground/below-ground-dialog.html',
                    controller: 'Below_groundDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Below_ground', function(Below_ground) {
                            return Below_ground.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('below-ground', null, { reload: 'below-ground' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('below-ground.delete', {
            parent: 'below-ground',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/below-ground/below-ground-delete-dialog.html',
                    controller: 'Below_groundDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Below_ground', function(Below_ground) {
                            return Below_ground.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('below-ground', null, { reload: 'below-ground' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
