(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SearchCAFController', SearchCAFController);

    SearchCAFController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'SearchCAF', '$location'];

    function SearchCAFController ($scope, Principal, LoginService, $state, SearchCAF, $location) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.getProject = getProject;
        vm.project={};
        vm.showtable=false;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
            ga('set', 'page', $location.path());
            ga('send','pageview');
        });


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
        function getProject()
        {
            vm.project=null;
            vm.showtable=false;
            SearchCAF.get({CAFPIN:vm.CAFPIN},function(result){
                vm.project = result;
                if(vm.project.caftype === 'FILMCAF'){
                    vm.project.caftype = 'Film'
                }
                if(vm.project.caftype === 'LandExchange'){
                    vm.project.caftype = 'Land'
                }
                if(vm.project.caftype === 'Renewable'){
                    vm.project.caftype = 'Renew'
                }
                if(!(angular.isDefined(vm.project.cafpin)))
                {
                    vm.showtable=false;
                }
                else
                {
                    vm.showtable=true;
                }
        });
        }
    }
})();
