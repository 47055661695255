(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('StateController', StateController);

    StateController.$inject = ['State','$stateParams','Country'];

    function StateController(State, $stateParams, Country) {

        var vm = this;

        vm.states = [];

        loadAll();

        function loadAll() {
            State.query(function(result) {
                vm.states = result;
                vm.searchQuery = null;
            });
        }
    }
})();
