(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerDioProjectsController', TowerDioProjectsController);

    TowerDioProjectsController.$inject = ['$scope','TowerDioProjects', 'Department', '$filter'];

    function TowerDioProjectsController($scope, TowerDioProjects, Department, $filter) {
        var vm = this;
        vm.projects = [];
        vm.status='Pending';
        vm.currentDepartment="All";
        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";


        loadAll();

        function loadAll() {
            TowerDioProjects.get({
                status:vm.status,
                departmentName: vm.currentDepartment,
                page: $scope.currentPage - 1,
                size: $scope.pageSize
            },function (result) {
                vm.projects = result;
            });
            Department.query(function(result){
                vm.departments = $filter('filter')(result,{ istower : true });
            });
        }

        vm.statusSelected=function () {
          loadAll();
        };

        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
        };

        $scope.pageChangedHandler = function(num) {
            loadAll();
        };

    }
})();
