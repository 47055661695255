(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HomeProjectsController', HomeProjectsController);

    HomeProjectsController.$inject = ['$scope', 'Principal','HomeProjects', 'Department', '$filter', 'HomeProjectServiceDetails', 'NewHomeProjectServiceDetails'];

    function HomeProjectsController($scope, Principal, HomeProjects, Department, $filter, HomeProjectServiceDetails, NewHomeProjectServiceDetails) {
        var vm = this;
        vm.projects = [];
        vm.status='Pending';
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                loadAll();
            });
        }

        function loadAll() {
            if(vm.account.authorities[0] === 'ROLE_HOME_CTM') {
                if (vm.status === 'Pending') {
                    HomeProjects.query({status:'Pending'},function (result) {
                        vm.projects = result;
                    });
                } else {
                    HomeProjects.query({status: 'Reverted to CTM'},function (result) {
                        vm.projects = result;
                    });
                }
            } else if (vm.account.authorities[0] === 'ROLE_HOME_SUPERINTENDENT') {
                if (vm.status === 'Pending') {
                    HomeProjects.query({status: 'Marked to Superintendent'},function (result) {
                        vm.projects = result;
                    });
                } else {
                    HomeProjects.query({status: 'Reverted to Superintendent'},function (result) {
                        vm.projects = result;
                    });
                }
            } else if (vm.account.authorities[0] === 'ROLE_HOME_ASSISTANT') {
                if (vm.status === 'Pending') {
                    HomeProjects.query({status: 'Marked to Assistant'},function (result) {
                        vm.projects = result;
                    });
                } else {
                    HomeProjects.query({status: 'Approved'},function (result) {
                        vm.projects = result;
                    });
                }
            } else if (vm.account.authorities[0] === 'ROLE_HOME_DC') {
                HomeProjects.query({status: 'Mark to DC'},function (result) {
                    vm.projects = result;
                });
            }
            // else if (vm.account.authorities[0] === 'ROLE_HOME_ASSISTANT') {
            //     HomeProjects.query({status: 'Approved'},function (result) {
            //         vm.projects = result;
            //     });
            // }
        }

        vm.mark = function(project, status, index) {
            project.status = status;
            project.lastComments = status;
            NewHomeProjectServiceDetails.update(project,function () {
                vm.projects.splice(index, 1);
            });
        };

        vm.statusSelected=function () {
            loadAll();
        };
    }
})();
