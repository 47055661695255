(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Tower_service_dashboardController', Tower_service_dashboardController);

    Tower_service_dashboardController.$inject = ['Tower_service_dashboard'];

    function Tower_service_dashboardController(Tower_service_dashboard) {

        var vm = this;

        vm.tower_service_dashboards = [];

        loadAll();

        function loadAll() {
            Tower_service_dashboard.query(function(result) {
                vm.tower_service_dashboards = result;
                vm.searchQuery = null;
            });
        }
    }
})();
