(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('AAIFillFormController', AAIFillFormController);

   AAIFillFormController.$inject = ['$http', '$timeout','$scope','$stateParams','$uibModalInstance','projectService','projectServiceFormFieldData','projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLogUpdated','FileManagement','Projectservicedetail','Projectserviceformfielddata','serviceFormFieldDataCollection','ServiceFormAttachments','DepartmentService','Serviceformdocs','downloadserviceformattachment'];
   function AAIFillFormController ($http, $timeout, $scope, $stateParams, $uibModalInstance,projectService,projectServiceFormFieldData, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, FileManagement, Projectservicedetail, Projectserviceformfielddata,serviceFormFieldDataCollection,ServiceFormAttachments,DepartmentService,Serviceformdocs,downloadserviceformattachment) {
        var vm = this;
        var AAIObject = {};
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectServiceFormFieldData=projectServiceFormFieldData;
        vm.projectService = projectService;
        vm.uploadAAIFiles = uploadAAIFiles;
        var eventname;
        var projectAttachmentResultObject=null;
        var projectServiceLogid=null;
        var formfielddataObjectArrayLength = 0;
        var formfieldCount = 0;
        var saveLogFlag = false;
        vm.clear = clear;
        vm.serviceformattachment = {};
        vm.saveFormFieldData = saveFormFieldData;
        vm.downloadFile=downloadFile;

        vm.aaiObject = {};

        $scope.file = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        function clear()
        {
             $uibModalInstance.dismiss('cancel');
        }

        vm.departmentservice= DepartmentService.get({id:vm.projectService.serviceid});
        Serviceformdocs.query({id:"1",serviceid:vm.projectService.serviceid},function(result) {
            vm.serviceformdocs = result;
            vm.searchQuery = null;
        });
        loadServiceFormFields();
        function loadServiceFormFields() {
            vm.serviceFormFieldData = serviceFormFieldDataCollection.query({serviceid:vm.projectService.serviceid, projectid:vm.projectService.projectid});
        }
        var combineFilledForm="";
        function saveFormFieldData()
        {
            var vmlength = vm.serviceFormFieldData.length;
            for(var i= 0; i<vmlength;i++) {
                var order = vm.serviceFormFieldData[i].formfieldOrder;
                if ( order == '20' || order == '21' || order == '22' || order == '23' || order == '24' || order == '25' || order == '26' || order == '27'){
                    $scope.strArray = vm.serviceFormFieldData[i].formfieldvalue.split(" ");
                    console.log($scope.strArray);
                    $scope.strArray = $scope.strArray.filter(function(e) {
                        return String(e).trim();
                    });
                    if($scope.strArray.length === 3){
                        for(var j = 0; j < $scope.strArray.length; j++){
                            if (!$scope.strArray[j].trim().match(/^[.0-9]+$/)){
                                alert("wrong coordinates format");
                                vm.isSaving = false;
                                return;
                            }
                        }
                    }else {
                        alert("fill coordinates correctly!!");
                        vm.isSaving = false;
                        return;
                    }
                }else if( order == '15' && !vm.serviceFormFieldData[i].formfieldvalue.match(/^[.0-9]+$/) ){
                    alert("Size of plot must be a number.");
                    vm.isSaving = false;
                    return;
                }else if( order == '28' && !vm.serviceFormFieldData[i].formfieldvalue.match(/^[.0-9]+$/) ){
                    alert("Site elevation must be a number.");
                    vm.isSaving = false;
                    return;
                }else if( order == '29' && !vm.serviceFormFieldData[i].formfieldvalue.match(/^[.0-9]+$/) ){
                    alert("Building height must be a number.");
                    vm.isSaving = false;
                    return;
                }
            }
            if( vm.undertaking1aUploaded != null && vm.elevationUploaded != null && vm.coordinatesUploaded != null && vm.authorizationUploaded != null && vm.permissionUploaded != null ){
                var isConfirmed = confirm("Alert!!! This is final submission. You can not edit the details further.");
                if (isConfirmed){
                    vm.isSaving = true;
                    // var vmlength = vm.serviceFormFieldData.length;
                    formfielddataObjectArrayLength = vmlength;
                    for(var i= 0; i<vmlength;i++)
                    {
                        var data = vm.serviceFormFieldData[i];
                        combineFilledForm=combineFilledForm + vm.serviceFormFieldData[i].formfieldvalue+" | ";
                        data.projectid = vm.projectService.projectid;
                        if (data.id !== null)
                        {
                            Projectserviceformfielddata.update(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
                        } else
                        {
                            Projectserviceformfielddata.save(data, onSaveServiceFormFieldDataSuccess, onSaveServiceFormFieldDataError);
                        }
                        formfieldCount = i;
                    }
                    for( var j = 0; j < vm.fileattach.length; j++ ) {
                        if(vm.fileattach[j].isSaved===false)
                        {
                            vm.fileattach[j].isSaved=true;
                        }
                        ServiceFormAttachments.update(vm.fileattach[j],onUpdatefieldServiceFormAttachmentsSuccess);
                    }
                    if((vm.projectService.serviceid == '16b4d378-5723-4adc-85e9-de55da296008') || ( vm.projectService.serviceid == '48726760-540c-4890-b649-e89dc5ff314e' )){
                        vm.createAAIObject(vm.serviceFormFieldData,vm.projectService.id);
                    }
                }else {
                    vm.isSaving = false;
                    return;
                }
            }else{
                alert("Please upload all files!!");
                vm.isSaving = false;
                return;
            }
        }

        function onUpdatefieldServiceFormAttachmentsSuccess()
        {}
        vm.fileattach = ServiceFormAttachments.query({id:"1", projectid:vm.projectService.projectid, serviceid:vm.projectService.serviceid});
        vm.addfileattachment=addfileattachment;

        function addfileattachment(documenttype)
        {
            eventname = documenttype;
            var fileNameArray = vm.serviceformfile.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=vm.serviceformattachment.description;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            vm.serviceformattachment.isSaved=false;
            vm.serviceformattachment.downloadfilename=inputFileName;
            vm.serviceformattachment.clientfilename=vm.serviceformfile.name;
            vm.serviceformattachment.projectID=vm.projectService.projectid;
            vm.serviceformattachment.serviceID=vm.projectService.serviceid;
            ServiceFormAttachments.save(vm.serviceformattachment,onSaveServiceFormAttachmentsSuccess);

        }



        function onSaveServiceFormAttachmentsSuccess (result)
        {

            var filename = result.id;
            var file =vm.serviceformfile;
            FileManagement.saveFile(file,filename);
            var fileNameArray = vm.serviceformfile.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            result.serverfilename=inputFileName;
            eventname(result);
            ServiceFormAttachments.update(result,onUpdateServiceFormAttachmentsSuccess);
        }
        function onUpdateServiceFormAttachmentsSuccess(result)
        {

            vm.fileattach.push(result);
            vm.fileattachment={};
            vm.serviceformattachment.description="";
            $('#browsefile').val('');

        }

        vm.removeRow = function(id,documenttype){
            vm.removeRawRow(id,function(){
                switch(documenttype){
                    case 'undertaking1auploaded':
                        vm.undertaking1aUploaded = null;
                        break;
                    case 'elevationuploaded':
                        vm.elevationUploaded = null;
                        break;
                    case 'coordinatesuploaded':
                        vm.coordinatesUploaded = null;
                        break;
                    case 'authorizationuploaded':
                        vm.authorizationUploaded = null;
                        break;
                    case 'permissionuploaded':
                        vm.permissionUploaded = null;
                        break;
                }
            });
        }

        vm.removeRawRow = function(id,callBack)
        {
             ServiceFormAttachments.delete({id: id});
             var index = -1;
             var comArray =eval(vm.fileattach);
             for( var i = 0; i < comArray.length; i++ )
             {
                 if( comArray[i].id=== id)
                 {
                     index = i;
                     break;
                 }
             }
             vm.fileattach.splice( index, 1 );
             callBack();
        }
        function onSaveServiceFormFieldDataSuccess(result)
        {
            if(!saveLogFlag)
            {
                if(formfieldCount === (formfielddataObjectArrayLength-1))
                {
                    saveProjectServiceLog();
                    saveLogFlag = true;
                }
            }
        }
        function onSaveServiceFormFieldDataError()
        {}
        function saveProjectServiceLog(result){
            $scope.$emit('investhryApp:projectserviceformfielddataUpdate', result);
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.comments=vm.projectServiceLog.comments + " | " +combineFilledForm;
            vm.projectServiceLog.actionTaken="ServiceFormEdited";
            ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);
        }
        function onServiceLogSaveSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments="Service form edited";
            vm.projectService.formFilledStatus=true;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
        }
        function onUpdateProjectServiceSuccess(result)
        {
            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }
        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }
        function saveProjectAttachment()
        {
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Service Form Field Data Attachment";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }
        function onSaveProjectAttachmentSuccess(result)
        {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }
        function onSaveProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function onUpdateProjectAttachmentSuccess(result)
        {

            $uibModalInstance.close(result);
        }

        function onUpdateProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function downloadFile(serverfilename,downloadfilename)
        {
            downloadserviceformattachment.download(serverfilename,downloadfilename)
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

        function uploadAAIFiles(documenttype){
            switch (documenttype) {

                            case 'undertaking1a':
                                vm.serviceformfile = vm.undertaking1a;
                                vm.serviceformattachment.description = documenttype;
                                addfileattachment(function (result) {
                                    vm.undertaking1aUploaded = result;
                                });
                                vm.undertaking1a = null;
                                $('#undertaking1a').val('');
                                break;

                            case 'elevation':
                                vm.serviceformfile = vm.elevation;
                                vm.serviceformattachment.description = documenttype;
                                addfileattachment(function (result) {
                                    vm.elevationUploaded = result;
                                });
                                vm.elevation = null;
                                $('#elevation').val('');
                                break;

                            case 'coordinates':
                                vm.serviceformattachment.description = documenttype;
                                vm.serviceformfile = vm.coordinates;
                                addfileattachment(function (result) {
                                        vm.coordinatesUploaded = result;
                                    });
                                vm.coordinates = null;
                                $('#coordinates').val('');
                                break;


                            case 'authorization':
                                vm.serviceformfile = vm.authorization;
                                vm.serviceformattachment.description = documenttype;
                                addfileattachment(function (result) {
                                        vm.authorizationUploaded = result
                                    });
                                vm.authorization = null;
                                $('#authorization').val('');
                                break;

                            case 'permission':
                                vm.serviceformfile = vm.permission;
                                vm.serviceformattachment.description = documenttype;
                                addfileattachment(function (result) {
                                        vm.permissionUploaded = result
                                    });
                                vm.permission = null;
                                $('#permission').val('');
                                break;
                        }
        }

        vm.createAAIObject = function (formfielddata,uniqueId) {
            AAIObject.uniqueId = uniqueId;
            for (var i = 0; i < formfielddata.length; i++){
                switch (formfielddata[i].formfieldName){
                    case'Height Clearance For':
                        AAIObject.heightClearanceFor=formfielddata[i].formfieldvalue;
                        break;
                    case'Name of Applicant*':
                        AAIObject.nameOfApplicant=formfielddata[i].formfieldvalue;
                        break;
                    case'Address of Applicant*':
                        AAIObject.addressOfApplicant=formfielddata[i].formfieldvalue;
                        break;
                    case'Mobile No*':
                        AAIObject.mobileNumber=formfielddata[i].formfieldvalue;
                        break;
                    case'Email Id*':
                        AAIObject.emailId=formfielddata[i].formfieldvalue;
                        break;
                    case'Name of Owner/Owners*':
                        AAIObject.nameOfOwner=formfielddata[i].formfieldvalue;
                        break;
                    case'Address of Communication*':
                        AAIObject.addressOfCommunication=formfielddata[i].formfieldvalue;
                        break;
                    case'Type of Structure*':
                        AAIObject.typeOfStructure=formfielddata[i].formfieldvalue;
                        break;
                    case'Uses/Purpose of Structure*':
                        AAIObject.useOfStructure=formfielddata[i].formfieldvalue;
                        break;
                    case'Site Address–Site Name & No, Plot No. (Max 500 Characters)*':
                        AAIObject.siteDescription=formfielddata[i].formfieldvalue;
                        break;
                    case'Size Of Plot(sq.mtrs)*':
                        AAIObject.sizeOfPlot=formfielddata[i].formfieldvalue;
                        break;
                    case'State*':
                        AAIObject.state=formfielddata[i].formfieldvalue;
                        break;
                    case'City*':
                        AAIObject.city=formfielddata[i].formfieldvalue;
                        break;
                    case'Area name – Taluk / Village / Town Name*':
                        AAIObject.areaName=formfielddata[i].formfieldvalue;
                        break;
                    case'Latitude 1*':
                        AAIObject.latitude1 = formfielddata[i].formfieldvalue;
                        break;
                    case'Latitude 2*':
                        AAIObject.latitude2 = formfielddata[i].formfieldvalue;
                        break;
                    case'Latitude 3*':
                        AAIObject.latitude3 = formfielddata[i].formfieldvalue;
                        break;
                    case'Latitude 4*':
                        AAIObject.latitude4 = formfielddata[i].formfieldvalue;
                        break;
                    case'Longitude 1*':
                        AAIObject.longitude1 = formfielddata[i].formfieldvalue;
                        break;
                    case'Longitude 2*':
                        AAIObject.longitude2 = formfielddata[i].formfieldvalue;
                        break;
                    case'Longitude 3*':
                        AAIObject.longitude3 = formfielddata[i].formfieldvalue;
                        break;
                    case'Longitude 4*':
                        AAIObject.longitude4 = formfielddata[i].formfieldvalue;
                        break;
                    case'SiteElevation (AMSL)(mtr)*':
                        AAIObject.siteElevation=formfielddata[i].formfieldvalue;
                        break;
                    case'Building Height (AGL)(mtr)*':
                        AAIObject.buildingHeight=formfielddata[i].formfieldvalue;
                        break;
                    case'Max Site Elevation (AMSL)(mtr)*':
                        AAIObject.maxSiteElevation=formfielddata[i].formfieldvalue;
                        break;
                    case'Do the above Coordinates plotted on the map correlate to your site/plot ?*':
                        AAIObject.isCorrelated = formfielddata[i].formfieldvalue;
                        break;
                    case'Does the site lies within the airport premises/Within the land belonging to Airport Operator*':
                        AAIObject.isWithinPremesis=formfielddata[i].formfieldvalue;
                        break;
                    case'Have you obtained permission from Airport Authority*':
                        AAIObject.hasPermission=formfielddata[i].formfieldvalue;
                        break;
                    }
                };
                if(vm.undertaking1aUploaded != undefined){
                    AAIObject.undertaking1aUrl = vm.undertaking1aUploaded.serverfilename;
                }
                if(vm.elevationUploaded != undefined){
                    AAIObject.elevationUrl = vm.elevationUploaded.serverfilename;
                }
                if(vm.coordinatesUploaded != undefined){
                    AAIObject.coordinatesUrl = vm.coordinatesUploaded.serverfilename;
                }
                if(vm.authorizationUploaded != undefined){
                    AAIObject.authorizationUrl = vm.authorizationUploaded.serverfilename;
                }
                if(vm.permissionUploaded != undefined){
                    AAIObject.permissionUrl = vm.permissionUploaded.serverfilename;
                }

                console.log(AAIObject);
                $http.post('/api/aai',AAIObject).then(function () {
                    console.log('success');
                    return AAIObject;
                });
            }
    }
})();
