(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NmatokenController', NmatokenController);

    NmatokenController.$inject = ['Nmatoken'];

    function NmatokenController(Nmatoken) {

        var vm = this;

        vm.nmatokens = [];

        loadAll();

        function loadAll() {
            Nmatoken.query(function(result) {
                vm.nmatokens = result;
                vm.searchQuery = null;
            });
        }
    }
})();
