(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('showprojectsTowerofficer', {
        parent: 'app',
        url: '/showprojectsTowerofficer',
        data: {
            authorities: [],
            pageTitle: 'global.menu.showprojectsTowerofficer'
        },
        views: {
            'content@': {
                templateUrl: 'app/showprojects-towerofficer/showprojectsTowerofficer.html',
                controller: 'ShowprojectsTowerofficerController',
                controllerAs: 'vm'
            }
        },
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('showprojectsTowerofficer');
                return $translate.refresh();
            }]
        }
    })
        .state('towerofficer-action', {
            parent: 'showprojectsTowerofficer',
            url: '/action/{action}/{projectserviceid}',
            data: {
                authorities: ['ROLE_TOWER_OFFICER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/showprojects-towerofficer/projects-dialog.html',
                    controller: 'TowerOfficerProjectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectAttachemnt: function () {
                            return {
                                fileName: null,
                                description: null,
                                fileExtension: null,
                                serverFileName: null,
                                id: null
                            };
                        },
                        projectServiceLog: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                comments: null,
                                commentDate: null,
                                commentByUserLogin: null,
                                actionTaken: null,
                                id: null
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }],
                        projectService: ['Projectservicedetail', function(Projectservicedetail) {
                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                        }]
//                            projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
//                                return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
//                            }]
                    }
                }).result.then(function() {
                    $state.go('showprojectsTowerofficer', null, { reload: 'showprojectsTowerofficer' });
                }, function() {
                    $state.go('showprojectsTowerofficer');
                });
            }]
        })
            .state('towerofficer-clarification-dialog', {
                parent: 'showprojectsTowerofficer',
                url: 'towerofficer-clarification/{projectserviceid}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_ACCOUNT_OFFICER','ROLE_NODAL_OFFICER','ROLE_USER','ROLE_FIELD_OFFICER','ROLE_TOWER_OFFICER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/showprojects-towerofficer/projects-dialog.html',
                        controller: 'TowerOfficerProjectDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            projectAttachemnt: function () {
                                return {
                                    fileName: null,
                                    description: null,
                                    fileExtension: null,
                                    serverFileName: null,
                                    id: null
                                };
                            },
                            projectServiceLog: function () {
                                return {
                                    projectid: null,
                                    serviceid: null,
                                    comments: null,
                                    commentDate: null,
                                    commentByUserLogin: null,
                                    actionTaken: null,
                                    id: null
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                $translatePartialLoader.addPart('home');
                                return $translate.refresh();
                            }],
                            projectService: ['Projectservicedetail', function(Projectservicedetail) {
                                return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                            }]
                            //                            projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
                            //                                return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
                            //                            }]
                        }
                    }).result.then(function() {
                        $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                    }, function() {
                        $state.go('serviceclearanceaction');
                    });
                }]
            })
        .state('towerofficer-action-verifyOtherPayment', {
                    parent: 'showprojectsTowerofficer',
                    url: '/towerofficer-action-verifyOtherPayment/{projectserviceid}',
                    data: {
                        authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_ACCOUNT_OFFICER','ROLE_NODAL_OFFICER','ROLE_USER','ROLE_FIELD_OFFICER','ROLE_TOWER_OFFICER']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/serviceclearanceactions/verifyOtherPayment.html',
                            controller: 'verifyOtherPaymentController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                projectServiceLog: function () {
                                      return {
                                          projectid: null,
                                          serviceid: null,
                                          comments: null,
                                          commentDate: null,
                                          commentByUserLogin: null,
                                          actionTaken: null,
                                          id: null
                                      };
                                  },
                                  projectService: ['Projectservicedetail', function(Projectservicedetail) {
                                       return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                                  }],
                                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('home');
                                    return $translate.refresh();
                                }]
                            }
                        }).result.then(function() {
                            $state.go('showprojectsTowerofficer', null, { reload: 'showprojectsTowerofficer' });
                        }, function() {
                            $state.go('showprojectsTowerofficer');
                        });
                    }]
                });
    }
})();
