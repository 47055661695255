(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerRejectionReasonDeleteController',TowerRejectionReasonDeleteController);

    TowerRejectionReasonDeleteController.$inject = ['$uibModalInstance', 'entity', 'TowerRejectionReason'];

    function TowerRejectionReasonDeleteController($uibModalInstance, entity, TowerRejectionReason) {
        var vm = this;

        vm.towerRejectionReason = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TowerRejectionReason.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
