(function () {
    'use strict';
    angular
        .module('investhryApp')
        .factory('ProjectActionTowerDio', ProjectActionTowerDio);

    ProjectActionTowerDio.$inject = ['Projectservicedetail', 'ProjectServiceLogUpdated', 'ProjectServiceReportInfoForClearance', 'ProjectAttachemnt', 'FileManagement','CheckContentFile'];

    function ProjectActionTowerDio(Projectservicedetail, ProjectServiceLogUpdated, ProjectServiceReportInfoForClearance, ProjectAttachemnt, FileManagement,CheckContentFile) {

        function performAction(project, attachment, comments, onsuccess, onerror, actiontaken, description,status) {
            var projectServiceLog = {};
            var projectAttachment = {};
            var projectAttachmentResultObject = null;
            var projectServiceLogid = null;
            var projectService = Projectservicedetail.get({id: project.id}, function () {
                var projectservicereportinfo = ProjectServiceReportInfoForClearance.get({
                    projectid: project.projectid,
                    department: project.departmentName,
                    service: project.serviceName
                }, function () {

                    projectServiceLog.projectid = project.projectid;
                    projectServiceLog.serviceid = project.serviceid;
                    projectServiceLog.comments = comments;
                    projectServiceLog.actionTaken = actiontaken;
                    ProjectServiceLogUpdated.save(projectServiceLog, onServiceLogSaveSuccess, onServiceLogSaveError);

                    function onServiceLogSaveSuccess(result) {
                        projectServiceLogid = result.id;
                        projectService.latestComments = projectServiceLog.comments;
                        projectService.status = status;
                        Projectservicedetail.update(projectService, onUpdateProjectServiceSuccess, onUpdateProjectServiceError);
                    }

                    function onUpdateProjectServiceSuccess(result) {
                        projectservicereportinfo.status = status;
                        ProjectServiceReportInfoForClearance.update(projectservicereportinfo, onUpdateProjectServiceReportInfoSuccess, onUpdateProjectServiceReportInfoError);
                    }

                    function onUpdateProjectServiceReportInfoSuccess(result) {
                        if (angular.isDefined(attachment.file)) {
                            saveProjectAttachment();
                        }
                        else {
                            onsuccess(result);
                        }
                    }

                    function onUpdateProjectServiceError() {
                        onerror();

                    }

                    function onServiceLogSaveError() {
                        onerror();
                    }

                    function saveProjectAttachment() {
                        var file = attachment.file;
                        projectAttachment.id = projectServiceLogid;
                        projectAttachment.fileName = file.name;
                        projectAttachment.description = description;
                        projectAttachment.fileExtension = file.type;
                        ProjectAttachemnt.update(projectAttachment, onSaveProjectAttachmentSuccess, onSaveProjectAttachmentError);
                    }

                    function onSaveProjectAttachmentSuccess(result) {
                        var filename = result.id;
                        var file = attachment.file;
                        FileManagement.saveFile(file, filename);
                        projectAttachmentResultObject = result;

                        var fileNameArray = attachment.file.name.split(".");
                        var fileextension = "";
                        if ((fileNameArray.length > 1)) {
                            fileextension = fileNameArray[(fileNameArray.length - 1)];
                        }
                        var inputFileName = result.id;
                        if (fileextension !== "") {
                            inputFileName = inputFileName + "." + fileextension;
                        }
                        projectAttachmentResultObject.serverFileName = inputFileName;

                        ProjectAttachemnt.update(projectAttachmentResultObject, onUpdateProjectAttachmentSuccess, onUpdateProjectAttachmentError);
                    }

                    function onSaveProjectAttachmentError() {
                        onerror();
                    }

                    function onUpdateProjectAttachmentSuccess(result) {
                        onsuccess(result);
                    }

                    function onUpdateProjectServiceReportInfoError() {

                        onerror();
                    }

                    function onUpdateProjectAttachmentError() {
                        onerror();
                    }

                });
            });
        }

        return {
            clear: function (project, attachment, comments, onsuccess, onerror) {
                var actiontaken = 'Cleared';
                var status = 'Cleared';
                var description = 'Clearance Attachment';
                if(CheckContentFile.checkFile(attachment.file.name)){
                    performAction(project, attachment, comments, onsuccess, onerror, actiontaken, description,status);
                }
                else {
                    alert("Invalid File");
                }
            },
            reject: function (project, attachment, comments, onsuccess, onerror) {
                var actiontaken = 'Declined';
                var status = 'Declined';
                var description = 'Rejection Attachment';
                if(CheckContentFile.checkFile(attachment.file.name)){
                    performAction(project, attachment, comments, onsuccess, onerror,actiontaken,description,status);
                }
                else {
                    alert("Invalid File");
                }
            },
            deactivate: function (project, attachment, comments, onsuccess, onerror) {
                var actiontaken = 'Rejected';
                var status = 'Rejected';
                var description = 'Rejection Attachment';

                performAction(project, attachment, comments, onsuccess, onerror,actiontaken,description,status);
            },
            reactivate: function (project, attachment, comments, onsuccess, onerror) {
                var actiontaken = 'Re-Activated';
                var status = 'Pending';
                var description = 'Reactivation Attachment';

                performAction(project, attachment, comments, onsuccess, onerror,actiontaken,description,status);
            },
            undoreject: function (project, attachment, comments, onsuccess, onerror) {
                var actiontaken = 'Undo-Rejected';
                var status = 'Pending';
                var description = 'Undo-Rejected Attachment';

                if (angular.isDefined(attachment.file)) {
                    if(CheckContentFile.checkFile(attachment.file.name)){
                        performAction(project, attachment, comments, onsuccess, onerror,actiontaken,description,status);
                    }
                    else{
                        alert("Invalid File");
                    }
                }
                else {
                    performAction(project, attachment, comments, onsuccess, onerror,actiontaken,description,status);
                }

            },
            comment: function (project, attachment, comments, onsuccess, onerror) {
                if (angular.isDefined(attachment.file)) {
                    if(CheckContentFile.checkFile(attachment.file.name)){
                        addComment();
                    }
                    else{
                        alert("Invalid File");
                    }
                }
                else {
                    addComment();
                }

                function addComment(){
                    var projectServiceLog = {};
                    var projectAttachment = {};
                    var projectAttachmentResultObject = null;
                    var projectServiceLogid = null;
                    var projectService = Projectservicedetail.get({id: project.id}, function () {
                        projectServiceLog.projectid = project.projectid;
                        projectServiceLog.serviceid = project.serviceid;
                        projectServiceLog.comments = comments;
                        projectServiceLog.actionTaken = "OpenComment";
                        ProjectServiceLogUpdated.save(projectServiceLog, onServiceLogSaveSuccess, onServiceLogSaveError);

                        function onServiceLogSaveSuccess(result) {
                            projectServiceLogid = result.id;
                            projectService.latestComments = projectServiceLog.comments;
                            Projectservicedetail.update(projectService, onUpdateProjectServiceSuccess, onUpdateProjectServiceError);
                        }

                        function onUpdateProjectServiceSuccess(result) {
                            if (angular.isDefined(attachment.file)) {
                                saveProjectAttachment();
                            }
                            else {
                                onsuccess(result);
                            }
                        }

                        function onUpdateProjectServiceError() {

                        }

                        function onServiceLogSaveError() {
                        }

                        function saveProjectAttachment() {
                            var file = attachment.file;
                            projectAttachment.id = projectServiceLogid;
                            projectAttachment.fileName = file.name;
                            projectAttachment.description = "Clearance Attachment";
                            projectAttachment.fileExtension = file.type;
                            ProjectAttachemnt.update(projectAttachment, onSaveProjectAttachmentSuccess, onSaveProjectAttachmentError);
                        }

                        function onSaveProjectAttachmentSuccess(result) {
                            var filename = result.id;
                            var file = attachment.file;
                            FileManagement.saveFile(file, filename);
                            projectAttachmentResultObject = result;

                            var fileNameArray = attachment.file.name.split(".");
                            var fileextension = "";
                            if ((fileNameArray.length > 1)) {
                                fileextension = fileNameArray[(fileNameArray.length - 1)];
                            }
                            var inputFileName = result.id;
                            if (fileextension !== "") {
                                inputFileName = inputFileName + "." + fileextension;
                            }
                            projectAttachmentResultObject.serverFileName = inputFileName;

                            ProjectAttachemnt.update(projectAttachmentResultObject, onUpdateProjectAttachmentSuccess, onUpdateProjectAttachmentError);
                        }

                        function onSaveProjectAttachmentError() {
                            onerror();
                        }

                        function onUpdateProjectAttachmentSuccess(result) {
                            onsuccess(result);
                        }

                        function onUpdateProjectServiceReportInfoError() {

                            onerror();
                        }

                        function onUpdateProjectAttachmentError() {
                            onerror();
                        }

                    });
                }

            }
        }
    }
})();
