(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DepartmentHodDialogController', DepartmentHodDialogController);

    DepartmentHodDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DepartmentHod','Department'];

    function DepartmentHodDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DepartmentHod,Department) {
        var vm = this;

        vm.departmentHod = entity;
        vm.clear = clear;
        vm.save = save;
        vm.selectedDepartment = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.departmentHod.id !== null) {
                DepartmentHod.update(vm.departmentHod, onSaveSuccess, onSaveError);
            } else {
                DepartmentHod.save(vm.departmentHod, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:departmentHodUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        vm.departments = [];
        loadAllDepartments();
        function loadAllDepartments() {
            Department.query(function(result) {
                vm.departmentstemp = result;
                angular.forEach(vm.departmentstemp, function(value, key){
                    if(value.departmentname!=='Development and Panchayats'){
                        vm.departments.push(value);
                    }
                });
                vm.searchQuery = null;
            });
        }
        vm.onDepartmentChange = function(department){
            console.log(department);
            vm.departmentHod.departmentId = department.id;
            vm.departmentHod.departmentName = department.departmentname;
        }

    }
})();
