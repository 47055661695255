(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('clarificationDialogController', clarificationDialogController);

    clarificationDialogController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','projectService', 'projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLogUpdated','FileManagement','Projectservicedetail','ProjectServiceReportInfoForClearance', 'TowerRejectionReason', 'RejectedProjectServiceLog', 'ReasonCategory','TowerRejectionReasonByCategory','ProjectAction'];
    function clarificationDialogController ($timeout, $scope, $stateParams, $uibModalInstance,projectService, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, FileManagement, Projectservicedetail,ProjectServiceReportInfoForClearance, TowerRejectionReason, RejectedProjectServiceLog, ReasonCategory, TowerRejectionReasonByCategory, ProjectAction) {
        var vm = this;
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectService=projectService;
//        vm.projectservicereportinfo = ProjectServiceReportInfoForClearance.get({department:vm.projectService.departmentName,projectid:vm.projectService.projectid,service:vm.projectService.serviceName});
        var projectAttachmentResultObject=null;
        var projectServiceLogid=null;
        $scope.file = {};
        vm.clear = clear;
        // vm.declineService = declineService;

        vm.clarify = clarify;
        vm.rejectedProjectServiceLog = {};
        // vm.getReasons = getReasons;
        //vm.getRejectionReason = getRejectionReason;
        vm.selectedCategory = {};
        vm.role = $stateParams.role;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.rejectionReasons = [];
        vm.reasonCategories = [];

        loadAll();

        function loadAll() {
            ReasonCategory.query(function(result) {
                vm.reasonCategories = result;
                vm.searchQuery = null;
            });
        }



        function clarify(role) {
            ProjectAction.clarificationReply(vm.projectService,vm.projectAttachemnt,vm.comments, role, onsuccess,onerror)
        }



        function onServiceLogSaveSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments=vm.projectServiceLog.comments;
            vm.projectService.status="Withdraw";
            vm.projectService.rejectionDate=result.commentDate;
            vm.rejectedProjectServiceLog.projectServiceLogId = projectServiceLogid;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
            RejectedProjectServiceLog.save(vm.rejectedProjectServiceLog);

        }
//        function onUpdateProjectServiceSuccess(result)
//        {
//            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
//            vm.projectservicereportinfo.status='Declined';
//            ProjectServiceReportInfoForClearance.update(vm.projectservicereportinfo,onUpdateProjectServiceReportInfoSuccess,onUpdateProjectServiceReportInfoError);
//        }
        function onUpdateProjectServiceSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceReportInfoUpdate', result);
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }
        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }
        function saveProjectAttachment()
        {
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Withdraw Attachment";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }
        function onSaveProjectAttachmentSuccess(result)
        {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }
        function onSaveProjectAttachmentError()
        {
            vm.isSaving = false;
        }
        function onUpdateProjectAttachmentSuccess(result)
        {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

//        function onUpdateProjectServiceReportInfoError()
//        {
//            vm.isSaving = false;
//        }
        function onUpdateProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function onsuccess(result) {
            $uibModalInstance.close();
        }

    }
})();
