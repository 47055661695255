(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DraftPolicyController', DraftPolicyController);

    DraftPolicyController.$inject = ['DraftPolicy', 'PolicyDetails'];

    function DraftPolicyController(DraftPolicy, PolicyDetails) {

        var vm = this;

        vm.draftPolicies = [];
        vm.statusSelected = statusSelected;

        loadAll();

        function loadAll() {
            PolicyDetails.query(function(result) {
                vm.policyDetails = result;
                vm.searchQuery = null;
                statusSelected('Pending',result[0].name);
            });
        }

        vm.save = function (data) {
            data.isReplied = true;
            DraftPolicy.update(data,onSaveSuccess,onSaveError)
        };

        function onSaveSuccess() {
            UIkit.modal('#remarkAdmin').hide();
            location.reload();
        }

        function onSaveError() {

        }

        function statusSelected (statusFilterobj,policyFilterobj) {
            vm.statusFilter = statusFilterobj;
            vm.policyFilter = policyFilterobj;
            console.log(statusFilterobj);
            console.log(policyFilterobj);
            DraftPolicy.query({id:'1',status:statusFilterobj,policy:policyFilterobj},function(result) {
                vm.draftPolicies = result;
                vm.searchQuery = null;
            });
        }
    }
})();
