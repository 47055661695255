(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Visitor_countDeleteController',Visitor_countDeleteController);

    Visitor_countDeleteController.$inject = ['$uibModalInstance', 'entity', 'Visitor_count'];

    function Visitor_countDeleteController($uibModalInstance, entity, Visitor_count) {
        var vm = this;

        vm.visitor_count = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Visitor_count.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
