(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CenterTowerUserDataController', CenterTowerUserDataController);

    CenterTowerUserDataController.$inject = ['CenterTowerUserData'];

    function CenterTowerUserDataController(CenterTowerUserData) {

        var vm = this;

        vm.centerTowerUserData = [];

        loadAll();

        function loadAll() {
            CenterTowerUserData.query(function(result) {
                vm.centerTowerUserData = result;
                vm.searchQuery = null;
            });
        }
    }
})();
