(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Tower_dcDeleteController',Tower_dcDeleteController);

    Tower_dcDeleteController.$inject = ['$uibModalInstance', 'entity', 'Tower_dc'];

    function Tower_dcDeleteController($uibModalInstance, entity, Tower_dc) {
        var vm = this;

        vm.tower_dc = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Tower_dc.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
