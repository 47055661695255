(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketsFeedbackController', TicketsFeedbackController);

    TicketsFeedbackController.$inject = ['$state', 'TicketsFeedback'];

    function TicketsFeedbackController($state, TicketsFeedback) {

        var vm = this;
        vm.ticketFeedback={};
        vm.selectedFeedback=selectedFeedback;
        vm.deleteFeedback=deleteFeedback;
        vm.ticketsFeedbacks = [];

        loadAll();

        function loadAll() {
            TicketsFeedback.query(function(result) {
                vm.ticketsFeedbacks = result;
                vm.searchQuery = null;
            });
        }

        function selectedFeedback(tktsFeedback) {
            vm.ticketFeedback = tktsFeedback;
        }

        function deleteFeedback(feedback) {
            TicketsFeedback.delete({id:feedback.id, ticketNumber: feedback.ticketNumber}, function (result) {
                alert("SuccessFully Delete");
                location.reload();
            })
        }
    }
})();
