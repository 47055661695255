(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('cafsfornodalofficer', {
            parent: 'entity',
            url: '/cafsfornodalofficer',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.cafsfornodalofficer.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/cafsfornodalofficer/cafsfornodalofficers.html',
                    controller: 'CafsfornodalofficerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('cafsfornodalofficer');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('cafsfornodalofficer-detail', {
            parent: 'cafsfornodalofficer',
            url: '/cafsfornodalofficer/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.cafsfornodalofficer.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/cafsfornodalofficer/cafsfornodalofficer-detail.html',
                    controller: 'CafsfornodalofficerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('cafsfornodalofficer');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Cafsfornodalofficer', function($stateParams, Cafsfornodalofficer) {
                    return Cafsfornodalofficer.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'cafsfornodalofficer',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('cafsfornodalofficer-detail.edit', {
            parent: 'cafsfornodalofficer-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cafsfornodalofficer/cafsfornodalofficer-dialog.html',
                    controller: 'CafsfornodalofficerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Cafsfornodalofficer', function(Cafsfornodalofficer) {
                            return Cafsfornodalofficer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('cafsfornodalofficer.new', {
            parent: 'cafsfornodalofficer',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cafsfornodalofficer/cafsfornodalofficer-dialog.html',
                    controller: 'CafsfornodalofficerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                serviceName: null,
                                cafpin: null,
                                rmInfo: null,
                                totalProjectCost: null,
                                proposedEmployment: null,
                                district: null,
                                projectLevel: null,
                                applicationDate: null,
                                lastActionDate: null,
                                lastActionComment: null,
                                departmentName: null,
                                status: null,
                                businessEntity: null,
                                projectId: null,
                                formfillstatus: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('cafsfornodalofficer', null, { reload: 'cafsfornodalofficer' });
                }, function() {
                    $state.go('cafsfornodalofficer');
                });
            }]
        })
        .state('cafsfornodalofficer.edit', {
            parent: 'cafsfornodalofficer',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cafsfornodalofficer/cafsfornodalofficer-dialog.html',
                    controller: 'CafsfornodalofficerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Cafsfornodalofficer', function(Cafsfornodalofficer) {
                            return Cafsfornodalofficer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cafsfornodalofficer', null, { reload: 'cafsfornodalofficer' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('cafsfornodalofficer.delete', {
            parent: 'cafsfornodalofficer',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/cafsfornodalofficer/cafsfornodalofficer-delete-dialog.html',
                    controller: 'CafsfornodalofficerDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Cafsfornodalofficer', function(Cafsfornodalofficer) {
                            return Cafsfornodalofficer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cafsfornodalofficer', null, { reload: 'cafsfornodalofficer' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
