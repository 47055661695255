(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DistrictDialogController', DistrictDialogController);

    DistrictDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'District', 'State'];

    function DistrictDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, District, State) {
        var vm = this;

        vm.district = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        load();
               function load()
               {
               vm.id=$stateParams.stateID;
               State.get({id: vm.id},function(result)
               {
               $scope.selected= {id: result.id, statename: result.statename}
               });
               }



         State.query(function(result) {
                   vm.states = result;
                   vm.searchQuery = null;
               });

        function save () {
            vm.isSaving = true;
            vm.district.stateID=$scope.selected.id;
            vm.district.statename=$scope.selected.statename;
            if (vm.district.id !== null) {
                District.update(vm.district, onSaveSuccess, onSaveError);
            } else {
                District.save(vm.district, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:districtUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
