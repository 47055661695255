(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TotalSkillsController', TotalSkillsController);

    TotalSkillsController.$inject = ['ProjectSkills'];

    function TotalSkillsController(ProjectSkills) {

        var vm = this;

        vm.projectSkills = [];

        loadAll();

        function loadAll() {
            ProjectSkills.query({skillsData:'skillsData'},function(result) {
                vm.projectSkills = result;
                console.log(vm.projectSkills);
                vm.searchQuery = null;
            });
        }
    }
})();
