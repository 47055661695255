(function() {
    'use strict';

    angular
    .module('investhryApp')
    .factory('downloadserviceformattachment', ['$q', '$timeout', '$window',
        function($q, $timeout, $window, $http) {
            return {
                download: function(serverfilename,downloadfilename) {
                    var defer = $q.defer();
                    $timeout(function() {
                        $window.location = 'api/downloadserviceformattachment?serverfilename=' + serverfilename +'&downloadfilename='+downloadfilename;
                        }, 1000)
                        .then(function() {
                            defer.resolve('success');
                        }, function() {
                            defer.reject('error');
                        });
                    return defer.promise;
                }
            };
        }
    ]);
})();
