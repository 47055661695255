(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CustomertypeController', CustomertypeController);

    CustomertypeController.$inject = ['Customertype'];

    function CustomertypeController(Customertype) {

        var vm = this;

        vm.customertypes = [];

        loadAll();

        function loadAll() {
            Customertype.query(function(result) {
                vm.customertypes = result;
                vm.searchQuery = null;
            });
        }
    }
})();
