(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('assignProjectToRM', {
            parent: 'app',
            url: '/assignProject',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_ASSIGNRM_OFFICER'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/assignProjectToRM/assignProjectToRM.html',
                    controller: 'assignProjectToRMController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('block');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('assignRM', {
            parent: 'assignProjectToRM',
            url: '/assign/{projectid}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_ASSIGNRM_OFFICER'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/assignProjectToRM/assignRM.html',
                    controller: 'assignRMController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'assignedProjectDetail', function($stateParams, assignedProjectDetail) {
                    return assignedProjectDetail.get({id : $stateParams.projectid}).$promise;
                }],
                project: ['$stateParams', 'Project', function($stateParams, Project) {
                    return Project.get({id : $stateParams.projectid}).$promise;
                }],
                projectLog: function () {
                    return {
                        projectid: null,
                        actiontaken: null,
                        actiontakenby: null,
                        actiontakendate: null,
                        actiontype: null,
                        id: null
                    };
                },
                projectLogNewCaf: function () {
                    return {
                        projectId: null,
                        actionTaken: null,
                        actionTakenBy: null,
                        actionTakenDate: null,
                        actionType: null,
                        fieldUpdated: null,
                        oldValue: null,
                        newValue: null,
                        id: null
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('block');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

    }

})();
