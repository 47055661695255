(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DistrictDeskOfficerController', DistrictDeskOfficerController);

    DistrictDeskOfficerController.$inject = ['DistrictDeskOfficer'];

    function DistrictDeskOfficerController(DistrictDeskOfficer) {

        var vm = this;

        vm.districtDeskOfficers = [];

        loadAll();

        function loadAll() {
            DistrictDeskOfficer.query(function(result) {
                vm.districtDeskOfficers = result;
                vm.searchQuery = null;
            });
        }
    }
})();
