(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CAFwithNoServiceController', CAFwithNoServiceController);

    CAFwithNoServiceController.$inject = ['$scope',  'Principal', 'LoginService',  '$state', 'CafWithNoService','$location'];

    function CAFwithNoServiceController ($scope,  Principal, LoginService, $state, CafWithNoService,$location) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.showtable=false;
        vm.cafWithNoServices = [];

        $scope.$on('authenticationSuccess', function() {
            getAccount();
            ga('set', 'page', $location.path());
            ga('send','pageview');
        });


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }


        loadAll();

        function loadAll() {
            vm.showtable=false;
            CafWithNoService.query(function(result) {
            vm.cafWithNoServices = result;
            vm.searchQuery = null;
            vm.showtable = vm.cafWithNoServices.length !== 0;
            });
        }

        function register () {
            $state.go('register');
        }

    }
})();
