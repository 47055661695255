(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AdminTicketViewController', AdminTicketViewController);

    AdminTicketViewController.$inject = ['Principal', '$window', 'Tickets', 'TicketsLogs', 'downloadticketattachment', 'TicketsPagination'];

    function AdminTicketViewController(Principal, $window, Tickets, TicketsLogs, downloadticketattachment, TicketsPagination) {
        var vm = this;
        vm.tickets = [];
        vm.allTickets = [];
        vm.loadAll=loadAll;
        vm.getLogs=getLogs;
        vm.downloadAttachment = downloadAttachment;
        vm.loadAllTickets = loadAllTickets;
        vm.tickets1 = [];
        vm.ticketList1 = [];
        vm.download_all_table_data = download_all_table_data;


        vm.page = 0;
        loadAll(vm.page);



        function loadAll(page) {
            Principal.identity().then(function(account) {
                vm.account = account;
                    vm.page = page;
                    TicketsPagination.get({page: page, size: 1000}, function (result) {
                        vm.tickets = result.content;
                        vm.allTickets = vm.tickets;
                        vm.searchQuery = null;
                    });
        })
        }
        loadAllTickets();

        function loadAllTickets() {
            Tickets.query(function(result) {
                vm.tickets1 = result;
                vm.ticketList1=vm.tickets1;

            });
        }

        function convertJsonsToArrays(args){
            var dataArray = [];
            var header = [ "Ticket Number",
                "Subject",
                "Category",
                "Department",
                "Assigned Member",
                "Priority",
                "Creation By",
                "Creation On",
                "Closing Date",
                "Ticket Status"
            ];
            dataArray.push(header);
            for( var i = 0; i < args.length; i++ ){
                var row = args[i];

                var rowAr=[];
                rowAr.push(row.ticketNumber);
                rowAr.push(row.subject);
                rowAr.push(row.incidentCategory);
                rowAr.push(row.assignedDepartmentName);
                rowAr.push(row.assignedMemberUserName);
                rowAr.push(row.priority);
                rowAr.push(row.createdByName);
                rowAr.push(row.creationDate);
                rowAr.push(row.resolveDate);
                rowAr.push(row.status);
                dataArray.push(rowAr);
            }
            return dataArray;
        }

        function download_all_table_data(args) {
            var rowsCleared= convertJsonsToArrays(args);
            var lineArray = [];
            var lineArrayCleared = [];


            for(var i = 0; i < rowsCleared.length; i++){
                var lineCleared = rowsCleared[i].join("\t");
                lineArrayCleared.push(lineCleared);
            }
            lineArrayCleared = lineArrayCleared.join("\n");


            var title = [];
            var rows = [];
            title = title.join(",");
            rows = rows.join("\n");
            var csv = title + rows;
            var blob = new Blob([lineArrayCleared],{type: 'data:text/csv;charset=utf-8,'+ encodeURIComponent(csv)});
            var url = URL.createObjectURL(blob);
            $window.location.href = url;

        }



        function getLogs(ticketDetails) {
            TicketsLogs.query({ticketNumber: ticketDetails.ticketNumber},function (result) {
                vm.ticketsLogs=result;
            })
        }

        function downloadAttachment(attachment) {
            downloadticketattachment.download(attachment)
                .then(function(success) {}, function(error) {
                    alert('error : ' + error);
                });
        }
    }
})();
