(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ApplicationLogsDeleteController',ApplicationLogsDeleteController);

    ApplicationLogsDeleteController.$inject = ['$uibModalInstance', 'entity', 'ApplicationLogs'];

    function ApplicationLogsDeleteController($uibModalInstance, entity, ApplicationLogs) {
        var vm = this;

        vm.applicationLogs = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ApplicationLogs.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
