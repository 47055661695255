(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MultipleUserWithoutCafController', MultipleUserWithoutCafController);

    MultipleUserWithoutCafController.$inject = ['MultipleUserWithoutCaf'];

    function MultipleUserWithoutCafController(MultipleUserWithoutCaf) {

        var vm = this;

        vm.multipleUserWithoutCafs = [];

        loadAll();

        function loadAll() {
            MultipleUserWithoutCaf.query(function(result) {
                vm.multipleUserWithoutCafs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
