(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RasCredentialsController', RasCredentialsController);

    RasCredentialsController.$inject = ['RasCredentials'];

    function RasCredentialsController(RasCredentials) {

        var vm = this;

        vm.rasCredentials = [];

        loadAll();

        function loadAll() {
            RasCredentials.query(function(result) {
                vm.rasCredentials = result;
                vm.searchQuery = null;
            });
        }
    }
})();
