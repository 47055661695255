(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('IncentiveWizardController', IncentiveWizardController);

    IncentiveWizardController.$inject = ['$scope', '$state','$filter','districts','sectors','IncentiveBlockMapping','Incentives','$location', 'IncentiveWizardProps'];

    function IncentiveWizardController($scope,$state,$filter,districts,sectors,Block,Incentives,$location, IncentiveWizardProps) {
        var vm = this;
        vm.calculateIncentives=calculateIncentives;
        vm.districts = [];
        vm.projecttype = null;
        vm.total_project_turnover = 0;
        vm.showTurnOverInput = false;
        vm.showServiceTypes = true;
        vm.showIncentives = false;
        vm.selectedSector = null;
        $scope.projecttype = null;
        vm.calculatedProjectType = null;
        vm.isLoadingIncentives = false;
        vm.notIncentivesFound = false;
        vm.incentivesData = null;
        vm.selectedProjectType = null;
        vm.projecttype = 'services';
        vm.isConfirmed=false;
        vm.showStartup = false;
        loadAll();
        vm.isEPPOnly = true;
        angular.forEach(districts, function(value, key){
            if((value.districtname!== 'Chandigarh HQ')){
                vm.districts.push(value);
            }
        });
            function loadAll(){
                       ga('set', 'page', $location.path());ga('send','pageview');
                    }

        vm.sectors=sectors;
        vm.incentives={};
        vm.incentives.projectsector='All';
        vm.result={};
        vm.result.incentives=[];
        vm.result.sectorincentives=[];
        vm.loadBlocksByDistrict=loadBlocksByDistrict;
        vm.sectorSpecificeResult = {};
        vm.eppWithSector = {};
        vm.incentiveResult = vm.incentives;

        function loadBlocksByDistrict() {
            if(vm.incentives.projectdistrict === "Hissar") {
                vm.incentives.district = "Hisar";
            } else {
                vm.incentives.district = vm.incentives.projectdistrict;
            }
            Block.query({districtName: vm.incentives.district},function (result) {
                vm.blocks=result;
            });
        }

        vm.getConfirmation = function(){
            vm.isConfirmed = true;
            if(vm.isConfirmed){
                vm.calculateIncentives();
            }
            vm.showIncentives = true;
        }

        vm.dismiss=function(){
            vm.showIncentives = false;
            $("#incentivesModal").modal('hide');
        }

        function calculateIncentives() {
            ga('set', 'page', "/calculated-incentives");ga('send','pageview');

            vm.calculateProjectType(vm.getProjectType);
            if(vm.selectedTextile != null){
                vm.incentives.projectsector = vm.selectedTextile;
            }else if(vm.selectedAgro != null){
                vm.incentives.projectsector = vm.selectedAgro;
            }
            vm.loadIncentives();
        }

        vm.getProjectType = function() {
            var result=Incentives.getIncentives(vm.result.projectsize,vm.block.blocktype,vm.incentives.projectsector,vm.projecttype);
//            vm.result.incentives=result[0]===undefined?[]:result[0].incentives;
            vm.result.incentives=result[0].incentives;
            var sectorSpecificeResult = Incentives.getSectorSpecificIncentives(vm.incentives.projectsector,vm.block.blocktype);
            vm.sectorSpecificeResult.incentives=sectorSpecificeResult[0]===undefined?[]:sectorSpecificeResult[0].incentives;
            vm.eppWithSector.incentives = clubResults(vm.result.incentives, vm.sectorSpecificeResult.incentives);
        }


        vm.getSelectedSector = function(selectedSector){
            if(selectedSector == 'Agro' || selectedSector == 'Footwear' || selectedSector == 'Textiles'){
                vm.isEPPOnly = false;
            }else{
                vm.isEPPOnly = true;
            }
        }

        vm.loadProjectType = function(selectedProjectType){
            $scope.projecttype = selectedProjectType;
            if(selectedProjectType == 'services'){
                vm.showServiceTypes = true;
                vm.showManufacturingTypes = false;
            }else if(selectedProjectType == 'manufacturing'){
                vm.showServiceTypes = false;
                vm.showManufacturingTypes = true;
            }
        }

        vm.loadBlockType = function(){
            vm.block=$filter('filter')(vm.blocks,{districtName: vm.incentives.district,blockName:vm.incentives.projectblock}, true)[0];
            vm.incentives.blockType = vm.block.blockType;
        };
        function clubResults(eppincentives, sectorIncentives){
            var newItems = [];
            var itemsToBeChanged = [];
            var indexForItemsToBeChanged = [];
            var incentiveIndexesForNewItems = [];
            eppincentives.forEach(function(e1,index){
                for(var i = 0; i < sectorIncentives.length; i++){
                    if(e1.name === sectorIncentives[i].name){
                        indexForItemsToBeChanged.push(index);
                       if(sectorIncentives[i].value1 !== null){
                           e1.value3 = sectorIncentives[i].value1;
                           if(sectorIncentives[i].constraints1 !== null){
                               e1.constraints3 = sectorIncentives[i].constraints1;
                           }
                           if(sectorIncentives[i].value2 !== null){
                               e1.value4 = sectorIncentives[i].value2;
                               if(sectorIncentives[i].constraints2 !== null){
                                   e1.constraints4 = sectorIncentives[i].constraints2;
                               }
                           }
                       }
                       e1.remarks1 = e1.remarks;
                       e1.remarks2 = sectorIncentives[i].remarks;
                       itemsToBeChanged.push(e1);
                    }else if(sectorIncentives[i].name.includes(e1.name)){
                       incentiveIndexesForNewItems.push(index);
                       newItems.push(sectorIncentives[i]);
                    }
                }
            });
            for(var j = 0; j < indexForItemsToBeChanged.length; j++){
                eppincentives[indexForItemsToBeChanged[j]] = itemsToBeChanged[j];
            }
            for(var k = 0; k < incentiveIndexesForNewItems.length; k++){
                eppincentives.splice(incentiveIndexesForNewItems[k],0,newItems.shift());
            }
            return eppincentives;
        }

        vm.calculateProjectType = function(callback){
            if (!vm.incentives.blockType) {
                return 'Ivalid Block type';
            }
            if (vm.incentives.blockType === 'A') {
                if (vm.total_project_cost >= 6000) {
                   return  vm.incentives.projectSize = 'Ultra-Mega';
                } else {
                    return getProjectTypeMappingWithoutBlockType();
                }
            } else if (vm.incentives.blockType === 'B') {
                if (vm.total_project_cost >= 4500) {
                    return  vm.incentives.projectSize = 'Ultra-Mega';
                } else if (vm.total_project_cost < 4500 && vm.total_project_cost >= 200) {
                    return vm.incentives.projectSize = 'Mega';
                } else {
                    return getProjectTypeMappingWithoutBlockType();
                }
            } else if (vm.incentives.blockType === 'C') {
                if (vm.total_project_cost >= 3000) {
                    return  vm.incentives.projectSize = 'Ultra-Mega';
                } else if (vm.total_project_cost < 3000 && vm.total_project_cost >= 100) {
                    return vm.incentives.projectSize = 'Mega';
                } else {
                    return getProjectTypeMappingWithoutBlockType();
                }
            }  else if (vm.incentives.blockType === 'D') {
                if (vm.total_project_cost >= 1500) {
                    return  vm.incentives.projectSize = 'Ultra-Mega';
                } else if (vm.total_project_cost < 1500 && vm.total_project_cost >= 75) {
                    return vm.incentives.projectSize = 'Mega';
                } else {
                    return getProjectTypeMappingWithoutBlockType();
                }
            }
        };

        vm.set_projectTurnover = function() {
            vm.incentives.trunover = vm.total_project_turnover;
            if (vm.total_project_turnover > 0 && vm.total_project_turnover < 50) {
                vm.showStartup = true;
            }

        };

        vm.loadIncentives = function() {
            vm.incentivesData = [];
            vm.notIncentivesFound = false;
            vm.incentivesData = null;
            vm.isLoadingIncentives = true;
            vm.incentiveResult.projectType = vm.incentiveResult.projectSize;
            vm.incentiveResult.businessType = vm.projecttype;
            IncentiveWizardProps.save(vm.incentiveResult, function (result) {
               vm.incentivesData = result;
               if (result.length === 0) {
                   vm.notIncentivesFound = true;
               }
                vm.isLoadingIncentives = false;
            });
        }


        vm.toggleTurnOverInput = function() {
            vm.showTurnOverInput = false;
            if (vm.total_project_cost < 75) {
                vm.showTurnOverInput = true;
            }
        };

        vm.toggleStartupInput = function(){
            vm.showStartup = false;
            if (vm.project_turnover_in_crore > 0 && vm.project_turnover_in_crore < 50){
                vm.showStartup = true;
            }
        };

        vm.changeInCost=function(){
              var projectCostInCrore=vm.project_cost_in_crore || '00';
              var projectCostInLacs=vm.project_cost_in_lacs|| '00' ;
              var projectCostInThousands=vm.project_cost_in_thousands||'00';
              if(vm.project_cost_in_lacs && vm.project_cost_in_lacs<10){
                 projectCostInLacs='0' + vm.project_cost_in_lacs;
              }
              if(vm.project_cost_in_thousands && vm.project_cost_in_thousands<10){
                 projectCostInThousands='0'+vm.project_cost_in_thousands;
              }
              vm.total_project_cost = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;
              vm.toggleTurnOverInput();
              vm.calculateProjectType(function(){
              });
        };

        vm.changeInTurnover = function(){
            var projectCostInCrore = vm.project_turnover_in_crore || '00';
            var projectCostInLacs = vm.project_turnover_in_lacs|| '00' ;
            var projectCostInThousands = vm.project_turnover_in_thousands||'00';
            if(vm.project_turnover_in_lacs && vm.project_turnover_in_lacs<10){
                projectCostInLacs='0'+vm.project_turnover_in_lacs;
            }
            if(vm.project_turnover_in_thousands && vm.project_turnover_in_thousands<10){
                projectCostInThousands='0'+vm.project_turnover_in_thousands;
            }
            vm.total_project_turnover = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;
            vm.set_projectTurnover();
            vm.calculateProjectType(function(){
            });
            vm.toggleStartupInput();

        };

        function getProjectTypeMappingWithoutBlockType() {
            if (vm.total_project_cost > 50 || (vm.total_project_turnover > 250)) {
                vm.incentives.projectSize = 'Large';
            } else if (vm.total_project_cost <= 50 && vm.total_project_cost > 10 || (vm.total_project_turnover <= 250 && vm.total_project_turnover > 50)) {
                vm.incentives.projectSize = 'Medium';
            } else if (vm.total_project_cost <=10 && vm.total_project_cost > 1 || (vm.total_project_turnover <= 50 && vm.total_project_turnover > 5)) {
                vm.incentives.projectSize = 'Small';
            }  else if (vm.total_project_cost <= 1  || (vm.total_project_turnover <= 5)) {
                vm.incentives.projectSize = 'Micro';
            }
        }

    }
})();
