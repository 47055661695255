(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MultipleactiveaccountwithcafDialogController', MultipleactiveaccountwithcafDialogController);

    MultipleactiveaccountwithcafDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Multipleactiveaccountwithcaf'];

    function MultipleactiveaccountwithcafDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Multipleactiveaccountwithcaf) {
        var vm = this;

        vm.multipleactiveaccountwithcaf = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.multipleactiveaccountwithcaf.id !== null) {
                Multipleactiveaccountwithcaf.update(vm.multipleactiveaccountwithcaf, onSaveSuccess, onSaveError);
            } else {
                Multipleactiveaccountwithcaf.save(vm.multipleactiveaccountwithcaf, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:multipleactiveaccountwithcafUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
