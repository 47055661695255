(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DeemedClearanceApprovalController', DeemedClearanceApprovalController);

    DeemedClearanceApprovalController.$inject = ['DeemedClearanceApproval'];

    function DeemedClearanceApprovalController(DeemedClearanceApproval) {

        var vm = this;

        vm.deemedClearanceApprovals = [];

        loadAll();

        function loadAll() {
            DeemedClearanceApproval.query(function(result) {
                vm.deemedClearanceApprovals = result;
                vm.searchQuery = null;
            });
        }
    }
})();
