(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DepartmentHodController', DepartmentHodController);

    DepartmentHodController.$inject = ['DepartmentHod'];

    function DepartmentHodController(DepartmentHod) {

        var vm = this;

        vm.departmentHods = [];

        loadAll();

        function loadAll() {
            DepartmentHod.query(function(result) {
                vm.departmentHods = result;
                vm.searchQuery = null;
            });
        }
    }
})();
