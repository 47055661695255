(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DisputeAttachmentController', DisputeAttachmentController);

    DisputeAttachmentController.$inject = ['DisputeAttachment'];

    function DisputeAttachmentController(DisputeAttachment) {

        var vm = this;

        vm.disputeAttachments = [];

        loadAll();

        function loadAll() {
            DisputeAttachment.query(function(result) {
                vm.disputeAttachments = result;
                vm.searchQuery = null;
            });
        }
    }
})();
