(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('IncentiveDashboardController', IncentiveDashboardController);

    IncentiveDashboardController.$inject = [];

    function IncentiveDashboardController () {
        var vm = this;

    }
})();
