(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcRecruitmentDialogController', HepcRecruitmentDialogController);

    HepcRecruitmentDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'HepcRecruitment', 'HepcRecruitmentQualifications', 'FileManagement', 'HepcRecruitmentExperience', 'District', 'State'];

    function HepcRecruitmentDialogController($timeout, $scope, $state, $stateParams, entity, HepcRecruitment, HepcRecruitmentQualifications, FileManagement, HepcRecruitmentExperience, District, State) {
        var vm = this;

        vm.hepcRecruitment = entity;
        vm.hepcRecruitment.locationsPreferred = [];
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.counter = 0;
        var uploadedExperience = 0;
        var totalExperience = 0;
        var totalQualifications = 0;
        var uploadedQualifications = 0;
        vm.maxFileSize = 1024*1024;
        vm.isSaving = false;
        var totalExperienceCount = 0;
        vm.states = [];
        vm.tc1 = false;
        vm.tc2 = false;
        vm.tc3 = false;
        vm.districts = [];
        vm.qualifications = {};
        $scope.file = {
            maxSizeError: false
        };
        vm.options = {
          maxDate: new Date()
        };
        $scope.more = [];
        $scope.addmore = function () {
            $scope.more.push({
                organization: null,
                designation: null,
                startDate: null,
                endDate: null,
                totalExperience: null,
                workField: null,
                documentName: null,
                documentType: null,
                refId: null,
                id: null
            });
        };

        $scope.more.push({
            organization: null,
            designation: null,
            startDate: null,
            endDate: null,
            totalExperience: null,
            workField: null,
            documentName: null,
            documentType: null,
            refId: null,
            id: null
        });

        $scope.remove_more = function (index) {
            if (index > 0) {
                $scope.more.splice(index, 1);
            } else alert("Last Row")
        };
        loadAll();

        function loadAll() {
            District.query(function (result) {
                angular.forEach(result, function(value, key){
                    if((value.districtname!=='Narnaul' && value.districtname!== 'Chandigarh HQ')){
                        vm.districts.push(value.districtname);
                    }
                });
                vm.firstLocations = copy(vm.districts);
                vm.secondLocations = copy(vm.districts);
                vm.thirdLocations = copy(vm.districts);
                vm.searchQuery = null;
            });
            State.query(function (result) {
                vm.states = result;
                vm.searchQuery = null;
            });
        }
        vm.firstLocationChanged = function(location) {
            var index1 = vm.secondLocations.indexOf(location);
            var index2 = vm.thirdLocations.indexOf(location);
            vm.secondLocations.splice(index1,1);
            vm.thirdLocations.splice(index2,1);
        };

        vm.secondLocationChanged = function(location) {
            var index1 = vm.firstLocations.indexOf(location);
            var index2 = vm.thirdLocations.indexOf(location);
            vm.firstLocations.splice(index1,1);
            vm.thirdLocations.splice(index2,1);
        };

        vm.thirdLocationChanged = function(location) {
            var index1 = vm.secondLocations.indexOf(location);
            var index2 = vm.firstLocations.indexOf(location);
            vm.secondLocations.splice(index1,1);
            vm.firstLocations.splice(index2,1);
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save() {
            if(vm.hepcRecruitment.jobProfile !== 'Relationship Executive'){
                vm.hepcRecruitment.preferredLocation = '';
                vm.hepcRecruitment.preferredLocationSecond = '';
                vm.hepcRecruitment.preferredLocationThree = '';
            }
            vm.qualificationsArray = [];
            uploadedExperience = 0;
            totalExperience = $scope.more.length;
            totalQualifications = Object.keys(vm.qualifications).length;
            uploadedQualifications = 0;
            totalExperienceCount = 0;
            for(var i=0; i < totalExperience; i++){
                totalExperienceCount = totalExperienceCount + parseInt($scope.more[i].totalExperience);
            }
            vm.hepcRecruitment.totalExperience = totalExperienceCount;
            HepcRecruitment.update(vm.hepcRecruitment, function (result) {
                FileManagement.saveFile(vm.photograph, result.id + '_photograph');
                FileManagement.saveFile(vm.signature, result.id + '_signature');
                var photographextension = getFileExtension(vm.photograph);
                var signatureextension = getFileExtension(vm.signature);
                if (vm.resume !== null && vm.resume !== undefined) {
                    FileManagement.saveFile(vm.resume, result.id + '_resume');
                    var resumeextension = getFileExtension(vm.resume);
                    result.resume = resumeextension === "" ? result.id + '_resume' : result.id + '_resume' + "." + resumeextension;
                }
                result.photograph = photographextension === "" ? result.id + '_photograph' : result.id + '_photograph' + "." + photographextension;
                result.signature = signatureextension === "" ? result.id + '_signature' : result.id + '_signature' + "." + signatureextension;
                HepcRecruitment.update(result);
                angular.forEach(vm.qualifications, function (value, key) {
                    var variable = {};
                    variable.degree = key;
                    variable.refId = result.id;
                    angular.forEach(value, function (v1, k1) {
                        variable[k1] = v1;
                    });
                    variable.name = result.name;
                    variable.jobProfile = result.jobProfile;
                    variable.preferredLocation = result.preferredLocation;
                    variable.applicationId = result.applicationId;
                    HepcRecruitmentQualifications.update(variable, function (qualificationResult) {
                        qualificationResult.documentName = qualificationResult.id;
                        if (variable.document !== null && variable.document !== undefined) {
                            FileManagement.saveFile(variable.document, qualificationResult.documentName);
                            var extension = getFileExtension(variable.document);
                            qualificationResult.documentName = extension === "" ? qualificationResult.id : qualificationResult.id + "." + extension;
                            HepcRecruitmentQualifications.update(qualificationResult, function () {
                                ++uploadedQualifications;
                                console.log(uploadedQualifications);
                                console.log('uploadexp',uploadedExperience,'totalexp', totalExperience,'uploadQuals', uploadedQualifications ,'totalquals', totalQualifications);
                                if (uploadedExperience === totalExperience && uploadedQualifications === totalQualifications) {
                                    alert('Your Form Is Saved');
                                    $scope.$emit('investhryApp:hepcRecruitmentUpdate', result);
                                    vm.isSaving = false;
                                    $state.go('home');
                                }
                            }, function (err) {
                                vm.isSaving = false;
                            });
                        }
                    }, function (err) {
                        vm.isSaving = false;
                    });
                });
                angular.forEach($scope.more, function (experience) {
                    experience.refId = result.id;
                    HepcRecruitmentExperience.save(experience, function (savedExperience) {
                        if (experience.document !== null && experience.document !== undefined) {
                            FileManagement.saveFile(experience.document, savedExperience.id);
                            var extension = getFileExtension(experience.document);
                            savedExperience.documentName = extension === "" ? savedExperience.id : savedExperience.id + "." + extension;
                            HepcRecruitmentExperience.update(savedExperience, function () {
                                ++uploadedExperience;
                                console.log(uploadedExperience);
                                console.log('uploadexp',uploadedExperience,'totalexp', totalExperience,'uploadQuals', uploadedQualifications ,'totalquals', totalQualifications);
                                if (uploadedExperience === totalExperience && uploadedQualifications === totalQualifications) {
                                    console.log('here');
                                    alert('your form is saved');
                                    $scope.$emit('investhryApp:hepcRecruitmentUpdate', result);
                                    vm.isSaving = false;
                                    $state.go('home');
                                }
                            }, function (err) {
                                vm.isSaving = false;
                            })
                        }
                    }, function (err) {
                        vm.isSaving = false;
                    });
                });
            }, function (err) {
                vm.isSaving = false;
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('investhryApp:hepcRecruitmentUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateOfBirth = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
            console.log(date);
        }

        $scope.imageToBase = function (input) {
            console.log(input);
            var file = $scope + '.' + input;
            console.log(file);
            var reader = new FileReader();
            reader.onloadend = function () {
                console.log('RESULT', reader.result)
            };
            reader.readAsDataURL(file);
        };


        function getFileExtension(file) {
            var fileNameArray = file.name.split(".");
            var fileextension = "";
            if ((fileNameArray.length > 1)) {
                fileextension = fileNameArray[(fileNameArray.length - 1)];
                return fileextension;
            }
            return "";
        }
        vm.degreeDateOptions = {};
        $scope.getDate = function(degree, nextDegree, minTime){
            var year = new Date(degree).getFullYear() + minTime;
            var nextDegreeDate = new Date(year, new Date(degree).getMonth(), new Date(degree).getDate());
            vm.degreeDateOptions[nextDegree] = {
                minDate: nextDegreeDate
            };
            vm.degreeDateOptions.others = vm.degreeDateOptions.twelveth;
        };

        function copy(value) {
            var a = [];
            value.forEach(function (val) {
               a.push(val);
            });
            return a;
        }
    }
})();
