(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Meeting_attendanceDialogController', Meeting_attendanceDialogController);

    Meeting_attendanceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Meeting_attendance', 'Meeting_attendee','$filter'];

    function Meeting_attendanceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Meeting_attendance, Meeting_attendee,$filter) {
        var vm = this;

        vm.meeting_attendance = entity;
        vm.clear = clear;

        loadAll();

        function loadAll() {
            Meeting_attendee.query(function(result) {
                vm.meeting_attendees = result;
                vm.searchQuery = null;
            });
        }

        // $scope.toggleAll = function() {
        //     var toggleStatus = $scope.isAllSelected;
        //     angular.forEach( vm.meeting_attendees, function(itm){ itm.ispresent = toggleStatus; });
        //     console.log(vm.meeting_attendees);
        // };

        $scope.optionToggled = function(type,index){
            if(type==='present'){
                    vm.meeting_attendees[index].ispresent = true;
                    vm.meeting_attendees[index].onleave = false;
                    vm.meeting_attendees[index].ontour = false;
            }
            else if(type==='leave'){
                vm.meeting_attendees[index].ispresent = false;
                vm.meeting_attendees[index].onleave = true;
                vm.meeting_attendees[index].ontour = false;
            }
            else if(type==='tour'){
                vm.meeting_attendees[index].ispresent = false;
                vm.meeting_attendees[index].onleave = false;
                vm.meeting_attendees[index].ontour = true;
            }
        };

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var meeting_date= $filter('date')(vm.meeting_attendance.meeting_date,'dd-MM-yyyy');
            Meeting_attendance.save({date: meeting_date}, vm.meeting_attendees,onSaveSuccess(),onSaveError());
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:meeting_attendanceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false ;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.meeting_date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
