(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('DisputeCommentController', DisputeCommentController);

    DisputeCommentController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','dispute','Disputelog', 'CheckContent','FileManagement'];
    function DisputeCommentController ($timeout, $scope, $stateParams, $uibModalInstance, dispute , Disputelog, CheckContent ,FileManagement) {
        var vm = this;

        vm.dispute = dispute;

        $scope.file = {};
        vm.clear = clear;
        vm.saveComments = saveComments;
        vm.disputelog = {};
        vm.projectAttachemnt = [];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        function saveComments() {
            console.log(vm.dispute);
            vm.disputelog.disputeID = vm.dispute.disputeID;
            vm.disputelog.disputeMainId = vm.dispute.id;
            vm.disputelog.action = "Comment";
            if(angular.isDefined(vm.projectAttachemnt.file)){
                var fileNameArray = vm.projectAttachemnt.file.name.split(".");
                if ((fileNameArray.length == 2)) {
                    if(fileNameArray[0] != ""){
                        Disputelog.save(vm.disputelog, onServiceLogSaveSuccess);

                    }
                    else {
                        alert("Invalid File Name");
                    }
                }
                else{
                    alert("Please select correct file format")
                }
            }
            else {
                Disputelog.save(vm.disputelog, onServiceLogSaveSuccess);

            }

        }

        function onServiceLogSaveSuccess(result){
            console.log(vm.disputelog);
            console.log(result);
            if(angular.isDefined(vm.projectAttachemnt.file)) {

                // $scope.$emit('investhryApp:projectAttachemntUpdate', result);
                var filename = result.id;
                var file = vm.projectAttachemnt.file;
                FileManagement.saveFile(file, filename);

                var fileNameArray = vm.projectAttachemnt.file.name.split(".");
                var fileextension = "";
                if ((fileNameArray.length > 1)) {
                    fileextension = fileNameArray[(fileNameArray.length - 1)];
                }

                var inputFileName = result.id;
                if (fileextension !== "") {
                    inputFileName = inputFileName + "." + fileextension;
                }
                vm.disputelog.id = result.id;
                vm.disputelog.fileName = inputFileName;
                console.log(inputFileName);
                console.log(vm.disputelog);

                if (vm.disputelog.id !== null) {
                    Disputelog.update(vm.disputelog, onSaveSuccess, onSaveError);
                } else {
                    Disputelog.save(vm.disputelog, onSaveSuccess, onSaveError);
                }
            }
            $uibModalInstance.close(result);

        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:disputelogUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function onSaveLog(){
            $uibModalInstance.dismiss('close');
        }


        $('input').each(function(){ CheckContent.check($(this))});
        $('textarea').each(function() { CheckContent.check($(this))});

    }
})();
