(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('draftCAF', {
            parent: 'app',
            url: '/draftCAF',
            data: {
                authorities: ["ROLE_ADMIN","ROLE_SUB_ADMIN"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/draftCAF/draftCAF.html',
                    controller: 'DraftCAFController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }]
            }
        });

        }
        })();
