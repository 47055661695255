(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DisputelogController', DisputelogController);

    DisputelogController.$inject = ['Disputelog'];

    function DisputelogController(Disputelog) {

        var vm = this;

        vm.disputelogs = [];

        loadAll();

        function loadAll() {
            Disputelog.query(function(result) {
                vm.disputelogs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
