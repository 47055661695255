(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HodDashboardController', HodDashboardController);

    HodDashboardController.$inject = ['$scope','Principal','RatingsByDepartmentIdAndRating', 'Tickets','DeptHodServiceDashboard'];

    function HodDashboardController ($scope,Principal,RatingsByDepartmentIdAndRating, Tickets,DeptHodServiceDashboard) {
        var vm = this;

        loadAll();
        function loadAll(){
            RatingsByDepartmentIdAndRating.query({ rating: 'All' },function(result){
                vm.feedbacks = result.length;
            });
            Principal.identity().then(function(account) {
                vm.account = account;
                Tickets.query({assignedMember: vm.account.login, status: "Closed"},function(result){
                    vm.closedTickets = result.length;
                });
                Tickets.query({assignedMember: vm.account.login, status: "Open"},function(result){
                    vm.openTickets = result.length;
                });
                DeptHodServiceDashboard.query(function(result) {
                    vm.deptservicedashboard = result;
                    vm.totalPending45 = 0;
                    for(var i=0;i<vm.deptservicedashboard.length;i++)
                    {
                        vm.totalPending45 += parseInt(vm.deptservicedashboard[i].pending_45days);
                    }
                    vm.searchQuery = null;
                });
            });
        }
    }
})();
