(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerUpdateDepartmentController', TowerUpdateDepartmentController);

    TowerUpdateDepartmentController.$inject = ['TowerUpdateDepartment'];

    function TowerUpdateDepartmentController(TowerUpdateDepartment) {

        var vm = this;

        vm.towerUpdateDepartments = [];

        loadAll();

        function loadAll() {
            TowerUpdateDepartment.query(function(result) {
                vm.towerUpdateDepartments = result;
                vm.searchQuery = null;
            });
        }
    }
})();
