(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('LicenseTrackController', LicenseTrackController);

    LicenseTrackController.$inject = [];

    function LicenseTrackController () {
        var vm = this;


    }
})();
