(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('NMARequest', NMARequest);

    NMARequest.$inject = ['$http'];

    function NMARequest ($http) {
        var NMAObject={};
        var requestObject={};
        requestObject.user={};
        requestObject.nmaobject={};
        var NMARegistration={};
        var NMARegistrationObject={};
        NMARegistration.Users=[];
        NMAObject.ApplicantDetails={};
        NMAObject.OwnerDetails={};
        NMAObject.ProposedWorkLocalityDtails={};
        NMAObject.ProposedWorkDetails={};
        NMAObject.Documents={};
        NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf={};
        NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.InCaseOfRepairsOrRenovationReportFromDulyAuthorisedOrLicencedArchitectSubmittedByApplicant=[];
        NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.Signature=[];
        NMAObject.ProposedWorkLocalityDtails.LocalityOfTheProposedConstruction={};
        NMAObject.ProposedWorkLocalityDtails.NameOfTheNearestMonumentOrSite={};
        NMAObject.ProposedWorkLocalityDtails.DistanceOfTheSiteOfTheConstructionFromProtectedBoundaryOfMonument={};

        return {
            createNMAObject:function (formfielddata,files,uniqueid,projectid,onSuccess,onError) {
                for (var i=0;i<formfielddata.length;i++){
                    switch (formfielddata[i].formfieldName){
                        case'Department':
                            formfielddata[i].formfieldvalue=formfielddata[i].formfieldvalue.split(' ').join('_');
                            NMAObject.Department=formfielddata[i].formfieldvalue;
                            NMARegistrationObject.Department=formfielddata[i].formfieldvalue;
                            NMARegistrationObject.UniqueId=uniqueid;
                            break;
                        case'Architect Name':
                            NMAObject.ArchitectName=formfielddata[i].formfieldvalue;
                            NMARegistrationObject.ArchitectName=formfielddata[i].formfieldvalue;
                            break;
                        case'Architect Email':
                            NMAObject.ArchitectEmailId=formfielddata[i].formfieldvalue;
                            NMARegistrationObject.ArchitectEmailId=formfielddata[i].formfieldvalue;
                            break;
                        case'Architect Mobile':
                            NMAObject.ArchitectMobileNo=formfielddata[i].formfieldvalue;
                            NMARegistrationObject.ArchitectMobileNo=formfielddata[i].formfieldvalue;
                            break;
                        case'NOC Request Screen':
                            NMAObject.NOCRequestScreen = getNOCValue(formfielddata[i].formfieldvalue);
                            break;
                        case'Name of The Applicant':
                            NMAObject.ApplicantDetails.ApplicantName=formfielddata[i].formfieldvalue;
                            break;
                        case'Present Address':
                            NMAObject.ApplicantDetails.PresentAddress=formfielddata[i].formfieldvalue;
                            break;
                        case'Permanent Address':
                            NMAObject.ApplicantDetails.PermanentAddress=formfielddata[i].formfieldvalue;
                            break;
                        case'The Applicant is other than the Owner?':
                            NMAObject.OwnerDetails.ApplicantIsOtherThanOwner=formfielddata[i].formfieldvalue;
                            break;
                        case'If Yes, Name of the Owner ':
                            NMAObject.OwnerDetails.NameOfTheOwner=formfielddata[i].formfieldvalue;
                            break;
                        case'If Yes, Present Address':
                            NMAObject.OwnerDetails.PresentAddress=formfielddata[i].formfieldvalue;
                            break;
                        case'If Yes, Permanent Address':
                            NMAObject.OwnerDetails.PermanentAddress=formfielddata[i].formfieldvalue;
                            break;
                        case'Whether the Property is Owned by':
                            NMAObject.OwnerDetails.WhetherThePropertyIsOwnedBy=formfielddata[i].formfieldvalue;
                            break;
                        case'Whether The Property is Owned by':
                            NMAObject.OwnerDetails.WhetherThePropertyIsOwnedBySecond=formfielddata[i].formfieldvalue;
                            break;
                        case'If Other,Please Specify':
                            NMAObject.OwnerDetails.Other=formfielddata[i].formfieldvalue;
                            break;
                        case'Plot/ Survey No':
                            NMAObject.ProposedWorkLocalityDtails.LocalityOfTheProposedConstruction.PlotSurveyNo=formfielddata[i].formfieldvalue;
                            break;
                        case'State':
                            if (formfielddata[i].formfieldOrder===22){
                                NMAObject.ProposedWorkLocalityDtails.LocalityOfTheProposedConstruction.State=formfielddata[i].formfieldvalue;
                            }

                            if (formfielddata[i].formfieldOrder===28){
                                NMAObject.ProposedWorkLocalityDtails.NameOfTheNearestMonumentOrSite.State=formfielddata[i].formfieldvalue;
                            }
                            break;
                        case'District':
                            if (formfielddata[i].formfieldOrder===23){
                                NMAObject.ProposedWorkLocalityDtails.LocalityOfTheProposedConstruction.District=formfielddata[i].formfieldvalue;
                            }

                            if (formfielddata[i].formfieldOrder===29){
                                NMAObject.ProposedWorkLocalityDtails.NameOfTheNearestMonumentOrSite.District=formfielddata[i].formfieldvalue;
                            }
                            break;
                        case'Taluk':
                            if (formfielddata[i].formfieldOrder===24){
                                NMAObject.ProposedWorkLocalityDtails.LocalityOfTheProposedConstruction.Taluk=formfielddata[i].formfieldvalue;
                            }

                            if (formfielddata[i].formfieldOrder===30){
                                NMAObject.ProposedWorkLocalityDtails.NameOfTheNearestMonumentOrSite.Taluk=formfielddata[i].formfieldvalue;
                            }
                            break;
                        case'Monument Name':
                            NMAObject.ProposedWorkLocalityDtails.NameOfTheNearestMonumentOrSite.MonumentName=formfielddata[i].formfieldvalue;
                            break;
                        case'Locality':
                            if (formfielddata[i].formfieldOrder===25){
                                NMAObject.ProposedWorkLocalityDtails.LocalityOfTheProposedConstruction.Locality=formfielddata[i].formfieldvalue;
                            }

                            if (formfielddata[i].formfieldOrder===31){
                                NMAObject.ProposedWorkLocalityDtails.NameOfTheNearestMonumentOrSite.Locality=formfielddata[i].formfieldvalue;
                            }
                            break;
                        case'Distance From the Main Monument (in Meters)':
                            NMAObject.ProposedWorkLocalityDtails.DistanceOfTheSiteOfTheConstructionFromProtectedBoundaryOfMonument.DistanceFromTheMainMonument=formfielddata[i].formfieldvalue;
                            break;
                        case'Distance From the Protected Boundary Wall (in Meters)':
                            NMAObject.ProposedWorkLocalityDtails.DistanceOfTheSiteOfTheConstructionFromProtectedBoundaryOfMonument.DistanceFromTheProtectedBoundaryWall=formfielddata[i].formfieldvalue;
                            break;
                        case'Nature of  Work Proposed':
                            NMAObject.ProposedWorkDetails.NatureOfWorkProposed=formfielddata[i].formfieldvalue;
                            break;
                        case'Details of Repair/ Renovation/Other':
                            NMAObject.ProposedWorkDetails.DetailsOfRepairAndRenovation=formfielddata[i].formfieldvalue;
                            break;
                        case'Number of Storeys':
                            NMAObject.ProposedWorkDetails.NumberOfStoreys=formfielddata[i].formfieldvalue;
                            break;
                        case'Floor Area .toString()(In Square Metres/Storey-wise)':
                            NMAObject.ProposedWorkDetails.FloorAreaInSquareMetresStoreyWise=formfielddata[i].formfieldvalue;
                            break;
                        case'Height(Excluding, Mumly, Parapet, Water-storage Tank, etc)':
                            NMAObject.ProposedWorkDetails.HeightInMetresExcludingMumtyParapetWaterStorageTankEtc=formfielddata[i].formfieldvalue;
                            break;
                        case'Height(Including Mumty, Parapet, Water-storage Tank, etc)':
                            NMAObject.ProposedWorkDetails.HeightInMetresIncludingMumtyParapetWaterStorageTankEtc=formfielddata[i].formfieldvalue;
                            break;
                        case'Basement, if any Proposed with Details':
                            NMAObject.ProposedWorkDetails.BasementIfAnyProposedWithDetails=formfielddata[i].formfieldvalue;
                            break;
                        case'Nature Of Work Proposed Other':
                            NMAObject.ProposedWorkDetails.NatureOfWorkProposedOther=formfielddata[i].formfieldvalue;
                            break;
                        case'Purpose of Proposed Work':
                            NMAObject.ProposedWorkDetails.PurposeOfProposedWork=formfielddata[i].formfieldvalue;
                            break;
                        case'If Other ,Please Specify':
                            NMAObject.ProposedWorkDetails.PurposeOfProposedWorkOther=formfielddata[i].formfieldvalue;
                            break;
                        case'Approximate Date of Commencement of the Proposed Works':
                            NMAObject.ProposedWorkDetails.ApproximateDateOfCommencementOfWorks=formfielddata[i].formfieldvalue;
                            break;
                        case'Approximate Duration for Completion of the Proposed  Works (in Years/Months)':
                            NMAObject.ProposedWorkDetails.ApproximateDurationOfCommencementOfWorks=formfielddata[i].formfieldvalue;
                            break;
                        case'Near the Monument':
                            NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.NearTheMonument=formfielddata[i].formfieldvalue;
                            break;
                        case'Near the Site of  Construction  Related Activity':
                            NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.NearTheSiteConstructionRelatedActivity=formfielddata[i].formfieldvalue;
                            break;
                        case'Whether the Monument is Located within limit of':
                            NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.WhetherMonumentIsLocatedWithinLimitOf=formfielddata[i].formfieldvalue;
                            break;
                        case'Does the master Plan/Zonal Development Plan/Layout Plan  Approved by Concerned  Local Authorities Exists for the City /Town/ Village':
                            NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.DoesMasterPlanApprovedByConcernedAuthoritiesExistsForTheCityTownVillage=formfielddata[i].formfieldvalue;
                            break;
                        case'Status of Modern Constructions in the Vicinity of the Monument and the Proposed Site of the Construction/Reconstruction':
                            NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.StatusOfModernConstructions=formfielddata[i].formfieldvalue;
                            break;
                        case'Open Space/ Park / Green Area Close To Protected Monument / Protected Area':
                            NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.OpenSpaceOrParkOrGreenAreaCloseToProtectedMonumentOrProtectedArea=formfielddata[i].formfieldvalue;
                            break;
                        case'Whether any Road(s) Exists Between the Monument and the Site of Construction/Reconstruction':
                            NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.WhetherAnyRoadExistsBetweenTheMonumentAndTheSiteOfConstruction=formfielddata[i].formfieldvalue;
                            break;
                        case'Remarks/Additional Information,If Any':
                            NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.Remarks=formfielddata[i].formfieldvalue;
                            break;
                    }
                }

                NMAObject.OwnerDetails.PropertyDocument=files[0];
                NMAObject.ApplicationUniqueNumebr=uniqueid;
                NMAObject.ProposedWorkDetails.ElevationDocument=files[1];
                NMAObject.ProposedWorkDetails.SectionDocument=files[3];
                NMAObject.ProposedWorkDetails.TypicalFloorPlan=files[2];
                NMAObject.ProposedWorkDetails.OtherDocument=files[4];
                NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.InCaseOfRepairsOrRenovationReportFromDulyAuthorisedOrLicencedArchitectSubmittedByApplicant=files[5];
                NMAObject.ProposedWorkDetails.MaximumHeightOfExistingModernBuildingInCloseVicinityOf.Signature=files[10];
                NMAObject.Documents.GoogleEarthImage=files[6];
                NMAObject.Documents.ModernConstructionsImage=files[7];
                NMAObject.Documents.OwnershipDocuments=files[8];
                NMAObject.Documents.FirmFiles=files[9];
                NMAObject.Documents.TermAndCondition="Yes";
                NMARegistration.Users.push(NMARegistrationObject);
                requestObject.user=NMARegistration;
                requestObject.nmaobject=NMAObject;
                requestObject.projectid=projectid;
                requestObject.cafpin=uniqueid;
                function getNOCValue(data) {
                    var value="";
                    switch (data){
                        case "Sanction of building plan":
                            value="S";
                            break;
                        case "Revise sanction of plan":
                            value="R";
                            break;
                        case "Completion Certificate":
                            value="SC";
                            break;
                        case "Saral Sanction of building plan":
                            value="SS";
                            break;
                        case "Revise Saral Sanction of building plan":
                            value="RS";
                            break;
                        case "Sanction Saral Completion":
                            value="SSC";
                            break;
                        case "Addition Alteration (1st sanction)":
                            value="A";
                            break;
                        case "Addition Alteration ( 2nd sanction)":
                            value="A1";
                            break;
                        case "Addition Alteration ( 3rd sanction)":
                            value="A2";
                            break;
                        case "Addition Alteration ( 4rth sanction)":
                            value="A3";
                            break;
                        case "Addition Alteration ( 5th sanction)":
                            value="A4";
                            break;
                        case "Addition alteration Revise sanction of plan":
                            value="RA";
                            break;
                        case "Addition alteration Revise sanction of plan (after 2nd sanction)":
                            value="RA1";
                            break;
                        case "Addition alteration Revise sanction of plan (after 3rd sanction)":
                            value="RA2";
                            break;
                        case "Addition alteration Revise sanction of plan (after 4th sanction)":
                            value="RA3";
                            break;
                        case "Addition alteration Revise sanction of plan (after 5th  sanction)":
                            value="RA4";
                            break;
                        case "Completion Addition alteration":
                            value="AC";
                            break;
                        case "Completion Addition alteration ( 2nd sanction)":
                            value="AC1";
                            break;
                        case "Completion Addition alteration ( 3rd sanction)":
                            value="AC2";
                            break;
                        case "Completion Addition alteration( 4th sanction)":
                            value="Ac3";
                            break;
                        case "Completion Addition alteration( 5th sanction)":
                            value="AC4";
                            break;
                    }
                    return value;
                }
                $http.post('/api/nma',requestObject).then(function (result) {
                    console.log('success');
                    if (onSuccess!==undefined){
                        onSuccess(result);
                    }
                    return NMAObject;
                },function (error) {
                    if (onError!==undefined){
                        onError(error);
                    }
                    console.log('error');
                });
            }
        }
    }
})();
