(function () {
    'use strict';
    angular
        .module('investhryApp')
        .factory('CountryService', CountryService);

    CountryService.$inject = [];

    function CountryService() {
        return {
            getAllCountries: function () {
                var countries = [{"name": "Afghanistan", "code": "af"}, {
                    "name": "Albania",
                    "code": "al"
                }, {"name": "Algeria", "code": "dz"}, {"name": "Andorra", "code": "ad"}, {
                    "name": "Angola",
                    "code": "ao"
                }, {"name": "Anguilla", "code": "ai"}, {
                    "name": "Antigua and Barbuda",
                    "code": "ag"
                }, {"name": "Argentina", "code": "ar"}, {"name": "Armenia", "code": "am"}, {
                    "name": "Aruba",
                    "code": "aw"
                }, {"name": "Australia", "code": "au"}, {"name": "Austria", "code": "at"}, {
                    "name": "Azerbaijan",
                    "code": "az"
                }, {"name": "Bahamas", "code": "bs"}, {"name": "Bahrain", "code": "bh"}, {
                    "name": "Bangladesh",
                    "code": "bd"
                }, {"name": "Barbados", "code": "bb"}, {"name": "Belarus", "code": "by"}, {
                    "name": "Belgium",
                    "code": "be"
                }, {"name": "Belize", "code": "bz"}, {"name": "Benin", "code": "bj"}, {
                    "name": "Bermuda",
                    "code": "bm"
                }, {"name": "Bhutan", "code": "bt"}, {
                    "name": "Bolivia",
                    "code": "bo"
                }, {"name": "Bosnia and Herzegovina", "code": "ba"}, {
                    "name": "Botswana",
                    "code": "bw"
                }, {"name": "Brazil", "code": "br"}, {"name": "Brunei Darussalam", "code": "bn"}, {
                    "name": "Bulgaria",
                    "code": "bg"
                }, {"name": "Burkina Faso", "code": "bf"}, {"name": "Burundi", "code": "bi"}, {
                    "name": "Cambodia",
                    "code": "kh"
                }, {"name": "Cameroon", "code": "cm"}, {"name": "Canada", "code": "ca"}, {
                    "name": "Cape Verde",
                    "code": "cv"
                }, {"name": "Cayman Islands", "code": "ky"}, {
                    "name": "Central African Republic",
                    "code": "cf"
                }, {"name": "Chad", "code": "td"}, {"name": "Chile", "code": "cl"}, {
                    "name": "China",
                    "code": "cn"
                }, {"name": "Christmas Island", "code": "cx"}, {
                    "name": "Cocos (Keeling) Islands",
                    "code": "cc"
                }, {"name": "Colombia", "code": "co"}, {"name": "Comoros", "code": "km"}, {
                    "name": "Congo",
                    "code": "cg"
                }, {"name": "Cook Islands", "code": "ck"}, {
                    "name": "Costa Rica",
                    "code": "cr"
                }, {"name": "Cote D\u0027Ivoire (Ivory Coast)", "code": "ci"}, {
                    "name": "Croatia (Hrvatska)",
                    "code": "hr"
                }, {"name": "Cuba", "code": "cu"}, {"name": "Cyprus", "code": "cy"}, {
                    "name": "Czech Republic",
                    "code": "cz"
                }, {"name": "Democratic Republic of the Congo", "code": "cd"}, {
                    "name": "Denmark",
                    "code": "dk"
                }, {"name": "Djibouti", "code": "dj"}, {
                    "name": "Dominica",
                    "code": "dm"
                }, {"name": "Dominican Republic", "code": "do"}, {"name": "Ecuador", "code": "ec"}, {
                    "name": "Egypt",
                    "code": "eg"
                }, {"name": "El Salvador", "code": "sv"}, {
                    "name": "Equatorial Guinea",
                    "code": "gq"
                }, {"name": "Eritrea", "code": "er"}, {"name": "Estonia", "code": "ee"}, {
                    "name": "Ethiopia",
                    "code": "et"
                }, {"name": "Falkland Islands (Malvinas)", "code": "fk"}, {
                    "name": "Faroe Islands",
                    "code": "fo"
                }, {"name": "Federated States of Micronesia", "code": "fm"}, {
                    "name": "Fiji",
                    "code": "fj"
                }, {"name": "Finland", "code": "fi"}, {"name": "France", "code": "fr"}, {
                    "name": "French Guiana",
                    "code": "gf"
                }, {"name": "French Polynesia", "code": "pf"}, {
                    "name": "French Southern Territories",
                    "code": "tf"
                }, {"name": "Gabon", "code": "ga"}, {"name": "Gambia", "code": "gm"}, {
                    "name": "Georgia",
                    "code": "ge"
                }, {"name": "Germany", "code": "de"}, {"name": "Ghana", "code": "gh"}, {
                    "name": "Gibraltar",
                    "code": "gi"
                }, {"name": "Great Britain (UK)", "code": "gb"}, {"name": "Greece", "code": "gr"}, {
                    "name": "Greenland",
                    "code": "gl"
                }, {"name": "Grenada", "code": "gd"}, {"name": "Guadeloupe", "code": "gp"}, {
                    "name": "Guatemala",
                    "code": "gt"
                }, {"name": "Guinea", "code": "gn"}, {"name": "Guinea-Bissau", "code": "gw"}, {
                    "name": "Guyana",
                    "code": "gy"
                }, {"name": "Haiti", "code": "ht"}, {"name": "Honduras", "code": "hn"}, {
                    "name": "Hong Kong",
                    "code": "hk"
                }, {"name": "Hungary", "code": "hu"}, {"name": "Iceland", "code": "is"}, {
                    "name": "India",
                    "code": "in"
                }, {"name": "Indonesia", "code": "id"}, {"name": "Iran", "code": "ir"}, {
                    "name": "Iraq",
                    "code": "iq"
                }, {"name": "Ireland", "code": "ie"}, {"name": "Israel", "code": "il"}, {
                    "name": "Italy",
                    "code": "it"
                }, {"name": "Jamaica", "code": "jm"}, {"name": "Japan", "code": "jp"}, {
                    "name": "Jordan",
                    "code": "jo"
                }, {"name": "Kazakhstan", "code": "kz"}, {"name": "Kenya", "code": "ke"}, {
                    "name": "Kiribati",
                    "code": "ki"
                }, {"name": "Korea (North)", "code": "kp"}, {"name": "Korea (South)", "code": "kr"}, {
                    "name": "Kuwait",
                    "code": "kw"
                }, {"name": "Kyrgyzstan", "code": "kg"}, {"name": "Laos", "code": "la"}, {
                    "name": "Latvia",
                    "code": "lv"
                }, {"name": "Lebanon", "code": "lb"}, {"name": "Lesotho", "code": "ls"}, {
                    "name": "Liberia",
                    "code": "lr"
                }, {"name": "Libya", "code": "ly"}, {"name": "Liechtenstein", "code": "li"}, {
                    "name": "Lithuania",
                    "code": "lt"
                }, {"name": "Luxembourg", "code": "lu"}, {"name": "Macao", "code": "mo"}, {
                    "name": "Macedonia",
                    "code": "mk"
                }, {"name": "Madagascar", "code": "mg"}, {"name": "Malawi", "code": "mw"}, {
                    "name": "Malaysia",
                    "code": "my"
                }, {"name": "Maldives", "code": "mv"}, {"name": "Mali", "code": "ml"}, {
                    "name": "Malta",
                    "code": "mt"
                }, {"name": "Marshall Islands", "code": "mh"}, {
                    "name": "Martinique",
                    "code": "mq"
                }, {"name": "Mauritania", "code": "mr"}, {"name": "Mauritius", "code": "mu"}, {
                    "name": "Mayotte",
                    "code": "yt"
                }, {"name": "Mexico", "code": "mx"}, {"name": "Moldova", "code": "md"}, {
                    "name": "Monaco",
                    "code": "mc"
                }, {"name": "Mongolia", "code": "mn"}, {"name": "Montserrat", "code": "ms"}, {
                    "name": "Morocco",
                    "code": "ma"
                }, {"name": "Mozambique", "code": "mz"}, {"name": "Myanmar", "code": "mm"}, {
                    "name": "Namibia",
                    "code": "na"
                }, {"name": "Nauru", "code": "nr"}, {"name": "Nepal", "code": "np"}, {
                    "name": "Netherlands",
                    "code": "nl"
                }, {"name": "Netherlands Antilles", "code": "an"}, {
                    "name": "New Caledonia",
                    "code": "nc"
                }, {"name": "New Zealand (Aotearoa)", "code": "nz"}, {
                    "name": "Nicaragua",
                    "code": "ni"
                }, {"name": "Niger", "code": "ne"}, {"name": "Nigeria", "code": "ng"}, {
                    "name": "Niue",
                    "code": "nu"
                }, {"name": "Norfolk Island", "code": "nf"}, {
                    "name": "Northern Mariana Islands",
                    "code": "mp"
                }, {"name": "Norway", "code": "no"}, {"name": "NULL", "code": "gg"}, {
                    "name": "Oman",
                    "code": "om"
                }, {"name": "Pakistan", "code": "pk"}, {
                    "name": "Palau",
                    "code": "pw"
                }, {"name": "Palestinian Territory", "code": "ps"}, {
                    "name": "Panama",
                    "code": "pa"
                }, {"name": "Papua New Guinea", "code": "pg"}, {"name": "Paraguay", "code": "py"}, {
                    "name": "Peru",
                    "code": "pe"
                }, {"name": "Philippines", "code": "ph"}, {"name": "Pitcairn", "code": "pn"}, {
                    "name": "Poland",
                    "code": "pl"
                }, {"name": "Portugal", "code": "pt"}, {"name": "Qatar", "code": "qa"}, {
                    "name": "Reunion",
                    "code": "re"
                }, {"name": "Romania", "code": "ro"}, {"name": "Russian Federation", "code": "ru"}, {
                    "name": "Rwanda",
                    "code": "rw"
                }, {"name": "S. Georgia and S. Sandwich Islands", "code": "gs"}, {
                    "name": "Saint Helena",
                    "code": "sh"
                }, {"name": "Saint Kitts and Nevis", "code": "kn"}, {
                    "name": "Saint Lucia",
                    "code": "lc"
                }, {"name": "Saint Pierre and Miquelon", "code": "pm"}, {
                    "name": "Saint Vincent and the Grenadines",
                    "code": "vc"
                }, {"name": "Samoa", "code": "ws"}, {
                    "name": "San Marino",
                    "code": "sm"
                }, {"name": "Sao Tome and Principe", "code": "st"}, {
                    "name": "Saudi Arabia",
                    "code": "sa"
                }, {"name": "Senegal", "code": "sn"}, {"name": "Seychelles", "code": "sc"}, {
                    "name": "Sierra Leone",
                    "code": "sl"
                }, {"name": "Singapore", "code": "sg"}, {"name": "Slovakia", "code": "sk"}, {
                    "name": "Slovenia",
                    "code": "si"
                }, {"name": "Solomon Islands", "code": "sb"}, {
                    "name": "Somalia",
                    "code": "so"
                }, {"name": "South Africa", "code": "za"}, {"name": "Spain", "code": "es"}, {
                    "name": "Sri Lanka",
                    "code": "lk"
                }, {"name": "Sudan", "code": "sd"}, {
                    "name": "Suriname",
                    "code": "sr"
                }, {"name": "Svalbard and Jan Mayen", "code": "sj"}, {
                    "name": "Swaziland",
                    "code": "sz"
                }, {"name": "Sweden", "code": "se"}, {"name": "Switzerland", "code": "ch"}, {
                    "name": "Syria",
                    "code": "sy"
                }, {"name": "Taiwan", "code": "tw"}, {"name": "Tajikistan", "code": "tj"}, {
                    "name": "Tanzania",
                    "code": "tz"
                }, {"name": "Thailand", "code": "th"}, {"name": "Togo", "code": "tg"}, {
                    "name": "Tokelau",
                    "code": "tk"
                }, {"name": "Tonga", "code": "to"}, {"name": "Trinidad and Tobago", "code": "tt"}, {
                    "name": "Tunisia",
                    "code": "tn"
                }, {"name": "Turkey", "code": "tr"}, {
                    "name": "Turkmenistan",
                    "code": "tm"
                }, {"name": "Turks and Caicos Islands", "code": "tc"}, {
                    "name": "Tuvalu",
                    "code": "tv"
                }, {"name": "Uganda", "code": "ug"}, {"name": "Ukraine", "code": "ua"}, {
                    "name": "United Arab Emirates",
                    "code": "ae"
                }, {"name": "United States of America", "code": "us"}, {
                    "name": "Uruguay",
                    "code": "uy"
                }, {"name": "Uzbekistan", "code": "uz"}, {"name": "Vanuatu", "code": "vu"}, {
                    "name": "Venezuela",
                    "code": "ve"
                }, {"name": "Viet Nam", "code": "vn"}, {
                    "name": "Virgin Islands (British)",
                    "code": "vg"
                }, {"name": "Virgin Islands (U.S.)", "code": "vi"}, {
                    "name": "Wallis and Futuna",
                    "code": "wf"
                }, {"name": "Western Sahara", "code": "eh"}, {"name": "Yemen", "code": "ye"}, {
                    "name": "Zaire (former)",
                    "code": "zr"
                }, {"name": "Zambia", "code": "zm"}, {"name": "Zimbabwe", "code": "zw"}];

                return countries;
            }
        }
    }
})();
