(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ProjectLogNewCafDetailController', ProjectLogNewCafDetailController);

    ProjectLogNewCafDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProjectLogNewCaf'];

    function ProjectLogNewCafDetailController($scope, $rootScope, $stateParams, previousState, entity, ProjectLogNewCaf) {
        var vm = this;

        vm.projectLogNewCaf = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:projectLogNewCafUpdate', function(event, result) {
            vm.projectLogNewCaf = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
