(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('PolicyFeedbackDashboardController', PolicyFeedbackDashboardController);

    PolicyFeedbackDashboardController.$inject = ['$scope','DraftPolicy','PolicyDetails'];

    function PolicyFeedbackDashboardController($scope, DraftPolicy, PolicyDetails) {
        var vm = this;
        vm.draftPolicies = [];
        vm.statusSelected = statusSelected;
        vm.statusFilter = 'All';
        vm.policyFilter = 'All';
        vm.showPolicyForm = false;
        vm.save = save;

        PolicyDetails.query(function(result) {
            vm.policyDetails = result;
            vm.searchQuery = null;
            statusSelected('Pending',result[0].name);
        });

        function statusSelected (statusFilterobj,policyFilterobj) {
            vm.statusFilter = statusFilterobj;
            vm.policyFilter = policyFilterobj;
            DraftPolicy.query({id:'1',status:statusFilterobj,policy:policyFilterobj},function(result) {
                vm.draftPolicies = result;
                vm.searchQuery = null;
            });
        }

        $scope.setTempItem = function(item) {
            $scope.policyName = item.name;
            $scope.serverFileName = item.file;
            vm.showPolicyForm = true;
        }

        function save() {
            DraftPolicy.save(vm.draftPolicy,onSaveSuccess,onSaveError);
        }

        function onSaveSuccess() {
            UIkit.modal('#draft_policy').hide();
            location.reload();
        }

        function onSaveError() {

        }
    }
})();
