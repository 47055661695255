(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('application-logs', {
            parent: 'entity',
            url: '/application-logs',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.applicationLogs.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/application-logs/application-logs.html',
                    controller: 'ApplicationLogsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('applicationLogs');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('application-logs-detail', {
            parent: 'application-logs',
            url: '/application-logs/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.applicationLogs.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/application-logs/application-logs-detail.html',
                    controller: 'ApplicationLogsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('applicationLogs');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ApplicationLogs', function($stateParams, ApplicationLogs) {
                    return ApplicationLogs.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'application-logs',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('application-logs-detail.edit', {
            parent: 'application-logs-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/application-logs/application-logs-dialog.html',
                    controller: 'ApplicationLogsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ApplicationLogs', function(ApplicationLogs) {
                            return ApplicationLogs.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('application-logs.new', {
            parent: 'application-logs',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/application-logs/application-logs-dialog.html',
                    controller: 'ApplicationLogsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                user_role: null,
                                user_details: null,
                                path: null,
                                requestBody: null,
                                responseBody: null,
                                status: null,
                                params: null,
                                header: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('application-logs', null, { reload: 'application-logs' });
                }, function() {
                    $state.go('application-logs');
                });
            }]
        })
        .state('application-logs.edit', {
            parent: 'application-logs',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/application-logs/application-logs-dialog.html',
                    controller: 'ApplicationLogsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ApplicationLogs', function(ApplicationLogs) {
                            return ApplicationLogs.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('application-logs', null, { reload: 'application-logs' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('application-logs.delete', {
            parent: 'application-logs',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/application-logs/application-logs-delete-dialog.html',
                    controller: 'ApplicationLogsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ApplicationLogs', function(ApplicationLogs) {
                            return ApplicationLogs.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('application-logs', null, { reload: 'application-logs' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
