(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AllRatingSummaryController', AllRatingSummaryController);

    AllRatingSummaryController.$inject = ['$scope', '$stateParams', '$uibModalInstance', '$filter', 'Rating'];

    function AllRatingSummaryController($scope, $stateParams, $uibModalInstance, $filter, Rating) {
        var vm = this;
        vm.allRatings = [];
        vm.feedbacks = [];
        vm.status = $stateParams.status;
        Rating.query(function(result){
            vm.allRatings = result;
            filterFeedbacks($stateParams.status);
        });
        function filterFeedbacks(status){
            if(status === 'Resolved'){
                vm.resolvedPoorFeedbacks = $filter('filter')(vm.allRatings,{ isResolved : true, rating: 'Poor'});
                vm.resolvedAverageFeedbacks = $filter('filter')(vm.allRatings,{ isResolved : true, rating: 'Average'});
                vm.feedbacks = vm.resolvedAverageFeedbacks.concat(vm.resolvedPoorFeedbacks);
            }else if(status === 'Unresolved'){
                vm.unResolvedPoorFeedbacks = $filter('filter')(vm.allRatings,{ isResolved : false, rating: 'Poor'});
                vm.unResolvedAverageFeedbacks = $filter('filter')(vm.allRatings,{ isResolved : false, rating: 'Average'});
                vm.feedbacks = vm.unResolvedAverageFeedbacks.concat(vm.unResolvedPoorFeedbacks);
            }
        }
        vm.clear = function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
