(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('UserAuthorizationDetailController', UserAuthorizationDetailController);

    UserAuthorizationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'UserAuthorization'];

    function UserAuthorizationDetailController($scope, $rootScope, $stateParams, previousState, entity, UserAuthorization) {
        var vm = this;

        vm.userAuthorization = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:userAuthorizationUpdate', function(event, result) {
            vm.userAuthorization = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
