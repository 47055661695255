(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('viewServiceStatus', {
            parent: 'app',
            url: '/viewServiceStatus',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/viewServiceStatus/viewServiceStatus.html',
                    controller: 'ViewServiceStatusController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }]
            }
        })
    .state('viewUserServiceStatus', {
            parent: 'app',
            url: '/myServiceStatus',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/viewServiceStatus/viewUsersServiceStatus.html',
                    controller: 'ViewUserServiceStatusController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }]
            }
        })
        .state('feedback-ratings', {
            parent: 'app',
            url: '/feedback-ratings',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/feedbackratingdashboard/feedbacks.html',
                    controller: 'FeedbackDashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }]
            }
        });

    }
})();
