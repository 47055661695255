(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NswsUserDetailsController', NswsUserDetailsController);

    NswsUserDetailsController.$inject = ['NswsUserDetails'];

    function NswsUserDetailsController(NswsUserDetails) {

        var vm = this;

        vm.nswsUserDetails = [];

        loadAll();

        function loadAll() {
            NswsUserDetails.query(function(result) {
                vm.nswsUserDetails = result;
                vm.searchQuery = null;
            });
        }
    }
})();
