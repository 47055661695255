(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ProjectservicedetailController', ProjectservicedetailController);

    ProjectservicedetailController.$inject = ['Projectservicedetail','ProjectservicedetailByProjectId'];

    function ProjectservicedetailController(Projectservicedetail,ProjectservicedetailByProjectId) {

        var vm = this;

        vm.projectservicedetails = undefined;
        vm.loadByProjectid=loadByProjectid;


//        loadAll();

//        function loadAll() {
//            Projectservicedetail.query(function(result) {
//                vm.projectservicedetails = result;
//                vm.searchQuery = null;
//            });
//        }


        function loadByProjectid() {
            vm.projectservicedetails=null;
            ProjectservicedetailByProjectId.query({projectid:vm.projectservicedetail.projectid}, function(result) {
                vm.projectservicedetails = result;
                vm.searchQuery = null;
            });
        }

    }
})();
