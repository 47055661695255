(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DashboardbetweendatesAllServicesController', DashboardbetweendatesAllServicesController);

    DashboardbetweendatesAllServicesController.$inject = ['DashboardbetweendatesAllServices','$stateParams','$uibModalInstance','$filter'];

    function DashboardbetweendatesAllServicesController(DashboardbetweendatesAllServices,$stateParams,$uibModalInstance,$filter) {
        var vm = this;

        vm.clear=clear;
        vm.fromdate=$stateParams.from;
        vm.todate=$stateParams.to;
        vm.status=$stateParams.status;

        loadAllServicesData();

        function loadAllServicesData() {
            vm.fromdate = new Date(vm.fromdate);
            vm.todate = new Date(vm.todate);
            var f = $filter('date')(vm.fromdate, "yyyy-MM-dd");
            var t = $filter('date')(vm.todate, "yyyy-MM-dd");
            var fromdate = $filter('date')(vm.fromdate, "dd-MM-yyyy");
            var todate = $filter('date')(vm.todate, "dd-MM-yyyy");
            DashboardbetweendatesAllServices.query({from:f,to:t,status:vm.status,fromdate:fromdate,todate:todate,All:'All'},function (result) {
               vm.servicecafs=result;
                console.log(vm.servicecafs);

                vm.show=true;
            });
        }

        function clear() {
            $uibModalInstance.close();
        }
    }
})();
