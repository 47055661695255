(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ChecklistViewController', ChecklistViewController);

    ChecklistViewController.$inject = ['$scope','$stateParams', '$sce', 'Checklist'];

    function ChecklistViewController($scope, $stateParams, $sce, Checklist) {

        var vm = this;
        $scope.urlpdf='';

        loadAll();

        function loadAll() {
            Checklist.get({id: $stateParams.serviceid},onFindSuccess,OnFindError);
        }

        function onFindSuccess(result) {
            $('#dislayCheckList').modal('show');

            // UIkit.modal("#dislayCheckList").show();
            $scope.urlpdf = $sce.trustAsResourceUrl(result.serverFileName);
        }
        function OnFindError() {
            alert("Checklist Not Available");
        }

    }
})();
