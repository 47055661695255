(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketDepartmentsController', TicketDepartmentsController);

    TicketDepartmentsController.$inject = ['TicketDepartments'];

    function TicketDepartmentsController(TicketDepartments) {

        var vm = this;

        vm.ticketDepartments = [];

        loadAll();

        function loadAll() {
            TicketDepartments.query(function(result) {
                vm.ticketDepartments = result;
                vm.searchQuery = null;
            });
        }
    }
})();
