(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('center-tower-user-data', {
            parent: 'entity',
            url: '/center-tower-user-data',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.centerTowerUserData.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/center-tower-user-data/center-tower-user-data.html',
                    controller: 'CenterTowerUserDataController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('centerTowerUserData');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('center-tower-user-data-detail', {
            parent: 'center-tower-user-data',
            url: '/center-tower-user-data/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.centerTowerUserData.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/center-tower-user-data/center-tower-user-data-detail.html',
                    controller: 'CenterTowerUserDataDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('centerTowerUserData');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CenterTowerUserData', function($stateParams, CenterTowerUserData) {
                    return CenterTowerUserData.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'center-tower-user-data',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('center-tower-user-data-detail.edit', {
            parent: 'center-tower-user-data-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/center-tower-user-data/center-tower-user-data-dialog.html',
                    controller: 'CenterTowerUserDataDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CenterTowerUserData', function(CenterTowerUserData) {
                            return CenterTowerUserData.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('center-tower-user-data.new', {
            parent: 'center-tower-user-data',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/center-tower-user-data/center-tower-user-data-dialog.html',
                    controller: 'CenterTowerUserDataDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                stateCode: null,
                                userId: null,
                                requestId: null,
                                applicationType: null,
                                applicationName: null,
                                email: null,
                                mobileNumber: null,
                                username: null,
                                districtCode: null,
                                applicantAddress: null,
                                organisationType: null,
                                organisationName: null,
                                licenseNumber: null,
                                licenseExpiryDate: null,
                                licenseCertificationPath: null,
                                authorizationLetterPath: null,
                                isView: null,
                                applicationNumber: null,
                                zipCode: null,
                                createdDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('center-tower-user-data', null, { reload: 'center-tower-user-data' });
                }, function() {
                    $state.go('center-tower-user-data');
                });
            }]
        })
        .state('center-tower-user-data.edit', {
            parent: 'center-tower-user-data',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/center-tower-user-data/center-tower-user-data-dialog.html',
                    controller: 'CenterTowerUserDataDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CenterTowerUserData', function(CenterTowerUserData) {
                            return CenterTowerUserData.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('center-tower-user-data', null, { reload: 'center-tower-user-data' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('center-tower-user-data.delete', {
            parent: 'center-tower-user-data',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/center-tower-user-data/center-tower-user-data-delete-dialog.html',
                    controller: 'CenterTowerUserDataDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CenterTowerUserData', function(CenterTowerUserData) {
                            return CenterTowerUserData.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('center-tower-user-data', null, { reload: 'center-tower-user-data' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
