(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DashboardbetweendatescafDetailController', DashboardbetweendatescafDetailController);

    DashboardbetweendatescafDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Dashboardbetweendatescaf'];

    function DashboardbetweendatescafDetailController($scope, $rootScope, $stateParams, previousState, entity, Dashboardbetweendatescaf) {
        var vm = this;

        vm.dashboardbetweendatescaf = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:dashboardbetweendatescafUpdate', function(event, result) {
            vm.dashboardbetweendatescaf = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
