(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcTicketTaskDetailController', HepcTicketTaskDetailController);

    HepcTicketTaskDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'HepcTicketTask'];

    function HepcTicketTaskDetailController($scope, $rootScope, $stateParams, previousState, entity, HepcTicketTask) {
        var vm = this;

        vm.hepcTicketTask = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:hepcTicketTaskUpdate', function(event, result) {
            vm.hepcTicketTask = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
