(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketGenerateController', TicketGenerateController);

    TicketGenerateController.$inject = ['$scope', '$state', 'Tickets', 'TicketDepartments', 'DepartmentService', 'TicketsCustomer', 'District', 'UploadTicketAttachment', 'TicketMember'];

    function TicketGenerateController ($scope, $state, Tickets, TicketDepartments, DepartmentService, TicketsCustomer, District, UploadTicketAttachment, TicketMember) {
        var vm = this;
        vm.customerMobile = null;
        vm.isCustomerMobileSearch = false;
        vm.isSearchedCustomerAdded = false;
        vm.isShowSearchByMobile= false;
        vm.newCustomer=false;
        vm.isSearchResult=false;
        vm.registered={};
        vm.tickets = {};
        vm.ticketsCustomer = {};
        vm.ticketsCustomer.email = null;
        vm.deparment=[];
        vm.districts=[];
        vm.services=[];
        vm.serviceDetails={};
        vm.save = save;
        vm.clear = clear;
        vm.search = search;
        vm.searchByMobile = searchByMobile;
        vm.addCustomerSearchByMobile=addCustomerSearchByMobile;
        vm.departmnetService = departmnetService;
        vm.addCustomer = addCustomer;
        vm.uploadFile = uploadFile;
        vm.changeUserBy=changeUserBy;
        vm.otherField = null;
        vm.HOBPASRole = [];
        vm.incentivesRole = [];
        $scope.file = {};

        loadAll();

        function loadAll() {
            TicketDepartments.query({hepcTicketDepartment:"HODNODAL"},function (result) {
                vm.deparment=result;
            });

            District.query(function(result) {
                var other = {
                    districtname: "Other",
                    id: "2c4709e1-0b26-4348-b6fb-e2ab85449cf9",
                    powercompany: "DHBVN",
                    stateID: "c8305487-aba6-461c-898c-3efe85a9d5e5",
                    statename: "Other"
                };
                vm.districts = result;
                vm.districts.push(other);
            });
            TicketMember.query({role: "ROLE_L2_IT_MANAGER"}, function (result) {
                vm.ticketsMember = result;
            });
            TicketMember.query({role: "ROLE_TICKET_HOBPAS"}, function (result) {
                vm.HOBPASRole = result;
            })
            TicketMember.query({role: "ROLE_L2_INCENTIVES"}, function (result) {
                vm.incentivesRole = result;
            })
        }

        function search(key , value) {
            TicketsCustomer.get({columnKey: key, columnValue: value},function (result) {
                vm.isSearchResult=true;
                vm.registered=result;
            }, function () {
                vm.isSearchResult=false;
                vm.registered={};
            });
        }

        function searchByMobile(key , value) {
            TicketsCustomer.get({columnKey: key, columnValue: value},function (result) {
                vm.isCustomerMobileSearch = true;
                result.mobileNumber = parseInt(result.mobileNumber);
                vm.oldTicketCustomer=result;
            }, function (result) {
                vm.isCustomerMobileSearch = false;
                vm.ticketsCustomer= {};
                vm.customerMobile = null;
            });
        }

        function changeUserBy() {
            vm.isSearchResult=false;
            vm.searchValue=null;
            vm.registered={};
        }

        function departmnetService(deparment) {
            DepartmentService.query({id:'1', departmentid: deparment.id}, function (result) {
                vm.services=result;
            });
        }

        function addCustomerSearchByMobile() {
            vm.isSearchedCustomerAdded = true;
            $("#addCustomer").modal("hide");
        }

        function addCustomer(customer) {
            var infoEmail = null;
            var infoMobile = null;

            if(customer.email && customer.email.includes("@") && customer.mobileNumber ) {
                infoEmail=customer.email;
                infoMobile=customer.mobileNumber;
                TicketsCustomer.get({columnData: infoEmail}, function (result) {
                    TicketsCustomer.get({columnData: infoMobile}, function (result) {
                        vm.newCustomer = true;
                        $("#addCustomer").modal("hide");
                    });
                });
            } else if (customer.email && customer.email.includes("@") && !customer.mobileNumber) {
                infoEmail = customer.email;
                TicketsCustomer.get({columnData: infoEmail}, function (result) {
                    vm.newCustomer = true;
                    $("#addCustomer").modal("hide");
                });
            } else if(!customer.email && customer.mobileNumber) {
                infoMobile = customer.mobileNumber;
                TicketsCustomer.get({columnData: infoMobile}, function (result) {
                    vm.newCustomer = true;
                    $("#addCustomer").modal("hide");
                });
            } else if(customer.email && !customer.email.includes("@") && customer.mobileNumber) {
                infoMobile = customer.mobileNumber;
                TicketsCustomer.get({columnData: infoMobile}, function (result) {
                    vm.newCustomer = true;
                    $("#addCustomer").modal("hide");
                });
            }
        }

        function uploadFile () {
            if(angular.isDefined(vm.file)){
                var file =  vm.file;
                UploadTicketAttachment.saveFile(file,onUploadSuccess,onUploadError);
            }else {
                save();
            }
        }

        function onUploadSuccess (result) {
            vm.tickets.attachment = result.filename;
            if(vm.tickets.attachment!=null){
                save();
            }
        }

        function onUploadError () {
            alert("Error while uploading file");
            $state.reload();
        }

        function save () {
            vm.isSaving = true;
            if (vm.tickets.incidentCategory==='Process Related' || vm.tickets.incidentCategory==='Grievances' || vm.tickets.incidentCategory==='HOBPAS') {
                vm.tickets.assignedDepartmentId=vm.departmentDetail.id;
                vm.tickets.assignedDepartmentName=vm.departmentDetail.departmentName;
                if(vm.serviceDetails!==null){
                    vm.tickets.serviceid=vm.serviceDetails.id;
                }
            }

            if (vm.tickets.incidentCategory==='Technical') {
                vm.tickets.nextLevelMemberId=vm.ticketsMemberDetail.id;
                vm.tickets.nextLevelMember=vm.ticketsMemberDetail.login;
                vm.tickets.nextLevelMemberName=vm.ticketsMemberDetail.firstName+" "+vm.ticketsMemberDetail.lastName;
            }

            if (vm.tickets.incidentCategory==='HOBPAS') {
                vm.tickets.nextLevelMemberId=vm.ticketsMemberDetail.id;
                vm.tickets.nextLevelMember=vm.ticketsMemberDetail.login;
                vm.tickets.nextLevelMemberName=vm.ticketsMemberDetail.firstName+" "+vm.ticketsMemberDetail.lastName;
            }
            if (vm.tickets.incidentCategory==='Incentives') {
                vm.tickets.nextLevelMemberId=vm.ticketsMemberDetail.id;
                vm.tickets.nextLevelMember=vm.ticketsMemberDetail.login;
                vm.tickets.nextLevelMemberName=vm.ticketsMemberDetail.firstName+" "+vm.ticketsMemberDetail.lastName;
            }

                if(vm.isSearchResult){
                if(vm.registered.cafpin===null){
                    vm.tickets.cafpin = vm.registered.id;
                } else {
                    vm.tickets.cafpin = vm.registered.cafpin;
                }
                if(vm.registered.email!=null){
                    vm.tickets.investorDetail=vm.registered.email;
                }
                else{
                    vm.tickets.investorDetail=vm.registered.mobileNumber;
                }
                vm.tickets.investorType=vm.registered.customerType;
            }
            if(vm.isSearchedCustomerAdded){
                vm.tickets.cafpin = vm.oldTicketCustomer.id;

                if(vm.oldTicketCustomer.email!=null){
                    vm.tickets.investorDetail=vm.oldTicketCustomer.email;
                }
                else{
                    vm.tickets.investorDetail=vm.oldTicketCustomer.mobileNumber;
                }
                vm.tickets.investorType=vm.oldTicketCustomer.customerType;
            }
            if(vm.newCustomer){
                if(vm.ticketsCustomer.email!=null){
                    if(!vm.ticketsCustomer.email.includes("@")){
                        vm.ticketsCustomer.email = null;
                    }
                }
                if(vm.ticketsCustomer.district === "Other"){
                    vm.ticketsCustomer.district=vm.otherField;
                }
                TicketsCustomer.save(vm.ticketsCustomer, onCustomerSave, onCustomerError);
            }else{
                Tickets.save(vm.tickets, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            alert("Ticket added successfully with Ticket Number:"+result.ticketNumber);
            $state.reload();
        }

        function onSaveError () {
            vm.isSaving = false;
            alert("Error while generating ticket");
            $state.reload();
        }

        function onCustomerSave (result) {
            if(result.email!=null){
                vm.tickets.investorDetail=result.email;
            } else {
                vm.tickets.investorDetail=result.mobileNumber;
            }
            vm.tickets.investorType = "HepcTicketCustomer";
            vm.tickets.cafpin=result.id;
            Tickets.save(vm.tickets, onSaveSuccess, onSaveError);
        }

        function onCustomerError () {
            alert("Error while adding customer");
            $state.reload();
        }

        function clear(){
            vm.customerMobile = null;
            vm.isCustomerMobileSearch = false;
            vm.isSearchedCustomerAdded = false;
            vm.searchBy=null;
            vm.searchValue=null;
            vm.otherField=null;
            vm.newCustomer=false;
            vm.isShowSearchByMobile= false;
            vm.isSearchResult=false;
            vm.ticketsCustomer = {};
            vm.ticketsCustomer.email = null;
            vm.registered={};
        }
    }
})();

