(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ProceduresController', ProceduresController);

    ProceduresController.$inject = [];

    function ProceduresController () {
        var vm = this;


    }
})();
