(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ClosedTicketViewController', ClosedTicketViewController);

    ClosedTicketViewController.$inject = ['Principal','Tickets', 'TicketsLogs', 'downloadticketattachment'];

    function ClosedTicketViewController(Principal,Tickets, TicketsLogs, downloadticketattachment) {
        var vm = this;
        vm.tickets = [];
        vm.getLogs=getLogs;
        vm.downloadAttachment=downloadAttachment;
        loadAll();

        function loadAll() {
            Principal.identity().then(function(account) {
                vm.account = account;
                Tickets.query({assignedMember: vm.account.login, status: "Closed"},function(result){
                   vm.tickets = result;
                   vm.searchQuery = null;
                });
            });
        }

        function getLogs(ticketDetails) {
            TicketsLogs.query({ticketNumber: ticketDetails.ticketNumber},function (result) {
                vm.ticketsLogs=result;
            })
        }

        function downloadAttachment(attachment) {
            downloadticketattachment.download(attachment)
                .then(function(success) {

                }, function(error) {
                    alert('error : ' + error);
                });
        }
    }
})();
