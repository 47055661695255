(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('allratingsummary', {
            parent: 'feedback-ratings',
            url: '/feedback-ratings/all/{status}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/feedbackratingdashboard/allratingsummary.html',
                    controller: 'AllRatingSummaryController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    windowClass: 'app-modal-window',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('feedback-ratings');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('feedback-ratings', null, { reload: 'feedback-ratings' });
                }, function() {
                    $state.go('feedback-ratings');
                });
            }]
        });
    }
})();
