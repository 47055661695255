(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerDioProjectDialogController', TowerDioProjectDialogController);

    TowerDioProjectDialogController.$inject = ['$scope','$stateParams','GetServiceLog','serviceFormFieldDataCollection','ServiceFormAttachments','$uibModalInstance','downloadserviceformattachment','ProjectActionTowerDio','projectService'];

    function TowerDioProjectDialogController ($scope,$stateParams,GetServiceLog,serviceFormFieldDataCollection,ServiceFormAttachments,$uibModalInstance,downloadserviceformattachment,ProjectActionTowerDio,projectService) {
        var vm = this;
        vm.projects=[];
        vm.downloadFile=downloadFile;
        vm.clearService=clearService;
        vm.addComment=addComment;
        vm.deactivateService=deactivateService;
        vm.rejectService=rejectService;
        vm.reactivate=reactivate;
        vm.undoreject=undoreject;
        vm.status=$stateParams.action;
        vm.projectAttachemnt={};

        $scope.file = {};

        loadProject();

        function loadProject() {
            vm.project=projectService;
           switch (vm.status){
               case 'service-logs':
                   viewlogs(vm.project);
                   break;
               case 'view-form':
                   loadFilledForm(vm.project);
                   break;
           }
        }

        vm.pdfconverter= function  () {
            console.log("called");
            var element = document.getElementById('pdf_data');
            html2pdf(element,{
                margin: 4
            });
        };

        function viewlogs(project) {
            vm.project=project;
            GetServiceLog.query({projectid:project.projectid,serviceid:project.serviceid},function (result) {
                vm.ServiceLog=result;
            });
        }

        function loadFilledForm(project) {
            vm.project=project;
            vm.serviceFormFieldData= serviceFormFieldDataCollection.query({serviceid:project.serviceid, projectid:project.projectid});
            vm.serviceformattachments=ServiceFormAttachments.query({id:"1",serviceid:project.serviceid, projectid:project.projectid});
        }


        function clearService() {
            ProjectActionTowerDio.clear(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function addComment() {
            console.log(vm.projectAttachemnt);
            ProjectActionTowerDio.comment(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function deactivateService() {
            ProjectActionTowerDio.deactivate(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function rejectService() {
            ProjectActionTowerDio.reject(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function reactivate() {
            ProjectActionTowerDio.reactivate(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function undoreject() {
            ProjectActionTowerDio.undoreject(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function downloadFile(serverfilename,downloadfilename)
        {
            downloadserviceformattachment.download(serverfilename,downloadfilename)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
                });
        }

        vm.clear=function () {
            $uibModalInstance.close();
        }

        function onsuccess(result) {
            console.log(result);
            $uibModalInstance.close();
        }

        function onerror() {

        }
    }
})();
