(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcRecruitmentDetailController', HepcRecruitmentDetailController);

    HepcRecruitmentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'HepcRecruitment','viewRecruitmentFile','HepcRecruitmentQualificationsByRefId','HepcRecruitmentExperienceByRefId'];

    function HepcRecruitmentDetailController($scope, $rootScope, $stateParams, previousState, entity, HepcRecruitment,viewRecruitmentFile,HepcRecruitmentQualificationsByRefId,HepcRecruitmentExperienceByRefId) {
        var vm = this;

        vm.hepcRecruitment = entity;
        vm.previousState = previousState.name;
        vm.viewDocument = viewDocument;
        var unsubscribe = $rootScope.$on('investhryApp:hepcRecruitmentUpdate', function(event, result) {
            vm.hepcRecruitment = result;
        });
        vm.hepcRecruitmentQualifications = [];
        vm.hepcRecruitmentExperiences = [];
        $scope.$on('$destroy', unsubscribe);

        function viewDocument(document){
            if(document === 'photo'){
                viewRecruitmentFile.view(entity.photograph);
            }else if(document === 'sign'){
                viewRecruitmentFile.view(entity.signature);
            }else if(document === 'resume'){
                viewRecruitmentFile.view(entity.resume);
            }else viewRecruitmentFile.view(document);
        }
        loadAll();
        function loadAll() {
            HepcRecruitmentQualificationsByRefId.query({refId: vm.hepcRecruitment.id}, function (data) {
                vm.hepcRecruitmentQualifications = data;
            });
            HepcRecruitmentExperienceByRefId.query({refId: vm.hepcRecruitment.id}, function (data) {
                console.log(data);
                vm.hepcRecruitmentExperiences = data;
            })
        }
    }
})();
