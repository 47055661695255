(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketsLogsController', TicketsLogsController);

    TicketsLogsController.$inject = ['TicketsLogs'];

    function TicketsLogsController(TicketsLogs) {

        var vm = this;
        vm.ticketsLogs = [];
        vm.search = false;
        vm.searchTicketLogs = searchTicketLogs;

        function searchTicketLogs(columnValues) {
            vm.search = true;
            TicketsLogs.query({ticketNumber: columnValues},function (result) {
                vm.ticketsLogs=result;
            });
        }
    }
})();
