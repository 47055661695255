(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HomePageCountOutputController', HomePageCountOutputController);

    HomePageCountOutputController.$inject = ['HomePageCountOutput'];

    function HomePageCountOutputController(HomePageCountOutput) {

        var vm = this;

        vm.homePageCountOutputs = [];

        loadAll();

        function loadAll() {
            HomePageCountOutput.query(function(result) {
                vm.homePageCountOutputs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
