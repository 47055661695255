(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('inspectionOfficerDeptController', inspectionOfficerDeptController);

    inspectionOfficerDeptController.$inject = ['$rootScope', '$scope', '$stateParams','$state', 'ConstructionPermitApprovalSteps', 'ConstructionPermitLog', 'ConstructionPermitApproval', 'InspectionReport', 'FileManagement'];

    function inspectionOfficerDeptController ($rootScope, $scope, $stateParams, $state, ConstructionPermitApprovalSteps, ConstructionPermitLog, ConstructionPermitApproval, InspectionReport, FileManagement) {
        var vm = this;
        var savefilename=null;
        vm.constructionPermit={};
        vm.constructionPermitLog={};
        vm.inspectionReport={};
        $scope.file = {};
        vm.count=0;


        loadAll();

        function loadAll() {
            ConstructionPermitApprovalSteps.query(function (result) {
                vm.pendingConstructionPermits= result;
            });
        }

        function ontempSaveSuccess(result) {
            vm.inspectionReport=result;
            console.log(vm.inspectionReport);
        }

        vm.uploadInspectionReport= function (data) {
            ConstructionPermitApproval.query({projectId: data.projectId},function (result) {
                vm.constructionPermit = result;
                vm.inspectionReport.projectid = vm.constructionPermit.projectId;
                vm.inspectionReport.reportDescription = "Inspection Report Uploaded";
                vm.inspectionReport.serviceid = vm.constructionPermit.serviceId;
                InspectionReport.save(vm.inspectionReport,ontempSaveSuccess);
            })
        };

        vm.uploadfile= function (object,count) {
            vm.count=count;
            var fileNameArray = object.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var filename = vm.constructionPermit.id+"_"+count;
            savefilename=filename+ "." + fileextension;
            FileManagement.saveFile(object,filename,onFileSaveSuccess);
        };

        function onFileSaveSuccess() {
            InspectionReport.get({id:vm.inspectionReport.id},function (result) {
                console.log(result);
            });
            if(vm.count===1){
                vm.inspectionReport.file1 = savefilename;
            }
            else if(vm.count===2){
                vm.inspectionReport.file2 = savefilename;
            }
            else if(vm.count===3){
                vm.inspectionReport.file3 = savefilename;
            }
            else if(vm.count===4){
                vm.inspectionReport.file4 = savefilename;
            }
            else if(vm.count===5){
                vm.inspectionReport.file5 = savefilename;
            }
            else if(vm.count===6){
                vm.inspectionReport.file6 = savefilename;
            }
            else if(vm.count===7){
                vm.inspectionReport.file7 = savefilename;
            }
            else if(vm.count===8){
                vm.inspectionReport.file8 = savefilename;
            }
            else if(vm.count===9){
                vm.inspectionReport.file9 = savefilename;
            }
            else if(vm.count===10){
                vm.inspectionReport.file10 = savefilename;
            }
            InspectionReport.update(vm.inspectionReport,onIRSaveSuccess);
        }

        function onIRSaveSuccess(result) {
            console.log("onIRSaveSuccess");
            vm.constructionPermit.serviceIdEC=result.id;
            vm.constructionPermit.fireNOCflag=true;
            vm.constructionPermit.isFileUploaded=true;
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onCPstepsSaveSuccess);
        }

        function onCPstepsSaveSuccess(result) {
            vm.constructionPermitLog.action="Inspection Report Uploaded";
            vm.constructionPermitLog.projectid= vm.constructionPermit.projectId;
            vm.constructionPermitLog.serviceid= vm.constructionPermit.serviceId;
            ConstructionPermitLog.save(vm.constructionPermitLog);
        }

        vm.onDoneSaveSuccess= function () {
            loadAll();
        }
    }
})();
