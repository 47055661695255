(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Below_groundDetailController', Below_groundDetailController);

    Below_groundDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Below_ground'];

    function Below_groundDetailController($scope, $rootScope, $stateParams, previousState, entity, Below_ground) {
        var vm = this;

        vm.below_ground = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:below_groundUpdate', function(event, result) {
            vm.below_ground = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
