(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('assignServiceDialogController', assignServiceDialogController);

    assignServiceDialogController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','projectService','projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLogUpdated','FileManagement','Projectservicedetail','ProjectServiceReportInfoForClearance','CheckContentFile'];
    function assignServiceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, projectService, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, FileManagement, Projectservicedetail, ProjectServiceReportInfoForClearance,CheckContentFile) {
        var vm = this;
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectService = projectService;
//        vm.projectservicereportinfo = ProjectServiceReportInfoForClearance.get({projectid:vm.projectService.projectid,department:vm.projectService.departmentName,service:vm.projectService.serviceName});
        var projectAttachmentResultObject=null;
        var projectServiceLogid=null;
        $scope.file = {};
        vm.clear = clear;
        vm.assignService = assignService;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function assignService(){
            if(angular.isDefined(vm.projectAttachemnt.file)){
                if(CheckContentFile.checkFile(vm.projectAttachemnt.file.name)){
                    assignServiceFeeAftercheck();
                }
                else{
                    alert("File Format Should be Correct");
                }
            }
            else{
                assignServiceFeeAftercheck();
            }
        }
        function onServiceLogSaveSuccess(result)
        {
            projectServiceLogid=result.id;
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            vm.projectService.isAssigned=true;
            vm.projectService.latestComments=vm.projectServiceLog.comments;
            vm.projectService.serviceFee=vm.projectservicedetail.serviceFee;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
        }
//        function onUpdateProjectServiceSuccess(result)
//        {
//            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
//            vm.projectservicereportinfo.assignDate=result.assigOnDate;
//            ProjectServiceReportInfoForClearance.update(vm.projectservicereportinfo,onUpdateProjectServiceReportInfoSuccess,onUpdateProjectServiceReportInfoError);
//        }
        function onUpdateProjectServiceSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceReportInfoUpdate', result);
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }
//        function onUpdateProjectServiceReportInfoError()
//        {
//            vm.isSaving = false;
//        }
        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }
        function saveProjectAttachment()
        {
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Service Assign Attachment";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }
        function onSaveProjectAttachmentSuccess(result)
        {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;
            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }
        function onSaveProjectAttachmentError()
        {
            vm.isSaving = false;
        }
        function onUpdateProjectAttachmentSuccess(result)
        {
            $uibModalInstance.close(result);
        }
        function onUpdateProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        function assignServiceFeeAftercheck(){
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.actionTaken="Assigned Service Fee";
            ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);
        }
    }
})();
