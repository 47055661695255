(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('rejectionReasonController', rejectionReasonController);

    rejectionReasonController.$inject = ['$scope','$state', 'Projectlist', 'Principal','ProjectServiceLog'];

    function rejectionReasonController($scope, $state, Projectlist ,Principal,ProjectServiceLog) {
        var vm = this;
        vm.accout = null;
        vm.projectlist ={};

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        

        getProjectServiceLog();
        function getProjectServiceLog() {
            ProjectServiceLog.query({status:'Declined'},function (result) {
                vm.projectlist = result;
            })

        }

    }
})();



