(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievanceViewStatsDetailController', GrievanceViewStatsDetailController);

    GrievanceViewStatsDetailController.$inject = ['$scope', 'Principal', '$uibModalInstance', '$stateParams', 'LoginService', '$state', 'GrievanceViewStatsDetail'];

    function GrievanceViewStatsDetailController ($scope, Principal, $uibModalInstance, $stateParams, LoginService, $state, GrievanceViewStatsDetail) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        vm.district=$stateParams.district;
        vm.status=$stateParams.status;

        vm.clear = clear;

        loadAllGrievancesStatus();
        function loadAllGrievancesStatus() {
            vm.grievancestats=GrievanceViewStatsDetail.query({departmentId:$stateParams.departmentId,districtId:$stateParams.districtId,status:$stateParams.status});
            vm.show=true;
        }

        function clear ()
        {
            $uibModalInstance.dismiss('cancel');
        }



        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

    }
})();
