(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('showdlccController', showdlccController);

    showdlccController.$inject = ['$scope', '$stateParams', 'Principal', 'LoginService', '$state','ServiceCafSummaryNodal','ShowDLCC','ShowButton'];

    function showdlccController ($scope, $stateParams, Principal, LoginService, $state,ServiceCafSummaryNodal,ShowDLCC,ShowButton) {
        var vm = this;

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.loadAll = loadAll;

        vm.status = 'Pending';

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        loadAll();

        function loadAll() {
            vm.districtsList = [];
            vm.servicesList = [];

            ShowDLCC.get({
                status: vm.status,
                page: $scope.currentPage - 1,
                size: $scope.pageSize
            },function(result) {
                vm.projectlistDLCC = result;

                angular.forEach(result.content, function(value, key){
                    if(vm.districtsList.indexOf(value.district) === -1){
                        vm.districtsList.push(value.district);
                    }
                    if(vm.servicesList.indexOf(value.serviceName) === -1){
                        vm.servicesList.push(value.serviceName);
                    }
                });

            });
        }

        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
        }

        $scope.pageChangedHandler = function(num) {
            loadAll();
        };
    }
})();
