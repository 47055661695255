(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('migratedata', {
            parent: 'app',
            url: '/migratedata',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/migratedata/migratedata.html',
                    controller: 'MigrateDataController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('indexMigrateData', {
                    parent: 'app',
                    url: '/indexMigrateData',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/migratedata/indexMigrateData.html',
                            controller: 'IndexMigrateDataController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                 }]
             }
        })
    .state('uhbvnAutoRequired', {
            parent: 'app',
            url: '/uhbvnAutoRequired',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/migratedata/uhbvnAutoRequired.html',
                    controller: 'UHBVNAutoRequired',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
