(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('EodbTotalDeptServicesDetailYearlyController', EodbTotalDeptServicesDetailYearlyController);

    EodbTotalDeptServicesDetailYearlyController.$inject = ['$scope', '$window', '$stateParams','$filter', 'Principal', '$uibModalInstance', 'LoginService', '$state', 'EodbTotalDasboardDetailYearly', 'EodbTotalDeptServicesDetailYearly'];

    function EodbTotalDeptServicesDetailYearlyController ($scope, $window, $stateParams,$filter, Principal, $uibModalInstance, LoginService, $state, EodbTotalDasboardDetailYearly, EodbTotalDeptServicesDetailYearly) {
        var vm = this;

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";
        // vm.download = download;

        vm.status = $stateParams.status;
        vm.clear = clear;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        var args = [];

        vm.spiner =true;
        vm.datePickerOpenStatus = {};
        vm.sortingdates={};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;
        vm.selectedDate=selectedDate;
        var d=null;
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.dateOptions = {
            minDate: new Date("05-01-2017"),
            maxDate: Date.now()
        };

        function selectedDate(dateSelected){
            var newDate = new Date(dateSelected);
            newDate.setDate(newDate.getDate()+365);

            if(newDate > Date.now()){
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: Date.now()
                };
            }
            else {
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: newDate
                };
            }
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        loadAllCafs();
        function loadAllCafs() {
            vm.totaldeptservicesdetail=EodbTotalDasboardDetailYearly.get(
                {status:$stateParams.status,
                    page: $scope.currentPage - 1,
                    size: $scope.pageSize
                },
                function (result) {
                    vm.show=true;
                vm.spiner=false;
            });
        }

        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
        }
        $scope.pageChanged = function(num) {
            loadAllCafs();
        };

        // function convertJsonsToArrays(args){
        //     var dataArray = [];
        //     var header = [ "CAFPin",
        //         "Status",
        //         "RTS > 30",
        //         "RTS > 45",
        //         "RTS > 65",
        //         "Within RTS",
        //         "District",
        //         "Department",
        //         "Investor Name",
        //         "Investor Contact",
        //         "Investor Email",
        //         "Project Level",
        //         "Proposed Employment",
        //         "Project Investment" ,
        //         "Applied Date",
        //         // "Address",
        //         "Land Zone Use Type",
        //         "Service Name",
        //         "Business Entity"
        //     ];
        //     dataArray.push(header);
        //     for( var i = 0; i < args.length; i++ ){
        //         var row = args[i];
        //         var rowAr=[];
        //         var appliedDate=$filter('date')(row.formFilledOn, 'dd-MM-yyyy');
        //         rowAr.push(row.cafpin);
        //         rowAr.push(row.status);
        //         rowAr.push(row.rtsGreaterThan30);
        //         rowAr.push(row.rtsGreaterThan45);
        //         rowAr.push(row.rtsGreaterThan65);
        //         rowAr.push(row.withinRts);
        //         rowAr.push(row.district);
        //         rowAr.push(row.departmentName);
        //         rowAr.push(row.investorName);
        //         rowAr.push(row.investorMobilenumber);
        //         rowAr.push(row.investorEmail);
        //         rowAr.push(row.projectLevel);
        //         rowAr.push(row.proposedEmployment);
        //         rowAr.push(row.proposedInvestment);
        //         rowAr.push(appliedDate);
        //         // rowAr.push(row.investorAddress);
        //         rowAr.push(row.landZoneUseType);
        //         rowAr.push(row.serviceName);
        //         rowAr.push(row.businessEntity);
        //         dataArray.push(rowAr);
        //     }
        //     return dataArray;
        // }
        //
        // function convertJsonsToArrays1(args){
        //     var dataArray = [];
        //     var header = [ "CAFPin",
        //         "Business Entity",
        //         "District",
        //         "Department",
        //         "Investor Name",
        //         "Investor Contact",
        //         "Investor Email",
        //         "Service Name",
        //         "Project Level",
        //         "Proposed Employment",
        //         "Project Investment" ,
        //         "Applied Date",
        //         "Completion Date",
        //         "Address",
        //         "Land Zone Use Type"
        //     ];
        //     dataArray.push(header);
        //     for( var i = 0; i < args.length; i++ ){
        //         var row = args[i];
        //         var rowAr=[];
        //         var appliedDate=$filter('date')(row.formFilledOn, 'dd-MM-yyyy');
        //         var clearedDate=$filter('date')(row.clearedDate, 'dd-MM-yyyy');
        //         rowAr.push(row.cafpin);
        //         rowAr.push(row.businessEntity);
        //         rowAr.push(row.district);
        //         rowAr.push(row.departmentName);
        //         rowAr.push(row.investorName);
        //         rowAr.push(row.investorMobilenumber);
        //         rowAr.push(row.investorEmail);
        //         rowAr.push(row.serviceName);
        //         rowAr.push(row.projectLevel);
        //         rowAr.push(row.proposedEmployment);
        //         rowAr.push(row.proposedInvestment);
        //         rowAr.push(appliedDate);
        //         rowAr.push(clearedDate);
        //         rowAr.push(row.investorAddress);
        //         rowAr.push(row.landZoneUseType);
        //         dataArray.push(rowAr);
        //     }
        //     return dataArray;
        // }

        // function download(status) {
        //     var fromdate=$filter('date')(vm.fromdate, "dd-MM-yyyy");
        //     var todate=$filter('date')(vm.todate, "dd-MM-yyyy");
        //     EodbTotalDownloadDetailYearly.query({status: status, fromdate:fromdate,todate:todate}, function (data) {
        //             args = data;
        //         if(status === "Required" || status === "Pending" || status === "Applied"){
        //             var rowsCleared= convertJsonsToArrays(args);
        //         }
        //         else{
        //             var rows = convertJsonsToArrays1(args);
        //
        //         }
        //         var lineArray = [];
        //         var lineArrayCleared = [];
        //         if(status === "Required" || status === "Pending" || status === "Applied"){
        //             for(var i = 0; i < rowsCleared.length; i++){
        //                 var lineCleared = rowsCleared[i].join("\t");
        //                 lineArrayCleared.push(lineCleared);
        //             }
        //             lineArrayCleared = lineArrayCleared.join("\n");
        //             var a = document.createElement('a');
        //             var blob = new Blob([lineArrayCleared],{type: 'data:text/plain;charset=utf-8;'});
        //             var url = URL.createObjectURL(blob);
        //             $window.location.href = url;
        //         }
        //         else{
        //             for(var i = 0; i < rows.length; i++){
        //                 var line = rows[i].join("\t");
        //                 lineArray.push(line);
        //             }
        //             lineArray = lineArray.join("\n");
        //             var a = document.createElement('a');
        //             var blob = new Blob([lineArray],{type: 'data:text/plain;charset=utf-8;'});
        //             var url = URL.createObjectURL(blob);
        //             $window.location.href = url;
        //         }
        //
        //     });
        // }
    }
})();
