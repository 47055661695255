(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MigrateDataController', MigrateDataController);

    MigrateDataController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'MigrateData', 'MigrateApplicationDate', 'Service_dashboard_output', 'MigrateDashboardBetweenDatesCAFs', 'GrievanceDashboardOutput', 'MigrateDashboardBetweenDates', 'SetTrue', 'Totalservicedashboarddetails', 'SetGrievanceAttachmentNull', 'Tower_service_dashboard', 'ShareAllDataMOU', 'District', 'Department', 'filterFilter', 'Towerofficerlist', 'ManageDuplicate', 'grievanceReAssign', 'MigrateNewDashboardStats', 'BulkEmail', 'Cafservicestatus', 'ViewServiceStatus', 'MigrateRatingsData','ReApplyReDashboardMigrate', 'MigrateRatings','MigrateProjectByEmail', 'SaveMultipleUserWithSingleMail', 'MultipleUserWithSingleMailWithoutCaf', 'TicketsLogs','MultipleUserWithSingleMailWithCaf','ExistingCafToZero','MigrateNewCafLogs', 'MigrateDistrictNodalOfficer','ProjectToExistingCafSearch','MigrateHomePageCounts','MigrateDatesToServiceDetailFromLog', 'MigrateServicelogToReappliedservices','MigrateServiceDashboardFlagservices', 'ServiceDashboardStats','MigrateProjectServiceDetailToIndex', 'DraftHavingServices', 'SetTowerDatesToNull', 'SetStatusDates','MigrateRejectionReason','ProjectElasticData','MigrateDuplicateRM', 'AssignProjectToRM', 'TicketScheduler','MigrateProjectServiceDetailToElastic','MigrateServiceDahboradToElastic', 'MigrateTicketDashboard','MigrateCcip', 'MigrateProjectServiceCcip'];

    function MigrateDataController($scope, Principal, LoginService, $state, MigrateData, MigrateApplicationDate, Service_dashboard_output, MigrateDashboardBetweenDatesCAFs, GrievanceDashboardOutput, MigrateDashboardBetweenDates, SetTrue, Totalservicedashboarddetails, SetGrievanceAttachmentNull, Tower_service_dashboard, ShareAllDataMOU, District, Department, filterFilter, Towerofficerlist, ManageDuplicate, grievanceReAssign, MigrateNewDashboardStats, BulkEmail, Cafservicestatus, ViewServiceStatus, MigrateRatingsData,ReApplyReDashboardMigrate, MigrateRatings,MigrateProjectByEmail, SaveMultipleUserWithSingleMail, MultipleUserWithSingleMailWithoutCaf, TicketsLogs,MultipleUserWithSingleMailWithCaf, ExistingCafToZero,MigrateNewCafLogs, MigrateDistrictNodalOfficer, ProjectToExistingCafSearch, MigrateHomePageCounts,MigrateDatesToServiceDetailFromLog, MigrateServicelogToReappliedservices,MigrateServiceDashboardFlagservices, ServiceDashboardStats,MigrateProjectServiceDetailToIndex, DraftHavingServices, SetTowerDatesToNull, SetStatusDates,MigrateRejectionReason,ProjectElasticData , MigrateDuplicateRM, AssignProjectToRM, TicketScheduler,MigrateProjectServiceDetailToElastic, MigrateServiceDahboradToElastic, MigrateTicketDashboard, MigrateCcip, MigrateProjectServiceCcip) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.migrate_project_elastic_data = migrate_project_elastic_data;
        vm.migratedashboardbetweendatescafsdata = migratedashboardbetweendatescafsdata;
        vm.migratedashboardbetweendatesdata = migratedashboardbetweendatesdata;
        vm.setAttachmentNull = setAttachmentNull;
        vm.migrate_reapply_dashboard_data = migrate_reapply_dashboard_data;

        //vm.migrate_ticket_dashboard_elastic_data = migrate_ticket_dashboard_elastic_data;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });
        loadAll();

        vm.migratedata = migratedata;
        vm.migrate_service_dashboard_data = migrate_service_dashboard_data;

        vm.migrateGrievanceDashboardData = migrateGrievanceDashboardData;

        vm.migrate_nodalofficer_dashboard_data = migrate_nodalofficer_dashboard_data;

        vm.setisrequiredtotrue = setisrequiredtotrue;

        vm.migrateCafServiceStatus = migrateCafServiceStatus;

        vm.migrateApplicationDate = migrateApplicationDate;

        function loadAll() {
            District.query(function (result) {
                vm.districts = result;
                vm.searchQuery = null;
            });
            Department.query(function (result) {
                vm.departments = filterFilter(result, {istower: true});
                vm.searchQuery = null;
            });
            Towerofficerlist.query(function (result) {
                vm.towerofficerlists = result;
                vm.searchQuery = null;
            });
        }

        function migratedata() {
            MigrateData.save();
        }

        function migrate_service_dashboard_data() {
            Service_dashboard_output.query({migrate: 'migrate'});
        }
        function migrate_project_elastic_data() {
            ProjectElasticData.query({migrate: 'migrate'});
        }

        function migrateGrievanceDashboardData() {
            GrievanceDashboardOutput.query({migrate: 'migrate'});
        }

        function migrate_nodalofficer_dashboard_data() {
            MigrateData.get();
        }
        function migrate_reapply_dashboard_data() {
            ReApplyReDashboardMigrate.get();
        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register() {
            $state.go('register');
        }

        function migratedashboardbetweendatescafsdata() {
            MigrateDashboardBetweenDatesCAFs.get();
        }

        function migratedashboardbetweendatesdata() {
            MigrateDashboardBetweenDates.get();
        }

        function setisrequiredtotrue() {
            SetTrue.get();
        }


        vm.migrate_min_max_to_Elastic = function () {
            MigrateTicketDashboard.query();
        };

        vm.migrateTotalDashboardData = function () {
            Totalservicedashboarddetails.query({migrate: 'migrate'});
        };

        vm.migrate_tower_service_dashboard_data1 = function () {
            Tower_service_dashboard.query({migrate: 'migrate1'});
        };

        vm.migrate_tower_service_dashboard_data2 = function () {
            Tower_service_dashboard.query({migrate: 'migrate2'});
        };

        vm.setReapplyCounter = function () {
            Tower_service_dashboard.query({migrate: 'migrate3'});
        };

        vm.setDepartmentByChoice = function () {
            Tower_service_dashboard.save({id: '1'}, vm.data);
        };

        vm.migrateIntegrationData = function () {
            Tower_service_dashboard.query({migrate: 'migrate4'});
        };

        function setAttachmentNull() {
            SetGrievanceAttachmentNull.query();
        }

        vm.shareAllDataToMou = function () {
            ShareAllDataMOU.get();
        };

        vm.showDuplicate = function (dept) {
            vm.duplicateIds = null;
            ManageDuplicate.query(function (result) {
                vm.duplicateIds = result;
            });
        };

        vm.reAssignGrievance = function () {
            grievanceReAssign.get({
                assignby: vm.assignBy,
                grievanceId: vm.grievanceid,
                assignto: vm.assignto,
                assigndate: vm.assigndate
            }, onsuccess);
        };

        vm.migrateGrievancewithAssignBy = function () {
            grievanceReAssign.query(onsuccess);
        };

        function onsuccess(result) {
            vm.duplicateIds = result;
            console.log(result);
        }

        vm.migrateNewDashboardStats = function () {
            console.log("now migrating...");
            MigrateNewDashboardStats.get();
        }

        function migrateCafServiceStatus() {
            console.log("now migrating...");
            Cafservicestatus.query({cafpin: '678789'});

            vm.fireBulkEmails = function () {
                console.log("fired bulk emails...");
                BulkEmail.get();
            }

            vm.testFireBulkEmails = function () {
                console.log("test fire bulk email...");
                BulkEmail.get({test: "test"});
            }
        }

        function migrateApplicationDate() {
            MigrateApplicationDate.get();
        }


        vm.migratePreviousServiceStats = function () {
            ViewServiceStatus.query({migrateData: 'migrateData'});
        }

        vm.migrateServiceStatusData = function () {
            ViewServiceStatus.query({serviceStatusTable:'serviceStatusTable'})

        }

        vm.migrateRatingsData = function(){
            MigrateRatingsData.get();
        }

        vm.migrateRatings = function(){
            MigrateRatings.get();
        }

        vm.saveMultipleUsersWithSingleMail = function(){
            SaveMultipleUserWithSingleMail.query();
        }
        vm.saveMultipleUserWithSingleMailWithoutCaf = function(){
            MultipleUserWithSingleMailWithoutCaf.query();
        }
        vm.saveMultipleUserWithSingleMailWithCaf = function(){
            MultipleUserWithSingleMailWithCaf.query();
        }


        vm.migrateProjectByEmail = function () {
            MigrateProjectByEmail.query({migrateProjectByEmail:'migrateProjectByEmail'});
        }

        vm.migrateHepcTicketlogsData = function (action) {
            TicketsLogs.query({migrateData: action}, function (result) {
                    vm.migrateData = result;
                    alert("success");
                }, function () {
                    alert("error");
                });
        };

        vm.migrateExistingCafsToZero = function () {
            ExistingCafToZero.get(function (data) {
                alert('Migration Done');
            });
        };

        vm.migrateNewCafLogs = function(){
            MigrateNewCafLogs.get();
        };
        vm.migrateHomePageCounts = function() {
            MigrateHomePageCounts.get(function (data) {
                alert('Migration Done');
            });
        };
        vm.migrateDistrictNodalOfficer = function(){
            MigrateDistrictNodalOfficer.get();
        };

        vm.projectToExistingCafSearch = function(){
            ProjectToExistingCafSearch.get(function (data) {
                alert('Migration Done');
            });
        };
        vm.migrateDatesToServiceDetailfromLog = function(){
            MigrateDatesToServiceDetailFromLog.get();
        };
        vm.migrateServicelogToReappliedservices = function(){
            MigrateServicelogToReappliedservices.get();
        };
        vm.migrate_service_dashboard_april_2018 = function () {
            ServiceDashboardStats.query({migrate: 'NA'}, function (data) {
                alert('Migration Done');
                console.log(data);
            })
        };
        vm.migrateProjectServiceDetailToIndex = function () {
            MigrateProjectServiceDetailToIndex.query(function () {
                alert('Migration Done');
            })
        };

        vm.migrate_projectservicedetail_elastic_data = function (){
            MigrateProjectServiceDetailToElastic.query(function () {
                alert('Migration Done');
            })
        };
        vm.removeServiceFromDashboardFlag = function(){
            MigrateServiceDashboardFlagservices.get();
        };
        vm.setTowerDatesToNull = function(){
           SetTowerDatesToNull.get();
        };

        vm.calculateDraftsHavingServices = function () {
            DraftHavingServices.get();
        };
        vm.setStatusDates = function(){
            SetStatusDates.get();
        };
        vm.saveRejectionReason = function () {
            MigrateRejectionReason.query({status:'Declined'});
        };

        vm.migrateAssignedRM = function () {
            MigrateDuplicateRM.query({delete:'Duplicate'});
        };

        vm.deleteMultipleRecordsWithSameProjectid = function () {
            AssignProjectToRM.get({multiplerecords:'multiplerecords'});
        };

        vm.openTicketSchedular = function () {
            TicketScheduler.get({ action: "open"}, function () {
                swal("Success!", "Schedular execution completed.!", "success");
            }, function () {
                swal("Error!", "Something bad happened!", "error");
            });
        };

        vm.closeTicketSchedular = function () {
            TicketScheduler.get({ action: "close"}, function () {
                swal("Success!", "Schedular execution completed.!", "success");
            }, function () {
                swal("Error!", "Something bad happened!", "error");
            });
        }

        vm.ccipdata = function () {
            MigrateCcip.get({ action: "close"}, function () {
                swal("Success!", "Schedular execution completed.!", "success");
            }, function () {
                swal("Error!", "Something bad happened!", "error");
            });
        }
        vm.projectServiceCcipdata = function () {
            MigrateProjectServiceCcip.get({ action: "close"}, function () {
                swal("Success!", "Schedular execution completed.!", "success");
            }, function () {
                swal("Error!", "Something bad happened!", "error");
            });
        }
    }
})();
