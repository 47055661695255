(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DecriminalizationController', DecriminalizationController);

    DecriminalizationController.$inject = ['Decriminalization'];

    function DecriminalizationController(Decriminalization) {

        var vm = this;

        vm.decriminalizations = [];

        loadAll();

        function loadAll() {
            Decriminalization.query(function(result) {
                vm.decriminalizations = result;
                vm.searchQuery = null;
            });
        }
    }
})();
