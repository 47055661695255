(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('inspection-report', {
            parent: 'entity',
            url: '/inspection-report',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.inspectionReport.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/inspection-report/inspection-reports.html',
                    controller: 'InspectionReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('inspectionReport');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('inspection-report-detail', {
            parent: 'inspection-report',
            url: '/inspection-report/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.inspectionReport.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/inspection-report/inspection-report-detail.html',
                    controller: 'InspectionReportDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('inspectionReport');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'InspectionReport', function($stateParams, InspectionReport) {
                    return InspectionReport.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'inspection-report',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('inspection-report-detail.edit', {
            parent: 'inspection-report-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/inspection-report/inspection-report-dialog.html',
                    controller: 'InspectionReportDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InspectionReport', function(InspectionReport) {
                            return InspectionReport.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('inspection-report.new', {
            parent: 'inspection-report',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/inspection-report/inspection-report-dialog.html',
                    controller: 'InspectionReportDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                reportDescription: null,
                                file1: null,
                                file2: null,
                                file3: null,
                                file4: null,
                                file5: null,
                                file6: null,
                                file7: null,
                                file8: null,
                                file9: null,
                                file10: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('inspection-report', null, { reload: 'inspection-report' });
                }, function() {
                    $state.go('inspection-report');
                });
            }]
        })
        .state('inspection-report.edit', {
            parent: 'inspection-report',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/inspection-report/inspection-report-dialog.html',
                    controller: 'InspectionReportDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InspectionReport', function(InspectionReport) {
                            return InspectionReport.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('inspection-report', null, { reload: 'inspection-report' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('inspection-report.delete', {
            parent: 'inspection-report',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/inspection-report/inspection-report-delete-dialog.html',
                    controller: 'InspectionReportDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InspectionReport', function(InspectionReport) {
                            return InspectionReport.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('inspection-report', null, { reload: 'inspection-report' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
