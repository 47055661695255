(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('PsTowerDashboardController', PsTowerDashboardController);

    PsTowerDashboardController.$inject = ['$scope','$filter', 'Principal', 'LoginService', '$state', 'DeptServiceDashboard', 'DeptServiceStats','ServiceViewCaf','ServiceDashboardStats' ,'PSTowerDashboardService','District','PsTowerDashboardByFilter', 'Department', 'PSTowerDepartment', 'DownloadRejectionReason', 'DownloadCafCsv', '$window'];

    function PsTowerDashboardController ($scope,$filter, Principal, LoginService, $state, DeptServiceDashboard, DeptServiceStats, ServiceViewCaf, ServiceDashboardStats ,PSTowerDashboardService,District,PsTowerDashboardByFilter, Department, PSTowerDepartment, DownloadRejectionReason, DownloadCafCsv, $window) {
        var vm = this;
        vm.account = null;
        vm.districts = [];
        vm.departments =[];
        vm.filter = {};
        vm.deptName = null;
        vm.districtName = null;
        vm.levelfiled = null;
        vm.hideClear = false;

        var args = [];


        loadAll();
        function loadAll() {
        Principal.identity().then(function(account) {
            vm.account = account;
        });

        District.query(function(result) {
            vm.districts = result;
            vm.searchQuery = null;
        });

            PSTowerDepartment.query(function(result) {
                vm.departments = $filter('filter')(result,{ istower : true });
                console.log(vm.departments);
                vm.searchQuery = null;
            });


        // ServiceDashboardStats.query(function(result) {
            PSTowerDashboardService.query(function(result) {

          vm.deptservicedashboard = result;
           vm.totalappliedservices=0;
           vm.totalrequiredservices=0;
           vm.totalpendingservices=0;
           vm.totalcompletedservices=0;
           vm.totalrejectedservices=0;
           vm.totaldeemedclearedservices=0;
           vm.totalWithdrawServices=0;
            for(var i=0;i<vm.deptservicedashboard.length;i++)
            {
                console.log(vm.deptservicedashboard[i]);
                console.log(vm.deptservicedashboard[i].withdraw);


              vm.totalappliedservices=vm.totalappliedservices + parseInt(vm.deptservicedashboard[i].applied);
              vm.totalrequiredservices=vm.totalrequiredservices+ parseInt(vm.deptservicedashboard[i].required);
              vm.totalpendingservices=vm.totalpendingservices+ parseInt(vm.deptservicedashboard[i].pending_total);
              vm.totalcompletedservices=vm.totalcompletedservices+ parseInt(vm.deptservicedashboard[i].cleared);
              vm.totalrejectedservices=vm.totalrejectedservices+ parseInt(vm.deptservicedashboard[i].rejected);
              vm.totaldeemedclearedservices=vm.totaldeemedclearedservices+ parseInt(vm.deptservicedashboard[i].deemed_cleared);
              vm.totalWithdrawServices=vm.totalWithdrawServices+ parseInt(vm.deptservicedashboard[i].withDraw);
            }
           vm.searchQuery = null;
         });
        }

        vm.FilterWiseDat = function () {
            console.log(vm.filter);
            PsTowerDashboardByFilter.query(vm.filter,function (result) {
                vm.hideClear = true;
                vm.deptservicedashboard =result;
            })
        };
        vm.clearFilter = function () {
            vm.filter ={};
            loadAll();
        };


    }
})();
