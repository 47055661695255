(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('deleteuser', {
                    parent: 'app',
                    url: '/deleteuser',
                    data: {
                        authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/deleteuser/deleteuser.html',
                            controller: 'DeleteUserController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                 }]
             }
        });
    }
})();
