(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('resolveRating', {
            parent: 'feedbacksbyofficer',
            url: '/resolveRating/{ratingId}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_FEEDBACK_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/feedbacksbyofficer/actiontaken.html',
                        controller: 'ActionTakenController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            rating: ['Rating', function(Rating) {
                                return Rating.get({id : $stateParams.ratingId}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                $translatePartialLoader.addPart('feedbacksbyofficer');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('feedbacksbyofficer', null, { reload: 'feedbacksbyofficer' });
                    }, function() {
                        $state.go('feedbacksbyofficer');
                    });
                }]
            })
        }
    })();
