(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('UHBVNAutoRequired', UHBVNAutoRequired);

    UHBVNAutoRequired.$inject = ['$scope', 'Principal', 'LoginService', '$state','UHBVNAutoRequiredRecord'];

    function UHBVNAutoRequired($scope, Principal, LoginService, $state, UHBVNAutoRequiredRecord ) {

        var vm = this;
        vm.record = {};
        vm.projectId = null;
        vm.serviceId = null;
        vm.migrateDataUHBVN = migrateDataUHBVN;

        function migrateDataUHBVN () {
            vm.record= UHBVNAutoRequiredRecord.get({projectId:vm.projectId,serviceId:vm.serviceId},function () {
               console.log(vm.record);
            });



        }
    }
 })();
