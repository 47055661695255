(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DisputeDashboardController', DisputeDashboardController);

   DisputeDashboardController.$inject = ['$scope','$filter', 'Principal','Dispute' ,'Department', 'District', '$location'];

    function DisputeDashboardController ($scope,$filter, Principal, Dispute, Department, District, $location) {
        var vm = this;
        vm.account = null;
        vm.disputes = [];
        vm.departments = [];
        vm.deatils = details;
        vm.districts = [];

        loadAll();
        function loadAll() {
        Principal.identity().then(function(account) {
            vm.account = account;
            ga('set', 'page', $location.path());
            ga('send','pageview');
        });
            Dispute.query(function(result) {
                vm.disputes = result;
           vm.searchQuery = null;
            });

            Department.query(function(result){
                vm.departments = result;
            });
            District.query(function(result){
                vm.districts = result;
            });

        }

        vm.resultDistrictWise = function resultDistrictWise(value) {
            vm.resultList =[];
            vm.testing ={};
            vm.disputeResults = [];
            vm.finalResult = {};
            vm.finalObject = [];

            if(value==='District'){
                for (var i = 0; i < vm.districts.length; i++) {
                    vm.disputeResults = $filter('filter')(vm.disputes,{distrcit:vm.districts[i].districtname});
                    if(vm.disputeResults.length !=0){
                        vm.finalResult.total = vm.disputeResults.length;

                        vm.resolved = $filter('filter')(vm.disputeResults,{isResolved:true});

                        vm.pending = $filter('filter')(vm.disputeResults,{isResolved:false});

                        vm.finalResult.resolved = vm.resolved.length;

                        vm.finalResult.pending= vm.pending.length;
                        vm.finalResult.districtName = vm.districts[i].districtname;
                        vm.finalResult.records =  vm.disputeResults;

                        vm.finalObject.push(vm.finalResult);

                        vm.finalResult = {};
                    }

                }
            }
            if(value==='Department'){
                for (var i = 0; i < vm.departments.length; i++) {
                    vm.disputeResults = $filter('filter')(vm.disputes,{departmentName:vm.departments[i].departmentname});

                    if(vm.disputeResults.length !=0){
                        vm.finalResult.total = vm.disputeResults.length;

                        vm.resolved = $filter('filter')(vm.disputeResults,{isResolved:true});

                        vm.pending = $filter('filter')(vm.disputeResults,{isResolved:false});

                        vm.finalResult.resolved = vm.resolved.length;

                        vm.finalResult.pending= vm.pending.length;
                        vm.finalResult.departmentname = vm.departments[i].departmentname;
                        vm.finalResult.records =  vm.disputeResults;

                        vm.finalObject.push(vm.finalResult);

                        vm.finalResult = {};
                    }

                }
            }


        };
        vm.recordList = [];
        function details(records,value){

             if(value=== 'pending'){
                vm.recordList =$filter('filter')(records,{isResolved:false});
            }
            else if(value=== 'resolved'){
                vm.recordList =$filter('filter')(records,{isResolved:true});
            }
            else {
                vm.recordList = records;
            }

            $('#dispute').modal('show');
        }
    }

})();
