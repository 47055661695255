(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('FeedbackOfficerDialogController', FeedbackOfficerDialogController);

    FeedbackOfficerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FeedbackOfficer', 'District', 'Department'];

    function FeedbackOfficerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FeedbackOfficer, District, Department) {
        var vm = this;

        vm.feedbackOfficer = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.onDepartmentChange = function(department){
            vm.feedbackOfficer.departmentId = department.id;
            vm.departmentName = department.departmentname;
        }

        function save () {
            vm.isSaving = true;
            vm.feedbackOfficer.departmentName = vm.departmentName;
            if (vm.feedbackOfficer.id !== null) {
                FeedbackOfficer.update(vm.feedbackOfficer, onSaveSuccess, onSaveError);
            } else {
                FeedbackOfficer.save(vm.feedbackOfficer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:feedbackOfficerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.districts = [];
        loadAllDistricts();
        function loadAllDistricts() {
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
                var obj={districtname:'Haryana',
                        powercompany:null,
                        stateID:null,
                        id:null,
                        statename:'Haryana'};
                vm.districts.push(obj);
            });
        }

        vm.departments = [];
        loadAllDepartments();
        function loadAllDepartments() {
            Department.query(function(result) {
                vm.departmentstemp = result;
                angular.forEach(vm.departmentstemp, function(value, key){
                    if(value.departmentname!=='Development and Panchayats'){
                        vm.departments.push(value);
                    }
                });
                vm.searchQuery = null;
            });
        }

    }
})();
