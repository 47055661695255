(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tower-log', {
            parent: 'entity',
            url: '/tower-log',
            data: {
                authorities: ['TOWER_USER','TOWER_ADMIN'],
                pageTitle: 'investhryApp.tower_log.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tower-log/tower-logs.html',
                    controller: 'Tower_logController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tower_log');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('tower-log-detail', {
            parent: 'tower-log',
            url: '/tower-log/{id}',
            data: {
                authorities: ['TOWER_USER','TOWER_ADMIN'],
                pageTitle: 'investhryApp.tower_log.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tower-log/tower-log-detail.html',
                    controller: 'Tower_logDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tower_log');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tower_log', function($stateParams, Tower_log) {
                    return Tower_log.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tower-log',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('tower-log-detail.edit', {
            parent: 'tower-log-detail',
            url: '/detail/edit',
            data: {
                authorities: ['TOWER_USER','TOWER_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tower-log/tower-log-dialog.html',
                    controller: 'Tower_logDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Tower_log', function(Tower_log) {
                            return Tower_log.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tower-log.new', {
            parent: 'tower-log',
            url: '/new',
            data: {
                authorities: ['TOWER_USER','TOWER_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tower-log/tower-log-dialog.html',
                    controller: 'Tower_logDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                action: null,
                                action_by: null,
                                comment: null,
                                tower_id: null,
                                action_date: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('tower-log', null, { reload: 'tower-log' });
                }, function() {
                    $state.go('tower-log');
                });
            }]
        })
        .state('tower-log.edit', {
            parent: 'tower-log',
            url: '/{id}/edit',
            data: {
                authorities: ['TOWER_USER','TOWER_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tower-log/tower-log-dialog.html',
                    controller: 'Tower_logDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Tower_log', function(Tower_log) {
                            return Tower_log.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tower-log', null, { reload: 'tower-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tower-log.delete', {
            parent: 'tower-log',
            url: '/{id}/delete',
            data: {
                authorities: ['TOWER_USER','TOWER_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tower-log/tower-log-delete-dialog.html',
                    controller: 'Tower_logDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Tower_log', function(Tower_log) {
                            return Tower_log.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tower-log', null, { reload: 'tower-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
