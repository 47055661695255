(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerserviceNOController', TowerserviceNOController);

    TowerserviceNOController.$inject = ['TowerserviceNO'];

    function TowerserviceNOController(TowerserviceNO) {

        var vm = this;

        vm.towerserviceNOS = [];

        vm.getProject = function () {
            TowerserviceNO.query({id: '1', projectdetailid: '1', projectdservicedetailid: vm.projectdetailid},function(result) {
                vm.towerserviceNOS = result;
                vm.searchQuery = null;
                vm.show = true;
            });
        }
    }
})();
