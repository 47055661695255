(function() {
    'use strict';

    angular
    .module('investhryApp')
    .factory('downloadticketattachment', ['$q', '$timeout', '$window',
        function($q, $timeout, $window, $http) {
            return {
                download: function(name) {
                    var defer = $q.defer();
                    $timeout(function() {
                        $window.location = 'api/downloadticketattachment?name=' + name;
                        }, 1000)
                        .then(function() {
                            defer.resolve('success');
                        }, function() {
                            defer.reject('error');
                        });
                    return defer.promise;
                }
            };
        }
    ]);
})();
