(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('assignRMController', assignRMController);

    assignRMController.$inject = ['$timeout', '$scope','entity', 'project', '$stateParams','assignedProjectDetail', 'RMDetails', 'Principal', 'AssignProjectToRM','Projectdetail','$state', 'Project', 'projectLog', 'projectLogNewCaf', 'ProjectLogNewCaf', 'Projectlogupdated'];

    function assignRMController ($timeout, $scope, entity, project, $stateParams, assignedProjectDetail, RMDetails, Principal, AssignProjectToRM, Projectdetail, $state, Project, projectLog, projectLogNewCaf, ProjectLogNewCaf, Projectlogupdated) {
        var vm = this;
        vm.save =save;
        vm.account = null;
        vm.investorsummarydetails = entity;
        vm.project = project;
        vm.isAuthenticated = null;
        vm.projectLog = projectLog;
        vm.projectLogNewCaf = projectLogNewCaf;
//        vm.assignProjectToRM = entity;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        loadAllRM();
        function loadAllRM() {
            vm.RMDetails= RMDetails.query()
        }
        function save (projectid, RMlogin) {
            vm.project.assignedRM = RMlogin;
            vm.project.isdeactivated = false;
            vm.project.deactivateddate = new Date();
            AssignProjectToRM.update(vm.project,onSaveSuccessRM);
        }

        function onSaveSuccessRM (result) {
            $scope.$emit('investhryApp:projectUpdate', result);
            if (vm.project.id !== null) {
                Project.update(vm.project, onSaveSuccess, onSaveError);
            }
            vm.isSaving = false;

        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:projectUpdate', result);
            vm.isSaving = false;
            if(result.caftype!=='New'){
                $scope.$emit('investhryApp:projectUpdate', result);
                vm.projectlog.projectid = $stateParams.projectid;
                vm.projectlog.actiontaken = "Changed RM.";
                vm.projectlog.actiontype = "RM Changed";
                Projectlogupdated.save(vm.projectlog, onProjectLogSaveSuccess, onProjectLogSaveError);
            }
            if(result.caftype==='New'){
                vm.projectLogNewCaf.projectId = $stateParams.projectid;
                vm.projectLogNewCaf.actionTaken = "Changed RM.";
                vm.projectLogNewCaf.actionType = "RM Changed";
                vm.projectLogNewCaf.oldValue = vm.investorsummarydetails.rmInfo;
                vm.projectLogNewCaf.newValue = result.assignedRM;
                ProjectLogNewCaf.save(vm.projectLogNewCaf, onProjectLogSaveSuccess, onProjectLogSaveError);
            }
            $state.go('assignProjectToRM');
        }

        function onProjectLogSaveSuccess(result)
        {

        }
        function onProjectLogSaveError()
        {

        }


        function onSaveError () {
            vm.isSaving = false;
             $state.go('assignProjectToRM');
        }

    }
})();
