(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('ViewCafController', ViewCafController);

    ViewCafController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'ViewCaf'];
    function ViewCafController ($timeout, $scope, $stateParams, $uibModalInstance, ViewCaf) {
        var vm = this;
        vm.clear = clear;
        vm.department=$stateParams.department;
        vm.status=$stateParams.status;
        $scope.file = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        loadAllCafs();

        function loadAllCafs() {
            vm.dashboardcafs=ViewCaf.query({department:$stateParams.department,status:$stateParams.status},function (result) {
                vm.show= !vm.show;
            });


        }


    }
})();
