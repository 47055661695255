(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('Questionnaire', {
            parent: 'app',
            url: '/questionnaire',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/questionnaire.html',
                    controller: 'QuestionnaireController',
                    controllerAs: 'vm'
                }
            },
              resolve: {
                  translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                      $translatePartialLoader.addPart('home');
                      return $translate.refresh();
                  }],
                  questionnaire: function () {
                      return {
                          typeofoperation: null,
                          investment: null,
                          typeofownership: null,
                          sector: null,
                          product: null,
                          category: null,
                          natureofland: null,
                          sourceofwater: null,
                          loadrequired: null,
                          contractedload: null,
                          totalworker: null,
                          migrantworker: null,
                          contractworker: null,
                          operatingboilers: false,
                          petrolium: false,
                          hazardouswaste: false,
                          biomedicalwaste: false,
                          ewaste: false,
                          solidwaste: false,
                          rectifiedspirit: false,
                          motortransport: false,
                          involveallopathic: false,
                          involveayurvedic: false,
                          lubricatinglicense: false,
                          commercial: false,
                          involvepesticides: false,
                          underbocwact: false,
                          requireexcavation: false,
                          neednoc: false,
                          involveenergy: false,
                          underaravali: false,
                          undereia: false,
                          pollutioncontrolboard: false,
                          taxation: false
                      };
                  }
              }
        })
        .state('clearances', {
            parent: 'Questionnaire',
            url: '/clearances/{questionnaire:json}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/clearances.html',
                    controller: 'clearancesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                  $translatePartialLoader.addPart('home');
                  return $translate.refresh();
            }]
           }
       })
        .state('page1', {
            parent: 'clearances',
            url: '/page1',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page1/page1.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page2', {
            parent: 'clearances',
            url: '/page2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page2/page2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page3', {
            parent: 'clearances',
            url: '/page3',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page3/page3.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page4', {
            parent: 'clearances',
            url: '/page4',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page4/page4.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page5', {
            parent: 'clearances',
            url: '/page5',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page5/page5.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page6', {
            parent: 'clearances',
            url: '/page6',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page6/page6.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page7', {
            parent: 'clearances',
            url: '/page7',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page7/page7.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page14', {
            parent: 'clearances',
            url: '/page14',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page14/page14.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page16', {
            parent: 'clearances',
            url: '/page16',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page16/page16.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page17', {
            parent: 'clearances',
            url: '/page17',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page17/page17.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page21', {
            parent: 'clearances',
            url: '/page21',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page21/page21.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page22', {
            parent: 'clearances',
            url: '/page22',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page22/page22.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page23', {
            parent: 'clearances',
            url: '/page23',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page23/page23.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page24', {
            parent: 'clearances',
            url: '/page24',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page24/page24.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page25', {
            parent: 'clearances',
            url: '/page25',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page25/page25.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page26', {
            parent: 'clearances',
            url: '/page26',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page26/page26.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page56', {
            parent: 'clearances',
            url: '/page56',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page56/page56.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page57', {
            parent: 'clearances',
            url: '/page57',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page57/page57.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page58', {
            parent: 'clearances',
            url: '/page58',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page58/page58.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('page60', {
            parent: 'clearances',
            url: '/page60',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page60/page60.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table31', {
            parent: 'clearances',
            url: '/table31',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table31/table31.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table32', {
            parent: 'clearances',
            url: '/table32',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table32/table32.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table33', {
            parent: 'clearances',
            url: '/table33',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table33/table33.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table34', {
            parent: 'clearances',
            url: '/table34',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table34/table34.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table36', {
            parent: 'clearances',
            url: '/table36',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table36/table36.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table37', {
            parent: 'clearances',
            url: '/table37',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table37/table37.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table41', {
            parent: 'clearances',
            url: '/table41',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table41/table41.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table42', {
            parent: 'clearances',
            url: '/table42',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table42/table42.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table43', {
            parent: 'clearances',
            url: '/table43',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table43/table43.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table44', {
            parent: 'clearances',
            url: '/table44',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table44/table44.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table45', {
            parent: 'clearances',
            url: '/table45',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table45/table45.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table46', {
            parent: 'clearances',
            url: '/table46',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table46/table46.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table47', {
            parent: 'clearances',
            url: '/table47',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table47/table47.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table48', {
            parent: 'clearances',
            url: '/table48',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table48/table48.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table49', {
            parent: 'clearances',
            url: '/table49',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table49/table49.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table52', {
            parent: 'clearances',
            url: '/table52',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table52/table52.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table53', {
            parent: 'clearances',
            url: '/table53',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table53/table53.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('table54', {
            parent: 'clearances',
            url: '/table54',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table54/table54.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('uhbvn2', {
            parent: 'clearances',
            url: '/uhbvn2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/uhbvn2/uhbvn2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('hsiidc1', {
            parent: 'clearances',
            url: '/hsiidc1',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc1.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('hsiidc2', {
            parent: 'clearances',
            url: '/hsiidc2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('hsiidc3', {
            parent: 'clearances',
            url: '/hsiidc3',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc3.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('hsiidc4', {
            parent: 'clearances',
            url: '/hsiidc4',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc4.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('hsiidc5', {
            parent: 'clearances',
            url: '/hsiidc5',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc5.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })

        .state('dradm', {
            parent: 'clearances',
            url: '/dradm',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/dradm.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })

        .state('inds1', {
            parent: 'clearances',
            url: '/inds1',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds1.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('inds2', {
            parent: 'clearances',
            url: '/inds2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('inds3', {
            parent: 'clearances',
            url: '/inds3',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds3.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('inds4', {
            parent: 'clearances',
            url: '/inds4',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds4.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('inds5', {
            parent: 'clearances',
            url: '/inds5',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds5.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('forest2', {
            parent: 'clearances',
            url: '/forest2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/forest2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('factlicense', {
            parent: 'clearances',
            url: '/factlicense',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/factlicense.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })

        .state('inds6', {
            parent: 'clearances',
            url: '/inds6',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds6.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('inds7', {
            parent: 'clearances',
            url: '/inds7',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds7.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allclearances', {
            parent: 'Questionnaire',
            url: '/allclearances',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/allclearances.html',
                    controller: 'allclearancesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                  $translatePartialLoader.addPart('home');
                  return $translate.refresh();
            }]
           }
       })
        ;
    }
})();
