(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceformdocsDialogController', ServiceformdocsDialogController);

    ServiceformdocsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Serviceformdocs','DepartmentService','FileManagement'];

    function ServiceformdocsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Serviceformdocs,DepartmentService, FileManagement) {
        var vm = this;

        vm.serviceformdocs = entity;
        vm.clear = clear;
        vm.save = save;
        vm.updateDepartmentName=updateDepartmentName;
        vm.selectedservicedepartment="";
        $scope.file = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.serviceformdocs.id !== null) {
                Serviceformdocs.update(vm.serviceformdocs, onSaveSuccess, onSaveError);
            } else {
                Serviceformdocs.save(vm.serviceformdocs, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:serviceformdocsUpdate', result);
            var filename = result.id;
            var file =vm.servicedoc.file;
            FileManagement.saveFile(file,filename);

            var fileNameArray = vm.servicedoc.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            result.downloadfilename=inputFileName;
            Serviceformdocs.update(result,onUpdateSuccess,onUpdateError);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function onUpdateSuccess(result)
        {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        function onUpdateError () {
            vm.isSaving = false;
        }

        loadAllService();
        function loadAllService() {
            DepartmentService.query(function(result) {
                vm.departmentServices = result;
                vm.searchQuery = null;
            });
        }
        function updateDepartmentName()
        {
            vm.selectedservicedepartment=$scope.selectedservice.departmentname;
            vm.serviceformdocs.serviceid=$scope.selectedservice.id;
        }
    }
})();
