(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievanceLogDeleteController',GrievanceLogDeleteController);

    GrievanceLogDeleteController.$inject = ['$uibModalInstance', 'entity', 'GrievanceLog'];

    function GrievanceLogDeleteController($uibModalInstance, entity, GrievanceLog) {
        var vm = this;

        vm.grievanceLog = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            GrievanceLog.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
