(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('Aai', Aai);

    Aai.$inject = ['$http'];

    function Aai ($http) {
        var AAIObject={};
        return {
            createAAIObject:function (formfielddata,uniqueId) {
                AAIObject.uniqueId = uniqueId;
                for (var i = 0; i < formfielddata.length; i++){
                    switch (formfielddata[i].formfieldName){
                        case'Height Clearance For':
                            AAIObject.heightClearanceFor=formfielddata[i].formfieldvalue;
                            break;
                        case'Name of Applicant*':
                            AAIObject.nameOfApplicant=formfielddata[i].formfieldvalue;
                            break;
                        case'Address of Applicant*':
                            AAIObject.addressOfApplicant=formfielddata[i].formfieldvalue;
                            break;
                        case'Mobile No*':
                            AAIObject.mobileNumber=formfielddata[i].formfieldvalue;
                            break;
                        case'Email Id*':
                            AAIObject.emailId=formfielddata[i].formfieldvalue;
                            break;
                        case'Name of Owner/Owners*':
                            AAIObject.nameOfOwner=formfielddata[i].formfieldvalue;
                            break;
                        case'Address of Communication*':
                            AAIObject.addressOfCommunication=formfielddata[i].formfieldvalue;
                            break;
                        case'Type of Structure *':
                            AAIObject.typeOfStructure=formfielddata[i].formfieldvalue;
                            break;
                        case'Uses/Purpose of Structure*':
                            AAIObject.useOfStructure=formfielddata[i].formfieldvalue;
                            break;
                        case'Site Description–Site Name & No, Plot No. (Max 500 Characters)*':
                            AAIObject.siteDescription=formfielddata[i].formfieldvalue;
                            break;
                        case'Size Of Plot(sq.mtrs)*':
                            AAIObject.sizeOfPlot=formfielddata[i].formfieldvalue;
                            break;
                        case'State*':
                            AAIObject.state=formfielddata[i].formfieldvalue;
                            break;
                        case'City*':
                            AAIObject.city=formfielddata[i].formfieldvalue;
                            break;
                        case'Area name – Taluk / Village / Town Name *':
                            AAIObject.areaName=formfielddata[i].formfieldvalue;
                            break;
                        case'Latitude 1*':
                            AAIObject.latitude1 = formfielddata[i].formfieldvalue;
                            break;
                        case'Latitude 2':
                            AAIObject.latitude2 = formfielddata[i].formfieldvalue;
                            break;
                        case'Latitude 3':
                            AAIObject.latitude3 = formfielddata[i].formfieldvalue;
                            break;
                        case'Latitude 4':
                            AAIObject.latitude4 = formfielddata[i].formfieldvalue;
                            break;
                        case'Longitude 1*':
                            AAIObject.longitude1 = formfielddata[i].formfieldvalue;
                            break;
                        case'Longitude 2':
                            AAIObject.longitude2 = formfielddata[i].formfieldvalue;
                            break;
                        case'Longitude 3':
                            AAIObject.longitude3 = formfielddata[i].formfieldvalue;
                            break;
                        case'Longitude 4':
                            AAIObject.longitude4 = formfielddata[i].formfieldvalue;
                            break;
                        case'SiteElevation (AMSL)(mtr)':
                            AAIObject.siteElevation=formfielddata[i].formfieldvalue;
                            break;
                        case'Building Height (AGL)(mtr)':
                            AAIObject.buildingHeight=formfielddata[i].formfieldvalue;
                            break;
                        case'Max Site Elevation (AMSL)(mtr)':
                            AAIObject.maxSiteElevation=formfielddata[i].formfieldvalue;
                            break;
                        case'Do the above Coordinates plotted on the map correlate to your site/plot ?':
                            AAIObject.isCorrelated = formfielddata[i].formfieldvalue;
                            break;
                        case'Does the site lies within the airport premises/Within the land belonging to Airport Operator':
                            AAIObject.isWithinPremesis=formfielddata[i].formfieldvalue;
                            break;
                        case'Have you obtained permission from Airport Authority':
                            AAIObject.hasPermission=formfielddata[i].formfieldvalue;
                            break;
                        }
                    };
                console.log(AAIObject);
                $http.post('/api/aai',AAIObject).then(function () {
                    console.log('success');
                    return AAIObject;
                });
            }
        }
    }
})();
