(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.
        state('aai-fillform', {
                              parent: 'serviceclearanceaction',
                              url: '/aai-fillform/{projectserviceid}',
                              data: {
                                  authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_RM']
                              },
                              onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                                  $uibModal.open({
                                      templateUrl: 'app/aai/aai-fillform.html',
                                      controller: 'AAIFillFormController',
                                      controllerAs: 'vm',
                                      backdrop: 'static',
                                      size: 'lg',
                                      resolve: {
                                          projectServiceFormFieldData: function () {
                                              return {
                                                  serviceid: null,
                                                  formfieldvalue: null,
                                                  projectid: null,
                                                  formfieldName: null,
                                                  id: null
                                              };
                                          },
                                          projectAttachemnt: function () {
                                                return {
                                                    fileName: null,
                                                    description: null,
                                                    fileExtension: null,
                                                    serverFileName: null,
                                                    id: null
                                                };
                                            },
                                            projectServiceLog: function () {
                                                return {
                                                    projectid: null,
                                                    serviceid: null,
                                                    comments: null,
                                                    commentDate: null,
                                                    commentByUserLogin: null,
                                                    actionTaken: null,
                                                    id: null
                                                };
                                            },
                                            projectService: ['Projectservicedetail', function(Projectservicedetail) {
                                              return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                                            }],
                                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                              $translatePartialLoader.addPart('home');
                                              return $translate.refresh();
                                            }]
                                      }
                                  }).result.then(function() {
                                      $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                                  }, function() {
                                      $state.go('serviceclearanceaction');
                                  });
                              }]
                          })
    }
})();
