(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ActionTakenController', ActionTakenController);

    ActionTakenController.$inject = ['$scope', '$uibModalInstance', '$stateParams', '$filter', 'rating', 'Rating'];

    function ActionTakenController($scope, $uibModalInstance, $stateParams, $filter, rating, Rating) {
        var vm = this;
        vm.rating = rating;
        vm.actionTaken = "";
        vm.clear = clear;
        vm.saveActionTaken = function(){
            rating.isResolved = true;
            rating.actionTaken = vm.actionTaken;
            Rating.update(vm.rating, function(result){
                $uibModalInstance.close(result);
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
