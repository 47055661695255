(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('otherServicePaymentController', otherServicePaymentController);

    otherServicePaymentController.$inject = ['$window', '$timeout','$scope','$sce','$stateParams', '$filter','$uibModalInstance','AssignServiceFee', 'projectService', 'projectServiceLog','ProjectServiceLogUpdated','FeePayment','projectServicePayment','Projectservicedetail','ProjectServicePaymentDetails','Payment','TowerserviceNO','MunicipalPaymentParams', 'Project'];
    function otherServicePaymentController ($window, $timeout, $scope, $sce, $stateParams, $filter, $uibModalInstance, AssignServiceFee, projectService, projectServiceLog, ProjectServiceLogUpdated, FeePayment,projectServicePayment,Projectservicedetail,ProjectServicePaymentDetails,Payment,TowerserviceNO, MunicipalPaymentParams, Project) {
        var vm = this;
        vm.projectServiceLog = projectServiceLog;
        vm.projectService=projectService;
        vm.projectServicePayment=projectServicePayment;
        vm.servicePaymentList=null;
        vm.municipalPaymentParams = [];
        vm.projectDistrict = {};

        vm.servicePaymentList=AssignServiceFee.query({id:vm.projectService.id, projectid:vm.projectService.projectid, serviceid:vm.projectService.serviceid});
        var projectServiceLogid=null;
        vm.clear = clear;
        vm.enableButton=[];
        vm.enableButton1=[];
        vm.enableButton2=[];
        vm.towerserviceNOS = [];
        vm.individualPay=individualPay;
        vm.get_chart=get_chart;
        vm.loadServiceFee=loadServiceFee;
        loadServiceFee();
        vm.projectDistrict = $stateParams.district;


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function get_chart()
        {

      //  example_chart=vm.paymentparameters;
        //console.log(example_chart);

        }


        function loadServiceFee()
        {
            {
                if (vm.projectService.serviceFee != null) {
                    vm.projectServicePayment.paymentMade = vm.projectService.serviceFee;
                    vm.projectServicePayment.isPaymentMade = vm.projectService.isPaymentMade;
                }
                else
                    vm.projectServicePayment.paymentMade = 0;
            }

            TowerserviceNO.query({id: '1', projectdetailid: '1', projectdservicedetailid: vm.projectService.id},function(result) {
                vm.towerserviceNOS = result;
                vm.searchQuery = null;
                vm.show = true;
                payment();
            });

        }
        function payment(){
            if(vm.towerserviceNOS[0].subname != null){
                MunicipalPaymentParams.query({name:vm.towerserviceNOS[0].subname},function(result) {
                    vm.municipalPaymentParams = result;
                    vm.searchQuery = null;
                });
            }
            if(vm.towerserviceNOS[0].subname == null) {
                if (vm.projectService.departmentName === 'ZILA PARISHAD') {
                    MunicipalPaymentParams.query({
                        name: vm.projectService.departmentName,
                        district: $stateParams.district
                    }, function (result) {
                        vm.municipalPaymentParams = result;
                        vm.searchQuery = null;
                    });
                }
                else {
                    if(!(vm.projectService.departmentName === 'ZILA PARISHAD' || vm.projectService.departmentName === "MUNICIPAL COUNCIL" || vm.projectService.departmentName === 'MUNICIPAL COMMITTEE'
                        || vm.projectService.departmentName === 'MUNICIPAL CORPORATION'))
                    {
                        MunicipalPaymentParams.query({name: vm.projectService.departmentName}, function (result) {
                            vm.municipalPaymentParams = result;
                            vm.searchQuery = null;
                        });
                    }
                }
            }
        }

        function individualPay(id,amount)
        {

            vm.enableButton='value';
                if(vm.municipalPaymentParams.length !==0) {
                    Payment.get({id: id, projectid: vm.projectService.projectid, amount: amount, serviceid: vm.projectService.serviceid}, function (result) {
                        vm.paymentparameters = result;
                        vm.enableButton1 = 'value';
                        var check = vm.paymentparameters.formPostUrl;
                        $scope.actionURL = $sce.trustAsResourceUrl(check);
                    });

                }
                else{
                    allPayment(id, amount);
                }



//            vm.paymentparameters = FeePayment.get({id:id, projectid:vm.projectService.projectid,amount:amount,serviceid:vm.projectService.serviceid});
//            $scope.actionURL = $sce.trustAsResourceUrl(vm.paymentparameters.formPostUrl);
//             else{
//                 // FeePayment.get({id:id, projectid:vm.projectService.projectid,amount:amount,serviceid:vm.projectService.serviceid},function(result) {
//                 //     vm.paymentparameters = result;
//                 //     vm.enableButton2='value';
//                 //     console.log(vm.paymentparameters);
//                 //     var check = vm.paymentparameters.formPostUrl;
//                 //     console.log(check);
//                 //     $scope.actionURL = $sce.trustAsResourceUrl(check);
//                 // });
//                 allPayment(id,amount);
//         }
    }

        function allPayment(id,amount){
            FeePayment.get({id:id, projectid:vm.projectService.projectid,amount:amount,serviceid:vm.projectService.serviceid},function(result) {
                vm.paymentparameters = result;
                vm.enableButton2='value';
                var check = vm.paymentparameters.formPostUrl;
                $scope.actionURL = $sce.trustAsResourceUrl(check);
            });
        }


    }
})();

