(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewsandgalleryDetailController', NewsandgalleryDetailController);

    NewsandgalleryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Newsandgallery'];

    function NewsandgalleryDetailController($scope, $rootScope, $stateParams, previousState, entity, Newsandgallery) {
        var vm = this;

        vm.newsandgallery = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:newsandgalleryUpdate', function(event, result) {
            vm.newsandgallery = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
