(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('hepcticket', {
            parent: 'app',
            url: '/hepcticket',
            data: {
                authorities: ["ROLE_L1_SUPPORT","ROLE_L2_IT_MANAGER","ROLE_L2_DEPARTMENT","ROLE_L2_DEPARTMENT_HEAD","ROLE_L2_INCENTIVES","ROLE_TICKET_NODAL_OFFICER","ROLE_TICKET_OPERATION_MANAGER","ROLE_NODAL_OFFICER","ROLE_ADMIN","ROLE_SUB_ADMIN","ROLE_TICKET_ADMIN","ROLE_TICKET_HOBPAS"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/hepcTickets/hepcTickets.html',
                    controller: 'HepcTicketsController',
                    controllerAs: 'vm'
                },
                'content_section@hepcticket': {
                    templateUrl: 'app/hepcTickets/viewTicket/viewMyOpenTicket.html',
                    controller: 'TicketViewController',
                    controllerAs: 'vm'
                },
                'tktSidebar@hepcticket': {
                    templateUrl: 'app/hepcTickets/hepcTktSidebar/hepcTktSidebar.html',
                    controller: 'HepcTicketSidebarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
