(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$scope', 'Principal', 'LoginService', '$state','DepartmentStats', '$location'];

    function DashboardController ($scope, Principal, LoginService, $state,DepartmentStats, $location) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
            ga('set', 'page', $location.path());
            ga('send','pageview');
        });

        getAccount();

        loadTotalstatcollection();
        function loadTotalstatcollection() {
                    DepartmentStats.get({deptname:"A",secparam:"B"},function(result) {
                        vm.totalstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadTcpstatcollection();
        function loadTcpstatcollection() {
                    DepartmentStats.get({deptname:"TOWN & COUNTRY PLANNING"},function(result) {
                        vm.tcpstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadHsiidcstatcollection();
        function loadHsiidcstatcollection() {
                    DepartmentStats.get({deptname:"HARYANA STATE INDUSTRIAL & INFRASTRUCTURE DEVELOPMENT CORPORATION LTD"},function(result) {
                        vm.hsiidcstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadPwdstatcollection();
        function loadPwdstatcollection() {
                    DepartmentStats.get({deptname:"PWD (B & R) DEPARTMENT"},function(result) {
                        vm.pwdstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadLabourstatcollection();
        function loadLabourstatcollection() {
                    DepartmentStats.get({deptname:"LABOUR DEPARTMENT - LABOUR WING"},function(result) {
                        vm.labourstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadHudastatcollection();
        function loadHudastatcollection() {
                    DepartmentStats.get({deptname:"HARYANA URBAN DEVELOPMENT AUTHORITY"},function(result) {
                        vm.hudastatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadDhbvnstatcollection();
        function loadDhbvnstatcollection() {
                    DepartmentStats.get({deptname:"DAKSHIN HARYANA BIJLI VITRAN NIGAM"},function(result) {
                        vm.dhbvnstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadFoodnsupplystatcollection();
        function loadFoodnsupplystatcollection() {
                    DepartmentStats.get({deptname:"FOOD & SUPPLIES DEPARTMENT"},function(result) {
                        vm.foodnsupplystatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadHspcbstatcollection();
        function loadHspcbstatcollection() {
                    DepartmentStats.get({deptname:"HARYANA STATE POLLUTION CONTROL BOARD"},function(result) {
                        vm.hspcbstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadUhbvnstatcollection();
        function loadUhbvnstatcollection() {
                    DepartmentStats.get({deptname:"UTTAR HARYANA BIJLI VITRAN NIGAM"},function(result) {
                        vm.uhbvnstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadUlbstatcollection();
        function loadUlbstatcollection() {
                    DepartmentStats.get({deptname:"URBAN LOCAL BODIES"},function(result) {
                        vm.ulbstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadAyushstatcollection();
        function loadAyushstatcollection() {
                    DepartmentStats.get({deptname:"DEPARTMENT OF AYUSH"},function(result) {
                        vm.ayushstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadEnvironmentstatcollection();
        function loadEnvironmentstatcollection() {
                    DepartmentStats.get({deptname:"ENVIRONMENT"},function(result) {
                        vm.environmentstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadCeistatcollection();
        function loadCeistatcollection() {
                    DepartmentStats.get({deptname:"CHIEF ELECTRICAL INSPECTOR"},function(result) {
                        vm.ceistatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadDicstatcollection();
        function loadDicstatcollection() {
                    DepartmentStats.get({deptname:"DEPARTMENT OF INDUSTRIES & COMMERCE"},function(result) {
                        vm.dicstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadPhedstatcollection();
        function loadPhedstatcollection() {
                    DepartmentStats.get({deptname:"PUBLIC HEALTH ENGINEERING DEPARTMENT"},function(result) {
                        vm.phedstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadExcisestatcollection();
        function loadExcisestatcollection() {
                    DepartmentStats.get({deptname:"EXCISE & TAXATION - EXCISE"},function(result) {
                        vm.excisestatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadMinesstatcollection();
        function loadMinesstatcollection() {
                    DepartmentStats.get({deptname:"MINES & GEOLOGY DEPARTMENT"},function(result) {
                        vm.minesstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadFnWstatcollection();
        function loadFnWstatcollection() {
                    DepartmentStats.get({deptname:"DEPARTMENT OF FORESTS & WILDLIFE"},function(result) {
                        vm.fnwstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadFoodndrugstatcollection();
        function loadFoodndrugstatcollection() {
                    DepartmentStats.get({deptname:"Food and Drug Administration"},function(result) {
                        vm.foodndrugstatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadRenewablestatcollection();
        function loadRenewablestatcollection() {
                    DepartmentStats.get({deptname:"DEPARTMENT OF RENEWABLE ENERGY"},function(result) {
                        vm.renewablestatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadAgriculturestatcollection();
        function loadAgriculturestatcollection() {
                    DepartmentStats.get({deptname:"DEPARTMENT OF AGRICULTURE"},function(result) {
                        vm.agriculturestatcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadLabourfactorywingstatcollection();
        function loadLabourfactorywingstatcollection() {
                    DepartmentStats.get({deptname:"LABOUR DEPARTMENT - FACTORY WING"},function(result) {
                        vm.labourfactorywingstatcollection = result;
                        vm.searchQuery = null;
                    });
                }


        loadNationalMonumentAuthoruty();
        function loadNationalMonumentAuthoruty() {
                    DepartmentStats.get({deptname:"National Monument Authority"},function(result) {
                        vm.loadnationalmonumentcollection = result;
                        vm.searchQuery = null;
                    });
                }

        loadAirportAuthority();
        function loadAirportAuthority() {
                    DepartmentStats.get({deptname:"Airport Authority of India"},function(result) {
                        vm.loadairportauthoritycollection = result;
                        vm.searchQuery = null;
                    });
                }




        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

    }
})();
