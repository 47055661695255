(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Service_dashboard_outputDeleteController',Service_dashboard_outputDeleteController);

    Service_dashboard_outputDeleteController.$inject = ['$uibModalInstance', 'entity', 'Service_dashboard_output'];

    function Service_dashboard_outputDeleteController($uibModalInstance, entity, Service_dashboard_output) {
        var vm = this;

        vm.service_dashboard_output = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Service_dashboard_output.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
