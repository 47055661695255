(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('OldIncentiveWizardController', OldIncentiveWizardController);

    OldIncentiveWizardController.$inject = ['$scope', '$state','$filter','districts','sectors','Block','Incentives','$location'];

    function OldIncentiveWizardController($scope,$state,$filter,districts,sectors,Block,Incentives,$location) {
        var vm = this;
        vm.calculateIncentives=calculateIncentives;
        vm.districts = [];
        vm.projecttype = null;
        vm.showServiceTypes = true;
        vm.showIncentives = false;
        vm.selectedSector = null;
        $scope.projecttype = null;
        vm.calculatedProjectType = null;
        vm.selectedProjectType = null;
        vm.projecttype = 'services';
        vm.isConfirmed=false;
        loadAll();
        var manufacturingProjectTypes = ['Ultra Mega Project','Mega Project','Large Project','Medium Enterprise','Small Enterprise','Micro Enterprise'];
        var servicesProjectTypes = ['Ultra Mega Project','Mega Project','Large Service Enterprise','Medium Service Enterprise','Small Service Enterprise','Micro Service Enterprise'];
        vm.isEPPOnly = true;
        angular.forEach(districts, function(value, key){
            if((value.districtname!== 'Chandigarh HQ')){
                vm.districts.push(value);
            }
        });
        function loadAll(){
            ga('set', 'page', $location.path());ga('send','pageview');
        }

        vm.sectors=sectors;
        vm.incentives={};
        vm.incentives.projectsector='All';
        vm.result={};
        vm.result.incentives=[];
        vm.result.sectorincentives=[];
        vm.loadBlocksByDistrict=loadBlocksByDistrict;
        vm.sectorSpecificeResult = {};
        vm.eppWithSector = {};

        function loadBlocksByDistrict() {
            Block.query({id:1, districtname:vm.incentives.projectdistrict},function (result) {
                vm.blocks=result;
            });
        }

        vm.getConfirmation = function(){
            vm.isConfirmed = true;
            if(vm.isConfirmed){
                vm.calculateIncentives();
            }
            vm.showIncentives = true;
        }

        vm.dismiss=function(){
            vm.showIncentives = false;
            $("#incentivesModal").modal('hide');
        }

        function calculateIncentives() {
            ga('set', 'page', "/calculated-incentives");ga('send','pageview');

            vm.calculateProjectType(vm.getProjectType);
            if(vm.selectedTextile != null){
                vm.incentives.projectsector = vm.selectedTextile;
            }else if(vm.selectedAgro != null){
                vm.incentives.projectsector = vm.selectedAgro;
            }
        }

        vm.getProjectType = function() {
            var result=Incentives.getIncentives(vm.result.projectsize,vm.block.blocktype,vm.incentives.projectsector,vm.projecttype);
//            vm.result.incentives=result[0]===undefined?[]:result[0].incentives;
            vm.result.incentives=result[0].incentives;
            var sectorSpecificeResult = Incentives.getSectorSpecificIncentives(vm.incentives.projectsector,vm.block.blocktype);
            vm.sectorSpecificeResult.incentives=sectorSpecificeResult[0]===undefined?[]:sectorSpecificeResult[0].incentives;
            vm.eppWithSector.incentives = clubResults(vm.result.incentives, vm.sectorSpecificeResult.incentives);
        }


        vm.getSelectedSector = function(selectedSector){
            if(selectedSector == 'Agro' || selectedSector == 'Footwear' || selectedSector == 'Textiles'){
                vm.isEPPOnly = false;
            }else{
                vm.isEPPOnly = true;
            }
        }

        vm.loadProjectType = function(selectedProjectType){
            $scope.projecttype = selectedProjectType;
            if(selectedProjectType == 'services'){
                vm.showServiceTypes = true;
                vm.showManufacturingTypes = false;
            }else if(selectedProjectType == 'manufacturing'){
                vm.showServiceTypes = false;
                vm.showManufacturingTypes = true;
            }
        }

        vm.loadBlockType = function(){
            vm.block=$filter('filter')(vm.blocks,{districtname:vm.incentives.projectdistrict,blockname:vm.incentives.projectblock})[0];
        }
        function clubResults(eppincentives, sectorIncentives){
            var newItems = [];
            var itemsToBeChanged = [];
            var indexForItemsToBeChanged = [];
            var incentiveIndexesForNewItems = [];
            eppincentives.forEach(function(e1,index){
                for(var i = 0; i < sectorIncentives.length; i++){
                    if(e1.name === sectorIncentives[i].name){
                        indexForItemsToBeChanged.push(index);
                        if(sectorIncentives[i].value1 !== null){
                            e1.value3 = sectorIncentives[i].value1;
                            if(sectorIncentives[i].constraints1 !== null){
                                e1.constraints3 = sectorIncentives[i].constraints1;
                            }
                            if(sectorIncentives[i].value2 !== null){
                                e1.value4 = sectorIncentives[i].value2;
                                if(sectorIncentives[i].constraints2 !== null){
                                    e1.constraints4 = sectorIncentives[i].constraints2;
                                }
                            }
                        }
                        e1.remarks1 = e1.remarks;
                        e1.remarks2 = sectorIncentives[i].remarks;
                        itemsToBeChanged.push(e1);
                    }else if(sectorIncentives[i].name.includes(e1.name)){
                        incentiveIndexesForNewItems.push(index);
                        newItems.push(sectorIncentives[i]);
                    }
                }
            });
            for(var j = 0; j < indexForItemsToBeChanged.length; j++){
                eppincentives[indexForItemsToBeChanged[j]] = itemsToBeChanged[j];
            }
            for(var k = 0; k < incentiveIndexesForNewItems.length; k++){
                eppincentives.splice(incentiveIndexesForNewItems[k],0,newItems.shift());
            }
            return eppincentives;
        }

        vm.calculateProjectType = function(callback){

            if(vm.projecttype === 'services'){
                if(vm.total_project_cost > 6000 && vm.landArea >= 500){
                    vm.calculatedProjectType = 'Ultra Mega Project';
                }else if(vm.total_project_cost >= 100 || (vm.employment  >= 200 && vm.block.blocktype === 'D') ||
                    (vm.employment >= 500 && (vm.block.blocktype === 'B' || vm.block.blocktype === 'C'))){
                    vm.calculatedProjectType = 'Mega Project';
                }else if(vm.total_project_cost > 5){
                    vm.calculatedProjectType = 'Large Service Enterprise';
                }else if(vm.total_project_cost > 2 && vm.total_project_cost <=5){
                    vm.calculatedProjectType = 'Medium Service Enterprise';
                }else if(vm.total_project_cost > 0.1000 && vm.total_project_cost <= 2){
                    vm.calculatedProjectType = 'Small Service Enterprise';
                }else if(vm.total_project_cost < 0.1000){
                    vm.calculatedProjectType = 'Micro Service Enterprise';
                }
            }else if(vm.projecttype === 'manufacturing'){
                if(vm.total_project_cost > 6000 && vm.landArea >= 500){
                    vm.calculatedProjectType = 'Ultra Mega Project';
                }else if(vm.total_project_cost >= 100 || (vm.employment  >= 200 && vm.block.blocktype === 'D') ||
                    (vm.employment >= 500 && (vm.block.blocktype === 'B' || vm.block.blocktype === 'C'))){
                    vm.calculatedProjectType = 'Mega Project';
                }else if(vm.total_project_cost > 10 && vm.total_project_cost <= 100){
                    vm.calculatedProjectType = 'Large Project';
                }else if(vm.total_project_cost > 5 && vm.total_project_cost <= 10){
                    vm.calculatedProjectType = 'Medium Enterprise';
                }else if(vm.total_project_cost > 0.2500 && vm.total_project_cost <= 5){
                    vm.calculatedProjectType = 'Small Enterprise';
                }else if(vm.total_project_cost < 0.2500){
                    vm.calculatedProjectType = 'Micro Enterprise';
                }
            }

            var selectedProjectType = vm.calculatedProjectType;

            if(selectedProjectType == 'Mega Project'){
                vm.result.projectsize = 'MEGA';
            }else if(selectedProjectType == 'Micro Project' || selectedProjectType == 'Medium Enterprise' ||
                selectedProjectType == 'Small Enterprise' || selectedProjectType == 'Micro Enterprise' ||
                selectedProjectType == 'Medium Service Enterprise' || selectedProjectType == 'Small Service Enterprise' ||
                selectedProjectType == 'Micro Service Enterprise'){
                vm.result.projectsize = 'MSME';

            }else if(selectedProjectType == 'Large Project'){
                vm.result.projectsize = 'LARGE';
            }else if(selectedProjectType == 'Large Service Enterprise'){
                vm.result.projectsize = 'Large Service Enterprise';
            }

            callback();
        }

        vm.changeInCost=function(){
            var projectCostInCrore=vm.project_cost_in_crore || '00';
            var projectCostInLacs=vm.project_cost_in_lacs|| '00' ;
            var projectCostInThousands=vm.project_cost_in_thousands||'00';
            if(vm.project_cost_in_lacs<10){
                projectCostInLacs='0'+vm.project_cost_in_lacs;
            }
            if(vm.project_cost_in_thousands<10){
                projectCostInThousands='0'+vm.project_cost_in_thousands;
            }
            vm.total_project_cost = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;
            vm.calculateProjectType(function(){
            });
        };


    }
})();
