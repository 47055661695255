(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DistrictFieldOfficerDeleteController',DistrictFieldOfficerDeleteController);

    DistrictFieldOfficerDeleteController.$inject = ['$uibModalInstance', 'entity', 'DistrictFieldOfficer'];

    function DistrictFieldOfficerDeleteController($uibModalInstance, entity, DistrictFieldOfficer) {
        var vm = this;

        vm.districtFieldOfficer = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DistrictFieldOfficer.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
