(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DashboardbetweendatescafController', DashboardbetweendatescafController);

    DashboardbetweendatescafController.$inject = ['Dashboardbetweendatescaf'];

    function DashboardbetweendatescafController(Dashboardbetweendatescaf) {

        var vm = this;

        vm.dashboardbetweendatescafs = [];

        loadAll();

        function loadAll() {
            Dashboardbetweendatescaf.query(function(result) {
                vm.dashboardbetweendatescafs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
