(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('DownloadCafCsv', ['$q', '$timeout', '$window',
            function($q, $timeout, $window, $http) {
                return {
                    download: function(serverfilename) {
                        var defer = $q.defer();
                        $timeout(function() {
                            $window.location = 'api/downloadcafattachment?serverfilename='+serverfilename;
                        }, 1000)
                            .then(function() {
                                defer.resolve('success');
                            }, function() {
                                defer.reject('error');
                            });
                        return defer.promise;
                    }
                };
            }
        ]);
})();
