(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('ReplaceRm', ReplaceRm);

    ReplaceRm.$inject = ['$resource'];

    function ReplaceRm ($resource) {
        var resourceUrl =  'api/replaceRm/:rmOld/:rmNew';
            return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    var data1 = {"value": data}
                    return data1;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
