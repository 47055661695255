(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('service-status', {
            parent: 'entity',
            url: '/service-status',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.serviceStatus.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service-status/service-statuses.html',
                    controller: 'ServiceStatusController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('serviceStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('service-status-detail', {
            parent: 'service-status',
            url: '/service-status/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.serviceStatus.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service-status/service-status-detail.html',
                    controller: 'ServiceStatusDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('serviceStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ServiceStatus', function($stateParams, ServiceStatus) {
                    return ServiceStatus.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'service-status',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('service-status-detail.edit', {
            parent: 'service-status-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-status/service-status-dialog.html',
                    controller: 'ServiceStatusDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ServiceStatus', function(ServiceStatus) {
                            return ServiceStatus.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service-status.new', {
            parent: 'service-status',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-status/service-status-dialog.html',
                    controller: 'ServiceStatusDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                cafPin: null,
                                projectId: null,
                                serviceId: null,
                                serviceName: null,
                                level1: null,
                                level2: null,
                                level3: null,
                                level4: null,
                                level5: null,
                                level6: null,
                                level7: null,
                                level8: null,
                                level9: null,
                                level10: null,
                                reApplyCounter: null,
                                percentageCompleted: null,
                                departmentName: null,
                                previousLevel: null,
                                projectLevel: null,
                                level0: null,
                                level1Date: null,
                                level2Date: null,
                                level3Date: null,
                                level4Date: null,
                                level5Date: null,
                                level6Date: null,
                                level7Date: null,
                                level8Date: null,
                                level9Date: null,
                                level10Date: null,
                                level0Date: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('service-status', null, { reload: 'service-status' });
                }, function() {
                    $state.go('service-status');
                });
            }]
        })
        .state('service-status.edit', {
            parent: 'service-status',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-status/service-status-dialog.html',
                    controller: 'ServiceStatusDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ServiceStatus', function(ServiceStatus) {
                            return ServiceStatus.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('service-status', null, { reload: 'service-status' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service-status.delete', {
            parent: 'service-status',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-status/service-status-delete-dialog.html',
                    controller: 'ServiceStatusDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ServiceStatus', function(ServiceStatus) {
                            return ServiceStatus.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('service-status', null, { reload: 'service-status' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
