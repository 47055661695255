(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ProjectController', ProjectController);

    ProjectController.$inject = ['Project','SearchProject'];

    function ProjectController(Project,SearchProject) {

        var vm = this;

        vm.projects = [];
        vm.seachedProject= [];

//        loadAll();

        vm.getProject = function(){
            var newString =vm.CAFPIN.split('.').join('#');
            SearchProject.query({CAFPIN:newString},function(result){
                vm.seachedProject = result;
                vm.showtable=true;
            });
        };

//        function loadAll() {
//            Project.query(function(result) {
//                vm.projects = result;
//                vm.searchQuery = null;
//            });
//        }
    }
})();
