(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerdiolistController', TowerdiolistController);

    TowerdiolistController.$inject = ['Towerdiolist'];

    function TowerdiolistController(Towerdiolist) {

        var vm = this;

        vm.towerdiolists = [];

        loadAll();

        function loadAll() {
            Towerdiolist.query(function(result) {
                vm.towerdiolists = result;
                vm.searchQuery = null;
            });
        }
    }
})();
