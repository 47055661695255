(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tower-service-dashboard', {
            parent: 'entity',
            url: '/tower-service-dashboard',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.tower_service_dashboard.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tower-service-dashboard/tower-service-dashboards.html',
                    controller: 'Tower_service_dashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tower_service_dashboard');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('tower-service-dashboard-detail', {
            parent: 'tower-service-dashboard',
            url: '/tower-service-dashboard/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.tower_service_dashboard.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tower-service-dashboard/tower-service-dashboard-detail.html',
                    controller: 'Tower_service_dashboardDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tower_service_dashboard');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tower_service_dashboard', function($stateParams, Tower_service_dashboard) {
                    return Tower_service_dashboard.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tower-service-dashboard',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('tower-service-dashboard-detail.edit', {
            parent: 'tower-service-dashboard-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tower-service-dashboard/tower-service-dashboard-dialog.html',
                    controller: 'Tower_service_dashboardDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Tower_service_dashboard', function(Tower_service_dashboard) {
                            return Tower_service_dashboard.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tower-service-dashboard.new', {
            parent: 'tower-service-dashboard',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tower-service-dashboard/tower-service-dashboard-dialog.html',
                    controller: 'Tower_service_dashboardDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                service_name: null,
                                department_name: null,
                                required: null,
                                applied: null,
                                pending_total: null,
                                pending_30: null,
                                pending_45: null,
                                cleared: null,
                                deactivated: null,
                                deemed: null,
                                rejected: null,
                                departmentOrder: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('tower-service-dashboard', null, { reload: 'tower-service-dashboard' });
                }, function() {
                    $state.go('tower-service-dashboard');
                });
            }]
        })
        .state('tower-service-dashboard.edit', {
            parent: 'tower-service-dashboard',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tower-service-dashboard/tower-service-dashboard-dialog.html',
                    controller: 'Tower_service_dashboardDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Tower_service_dashboard', function(Tower_service_dashboard) {
                            return Tower_service_dashboard.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tower-service-dashboard', null, { reload: 'tower-service-dashboard' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tower-service-dashboard.delete', {
            parent: 'tower-service-dashboard',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tower-service-dashboard/tower-service-dashboard-delete-dialog.html',
                    controller: 'Tower_service_dashboardDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Tower_service_dashboard', function(Tower_service_dashboard) {
                            return Tower_service_dashboard.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tower-service-dashboard', null, { reload: 'tower-service-dashboard' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
