(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewsandgalleryController', NewsandgalleryController);

    NewsandgalleryController.$inject = ['Newsandgallery'];

    function NewsandgalleryController(Newsandgallery) {

        var vm = this;

        vm.newsandgalleries = [];

        loadAll();

        function loadAll() {
            Newsandgallery.query(function(result) {
                vm.newsandgalleries = result;
                vm.searchQuery = null;
            });
        }
    }
})();
