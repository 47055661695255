(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('GrievanceDashboardController', GrievanceDashboardController);

    GrievanceDashboardController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'GrievanceDashboard', 'GrievanceDashboardOutput'];

    function GrievanceDashboardController ($scope, Principal, LoginService, $state, GrievanceDashboard, GrievanceDashboardOutput) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        loadAll();
        function loadAll() {
        GrievanceDashboardOutput.query(function(result) {
              vm.grievancedashboard = result;
                vm.totalunassignedgrievances=0;
                vm.totalassignedgrievances=0;
                vm.totalpendinggrievances=0;
                vm.totalresolvedgrievances=0;
                 for(var i=0;i<vm.grievancedashboard.length;i++)
                 {
                   vm.totalunassignedgrievances=vm.totalunassignedgrievances + parseInt(vm.grievancedashboard[i].unassigned);
                   vm.totalassignedgrievances=vm.totalassignedgrievances+ parseInt(vm.grievancedashboard[i].assigned);
                   vm.totalpendinggrievances=vm.totalpendinggrievances+ parseInt(vm.grievancedashboard[i].pending);
                   vm.totalresolvedgrievances=vm.totalresolvedgrievances+ parseInt(vm.grievancedashboard[i].resolved);
                 }
                 vm.searchQuery = null;
        });
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
