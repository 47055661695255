(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('huda-form', {
            parent: 'entity',
            url: '/huda-form',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.hudaForm.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/huda-form/huda-forms.html',
                    controller: 'HudaFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('hudaForm');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('huda-form-detail', {
            parent: 'huda-form',
            url: '/huda-form/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.hudaForm.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/huda-form/huda-form-detail.html',
                    controller: 'HudaFormDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('hudaForm');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'HudaForm', function($stateParams, HudaForm) {
                    return HudaForm.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'huda-form',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('huda-form-detail.edit', {
            parent: 'huda-form-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/huda-form/huda-form-dialog.html',
                    controller: 'HudaFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['HudaForm', function(HudaForm) {
                            return HudaForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('huda-form.new', {
            parent: 'huda-form',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/huda-form/huda-form-dialog.html',
                    controller: 'HudaFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                caseType: null,
                                estateOffice: null,
                                projectType: null,
                                sector: null,
                                existingFAR: null,
                                basementArea: null,
                                zoningLayout: null,
                                plotNumber: null,
                                applicationType: null,
                                applicantEmail: null,
                                applicantMobile: null,
                                ownerName: null,
                                ownerAddress: null,
                                aadhaarCardNumber: null,
                                fatherName: null,
                                pinCode: null,
                                applicantName: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('huda-form', null, { reload: 'huda-form' });
                }, function() {
                    $state.go('huda-form');
                });
            }]
        })
        .state('huda-form.edit', {
            parent: 'huda-form',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/huda-form/huda-form-dialog.html',
                    controller: 'HudaFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['HudaForm', function(HudaForm) {
                            return HudaForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('huda-form', null, { reload: 'huda-form' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('huda-form.delete', {
            parent: 'huda-form',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/huda-form/huda-form-delete-dialog.html',
                    controller: 'HudaFormDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['HudaForm', function(HudaForm) {
                            return HudaForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('huda-form', null, { reload: 'huda-form' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
