(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('Dashboardbetweendatescaf', Dashboardbetweendatescaf);

    Dashboardbetweendatescaf.$inject = ['$resource', 'DateUtils'];

    function Dashboardbetweendatescaf ($resource, DateUtils) {
        var resourceUrl =  'api/dashboardbetweendatescafs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.statusDate = DateUtils.convertLocalDateFromServer(data.statusDate);
                        data.formFilledOn = DateUtils.convertLocalDateFromServer(data.formFilledOn);
                        data.appliedDate = DateUtils.convertLocalDateFromServer(data.appliedDate);
                        data.clearedDate = DateUtils.convertLocalDateFromServer(data.clearedDate);
                        data.rejectionDate = DateUtils.convertLocalDateFromServer(data.rejectionDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.statusDate = DateUtils.convertLocalDateToServer(copy.statusDate);
                    copy.formFilledOn = DateUtils.convertLocalDateToServer(copy.formFilledOn);
                    copy.appliedDate = DateUtils.convertLocalDateToServer(copy.appliedDate);
                    copy.clearedDate = DateUtils.convertLocalDateToServer(copy.clearedDate);
                    copy.rejectionDate = DateUtils.convertLocalDateToServer(copy.rejectionDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.statusDate = DateUtils.convertLocalDateToServer(copy.statusDate);
                    copy.formFilledOn = DateUtils.convertLocalDateToServer(copy.formFilledOn);
                    copy.appliedDate = DateUtils.convertLocalDateToServer(copy.appliedDate);
                    copy.clearedDate = DateUtils.convertLocalDateToServer(copy.clearedDate);
                    copy.rejectionDate = DateUtils.convertLocalDateToServer(copy.rejectionDate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
