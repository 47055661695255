(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ConstructionPermitLogController', ConstructionPermitLogController);

    ConstructionPermitLogController.$inject = ['ConstructionPermitLog'];

    function ConstructionPermitLogController(ConstructionPermitLog) {

        var vm = this;

        vm.constructionPermitLogs = [];

        loadAll();

        function loadAll() {
            ConstructionPermitLog.query(function(result) {
                vm.constructionPermitLogs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
