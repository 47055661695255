(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketsDeleteController',TicketsDeleteController);

    TicketsDeleteController.$inject = ['$uibModalInstance', 'entity', 'Tickets'];

    function TicketsDeleteController($uibModalInstance, entity, Tickets) {
        var vm = this;

        vm.tickets = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Tickets.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
