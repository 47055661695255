(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DisputeFormController', DisputeFormController);

    DisputeFormController.$inject = ['$timeout', '$scope', '$stateParams','$state', '$uibModalInstance', 'Dispute','Department','DisputeAttachment','FileManagement','District','Disputelog','CheckContent'];

    function DisputeFormController ($timeout, $scope, $stateParams, $state , $uibModalInstance, Dispute, Department, DisputeAttachment, FileManagement,District, Disputelog,CheckContent) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.departments = [];
        vm.datePickerOpenStatus = {};

        vm.openCalendar = openCalendar;

        vm.fileattach = [];
        var inputFileName = null;

        var fileextension="";
        $scope.file = {};
        vm.dispute = {};
        vm.disputelog ={};

        $('input').each(function(){ CheckContent.check($(this))});
        $('textarea').each(function() { CheckContent.check($(this))});


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        loadAll();

        function loadAll() {
            Department.query(function (result) {
                vm.departments = result;
                vm.searchQuery = null;
            });
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
            });
        }

        vm.districts = [];

        vm.checkPoint=function (value) {
            if(value !== true){
                alert("You are Not Eligible For this");
                $uibModalInstance.dismiss('cancel');
                $state.go('myDisputes');
            }
        };


        function save() {
            vm.isSaving = true;
            if(vm.dispute.gotReliefLevel ==='False'){
                vm.dispute.levelOfRelief = null;
                vm.orderOfReliefAttachemnt = null;
            }
            if(vm.dateOfFilingPetition !==null){
                vm.fileattach.push({file: vm.dateOfFilingPetition, name: "dateOfFilingPetition"});
            }
            if(vm.replyOfDepartment !==null){
                vm.fileattach.push({file:vm.replyOfDepartment, name: "replyOfDepartment"});
            }
            if(vm.detailsOfDecision !==null){
                vm.fileattach.push({file:vm.detailsOfDecision, name: "detailsOfDecision"});
            }
            if(vm.appealsDocument !==null){
                vm.fileattach.push({file:vm.appealsDocument,name: "appealsDocument"});
            }
            if(vm.orderOfReliefAttachemnt !==null){
                vm.fileattach.push({file:vm.orderOfReliefAttachemnt,name: "orderOfReliefAttachemnt"});
            }

            Dispute.save(vm.dispute, onSaveSuccess, onSaveError);

        }

        function onSaveSuccess(result) {
            $scope.$emit('investhryApp:disputeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.disputelog.disputeID = result.disputeID;
            vm.disputelog.disputeMainId = result.id;
            vm.disputelog.action = "Dispute Added";

            if (vm.disputelog.id !== null) {
                Disputelog.update(vm.disputelog,onSaveError);
            } else {
                Disputelog.save(vm.disputelog,onSaveError);
            }

            for(var i=0;i<vm.fileattach.length;i++){
                var filename = result.disputeID + "-" + Math.random().toString(36).substring(10);

                FileManagement.saveFile(vm.fileattach[i].file,filename);
                if(vm.fileattach[i].name=== 'dateOfFilingPetition'){
                    checkExtension(vm.dateOfFilingPetition,result);
                    result.dateOfFilingPetition = filename +"."+fileextension;
                }
                if(vm.fileattach[i].name=== 'replyOfDepartment'){
                    checkExtension(vm.replyOfDepartment,result);
                    result.replyOfDepartment = filename +"."+fileextension;

                }
                if(vm.fileattach[i].name=== 'detailsOfDecision'){
                    checkExtension(vm.detailsOfDecision,result);
                    result.detailsOfDecision = filename +"."+fileextension;
                }
                if(vm.fileattach[i].name=== 'appealsDocument'){
                    checkExtension(vm.appealsDocument,result);
                    result.appealsDocument = filename +"."+fileextension;
                }
                if(vm.fileattach[i].name=== 'orderOfReliefAttachemnt'){
                    checkExtension(vm.orderOfReliefAttachemnt,result);
                    result.orderOfReliefAttachemnt = filename +"."+fileextension;
                }
            }

            Dispute.update(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function checkExtension(File,result) {
            var fileNameArray = File.name.split(".");
            if((fileNameArray.length>1)){
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            // var inputFileName=result.disputeID;
            if(fileextension!=""){
                inputFileName=inputFileName+"."+fileextension;
            }
        }

        vm.datePickerOpenStatus.dateOfFiling =false;
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }


    }
})();
