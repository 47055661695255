(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SentTicketsController', SentTicketsController);

    SentTicketsController.$inject = ['Principal','Tickets','$filter', 'TicketsLogs', 'downloadticketattachment'];

    function SentTicketsController(Principal,Tickets,$filter ,TicketsLogs, downloadticketattachment) {
        var vm = this;
        vm.sentTicketsLogs = [];
        vm.getLogs=getLogs;
        vm.downloadAttachment=downloadAttachment;
        loadAll();

        function loadAll() {
            Principal.identity().then(function(account) {
                vm.account = account;
                TicketsLogs.query({assignedLogin: vm.account.login},function(result){
                   vm.sentTicketsLogs = result;
                   vm.searchQuery = null;
                });
            });
        }

        function getLogs(ticketDetails) {
            TicketsLogs.query({ticketNumber: ticketDetails.ticketNumber},function (result) {
                vm.ticketsLogs=result;
            })
        }

        function downloadAttachment(attachment) {
            downloadticketattachment.download(attachment)
                .then(function(success) {

                }, function(error) {
                    alert('error : ' + error);
                });
        }
    }
})();
