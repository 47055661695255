(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
         .state("aai-state",{
                parent: 'constructionpermit',
                url: '/aai/{CPid}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/entities/aai/aai-dialog.html',
                        controller: 'AaiDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            height_clearance: null,
                            nameOfAplicant: null,
                            addressOfApplicant: null,
                            mobile_no: null,
                            email_id: null,
                            nameOfOwner: null,
                            addressOfCommunication: null,
                            typeOfStructure: null,
                            usesOfStructure: null,
                            siteDescription: null,
                            sizeOfPlot: null,
                            state: null,
                            areaName: null,
                            latitude1: null,
                            latitude2: null,
                            latitude3: null,
                            longitude1: null,
                            longitude2: null,
                            longitude3: null,
                            siteElevation: null,
                            buildingHeight: null,
                            maxSiteElevation: null,
                            question1: null,
                            question2: null,
                            question3: null,
                            file1: null,
                            city: null,
                            id: null
                        };
                    }
                }
            })
         .state("aai-detailPage",{
                parent: 'constructionpermit',
                url: '/aaiDetail/{aaiId}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/aai-detailPage.html',
                        controller: 'aaiDetailPageController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("nma-detailPage",{
                parent: 'constructionpermit',
                url: '/nmaDetail/{nmaId}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/nma-detailPage.html',
                        controller: 'nmaDetailPageController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("ffs",{
                parent: 'constructionpermit',
                url: '/ffs',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/ffs.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("twc",{
                parent: 'constructionpermit',
                url: '/twc',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/twc.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state("twc_huda",{
                parent: 'constructionpermit',
                url: '/twc(huda)',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/twc_huda.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state("twc_ulb",{
                parent: 'constructionpermit',
                url: '/twc(ulb)',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/twc_ulb.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })


            .state("tec",{
                parent: 'constructionpermit',
                url: '/tec/{deptID}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/tec.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("huda",{
                parent: 'constructionpermit',
                url: '/huda',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/huda.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('hudaForm');
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state("labour",{
                parent: 'constructionpermit',
                url: '/labourDepartment',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/labour.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('factotyPlan');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state("hsidc",{
                parent: 'constructionpermit',
                url: '/hsidc',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/hsidc.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("tcp",{
                parent: 'constructionpermit',
                url: '/tcp',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/tcp.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("tcp-oc",{
                parent: 'constructionpermit',
                url: '/tcp-oc',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/tcp-oc.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("ulb-oc",{
                parent: 'constructionpermit',
                url: '/ulb-oc',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/ulb-oc.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("ulb",{
                parent: 'constructionpermit',
                url: '/ulb',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/ulb.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("hsiidc-oc",{
                parent: 'constructionpermit',
                url: '/hsiidc-oc',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/hsiidc-oc.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
         .state("huda-oc",{
                parent: 'constructionpermit',
                url: '/huda-oc',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/huda-oc.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('hudaformOc');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state("fireNOC",{
                parent: 'constructionpermit',
                url: '/fireNOC',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/fireNOC.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state("inspectionOfficer",{
                parent: 'app',
                url: '/pendingInspectionReports',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/ConstructionPermit/inspectionOfficer.html',
                        controller: 'inspectionOfficerDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
         })
            .state("labour-oc",{
                parent: 'constructionpermit',
                url: '/labour-oc',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'nestedForm@constructionpermit': {
                        templateUrl: 'app/ConstructionPermit/labour-oc.html',
                        controller: 'constructionPermitDeptController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('aai');
                        $translatePartialLoader.addPart('nma');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })




            .state('serviceclearanceaction', {
                parent: 'projectdetailjist',
                url: '/serviceclearanceaction/{district}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_DEPT_CHANGE','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD', 'ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN','ROLE_CAF_STATUS_OFFICER']
                },
                views: {
                    'sideView@projectdetailjist': {
                        templateUrl: 'app/serviceclearanceactions/serviceclearanceactions.html',
                        controller: 'serviceClearanceActionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    projectServiceReportInfo: function () {
                        return {
                            projectid: null,
                            projecttype: null,
                            departmentname: null,
                            servicename: null,
                            assignDate: null,
                            requireDate: null,
                            status: null,
                            stage: null,
                            investorName: null,
                            cafPin: null,
                            finalAction: null,
                            finalActionDate: null,
                            projectInvestment: null,
                            projectEmployment: null,
                            id: null
                        };
                    },
                    projectServiceLog: function () {
                        return {
                            projectid: null,
                            serviceid: null,
                            comments: null,
                            commentDate: null,
                            commentByUserLogin: null,
                            actionTaken: null,
                            id: null
                        };
                    },
                    projectservicelist: ['$stateParams', 'ProjectservicedetailbyProject', function($stateParams, ProjectservicedetailbyProject) {
                        return ProjectservicedetailbyProject.get({id : $stateParams.projectid, status:"required"});
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            })
            .state('constructionpermit', {
                parent: 'app',
                url: '/constructionpermit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/serviceclearanceactions/constructionpermit.html',
                        controller: 'constructionPermitController',
                        controllerAs: 'vm'
                    }
                }
            })

        .state('assignservice', {
            parent: 'serviceclearanceaction',
            url: '/assignservice/{projectserviceid}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER','ROLE_DESK_OFFICER','ROLE_FILM_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/assignServiceDialog.html',
                    controller: 'assignServiceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectAttachemnt: function () {
                              return {
                                  fileName: null,
                                  description: null,
                                  fileExtension: null,
                                  serverFileName: null,
                                  id: null
                              };
                          },
                          projectServiceLog: function () {
                              return {
                                  projectid: null,
                                  serviceid: null,
                                  comments: null,
                                  commentDate: null,
                                  commentByUserLogin: null,
                                  actionTaken: null,
                                  id: null
                              };
                          },
                          projectService: ['Projectservicedetail', function(Projectservicedetail) {
                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                          }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })
        .state('assignotherservice', {
        parent: 'serviceclearanceaction',
        url: '/assignotherservice/{projectserviceid}',
        data: {
            authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER','ROLE_DESK_OFFICER','ROLE_FILM_OFFICER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/serviceclearanceactions/assignOtherServiceDialog.html',
                controller: 'assignOtherServiceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    projectAttachemnt: function () {
                          return {
                              fileName: null,
                              description: null,
                              fileExtension: null,
                              serverFileName: null,
                              id: null
                          };
                      },
                      projectServiceLog: function () {
                          return {
                              projectid: null,
                              serviceid: null,
                              comments: null,
                              commentDate: null,
                              commentByUserLogin: null,
                              actionTaken: null,
                              id: null
                          };
                      },
                      projectService: ['Projectservicedetail', function(Projectservicedetail) {
                        return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                      }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
            }, function() {
                $state.go('serviceclearanceaction');
            });
        }]
    })
    .state('serviceForm-fillform', {
        parent: 'serviceclearanceaction',
        url: '/fillform/{projectserviceid}',
        data: {
            authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_RM','ROLE_FILM_OFFICER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/serviceclearanceactions/serviceForm.html',
                controller: 'serviceFormController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    projectServiceFormFieldData: function () {
                        return {
                            serviceid: null,
                            formfieldvalue: null,
                            projectid: null,
                            formfieldName: null,
                            id: null
                        };
                    },
                    projectAttachemnt: function () {
                          return {
                              fileName: null,
                              description: null,
                              fileExtension: null,
                              serverFileName: null,
                              id: null
                          };
                      },
                      projectServiceLog: function () {
                          return {
                              projectid: null,
                              serviceid: null,
                              comments: null,
                              commentDate: null,
                              commentByUserLogin: null,
                              actionTaken: null,
                              id: null
                          };
                      },
                      projectService: ['Projectservicedetail', function(Projectservicedetail) {
                        return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                      }],
                      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                      }]
                }
            }).result.then(function() {
                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
            }, function() {
                $state.go('serviceclearanceaction');
            });
        }]
    })
    .state('servicePayment', {
          parent: 'serviceclearanceaction',
          url: '/payFee/{projectserviceid}',
          data: {
              authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_FILM_OFFICER']
          },
          onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
              $uibModal.open({
                  templateUrl: 'app/serviceclearanceactions/servicePayment.html',
                  controller: 'servicePaymentController',
                  controllerAs: 'vm',
                  backdrop: 'static',
                  size: 'lg',
                  resolve: {
                      projectServicePayment: function () {
                          return {
                              projectid: null,
                              serviceid: null,
                              paymentMade: null,
                              paymentMadeBy: null,
                              paymentDate: null,
                              id: null
                          };
                      },
                      projectAttachemnt: function () {
                        return {
                            fileName: null,
                            description: null,
                            fileExtension: null,
                            serverFileName: null,
                            id: null
                        };
                      },
                      projectServiceLog: function () {
                        return {
                            projectid: null,
                            serviceid: null,
                            comments: null,
                            commentDate: null,
                            commentByUserLogin: null,
                            actionTaken: null,
                            id: null
                        };
                      },
                      projectService: ['Projectservicedetail', function(Projectservicedetail) {
                          return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                      }],
                      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                          $translatePartialLoader.addPart('home');
                          return $translate.refresh();
                      }]
                  }
              }).result.then(function() {
                  $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
              }, function() {
                  $state.go('serviceclearanceaction');
              });
          }]
      })
 .state('otherServicePayment', {
          parent: 'serviceclearanceaction',
          url: '/payOtherFee/{projectserviceid}',
          data: {
              authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER']
          },
          onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
              $uibModal.open({
                  templateUrl: 'app/serviceclearanceactions/otherServicePayment.html',
                  controller: 'otherServicePaymentController',
                  controllerAs: 'vm',
                  backdrop: 'static',
                  size: 'lg',
                  resolve: {
                      projectServicePayment: function () {
                          return {
                              projectid: null,
                              serviceid: null,
                              paymentMade: null,
                              paymentMadeBy: null,
                              paymentDate: null,
                              isPaymentMade: null,
                              id: null
                          };
                      },
                      projectServiceLog: function () {
                        return {
                            projectid: null,
                            serviceid: null,
                            comments: null,
                            commentDate: null,
                            commentByUserLogin: null,
                            actionTaken: null,
                            id: null
                        };
                      },
                      projectService: ['Projectservicedetail', function(Projectservicedetail) {
                          return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                      }],
                      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                          $translatePartialLoader.addPart('home');
                          return $translate.refresh();
                      }]
                  }
              }).result.then(function() {
                  $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
              }, function() {
                  $state.go('serviceclearanceaction');
              });
          }]
      })

    .state('verifyPayment', {
            parent: 'serviceclearanceaction',
            url: '/verify/{projectserviceid}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_ACCOUNT_OFFICER','ROLE_NODAL_OFFICER','ROLE_USER','ROLE_FIELD_OFFICER','ROLE_FILM_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/verifyPayment.html',
                    controller: 'verifyPaymentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectServiceLog: function () {
                              return {
                                  projectid: null,
                                  serviceid: null,
                                  comments: null,
                                  commentDate: null,
                                  commentByUserLogin: null,
                                  actionTaken: null,
                                  id: null
                              };
                          },
                          projectService: ['Projectservicedetail', function(Projectservicedetail) {
                               return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                          }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })
.state('verifyOtherPayment', {
            parent: 'serviceclearanceaction',
            url: '/verifyOtherPay/{projectserviceid}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_ACCOUNT_OFFICER','ROLE_NODAL_OFFICER','ROLE_USER','ROLE_FIELD_OFFICER','ROLE_FILM_OFFICER','ROLE_TOWER_DC']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/verifyOtherPayment.html',
                    controller: 'verifyOtherPaymentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectServiceLog: function () {
                              return {
                                  projectid: null,
                                  serviceid: null,
                                  comments: null,
                                  commentDate: null,
                                  commentByUserLogin: null,
                                  actionTaken: null,
                                  id: null
                              };
                          },
                          projectService: ['Projectservicedetail', function(Projectservicedetail) {
                               return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                          }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })
    .state('viewFormFilled', {
        parent: 'serviceclearanceaction',
        url: '/ViewFilledForm/{projectserviceid}',
        data: {
            authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_RM','ROLE_USER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICER','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/serviceclearanceactions/serviceFormFilled.html',
                controller: 'serviceFormFilledController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    projectServiceLog: function () {
                          return {
                              projectid: null,
                              serviceid: null,
                              comments: null,
                              commentDate: null,
                              commentByUserLogin: null,
                              actionTaken: null,
                              id: null
                        };
                    },
                    projectService: ['Projectservicedetail', function(Projectservicedetail) {
                       return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
            }, function() {
                $state.go('serviceclearanceaction');
            });
        }]
    })

    .state('serviceLog', {
        parent: 'serviceclearanceaction',
        url: '/serviceLog/{projectserviceid}/{projectid}/{serviceid}',
        data: {
            authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_RM','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICER','ROLE_ACCOUNT_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_DEPT_CHANGE','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/serviceclearanceactions/serviceLog.html',
                controller: 'serviceLogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }],
                    serviceLogList: ['GetServiceLog', function(GetServiceLog) {
                        return GetServiceLog.query({projectid:$stateParams.projectid,serviceid:$stateParams.serviceid});
                    }],
                    service: ['DepartmentService', function(DepartmentService) {
                        return DepartmentService.get({id : $stateParams.serviceid}).$promise;
                    }],
                    projectService: ['Projectservicedetail', function(Projectservicedetail) {
                      return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                    }]
                }
            }).result.then(function() {
                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
            }, function() {
                $state.go('serviceclearanceaction');
            });
        }]
    })
  .state('serviceClearance-action', {
          parent: 'serviceclearanceaction',
          url: '/clear/{projectserviceid}',
          data: {
              authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_NODAL_OFFICER','ROLE_FILM_OFFICER']
          },
          onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
              $uibModal.open({
                  templateUrl: 'app/serviceclearanceactions/serviceclearance.html',
                  controller: 'serviceClearanceController',
                  controllerAs: 'vm',
                  backdrop: 'static',
                  size: 'lg',
                  resolve: {
                       projectAttachemnt: function () {
                            return {
                                fileName: null,
                                description: null,
                                fileExtension: null,
                                serverFileName: null,
                                id: null
                            };
                       },
                       projectServiceLog: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                comments: null,
                                commentDate: null,
                                commentByUserLogin: null,
                                actionTaken: null,
                                id: null
                            };
                       },
                       projectService: ['Projectservicedetail', function(Projectservicedetail) {
                          return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                       }],
                      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                          $translatePartialLoader.addPart('home');
                          return $translate.refresh();
                      }]
                  }
              }).result.then(function() {
                  $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
              }, function() {
                  $state.go('serviceclearanceaction');
              });
          }]
      })
  .state('deemedClearance', {
            parent: 'serviceclearanceaction',
            url: '/deemed/{projectserviceid}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DESK_OFFICER','ROLE_FILM_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/deemedClearance.html',
                    controller: 'deemedClearanceController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectAttachemnt: function () {
                            return {
                              fileName: null,
                              description: null,
                              fileExtension: null,
                              serverFileName: null,
                              id: null
                        };
                        },
                        projectServiceLog: function () {
                            return {
                              projectid: null,
                              serviceid: null,
                              comments: null,
                              commentDate: null,
                              commentByUserLogin: null,
                              actionTaken: null,
                              id: null
                            };
                        },
                        projectService: ['Projectservicedetail', function(Projectservicedetail) {
                          return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })
        .state('comment-dialog', {
              parent: 'serviceclearanceaction',
              url: '/comment/{projectserviceid}',
              data: {
                  authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_RM','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICER','ROLE_ACCOUNT_OFFICER','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER']
              },
              onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                  $uibModal.open({
                      templateUrl: 'app/serviceclearanceactions/commentDialog.html',
                      controller: 'commentDialogController',
                      controllerAs: 'vm',
                      backdrop: 'static',
                      size: 'lg',
                      resolve: {
                          projectAttachemnt: function () {
                              return {
                                  fileName: null,
                                  description: null,
                                  fileExtension: null,
                                  serverFileName: null,
                                  id: null
                              };
                          },
                          projectServiceLog: function () {
                              return {
                                  projectid: null,
                                  serviceid: null,
                                  comments: null,
                                  commentDate: null,
                                  commentByUserLogin: null,
                                  actionTaken: null,
                                  id: null
                              };
                          },
                          projectService: ['Projectservicedetail', function(Projectservicedetail) {
                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                          }],
                          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                              $translatePartialLoader.addPart('home');
                              return $translate.refresh();
                          }]
                      }
                  }).result.then(function() {
                      $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                  }, function() {
                      $state.go('serviceclearanceaction');
                  });
              }]
          })
          .state('reject-dialog', {
                parent: 'serviceclearanceaction',
                url: '/reject/{projectserviceid}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FILM_OFFICER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/serviceclearanceactions/rejectDialog.html',
                        controller: 'rejectDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            projectAttachemnt: function () {
                                return {
                                    fileName: null,
                                    description: null,
                                    fileExtension: null,
                                    serverFileName: null,
                                    id: null
                                };
                            },
                            projectServiceLog: function () {
                                return {
                                    projectid: null,
                                    serviceid: null,
                                    comments: null,
                                    commentDate: null,
                                    commentByUserLogin: null,
                                    actionTaken: null,
                                    id: null
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                $translatePartialLoader.addPart('home');
                                return $translate.refresh();
                            }],
                            projectService: ['Projectservicedetail', function(Projectservicedetail) {
                                return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                            }]
//                            projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
//                                return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
//                            }]
                        }
                    }).result.then(function() {
                        $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                    }, function() {
                        $state.go('serviceclearanceaction');
                    });
                }]
            })
            .state('decline-dialog', {
                            parent: 'serviceclearanceaction',
                            url: '/decline/{projectserviceid}',
                            data: {
                                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FILM_OFFICER']
                            },
                            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                                $uibModal.open({
                                    templateUrl: 'app/serviceclearanceactions/declineDialog.html',
                                    controller: 'declineDialogController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'lg',
                                    resolve: {
                                        projectAttachemnt: function () {
                                            return {
                                                fileName: null,
                                                description: null,
                                                fileExtension: null,
                                                serverFileName: null,
                                                id: null
                                            };
                                        },
                                        projectServiceLog: function () {
                                            return {
                                                projectid: null,
                                                serviceid: null,
                                                comments: null,
                                                commentDate: null,
                                                commentByUserLogin: null,
                                                actionTaken: null,
                                                id: null
                                            };
                                        },
                                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                            $translatePartialLoader.addPart('home');
                                            return $translate.refresh();
                                        }],
                                        projectService: ['Projectservicedetail', function(Projectservicedetail) {
                                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                                        }]
            //                            projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
            //                                return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
            //                            }]
                                    }
                                }).result.then(function() {
                                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                                }, function() {
                                    $state.go('serviceclearanceaction');
                                });
                            }]
                        })
            .state('reactivate', {
                parent: 'serviceclearanceaction',
                url: '/reactivate/{projectserviceid}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FILM_OFFICER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/serviceclearanceactions/reactivateDialog.html',
                        controller: 'reactivateDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            projectAttachemnt: function () {
                                return {
                                    fileName: null,
                                    description: null,
                                    fileExtension: null,
                                    serverFileName: null,
                                    id: null
                                };
                            },
                            projectServiceLog: function () {
                                return {
                                    projectid: null,
                                    serviceid: null,
                                    comments: null,
                                    commentDate: null,
                                    commentByUserLogin: null,
                                    actionTaken: null,
                                    id: null
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                $translatePartialLoader.addPart('home');
                                return $translate.refresh();
                            }],
                            projectService: ['Projectservicedetail', function(Projectservicedetail) {
                                return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                            }]
        //                            projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
        //                                return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
        //                            }]
                        }
                    }).result.then(function() {
                        $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                    }, function() {
                        $state.go('serviceclearanceaction');
                    });
                }]
        })

        .state('undoreject', {
                        parent: 'serviceclearanceaction',
                        url: '/undoreject/{projectserviceid}',
                        data: {
                            authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_DESK_OFFICER','ROLE_NODAL_OFFICER','ROLE_FILM_OFFICER']
                        },
                        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/serviceclearanceactions/undoRejectDialog.html',
                                controller: 'undoRejectDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    projectAttachemnt: function () {
                                        return {
                                            fileName: null,
                                            description: null,
                                            fileExtension: null,
                                            serverFileName: null,
                                            id: null
                                        };
                                    },
                                    projectServiceLog: function () {
                                        return {
                                            projectid: null,
                                            serviceid: null,
                                            comments: null,
                                            commentDate: null,
                                            commentByUserLogin: null,
                                            actionTaken: null,
                                            id: null
                                        };
                                    },
                                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                        $translatePartialLoader.addPart('home');
                                        return $translate.refresh();
                                    }],
                                    projectService: ['Projectservicedetail', function(Projectservicedetail) {
                                        return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                                    }]
                //                            projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
                //                                return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
                //                            }]
                                }
                            }).result.then(function() {
                                $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                            }, function() {
                                $state.go('serviceclearanceaction');
                            });
                        }]
                })

        .state('i-serviceform', {
            parent: 'app',
            url: '/i-serviceform/{projectid}/{serviceid}/{projectserviceid}/{id}/{markeddate}/{requestType}',

            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_FIELD_OFFICER','ROLE_FILM_OFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/serviceclearanceactions/i-serviceform.html',
                    controller: 'iServiceFormController',
                    controllerAs: 'vm'
                }
            },
            resolve : {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                }],
                Project:['$stateParams','Project',function ($stateParams,Project) {
                   return Project.get({id:$stateParams.projectid}).$promise;
                }],
                Users:['$stateParams','UserById',function ($stateParams,UserById) {
                   return UserById.get({id: $stateParams.id}).$promise;
                }],
                ExtendedUsers:['$stateParams','ExtendedUserInformation',function ($stateParams,ExtendedUserInformation) {
                return ExtendedUserInformation.get({id: $stateParams.id}).$promise;
                }],
                Projectservicedetail:['$stateParams','Projectservicedetail',function ($stateParams,Projectservicedetail) {
                   return Projectservicedetail.get({id:$stateParams.projectserviceid}).$promise;
                }]
            }
        })
        .state('paymentResponse', {
            parent: 'app',
            url: '/paymentResponse',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/serviceclearanceactions/paymentResponse.html',
                    controller: 'paymentResponseController',
                    controllerAs: 'vm'
                }
            },
            resolve : {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                }]
            }
        })
        .state('landexchange-form', {
            parent: 'serviceclearanceaction',
            url: '/landexchange-form/{projectserviceid}',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/landexchange-dialog.html',
                    controller: 'LandexchangeDialogController2',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                investor_name: null,
                                address: null,
                                contact: null,
                                reason: null,
                                owner_name: null,
                                tehsil: null,
                                block: null,
                                village: null,
                                khasra: null,
                                area_of_land: null,
                                market_value: null,
                                owner_name_offered: null,
                                tehsil_offered: null,
                                block_offered: null,
                                village_offered: null,
                                khasra_offered: null,
                                area_of_land_offered: null,
                                market_value_offered: null,
                                email: null,
                                name_of_gramPanchayat: null,
                                gramPanchayat_code: null,
                                district: null,
                                khewat: null,
                                khatoni: null,
                                type_of_land: null,
                                district_offered: null,
                                hadbast_offered: null,
                                khewat_offered: null,
                                khatoni_offered: null,
                                type_of_land_offered: null,
                                hadbast: null,
                                id: null
                            };
                        },
                        projectServiceFormFieldData: function () {
                            return {
                                serviceid: null,
                                formfieldvalue: null,
                                projectid: null,
                                formfieldName: null,
                                id: null
                            };
                        },
                        projectAttachemnt: function () {
                            return {
                                fileName: null,
                                description: null,
                                fileExtension: null,
                                serverFileName: null,
                                id: null
                            };
                        },
                        projectServiceLog: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                comments: null,
                                commentDate: null,
                                commentByUserLogin: null,
                                actionTaken: null,
                                id: null
                            };
                        },
                        projectService: ['Projectservicedetail', function(Projectservicedetail) {
                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            $translatePartialLoader.addPart('landexchange');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })
        .state('ULBlandexchange-form', {
            parent: 'serviceclearanceaction',
            url: '/ULBlandexchange-form/{projectserviceid}',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/ULBlandexchange-dialog.html',
                    controller: 'LandexchangeDialogController2',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                investor_name: null,
                                address: null,
                                contact: null,
                                reason: null,
                                owner_name: null,
                                tehsil: null,
                                block: null,
                                village: null,
                                khasra: null,
                                area_of_land: null,
                                market_value: null,
                                owner_name_offered: null,
                                tehsil_offered: null,
                                block_offered: null,
                                village_offered: null,
                                khasra_offered: null,
                                area_of_land_offered: null,
                                market_value_offered: null,
                                email: null,
                                name_of_gramPanchayat: null,
                                gramPanchayat_code: null,
                                district: null,
                                khewat: null,
                                khatoni: null,
                                type_of_land: null,
                                district_offered: null,
                                hadbast_offered: null,
                                khewat_offered: null,
                                khatoni_offered: null,
                                type_of_land_offered: null,
                                hadbast: null,
                                id: null
                            };
                        },
                        projectServiceFormFieldData: function () {
                            return {
                                serviceid: null,
                                formfieldvalue: null,
                                projectid: null,
                                formfieldName: null,
                                id: null
                            };
                        },
                        projectAttachemnt: function () {
                            return {
                                fileName: null,
                                description: null,
                                fileExtension: null,
                                serverFileName: null,
                                id: null
                            };
                        },
                        projectServiceLog: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                comments: null,
                                commentDate: null,
                                commentByUserLogin: null,
                                actionTaken: null,
                                id: null
                            };
                        },
                        projectService: ['Projectservicedetail', function(Projectservicedetail) {
                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            $translatePartialLoader.addPart('landexchange');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })

    .state('landexchange-viewform', {
    parent: 'serviceclearanceaction',
    url: '/landexchange-viewform/{projectserviceid}/{projectid}/{serviceid}',
    data: {
        authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN'],
        pageTitle: 'investhryApp.landexchange.detail.title'
    },
    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        $uibModal.open({
            templateUrl: 'app/serviceclearanceactions/landexchange-detail.html',
            controller: 'LandexchangeDetailController2',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                entity: ['Landexchange', function(Landexchange) {
                    return Landexchange.get({id : $stateParams.projectserviceid}).$promise;
                }],
                serviceFormAttachments: ['ServiceFormAttachments', function(ServiceFormAttachments) {
                    return ServiceFormAttachments.query({id:"1",serviceid:$stateParams.serviceid, projectid:$stateParams.projectid}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('landexchange');
                    return $translate.refresh();
                }]
            }
        }).result.then(function() {
            $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
        }, function() {
            $state.go('serviceclearanceaction');
        });
    }]
})
    .state('ULBlandexchange-viewform', {
            parent: 'serviceclearanceaction',
            url: '/ULBlandexchange-viewform/{projectserviceid}/{projectid}/{serviceid}',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN'],
                pageTitle: 'investhryApp.landexchange.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/ULBlandexchange-detail.html',
                    controller: 'LandexchangeDetailController2',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Landexchange', function(Landexchange) {
                            return Landexchange.get({id : $stateParams.projectserviceid}).$promise;
                        }],
                        serviceFormAttachments: ['ServiceFormAttachments', function(ServiceFormAttachments) {
                            return ServiceFormAttachments.query({id:"1",serviceid:$stateParams.serviceid, projectid:$stateParams.projectid}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            $translatePartialLoader.addPart('landexchange');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })

    .state('view-checklist', {
            parent: 'app',
            url: '/checklist/{serviceid}',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_FILM_OFFICER'],
                pageTitle: 'checklist'
            },
            views: {
                'content@': {
                    templateUrl: 'app/serviceclearanceactions/checklist.html',
                    controller: 'ChecklistViewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    .state('serviceclearanceaction.required', {
        parent: 'projectdetailjist',
        url: '/required-services/{district}',
        data: {
            authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_DEPT_CHANGE','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD', 'ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_CAF_STATUS_OFFICER']
        },
        views: {
            'sideView@projectdetailjist': {
                templateUrl: 'app/serviceclearanceactions/requiredList.html',
                controller: 'RequiredListController',
                controllerAs: 'vm'
            }
        },
        resolve: {
            projectServiceLog: function () {
                return {
                    projectid: null,
                    serviceid: null,
                    comments: null,
                    commentDate: null,
                    commentByUserLogin: null,
                    actionTaken: null,
                    id: null
                };
            },
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                $translatePartialLoader.addPart('home');
                return $translate.refresh();
            }],
            // Project:['$stateParams','Project',function ($stateParams,Project) {
            //     return Project.get({id:$stateParams.projectid}).$promise;
            // }]
        }
    })
    .state('withdraw-dialog', {
            parent: 'serviceclearanceaction',
            url: '/withdraw/{projectserviceid}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/withdrawDialog.html',
                    controller: 'withdrawDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectAttachemnt: function () {
                            return {
                                fileName: null,
                                description: null,
                                fileExtension: null,
                                serverFileName: null,
                                id: null
                            };
                        },
                        projectServiceLog: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                comments: null,
                                commentDate: null,
                                commentByUserLogin: null,
                                actionTaken: null,
                                id: null
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }],
                        projectService: ['Projectservicedetail', function(Projectservicedetail) {
                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                        }]
                        //                            projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
                        //                                return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
                        //                            }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        })
        .state('clarification-dialog', {
            parent: 'serviceclearanceaction',
            url: '/clarification/{role}/{projectserviceid}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/clarificationDialog.html',
                    controller: 'clarificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectAttachemnt: function () {
                            return {
                                fileName: null,
                                description: null,
                                fileExtension: null,
                                serverFileName: null,
                                id: null
                            };
                        },
                        projectServiceLog: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                comments: null,
                                commentDate: null,
                                commentByUserLogin: null,
                                actionTaken: null,
                                id: null
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }],
                        projectService: ['Projectservicedetail', function(Projectservicedetail) {
                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                        }]
                        //                            projectservicereportinfo: ['ProjectServiceReportInfoForClearance', function(ProjectServiceReportInfoForClearance) {
                        //                                return ProjectServiceReportInfoForClearance.get({department:$stateParams.projectService.departmentName,projectid:$stateParams.projectService.projectid,service:$stateParams.projectService.serviceName});
                        //                            }]
                    }
                }).result.then(function() {
                    $state.go('serviceclearanceaction', null, { reload: 'serviceclearanceaction' });
                }, function() {
                    $state.go('serviceclearanceaction');
                });
            }]
        });

    }
})();
