(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ApplicationLogsController', ApplicationLogsController);

    ApplicationLogsController.$inject = ['ApplicationLogs'];

    function ApplicationLogsController(ApplicationLogs) {

        var vm = this;

        vm.applicationLogs = [];

        loadAll();

        function loadAll() {
            ApplicationLogs.query(function(result) {
                vm.applicationLogs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
