(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('EstateManagerDetailController', EstateManagerDetailController);

    EstateManagerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'EstateManager'];

    function EstateManagerDetailController($scope, $rootScope, $stateParams, previousState, entity, EstateManager) {
        var vm = this;

        vm.estateManager = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:estateManagerUpdate', function(event, result) {
            vm.estateManager = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
