(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('MigrateHomePageCounts', MigrateHomePageCounts);

    MigrateHomePageCounts.$inject = ['$resource'];

    function MigrateHomePageCounts ($resource) {
        var resourceUrl =  '/api/home-outputs';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: false},
            'query': { method: 'GET', isArray: true}
        });
    }
})();
