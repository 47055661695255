(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerdiolistDeleteController',TowerdiolistDeleteController);

    TowerdiolistDeleteController.$inject = ['$uibModalInstance', 'entity', 'Towerdiolist'];

    function TowerdiolistDeleteController($uibModalInstance, entity, Towerdiolist) {
        var vm = this;

        vm.towerdiolist = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Towerdiolist.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
