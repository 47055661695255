(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dashboardbetweendates', {
            parent: 'app',
            url: '/dashboardbetweendates',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/dashboardbetweendates/dashboardbetweendates.html',
                    controller: 'DashboardbetweendatesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('state');
                    return $translate.refresh();
                }]
            }
        })
        .state('dashboardCAFs', {
            parent: 'dashboardbetweendates',
            url: '/dashboardCAFs/{serviceid}/{status}/{from}/{to}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/dashboardbetweendates/dashboardbetweendatesCAFs.html',
                    controller: 'DashboardbetweendatesCAFsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowClass: 'app-modal-window',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('dashboardbetweendates', {}, { reload: false });
                }, function() {
                    $state.go('dashboardbetweendates');
                });
            }]
        })

        .state('dashboardCAFAllServices', {
            parent: 'dashboardbetweendates',
            url: '/dashboardCAFAllServices/{from}/{to}/{status}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/dashboardbetweendates/dashboardbetweendatesAllServices.html',
                    controller: 'DashboardbetweendatesAllServicesController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowClass: 'app-modal-window',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('dashboardbetweendates', {}, { reload: false });
                }, function() {
                    $state.go('dashboardbetweendates');
                });
            }]
        });

    }
})();
