(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceLogController', ServiceLogController);
    ServiceLogController.$inject = ['$stateParams', '$scope', 'Principal', 'LoginService', '$state', 'Projectlogupdated', 'ProjectLogNewCaf'];

    function ServiceLogController ($stateParams, $scope, Principal, LoginService, $state, Projectlogupdated, ProjectLogNewCaf) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        $scope.projectId = $stateParams.projectid;
        $scope.cafType = $stateParams.cafType;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();
        loadProjectLog();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register () {
            $state.go('register');
        }

        function loadProjectLog() {
            if($scope.cafType === 'New'){
                ProjectLogNewCaf.query({projectid: $scope.projectId}, function(result) {
                    vm.projectlogsnewcaf = result;
                });
            }
            else {
                Projectlogupdated.query({projectid: $scope.projectId}, function(result) {
                    vm.projectlogs = result;
                    console.log(result)
                });
            }
        }
    }

})();
