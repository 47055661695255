(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('BelowGroundDetailController', BelowGroundDetailController);

    BelowGroundDetailController.$inject = ['$scope', '$state', '$stateParams', 'entity', 'Below_ground', 'District', 'Zone','FileManagement','downloadserviceformattachment'];

    function BelowGroundDetailController($scope, $state, $stateParams, entity, Below_ground, District, Zone,FileManagement,downloadserviceformattachment) {
        var vm = this;
        vm.save= save;
        vm.download=download;
        vm.upload=upload;
        $scope.file={};
        var savefilename=null;

        vm.below_ground = entity;
        vm.edit_form= edit_form;
        $scope.isDisabled =true;

        loadAll();

        function loadAll() {
            District.query(function(result) {
                vm.districts = result;
                console.log(result);
                vm.searchQuery = null;
            });
            Zone.query(function(result) {
                vm.zones = result;
                vm.searchQuery = null;
            });
        }

        function save () {
            vm.isSaving = true;
            vm.show1= true;
            if (vm.below_ground.id !== null) {
                vm.below_ground.status = 'Pending';
                Below_ground.update(vm.below_ground, onSaveSuccess, onSaveError);
            } else {
                vm.below_ground.isfullyfilled = false;
                console.log(vm.below_ground.isfullyfilled);
                Below_ground.save(vm.below_ground, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $state.go("belowGround");
            //$scope.$emit('investhryApp:below_groundUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function edit_form() {
            vm.show= true;
            $scope.isDisabled =false;
        }

        function download(serverfilename,clientfilename) {
            downloadserviceformattachment.download(serverfilename,clientfilename);
        }

        function  upload(i,object) {
            var fileNameArray = object.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var filename = vm.below_ground.id+'_'+ i;
            savefilename=filename+ "." + fileextension;

            var file =object;
            FileManagement.saveFile(file,filename,onFileUploadedSuccess,onFileUploadedError);
            vm.vari=i;

        }
        function onFileUploadedSuccess() {
            UIkit.notification("File Uploaded Successfully . . .",{timeout: 2000});
            if (vm.vari==1){
                vm.show_upload1=true;
                vm.below_ground.document1= savefilename;
                Below_ground.update(vm.below_ground);
            }
            else if(vm.vari==2){
                vm.show_upload2=true;
                vm.below_ground.document2= savefilename;
                Below_ground.update(vm.below_ground);
            }
            else if(vm.vari==3){
                vm.show_upload3=true;
                vm.below_ground.document3= savefilename;
                Below_ground.update(vm.below_ground);
            }
            else if(vm.vari==4){
                vm.show_upload4=true;
                vm.below_ground.document4= savefilename;
                Below_ground.update(vm.below_ground);
            }
            else if(vm.vari==5){
                vm.show_upload5=true;
                vm.below_ground.document5= savefilename;
                Below_ground.update(vm.below_ground);
            }
            else if(vm.vari==6){
                vm.show_upload6=true;
                vm.below_ground.document6= savefilename;
                vm.below_ground.isfullyfilled = true;
                Below_ground.update(vm.below_ground);
            }
            else if(vm.vari==7){
                vm.show_upload7=true;
                vm.below_ground.document7= savefilename;
                Below_ground.update(vm.below_ground);
            }
            else if(vm.vari==8){
                vm.show_upload8=true;
                vm.below_ground.document8= savefilename;
                Below_ground.update(vm.below_ground);
            }
        }

        function onFileUploadedError() {
            UIkit.notification("Unexpected Error Occurred! Try Again . .",{timeout: 2000,status: 'danger'});
        }
    }
})();
