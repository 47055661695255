(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('nma', {
            parent: 'entity',
            url: '/nma',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.nma.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/nma/nmas.html',
                    controller: 'NmaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('nma');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('nma-detail', {
            parent: 'nma',
            url: '/nma/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.nma.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/nma/nma-detail.html',
                    controller: 'NmaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('nma');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Nma', function($stateParams, Nma) {
                    return Nma.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'nma',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('nma-detail.edit', {
            parent: 'nma-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/nma/nma-dialog.html',
                    controller: 'NmaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Nma', function(Nma) {
                            return Nma.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('nma.new', {
            parent: 'nma',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/nma/nma-dialog.html',
                    controller: 'NmaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                department: null,
                                architectName: null,
                                architectEmail: null,
                                architectMobile: null,
                                nocRequestScreen: null,
                                nameOfApplicant: null,
                                presentAddress: null,
                                permanentAddress: null,
                                isOwner: null,
                                nameOfOwner: null,
                                presentAddressOwner: null,
                                permanentAddressOwner: null,
                                typeOwner1: null,
                                typeOwner2: null,
                                typeOwner3: null,
                                plotNo: null,
                                state: null,
                                district: null,
                                taulk: null,
                                locality: null,
                                monumentName: null,
                                stateMonument: null,
                                districtMonument: null,
                                taulkMonument: null,
                                localityMonument: null,
                                distance1: null,
                                distance2: null,
                                workDetails1: null,
                                workDetails2: null,
                                workDetails3: null,
                                workDetails4: null,
                                workDetails5: null,
                                workDetails6: null,
                                workDetails7: null,
                                workDetails8: null,
                                workDetails9: null,
                                workDetails10: null,
                                workDetails11: null,
                                workDetails12: null,
                                otherWorkDetails1: null,
                                otherWorkDetails2: null,
                                otherWorkDetails3: null,
                                otherWorkDetails4: null,
                                otherWorkDetails5: null,
                                otherWorkDetails6: null,
                                otherWorkDetails7: null,
                                otherWorkDetails8: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('nma', null, { reload: 'nma' });
                }, function() {
                    $state.go('nma');
                });
            }]
        })
        .state('nma.edit', {
            parent: 'nma',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/nma/nma-dialog.html',
                    controller: 'NmaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Nma', function(Nma) {
                            return Nma.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('nma', null, { reload: 'nma' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('nma.delete', {
            parent: 'nma',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/nma/nma-delete-dialog.html',
                    controller: 'NmaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Nma', function(Nma) {
                            return Nma.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('nma', null, { reload: 'nma' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
