(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider'];
    function stateConfig($stateProvider) {
        $stateProvider
        .state('assignFieldOfficerToDepartment', {
            parent: 'app',
            url: '/assignFieldOfficer/',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/assignFieldOfficerToDepartment/assignFieldOfficerToDepartment.html',
                    controller: 'assignFieldOfficerToDepartmentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        departmentid: null,
                        departmentname: null,
                        fieldOfficerid: null,
                        fieldofficerlogin: null,
                        fieldofficerdesignation: null,
                        fieldofficeraddress: null,
                        id: null
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('block');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
