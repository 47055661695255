(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tickets', {
            parent: 'entity',
            url: '/tickets',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.tickets.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tickets/tickets.html',
                    controller: 'TicketsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tickets');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('tickets-detail', {
            parent: 'tickets',
            url: '/tickets/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'investhryApp.tickets.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tickets/tickets-detail.html',
                    controller: 'TicketsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tickets');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tickets', function($stateParams, Tickets) {
                    return Tickets.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tickets',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('tickets-detail.edit', {
            parent: 'tickets-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tickets/tickets-dialog.html',
                    controller: 'TicketsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Tickets', function(Tickets) {
                            return Tickets.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tickets.new', {
            parent: 'tickets',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tickets/tickets-dialog.html',
                    controller: 'TicketsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                ticketNumber: null,
                                cafpin: null,
                                investorDetail: null,
                                latestRemark: null,
                                creationDate: null,
                                resolveDate: null,
                                assignDate: null,
                                assignedDepartmentId: null,
                                assignedDepartmentName: null,
                                assignedMemberId: null,
                                assignedMemberName: null,
                                subject: null,
                                description: null,
                                status: null,
                                projectid: null,
                                serviceid: null,
                                priority: null,
                                incidentCategory: null,
                                level: null,
                                investorType: null,
                                nextLevelMember: null,
                                timeLeft: null,
                                expectedResoluation: null,
                                nextLevelMemberId: null,
                                createdBy: null,
                                attachment: null,
                                assignedMemberUserName: null,
                                nextLevelMemberName: null,
                                createdByName: null,
                                isOpen: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('tickets', null, { reload: 'tickets' });
                }, function() {
                    $state.go('tickets');
                });
            }]
        })
        .state('tickets.edit', {
            parent: 'tickets',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tickets/tickets-dialog.html',
                    controller: 'TicketsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Tickets', function(Tickets) {
                            return Tickets.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tickets', null, { reload: 'tickets' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tickets.delete', {
            parent: 'tickets',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tickets/tickets-delete-dialog.html',
                    controller: 'TicketsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Tickets', function(Tickets) {
                            return Tickets.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tickets', null, { reload: 'tickets' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
