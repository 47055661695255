(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceformdraftsavedDetailController', ServiceformdraftsavedDetailController);

    ServiceformdraftsavedDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Serviceformdraftsaved'];

    function ServiceformdraftsavedDetailController($scope, $rootScope, $stateParams, previousState, entity, Serviceformdraftsaved) {
        var vm = this;

        vm.serviceformdraftsaved = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:serviceformdraftsavedUpdate', function(event, result) {
            vm.serviceformdraftsaved = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
