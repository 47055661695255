(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('password', {
            parent: 'account',
            url: '/password',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN','ROLE_SUB_ADMIN', 'ROLE_RM', 'ROLE_DESK_OFFICER', 'ROLE_NODAL_OFFICER', 'ROLE_ACCOUNT_OFFICER', 'ROLE_FIELD_OFFICER','ROLE_ASSIGNRM_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_API_VIEWER','ROLE_GRIEVANCE_OFFICER','ROLE_GRIEVANCE_ADMIN', 'ROLE_TOWER_DC', 'ROLE_TOWER_USER', 'ROLE_TOWER_NODAL_OFFICER', 'ROLE_TOWER_DEPARTMENT', 'ROLE_CPUSER', 'ROLE_TOWER_DIO','ROLE_TOWER_OFFICER','ROLE_DEPT_CHANGE','ROLE_USER_EXT','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER', 'ROLE_L1_SUPPORT', 'ROLE_L2_IT_MANAGER', 'ROLE_L2_INCENTIVES', 'ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_TICKET_OPERATION_MANAGER', 'ROLE_TICKET_NODAL_OFFICER', 'ROLE_FEEDBACK_OFFICER', 'ROLE_TICKET_ADMIN' ,'ROLE_SKILLS_REQUIREMENT_OFFICER','ROLE_CAF_STATUS_OFFICER','ROLE_INCENTIVE_DISBURSEMENT_HEAD'],
                pageTitle: 'global.menu.account.password'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/password/password.html',
                    controller: 'PasswordController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('password');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
