(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('constructionPermitController', constructionPermitController);

    constructionPermitController.$inject = ['$rootScope', '$timeout', '$scope', '$stateParams','$state', 'ConstructionPermitApprovalSteps', 'ConstructionPermitLog', 'ConstructionPermitApproval', 'FileManagement', 'InspectionReport', 'downloadserviceformattachment', 'Aai_service', 'Nma'];

    function constructionPermitController ($rootScope, $timeout, $scope, $stateParams, $state, ConstructionPermitApprovalSteps, ConstructionPermitLog, ConstructionPermitApproval, FileManagement, InspectionReport, downloadserviceformattachment, Aai_service, Nma) {
        var vm = this;
        vm.constructionPermit={};
        vm.inspectionReport={};
        vm.projectid = $stateParams.id;
        vm.constructionPermitLog={};
        vm.aai={};
        vm.nma={};
        vm.filesdata=[];
        // vm.constructionPermit.serviceId=null;

        $scope.creation_date= new Date();

        loadAll();

        $scope.$on("investhry: constructionPermitEvent",function (event) {
            loadAll();
        });

        function loadAll() {
            ConstructionPermitApproval.query({projectId: $stateParams.id},function (result) {
                vm.constructionPermit= result;
                // console.log(result);
            })
        }

        vm.viewNMA= function (id) {
            Nma.get({id: id},function (result) {
                console.log(result);
                vm.nma=result;
            })
        };

        vm.toggleShow=function () {
          vm.show=!vm.show;
        };

        vm.savePlinth= function () {
            vm.constructionPermitLog.projectid = vm.projectid;
            vm.constructionPermitLog.serviceid = vm.constructionPermit.serviceId;
            vm.constructionPermitLog.action = "Plinth Inspection Scheduled";
            ConstructionPermitLog.save(vm.constructionPermitLog,onplinthSaveSuccess);
        };

        function onplinthSaveSuccess(result) {
            vm.constructionPermit.pIflag=true;
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onCPsaveSuccess);
        }

        function onCPsaveSuccess() {
            loadAll();
        }

        vm.showInspectionReport= function () {
            console.log("called");
            if(vm.constructionPermit.serviceIdEC ===null){
                console.log("if");
                onfindOneError();
            }
            else {
                console.log("else");
                InspectionReport.query({id:'1', projectid:vm.constructionPermit.projectId},onfindOneSuccess,onfindOneError);
            }
        };

        function onfindOneSuccess(result) {
            vm.filesdata= result;
            console.log(result);
            // downloadserviceformattachment.download(result.reportURL,"Inspection Report.pdf");
        }

        function onfindOneError() {
            alert("Inspection Report Not Uploaded ! !  \n\nCheck Again Later");
        }

        vm.OccupationCertificate= function () {
            if(vm.constructionPermit.serviceId==='43575ec0-7dd3-40bd-b3c4-47638d17bf3c'){
                $state.go('huda-oc');
            }
            else if(vm.constructionPermit.serviceId='a69786f5-c674-4de9-8807-8689c7ef86b5'){
                $state.go('hsiidc-oc');
            }
            else if(vm.constructionPermit.serviceId='159a0331-87c3-407d-9c6c-559455d4b6ab'){
                $state.go('tcp-oc');
            }
            else if(vm.constructionPermit.serviceId='1c90c949-f2d1-4997-84b0-e6b2d40fe5e6'){
                $state.go('ulb-oc');
            }
            // else if(vm.constructionPermit.serviceId='1c90c949-f2d1-4997-84b0-e6b2d40fe5e6'){
            //     $state.go('labour-oc');
            // }
        };

        vm.showLog=function () {
            ConstructionPermitLog.query({id: '1',projectid:vm.projectid},function(result) {
                vm.constructionPermitLogs = result;
                vm.searchQuery = null;
            });
        };

        vm.fireNOC= function () {
            vm.constructionPermit.oCflag=true;
            ConstructionPermitApprovalSteps.update(vm.constructionPermit,onFireNOCsaveSuccess);
        };

        function onFireNOCsaveSuccess() {
            vm.OccupationCertificate();
        }

        vm.downloadReport=function (result) {
            downloadserviceformattachment.download(result,"Inspection Report.pdf");
        };

        vm.downloadReportInspection=function (result) {
            downloadserviceformattachment.download(result+"_10.pdf","Inspection Report.pdf");
        }

    }
})();
