(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('InspectionReportController', InspectionReportController);

    InspectionReportController.$inject = ['InspectionReport'];

    function InspectionReportController(InspectionReport) {

        var vm = this;

        vm.inspectionReports = [];

        loadAll();

        function loadAll() {
            InspectionReport.query(function(result) {
                vm.inspectionReports = result;
                vm.searchQuery = null;
            });
        }
    }
})();
