(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('newincentiveswizard', {
            parent: 'app',
           url: '/new-incentive-wizard',
            data: {
                authorities: [],
            },
            views: {
                'content@': {
                    templateUrl: 'app/incentive-wizard/incentivewizard.html',
                    controller: 'IncentiveWizardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                districts: ['District', function(District) {
                    return District.query().$promise;
                }],
                sectors: ['Sector', function(Sector) {
                    return Sector.query().$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('eodb');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
