(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerInstallationController', TowerInstallationController);

    TowerInstallationController.$inject = ['$scope', '$state', 'Principal', 'TowerInstallationAboveService', 'TowerInstallationBelowService', 'TowerInstallationService', 'Above_ground', 'Below_ground', 'Tower_log','downloadserviceformattachment','FileManagement'];

    function TowerInstallationController($scope, $state, Principal , TowerInstallationAboveService, TowerInstallationBelowService, TowerInstallationService, Above_ground, Below_ground, Tower_log,downloadserviceformattachment,FileManagement) {
        var vm = this;
        vm.save= save;

        vm.show_type=show_type;
        vm.show_status=show_status;

        vm.action= action;
        vm.detail= detail;
        vm.download=download;
        vm.view_log=view_log;
        vm.disableSave=disableSave;
        vm.towerLog={};
        vm.show_request=show_request;

        vm.towertype='Above';
        vm.status='N';
        vm.requesttype='false';

        $scope.file={};

        vm.forward=forward;
        // vm.get_TowerRequests=get_TowerRequests;

        loadAll(vm.towertype,vm.status,vm.requesttype);

        function loadAll(towertype,status,requesttype) {
            if(towertype==='Above'){
                vm.show1= false;
                vm.show2= false;
                console.log(status);
                console.log(requesttype);
                TowerInstallationAboveService.query({status:status,request:requesttype},function (result) {
                    vm.above_grounds = result;
                    vm.searchQuery = null;
                })
            }
            else {
                vm.show1= true;
                vm.show2= true;
                TowerInstallationBelowService.query({status:status,request:requesttype},function (result) {
                    vm.below_grounds = result;
                    vm.searchQuery = null;
                })
            }

        }

        function save (id) {
            vm.isSaving = true;
            vm.id=id;

            if (vm.comment_title==='clear'){
                uploadFiles();
            }
            else {
                if (vm.option==='Above'){
                    Above_ground.update(vm.result, onSaveSuccess, onSaveError);
                }else if(vm.option==='Below'){
                    if (vm.comment_title==='clear'){}
                    Below_ground.update(vm.result, onSaveSuccess, onSaveError);
                }
            }

        }

        function onSaveSuccess (result) {
            vm.tower_log.tower_id= vm.id;
            vm.tower_log.action= vm.comment_title;
            // vm.tower_log.action_by=vm.username;
            Tower_log.save(vm.tower_log,onLogSaveSuccess);

            vm.isSaving = false;
        }
        function onLogSaveSuccess (result) {
            UIkit.modal('#modal5').hide();
            vm.tower_log.comment =null;
            $state.reload();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function action(i,object,option) {
            vm.applicant_id= object.id;
            vm.name_applicant= object.name_applicant;

            var result=angular.copy(object);
            vm.option=option;
            if(i===1){
                vm.comment_title= "clear";
                result.status= 'Cleared';
            }
            else if(i===2) {
                vm.comment_title= "comment";
            }
            else if(i===3){
                vm.comment_title= "reject";
                result.status = 'Rejected';
            }
            vm.result= result;
        }

        function detail(i,id) {
            if(i===1){
               vm.above_ground = Above_ground.get({id : id});
            }
            else if(i===2){
               vm.below_ground = Below_ground.get({id : id});
            }
        }

        function download(serverfilename,clientfilename) {
            console.log(serverfilename);
            downloadserviceformattachment.download(serverfilename,clientfilename);
        }

        function view_log(id) {
            console.log(id);
           Tower_log.query({towerId: id},function (result){
               vm.towerLog=result;
               console.log(result);
            });
        }

        function uploadFiles() {
            var fileNameArray = vm.clearancefile1.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var filename = vm.result.id+'_'+ 'clearancefile1';
            vm.result.clearancefile1=filename+ "." + fileextension;
            var file =vm.clearancefile1;
            FileManagement.saveFile(file,filename,onFile1UploadSuccess,onFile1UploadError);
        }

        function onFile1UploadSuccess() {
           UIkit.notification("File Uploaded Successfully . . .",{timeout: 2000});
            var fileNameArray = vm.clearancefile2.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var filename = vm.result.id+'_'+ 'clearancefile2';
            vm.result.clearancefile2=filename+ "." + fileextension;
            var file =vm.clearancefile2;
            FileManagement.saveFile(file,filename,onFile2UploadSuccess,onFile2UploadError);

        }
        function onFile2UploadSuccess() {
           UIkit.notification("File Uploaded Successfully . . .",{timeout: 2000});
            var fileNameArray = vm.clearancefile3.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var filename = vm.result.id+'_'+ 'clearancefile3';
            vm.result.clearancefile3=filename+ "." + fileextension;
            var file =vm.clearancefile3;
            FileManagement.saveFile(file,filename,onFile3UploadSuccess,onFile3UploadError);

        }
        function onFile3UploadSuccess() {
           UIkit.notification("File Uploaded Successfully . . .",{timeout: 2000});
            if (vm.option==='Above'){
                Above_ground.update(vm.result, onSaveSuccess, onSaveError);
                console.log('updated above');
            }else if(vm.option==='Below'){
                Below_ground.update(vm.result, onSaveSuccess, onSaveError);
                console.log('updated below');
            }
        }
        function onFile1UploadError() {
            UIkit.notification("Unexpected Error Occurred! Try Again . .",{timeout: 2000,status: 'danger'});
        }

        function onFile2UploadError() {
            UIkit.notification("Unexpected Error Occurred! Try Again . .",{timeout: 2000,status: 'danger'});
        }

        function onFile3UploadError() {
            UIkit.notification("Unexpected Error Occurred! Try Again . .",{timeout: 2000,status: 'danger'});
        }

        function forward(object,option,role) {
            vm.applicant_id= object.id;
            vm.name_applicant= object.name_applicant;

            var result=angular.copy(object);
            vm.option=option;

            if(role==='DC'){
                vm.comment_title= "Forward ( TO DIO )";
               result.isforwardedtodio=true;
            }
            else if(role==='DIO'){
                vm.comment_title= "Forward ( TO DC )";
                result.isforwardedtodept=true;
            }
            else if(role==='DEPT'){
                vm.comment_title= "Forward ( TO DEPT )";
                result.isforwardedtodc=true;
            }
            vm.result= result;
        }

        // function get_TowerRequests() {
        //     TowerInstallationService.query({type: 'Above', filter: 'new'},function(result){
        //         vm.towerRequests= result;
        //         console.log(result);
        //     });
        // }

        function show_type(towertype) {
           vm.towertype=towertype;
           loadAll(vm.towertype,vm.status,vm.requesttype);
        }

        function show_request(requesttype){
            vm.requesttype= requesttype;
            loadAll(vm.towertype,vm.status,vm.requesttype);
        }

        function show_status(status) {
            vm.status=status;
            loadAll(vm.towertype,vm.status,vm.requesttype);
        }

        function disableSave() {
            return (!angular.isDefined(vm.clearancefile3)) || (!angular.isDefined(vm.clearancefile2)) || (!angular.isDefined(vm.clearancefile1));
        }


    }
})();
