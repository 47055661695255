(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('ticket-generate', {
            parent: 'hepcticket',
            url: '/ticket-generate',
            data: {
                authorities: ["ROLE_L1_SUPPORT","ROLE_TICKET_OPERATION_MANAGER","ROLE_TICKET_HOBPAS"]
            },
            views: {
                'content_section@hepcticket': {
                    templateUrl: 'app/hepcTickets/ticket-generate/ticket-generate.html',
                    controller: 'TicketGenerateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('state');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
