(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('LandrequirementcafDeleteController',LandrequirementcafDeleteController);

    LandrequirementcafDeleteController.$inject = ['$uibModalInstance', 'entity', 'Landrequirementcaf'];

    function LandrequirementcafDeleteController($uibModalInstance, entity, Landrequirementcaf) {
        var vm = this;

        vm.landrequirementcaf = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Landrequirementcaf.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
