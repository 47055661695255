(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('viewprojectdetailController', addprojectController);

    addprojectController.$inject = ['$scope', '$stateParams', 'Principal', 'LoginService', '$state','Projectlist', 'Projectbycafpin'];

    function addprojectController ($scope, $stateParams, Principal, LoginService, $state,Projectlist, Projectbycafpin) {
        var vm = this;

        // $scope.currentPage = 1;
        // $scope.pageSize = 100;
        // vm.selectedValue = "100";
        vm.projectlist = [];
        vm.allprojectlist = [];
        vm.loadAll=loadAll;
        vm.findByCafpin=findByCafpin;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        vm.page = 0;
        loadAll(vm.page);
        function loadAll(page) {
            vm.page = page;
            Projectlist.get({
                id:"1",status:$stateParams.status,
                page: page,
                size: 100
            },function(result) {
                vm.projectlist = result;
                vm.allprojectlist = vm.projectlist.content;
            });
        }

        function findByCafpin(searchText){
            vm.cafpin = searchText;
            Projectbycafpin.query({
                cafpin:vm.cafpin,
            },function(result) {
                vm.allprojectlist = result;
                 // vm.allprojectlist = vm.projectlist.content;
            });
        }

        // $scope.setPageSize = function (pageSizeVal) {
        //     $scope.pageSize = pageSizeVal;
        // }
        //
        // $scope.pageChangedHandler = function(num) {
        //     loadAll();
        // };
    }


})();
