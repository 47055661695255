(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('reApplyDashboard', {
            parent: 'app',
            url: '/reApplyDashboard',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/reApplyDashboard/reApplyDashboard.html',
                    controller: 'ReAppliedDashboardController',
                    controllerAs: 'vm'
                }
            }
        }).state('reApplyDashboard-view', {
            parent: 'reApplyDashboard',
            url: '/view/{serviceid}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/reApplyDashboard/reApplyDashboard-view.html',
                    controller: 'ReApplyDashboardView',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                }).result.then(function() {
                    $state.go('reApplyDashboard', null, { reload: 'reApplyDashboard' });
                }, function() {
                    $state.go('reApplyDashboard');
                });
            }]
        });
    }
})();
