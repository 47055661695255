(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
         .state('towerServiceCAF', {
          parent: 'app',
          url: '/towerServiceCAF/{status}',
          data: {
              authorities: ['ROLE_ADMIN','ROLE_TOWER_MONITORING_OFFICER']
          },
             views: {
                 'content@': {
                     templateUrl: 'app/towerCafServices/towerCafsServices.html',
                     controller: 'TowerCAFServicesController',
                     controllerAs: 'vm'
                 }
             },
             resolve: {
                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                     $translatePartialLoader.addPart('state');
                     return $translate.refresh();
                 }]
             }
      })
    .state('serviceLogTower', {
            parent: 'towerServiceCAF',
            url: '/{projectserviceid}/{projectid}/{serviceid}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_TOWER_MONITORING_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/serviceclearanceactions/serviceLog.html',
                    controller: 'serviceLogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }],
                        serviceLogList: ['GetServiceLog', function(GetServiceLog) {
                            return GetServiceLog.query({projectid:$stateParams.projectid,serviceid:$stateParams.serviceid});
                        }],
                        service: ['DepartmentService', function(DepartmentService) {
                            return DepartmentService.get({id : $stateParams.serviceid}).$promise;
                        }],
                        projectService: ['Projectservicedetail', function(Projectservicedetail) {
                            return Projectservicedetail.get({id : $stateParams.projectserviceid}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('towerServiceCAF', null, { reload: 'towerServiceCAF' });
                }, function() {
                    $state.go('towerServiceCAF');
                });
            }]
        });
    }
})();
