(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ShopULbDepartmentLogsController', ShopULbDepartmentLogsController);

    ShopULbDepartmentLogsController.$inject = ['ShopULbDepartmentLogs'];

    function ShopULbDepartmentLogsController(ShopULbDepartmentLogs) {

        var vm = this;

        vm.shopULbDepartmentLogs = [];

        loadAll();

        function loadAll() {
            ShopULbDepartmentLogs.query(function(result) {
                vm.shopULbDepartmentLogs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
