(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MinutesofmeetingDetailController', MinutesofmeetingDetailController);

    MinutesofmeetingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Minutesofmeeting'];

    function MinutesofmeetingDetailController($scope, $rootScope, $stateParams, previousState, entity, Minutesofmeeting) {
        var vm = this;

        vm.minutesofmeeting = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:minutesofmeetingUpdate', function(event, result) {
            vm.minutesofmeeting = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
