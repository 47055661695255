(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerdiolistDetailController', TowerdiolistDetailController);

    TowerdiolistDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Towerdiolist'];

    function TowerdiolistDetailController($scope, $rootScope, $stateParams, previousState, entity, Towerdiolist) {
        var vm = this;

        vm.towerdiolist = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:towerdiolistUpdate', function(event, result) {
            vm.towerdiolist = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
