(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('viewpayments', {
            parent: 'app',
            url: '/viewpayments/{disbursed}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_ACCOUNT_OFFICER','ROLE_DESK_OFFICER','ROLE_HEPC_MONITORING_OFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/payments/viewpayments.html',
                    controller: 'viewpaymentsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('disbursePayment', {
            parent: 'viewpayments',
            url: '/disbursePayment/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_ACCOUNT_OFFICER','ROLE_HEPC_MONITORING_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/payments/disbursePayment.html',
                    controller: 'disbursePaymentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        projectServiceLog: function () {
                              return {
                                  projectid: null,
                                  serviceid: null,
                                  comments: null,
                                  commentDate: null,
                                  commentByUserLogin: null,
                                  actionTaken: null,
                                  id: null
                              };
                        },
                        entity: ['ProjectServicePaymentDetails', function(ProjectServicePaymentDetails) {
                            return ProjectServicePaymentDetails.get({id:$stateParams.id}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('home');
                            return $translate.refresh();
                        }]

                    }
                }).result.then(function() {
                    $state.go('viewpayments', null, { reload: 'viewpayments' });
                }, function() {
                    $state.go('viewpayments');
                });
            }]
        });
    }
})();
