(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ViewServiceStatusController', ViewServiceStatusController);

    ViewServiceStatusController.$inject = ['$scope', 'Principal','$filter', 'LoginService', '$state', 'ViewServiceStatus','ServiceStatus','Department','DepartmentService','ServiceLevel'];

    function ViewServiceStatusController ($scope, Principal,$filter , LoginService, $state, ViewServiceStatus , ServiceStatus ,Department ,DepartmentService, ServiceLevel) {
        var vm = this;
        vm.loadAll=loadAll();

        vm.service = false;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.getProject = getProject;
        vm.serviceStatus=[];
        vm.showtable=false;
        vm.departments = [];
        vm.selectedObj={};
        vm.departmentservices = [];
        vm.serviceStatusByService = [];
        vm.showRecord = false;
        vm.findservice = findservice;
        vm.deptservice = deptservice;
        vm.projectbyservice = projectbyservice;
        vm.projectbycafPin = projectbycafPin;
        var isCafSearch = true;

        vm.serviceLevels = [];

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        vm.more = function (index, serviceId) {
            $scope.activeParentIndex = index;
            if (serviceId != null){
                vm.serviceLevelsByServiceId = $filter('filter')(vm.serviceLevels,{serviceId: serviceId});
                vm.serviceLevelsSelected = [];
                vm.serviceLevelsByServiceId.forEach(function (servicelevel) {
                    vm.serviceLevelsSelected[servicelevel.level] = servicelevel.levelDescription;
                });
            }

        };

        $scope.isShowing = function(index){
            return  $scope.activeParentIndex === index;
        };


        function deptservice() {

            vm.service = true;
        }
        function projectbycafPin() {

            vm.service = false;
        }
        function loadAll() {
            Department.query(function(result) {
                angular.forEach(result, function(value, key){
                    if(( value.departmentname === 'LABOUR DEPARTMENT - LABOUR WING' || value.departmentname === 'DAKSHIN HARYANA BIJLI VITRAN NIGAM' || value.departmentname === 'Haryana Shehri Vikas Pradhikaran'
                        || value.departmentname === 'TOWN & COUNTRY PLANNING' || value.departmentname === 'HARYANA STATE POLLUTION CONTROL BOARD' || value.departmentname ==='UTTAR HARYANA BIJLI VITRAN NIGAM' || value.departmentname === 'URBAN LOCAL BODIES'
                        || value.departmentname === 'DEPARTMENT OF INDUSTRIES & COMMERCE' || value.departmentname === 'PUBLIC HEALTH ENGINEERING DEPARTMENT' || value.departmentname === 'HARYANA STATE INDUSTRIAL & INFRASTRUCTURE DEVELOPMENT CORPORATION LTD'
                        || value.departmentname === 'LABOUR DEPARTMENT - FACTORY WING' || value.departmentname === 'PWD (B & R) DEPARTMENT'
                        || value.departmentname === 'Food and Drug Administration' || value.departmentname === 'FOOD & SUPPLIES DEPARTMENT'
                        || value.departmentname === 'DEPARTMENT OF FORESTS & WILDLIFE')){
                        vm.departments.push(value);
                    }
                });

                vm.searchQuery = null;
            });
            ServiceLevel.query(function (serviceLevels) {
                vm.serviceLevels = serviceLevels;
            });

        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });

        }
        function register () {
            $state.go('register');
        }
        function getProject()
        {
            ViewServiceStatus.query({id:"1",cafPin:vm.cafPin},function(result){
                vm.serviceStatus = result;
                if(vm.serviceStatus.length==0) {
                    vm.showtable=false;
                } else {
                    vm.showtable=true;
                }
            });
        }

        function findservice(){
            DepartmentService.query({id:'1', departmentid:vm.deptName},function(result) {
                vm.departmentservices = $filter('filter')(result,{active:true});
                vm.searchQuery = null;
            });

        }

        function projectbyservice()
        {
            ViewServiceStatus.query({serviceId:vm.serviceId},function(result){
                vm.serviceStatusByService = result;
                isCafSearch = false;
            });
        }

    }
})();
