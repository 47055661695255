(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('addCAFlandreq', {
            parent: 'app',
            url: '/addCAFlandreq',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFlandreq.html',
                    controller: 'AddCAFlandreqController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: function () {
                     return {
                         id: null,
                         firstname: null,
                         middlename: null,
                         lastname: null,
                         emailprimary: null,
                         emailsecondary: null,
                         phonenumber: null,
                         mobilenumber: null,
                         businessentity: null,
                         district: null,
                         totalproposedprojectarea: "0",
                         total_project_cost: "0",
                         totalpurposedemployment: 0,

                         cafpin: null,
                         isdeactivated: false,

                         landrequired: null,
                         plotsize: null,
                         agencyname: null,
                         estatename: null,
                         purchaserate: null,
                         village: null,
                         tehsil: null,
                         landrequirementindistrict: null,
                         landusetype: null,

                         landzoneuse_type: "Commercial",
                         clurequired: false,

                         acceptance: null,
                         place: null,
                         isdraft: false,
                         caftype: "LandRequirement",

                         mouapplicable: false,
                         multyvillageinvolved: false,
                         falls_in_aravalli: false,
                         islandprocured: false,
                         allotedbyhsiidc: false,
                         liesunder_mc: false,
                         islocated_in_urban: false,
                         certifiedownership: false,
                         leaseapplicable: false,
                         landagreementapplicable: false,
                         intersectiondistance: false,
                         railwaydistance: false,
                         confirmitylanduse: false,
                         existing_building_applicable: false,
                         site_situated_in_controlled_area: false,
                         isfdi: false,
                         existing_regulatory_approval: false,
                         edc_sif_clu_fee_paid_applicable: false,
                         foreign_investment: "0",
                         nri_investment: "0",
                         projectlevel: "d",
                         powerRequirement: null
                     };
                },
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                }
        }
        })

        .state('editlandreqCAF', {
            parent: 'app',
            url: '/addCAFlandreq/edit/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFlandreq.html',
                    controller: 'AddCAFlandreqController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: ['$stateParams', 'Project', function($stateParams,Project) {
                        return Project.get({id : $stateParams.id}).$promise;
                }],
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                    return CAFAttachment.query({id : "1",projectid : $stateParams.id}).$promise;
                }]
            }
        })

        .state('caftype', {
            parent: 'app',
            url: '/caftype',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/addCAF/caftype.html',
                    controller: 'CAFTypeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('addCAFshop', {
            parent: 'app',
            url: '/addCAFshop',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFshop.html',
                    controller: 'AddCAFshopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: function () {
                     return {
                         id: null,
                         firstname: null,
                         middlename: null,
                         lastname: null,
                         emailprimary: null,
                         emailsecondary: null,
                         phonenumber: null,
                         mobilenumber: null,
                         businessentity: null,
                         district: null,
                         total_project_cost: "0",
                         totalpurposedemployment: 0,
                         cafpin: null,
                         isdeactivated: false,
                         landzoneuse_type: "Commercial",
                         clurequired: false,
                         acceptance: null,
                         place: null,
                         isdraft: false,
                         caftype: "Shop",

                         totalproposedprojectarea: "0",
                         mouapplicable: false,
                         multyvillageinvolved: false,
                         falls_in_aravalli: false,
                         islandprocured: false,
                         allotedbyhsiidc: false,
                         liesunder_mc: false,
                         islocated_in_urban: false,
                         certifiedownership: false,
                         leaseapplicable: false,
                         landagreementapplicable: false,
                         intersectiondistance: false,
                         railwaydistance: false,
                         confirmitylanduse: false,
                         existing_building_applicable: false,
                         site_situated_in_controlled_area: false,
                         isfdi: false,
                         existing_regulatory_approval: false,
                         edc_sif_clu_fee_paid_applicable: false,
                         foreign_investment: "0",
                         nri_investment: "0",
                         projectlevel: "d"
                     };
                },
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                }
            }
        })
            .state('addCAFexcise', {
                parent: 'app',
                url: '/addCAFexcise',
                data: {
                    authorities: ['ROLE_USER','ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/addCAF/addCAFexcise.html',
                        controller: 'AddCAFexciseController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('addproject');
                        return $translate.refresh();
                    }],
                    project: function () {
                        return {
                            id: null,
                            firstname: null,
                            middlename: null,
                            lastname: null,
                            emailprimary: null,
                            emailsecondary: null,
                            phonenumber: null,
                            mobilenumber: null,
                            businessentity: null,
                            district: null,
                            total_project_cost: "0",
                            totalpurposedemployment: 0,
                            cafpin: null,
                            isdeactivated: false,
                            landzoneuse_type: "Commercial",
                            clurequired: false,
                            acceptance: null,
                            place: null,
                            isdraft: false,
                            caftype: "Excise",

                            totalproposedprojectarea: "0",
                            mouapplicable: false,
                            multyvillageinvolved: false,
                            falls_in_aravalli: false,
                            islandprocured: false,
                            allotedbyhsiidc: false,
                            liesunder_mc: false,
                            islocated_in_urban: false,
                            certifiedownership: false,
                            leaseapplicable: false,
                            landagreementapplicable: false,
                            intersectiondistance: false,
                            railwaydistance: false,
                            confirmitylanduse: false,
                            existing_building_applicable: false,
                            site_situated_in_controlled_area: false,
                            isfdi: false,
                            existing_regulatory_approval: false,
                            edc_sif_clu_fee_paid_applicable: false,
                            foreign_investment: "0",
                            nri_investment: "0",
                            projectlevel: "d"
                        };
                    },
                    cAFAttachment: function () {
                        return {
                            projectid: null,
                            docName: null,
                            fileName: null,
                            id: null
                        };
                    },
                    cafAttachments: function () {
                        return {
                            projectid: null,
                            docName: null,
                            fileName: null,
                            id: null
                        };
                    }
                }
            })

        .state('editshopCAF', {
            parent: 'app',
            url: '/addCAFshop/edit/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFshop.html',
                    controller: 'AddCAFshopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: ['$stateParams', 'Project', function($stateParams,Project) {
                        return Project.get({id : $stateParams.id}).$promise;
                }],
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                    return CAFAttachment.query({id : "1",projectid : $stateParams.id}).$promise;
                }]
            }
        })

        .state('addCAFrenewable', {
            parent: 'app',
            url: '/addCAFrenewable',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFrenewable.html',
                    controller: 'AddCAFrenewableController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: function () {
                     return {
                         id: null,
                         firstname: null,
                         middlename: null,
                         lastname: null,
                         emailprimary: null,
                         emailsecondary: null,
                         phonenumber: null,
                         mobilenumber: null,
                         district: null,
                         cafpin: null,
                         isdeactivated: false,
                         landzoneuse_type: "Industrial",
                         clurequired: false,
                         acceptance: null,
                         place: null,
                         isdraft: false,
                         caftype: "Renewable",
                         businessentity: null,

                         totalpurposedemployment: 0,
                         totalproposedprojectarea: "0",
                         total_project_cost: "0",
                         mouapplicable: false,
                         multyvillageinvolved: false,
                         falls_in_aravalli: false,
                         islandprocured: false,
                         allotedbyhsiidc: false,
                         liesunder_mc: false,
                         islocated_in_urban: false,
                         certifiedownership: false,
                         leaseapplicable: false,
                         landagreementapplicable: false,
                         intersectiondistance: false,
                         railwaydistance: false,
                         confirmitylanduse: false,
                         existing_building_applicable: false,
                         site_situated_in_controlled_area: false,
                         isfdi: false,
                         existing_regulatory_approval: false,
                         edc_sif_clu_fee_paid_applicable: false,
                         foreign_investment: "0",
                         nri_investment: "0",
                         projectlevel: "d"
                     };
                },
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                }
            }
        })

        .state('editrenewableCAF', {
            parent: 'app',
            url: '/addCAFrenewable/edit/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFrenewable.html',
                    controller: 'AddCAFrenewableController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: ['$stateParams', 'Project', function($stateParams,Project) {
                        return Project.get({id : $stateParams.id}).$promise;
                }],
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                    return CAFAttachment.query({id : "1",projectid : $stateParams.id}).$promise;
                }]
            }
        })

        .state('addCAFnew', {
            parent: 'app',
            url: '/addCAFnew',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFnew.html',
                    controller: 'AddCAFnewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: function () {
                     return {
                         id: null,
                         mouapplicable: false,
                         mousignyear: null,
                         mouidnumber: null,
                         firstname: null,
                         middlename: null,
                         lastname: null,
                         address1: null,
                         address2: null,
                         address3: null,
                         emailprimary: null,
                         emailsecondary: null,
                         userlogin: null,
                         cityname: null,
                         countryname: null,
                         statename: null,
                         createdate: null,
                         updatedate: null,
                         cafpin: null,
                         pincode: null,
                         phonenumber: null,
                         mobilenumber: null,
                         promoter_md_director: null,
                         designation: null,
                         businessentity: null,
                         director_promoter_md_ceo_number: null,
                         pan_number: null,
                         aadhar_number: null,
                         nri: null,
                         tin_vat_number: null,
                         cst_number: null,
                         businessentitytype: null,
                         gstnumber: null,
                         siteaddress: null,
                         multyvillageinvolved: false,
                         villageinvolved: null,
                         falls_in_aravalli: false,
                         islandprocured: false,
                         allotedbyhsiidc: false,
                         estate: null,
                         cluster: null,
                         phase: null,
                         sector: null,
                         plotno: null,
                         hadbastno: null,
                         liesunder_mc: false,
                         distance_from_mc: null,
                         islocated_in_urban: false,
                         totalproposedprojectarea: "0",
                         proposedbuilt_up_area: null,
                         certifiedownership: false,
                         leaseapplicable: false,
                         landagreementapplicable: false,
                         intersectiondistance: false,
                         railwaydistance: false,
                         confirmitylanduse: false,
                         existing_building_applicable: false,
                         site_situated_in_controlled_area: false,
                         buildingexisted: null,
                         district: null,
                         block: null,
                         city_town_village: null,
                         connectingroad: null,
                         landzoneuse_type: "",
                         tehsil_subtehsil: null,
                         clurequired: false,
                         land_cost: null,
                         building_cost: null,
                         total_project_cost: "0",
                         totalpurposedemployment: 0,
                         acceptance: false,
                         applicationdate: null,
                         place: null,
                         projectpurpose: null,
                         niccode: null,
                         existing_regulatory_approval: false,
                         edc_sif_clu_fee_paid_applicable: false,
                         approval_application_form: null,
                         category_of_project: null,
                         collaboration_with_foreign_country: null,
                         projectype: null,
                         sectorname: null,
                         size_of_industry: null,
                         sectorother: null,
                         isdeactivated: false,
                         deactivatedby: null,
                         deactivateddate: null,
                         projectlevel: "d",
                         isdraft: false,
                         caftype: "New",
                         landrequired: null,
                         plotsize: null,
                         landusetype: null,
                         product: null,
                         uamnumber: null,
                         isfdi: false,
                         foreign_investment: 0,
                         nri_investment: "0",
                         investmentType: null,
                         otherInvestmetType: null,
                         prodctionStartDate: null,
                         otherFDI: null,
                         haryanadomicile: 0,
                         nonharyanadomicile: 0
                     };
                },
                cAFAttachmentPhotoobject: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cAFAttachmentSignobject: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                }
            }
        })

        .state('editnewCAF', {
            parent: 'app',
            url: '/addCAFnew/edit/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFnew.html',
                    controller: 'AddCAFnewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: ['$stateParams', 'Project', function($stateParams,Project) {
                    return Project.get({id : $stateParams.id}).$promise;
                }],
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                        return CAFAttachment.query({id : "1",projectid : $stateParams.id}).$promise;
                }],
                cAFAttachmentPhotoobject: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cAFAttachmentSignobject: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                }
            }
        })

        .state('addCAFnewRestricted', {
                parent: 'app',
                url: '/addCAFnew/restricted/{id}',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/addCAF/addCAFnewRestricted.html',
                        controller: 'AddCAFnewRestrictedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('addproject');
                        return $translate.refresh();
                    }],
                    project: ['$stateParams', 'Project', function($stateParams,Project) {
                        var project = Project.get({id : $stateParams.id}).$promise;
                        return {
                            id: null,
                            mouapplicable: false,
                            mousignyear: null,
                            mouidnumber: null,
                            firstname: null,
                            middlename: null,
                            lastname: null,
                            address1: null,
                            address2: null,
                            address3: null,
                            emailprimary: null,
                            emailsecondary: null,
                            userlogin: null,
                            cityname: null,
                            countryname: null,
                            statename: null,
                            createdate: null,
                            updatedate: null,
                            cafpin: null,
                            pincode: null,
                            phonenumber: null,
                            mobilenumber: null,
                            promoter_md_director: null,
                            designation: null,
                            businessentity: null,
                            director_promoter_md_ceo_number: null,
                            pan_number: null,
                            aadhar_number: null,
                            nri: null,
                            tin_vat_number: null,
                            cst_number: null,
                            businessentitytype: null,
                            gstnumber: null,
                            siteaddress: null,
                            multyvillageinvolved: false,
                            villageinvolved: null,
                            falls_in_aravalli: false,
                            islandprocured: false,
                            allotedbyhsiidc: false,
                            estate: null,
                            cluster: null,
                            phase: null,
                            sector: null,
                            plotno: null,
                            hadbastno: null,
                            liesunder_mc: false,
                            distance_from_mc: null,
                            islocated_in_urban: false,
                            totalproposedprojectarea: "0",
                            proposedbuilt_up_area: null,
                            certifiedownership: false,
                            leaseapplicable: false,
                            landagreementapplicable: false,
                            intersectiondistance: false,
                            railwaydistance: false,
                            confirmitylanduse: false,
                            existing_building_applicable: false,
                            site_situated_in_controlled_area: false,
                            buildingexisted: null,
                            district: null,
                            block: null,
                            city_town_village: null,
                            connectingroad: null,
                            landzoneuse_type: "",
                            tehsil_subtehsil: null,
                            clurequired: false,
                            land_cost: null,
                            building_cost: null,
                            total_project_cost: "0",
                            totalpurposedemployment: 0,
                            acceptance: false,
                            applicationdate: null,
                            place: null,
                            projectpurpose: null,
                            niccode: null,
                            existing_regulatory_approval: false,
                            edc_sif_clu_fee_paid_applicable: false,
                            approval_application_form: null,
                            category_of_project: null,
                            collaboration_with_foreign_country: null,
                            projectype: null,
                            sectorname: null,
                            size_of_industry: null,
                            sectorother: null,
                            isdeactivated: false,
                            deactivatedby: null,
                            deactivateddate: null,
                            projectlevel: "d",
                            isdraft: false,
                            caftype: "New",
                            landrequired: null,
                            plotsize: null,
                            landusetype: null,
                            product: null,
                            uamnumber: null,
                            isfdi: false,
                            foreign_investment: 0,
                            nri_investment: "0",
                            investmentType: null,
                            otherInvestmetType: null,
                            prodctionStartDate: null,
                            otherFDI: null,
                            haryanadomicile: 0,
                            nonharyanadomicile: 0
                        };
                    }],
                    cAFAttachmentPhotoobject: function () {
                        return {
                            projectid: null,
                            docName: null,
                            fileName: null,
                            id: null
                        };
                    },
                    cAFAttachmentSignobject: function () {
                        return {
                            projectid: null,
                            docName: null,
                            fileName: null,
                            id: null
                        };
                    },
                    cAFAttachment: function () {
                        return {
                            projectid: null,
                            docName: null,
                            fileName: null,
                            id: null
                        };
                    },
                    cafAttachments: function () {
                        return {
                            projectid: null,
                            docName: null,
                            fileName: null,
                            id: null
                        };
                    }
                }
        })

        .state('caftypeNewEnterprise', {
            parent: 'caftype',
            url: '/NewEnterpriseservies',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/addCAF/newcafservices.html',
                    controller: 'CAFTypeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('caftype', {}, { reload: false });
                }, function() {
                    $state.go('caftype');
                });
            }]
        })

        .state('caftypeShopEnterprise', {
            parent: 'caftype',
            url: '/ShopEnterpriseservies',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/addCAF/shopcafservices.html',
                    controller: 'CAFTypeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('caftype', {}, { reload: false });
                }, function() {
                    $state.go('caftype');
                });
            }]
        })
            .state('caftypeExciseEnterprise', {
                parent: 'caftype',
                url: '/ExciseEnterpriseservies',
                data: {
                    authorities: ['ROLE_USER','ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/addCAF/excisecafservices.html',
                        controller: 'CAFTypeController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                        }
                    }).result.then(function() {
                        $state.go('caftype', {}, { reload: false });
                    }, function() {
                        $state.go('caftype');
                    });
                }]
            })

         .state('caftypeLandExchange', {
            parent: 'caftype',
            url: '/landExchangecafservices',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/addCAF/landExchangecafservices.html',
                    controller: 'CAFTypeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('caftype', {}, { reload: false });
                }, function() {
                    $state.go('caftype');
                });
            }]
        })

        .state('caftypeRenewEnterprise', {
            parent: 'caftype',
            url: '/RenewEnterpriseservies',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/addCAF/renewcafservices.html',
                    controller: 'CAFTypeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('caftype', {}, { reload: false });
                }, function() {
                    $state.go('caftype');
                });
            }]
        })

        .state('commAndConnServices', {
                    parent: 'caftype',
                    url: '/commAndConnServices',
                    data: {
                        authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/addCAF/CommAndConnPermissions.html',
                            controller: 'CAFTypeController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                            }
                        }).result.then(function() {
                            $state.go('caftype', {}, { reload: false });
                        }, function() {
                            $state.go('caftype');
                        });
                    }]
                })
            // .state('rowServices', {
            //     parent: 'caftype',
            //     url: '/rowServices',
            //     data: {
            //         authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            //     },
            //     onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            //         $uibModal.open({
            //             templateUrl: 'app/addCAF/rowServices.html',
            //             controller: 'CAFTypeController',
            //             controllerAs: 'vm',
            //             backdrop: 'static',
            //             size: 'lg',
            //             resolve: {
            //             }
            //         }).result.then(function() {
            //             $state.go('caftype', {}, { reload: false });
            //         }, function() {
            //             $state.go('caftype');
            //         });
            //     }]
            // })
            .state('filmServices', {
                parent: 'caftype',
                url: '/filmServices',
                data: {
                    authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/addCAF/filmServices.html',
                        controller: 'CAFTypeController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                        }
                    }).result.then(function() {
                        $state.go('caftype', {}, { reload: false });
                    }, function() {
                        $state.go('caftype');
                    });
                }]
            })
            .state('landServices', {
                parent: 'caftype',
                url: '/landServices',
                data: {
                    authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/addCAF/landServices.html',
                        controller: 'CAFTypeController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                        }
                    }).result.then(function() {
                        $state.go('caftype', {}, { reload: false });
                    }, function() {
                        $state.go('caftype');
                    });
                }]
            })

        .state('addCAFtower', {
                    parent: 'app',
                    url: '/addCAFtower',
                    data: {
                        authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/addCAF/addCAFtower.html',
                            controller: 'AddCAFtowerController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('addproject');
                            return $translate.refresh();
                        }],
                        project: function () {
                             return {
                                 id: null,
                                 firstname: null,
                                 middlename: null,
                                 lastname: null,
                                 emailprimary: null,
                                 emailsecondary: null,
                                 phonenumber: null,
                                 mobilenumber: null,
                                 businessentity: null,
                                 district: null,
                                 total_project_cost: "0",
                                 totalpurposedemployment: 0,
                                 cafpin: null,
                                 isdeactivated: false,
                                 landzoneuse_type: "Commercial",
                                 clurequired: false,
                                 acceptance: null,
                                 place: null,
                                 isdraft: false,
                                 caftype: "Tower",

                                 totalproposedprojectarea: "0",
                                 mouapplicable: false,
                                 multyvillageinvolved: false,
                                 falls_in_aravalli: false,
                                 islandprocured: false,
                                 allotedbyhsiidc: false,
                                 liesunder_mc: false,
                                 islocated_in_urban: false,
                                 certifiedownership: false,
                                 leaseapplicable: false,
                                 landagreementapplicable: false,
                                 intersectiondistance: false,
                                 railwaydistance: false,
                                 confirmitylanduse: false,
                                 existing_building_applicable: false,
                                 site_situated_in_controlled_area: false,
                                 isfdi: false,
                                 existing_regulatory_approval: false,
                                 edc_sif_clu_fee_paid_applicable: false,
                                 foreign_investment: "0",
                                 nri_investment: "0",
                                 projectlevel: "d"
                             };
                        },
                        cAFAttachment: function () {
                            return {
                                projectid: null,
                                docName: null,
                                fileName: null,
                                id: null
                            };
                        },
                        cafAttachments: function () {
                            return {
                                projectid: null,
                                docName: null,
                                fileName: null,
                                id: null
                            };
                        }
                    }
                })

        .state('editTowerCAF', {
            parent: 'app',
            url: '/addCAFtower/edit/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFtower.html',
                    controller: 'AddCAFtowerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: ['$stateParams', 'Project', function($stateParams,Project) {
                        return Project.get({id : $stateParams.id}).$promise;
                }],
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                    return CAFAttachment.query({id : "1",projectid : $stateParams.id}).$promise;
                }]
            }
        })

        .state('addCAFrow', {
            parent: 'app',
            url: '/addCAFrow',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFrow.html',
                    controller: 'AddCAFrowController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: function () {
                    return {
                        id: null,
                        firstname: null,
                        middlename: null,
                        lastname: null,
                        emailprimary: null,
                        emailsecondary: null,
                        phonenumber: null,
                        mobilenumber: null,
                        businessentity: null,
                        district: null,
                        total_project_cost: "0",
                        totalpurposedemployment: 0,
                        cafpin: null,
                        isdeactivated: false,
                        landzoneuse_type: "NA",
                        clurequired: false,
                        acceptance: null,
                        place: null,
                        isdraft: false,
                        caftype: "ROW",

                        totalproposedprojectarea: "0",
                        mouapplicable: false,
                        multyvillageinvolved: false,
                        falls_in_aravalli: false,
                        islandprocured: false,
                        allotedbyhsiidc: false,
                        liesunder_mc: false,
                        islocated_in_urban: false,
                        certifiedownership: false,
                        leaseapplicable: false,
                        landagreementapplicable: false,
                        intersectiondistance: false,
                        railwaydistance: false,
                        confirmitylanduse: false,
                        existing_building_applicable: false,
                        site_situated_in_controlled_area: false,
                        isfdi: false,
                        existing_regulatory_approval: false,
                        edc_sif_clu_fee_paid_applicable: false,
                        foreign_investment: "0",
                        nri_investment: "0",
                        projectlevel: "d"
                    };
                },
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                }
            }
        })

        .state('editRowCAF', {
            parent: 'app',
            url: '/addCAFrow/edit/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFrow.html',
                    controller: 'AddCAFrowController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: ['$stateParams', 'Project', function($stateParams,Project) {
                    return Project.get({id : $stateParams.id}).$promise;
                }],
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                    return CAFAttachment.query({id : "1",projectid : $stateParams.id}).$promise;
                }]
            }
        })

        .state('editFilmCAF', {
            parent: 'app',
            url: '/editFilmCAF/edit/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFfilm.html',
                    controller: 'AddCAFfilmController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: ['$stateParams', 'Project', function($stateParams,Project) {
                        return Project.get({id : $stateParams.id}).$promise;
                }],
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                    return CAFAttachment.query({id : "1",projectid : $stateParams.id}).$promise;
                }]
            }
        })

            .state('editExciseCAF', {
                parent: 'app',
                url: '/editExciseCAF/edit/{id}',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/addCAF/addCAFexcise.html',
                        controller: 'AddCAFexciseController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('addproject');
                        return $translate.refresh();
                    }],
                    project: ['$stateParams', 'Project', function($stateParams,Project) {
                        return Project.get({id : $stateParams.id}).$promise;
                    }],
                    cAFAttachment: function () {
                        return {
                            projectid: null,
                            docName: null,
                            fileName: null,
                            id: null
                        };
                    },
                    cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                        return CAFAttachment.query({id : "1",projectid : $stateParams.id}).$promise;
                    }]
                }
            })

        .state('addCAFlandExchange', {
                parent: 'app',
                url: '/addCAFlandExchange',
                data: {
                    authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/addCAF/addCAFlandExchange.html',
                        controller: 'AddCAFlandExchangeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('addproject');
                        return $translate.refresh();
                    }],
                    project: function () {
                        return {
                            id: null,
                            firstname: null,
                            middlename: null,
                            lastname: null,
                            emailprimary: null,
                            emailsecondary: null,
                            phonenumber: null,
                            mobilenumber: null,
                            businessentity: null,
                            district: null,
                            total_project_cost: "0",
                            totalpurposedemployment: 0,
                            cafpin: null,
                            isdeactivated: false,
                            landzoneuse_type: "Commercial",
                            clurequired: false,
                            acceptance: null,
                            place: null,
                            isdraft: false,
                            caftype: "LandExchange",
                            block: "NA",

                            totalproposedprojectarea: "0",
                            mouapplicable: false,
                            multyvillageinvolved: false,
                            falls_in_aravalli: false,
                            islandprocured: false,
                            allotedbyhsiidc: false,
                            liesunder_mc: false,
                            islocated_in_urban: false,
                            certifiedownership: false,
                            leaseapplicable: false,
                            landagreementapplicable: false,
                            intersectiondistance: false,
                            railwaydistance: false,
                            confirmitylanduse: false,
                            existing_building_applicable: false,
                            site_situated_in_controlled_area: false,
                            isfdi: false,
                            existing_regulatory_approval: false,
                            edc_sif_clu_fee_paid_applicable: false,
                            foreign_investment: "0",
                            nri_investment: "0",
                            projectlevel: "d",
                            isulb: false,
                            mc: "NA"
                        };
                    },
                    cAFAttachment: function () {
                        return {
                            projectid: null,
                            docName: null,
                            fileName: null,
                            id: null
                        };
                    },
                    cafAttachments: function () {
                        return {
                            projectid: null,
                            docName: null,
                            fileName: null,
                            id: null
                        };
                    }
                }
            })

        .state('editLandExchangeCAF', {
            parent: 'app',
            url: '/addCAFlandExchange/edit/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFlandExchange.html',
                    controller: 'AddCAFlandExchangeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: ['$stateParams', 'Project', function($stateParams,Project) {
                    return Project.get({id : $stateParams.id}).$promise;
                }],
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                    return CAFAttachment.query({id : "1",projectid : $stateParams.id}).$promise;
                }]
            }
        })
        .state('addCAFfilm', {
            parent: 'app',
            url: '/addCAFfilm',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN','ROLE_SUB_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/addCAF/addCAFfilm.html',
                    controller: 'AddCAFfilmController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('addproject');
                    return $translate.refresh();
                }],
                project: function () {
                     return {
                         id: null,
                         firstname: null,
                         middlename: null,
                         lastname: null,
                         emailprimary: null,
                         emailsecondary: null,
                         phonenumber: null,
                         mobilenumber: null,
                         businessentity: null,
                         district: null,
                         total_project_cost: "0",
                         totalpurposedemployment: 0,
                         cafpin: null,
                         isdeactivated: false,
                         landzoneuse_type: "Commercial",
                         clurequired: false,
                         acceptance: null,
                         place: null,
                         isdraft: false,
                         caftype: "FILMCAF",

                         totalproposedprojectarea: "0",
                         mouapplicable: false,
                         multyvillageinvolved: false,
                         falls_in_aravalli: false,
                         islandprocured: false,
                         allotedbyhsiidc: false,
                         liesunder_mc: false,
                         islocated_in_urban: false,
                         certifiedownership: false,
                         leaseapplicable: false,
                         landagreementapplicable: false,
                         intersectiondistance: false,
                         railwaydistance: false,
                         confirmitylanduse: false,
                         existing_building_applicable: false,
                         site_situated_in_controlled_area: false,
                         isfdi: false,
                         existing_regulatory_approval: false,
                         edc_sif_clu_fee_paid_applicable: false,
                         foreign_investment: "0",
                         nri_investment: "0",
                         projectlevel: "d"
                     };
                },
                cAFAttachment: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                },
                cafAttachments: function () {
                    return {
                        projectid: null,
                        docName: null,
                        fileName: null,
                        id: null
                    };
                }
            }
        });
    }
})();
