(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('IncentiveBlockMappingController', IncentiveBlockMappingController);

    IncentiveBlockMappingController.$inject = ['IncentiveBlockMapping'];

    function IncentiveBlockMappingController(IncentiveBlockMapping) {

        var vm = this;

        vm.incentiveBlockMappings = [];

        loadAll();

        function loadAll() {
            IncentiveBlockMapping.query(function(result) {
                vm.incentiveBlockMappings = result;
                vm.searchQuery = null;
            });
        }
    }
})();
