(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcRecruitmentExperienceController', HepcRecruitmentExperienceController);

    HepcRecruitmentExperienceController.$inject = ['HepcRecruitmentExperience'];

    function HepcRecruitmentExperienceController(HepcRecruitmentExperience) {

        var vm = this;

        vm.hepcRecruitmentExperiences = [];

        loadAll();

        function loadAll() {
            HepcRecruitmentExperience.query(function(result) {
                vm.hepcRecruitmentExperiences = result;
                vm.searchQuery = null;
            });
        }
    }
})();
