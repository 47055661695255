(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('allpage1', {
            parent: 'allclearances',
            url: '/page1',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page1/page1.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage2', {
            parent: 'allclearances',
            url: '/page2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page2/page2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage3', {
            parent: 'allclearances',
            url: '/page3',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page3/page3.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage4', {
            parent: 'allclearances',
            url: '/page4',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page4/page4.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage5', {
            parent: 'allclearances',
            url: '/page5',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page5/page5.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage6', {
            parent: 'allclearances',
            url: '/page6',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page6/page6.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage7', {
            parent: 'allclearances',
            url: '/page7',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page7/page7.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage14', {
            parent: 'allclearances',
            url: '/page14',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page14/page14.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage16', {
            parent: 'allclearances',
            url: '/page16',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page16/page16.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage17', {
            parent: 'allclearances',
            url: '/page17',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page17/page17.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage21', {
            parent: 'allclearances',
            url: '/page21',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page21/page21.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage22', {
            parent: 'allclearances',
            url: '/page22',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page22/page22.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage23', {
            parent: 'allclearances',
            url: '/page23',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page23/page23.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage24', {
            parent: 'allclearances',
            url: '/page24',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page24/page24.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage25', {
            parent: 'allclearances',
            url: '/page25',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page25/page25.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage26', {
            parent: 'allclearances',
            url: '/page26',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page26/page26.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage56', {
            parent: 'allclearances',
            url: '/page56',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page56/page56.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage57', {
            parent: 'allclearances',
            url: '/page57',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page57/page57.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage58', {
            parent: 'allclearances',
            url: '/page58',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page58/page58.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allpage60', {
            parent: 'allclearances',
            url: '/page60',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/page60/page60.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable31', {
            parent: 'allclearances',
            url: '/table31',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table31/table31.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable32', {
            parent: 'allclearances',
            url: '/table32',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table32/table32.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable33', {
            parent: 'allclearances',
            url: '/table33',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table33/table33.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable34', {
            parent: 'allclearances',
            url: '/table34',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table34/table34.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable36', {
            parent: 'allclearances',
            url: '/table36',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table36/table36.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable37', {
            parent: 'allclearances',
            url: '/table37',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table37/table37.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable41', {
            parent: 'allclearances',
            url: '/table41',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table41/table41.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable42', {
            parent: 'allclearances',
            url: '/table42',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table42/table42.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable43', {
            parent: 'allclearances',
            url: '/table43',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table43/table43.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable44', {
            parent: 'allclearances',
            url: '/table44',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table44/table44.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable45', {
            parent: 'allclearances',
            url: '/table45',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table45/table45.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable46', {
            parent: 'allclearances',
            url: '/table46',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table46/table46.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable47', {
            parent: 'allclearances',
            url: '/table47',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table47/table47.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable48', {
            parent: 'allclearances',
            url: '/table48',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table48/table48.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable49', {
            parent: 'allclearances',
            url: '/table49',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table49/table49.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable52', {
            parent: 'allclearances',
            url: '/table52',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table52/table52.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable53', {
            parent: 'allclearances',
            url: '/table53',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table53/table53.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alltable54', {
            parent: 'allclearances',
            url: '/table54',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/table54/table54.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('alluhbvn2', {
            parent: 'allclearances',
            url: '/uhbvn2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/uhbvn2/uhbvn2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allhsiidc1', {
            parent: 'allclearances',
            url: '/hsiidc1',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc1.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allhsiidc2', {
            parent: 'allclearances',
            url: '/hsiidc2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allhsiidc3', {
            parent: 'allclearances',
            url: '/hsiidc3',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc3.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allhsiidc4', {
            parent: 'allclearances',
            url: '/hsiidc4',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc4.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allhsiidc5', {
            parent: 'allclearances',
            url: '/hsiidc5',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/hsiidc5.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allinds1', {
            parent: 'allclearances',
            url: '/inds1',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds1.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allinds2', {
            parent: 'allclearances',
            url: '/inds2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allinds3', {
            parent: 'allclearances',
            url: '/inds3',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds3.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allinds4', {
            parent: 'allclearances',
            url: '/inds4',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds4.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allinds5', {
            parent: 'allclearances',
            url: '/inds5',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds5.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allforest2', {
            parent: 'allclearances',
            url: '/forest2',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/forest2.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allfactlicense', {
            parent: 'allclearances',
            url: '/factlicense',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/factlicense.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })

        .state('allinds6', {
            parent: 'allclearances',
            url: '/inds6',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds6.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        .state('allinds7', {
            parent: 'allclearances',
            url: '/inds7',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire/table/inds7.html',
                    controller: '',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }
})();
