(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewsandgalleryDeleteController',NewsandgalleryDeleteController);

    NewsandgalleryDeleteController.$inject = ['$uibModalInstance', 'entity', 'Newsandgallery'];

    function NewsandgalleryDeleteController($uibModalInstance, entity, Newsandgallery) {
        var vm = this;

        vm.newsandgallery = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Newsandgallery.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
