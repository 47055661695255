(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('servicedashboardyearly', {
            parent: 'app',
            url: '/service-dashboard',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/servicedashboardyearly/servicedashboardyearly.html',
                    controller: 'DeptServiceDashboardYearlyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }]
            }
        }).state('servicedashboardyearlycafs', {
            parent: 'servicedashboardyearly',
            url: '/service-dashboard-yearly/{servicename}/{departmentName}/{district}/{projectLevel}/{serviceid}/{status}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/servicedashboardyearly/serviceviewcafsyearly.html',
                    controller: 'ServiceViewCafYearlyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('dashboard');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('servicedashboardyearly', null, { reload: 'servicedashboardyearly' });
                }, function() {
                    $state.go('servicedashboardyearly');
                });
            }]
        }).state('averagefee', {
            parent: 'servicedashboardyearly',
            url: '/averagefee-dashboard-yearly/{servicename}/{departmentName}/{district}/{projectLevel}/{serviceid}/{status}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/servicedashboardyearly/averagefeeyearly.html',
                    controller: 'AverageFeeYearlyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('dashboard');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('servicedashboardyearly', null, { reload: 'servicedashboardyearly' });
                }, function() {
                    $state.go('servicedashboardyearly');
                });
            }]
        });
    }
})();

