(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CafservicestatusController', CafservicestatusController);

    CafservicestatusController.$inject = ['Cafservicestatus', '$scope', '$filter', 'CafServiceStatusBetweenDate'];

    function CafservicestatusController (Cafservicestatus, $scope, $filter, CafServiceStatusBetweenDate) {
        var vm = this;
        vm.filterResult = filterResult;
        vm.cafservicestatus = [];
        vm.percentfilter = 0;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;
        vm.clearFilterButton = false;
        vm.selectedDate=selectedDate;

        vm.percentages=[{name:'100 %',value:100},{name:'>25 %',value:25},{name:'>50 %',value:50},{name:'>75 %',value:75},{name:'All ',value:0}];

        $scope.currentPage = 1;
        $scope.itemsPerPage = 1000;

        vm.changePage = function (index) {
            $scope.currentPage = index+1;
        };

        vm.openCalendar = openCalendar;
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.dateOptions = {
            minDate: new Date("01-01-2000"),
            maxDate: Date.now()
        };

        Cafservicestatus.query(function (result) {
            vm.cafservicestatus = result;
            filterResult(vm.percentfilter);
        });


        function selectedDate(dateSelected){
            var newDate = new Date(dateSelected);
            newDate.setDate(newDate.getDate()+181);
            if(newDate > Date.now()){
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: Date.now()
                };
            }
            else {
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: newDate
                };
            }
        }

        vm.getCafBetweenDates=function(){
            var f=$filter('date')(vm.fromdate, "yyyy-MM-dd");
            var t=$filter('date')(vm.todate, "yyyy-MM-dd");
            var fromdate=$filter('date')(vm.fromdate, "yyyy-MM-dd");
            var todate=$filter('date')(vm.todate, "yyyy-MM-dd");

            CafServiceStatusBetweenDate.query({fromdate:f,todate:t},onSuccess,onError);
            function onSuccess(result){
                // vm.cafservicestatus = result;
                vm.cafservicestatus=result.cafservicestatusData.content;
                console.log("@@@@@@@@", vm.cafservicestatus);
                filterResult(vm.percentfilter);
                vm.clearFilterButton = true;
            };
        }

        function filterResult (percentage) {
            vm.totalcompleted= -1;
            vm.filteredResult = [];
            angular.forEach(vm.cafservicestatus , function(value, key){
                if(value.totalapplied > 0 && value.totalpending === 0){
                    vm.totalcompleted+=1;
                }
                if(value.percentagecompleted >= percentage){
                    vm.filteredResult.push(value);
                }
            });
            vm.show = true;

            $scope.maxSizeArray = function() {
                $scope.maxPageRound = Math.round(vm.filteredResult.length/$scope.itemsPerPage);
                $scope.maxPageAbs = Math.abs(vm.filteredResult.length/$scope.itemsPerPage);
                if($scope.maxPageRound > $scope.maxPageAbs){
                    return new Array($scope.maxPageRound);
                }
                else {
                    return new Array($scope.maxPageRound + 1);
                }
            };
        }

        vm.clearFilter = function () {
            vm.fromdate = null;
            vm.todate = null;
            vm.clearFilterButton = false;
            Cafservicestatus.query(function (result) {
                vm.cafservicestatus = result;
                filterResult(vm.percentfilter);
            });        }

        function onError(){
            alert("Please enter a period between 6 months");
        }

    }
})();
