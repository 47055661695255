(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MunicipalPaymentParamsDialogController', MunicipalPaymentParamsDialogController);

    MunicipalPaymentParamsDialogController.$inject = ['$timeout', '$scope','$filter', '$stateParams', '$uibModalInstance', 'entity', 'MunicipalPaymentParams','District' ,'Department'];

    function MunicipalPaymentParamsDialogController ($timeout, $scope,$filter, $stateParams, $uibModalInstance, entity, MunicipalPaymentParams,District ,Department) {
        var vm = this;

        vm.municipalPaymentParams = entity;
        vm.clear = clear;
        vm.save = save;
        vm.districts = [];
        vm.departments= [];


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        loadAll();
        function loadAll(){
        District.query(function(result) {
            vm.districts = result;
            vm.searchQuery = null;
        });
            Department.query(function (result) {
                console.log(result);

                vm.departments = $filter('filter')(result,{istower : true});
                vm.searchQuery = null;
            });
    }


        function save () {
            vm.isSaving = true;
            if (vm.municipalPaymentParams.id !== null) {
                MunicipalPaymentParams.update(vm.municipalPaymentParams, onSaveSuccess, onSaveError);
            } else {
                MunicipalPaymentParams.save(vm.municipalPaymentParams, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:municipalPaymentParamsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
