(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DashboardbetweendatesController', DashboardbetweendatesController);

    DashboardbetweendatesController.$inject = ['Dashboardbetweendates','$filter','AlertService','DashboardbetweendatesCAFs'];

    function DashboardbetweendatesController (Dashboardbetweendates,$filter,AlertService,DashboardbetweendatesCAFs) {
        var vm = this;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.status=['Applied','Pending','Cleared','Rejected'];
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;
        vm.selectedDate=selectedDate;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        vm.dateOptions = {
            minDate: new Date("05-01-2017"),
            maxDate: Date.now()
        };

        function selectedDate(dateSelected){
            var newDate = new Date(dateSelected);
            newDate.setDate(newDate.getDate()+30);
            if(newDate > Date.now()){
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: Date.now()
                };
            }
            else {
                vm.dateOptions1= {
                    minDate: dateSelected,
                    maxDate: newDate
                };
            }
        }

        function loadAll() {
            vm.isloading=true;
            vm.dashboardsbydate=null;
            var f=$filter('date')(vm.fromdate, "yyyy-MM-dd");
            var t=$filter('date')(vm.todate, "yyyy-MM-dd");
            var fromdate=$filter('date')(vm.fromdate, "dd-MM-yyyy");
            var todate=$filter('date')(vm.todate, "dd-MM-yyyy");
            vm.toDate=todate;
            vm.fromDate=fromdate;
            Dashboardbetweendates.query({from:f,to:t,status:vm.selectedStatus},onSuccess,onError);
            console.log(vm.dashboardsbydate);

            function onSuccess(result) {
                vm.dashboardsbydate=result;
                console.log(vm.dashboardsbydate);
                for (var i=0;i<vm.dashboardsbydate.length;i++){
                    if (vm.dashboardsbydate[i].departmentName==='DAKSHIN HARYANA BIJLI VITRAN NIGAM'){
                        vm.dashboardsbydate[i].deptorder=1;

                    }
                    if (vm.dashboardsbydate[i].departmentName==='UTTAR HARYANA BIJLI VITRAN NIGAM'){
                        vm.dashboardsbydate[i].deptorder=2;

                    }
                    if (vm.dashboardsbydate[i].departmentName==='HARYANA URBAN DEVELOPMENT AUTHORITY'){
                        vm.dashboardsbydate[i].deptorder=3;

                    }
                    if (vm.dashboardsbydate[i].departmentName==='LABOUR DEPARTMENT - FACTORY WING'){
                        vm.dashboardsbydate[i].deptorder=10;

                    }
                    if (vm.dashboardsbydate[i].departmentName==='LABOUR DEPARTMENT - LABOUR WING'){
                        vm.dashboardsbydate[i].deptorder=11;

                    }
                    if (vm.dashboardsbydate[i].departmentName==='TOWN & COUNTRY PLANNING'){
                        vm.dashboardsbydate[i].deptorder=6;

                    }
                    if (vm.dashboardsbydate[i].departmentName==='HARYANA STATE POLLUTION CONTROL BOARD'){
                        vm.dashboardsbydate[i].deptorder=8;

                    }
                    if (vm.dashboardsbydate[i].departmentName==='URBAN LOCAL BODIES'){
                        vm.dashboardsbydate[i].deptorder=9;

                    }
                    if (vm.dashboardsbydate[i].departmentName==='DEPARTMENT OF INDUSTRIES & COMMERCE'){
                        vm.dashboardsbydate[i].deptorder=4;
                    }

                    if (vm.dashboardsbydate[i].departmentName==='HARYANA STATE INDUSTRIAL & INFRASTRUCTURE DEVELOPMENT CORPORATION LTD'){
                        vm.dashboardsbydate[i].deptorder=5;
                    }
                }
            vm.header=vm.selectedStatus;
            vm.isloading=false;
            };
        }

        function onError(){
            alert("Please enter a period between 31 days");
        }

        vm.getServicesBetweenDates=function () {
            /*if (vm.todate.getTime()< vm.fromdate.getTime()){
                AlertService.error('Invalid Dates');
                return;
            }*/
            loadAll();
        }

        vm.statusChanged=function () {
            vm.dashboardsbydate=null;
        }

    }
})();
