(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HomeProjectDialogController', HomeProjectDialogController);

    HomeProjectDialogController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','downloadserviceformattachment','GetServiceLog','ProjectAction','serviceFormFieldDataCollection','ServiceFormAttachments','HomeProjectServiceDetails','homeProjectService','projectService','projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLogUpdated','FileManagement','Projectservicedetail','ProjectServiceReportInfoForClearance'];

    function HomeProjectDialogController ($timeout, $scope, $stateParams, $uibModalInstance, downloadserviceformattachment, GetServiceLog, ProjectAction, serviceFormFieldDataCollection,ServiceFormAttachments, HomeProjectServiceDetails, homeProjectService,  projectService, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, FileManagement, Projectservicedetail,ProjectServiceReportInfoForClearance) {
        var vm = this;
        vm.projects=[];

        vm.status=$stateParams.action;
        vm.addComment=addComment;
        vm.rejectService=rejectService;
        vm.downloadFile=downloadFile;
        $scope.file = {};
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectService = projectService;
        vm.homeProjectService = homeProjectService;
        var projectAttachmentResultObject=null;
        var projectServiceLogid=null;
        $scope.file = {};
        vm.clear = clear;
        vm.clearService = clearService;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function clearService (){
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.actionTaken="Cleared";
            vm.projectServiceLog.comments="Cleared"
            if(angular.isDefined(vm.projectAttachemnt.file)){
                var fileNameArray = vm.projectAttachemnt.file.name.split(".");
                if ((fileNameArray.length == 2)) {
                    if(fileNameArray[0] != ""){
                        ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);

                    }
                    else {
                        alert("Invalid File Name");
                    }
                }
                else{
                    alert("Please select correct file format")
                }
            }
            else {
                ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);

            }
        }
        function onServiceLogSaveSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments=vm.projectServiceLog.comments;
            vm.projectService.latestComments=vm.projectServiceLog.comments;
            vm.projectService.status="Cleared";
            vm.projectService.clearedDate=result.commentDate;
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
        }

        function onUpdateProjectServiceSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceReportInfoUpdate', result);
            vm.homeProjectService.status = "Cleared";
            vm.homeProjectService.lastComments = "Cleared";
            HomeProjectServiceDetails.update(vm.homeProjectService,function () {
                // vm.projects.splice(index, 1);
            });
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }
        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }
        function saveProjectAttachment()
        {
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Clearance Attachment";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }
        function onSaveProjectAttachmentSuccess(result)
        {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }
        function onSaveProjectAttachmentError()
        {
            vm.isSaving = false;
        }
        function onUpdateProjectAttachmentSuccess(result)
        {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onUpdateProjectAttachmentError()
        {
            vm.isSaving = false;
        }

        loadProject();

        function loadProject() {
            vm.project=projectService;
            switch (vm.status){
                case 'service-logs':
                    viewlogs(vm.project);
                    break;
                case 'view-form':
                    loadFilledForm(vm.project);
                    break;
            }
        }

        function viewlogs(project) {
            vm.project=project;
            GetServiceLog.query({projectid:project.projectid,serviceid:project.serviceid},function (result) {
                vm.ServiceLog=result;
            });
        }

        function loadFilledForm(project) {
            vm.project=project;
            vm.serviceFormFieldData= serviceFormFieldDataCollection.query({serviceid:project.serviceid, projectid:project.projectid});
            vm.serviceformattachments=ServiceFormAttachments.query({id:"1",serviceid:project.serviceid, projectid:project.projectid});
        }

        function addComment() {
            ProjectAction.comment(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function rejectService() {
            ProjectAction.reject(vm.project,vm.projectAttachemnt,vm.comments,onRejectsuccess,onerror);
        }

        function onRejectsuccess(result) {
            vm.homeProjectService.status = "Rejected";
            vm.homeProjectService.lastComments = vm.projectServiceLog.comments;
            HomeProjectServiceDetails.update(vm.homeProjectService,function () {
                vm.projects.splice(index, 1);
            });
            $uibModalInstance.close();
        }

        function onsuccess(result) {
            $uibModalInstance.close();
        }

        function onerror() {

        }
        function downloadFile(serverfilename,downloadfilename)
        {
            downloadserviceformattachment.download(serverfilename,downloadfilename)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
                });
        }

        vm.pdfconverter= function  () {
            console.log("called");
            var element = document.getElementById('pdf_data');
            html2pdf(element,{
                margin: 4
            });
        };
    }
})();
