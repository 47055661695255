(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DashboardbetweendatescafDeleteController',DashboardbetweendatescafDeleteController);

    DashboardbetweendatescafDeleteController.$inject = ['$uibModalInstance', 'entity', 'Dashboardbetweendatescaf'];

    function DashboardbetweendatescafDeleteController($uibModalInstance, entity, Dashboardbetweendatescaf) {
        var vm = this;

        vm.dashboardbetweendatescaf = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Dashboardbetweendatescaf.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
