(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketsLogsDetailController', TicketsLogsDetailController);

    TicketsLogsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TicketsLogs'];

    function TicketsLogsDetailController($scope, $rootScope, $stateParams, previousState, entity, TicketsLogs) {
        var vm = this;

        vm.ticketsLogs = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:ticketsLogsUpdate', function(event, result) {
            vm.ticketsLogs = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
