(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal','$state','Auth', 'JhiLanguageService', '$translate', 'ExtendedUserInformation','CheckContent'];

    function SettingsController (Principal,$state, Auth, JhiLanguageService, $translate, ExtendedUserInformation,CheckContent) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        vm.extendedUserInformation={};

        $('input').each(function(){ CheckContent.check($(this))});
        $('textarea').each(function() { CheckContent.check($(this))});

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
           // console.log(account);
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };

        Principal.identity().then(function(account) {
            ExtendedUserInformation.get({id:account.id},function(result) {
                vm.extendedUserInformation = result;
            });
            vm.settingsAccount = copyAccount(account);
        });

        function save () {
            Auth.updateAccount(vm.settingsAccount).then(function() {
                ExtendedUserInformation.update(vm.extendedUserInformation);
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function(account) {
                    vm.settingsAccount = copyAccount(account);
                });
                JhiLanguageService.getCurrent().then(function(current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
                $state.go('home',{},{reload:true});
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }
    }
})();
