(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ProjectCompleteDetailNew', {
            parent: 'projectdetailjist',
            url: '/ProjectCompleteDetailNew/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT', 'ROLE_L2_DEPARTMENT_HEAD','ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN','ROLE_CAF_STATUS_OFFICER']
            },
            views: {
                'sideView@projectdetailjist': {
                    templateUrl: 'app/ProjectCompleteDetail/ProjectCompleteDetailNew.html',
                    controller: 'ProjectCompleteDetailController',
                    controllerAs: 'vm'
                }
            },
              resolve: {
                  translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                      $translatePartialLoader.addPart('home');
                      return $translate.refresh();
                  }],
                  entity: ['$stateParams', 'Projectcompletedetail', function($stateParams,Projectcompletedetail) {
                          return Projectcompletedetail.get({id : $stateParams.id}).$promise;
                  }],
                  cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                          return CAFAttachment.query({id : $stateParams.id,projectid : $stateParams.id}).$promise;
                  }]
              }
        })
        .state('ProjectCompleteDetailShop', {
            parent: 'projectdetailjist',
            url: '/ProjectCompleteDetailShop/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN']
            },
            views: {
                'sideView@projectdetailjist': {
                    templateUrl: 'app/ProjectCompleteDetail/ProjectCompleteDetailShop.html',
                    controller: 'ProjectCompleteDetailController',
                    controllerAs: 'vm'
                }
            },
              resolve: {
                  translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                      $translatePartialLoader.addPart('home');
                      return $translate.refresh();
                  }],
                  entity: ['$stateParams', 'Projectcompletedetail', function($stateParams,Projectcompletedetail) {
                          return Projectcompletedetail.get({id : $stateParams.id}).$promise;
                  }],
                  cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                          return CAFAttachment.query({id : $stateParams.id,projectid : $stateParams.id}).$promise;
                  }]
              }
        })
            .state('ProjectCompleteDetailExcise', {
                parent: 'projectdetailjist',
                url: '/ProjectCompleteDetailExcise/{id}',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN']
                },
                views: {
                    'sideView@projectdetailjist': {
                        templateUrl: 'app/ProjectCompleteDetail/ProjectCompleteDetailExcise.html',
                        controller: 'ProjectCompleteDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Projectcompletedetail', function($stateParams,Projectcompletedetail) {
                        return Projectcompletedetail.get({id : $stateParams.id}).$promise;
                    }],
                    cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                        return CAFAttachment.query({id : $stateParams.id,projectid : $stateParams.id}).$promise;
                    }]
                }
            })
        .state('ProjectCompleteDetailRenewable', {
         parent: 'projectdetailjist',
         url: '/ProjectCompleteDetailRenewable/{id}',
         data: {
             authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN']
         },
         views: {
             'sideView@projectdetailjist': {
                 templateUrl: 'app/ProjectCompleteDetail/ProjectCompleteDetailRenewable.html',
                 controller: 'ProjectCompleteDetailController',
                 controllerAs: 'vm'
             }
         },
           resolve: {
               translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                   $translatePartialLoader.addPart('home');
                   return $translate.refresh();
               }],
               entity: ['$stateParams', 'Projectcompletedetail', function($stateParams,Projectcompletedetail) {
                       return Projectcompletedetail.get({id : $stateParams.id}).$promise;
               }],
               cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                       return CAFAttachment.query({id : $stateParams.id,projectid : $stateParams.id}).$promise;
               }]
           }
     })
        .state('ProjectCompleteDetailLandRequirement', {
          parent: 'projectdetailjist',
          url: '/ProjectCompleteDetailLandRequirement/{id}',
          data: {
              authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN']
          },
          views: {
              'sideView@projectdetailjist': {
                  templateUrl: 'app/ProjectCompleteDetail/ProjectCompleteDetailLandRequirement.html',
                  controller: 'ProjectCompleteDetailController',
                  controllerAs: 'vm'
              }
          },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Projectcompletedetail', function($stateParams,Projectcompletedetail) {
                        return Projectcompletedetail.get({id : $stateParams.id}).$promise;
                }],
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                        return CAFAttachment.query({id : $stateParams.id,projectid : $stateParams.id}).$promise;
                }]
            }
      })
        .state('ProjectCompleteDetailTower', {
           parent: 'projectdetailjist',
           url: '/ProjectCompleteDetailMix/{id}',
           data: {
               authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN', 'ROLE_DEPT_CHANGE']
           },
           views: {
               'sideView@projectdetailjist': {
                   templateUrl: 'app/ProjectCompleteDetail/ProjectCompleteDetailTower.html',
                   controller: 'ProjectCompleteDetailController',
                   controllerAs: 'vm'
               }
           },
             resolve: {
                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                     $translatePartialLoader.addPart('home');
                     return $translate.refresh();
                 }],
                 entity: ['$stateParams', 'Projectcompletedetail', function($stateParams,Projectcompletedetail) {
                         return Projectcompletedetail.get({id : $stateParams.id}).$promise;
                 }],
                 cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                         return CAFAttachment.query({id : $stateParams.id,projectid : $stateParams.id}).$promise;
                 }]
             }
       })
       .state('ProjectCompleteDetailFilm', {
          parent: 'projectdetailjist',
          url: '/ProjectCompleteDetailFilm/{id}',
          data: {
              authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_USER','ROLE_DESK_OFFICER','ROLE_FIELD_OFFICER','ROLE_RM','ROLE_NODAL_OFFICER','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER','ROLE_FILM_OFFICER','ROLE_FILM_SUBOFFICER','ROLE_TICKET_OPERATION_MANAGER','ROLE_L1_SUPPORT','ROLE_L2_IT_MANAGER','ROLE_L2_DEPARTMENT','ROLE_L2_DEPARTMENT_HEAD','ROLE_L2_INCENTIVES','ROLE_TICKET_NODAL_OFFICER','ROLE_TICKET_ADMIN']
          },
          views: {
              'sideView@projectdetailjist': {
                  templateUrl: 'app/ProjectCompleteDetail/ProjectCompleteDetailFilm.html',
                  controller: 'ProjectCompleteDetailController',
                  controllerAs: 'vm'
              }
          },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Projectcompletedetail', function($stateParams,Projectcompletedetail) {
                        return Projectcompletedetail.get({id : $stateParams.id}).$promise;
                }],
                cafAttachments: ['$stateParams', 'CAFAttachment', function($stateParams,CAFAttachment) {
                        return CAFAttachment.query({id : $stateParams.id,projectid : $stateParams.id}).$promise;
                }]
            }
      });
    }
})();
