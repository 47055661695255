(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('decriminalization', {
            parent: 'entity',
            url: '/decriminalization',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.decriminalization.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/decriminalization/decriminalizations.html',
                    controller: 'DecriminalizationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('decriminalization');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('decriminalization-detail', {
            parent: 'decriminalization',
            url: '/decriminalization/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.decriminalization.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/decriminalization/decriminalization-detail.html',
                    controller: 'DecriminalizationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('decriminalization');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Decriminalization', function($stateParams, Decriminalization) {
                    return Decriminalization.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'decriminalization',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('decriminalization-detail.edit', {
            parent: 'decriminalization-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/decriminalization/decriminalization-dialog.html',
                    controller: 'DecriminalizationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Decriminalization', function(Decriminalization) {
                            return Decriminalization.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('decriminalization.new', {
            parent: 'decriminalization',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/decriminalization/decriminalization-dialog.html',
                    controller: 'DecriminalizationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                complianceRequest: null,
                                departmentName: null,
                                departmentId: null,
                                serviceType: null,
                                otherServiceType: null,
                                serviceSchemeName: null,
                                actName: null,
                                ruleRegulation: null,
                                ruleRegulationName: null,
                                sectionRuleNumber: null,
                                sectionRuleNumberCriminalProvision: null,
                                criminalPunishmentDescription: null,
                                sector: null,
                                otherSector: null,
                                offence: null,
                                criminalPunishmentTenureInMonths: null,
                                minimumTenure: null,
                                maximumTenure: null,
                                criminalPunishmentAmountInInr: null,
                                minimumAmount: null,
                                maximumAmount: null,
                                createDate: null,
                                updateDate: null,
                                sectorId: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('decriminalization', null, { reload: 'decriminalization' });
                }, function() {
                    $state.go('decriminalization');
                });
            }]
        })
        .state('decriminalization.edit', {
            parent: 'decriminalization',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/decriminalization/decriminalization-dialog.html',
                    controller: 'DecriminalizationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Decriminalization', function(Decriminalization) {
                            return Decriminalization.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('decriminalization', null, { reload: 'decriminalization' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('decriminalization.delete', {
            parent: 'decriminalization',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/decriminalization/decriminalization-delete-dialog.html',
                    controller: 'DecriminalizationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Decriminalization', function(Decriminalization) {
                            return Decriminalization.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('decriminalization', null, { reload: 'decriminalization' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
