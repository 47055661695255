(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('ReApplyDashboardView', ReApplyDashboardView);

    ReApplyDashboardView.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance','ReApplyReDashboardViewStats'];
    function ReApplyDashboardView ($timeout, $scope, $stateParams, $uibModalInstance,ReApplyReDashboardViewStats) {
        var vm = this;
        vm.clear = clear;
        vm.serviceName = $stateParams.servicename;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        loadAll();
        function loadAll() {
            ReApplyReDashboardViewStats.query({id:$stateParams.serviceid},function(result){
                vm.dashboardcafs = result;
            });
        }
    }
})();
