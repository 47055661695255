(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ScheduledVisitsDetailController', ScheduledVisitsDetailController);

    ScheduledVisitsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ScheduledVisits'];

    function ScheduledVisitsDetailController($scope, $rootScope, $stateParams, previousState, entity, ScheduledVisits) {
        var vm = this;

        vm.scheduledVisits = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:scheduledVisitsUpdate', function(event, result) {
            vm.scheduledVisits = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
