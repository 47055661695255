(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Tower_service_dashboardDetailController', Tower_service_dashboardDetailController);

    Tower_service_dashboardDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tower_service_dashboard'];

    function Tower_service_dashboardDetailController($scope, $rootScope, $stateParams, previousState, entity, Tower_service_dashboard) {
        var vm = this;

        vm.tower_service_dashboard = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:tower_service_dashboardUpdate', function(event, result) {
            vm.tower_service_dashboard = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
