(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceFormAttachmentsDetailController', ServiceFormAttachmentsDetailController);

    ServiceFormAttachmentsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ServiceFormAttachments'];

    function ServiceFormAttachmentsDetailController($scope, $rootScope, $stateParams, previousState, entity, ServiceFormAttachments) {
        var vm = this;

        vm.serviceFormAttachments = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:serviceFormAttachmentsUpdate', function(event, result) {
            vm.serviceFormAttachments = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
