(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('BackupProjectServiceLogController', BackupProjectServiceLogController);

    BackupProjectServiceLogController.$inject = ['BackupProjectServiceLog'];

    function BackupProjectServiceLogController(BackupProjectServiceLog) {

        var vm = this;

        vm.backupProjectServiceLogs = [];

        loadAll();

        function loadAll() {
            BackupProjectServiceLog.query(function(result) {
                vm.backupProjectServiceLogs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
