(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('EodbDashboardYearlyController', EodbDashboardYearlyController);

    EodbDashboardYearlyController.$inject = ['$scope','$filter', 'Principal', 'LoginService', '$state', 'DeptServiceDashboard', 'DeptServiceStats','ServiceViewCaf','ServiceDashboardStats' ,'EodbDashboardYearlyByElatsic','District','EodbDashboardYearlyByFilter'];

    function EodbDashboardYearlyController ($scope,$filter, Principal, LoginService, $state, DeptServiceDashboard, DeptServiceStats, ServiceViewCaf, ServiceDashboardStats ,EodbDashboardYearlyByElatsic,District,EodbDashboardYearlyByFilter) {
        var vm = this;
        vm.account = null;
        vm.districts = [];
        vm.departments =[];
        vm.filter = {};
        vm.deptName = null;
        vm.districtName = null;
        vm.levelfiled = null;
        vm.hideClear = false;
        $scope.CurrentDate = new Date();

        loadAll();
        function loadAll() {
        Principal.identity().then(function(account) {
            vm.account = account;
        });

        District.query(function(result) {
            vm.districts = result;
            vm.searchQuery = null;
        });


        // ServiceDashboardStats.query(function(result) {
            EodbDashboardYearlyByElatsic.query(function(result) {
          vm.deptservicedashboard = result;
           vm.totalappliedservices=0;
           vm.totalrequiredservices=0;
           vm.totalpendingservices=0;
           vm.totalcompletedservices=0;
           vm.totalrejectedservices=0;
            for(var i=0;i<vm.deptservicedashboard.length;i++)
            {
              vm.totalappliedservices=vm.totalappliedservices + parseInt(vm.deptservicedashboard[i].applied);
              vm.totalrequiredservices=vm.totalrequiredservices+ parseInt(vm.deptservicedashboard[i].required);
              vm.totalpendingservices=vm.totalpendingservices+ parseInt(vm.deptservicedashboard[i].pending_total);
              vm.totalcompletedservices=vm.totalcompletedservices+ parseInt(vm.deptservicedashboard[i].cleared);
              vm.totalrejectedservices=vm.totalrejectedservices+ parseInt(vm.deptservicedashboard[i].rejected);
            }
           vm.searchQuery = null;
                vm.departments = [];

                angular.forEach(vm.deptservicedashboard, function(value, key){
                    if(vm.departments.indexOf(value.department_name) === -1){
                        if(value.department_name != "Communication and Connectivity Permissions"){
                            vm.departments.push(value.department_name);
                        }
                    }
                });
         });
        }

        vm.FilterWiseDat = function () {
            EodbDashboardYearlyByFilter.query(vm.filter,function (result) {
                vm.hideClear = true;
                vm.deptservicedashboard =result;
            })
        };
        vm.clearFilter = function () {
            vm.filter ={};
            loadAll();
        }

    }
})();
