(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DisputeAllController', DisputeAllController);

    DisputeAllController.$inject = ['Dispute','viewRecruitmentFile'];

    function DisputeAllController(Dispute,viewRecruitmentFile ) {

        var vm = this;

        vm.disputes = [];

        loadAll();

        function loadAll() {
            Dispute.query(function(result) {
                vm.disputes = result;
                vm.searchQuery = null;
            });
        }
        vm.DownloadFile = function (fileId) {
            viewRecruitmentFile.view(fileId);
        };

        vm.showPersonalDetail= function (id) {
            Dispute.get({id: id},function(result) {
                console.log(result);
                swal({
                    title: '<u>Applicant Details</u><br/><br/>',
                    animation: true,
                    html:
                        '<b>Name: </b> ' + result.nameOfEnterprise + '<br/><br/>' +
                        '<b>Phone: </b> ' + result.mobileNumber + '<br/><br/>' +
                        '<b>Email: </b> ' + result.emailAddress + '<br/><br/>' +
                        '<b>Address: </b> ' + result.address + '<br/><br/>'
                })
            });
        }
    }
})();
