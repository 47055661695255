(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
         .state('grievancelist', {
              parent: 'app',
              url: '/grievancelist',
             data: {
                 authorities: ['ROLE_ADMIN']
             },
             views: {
                 'content@': {
                     templateUrl: 'app/grievancedetail/grievancelist.html',
                     controller: 'GrievancelistController',
                     controllerAs: 'vm'
                 }
             },
             resolve: {
               translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                   $translatePartialLoader.addPart('home');
                   return $translate.refresh();
             }]
            }
        })

        .state('grievancedetail', {
            parent: 'grievancelist',
            url: '/grievancedetail',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                templateUrl: 'app/grievancedetail/grievancedetail.html',
                controller: 'GrievancedetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: 'false',

                resolve: {
                grievancedetail: function () {
                    return {
                        grievanceId: null,
                        grievanceDate: null,
                        grievanceBy: null,
                        grievance: null,
                        status: 'Pending',
                        resolveComment: null,
                        resolveDate: null,
                        resolveBy: null,
                        assignTo: null,
                        assignDate: null,
                        assignBy: null,
                        assignStatus: false,
                        id: null,
                        district: null,
                        department: null,
                        distrcitId: null,
                        departmentId: null,
                        isResolved: false,
                        nameOfEnterprise: null,
                        factoryAddress: null,
                        correspondenceAddress: null,
                        activityOfEnterprise: null,
                        adhaarNumber: null,
                        nameOfPartner: null,
                        officeBearerIndustriesAssociation: null,
                        contactNumber: null,
                        emailAddress: null,
                        categoryOfGrievance: null,
                        otherCategory: null,
                        grievanceRefferedToDept: false,
                        dateOfCommunication: null,
                        attachemnt1: null,
                        attachemnt2: null

                };
                    }
            }
            }).result.then(function() {
                         $state.go('grievancelist', null, { reload: 'grievancelist' });
                     }, function() {
                         $state.go('grievancelist');
                     });
             }]
        })

        .state('grievancelistadmin', {
                     parent: 'app',
                     url: '/grievancelistadmin',
                     data: {
                         authorities: ['ROLE_ADMIN']
                     },
                     views: {
                         'content@': {
                             templateUrl: 'app/grievancedetail/grievancelistadmin.html',
                             controller: 'GrievancelistadminController',
                             controllerAs: 'vm'
                         }
                     },
                     resolve: {
                       translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                           $translatePartialLoader.addPart('home');
                           return $translate.refresh();
                     }]
                    }
                })

        .state('grievanceassign', {
                parent: 'grievancelistadmin',
                url: '/grievanceassign/{id}',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                    templateUrl: 'app/grievancedetail/grievanceassign.html',
                    controller: 'GrievanceassignController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        grievance: ['Grievance', function(Grievance) {
                             return Grievance.get({id : $stateParams.id}).$promise;
                        }]
                   }

                }).result.then(function() {
                             $state.go('grievancelistadmin', null , { reload: 'grievancelistadmin' });
                         }, function() {
                             $state.go('grievancelistadmin');
                         });
                 }]
            })

        .state('grievanceassignofficer', {
                 parent: 'grievancelistofficer',
                 url: '/grievanceassign/{id}',
                 data: {
                     authorities: ['ROLE_ADMIN']
                 },
                 onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                     $uibModal.open({
                     templateUrl: 'app/grievancedetail/grievanceassign.html',
                     controller: 'GrievanceassignController',
                     controllerAs: 'vm',
                     backdrop: 'static',
                     resolve: {
                         grievance: ['Grievance', function(Grievance) {
                              return Grievance.get({id : $stateParams.id}).$promise;
                         }]
                    }
                 }).result.then(function() {
                              $state.go('grievancelistofficer', null , { reload: 'grievancelistofficer' });
                          }, function() {
                              $state.go('grievancelistofficer');
                          });
                  }]
             })

        .state('grievancelistofficer', {
                     parent: 'app',
                     url: '/grievancelistofficer',
                     data: {
                         authorities: ['ROLE_ADMIN']
                     },
                     views: {
                         'content@': {
                             templateUrl: 'app/grievancedetail/grievancelistofficer.html',
                             controller: 'GrievancelistofficerController',
                             controllerAs: 'vm'
                         }
                     },
                     resolve: {
                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                           $translatePartialLoader.addPart('home');
                           return $translate.refresh();
                     }]

                    }
                })

        .state('resolvebox', {
                    parent: 'grievancelistofficer',
                    url: '/resolvebox/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                        templateUrl: 'app/grievancedetail/resolvebox.html',
                        controller: 'ResolveboxController',
                        controllerAs: 'vm',
                        resolve: {
                            grievance: ['Grievance', function(Grievance) {
                                   return Grievance.get({id : $stateParams.id}).$promise;
                            }]
                           }

                        }).result.then(function() {
                                     $state.go('grievancelistofficer', null, { reload: 'grievancelistofficer' });
                                 }, function() {
                                     $state.go('grievancelistofficer');
                                 });
                     }]
                })

        .state('viewlog-userlist', {
             parent: 'grievancelist',
             url: '/viewlog/{grievanceId}',
             data: {
                 authorities: ['ROLE_ADMIN']
             },
             onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                     $uibModal.open({
                     templateUrl: 'app/grievancedetail/viewlog.html',
                     controller: 'ViewLogController',
                     controllerAs: 'vm',
                     size:'lg',

                         resolve: {
               grievance: ['$stateParams', 'Grievance', function($stateParams, Grievance) {
                      return Grievance.query({grievanceId : $stateParams.grievanceId}).$promise;
               }]
             }
           }).result.then(function() {
                        $state.go('grievancelist', null, { reload: 'grievancelist' });
                   }, function() {
                        $state.go('grievancelist');
                   });
            }]
        })

        .state('viewlog-adminlist', {
             parent: 'grievancelistadmin',
             url: '/viewlog/{grievanceId}',
             data: {
                 authorities: ['ROLE_ADMIN']
             },
             onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                     $uibModal.open({
                     templateUrl: 'app/grievancedetail/viewlog.html',
                     controller: 'ViewLogController',
                     controllerAs: 'vm',
                     size:'lg',

                         resolve: {
               grievance: ['$stateParams', 'Grievance', function($stateParams, Grievance) {
                      return Grievance.query({grievanceId : $stateParams.grievanceId}).$promise;
               }]
             }
           }).result.then(function() {
                        $state.go('grievancelistadmin', null, { reload: 'grievancelistadmin' });
                   }, function() {
                        $state.go('grievancelistadmin');
                   });
            }]
        })

        .state('viewlog-officer', {
             parent: 'grievancelistofficer',
             url: '/viewlog/{grievanceId}',
             data: {
                 authorities: ['ROLE_ADMIN']
             },
             onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                     $uibModal.open({
                     templateUrl: 'app/grievancedetail/viewlog.html',
                     controller: 'ViewLogController',
                     controllerAs: 'vm',
                     size:'lg',

                         resolve: {
               grievance: ['$stateParams', 'Grievance', function($stateParams, Grievance) {
                      return Grievance.query({grievanceId : $stateParams.grievanceId}).$promise;
               }]
             }
           }).result.then(function() {
                        $state.go('grievancelistofficer', null, { reload: 'grievancelistofficer' });
                   }, function() {
                        $state.go('grievancelistofficer');
                   });
            }]
        })
            .state('viewFilledForm-admin', {
                parent: 'grievancelistadmin',
                url: '/viewFilledForm/{id}',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/grievancedetail/ViewGrievanceFormFilled.html',
                        controller: 'ViewGrievanceFormFilledController',
                        controllerAs: 'vm',
                        size:'lg',
                        backdrop: 'static',

                        resolve: {
                            grievance: ['Grievance', function(Grievance) {
                                return Grievance.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('grievancelistadmin', null, { reload: 'grievancelistadmin' });
                    }, function() {
                        $state.go('grievancelistadmin');
                    });
                }]
            })
            .state('viewMyFilledForm', {
                parent: 'grievancelist',
                url: '/viewFilledForm/{id}',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/grievancedetail/ViewGrievanceFormFilled.html',
                        controller: 'ViewGrievanceFormFilledController',
                        controllerAs: 'vm',
                        size:'lg',
                        backdrop: 'static',

                        resolve: {
                            grievance: ['Grievance', function(Grievance) {
                                return Grievance.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('grievancelist', null, { reload: 'grievancelist' });
                    }, function() {
                        $state.go('grievancelist');
                    });
                }]
            })

            .state('viewFilledForm-Officer', {
            parent: 'grievancelistofficer',
            url: '/viewFilledForm/{id}',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/grievancedetail/ViewGrievanceFormFilled.html',
                    controller: 'ViewGrievanceFormFilledController',
                    controllerAs: 'vm',
                    size:'lg',
                    backdrop: 'static',

                    resolve: {
                        grievance: ['Grievance', function(Grievance) {
                            return Grievance.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('grievancelistofficer', null, { reload: 'grievancelistofficer' });
                }, function() {
                    $state.go('grievancelistofficer');
                });
            }]
        });

    }
})();
