(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RejectedProjectServiceLogDetailController', RejectedProjectServiceLogDetailController);

    RejectedProjectServiceLogDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RejectedProjectServiceLog'];

    function RejectedProjectServiceLogDetailController($scope, $rootScope, $stateParams, previousState, entity, RejectedProjectServiceLog) {
        var vm = this;

        vm.rejectedProjectServiceLog = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:rejectedProjectServiceLogUpdate', function(event, result) {
            vm.rejectedProjectServiceLog = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
