(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('viewpaymentsController', viewpaymentsController);

    viewpaymentsController.$inject = ['$filter', '$scope','$state', 'Principal', '$stateParams', 'ProjectServicePayment','ProjectServicePaymentBetweenDate', 'ProjectServicePaymentByTransaction'];

    function viewpaymentsController($filter, $scope, $state, Principal, $stateParams, ProjectServicePayment, ProjectServicePaymentBetweenDate, ProjectServicePaymentByTransaction) {
        var vm = this;
        vm.viewstatus=$stateParams.disbursed;
       // vm.selectedDate=selectedDate;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.fromDate = false;
        vm.datePickerOpenStatus.toDate = false;
        vm.showDateFilter = false;
        vm.clearFilterButton = false;
        vm.transId = null;

        vm.openCalendar = openCalendar;
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        getAccount();
        // loadAll($stateParams.disbursed);

        // function loadAll(disbursed) {
        //     ProjectServicePayment.query({disbursed:disbursed},function(result){
        //         vm.projectservicepayments=result;
        //         if (vm.projectservicepayments!==""){
        //             vm.showDateFilter = true;
        //         }
        //     });
        //
        // }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        vm.dateOptions = {
            minDate: new Date("01-01-2000"),
            maxDate: Date.now()
        };

        
        function getPaymentBetweenDates(disbursed) {
            ProjectServicePayment.query({disbursed:disbursed, },function(result){
                vm.projectservicepayments=result;
            });
        }

        vm.getPaymentBetweenDates=function(disbursed){
            var fromdate=$filter('date')(vm.fromdate, "yyyy-MM-dd");
            var todate=$filter('date')(vm.todate, "yyyy-MM-dd");

            ProjectServicePaymentBetweenDate.query({disbursed:disbursed,fromdate:fromdate,todate:todate},function(result){
                vm.projectservicepayments=result;
                vm.clearFilterButton = true;
            });
        };

        vm.getPaymentByTransactionId=function(disbursed, transactionId){
            ProjectServicePaymentByTransaction.query({disbursed:disbursed, transactionId:transactionId},function(result){
                vm.projectservicepayments=result;
                vm.clearFilterButton = true;
            });
        };

        vm.clearFilter = function () {
             vm.fromdate = null;
             vm.todate = null;
            vm.clearFilterButton = false;
            vm.transId = null;
            loadAll($stateParams.disbursed);
            // loadAll($stateParams.disbursed);
        }
    }
})();
