(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('ProjectToExistingCafSearch', ProjectToExistingCafSearch);

    ProjectToExistingCafSearch.$inject = ['$resource'];

    function ProjectToExistingCafSearch ($resource) {
        var resourceUrl =  '/api/migrateprojecttoexistingcafsearch';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            }
        });
    }
})();
