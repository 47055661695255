(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('InspectorDetailsDeleteController',InspectorDetailsDeleteController);

    InspectorDetailsDeleteController.$inject = ['$uibModalInstance', 'entity', 'InspectorDetails'];

    function InspectorDetailsDeleteController($uibModalInstance, entity, InspectorDetails) {
        var vm = this;

        vm.inspectorDetails = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            InspectorDetails.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
