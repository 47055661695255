(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('BelowGroundDialogController', BelowGroundDialogController);

    BelowGroundDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'Below_ground', 'FileManagement', 'District', 'Zone'];

    function BelowGroundDialogController ($timeout, $scope, $state, $stateParams, entity, Below_ground, FileManagement, District, Zone) {
        var vm = this;

        vm.below_ground = entity;
        vm.save = save;
        vm.onsubmit = onsubmit;
        vm.upload= upload;
        $scope.file = {};
        var savefilename=null;

        UIkit.modal("#modal-below2").show();


        loadAll();

        function loadAll() {
            District.query(function(result) {
                vm.districts = result;
                console.log(result);
                vm.searchQuery = null;
            });
            Zone.query(function(result) {
                vm.zones = result;
                vm.searchQuery = null;
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            vm.show= true;
            if (vm.below_ground.id !== null) {
                Below_ground.update(vm.below_ground, onSaveSuccess, onSaveError);
            } else {
                vm.below_ground.isfullyfilled= false;
                vm.below_ground.status = 'Pending';
                Below_ground.save(vm.below_ground, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.result=result;
                $scope.$emit('investhryApp:below_groundUpdate', result);
                vm.isSaving = false;
            }

        function onSaveError() {
                vm.isSaving = false;
        }

        function  upload(i,object) {
            var fileNameArray = object.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var filename = vm.result.id+'_'+ i;
            savefilename=filename+ "." + fileextension;

            var file =object;
            FileManagement.saveFile(file,filename,onFileUploadedSuccess,onFileUploadedError);
            vm.vari=i;
        }

        function onsubmit() {
           $state.go("belowGround");
        }

        function onFileUploadedSuccess() {
            UIkit.notification("File Uploaded Successfully . . .",{timeout: 2000});
            if (vm.vari==1){
                vm.show_upload1=true;
                vm.result.document1= savefilename;
                Below_ground.update(vm.result);
            }
            else if(vm.vari==2){
                vm.show_upload2=true;
                vm.result.document2= savefilename;
                Below_ground.update(vm.result);
            }
            else if(vm.vari==3){
                vm.show_upload3=true;
                vm.result.document3= savefilename;
                Below_ground.update(vm.result);
            }
            else if(vm.vari==4){
                vm.show_upload4=true;
                vm.result.document4= savefilename;
                Below_ground.update(vm.result);
            }
            else if(vm.vari==5){
                vm.show_upload5=true;
                vm.result.document5= savefilename;
                Below_ground.update(vm.result);
            }
            else if(vm.vari==6){
                vm.show_upload6=true;
                vm.result.document6= savefilename;
                vm.result.isfullyfilled = true;
                Below_ground.update(vm.result);
            }
            else if(vm.vari==7){
                vm.show_upload7=true;
                vm.result.document7= savefilename;
                Below_ground.update(vm.result);
            }
            else if(vm.vari==8){
                vm.show_upload8=true;
                vm.result.document8= savefilename;
                Below_ground.update(vm.result);
            }
        }

        function onFileUploadedError() {
            UIkit.notification("Unexpected Error Occurred! Try Again . .",{timeout: 2000,status: 'danger'});
        }

    }
})();
