(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('assignFieldOfficerToDepartmentController', assignFieldOfficerToDepartmentController);

    assignFieldOfficerToDepartmentController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'fieldOfficerList', 'Principal', 'Department', 'AssignFieldOfficerToDepartment'];

    function assignFieldOfficerToDepartmentController ($timeout, $scope, $stateParams, entity, fieldOfficerList, Principal, Department, AssignFieldOfficerToDepartment) {
        var vm = this;
        vm.save =save;
        vm.account = null;
        vm.assignFieldOfficerToDepartment = entity;
            vm.isAuthenticated = null;
//                vm.login = LoginService.open;

            vm.register = register;
            $scope.$on('authenticationSuccess', function() {
                getAccount();
            });

            getAccount();

            function getAccount() {
                Principal.identity().then(function(account) {
                    vm.account = account;
                    vm.isAuthenticated = Principal.isAuthenticated;
                });
            }
            function register () {
                $state.go('register');
            }
        loadAllDepartment();

        function loadAllDepartment() {
            Department.query(function(result) {
                vm.departments = result;
                vm.searchQuery = null;
            });
        }
        loadAllFieldOfficer();
        function loadAllFieldOfficer() {
            vm.fieldofficerList= fieldOfficerList.query();
            console.log(vm.RMDetails);
        }
        function save (departmentid, fieldOfficer) {
            vm.isSaving = true;
            vm.assignFieldOfficerToDepartment.departmentid = departmentid;
            vm.assignFieldOfficerToDepartment.fieldofficerlogin = fieldOfficer.login;
            vm.assignFieldOfficerToDepartment.fieldOfficerid = fieldOfficer.id;
            if (vm.assignFieldOfficerToDepartment.id !== null) {
                AssignFieldOfficerToDepartment.update(vm.assignFieldOfficerToDepartment, onSaveSuccess, onSaveError);
            } else {
                AssignFieldOfficerToDepartment.save(vm.assignFieldOfficerToDepartment, onSaveSuccess, onSaveError);
            }
        }
        function onSaveSuccess (result) {
//            $scope.$emit('investhryApp:assignFieldOfficerToDepartmentUpdate', result);
//            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
