(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DeleteUserController', DeleteUserController);

    DeleteUserController.$inject = ['User','UserById','DeleteUser'];

    function DeleteUserController(User,UserById,DeleteUser) {

        var vm = this;
        vm.getUser = getUser;
        function getUser(id){
            UserById.get({id:id},function(user){
                vm.user = user;
                vm.showButton = true;
            });
        }

        vm.deleteuser = deleteuser;
        function deleteuser(id){
            DeleteUser.delete({deleteuserid:id},onSuccess,onError);
        }
        function onSuccess(result){
            vm.showButton = false;
            alert("User Successfully Deleted");
        }
        function onError(){
            alert("User Not Deleted");
        }
    }
 })();
