(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DraftCAFController', DraftCAFController);

    DraftCAFController.$inject = ['$scope',  'Principal', 'LoginService',  '$state', 'DraftCAF','Project','DeleteDraftCAF'];

    function DraftCAFController ($scope,  Principal, LoginService, $state, DraftCAF, Project, DeleteDraftCAF) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.showtable=false;
        vm.project=[];
        vm.deleteId = null;
        vm.deleteCaf=deleteCaf;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        loadDraftCAF();
        function loadDraftCAF()
        {
            vm.project=null;
            vm.showtable=false;
            DraftCAF.query(function(result){
                vm.project = result;
                if(vm.project.length === 0){
                    vm.showtable=false;
                }
                else {
                    vm.showtable=true;
                }
        });
        }

        vm.modalCaf = function(id){
          $('#deleteModal').modal('show');
          vm.deleteId = id;
        };

        function deleteCaf(id) {
            $('#deleteModal').modal('hide');
            Project.delete({id: vm.deleteId},
            function(){
            loadDraftCAF();
                });
        }

        vm.DeleteCaf = function () {
               console.log(vm.project);
               DeleteDraftCAF.get();
               location.reload();
        }

    }
})();
