(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('EstateController', EstateController);

    EstateController.$inject = ['Estate'];

    function EstateController(Estate) {

        var vm = this;

        vm.estates = [];

        loadAll();

        function loadAll() {
            Estate.query(function(result) {
                vm.estates = result;
                vm.searchQuery = null;
            });
        }
    }
})();
