(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AboveGroundDialogController', AboveGroundDialogController);

    AboveGroundDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'Above_ground', 'FileManagement', 'District', 'Zone'];

    function AboveGroundDialogController ($timeout, $scope, $state, $stateParams, entity, Above_ground, FileManagement, District, Zone) {
        var vm = this;

        vm.above_ground = entity;
        vm.save = save;
        vm.extent= extent;
        vm.upload= upload;
        vm.onsubmit = onsubmit;
        $scope.file = {};
        var savefilename=null;

        loadAll();
        UIkit.modal("#modal-above2").show();

        function loadAll() {
            District.query(function(result) {
                vm.districts = result;
                vm.searchQuery = null;
            });
            Zone.query(function(result) {
                vm.zones = result;
                vm.searchQuery = null;
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function extent() {
         vm.show= !vm.show;
        }

        function save () {
            vm.isSaving = true;
            vm.show1= true;
            if (vm.above_ground.id !== null) {
                Above_ground.update(vm.above_ground, onSaveSuccess, onSaveError);
            } else {
                if($stateParams=='new'){
                  vm.above_ground.isexisting = false;
                }
                vm.above_ground.isfullyfilled = false;
                vm.above_ground.status = 'Pending';
                Above_ground.save(vm.above_ground, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.result=result;
            $scope.$emit('investhryApp:above_groundUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function  upload(i,object) {
            var fileNameArray = object.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var filename = vm.result.id+'_'+ i;
            savefilename=filename+ "." + fileextension;
            var file =object;
            FileManagement.saveFile(file,filename,onFileUploadedSuccess,onFileUploadedError);
            vm.vari=i;
        }

        function onsubmit() {
            $state.go("aboveGround");
        }


        function onFileUploadedSuccess() {
            UIkit.notification("File Uploaded Successfully . . .",{timeout: 2000});
            if (vm.vari==1){
                vm.show_upload1=true;
                vm.result.document1= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==2){
                vm.show_upload2=true;
                vm.result.document2= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==3){
                vm.show_upload3=true;
                vm.result.document3= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==4){
                vm.show_upload4=true;
                vm.result.document4= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==5){
                vm.show_upload5=true;
                vm.result.document5= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==6){
                vm.show_upload6=true;
                vm.result.document6= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==7){
                vm.show_upload7=true;
                vm.result.document7= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==8){
                vm.show_upload8=true;
                vm.result.document8= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==9){
                vm.show_upload9=true;
                vm.result.document9= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==10){
                vm.show_upload10=true;
                vm.result.document10= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==11){
                vm.show_upload11=true;
                vm.result.document11= savefilename;
                vm.result.isfullyfilled = true;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==12){
                vm.show_upload12=true;
                vm.result.other_document1= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==13){
                vm.show_upload13=true;
                vm.result.other_document2= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==14){
                vm.show_upload14=true;
                vm.result.other_document3= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==15){
                vm.show_upload15=true;
                vm.result.other_document4= savefilename;
                Above_ground.update(vm.result);
            }
            else if(vm.vari==16) {
                vm.show_upload16=true;
                vm.result.other_document5= savefilename;
                Above_ground.update(vm.result);
            }
        }

        function onFileUploadedError() {
            UIkit.notification("Unexpected Error Occurred! Try Again . .",{timeout: 2000,status: 'danger'});
        }
    }
})();
