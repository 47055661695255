(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
         .state('aboveGround', {
            parent: 'app',
                url: '/towerInstallation/aboveGround',
            data: {
                authorities: ['ROLE_TOWER_USER'],
                pageTitle: 'global.menu.aboveGround'
            },
            views: {
                'content@': {
                    templateUrl: 'app/aboveGround/aboveGround.html',
                    controller: 'AboveGroundController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('aboveGround');
                    return $translate.refresh();
                }]
            }
        })
         .state('aboveGround-dialog', {
                parent: 'aboveGround',
                url: '/{isNew}',
                data: {
                    authorities: ['ROLE_TOWER_USER']
                },
                views: {
                 'content@': {
                     templateUrl: 'app/aboveGround/aboveGround-dialog.html',
                     controller: 'AboveGroundDialogController',
                     controllerAs: 'vm'
                 }
                },
                resolve: {
                    entity: function () {
                        return {
                            name_applicant: null,
                            registered_address: null,
                            har_office_address: null,
                            designation_auth_person: null,
                            phone_auth_person: null,
                            email_auth_person: null,
                            extent: null,
                            address_line_1: null,
                            address_line_2: null,
                            address_line_3: null,
                            address_line_4: null,
                            tehsil: null,
                            district: null,
                            latitude_longitude: null,
                            name_auth_person: null,
                            zone_area: null,
                            owner_name: null,
                            owner_address: null,
                            tower_height: null,
                            tower_weight: null,
                            tower_type1: null,
                            tower_type2: null,
                            tower_type3: null,
                            tower_antena_no: null,
                            tower_lat_long: null,
                            other_info1: null,
                            other_info2: null,
                            other_info3: null,
                            other_info4: null,
                            other_info5: null,
                            other_info6: null,
                            fee_details: null,
                            document1: null,
                            document2: null,
                            document3: null,
                            document4: null,
                            document5: null,
                            document6: null,
                            document7: null,
                            document8: null,
                            document9: null,
                            document10: null,
                            document11: null,
                            other_document1: null,
                            other_document2: null,
                            other_document3: null,
                            other_document4: null,
                            other_document5: null,
                            isfullyfilled: false,
                            status: null,
                            isforwardedtodio: false,
                            isforwardedtodept: false,
                            isforwardedtodc: false,

                            clearancefile1: null,
                            clearancefile2: null,
                            clearancefile3: null,

                            created_by: null,
                            created_on: null,
                            isforwarded: null,
                            isexisting: false,

                            id: null
                        };
                    }
             }
            })
         .state('aboveGround-detail', {
                parent: 'aboveGround',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_TOWER_USER']
                    //pageTitle: 'investhryApp.aboveGround.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/aboveGround/aboveGround-detail.html',
                        controller: 'AboveGroundDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('above_ground');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Above_ground', function($stateParams, Above_ground) {
                        return Above_ground.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'above-ground',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });
    }
})();
