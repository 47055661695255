(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CafsscheduledfordeactivationController', CafsscheduledfordeactivationController);

    CafsscheduledfordeactivationController.$inject = ['Cafsscheduledfordeactivation'];

    function CafsscheduledfordeactivationController(Cafsscheduledfordeactivation) {

        var vm = this;

        vm.cafsscheduledfordeactivations = [];

        loadAll();

        function loadAll() {
            Cafsscheduledfordeactivation.query(function(result) {
                vm.cafsscheduledfordeactivations = result;
                vm.searchQuery = null;
            });
        }
    }
})();
