(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AssignDisputeController', AssignDisputeController);

    AssignDisputeController.$inject = ['$scope', '$state','$uibModalInstance', 'Principal', 'Dispute','dispute','DepartemntEEC','Disputelog'];

    function AssignDisputeController ($scope, $state, $uibModalInstance, Principal,  Dispute, dispute, DepartemntEEC,Disputelog) {
        var vm = this;
       vm.dispute = dispute;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.register = register;

        vm.clear = clear;
        vm.saveResolveData = saveResolveData;

        vm.disputelog={};

        loadAll();
        vm.departments =[];

        function loadAll() {
            DepartemntEEC.query(function (result) {
                vm.departments = result;
                vm.searchQuery = null;
            });
        }
        function saveResolveData()
        {
            Dispute.update(vm.dispute, onSaveSuccess);
        }
        function onSaveSuccess(result)
        {
            vm.disputelog.disputeID = result.disputeID;
            vm.disputelog.disputeMainId = result.id;
            vm.disputelog.actionComment = result.concernedNo;
            vm.disputelog.action = "Dispute Assigned";

            if (vm.disputelog.id !== null) {
                Disputelog.update(vm.disputelog);
            } else {
                Disputelog.save(vm.disputelog);
            }
            $uibModalInstance.close(result);
        }
        function clear ()
        {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
