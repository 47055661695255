(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('hepc-recruitment-qualifications', {
            parent: 'entity',
            url: '/hepc-recruitment-qualifications',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.hepcRecruitmentQualifications.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/hepc-recruitment-qualifications/hepc-recruitment-qualifications.html',
                    controller: 'HepcRecruitmentQualificationsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('hepcRecruitmentQualifications');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('hepc-recruitment-qualifications-detail', {
            parent: 'hepc-recruitment-qualifications',
            url: '/hepc-recruitment-qualifications/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.hepcRecruitmentQualifications.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/hepc-recruitment-qualifications/hepc-recruitment-qualifications-detail.html',
                    controller: 'HepcRecruitmentQualificationsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('hepcRecruitmentQualifications');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'HepcRecruitmentQualifications', function($stateParams, HepcRecruitmentQualifications) {
                    return HepcRecruitmentQualifications.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'hepc-recruitment-qualifications',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('hepc-recruitment-qualifications-detail.edit', {
            parent: 'hepc-recruitment-qualifications-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/hepc-recruitment-qualifications/hepc-recruitment-qualifications-dialog.html',
                    controller: 'HepcRecruitmentQualificationsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['HepcRecruitmentQualifications', function(HepcRecruitmentQualifications) {
                            return HepcRecruitmentQualifications.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('hepc-recruitment-qualifications.new', {
            parent: 'hepc-recruitment-qualifications',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/hepc-recruitment-qualifications/hepc-recruitment-qualifications-dialog.html',
                    controller: 'HepcRecruitmentQualificationsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                degree: null,
                                stream: null,
                                session: null,
                                passingYear: null,
                                schoolUniversity: null,
                                specialization: null,
                                marksObtained: null,
                                marksPercentage: null,
                                regularCorrespondance: null,
                                division: null,
                                documentName: null,
                                documentType: null,
                                refId: null,
                                applicationId: null,
                                preferredLocation: null,
                                totalExperience: null,
                                jobProfile: null,
                                name: null,
                                sortIndex: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('hepc-recruitment-qualifications', null, { reload: 'hepc-recruitment-qualifications' });
                }, function() {
                    $state.go('hepc-recruitment-qualifications');
                });
            }]
        })
        .state('hepc-recruitment-qualifications.edit', {
            parent: 'hepc-recruitment-qualifications',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/hepc-recruitment-qualifications/hepc-recruitment-qualifications-dialog.html',
                    controller: 'HepcRecruitmentQualificationsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['HepcRecruitmentQualifications', function(HepcRecruitmentQualifications) {
                            return HepcRecruitmentQualifications.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('hepc-recruitment-qualifications', null, { reload: 'hepc-recruitment-qualifications' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('hepc-recruitment-qualifications.delete', {
            parent: 'hepc-recruitment-qualifications',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/hepc-recruitment-qualifications/hepc-recruitment-qualifications-delete-dialog.html',
                    controller: 'HepcRecruitmentQualificationsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['HepcRecruitmentQualifications', function(HepcRecruitmentQualifications) {
                            return HepcRecruitmentQualifications.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('hepc-recruitment-qualifications', null, { reload: 'hepc-recruitment-qualifications' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
