(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('projecttypeController', projecttypeController);

    projecttypeController.$inject = ['$scope','$state','$uibModalInstance','ProjectType'];

    function projecttypeController($scope,$state,$uibModalInstance,ProjectType) {

        var vm = this;
        vm.clear = clear;

        function clear () {
                    $uibModalInstance.dismiss('cancel');
                }

        vm.onprojectTypeSelected=function(){
        ProjectType.putProjectType(vm.projectType)
        $uibModalInstance.close();
        $state.go('addproject',{},{reload:true});
        }

    }
})();
