(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SupplyDisposalController', SupplyDisposalController);

    SupplyDisposalController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'SupplyAndDisposal', 'SupplyDisposal', '$location'];

    function SupplyDisposalController ($scope, Principal, LoginService, $state, SupplyAndDisposal, SupplyDisposal, $location) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.showtable=false;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
            ga('set', 'page', $location.path());
            ga('send','pageview');
        });

        // dataByStatus(status);

        vm.supplyAndDisposals = {};

        // loadAll();
        //
        // function loadAll() {
        //     SupplyAndDisposal.query(function(result) {
        //         vm.supplyAndDisposals = result;
        //     });
        // }

        dataByStatus();
        function dataByStatus () {
            SupplyDisposal.get(function(result) {
                vm.supplyAndDisposals = result;
                console.log(vm.supplyAndDisposals);
            });
        }


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
    }

})();
