(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('FeedbacksByOfficerController', FeedbacksByOfficerController);

    FeedbacksByOfficerController.$inject = ['$scope', '$filter','$state','Principal','Rating', 'DepartmentService', 'District','RatingsByDepartmentId','RatingsByDepartmentIdAndRating'];

    function FeedbacksByOfficerController($scope, $filter, $state,Principal, Rating, DepartmentService, District, RatingsByDepartmentId, RatingsByDepartmentIdAndRating) {
        var vm = this;
        vm.account= {};
        vm.feedbacks = [];
        vm.allFeedbacks = [];
        vm.departmentNames = [];
        vm.ratingType = "All";
        vm.getWorkListType="";
        vm.getWorkType = "pending";
        vm.getPoorAverage = getPoorAverage;
        vm.resolve = false;
        vm.ResolveFeedback=[];
        vm.filter = false;
        vm.back = back;
        vm.HODFeedback = [];

        $scope.averageOrPoor = function(feedback){
            return feedback.rating === 'Average' || feedback.rating === 'Poor';
        };
        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                for(var i=0;i<=vm.account.authorities.length; i++){
                    if(vm.account.authorities[i]==="ROLE_NODAL_OFFICER"){
                        vm.ratingType = "Average";
                        vm.getRatingsByRating(vm.ratingType);
                    }else if(vm.account.authorities[i]==="ROLE_FEEDBACK_OFFICER"){
                        vm.ratingType = "All";
                        vm.getRatingsByRating(vm.ratingType);
                        loadAll();
                    }
                }
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }


            function loadAll() {
                RatingsByDepartmentIdAndRating.query({rating: "All"}, function (result) {
                    vm.HODFeedback = $filter('filter')(result, {isResolved: false});
                    if (result.length > 0) {
                        vm.departmentNames = getUniqueDepartmentNames(result);
                    }
                });
            }



        function getPoorAverage(){
            vm.resolve=true;
            vm.filter= true;
            vm.ResolveFeedback = vm.HODFeedback;
        }


        vm.getRatingsByRating = function(ratingType){
            vm.resolve = false;
            vm.ResolveFeedback = [];
            RatingsByDepartmentIdAndRating.query({ rating: ratingType },function(result){
                vm.feedbacks = result;
                vm.allFeedbacks = result;
                if(result.length > 0){
                    vm.departmentNames = getUniqueDepartmentNames(result);
                }
                vm.getWorkList(vm.getWorkType);
            });
        }



        vm.getWorkList = function(selection){
            vm.getWorkType = selection;
            vm.resolve = false;
            if(selection === 'pending'){
                vm.feedbacks=$filter('filter')(vm.allFeedbacks,{ isResolved : false });
            }else if(selection === 'resolved'){
                vm.filter= true;
                vm.feedbacks=$filter('filter')(vm.allFeedbacks,{ isResolved : true });
            }
        };

        function getUniqueDepartmentNames(array){
            var unique = {};
            var distinct = [];
            for( var i in array ){
                if( typeof(unique[array[i].departmentName]) == "undefined"){
                    distinct.push(array[i].departmentName);
                }
                unique[array[i].departmentName] = 0;
            }
            return filter_array(distinct);
        }

        function filter_array(test_array) {
            var index = -1,
                arr_length = test_array ? test_array.length : 0,
                resIndex = -1,
                result = [];

            while (++index < arr_length) {
                var value = test_array[index];

                if (value) {
                    result[++resIndex] = value;
                }
            }

            return result;
        }
        function back() {
            $state.reload();
        }
    }
})();
