(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('UploadTicketAttachment',['$http', function($http){
        return {
            saveFile: function(file,onsuccess,onerror){
                var resourceUrl =  'api/uploadTicketAttachment';
                var fd = new FormData();
                fd.append('file', file);
                return $http.post(resourceUrl, fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    }
                }).success(function(result){
                    if (onsuccess!==undefined){
                        onsuccess(result);
                    }
                }).error(function(){
                    if (onerror!==undefined){
                        onerror();
                    }
                });
            }
            }
        }]);
})();
