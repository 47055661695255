(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('QuestionnaireController', QuestionnaireController);

    QuestionnaireController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'questionnaire'];

    function QuestionnaireController ($scope, Principal, LoginService, $state, questionnaire) {
        var vm = this;
        vm.questionnaire=questionnaire;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();
        loadAll();
        function loadAll(){
            ga('set', 'page', '/Know-Your-Clearance');ga('send','pageview');
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

    }
})();
