(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ReappliedservicesController', ReappliedservicesController);

    ReappliedservicesController.$inject = ['Reappliedservices'];

    function ReappliedservicesController(Reappliedservices) {

        var vm = this;

        vm.reappliedservices = [];
        vm.loadByProjectservicedetailid = loadByProjectservicedetailid;

//        loadAll();
//
//        function loadAll() {
//            Reappliedservices.query(function(result) {
//                vm.reappliedservices = result;
//                vm.searchQuery = null;
//            });
//        }

        function loadByProjectservicedetailid(projectservicedetailid) {
            vm.reappliedservices = null;
            Reappliedservices.query({projectservicedetailid:projectservicedetailid}, function(result) {
                vm.reappliedservices = result;
                vm.searchQuery = null;
            });
        }
    }
})();
