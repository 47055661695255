(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MinutesofmeetingDialogController', MinutesofmeetingDialogController);

    MinutesofmeetingDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Minutesofmeeting','FileManagement'];

    function MinutesofmeetingDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Minutesofmeeting,FileManagement) {
        var vm = this;

        vm.minutesofmeeting = entity;
        vm.clear = clear;
        vm.save = save;
        $scope.file = {};

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            // console.log(vm.minutesofmeeting);
            vm.isSaving = true;
            if (vm.minutesofmeeting.id !== null) {
                Minutesofmeeting.update(vm.minutesofmeeting, onSaveSuccess, onSaveError);
            } else {
                Minutesofmeeting.save(vm.minutesofmeeting, onSaveSuccess, onSaveError);
            }
        }

        vm.update= function () {
            // console.log(vm.minutesofmeeting);
            Minutesofmeeting.update(vm.minutesofmeeting);
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:minutesofmeetingUpdate', result);
            var filename = result.id;
            var file = vm.mintuesofmeeting.file;
            FileManagement.saveFile(file,filename);

            var fileNameArray = vm.mintuesofmeeting.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var downloadfilename=result.momdescription;
            var serverfilename=result.id;

            if(fileextension!=="")
            {
                downloadfilename=downloadfilename+"."+fileextension;
                serverfilename=serverfilename+"."+fileextension;
            }
            console.log(downloadfilename);
            result.downloadfilename=downloadfilename;

            console.log(serverfilename);
            result.serverfilename=serverfilename;

            console.log(result);

            Minutesofmeeting.update(result,onUpdateSuccess,onUpdateError);
        }

        function onUpdateSuccess(result)
        {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        function onUpdateError () {
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
