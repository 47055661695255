(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RedirectToCentralPortalController', RedirectToCentralPortalController);

    RedirectToCentralPortalController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'CentralRecord', '$timeout'];

    function RedirectToCentralPortalController ($scope, Principal, LoginService, $state, CentralRecord, $timeout) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.project={};
        vm.showtable=false;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
            getRecord();
        }

        function getRecord() {
            CentralRecord.get(function(result){
                vm.project = result;
                console.log(vm.project);
                $timeout(function () {
                    angular.element('.form-group:eq(1)>input').focus();
                    angular.element(document.getElementById('fillform')).trigger('click')
                });
            });
        }


    }
})();
