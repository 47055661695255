(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Service_dashboard_outputController', Service_dashboard_outputController);

    Service_dashboard_outputController.$inject = ['Service_dashboard_output'];

    function Service_dashboard_outputController(Service_dashboard_output) {

        var vm = this;

        vm.service_dashboard_outputs = [];

        loadAll();

        function loadAll() {
            Service_dashboard_output.query(function(result) {
                vm.service_dashboard_outputs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
