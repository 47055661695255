(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SupplyAndDisposalDeleteController',SupplyAndDisposalDeleteController);

    SupplyAndDisposalDeleteController.$inject = ['$uibModalInstance', 'entity', 'SupplyAndDisposal'];

    function SupplyAndDisposalDeleteController($uibModalInstance, entity, SupplyAndDisposal) {
        var vm = this;

        vm.supplyAndDisposal = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SupplyAndDisposal.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
