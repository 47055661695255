(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SurveyController', SurveyController);

    SurveyController.$inject = ['Survey'];

    function SurveyController(Survey) {

        var vm = this;

        vm.surveys = [];

        loadAll();

        function loadAll() {
            Survey.query(function(result) {
                vm.surveys = result;
                vm.show = true;
                vm.searchQuery = null;
            });
        }
    }
})();
