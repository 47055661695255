(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Meeting_attendeeDetailController', Meeting_attendeeDetailController);

    Meeting_attendeeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Meeting_attendee'];

    function Meeting_attendeeDetailController($scope, $rootScope, $stateParams, previousState, entity, Meeting_attendee) {
        var vm = this;

        vm.meeting_attendee = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:meeting_attendeeUpdate', function(event, result) {
            vm.meeting_attendee = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
