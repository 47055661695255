(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DistrictNodalOfficerController', DistrictNodalOfficerController);

    DistrictNodalOfficerController.$inject = ['DistrictNodalOfficer'];

    function DistrictNodalOfficerController(DistrictNodalOfficer) {

        var vm = this;

        vm.districtNodalOfficers = [];

        loadAll();

        function loadAll() {
            DistrictNodalOfficer.query(function(result) {
                vm.districtNodalOfficers = result;
                vm.searchQuery = null;
            });
        }
    }
})();
