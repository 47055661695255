(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RejectionBucketController', RejectionBucketController);

    RejectionBucketController.$inject = ['RejectionBucket'];

    function RejectionBucketController(RejectionBucket) {

        var vm = this;

        vm.rejectionBuckets = [];

        loadAll();

        function loadAll() {
            RejectionBucket.query(function(result) {
                vm.rejectionBuckets = result;
                vm.searchQuery = null;
            });
        }
    }
})();
