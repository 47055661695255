(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('ExistingCafToZero', ExistingCafToZero);

    ExistingCafToZero.$inject = ['$resource', 'DateUtils'];

    function ExistingCafToZero ($resource, DateUtils) {
        var resourceUrl =  'api/existingCafMigrationResource';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            }
        });
    }
})();
