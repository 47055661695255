(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('verifyOtherPaymentController', verifyOtherPaymentController);

    verifyOtherPaymentController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'projectService','projectServiceLog','AssignServiceFee','ProjectServicePaymentDetailsByProjectAndService'];
    function verifyOtherPaymentController ($timeout, $scope, $stateParams, $uibModalInstance, projectService,projectServiceLog,AssignServiceFee,ProjectServicePaymentDetailsByProjectAndService) {
        var vm = this;
        vm.clear = clear;
        vm.projectService=projectService;
        vm.projectServiceLog=projectServiceLog;
        vm.assignFeeDetail=[];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        loadAllPayments();

        function loadAllPayments() {
          vm.assignFeeDetail=ProjectServicePaymentDetailsByProjectAndService.query({projectid: vm.projectService.projectid,serviceid: vm.projectService.serviceid});
        }

        }
        })();
