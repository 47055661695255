(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('PublicHolidayController', PublicHolidayController);

    PublicHolidayController.$inject = ['PublicHoliday'];

    function PublicHolidayController(PublicHoliday) {

        var vm = this;

        vm.publicHolidays = [];

        loadAll();

        function loadAll() {
            PublicHoliday.query(function(result) {
                vm.publicHolidays = result;
                vm.searchQuery = null;
            });
        }
    }
})();
