(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ClearedServicesDetailController', ClearedServicesDetailController);

    ClearedServicesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ClearedServices'];

    function ClearedServicesDetailController($scope, $rootScope, $stateParams, previousState, entity, ClearedServices) {
        var vm = this;

        vm.clearedServices = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:clearedServicesUpdate', function(event, result) {
            vm.clearedServices = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
