(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ServiceDashboardStatsController', ServiceDashboardStatsController);

    ServiceDashboardStatsController.$inject = ['ServiceDashboardStats'];

    function ServiceDashboardStatsController(ServiceDashboardStats) {

        var vm = this;

        vm.serviceDashboardStats = [];

        loadAll();

        function loadAll() {
            ServiceDashboardStats.query(function(result) {
                vm.serviceDashboardStats = result;
                vm.searchQuery = null;
            });
        }
    }
})();
