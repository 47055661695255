(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('draft-policy', {
            parent: 'entity',
            url: '/draft-policy',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER'],
                pageTitle: 'investhryApp.draftPolicy.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/draft-policy/draft-policies.html',
                    controller: 'DraftPolicyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('draftPolicy');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('draft-policy-detail', {
            parent: 'draft-policy',
            url: '/draft-policy/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER'],
                pageTitle: 'investhryApp.draftPolicy.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/draft-policy/draft-policy-detail.html',
                    controller: 'DraftPolicyDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('draftPolicy');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'DraftPolicy', function($stateParams, DraftPolicy) {
                    return DraftPolicy.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'draft-policy',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('draft-policy-detail.edit', {
            parent: 'draft-policy-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/draft-policy/draft-policy-dialog.html',
                    controller: 'DraftPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DraftPolicy', function(DraftPolicy) {
                            return DraftPolicy.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('draft-policy.new', {
            parent: 'draft-policy',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/draft-policy/draft-policy-dialog.html',
                    controller: 'DraftPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                policy: null,
                                name: null,
                                email: null,
                                phone: null,
                                remarks: null,
                                remarks_admin: null,
                                isReplied: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('draft-policy', null, { reload: 'draft-policy' });
                }, function() {
                    $state.go('draft-policy');
                });
            }]
        })
        .state('draft-policy.edit', {
            parent: 'draft-policy',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/draft-policy/draft-policy-dialog.html',
                    controller: 'DraftPolicyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DraftPolicy', function(DraftPolicy) {
                            return DraftPolicy.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('draft-policy', null, { reload: 'draft-policy' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('draft-policy.delete', {
            parent: 'draft-policy',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_SUB_ADMIN','ROLE_MONITORING_OFFICER', 'ROLE_HEPC_MONITORING_OFFICER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/draft-policy/draft-policy-delete-dialog.html',
                    controller: 'DraftPolicyDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DraftPolicy', function(DraftPolicy) {
                            return DraftPolicy.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('draft-policy', null, { reload: 'draft-policy' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
