(function() {
    'use strict';

    angular
        .module('investhryApp')
        .factory('FileService', FileService);

    FileService.$inject = ['$http'];

    function FileService($http) {
        var service = {
            getFileUrl: getFileUrl
        };

        return service;

        function getFileUrl(filename) {
            return $http.get('/api/files/' + filename, { responseType: 'arraybuffer' })
                .then(function(response) {
                    var blob = new Blob([response.data], { type: response.headers('Content-Type') });
                    var url = window.URL.createObjectURL(blob);
                    return url;
                });
        }
    }
})();
