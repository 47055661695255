(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('EscalatedTicketController', EscalatedTicketController);

    EscalatedTicketController.$inject = ['Principal','Tickets', 'TicketsLogs', 'downloadticketattachment', 'TicketMember'];

    function EscalatedTicketController(Principal,Tickets, TicketsLogs, downloadticketattachment, TicketMember) {
        var vm = this;
        vm.account=null;
        vm.allEscalatedTickets = [];
        vm.getTicketByDepartment=getTicketByDepartment;
        vm.getLogs=getLogs;
        vm.downloadAttachment=downloadAttachment;
        loadAll();

        function loadAll() {
            Principal.identity().then(function(account) {
                vm.account = account;
                TicketMember.get({login: vm.account.login}, function (result) {
                    vm.ticketmember = result;
                });
            });
        }

        function getTicketByDepartment(key) {
            Tickets.query({columnKey: "EscalatedTickets", columnValue: key},function(result){
               vm.allEscalatedTickets = result;
               vm.searchQuery = null;
            });
        }

        function getLogs(ticketDetails) {
            TicketsLogs.query({ticketNumber: ticketDetails.ticketNumber},function (result) {
                vm.ticketsLogs=result;
            })
        }

        function downloadAttachment(attachment) {
            downloadticketattachment.download(attachment)
                .then(function(success) {}, function(error) {
                    alert('error : ' + error);
                });
        }
    }
})();
