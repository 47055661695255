(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ViewLogController', ViewLogController);

    ViewLogController.$inject = ['$scope', '$state', '$uibModalInstance', '$stateParams', 'Principal', 'LoginService', 'Grievance', 'grievance', 'GrievanceLog', 'downloadService'];

    function ViewLogController ($scope, $state, $uibModalInstance, $stateParams, Principal, LoginService, Grievance, grievance, GrievanceLog, downloadService) {
        var vm = this;
        vm.grievance = grievance;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.downloadFile = downloadFile;
        vm.grievanceId= $stateParams.grievanceId;
        vm.pdfconverter= pdfconverter;

        vm.clear = clear;

        GrievanceLog.query({id:"1",grievanceId: $stateParams.grievanceId}, function(result) {
                    vm.grievancelog = result;
                    vm.searchQuery = null;
            });

        function clear ()
        {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        function downloadFile(attachment)
        {
         downloadService.download(attachment + ".pdf")
            .then(function(success) {
                console.log('success : ' + success);
            }, function(error) {
                console.log('error : ' + error);
            });
        }

        function pdfconverter () {
            var element = document.getElementById('pdf_data');
            html2pdf(element,{
                margin:  0.5,
                html2canvas: { dpi:192, letterRendering: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            });
        }
    }
})();
