(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewsandgalleryDialogController', NewsandgalleryDialogController);

    NewsandgalleryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Newsandgallery', 'FileManagement'];

    function NewsandgalleryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Newsandgallery, FileManagement) {
        var vm = this;

        vm.newsandgallery = entity;
        $scope.file = {};
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.addfileattachment = function()
        {
            var fileNameArray = vm.imageFile.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=vm.newsandgallery.description;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            vm.newsandgallery.isSaved=false;
            Newsandgallery.save(vm.newsandgallery,onSaveImageFileSuccess);

        }

        function onSaveImageFileSuccess (result)
        {
            var filename = result.id;
            var file =vm.imageFile;
            FileManagement.saveFile(file,filename);
            var fileNameArray = vm.imageFile.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                 fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            result.description = result.id+"."+fileextension;
            Newsandgallery.update(result,onSaveSuccess,onSaveError);
        }

        function save () {
            vm.isSaving = true;
            if (vm.newsandgallery.id !== null) {
                Newsandgallery.update(vm.newsandgallery, onSaveSuccess, onSaveError);
            } else {
                Newsandgallery.save(vm.newsandgallery, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:newsandgalleryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.lastmodified = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
