(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('Service_dashboard_outputDetailController', Service_dashboard_outputDetailController);

    Service_dashboard_outputDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Service_dashboard_output'];

    function Service_dashboard_outputDetailController($scope, $rootScope, $stateParams, previousState, entity, Service_dashboard_output) {
        var vm = this;

        vm.service_dashboard_output = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:service_dashboard_outputUpdate', function(event, result) {
            vm.service_dashboard_output = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
