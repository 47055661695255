(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('StartupPoliciesController', EvPoliciesController);

    EvPoliciesController.$inject = ['EvPolicies', '$stateParams','$scope', '$state','IncentiveForm', 'Principal', 'LoginService'];

    function EvPoliciesController(EvPolicies, $stateParams,$scope, $state, IncentiveForm, Principal, LoginService) {

        var vm = this;
        vm.url = null;
        vm.isAuthenticated = Principal.isAuthenticated;
        IncentiveForm.query(function(result){
            vm.userdetails=result;
        });

        vm.setUrl = function (url) {
            vm.url = $sce.trustAsResourceUrl(url);
        };

        vm.login = function login() {
            LoginService.open();
        };
    }
})();
