(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('MinutesofmeetingDeleteController',MinutesofmeetingDeleteController);

    MinutesofmeetingDeleteController.$inject = ['$uibModalInstance', 'entity', 'Minutesofmeeting'];

    function MinutesofmeetingDeleteController($uibModalInstance, entity, Minutesofmeeting) {
        var vm = this;

        vm.minutesofmeeting = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Minutesofmeeting.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
