(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('AverageFeeYearlyController', AverageFeeYearlyController);

    AverageFeeYearlyController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'ServiceViewCafYearly', 'TowerserviceNO','ServiceViewCafYearlyByElastic','DownloadServiceViewCafYearlyByElastic','DownloadCafCsv', 'AveragefeeYearlyByElatsic'];
    function AverageFeeYearlyController ($timeout, $scope, $stateParams, $uibModalInstance, ServiceViewCafYearly, TowerserviceNO, ServiceViewCafYearlyByElastic,DownloadServiceViewCafYearlyByElastic,DownloadCafCsv, AveragefeeYearlyByElatsic) {
        var vm = this;

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";
        vm.clear = clear;
        vm.servicename=$stateParams.servicename;
        vm.status=$stateParams.status;
        vm.serviceid=$stateParams.serviceid;
        var initDate=new Date('05-01-2017');
        var totalTime=0;
        var totalCleared=0;
        vm.showDepartments=showDepartments;
        vm.departmentName=[];
        vm.downloadAllData = downloadAllData;

        vm.spiner= true;

        $scope.file = {};
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        /*appliedDate=new Date(result[i].appliedDate.toDateString());
        clearedDate=new Date(result[i].clearedDate.toDateString());
        dayDiff= (clearedDate - appliedDate)  / 1000 / 60 / 60 / 24;
*/
        loadAllCafs();
        function loadAllCafs() {
            var appliedDate;
            var clearedDate;
            var dayDiff;
            if (!$stateParams.departmentName) {
                vm.deptName = null;
            } else {
                vm.deptName = $stateParams.departmentName;
            }

            if (!$stateParams.district) {
                vm.districtName = null;
            } else {
                vm.districtName = $stateParams.district;
            }

            if (!$stateParams.projectLevel) {
                vm.levelfiled = null;
            } else {
                vm.levelfiled = $stateParams.projectLevel;
            }
            var data = {
                departmentName: vm.deptName,
                district: vm.districtName,
                projectLevel: vm.levelfiled,
                serviceId: $stateParams.serviceid,
                status: $stateParams.status,
                page: $scope.currentPage - 1,
                size: $scope.pageSize
            };
            Object.keys(data).forEach(function (k) {
                if (!data[k] && data[k] !== undefined) {
                    delete data[k];
                }
            });

            //   vm.dashboardcafs=ServiceViewCafYearly.query({serviceid:$stateParams.serviceid,status:$stateParams.status},function(result){
            //     vm.show=true;
            //     vm.spiner=false;
            //     console.log(result);
            // });
            vm.dashboardcafs = AveragefeeYearlyByElatsic.get(data,
                function (result) {
                    vm.show = true;
                    vm.spiner = false;
                });
        }

        vm.getTotalPhaseFee = function(averageFeeApiList) {
            return averageFeeApiList.reduce(function(total, feeApi) {
                return total + feeApi.phasefee;
            }, 0);
        };

        function downloadAllData() {
            AveragefeeYearlyByElatsic.getAverageFee({
                departmentName: vm.deptName,
                district: vm.districtName,
                projectLevel: vm.levelfiled,
                serviceId: $stateParams.serviceid,
                status: $stateParams.status
            }, function(result) {
                try {
                    // Check if result is an array and has at least one item
                    if (Array.isArray(result) && result.length > 0) {
                        // Access the first object's status property
                        var filename = result[0].status; // This gets the status value from the first object in the array

                        // Call the download function with the filename
                        DownloadCafCsv.download(filename).then(function(success) {
                            console.log(success);
                        }, function(error) {
                            alert('error : ' + error);
                        });
                    } else {
                        alert('No data received or result is empty.');
                    }
                } catch (e) {
                    console.error("Error processing response:", e);
                }
            }, function(error) {
                console.error("Error fetching data:", error);
            });
        }



        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
        }

        $scope.pageChangedHandler = function(num) {
            loadAllCafs();
        };

        function showDepartments(data) {
            TowerserviceNO.query({id:'1',projectdetailid:data.projectdetailid},function (result) {
                if(result.length===0){
                    vm.departmentName=[];
                    vm.departmentName[0] = "NA";
                }
                else {
                    vm.departmentName = result;
                }
            });

        }

    }
})();
