(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('undoRejectDialogController', undoRejectDialogController);

    undoRejectDialogController.$inject = ['$timeout','$scope','$stateParams','$uibModalInstance','projectService', 'projectAttachemnt','projectServiceLog','ProjectAttachemnt','ProjectServiceLogUpdated','FileManagement','Projectservicedetail','ProjectServiceReportInfoForClearance','CheckContentFile'];
    function undoRejectDialogController ($timeout, $scope, $stateParams, $uibModalInstance, projectService, projectAttachemnt, projectServiceLog, ProjectAttachemnt, ProjectServiceLogUpdated, FileManagement, Projectservicedetail,ProjectServiceReportInfoForClearance,CheckContentFile) {
        var vm = this;
        vm.projectServiceLog = projectServiceLog;
        vm.projectAttachemnt = projectAttachemnt;
        vm.projectService=projectService;
        vm.projectservicereportinfo = ProjectServiceReportInfoForClearance.get({department:vm.projectService.departmentName,projectid:vm.projectService.projectid,service:vm.projectService.serviceName});
        var projectAttachmentResultObject=null;
        var projectServiceLogid=null;
        $scope.file = {};
        vm.clear = clear;
        vm.undoRejectService = undoRejectService;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function undoRejectService(){
            console.log(projectAttachemnt.file);
            if(angular.isDefined(projectAttachemnt.file)){
                if(CheckContentFile.checkFile(projectAttachemnt.file.name)){
                    undoService();
                }
                else{
                    alert("Invalid File");
                }
            }
            else{
                undoService();
            }

        }

        function undoService() {
            vm.projectServiceLog.projectid=vm.projectService.projectid;
            vm.projectServiceLog.serviceid=vm.projectService.serviceid;
            vm.projectServiceLog.actionTaken="Undo-Rejected";
            ProjectServiceLogUpdated.save(vm.projectServiceLog,onServiceLogSaveSuccess,onServiceLogSaveError);
        }

        function onServiceLogSaveSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceLogUpdate', result);
            projectServiceLogid=result.id;
            vm.projectService.latestComments=vm.projectServiceLog.comments;
            vm.projectService.status="Pending";
            Projectservicedetail.update(vm.projectService,onUpdateProjectServiceSuccess,onUpdateProjectServiceError);
        }
        function onUpdateProjectServiceSuccess(result)
        {
            $scope.$emit('investhryApp:projectservicedetailUpdate', result);
            vm.projectservicereportinfo.status='Undo-Rejected';
            ProjectServiceReportInfoForClearance.update(vm.projectservicereportinfo,onUpdateProjectServiceReportInfoSuccess,onUpdateProjectServiceReportInfoError);
        }
        function onUpdateProjectServiceReportInfoSuccess(result)
        {
            $scope.$emit('investhryApp:projectServiceReportInfoUpdate', result);
            if(angular.isDefined(vm.projectAttachemnt.file)){
                saveProjectAttachment();
            }
            else
            {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }
        function onUpdateProjectServiceError()
        {
            vm.isSaving = false;
        }
        function onServiceLogSaveError()
        {
            vm.isSaving = false;
        }
        function saveProjectAttachment()
        {
            var file=vm.projectAttachemnt.file;
            vm.projectAttachemnt.id=projectServiceLogid;
            vm.projectAttachemnt.fileName=file.name;
            vm.projectAttachemnt.description="Undo-Rejected Attachment";
            vm.projectAttachemnt.fileExtension =file.type;
            ProjectAttachemnt.update(vm.projectAttachemnt,onSaveProjectAttachmentSuccess,onSaveProjectAttachmentError);
        }
        function onSaveProjectAttachmentSuccess(result)
        {
            $scope.$emit('investhryApp:projectAttachemntUpdate', result);
            var filename = result.id;
            var file =vm.projectAttachemnt.file;
            FileManagement.saveFile(file,filename);
            projectAttachmentResultObject=result;

            var fileNameArray = vm.projectAttachemnt.file.name.split(".");
            var fileextension="";
            if((fileNameArray.length>1))
            {
                fileextension=fileNameArray[(fileNameArray.length-1)];
            }
            var inputFileName=result.id;
            if(fileextension!=="")
            {
                inputFileName=inputFileName+"."+fileextension;
            }
            projectAttachmentResultObject.serverFileName=inputFileName;

            ProjectAttachemnt.update(projectAttachmentResultObject,onUpdateProjectAttachmentSuccess,onUpdateProjectAttachmentError);
        }
        function onSaveProjectAttachmentError()
        {
            vm.isSaving = false;
        }
        function onUpdateProjectAttachmentSuccess(result)
        {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onUpdateProjectServiceReportInfoError()
        {
            vm.isSaving = false;
        }
        function onUpdateProjectAttachmentError()
        {
            vm.isSaving = false;
        }
    }
})();
