(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('TicketScheduler', TicketScheduler);

    TicketScheduler.$inject = ['$resource'];

    function TicketScheduler ($resource) {
        var resourceUrl =  'api/ticketscheduler/:action';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
            },
            'update': { method:'PUT' }
        });
    }
})();
