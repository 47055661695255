(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ShopMoveCaftoExciseController', ShopMoveCaftoExciseController);


    ShopMoveCaftoExciseController.$inject = ['$scope', '$state', 'ShopExciseCaf','MigrateCafType'];

    function ShopMoveCaftoExciseController($scope, $state, ShopExciseCaf , MigrateCafType) {
        var vm = this;
        vm.projects = [];
        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";
        vm.deleteId = null;
        vm.deleteCaf=deleteCaf;



        loadAll();

        function loadAll() {
            ShopExciseCaf.query(function (result) {
                vm.projects = result;
            });
        }

        vm.details = function (projectid) {
            $state.go('projectdetailjist',{projectid:projectid});
        };

        vm.modalCaf = function(id){
            $('#deleteModal').modal('show');
            vm.deleteId = id;
        };

       function deleteCaf(id){
           $('#deleteModal').modal('hide');
           MigrateCafType.get({projectId:vm.deleteId},function (result) {
                alert("CAF TYPE has been updated for"+result.cafpin);
            }, function (error) {
               alert("CAF already exists"+error);
           });

           loadAll();
       }
    }
})();
