(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketsFeedbackDetailController', TicketsFeedbackDetailController);

    TicketsFeedbackDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TicketsFeedback'];

    function TicketsFeedbackDetailController($scope, $rootScope, $stateParams, previousState, entity, TicketsFeedback) {
        var vm = this;

        vm.ticketsFeedback = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('investhryApp:ticketsFeedbackUpdate', function(event, result) {
            vm.ticketsFeedback = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
