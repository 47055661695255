(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DashboardbetweendatesCAFsController', DashboardbetweendatesCAFsController);

    DashboardbetweendatesCAFsController.$inject = ['DashboardbetweendatesCAFs','$stateParams','$uibModalInstance','$filter'];

    function DashboardbetweendatesCAFsController(DashboardbetweendatesCAFs,$stateParams,$uibModalInstance,$filter) {
        var vm = this;

        vm.clear=clear;
        vm.serviceid=$stateParams.serviceid;
        vm.fromdate=$stateParams.from;
        vm.todate=$stateParams.to;
        vm.status=$stateParams.status;

        loadAll();

        function loadAll() {
            var fromdate=$filter('date')(vm.fromdate, "dd-MM-yyyy");
            var todate=$filter('date')(vm.todate, "dd-MM-yyyy");
            DashboardbetweendatesCAFs.query({from:fromdate,to:todate,status:vm.status,serviceid:vm.serviceid},function (result) {
               vm.servicecafs=result;
               vm.show=true;
            });
        }

        function clear() {
            $uibModalInstance.close();
        }
    }
})();
