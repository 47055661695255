(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('decriminalization-form', {
            parent: 'entity',
            url: '/decriminalization-form',
            data: {
                authorities: ['ROLE_NODAL_OFFICER','ROLE_TOWER_OFFICER']
               // pageTitle: 'investhryApp.decriminalization.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/decriminalization/decriminalizations.html',
                    controller: 'DecriminalizationFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        complianceRequest: null,
                        departmentName: null,
                        departmentId: null,
                        serviceType: null,
                        otherServiceType: null,
                        serviceSchemeName: null,
                        actName: null,
                        ruleRegulation: null,
                        ruleRegulationName: null,
                        sectionRuleNumber: null,
                        sectionRuleNumberCriminalProvision: null,
                        criminalPunishmentDescription: null,
                        sector: null,
                        otherSector: null,
                        offence: null,
                        criminalPunishmentTenureInMonths: null,
                        minimumTenure: null,
                        maximumTenure: null,
                        criminalPunishmentAmountInInr: null,
                        minimumAmount: null,
                        maximumAmount: null,
                        createDate: null,
                        updateDate: null,
                        sectorId: null,
                        id: null
                    };
                }
            }
        })
            .state('view-decriminalization-form', {
                parent: 'entity',
                url: '/view-decriminalization-form',
                data: {
                    authorities: []
                    // pageTitle: 'investhryApp.decriminalization.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/decriminalization/view-decriminalizations.html',
                        controller: 'ViewDecriminalizationFormController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            complianceRequest: null,
                            departmentName: null,
                            departmentId: null,
                            serviceType: null,
                            otherServiceType: null,
                            serviceSchemeName: null,
                            actName: null,
                            ruleRegulation: null,
                            ruleRegulationName: null,
                            sectionRuleNumber: null,
                            sectionRuleNumberCriminalProvision: null,
                            criminalPunishmentDescription: null,
                            sector: null,
                            otherSector: null,
                            offence: null,
                            criminalPunishmentTenureInMonths: null,
                            minimumTenure: null,
                            maximumTenure: null,
                            criminalPunishmentAmountInInr: null,
                            minimumAmount: null,
                            maximumAmount: null,
                            createDate: null,
                            updateDate: null,
                            sectorId: null,
                            id: null
                        };
                    }
                }
            });
    }

})();
