(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('CAFAttachmentController', CAFAttachmentController);

    CAFAttachmentController.$inject = ['CAFAttachment'];

    function CAFAttachmentController(CAFAttachment) {

        var vm = this;

        vm.cAFAttachments = [];

        loadAll();

        function loadAll() {
            CAFAttachment.query(function(result) {
                vm.cAFAttachments = result;
                vm.searchQuery = null;
            });
        }
    }
})();
