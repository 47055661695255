(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketFeedbackDetailController', TicketFeedbackDetailController);

    TicketFeedbackDetailController.$inject = ['$state', '$scope', 'entity', 'Principal', 'downloadticketattachment', 'TicketsCustomer', 'TicketsFeedback'];

    function TicketFeedbackDetailController($state, $scope, entity, Principal, downloadticketattachment, TicketsCustomer, TicketsFeedback) {
        var vm = this;
        vm.tickets = entity[0];
        vm.account= {};
        vm.downloadAttachment = downloadAttachment;
        $scope.file = {};
        loadAll();

        function loadAll() {
            Principal.identity().then(function(account) {
                vm.account = account;
            });

            if(vm.tickets.cafpin.length===10){
                TicketsCustomer.get({columnKey: "cafpin", columnValue: vm.tickets.cafpin},function (result) {
                    vm.complainant=result;
                });
            }
            else if(vm.tickets.cafpin.length>10){
                if(vm.tickets.investorDetail.includes("@")){
                    TicketsCustomer.get({columnKey: "email", columnValue: vm.tickets.investorDetail},function (result) {
                        vm.complainant=result;
                    });
                }
                else{
                    TicketsCustomer.get({columnKey: "mobileNumber", columnValue: vm.tickets.investorDetail},function (result) {
                        vm.complainant=result;
                    });
                }

            }
        }

        function downloadAttachment(attachment) {
            downloadticketattachment.download(attachment)
                .then(function(success) {
                }, function(error) {
                    alert('error : ' + error);
                });
        }

    }
})();
