(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('totalservicedashboarddetails', {
            parent: 'entity',
            url: '/totalservicedashboarddetails',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.totalservicedashboarddetails.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/totalservicedashboarddetails/totalservicedashboarddetails.html',
                    controller: 'TotalservicedashboarddetailsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('totalservicedashboarddetails');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('totalservicedashboarddetails-detail', {
            parent: 'totalservicedashboarddetails',
            url: '/totalservicedashboarddetails/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'investhryApp.totalservicedashboarddetails.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/totalservicedashboarddetails/totalservicedashboarddetails-detail.html',
                    controller: 'TotalservicedashboarddetailsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('totalservicedashboarddetails');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Totalservicedashboarddetails', function($stateParams, Totalservicedashboarddetails) {
                    return Totalservicedashboarddetails.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'totalservicedashboarddetails',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('totalservicedashboarddetails-detail.edit', {
            parent: 'totalservicedashboarddetails-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/totalservicedashboarddetails/totalservicedashboarddetails-dialog.html',
                    controller: 'TotalservicedashboarddetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Totalservicedashboarddetails', function(Totalservicedashboarddetails) {
                            return Totalservicedashboarddetails.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('totalservicedashboarddetails.new', {
            parent: 'totalservicedashboarddetails',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/totalservicedashboarddetails/totalservicedashboarddetails-dialog.html',
                    controller: 'TotalservicedashboarddetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                projectid: null,
                                serviceid: null,
                                cafpin: null,
                                businessentity: null,
                                district: null,
                                projectlevel: null,
                                proposedemployement: null,
                                projectinvestment: null,
                                deactivated: null,
                                status: null,
                                formfilleddate: null,
                                formfilledstatus: null,
                                isapplied: null,
                                servicename: null,
                                departmentname: null,
                                investorName: null,
                                investorEmail: null,
                                investorContact: null,
                                serviceAppliedDate: null,
                                serviceClearedDate: null,
                                address: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('totalservicedashboarddetails', null, { reload: 'totalservicedashboarddetails' });
                }, function() {
                    $state.go('totalservicedashboarddetails');
                });
            }]
        })
        .state('totalservicedashboarddetails.edit', {
            parent: 'totalservicedashboarddetails',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/totalservicedashboarddetails/totalservicedashboarddetails-dialog.html',
                    controller: 'TotalservicedashboarddetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Totalservicedashboarddetails', function(Totalservicedashboarddetails) {
                            return Totalservicedashboarddetails.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('totalservicedashboarddetails', null, { reload: 'totalservicedashboarddetails' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('totalservicedashboarddetails.delete', {
            parent: 'totalservicedashboarddetails',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/totalservicedashboarddetails/totalservicedashboarddetails-delete-dialog.html',
                    controller: 'TotalservicedashboarddetailsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Totalservicedashboarddetails', function(Totalservicedashboarddetails) {
                            return Totalservicedashboarddetails.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('totalservicedashboarddetails', null, { reload: 'totalservicedashboarddetails' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
