(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ReappliedservicesDialogController', ReappliedservicesDialogController);

    ReappliedservicesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Reappliedservices'];

    function ReappliedservicesDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Reappliedservices) {
        var vm = this;

        vm.reappliedservices = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.reappliedservices.id !== null) {
                Reappliedservices.update(vm.reappliedservices, onSaveSuccess, onSaveError);
            } else {
                Reappliedservices.save(vm.reappliedservices, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:reappliedservicesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.requireMarkedOnDate = false;
        vm.datePickerOpenStatus.assigOnDate = false;
        vm.datePickerOpenStatus.formFilledOnDate = false;
        vm.datePickerOpenStatus.paymentMadeOnDate = false;
        vm.datePickerOpenStatus.lastactiondate = false;
        vm.datePickerOpenStatus.statusdate = false;
        vm.datePickerOpenStatus.integrationDate = false;
        vm.datePickerOpenStatus.clearedDate = false;
        vm.datePickerOpenStatus.rejectionDate = false;
        vm.datePickerOpenStatus.deemedClearedDate = false;
        vm.datePickerOpenStatus.reAppliedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
