(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('DownloadServiceViewCafYearlyByElastic', DownloadServiceViewCafYearlyByElastic);

    DownloadServiceViewCafYearlyByElastic.$inject = ['$resource'];

    function DownloadServiceViewCafYearlyByElastic($resource) {
        var resourceUrl = 'api/servicedashboardcafsYearlyListByElastic/departmentName/district/projectLevel/serviceId/status';
        var averageFeeUrl = 'api/servicedashboardcafsYearlyListByElasticForAverageFee/departmentName/district/projectLevel/serviceId/status';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'getAverageFee': {
                method: 'GET',
                url: averageFeeUrl,
                transformResponse: function(data) {
                    return data; // Process the response if needed
                }
            }
        });
    }
})();
