(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('SearchGrievanceController', SearchGrievanceController);

    SearchGrievanceController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'SearchGrievance','Grievance','GrievanceLog'];

    function SearchGrievanceController ($scope, Principal, LoginService, $state, SearchGrievance,Grievance,GrievanceLog) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.getGrievance = getGrievance;
        vm.grievance={};
        vm.showtable=false;
        vm.grievanceLog={};

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register () {
            $state.go('register');
        }

        function getGrievance() {
            vm.grievance=null;
            vm.showtable=false;
            SearchGrievance.get({grievance:vm.grievanceID},function(result){
                vm.grievance = result;
                vm.showtable=true;
            });
        }

        vm.commentContent= function (data) {
            vm.commentData = data;
        };

        vm.comment = function () {
            vm.grievanceLog.grievanceId= vm.commentData.grievanceId;
            vm.grievanceLog.action= "Comment";
            vm.grievanceLog.actionComment= vm.commentValue;
            GrievanceLog.save(vm.grievanceLog,onSaveSuccess);
        };

        function onSaveSuccess() {
            vm.grievanceLog={};
            vm.commentValue=null;
        }
        vm.showPersonalDetail= function (id) {
            Grievance.get({id: id},function(result) {
                swal({
                    title: '<u>Applicant Details</u><br/><br/>',
                    animation: true,
                    html:
                        '<b>Name: </b> ' + result.grievanceBy + '<br/><br/>' +
                        '<b>Phone: </b> ' + result.phone + '<br/><br/>' +
                        '<b>Email: </b> ' + result.email + '<br/><br/>' +
                        '<b>Address: </b> ' + result.address + '<br/><br/>'
                })
            });
        }
    }
})();
