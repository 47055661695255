(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('MultipleUserWithSingleMailWithCaf', MultipleUserWithSingleMailWithCaf);

    MultipleUserWithSingleMailWithCaf.$inject = ['$resource'];

    function MultipleUserWithSingleMailWithCaf ($resource) {
        var resourceUrl =  '/api/userswithsamemailandwithcaf';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': { method:'PUT' }
        });
    }
})();
