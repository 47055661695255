(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('UserDisputeController', UserDisputeController);

    UserDisputeController.$inject = ['Principal','Dispute', 'viewRecruitmentFile'];

    function UserDisputeController(Principal ,Dispute ,viewRecruitmentFile) {

        var vm = this;

        vm.disputes = [];
        vm.account =[];

        loadAll();

        function loadAll() {
            Dispute.query({user:'User'},function(result) {
                vm.disputes = result;
                vm.searchQuery = null;
            });
        }
        UserRole();
        function UserRole() {
            Principal.identity().then(function(account) {
                vm.account = account;
            });
        }

        vm.DownloadFile = function (fileId) {
            viewRecruitmentFile.view(fileId);
        };

        vm.showPersonalDetail= function (id) {
            Dispute.get({id: id},function(result) {
                console.log(result);
                swal({
                    title: '<u>Applicant Details</u><br/><br/>',
                    animation: true,
                    html:
                        '<b>Name: </b> ' + result.nameOfEnterprise + '<br/><br/>' +
                        '<b>Phone: </b> ' + result.mobileNumber + '<br/><br/>' +
                        '<b>Email: </b> ' + result.emailAddress + '<br/><br/>' +
                        '<b>Address: </b> ' + result.address + '<br/><br/>'
                })
            });
        }

    }
})();
