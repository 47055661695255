(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('BrapChecklistController', BrapChecklistController);

    BrapChecklistController.$inject = ['BrapChecklist'];

    function BrapChecklistController(BrapChecklist) {

        var vm = this;

        vm.brapChecklists = [];

        loadAll();

        function loadAll() {
            BrapChecklist.query(function(result) {
                vm.brapChecklists = result;
                vm.searchQuery = null;
            });
        }
    }
})();
