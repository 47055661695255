(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TicketMemberDialogController', TicketMemberDialogController);

    TicketMemberDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TicketMember'];

    function TicketMemberDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TicketMember) {
        var vm = this;

        vm.ticketMember = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.ticketMember.id !== null) {
                TicketMember.update(vm.ticketMember, onSaveSuccess, onSaveError);
            } else {
                TicketMember.save(vm.ticketMember, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('investhryApp:ticketMemberUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
