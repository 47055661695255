(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('paymentResponseController', paymentResponseController);

    paymentResponseController.$inject = ['$timeout', '$scope', '$stateParams','$state', 'Auth', 'Principal','$location'];

    function paymentResponseController ($timeout, $scope, $stateParams, $state, Auth, Principal, $location) {
        var vm = this;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        vm.response= $location.search();
    }
})();
