(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NewDeptservicedashboardController', NewDeptservicedashboardController);

   NewDeptservicedashboardController.$inject = ['$scope', 'Principal', 'LoginService', '$state','Newdeptservicedashboard'];

    function NewDeptservicedashboardController ($scope, Principal, LoginService, $state,Newdeptservicedashboard) {
        var vm = this;

       vm.account=null;
       vm.isLabourActive=true;
       vm.Department='HSPCB';
       vm.loadAll=loadAll;
       loadAll('HSPCB');

       function loadAll(name){
            vm.Department=name + " Department";
            vm.newdeptservicedashboard={};
            Newdeptservicedashboard.query({name:name},function(result){
                vm.newdeptservicedashboard= result;
            });
       }
    }
})();
